/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import { useSelector } from 'react-redux';
import { IOperationConfiguration } from '../../models/IOperations';
import { t } from '../../translations/i18n';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { useAsyncLoadContextualInterpolationDescriptions } from './useAsyncLoadContextualInterpolationDescriptions';
import { submitMeshInterpolation } from './mesh-interpolation-util';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { MmgMeshInterpolationConfiguration } from './configuration/mesh-interpolation-configuration';
import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import LayerUtils, { isLayerOutdated } from '../../shared/layers/layer-utils';
import { MmgConnectedMeshInterpolationSelectVariables } from './mesh-interpolation-select-variables';
import { getDataItemsByIds, getIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { getMessageFromOperation } from '../../store/selectors/WorkspaceOperationUtils';
import { MmgOperationDescription } from '../../operations/operation-description';
import { isInterpolationChildOperationsValid } from '../../managers/MeshInterpolationValidation';
import { useAsyncLoadInterpolationOperation } from './useAsyncLoadInterpolationOperation';
import { MmgGroup } from '../../shared/groups/group';
import { MmgOperationSelector } from '../../operations/operation-selector';
import { MmgStepper } from '../../shared/steppers/stepper';
import { MmgStep } from '../../shared/steppers/step';
import { MmgPanelTopActions } from '../../shared/panels/panel-top-actions';
import { MmgStepContent } from '../../shared/steppers/step-content';
import { stepperStickyPanelStyle } from '../../shared/steppers/stepper-styles';
import { IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import { IWorkspaceQuery } from '../../models/IQueries';
import { IOperationDescription, IContextualOperationDescriptions } from '../../models/IOperationDescriptions';
import { createOperationConfigurationFromDescription } from '../../operations/operation-utils';
import { useIsMounted } from '../../shared/hooks/hooks';
import { useStepper } from '../../shared/steppers/useStepper';
import { IGlobalState } from '../../store/reducers';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';

const { isLayerInProgress, isMeshInterpolationAllowed, isLayerFailed } = LayerUtils;

type MeshEditInterpolationConfigurationProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
  operationId: string;
  onOperationSubmitSuccess?: (execute: boolean) => void;
};

const EDIT_INTERPOLATION_STEPS = {
  VARIABLES: 0,
  CONFIGURE: 1,
};

/**
 * @name MmgConnectedMeshEditInterpolationConfiguration
 * @summary Allows configuring a existing  mesh interpolation.
 * Supports both 'basic'/'base' configurations and variable configurations.
 *
 * @param props
 */
export const MmgConnectedMeshEditInterpolationConfiguration = (props: MeshEditInterpolationConfigurationProps) => {
  const { projectId, workspaceId, meshId, operationId, onOperationSubmitSuccess } = props;

  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getMeshAttributesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributes();
  const getMeshAttributeSettingsSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributeSettings();
  const getMeshQueriesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshQueries();

  const mesh: IWorkspaceEnrichedMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const workspaceVariables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );
  const meshAttributes: Array<IWorkspaceAttribute> =
    useSelector((state: IGlobalState) => getMeshAttributesSelectorInstance(state, { meshId })) || [];
  const meshAttributeSettings: Array<IWorkspaceAttributeSettings> =
    useSelector((state: IGlobalState) => getMeshAttributeSettingsSelectorInstance(state, { meshId })) || [];
  const meshQueries: Array<IWorkspaceQuery> =
    useSelector((state: IGlobalState) => getMeshQueriesSelectorInstance(state, { meshId })) || [];
  const interpolationType: string = useSelector((state: IGlobalState) => state.WorkspaceMeshReducer.interpolationType);

  const isMounted = useIsMounted();

  const [interpolationInProgress, setInterpolationInProgress] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [interpolationConfiguration, setInterpolationConfiguration] = useState(null as IOperationConfiguration);

  const [selectedOperationKey, setSelectedOperationKey] = React.useState(null as string);

  const [selectedVariableIds, setSelectedVariableIds] = React.useState([] as Array<string>);

  const { activeStep, handleNext, goToStep } = useStepper(EDIT_INTERPOLATION_STEPS.CONFIGURE);

  /**
   * Callback to when the 'add more' button is pressed.
   */
  const addMoreItems = () => {
    goToStep(EDIT_INTERPOLATION_STEPS.VARIABLES);
  };

  /**
   * Effectively checks if any variables are selected to be included in the interpolation.
   */
  const anyVariablesSelected = selectedVariableIds && selectedVariableIds.length > 0;

  const retryFailedLoading = () => {
    if (interpolationDescriptionsLoadingFailed) {
      retryLoadInterpolationDescriptions();
    }

    if (interpolationOperationLoadingFailed) {
      retryLoadInterpolationOperation();
    }
  };

  /**
   * Saves changes without executing the interpolations.
   */
  const saveInterpolation = () => submitInterpolation(false);

  /**
   * Saves changes and executes the interpolation.
   */
  const executeInterpolation = () => submitInterpolation(true);

  /**
   * saves the interpolation operation, optionally executes it.
   *
   * @param execute
   */
  const submitInterpolation = (execute: boolean) => {
    if (!interpolationConfiguration) {
      // nothing to submit
      return;
    }

    if (execute) {
      setInterpolationInProgress(true);
    } else {
      setSaveInProgress(true);
    }

    // only include child operations of selected variables
    const filteredChildOperations = getFilteredChildOperations();

    const operationConfigurationToSubmit = {
      ...interpolationConfiguration,
      childOperations: filteredChildOperations,
      inputIds: [meshId],
    };

    submitMeshInterpolation(workspaceId, operationConfigurationToSubmit, execute)
      .then(() => {
        if (onOperationSubmitSuccess) {
          onOperationSubmitSuccess(execute);
        }
      })
      .finally(() => {
        if (isMounted()) {
          setInterpolationInProgress(false);
          setSaveInProgress(false);
        }
      });
  };

  // Only include child operations corresponding to selected variables.
  // This allows user to remove a variabel and add it back with the same parameters set
  const getFilteredChildOperations = useCallback(
    () => {
      const { childOperations } = interpolationConfiguration || {};

      // Do not include any selected ids no longer included in the workspace variables.
      const allVariableIds = getIds(workspaceVariables) || [];
      const variableIds = selectedVariableIds.filter((id) => allVariableIds.indexOf(id) !== -1);

      const filteredChildOperations = (childOperations || []).filter(
        // we know that there is currently only one variable per child operation.
        ({ inputIds }) => variableIds.indexOf(inputIds[0]) !== -1,
      );

      return filteredChildOperations;
    },
    [interpolationConfiguration, selectedVariableIds, workspaceVariables],
  );

  /**
   * Callback for when operation configuration has changed
   *
   * @param operationConfiguration
   */
  const onInterpolationConfigurationChanged = (operationConfiguration: IOperationConfiguration) => {
    setInterpolationConfiguration(operationConfiguration);
  };

  /**
   * Sets the selected interpolation operation to the selected one.
   *
   * @param event
   */
  const onOperationChanged = (event) => {
    const operationKey = event.target.value;

    setSelectedOperationKey(operationKey);

    const interpolationDescription =
      (interpolationDescriptions || [])[operationKey] ||
      ({} as {
        basicOperationDescription: IOperationDescription;
        childContextualOperationDescriptions: IContextualOperationDescriptions;
      });

    const basicConfiguration = createOperationConfigurationFromDescription(
      interpolationDescription.basicOperationDescription,
    );

    // reset configuration and selected variables
    setInterpolationConfiguration(basicConfiguration);
    setSelectedVariableIds([]);
  };

  /**
   * Sets the selected variables the selected ones.
   *
   * @param selectedIds
   */
  const onSelectedVariablesChanged = (selectedIds: Array<string>) => {
    setSelectedVariableIds(selectedIds);
    // we do not reset interplationConfiguration as we do not want to loose any changes already made by the user
  };

  /**
   * Gets the contextual operation descriptions based on variables.
   */
  const {
    interpolationDescriptions,
    interpolationDescriptionsLoading,
    interpolationDescriptionsLoadingFailed,
    retry: retryLoadInterpolationDescriptions,
  } = useAsyncLoadContextualInterpolationDescriptions(workspaceId, workspaceVariables);

  /**
   * Gets the interpolation operation based on operation id.
   */
  const {
    interpolationOperation,
    interpolationOperationLoading,
    interpolationOperationLoadingFailed,
    retryLoadInterpolationOperation,
  } = useAsyncLoadInterpolationOperation(workspaceId, operationId);

  useEffect(
    () => {
      setInterpolationConfiguration(interpolationOperation);
      setSelectedOperationKey((interpolationOperation || {}).operationType);

      if (interpolationOperation) {
        const { childOperations } = interpolationOperation;

        const varableIds = childOperations.map(({ inputIds }) => inputIds[0]);
        setSelectedVariableIds(varableIds);
      }
    },
    [interpolationOperation],
  );

  const selectedVariables = useMemo(
    () => {
      return getDataItemsByIds(workspaceVariables, selectedVariableIds);
    },
    [workspaceVariables, selectedVariableIds],
  );

  const isMeshMissing = !mesh;

  const isInterpolationOperationValid = useMemo(
    () => {
      if (!meshId) {
        return false;
      }
      // we only consider childoperations corresponding to selected variable Ids
      const childOperationsForSelected = getFilteredChildOperations();
      return isInterpolationChildOperationsValid(childOperationsForSelected);
    },
    [getFilteredChildOperations, meshId],
  );

  if (isMeshMissing) {
    return <></>;
  }

  //User can do some work even if the mesh is not loaded.
  const isMeshInProgress = isLayerInProgress(mesh);

  const isInterpolationAllowed = isMeshInterpolationAllowed(mesh);

  const isLoading = interpolationDescriptionsLoading || interpolationOperationLoading;

  const isLoadingFailed = interpolationOperationLoadingFailed || interpolationDescriptionsLoadingFailed;

  // todo hevo should update this based on operationmetadata
  const isOperationFailed = isLayerFailed(interpolationConfiguration);
  const isOperationOutdated = isLayerOutdated(interpolationConfiguration);
  const message = getMessageFromOperation(interpolationConfiguration, true);

  const saveDisabled =
    !meshId || isLoading || interpolationInProgress || saveInProgress || !isInterpolationOperationValid;

  const interpolateDisabled = saveDisabled || !isInterpolationAllowed;

  const operationKeys = Object.keys(interpolationDescriptions || {});
  const isOperationsAvailable = operationKeys && operationKeys.length > 0;

  const selectedInterpolationDescription =
    (interpolationDescriptions || [])[selectedOperationKey] ||
    ({} as {
      basicOperationDescription: IOperationDescription;
      childContextualOperationDescriptions: IContextualOperationDescriptions;
    });

  const {
    basicOperationDescription,
    childContextualOperationDescriptions,
    postOperationDescription,
  } = selectedInterpolationDescription;

  // we expect one key per variable
  const allowedVariableIds = Object.keys(childContextualOperationDescriptions || {});

  const isStepComplete = (step: number) => {
    switch (step) {
      case EDIT_INTERPOLATION_STEPS.VARIABLES:
        return selectedOperationKey && selectedVariableIds && selectedVariableIds.length > 0;
      case EDIT_INTERPOLATION_STEPS.CONFIGURE:
        return isInterpolationOperationValid;
      default:
        return false;
    }
  };

  if (isLoadingFailed) {
    return (
      <MmgPanelSubsection>
        <p>
          {interpolationOperationLoadingFailed
            ? t('FAILED_TO_LOAD_CURRENT_MESH_INTERPOLATION')
            : t('FAILED_TO_LOAD_AVAILABLE_MESH_INTERPOLATIONS')}
        </p>

        <MikeButton variant="contained" onClick={retryFailedLoading}>{t('RETRY')}</MikeButton>
      </MmgPanelSubsection>
    );
  }

  return (
    <>
      {(isMeshInProgress || interpolationInProgress) && (
        <MmgPanelSubsection>
          <p>{t('CONFIGURE_AFTER_DATA_PROCESSED')}</p>
        </MmgPanelSubsection>
      )}

      <MmgStepper cssProp={stepperStickyPanelStyle} activeStep={activeStep}>
        <MmgStep key={'STEP_VARIABLES'} index={0} />
        <MmgStep key={'STEP_CONFIGURE'} index={1} last={true} />
      </MmgStepper>

      {!(isMeshInProgress || interpolationInProgress) && (
        <>
          {isOperationFailed && (
            <MmgMessageBanner messageTitle={t('MESH_INTERPOLATION_FAILED')} message={message} messageType="failed" />
          )}
          {isOperationOutdated && (
            <MmgMessageBanner
              messageTitle={t('MESH_INTERPOLATION_OUTDATED')}
              message={t('MESH_INTERPOLATION_OUTDATED_INFO', selectedVariables.length)}
              messageType="warning"
            />
          )}
        </>
      )}

      {activeStep === EDIT_INTERPOLATION_STEPS.CONFIGURE && (
        <MmgPanelTopActions>
          <MikeButton variant="outlined" color="secondary" onClick={addMoreItems}>
            <span css="mesh-button-text">{t('MESH_INTERPOLATION_ADD_VARIABLES')}</span>
          </MikeButton>
        </MmgPanelTopActions>
      )}

      <MmgStepContent step={EDIT_INTERPOLATION_STEPS.VARIABLES} activeStep={activeStep} index={0}>
        <MmgGroup groupName={t('INTERPOLATION_STEP_VARIABLES')} canBeHidden={false}>
          {/* todo hevo handle no operation anvaliable and not loading */}

          {isOperationsAvailable && (
            <MmgPanelSubsection>
              {/* in case operation was saved without the operationType, the use can selec tthe type. Is not expected to happen */}
              {!selectedOperationKey && (
                <MmgOperationSelector
                  label={t('SELECT_INTERPOLATION_OPERATION_LABEL')}
                  placeholder={t('SELECT_INTERPOLATION_OPERATION').toLowerCase()}
                  operationKeys={operationKeys}
                  operationsLoading={interpolationDescriptionsLoading}
                  operationsLoadingFailed={interpolationDescriptionsLoadingFailed}
                  onOperationChanged={onOperationChanged}
                  selectedOperationKey={selectedOperationKey}
                />
              )}
              <MmgOperationDescription operationKey={selectedOperationKey} />
            </MmgPanelSubsection>
          )}

          {/* todo hevo handle no allowed variables  and not loading */}
          {/*If any variables are allowed the user to select some */}
          {allowedVariableIds &&
            allowedVariableIds.length > 0 && (
              <MmgConnectedMeshInterpolationSelectVariables
                allowedVariableIds={allowedVariableIds}
                selectedVariableIds={selectedVariableIds}
                onSelectionChanged={onSelectedVariablesChanged}
              />
            )}
        </MmgGroup>
      </MmgStepContent>

      <MmgStepContent step={EDIT_INTERPOLATION_STEPS.CONFIGURE} activeStep={activeStep} index={0}>
        {selectedOperationKey &&
          anyVariablesSelected && (
            <MmgMeshInterpolationConfiguration
              projectId={projectId}
              workspaceId={workspaceId}
              meshId={meshId}
              variables={selectedVariables}
              initialMeshInterpolationsConfiguration={interpolationConfiguration}
              basicMeshInterpolationDescription={basicOperationDescription}
              contextualChildOperationDescriptions={childContextualOperationDescriptions}
              onMeshInterpolationConfigurationChanged={onInterpolationConfigurationChanged}
              meshAttributes={meshAttributes}
              meshAttributeSettings={meshAttributeSettings}
              meshQueries={meshQueries}
              interpolationType={interpolationType}
              mappedPostOperationContextualOperationDescriptions={postOperationDescription}
            />
          )}
      </MmgStepContent>

      <MikeStickyPanelBottomActions>
        {activeStep !== EDIT_INTERPOLATION_STEPS.CONFIGURE && (
          <MikeButton variant="contained" onClick={handleNext} disabled={!isStepComplete(activeStep)}>
            {t('NEXT')}
          </MikeButton>
        )}
        {activeStep === EDIT_INTERPOLATION_STEPS.CONFIGURE && (
          <>
            {/* <MikeButton buttontype="secondary" onClick={handleBack}>
              {t('INTERPOLATION_STEP_BACK')}
            </MikeButton> */}

            <MikeButton
              variant="outlined" color="secondary"
              disabled={saveDisabled}
              active={saveInProgress}
              onClick={saveInterpolation}
            >
              {t('MESH_SAVE_INTERPOLATION')}
            </MikeButton>

            <MikeButton variant="contained" color="primary" disabled={interpolateDisabled} active={interpolationInProgress} onClick={executeInterpolation}>
              {t('MESH_SAVE_AND_RUN_INTERPOLATION')}
            </MikeButton>
          </>
        )}
      </MikeStickyPanelBottomActions>
    </>
  );
};
