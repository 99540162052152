/** @jsxImportSource @emotion/react */
import { t } from '../../../translations/i18n';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { ViewerToolsButtonStyle } from '../../../workspaces/viewer/tools/viewer-tools-styles';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';;
import { store } from '../../../store/store';
import { EWorkspaceMeshActionType } from '../../../store/actions/WorkspaceMeshActionType';
import { useParams } from 'react-router-dom';

const { zoomIn } = MikeVisualizer;

/**
 * @name MmgViewerToolZoomIn
 * @summary Allows zooming in.
 */
export const MmgViewerToolZoomIn = () => {
  const {workspaceId } = useParams();
  const handleZoomIn = () => {
    zoomIn();
    store.dispatch({ type: EWorkspaceMeshActionType.CHECK_VIEW_BOUNDS, data: {workspaceId, wait: 1000}})
  }
  return (
    <Tooltip title={t('TOOL_ZOOM_IN')} placement="right">
      <button css={ViewerToolsButtonStyle} onClick={handleZoomIn}>
        <AddIcon />
      </button>
    </Tooltip>
  );
};
