/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import WorkspaceQuerySelectors from '../../store/selectors/WorkspaceQuerySelectors';
import EyeVisible from '../../icons/EyeVisible.svg?react';
import EyeHidden from '../../icons/EyeHidden.svg?react';
import { useSelector } from 'react-redux';
import { IOperationMetadata } from '../../models/IOperations';
import { isLayerFailed, isLayerProcessing, isLayerScheduled } from '../../shared/layers/layer-utils';
import CircularProgress from '@mui/material/CircularProgress';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { IGlobalState } from '../../store/reducers';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

export const LAYER_ICON_SIZE = '30px';
export const LAYER_HEIGHT = '40px';

const FlexContainerStyle = css`
  display: flex;  
  align-items: center;  
`;

export const LayerStyle = css`
  display: flex;
  margin-left: 0;
  width: 100%;
  height: ${LAYER_HEIGHT};
  overflow: hidden;
  background-color: white;
  align-items: center;
  justify-content: space-between;
`;

export const LayerNameAndDescriptionStyle = css`
  display: flex;
  flex-direction: column;
  text-align: left;
  min-width: 0;
  width: 100%;
  margin-left: 4px;
`;

export const LayerNameStyle = css`
  display: inline;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
  padding: 0 ${mikeSharedTheme.spacing(1)} 0 0;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const LayerDescriptionStyle = css`
  font-size: 0.75rem; /* 12px */
  line-height: 1rem;
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const LayerContentStyle = css`
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_LIGHT};
`;

export const LayerIconStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 30px;
  height: ${LAYER_ICON_SIZE};
  width: ${LAYER_ICON_SIZE};
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};

  &:focus {
    outline: 0;
  }
`;

export const LayerButtonStyle = css`
  background: 0;
  border: 0;
  padding: 0;
`;

export const LayerWithoutBorderStyle = css`
  border-bottom: none;
`;

const LayerRightActionsContainerStyle = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
`;

const LayerLeftActionsContainerStyle = css`
  position: relative;
`;

const LayerHiddenStyle = css`
  opacity: 0.5;
`;

const LayerTransparentStyle = css`
  opacity: 0;
`;

const LayerDisabledStyle = css`
  opacity: 0.5;
  pointer-events: none;
`;

export interface ILayerProps {
  leftIcon?: React.ReactNode;
  leftComplications?: React.ReactNode;
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  layerName: string; // | React.ReactNode;
  layerDescription?: string | React.ReactNode;
  layerId: string;
  /*  onLayerNamePressed?: (layerId: string) => void;*/
  onLayerMouseEnter?: (layerId: string) => void;
  onLayerMouseLeave?: (layerId: string) => void;
  onLayerHide?: (layerId: string) => void;
  onLayerShow?: (layerId: string) => void;
  cssProp?: any;
  showTooltip?: boolean;
  isParentHidden?: boolean;
  showVisibilityIcon: boolean;
}

export const PROGRESS_SIZE = 24;

/**
 * @name MikeLayer
 * @summary A generic layer that can display text and an icon.
 * Customizable with various left and right actions.
 *
 * @param props
 */
export function SpatialSelectionLayer(props: ILayerProps) {
  const shownSelectionIds = useSelector((state: IGlobalState) => state.WorkspaceQueryReducer.shownSelectionIds);

  const {
    layerId,
    layerName,
    layerDescription,
    leftIcon,
    leftActions,
    leftComplications,
    rightActions,
    cssProp,
    showTooltip = false,
    isParentHidden,
    showVisibilityIcon,
  } = props;

  const getQueryOperationSelectorInstance = WorkspaceQuerySelectors.makeGetQueryOperation();

  const latestOperation: IOperationMetadata | null = useSelector((state: IGlobalState) =>
    getQueryOperationSelectorInstance(state, {
      queryId: layerId,
    }),
  );

  const { working, icon } = useMemo(
    () => {     
      const isWorking = isLayerProcessing(latestOperation) || isLayerScheduled(latestOperation);
      const isFailed = isLayerFailed(latestOperation);
      return {
        working: isWorking,
        icon: isWorking ? (
          <CircularProgress style={{ width: PROGRESS_SIZE, height: PROGRESS_SIZE }} color="secondary" />
        ) : isFailed ? (
          <WarningRoundedIcon style={{ width: 30, height: 30 }} />
        ) : (
          leftIcon
        ),
      };
    },
    [latestOperation, leftIcon],
  );

  const [isHovering, setIsHovering] = useState(false);

  const isLayerFaded = useMemo(
    () => {
      return !shownSelectionIds.includes(layerId);
    },
    [layerId, shownSelectionIds],
  );

  const handleLayerMouseEnter = () => () => {
    setIsHovering(true);
  };

  const handleLayerMouseLeave = () => () => {
    setIsHovering(false);
  };

  const onVisibleIconClick = (id: string) => {
    if (props.onLayerHide) {
      props.onLayerHide(id);
    }
  };

  const onNotVisibleIconClick = (id: string) => {
    if (props.onLayerShow) {
      props.onLayerShow(id);
    }
  };

  const hidden = useMemo(
    () => {
      return isParentHidden || isLayerFaded;
    },
    [isLayerFaded, isParentHidden],
  );

  return (
    <div
      css={css`${LayerStyle} ${cssProp && cssProp}`}
      onMouseEnter={handleLayerMouseEnter()}
      onMouseLeave={handleLayerMouseLeave()}
      id="layer"
    >
      <div css={FlexContainerStyle}>
        {leftActions && <span css={LayerLeftActionsContainerStyle}>{leftActions}</span>}

        {icon &&
          !leftActions && (
            <button
              css={css`${LayerButtonStyle};
                ${LayerIconStyle};
                ${hidden && LayerHiddenStyle};
                ${isParentHidden && LayerDisabledStyle};`}
            >
              {icon}
              {leftComplications}
            </button>
          )}
        {icon &&
          leftActions && (
            <span
            css={css`${LayerButtonStyle};
            ${LayerIconStyle};
            ${hidden && LayerHiddenStyle};
            ${isParentHidden && LayerDisabledStyle};`}
            >
              {icon}
              {leftComplications}
            </span>
          )}

        <button>
          <div
            css={css`${LayerNameAndDescriptionStyle};
              ${hidden && LayerHiddenStyle};
                ${isParentHidden && LayerDisabledStyle};`}
          >
            {showTooltip ? (
              <Tooltip title={layerName}>
                <div css={LayerNameStyle}>{layerName}</div>
              </Tooltip>
            ) : (
              <div
                css={css`${LayerNameStyle};
                ${hidden && LayerHiddenStyle};
                  ${isParentHidden && LayerDisabledStyle};`}
              >
                {layerName}
              </div>
            )}
            <div css={LayerDescriptionStyle}>{layerDescription}</div>
          </div>
        </button>
      </div>
      <div css={FlexContainerStyle}>
      {!working &&
        showVisibilityIcon &&
        isLayerFaded && (
          <span
            onClick={() => {
              onNotVisibleIconClick(layerId);
            }}
            css={css`${LayerRightActionsContainerStyle};
            ${hidden ? (isHovering ? LayerHiddenStyle : LayerTransparentStyle) : ''};
            ${isParentHidden && LayerDisabledStyle};`}
          >
            <EyeHidden />
          </span>
        )}
      {!working &&
        !isLayerFaded &&
        showVisibilityIcon && (
          <span
            onClick={() => {
              onVisibleIconClick(layerId);
            }}
            css={css`${LayerRightActionsContainerStyle}; ${!isHovering ? LayerTransparentStyle : ''};`}
          >
            <EyeVisible />
          </span>
        )}
      <span css={LayerRightActionsContainerStyle}>{rightActions}</span>
      </div>
    </div>
  );
}
