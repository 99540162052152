/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useMemo, useState } from 'react';
import {
  LayerNameAndDescriptionStyle,
  LayerNameStyle,
  LayerDescriptionStyle,
  LayerIconStyle,
  LayerButtonStyle,
  LayerStyle,
} from './layer-styles';
import { Tooltip } from '@mui/material';
import EyeVisible from '../../icons/EyeVisible.svg?react';
import EyeHidden from '../../icons/EyeHidden.svg?react';
import { mikePalette } from '../../shared/styles/mikeSharedTheme';
import { useSelector } from 'react-redux';
import { getHiddenElements } from '../../store/selectors/WorkspaceSelectors';

const EyeballStyle = (hidden: boolean) => {
  return css`
    path {
      fill: ${hidden ? "none" : mikePalette.primary.main};      
    };
    line {      
      stroke: ${hidden ? "none" : mikePalette.primary.main};
    };
  `
}
  
const FlexContainerStyle = css`
  display: flex;
  align-items: center;
`;

const LayerRightActionsContainerStyle = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
`;

const LayerLeftActionsContainerStyle = css`
  position: relative;
`;

const LayerHiddenStyle = css`
  opacity: 0.5;
`;

export interface ILayerProps {
  leftIcon?: React.ReactNode;
  leftComplications?: React.ReactNode;
  topComplications?: React.ReactNode;
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  layerName: string | React.ReactNode;
  layerDescription?: string | React.ReactNode;
  layerId: string;
  // isLayerFaded?: boolean;
  onLayerNamePressed?: (layerId: string) => void;
  onLayerMouseEnter?: (layerId: string) => void;
  onLayerMouseLeave?: (layerId: string) => void;
  onLayerHide?: (layerId: string) => void;
  onLayerShow?: (layerId: string) => void;
  handleShowVisibilityIcon?: (show: boolean) => void; 
  showTooltip?: boolean;
  showVisibilityIcon?: boolean;
  hovering?: boolean;
  cssProp?: any
}

/**
 * @name MikeLayer
 * @summary A generic layer that can display text and an icon.
 * Customizable with various left and right actions.
 *
 * @param props
 */
export function MikeLayer(props: any) {
  const {
    layerId,
    layerName,
    layerDescription,
    leftIcon,
    leftActions,
    leftComplications,
    rightActions,
    // isLayerFaded,
    cssProp,
    showTooltip = false,
    showVisibilityIcon,
 /*    hovering, */
    topComplications
  } = props; 

  const hiddenIds = useSelector(getHiddenElements);

  const isLayerHidden = useMemo(() => {
    return layerId && hiddenIds && hiddenIds.includes(layerId)
  },[hiddenIds, layerId])

  const [isHovering, setIsHovering] = useState(false);
  const [isLayerFaded, setIsLayerFaded] = useState(isLayerHidden);

  const onLayerMouseEnter = (id: string) => () => {    
    setIsHovering(true) 
    if (props.onLayerMouseEnter) {
      props.onLayerMouseEnter(id);
    }
  };

  const onLayerMouseLeave = (id: string) => () => {  
    setIsHovering(false)
    if (props.onLayerMouseLeave) {
      props.onLayerMouseLeave(id);
    }
  };

  const onLayerNamePressed = (id: string) => () => {
    if (props.onLayerNamePressed) {
      props.onLayerNamePressed(id);
    }
  };

  const onVisibleIconClick = (id: string) => {
    setIsLayerFaded(false)
    if(props.handleShowVisibilityIcon){
      props.handleShowVisibilityIcon(false)
    }
    if(props.onLayerHide){
      props.onLayerHide(id)
    }
  }

  const onNotVisibleIconClick = (id: string) => {
    setIsLayerFaded(true)
    if(props.handleShowVisibilityIcon){
      props.handleShowVisibilityIcon(true)
    }
    if(props.onLayerShow){
      props.onLayerShow(id)
    }
  }

  

  return (
    <div
      css={css`${LayerStyle} ${cssProp && cssProp}`}
      onMouseEnter={onLayerMouseEnter(layerId) /* () => setHiddenByHovering(true) */}
      onMouseLeave={onLayerMouseLeave(layerId) /*() => setHiddenByHovering(false)  */}
      id="layer"
    >
      <div css={FlexContainerStyle}>

      
      {leftActions && <span css={LayerLeftActionsContainerStyle}>{leftActions}</span>}

      {leftIcon && !leftActions && (
        <button
          css={css`${LayerButtonStyle}; ${LayerIconStyle}; ${isLayerFaded && LayerHiddenStyle};`}
        >
          {leftIcon}
          {leftComplications}
          {topComplications ? topComplications : null}
        </button>
      )}
      {leftIcon && leftActions && (
        <span
          css={css`${LayerButtonStyle}; ${LayerIconStyle}; ${isLayerFaded && LayerHiddenStyle};`}
        >
          {leftIcon}
          {leftComplications}
          {topComplications ? topComplications : null}
        </span>
      )}

      <button onClick={onLayerNamePressed(layerId)}>
        <div css={css`${LayerNameAndDescriptionStyle} ${isLayerFaded && LayerHiddenStyle}`}>
          {showTooltip ? (
            <Tooltip title={layerName ? layerName : ""}>
              <div css={LayerNameStyle}>{layerName}</div>
            </Tooltip>
          ) : (
            <div css={LayerNameStyle}>{layerName}</div>
          )}
          <div css={LayerDescriptionStyle}>{layerDescription}</div>
        </div>
      </button>
      </div>
      <div css={FlexContainerStyle}>
        {showVisibilityIcon && 
        <div onClick={isLayerFaded ? () => onNotVisibleIconClick(layerId) : () => onVisibleIconClick(layerId)} 
        css={LayerRightActionsContainerStyle}>{isLayerFaded ? <EyeHidden css={EyeballStyle(!isHovering)}/> : <EyeVisible css={EyeballStyle(!isHovering)}/>} </div>}       
        <div css={LayerRightActionsContainerStyle}>{rightActions}</div>
      </div>      
    </div>
  );
}
