/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const LayerNonHoverStyle = css`
  transition: none;
  border-bottom: 0;

  :hover {
    transition: none;
    background: 0;
    border: 0;
  }
`;
