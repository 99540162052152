/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Popover, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Info from '../../icons/Info.svg?react';
import mikeSharedTheme, { mikePalette } from '../styles/mikeSharedTheme';


const iconPrimaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main;
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `;
};

const iconSecondaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.secondary.light : mikePalette.secondary.main;
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `;
};

interface IProps {
  helpTexts?: string[];
  disabled?: boolean;
  primary?: boolean;
  content?: React.ReactNode;
}

const buttonStyle = (disabled: boolean) => {
  return css`
    cursor: ${disabled} ? 'default' : 'pointer';
  `;
} 

const popoverStyle = css`
  max-width: 75vw;
`;

const contentStyle = css`
  padding: ${mikeSharedTheme.spacing(2)};
`;

const ContextHelp = (props: IProps) => {
 
  const { helpTexts, content, disabled = false, primary = false } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {    
    !disabled && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'context-help' : undefined;

  return (
    <>
      <ButtonBase css={buttonStyle(disabled)} aria-describedby={id} onClick={handleClick}>
        <Info css={primary ? iconPrimaryStyle(disabled) : iconSecondaryStyle(disabled)} />
      </ButtonBase>
      <Popover
        css={popoverStyle}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div css={contentStyle}>
          {helpTexts
            ? helpTexts.map((helpText, index) => {
                return <Typography key={index}>{helpText}</Typography>;
              })
            : content}
        </div>
      </Popover>
    </>
  );
};

export default ContextHelp;
