/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import get from 'lodash/fp/get';
import capitalize from 'lodash/capitalize';
import toLower from 'lodash/fp/toLower';
import { Checkbox, TableCell } from '@mui/material';
import Folder from '../icons/Folder.svg?react';
import { IGetDataset } from '../models/IGetDataset';
import Alert from '../icons/Alert.svg?react';
import { t } from '../translations/i18n';
import { IGetProject } from '../shared-components/mike-project-explorer/model';
import { isDataset } from '../shared-components/mike-project-explorer/MikeProjectContentTable/support';
import LongText from '../shared-components/mike-project-explorer/renders/LongText';
import DateTime from '../shared-components/mike-project-explorer/renders/DateTime';
import { IColumn } from '../shared-components/TableComponents/support';

export const firstTableCellRender = (
  item: IGetProject | IGetDataset,
  isSelectable: boolean,
  isSelected: boolean,
  onClick: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, item: IGetProject | IGetDataset) => void,
) => {
  if (isDataset(item)) {
    if (isSelectable) {
      const dataset: IGetDataset = item as IGetDataset;
      const isWorkspace = Object.keys(dataset.usageTypes.includes(5));
      const hasSpatialInfo = dataset.spatialInformation && dataset.spatialInformation.srid;
      const enabled = isWorkspace || hasSpatialInfo;
      return (
        <TableCell padding="checkbox" onClick={(event) => enabled && onClick(event, item)}>
          <Checkbox disabled={!enabled} checked={isSelected} />
        </TableCell>
      );
    } else {
      return <TableCell padding="checkbox" />;
    }
  } else {
    return (
      <TableCell padding="checkbox">
        <Folder />
      </TableCell>
    );
  }
};

const noSRIDStyle = css`
  display: flex;
  align-items: center;
`;

const hoverStyle = (item: IGetProject | IGetDataset) => {
  const className =
    'datasetType' in item
      ? null
      : item.capabilities && item.capabilities.canListContent
        ? css`
            &:hover {
              cursor: pointer;
            }
          `
        : null;
  return className;
};

const getGeometryType = get('properties.geometryType');
const typeRender = (value: string, item: IGetProject | IGetDataset) => {
  const geometryType = getGeometryType(item);
  const type = value ? capitalize(value) + (geometryType ? ' / ' + capitalize(geometryType) : '') : 'Folder';
  return <LongText text={type} />;
};
const typeComparator = (item: IGetProject | IGetDataset) => {
  const geometryType = getGeometryType(item);
  const type =
    'datasetType' in item
      ? geometryType
        ? toLower(item.datasetType) + ' / ' + toLower(geometryType)
        : toLower(item.datasetType)
      : 'zzzz';
  return type;
};

const getSRID = get('spatialInformation.srid');
const projectionSystemRender = (_value: string, item: IGetProject | IGetDataset) => {
  const isDS = isDataset(item);
  const srid = isDS && getSRID(item);
  return !isDS ? (
    ''
  ) : srid ? (
    t('EPSG') + ' ' + srid
  ) : (
    <div css={noSRIDStyle}>
      <Alert />
      {t('NA')}
    </div>
  );
};

const projectionSystemComparator = (item: IGetProject | IGetDataset) => {
  const isDS = isDataset(item);
  const srid = isDS && getSRID(item);
  const ps = isDS ? (srid ? srid : 88888888) : 99999999;
  return ps;
};

const itemNameRender = (value: string) => <LongText text={value} />;
const nameComparator = (item: IGetProject | IGetDataset) => item.name.toLowerCase();

const dateTimeRender = (value: string) => <DateTime jsonTime={value} />;

export const projectContentColumns = [
  {
    field: 'name',
    label: t('PROJECT_EXPLORER_COLUMN_NAME'),
    render: itemNameRender,
    comparator: nameComparator,
    cssProp: hoverStyle,
  },
  {
    field: 'datasetType',
    label: t('PROJECT_EXPLORER_COLUMN_TYPE'),
    render: typeRender,
    comparator: typeComparator,
    cssProp: hoverStyle,
  },
  {
    field: 'spatialInformation',
    label: t('WORKSPACE_PROJECTION'),
    render: projectionSystemRender,
    comparator: projectionSystemComparator,
    cssProp: hoverStyle,
  },
  {
    field: 'updatedAt',
    label: t('PROJECT_EXPLORER_COLUMN_UPDATED_AT'),
    render: dateTimeRender,
    cssProp: hoverStyle,
  },
] as Array<IColumn>;
