import { t } from '../translations/i18n';
import { store } from '../store/store';
import { FeatureCollection } from 'geojson';
import TextField from '@mui/material/TextField';
import WorkspaceCommentsManager from '../managers/WorkspaceCommentsManager';
import { MmgConfirmContainer } from '../shared/confirm-containers/confirm-container';
import { useIsMounted } from '../shared/hooks/hooks';
import { EWorkspaceActionType } from '../store/actions/WorkspaceActionType';
import { EMapToolActionType } from '../store/actions/MapToolActionType';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import { MikeStickyPanelBottomActions } from '../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../shared-components/mike-button';

const { onDrawnDataUpdated, onDrawingInProgressChanged, clearDrawnVectorLayerData } = MikeVisualizerLib;

/**
 * @name MmgCommentConfirm Allows submitting (confirming) a comment related to a point (coordinate).
 * @summary Grabs the point and submits it as a comment.
 *
 */
export function MmgConnectedCommentAddConfirm() {
  const [message, setMessage] = useState('');
  const [featureCollection, setFeatureCollection] = useState({} as FeatureCollection<any, any>);
  const [createCommentFailed, setCreateCommentFailed] = useState(false);
  const [createCommentWorking, setCreateCommentWorking] = useState(false);
  const [drawingInProgress, setDrawingInProgress] = useState(false);
  const isMounted = useIsMounted();
  const { workspaceId } = useParams();

  const selectedPoint = featureCollection.features && featureCollection.features.length;

  const handleCommentChange = (event) => {
    setMessage(event.target.value);
  };
  const clearFeatures = () => setFeatureCollection({} as FeatureCollection<any, any>);

  /**
   * Callback for when drawn data has updated.
   * Updates the local reference to the feature collection so it can be eventually sent to the API.
   *
   * @param features
   */
  const drawUpdateCallback = (features: FeatureCollection<any, any>) => {
    setFeatureCollection(features);
  };

  /**
   * Callback for when drawing in progress changes.
   *
   * @param inProgress
   */
  const drawingProgressChangedCallback = (inProgress: boolean) => {
    setDrawingInProgress(inProgress);
  };

  /**
   * Confirms the comment and creates it.
   */
  const confirmComment = () => {
    setCreateCommentWorking(true);
    setCreateCommentFailed(false);
    const nonEmptyFeatures = featureCollection.features.filter((feature) => feature.geometry);
    const pointFeatures = nonEmptyFeatures.filter((feature) => feature.geometry.type === 'Point');

    // we only allow one point attached to a comment. Take the first in case there are more
    const pointCoordinates = pointFeatures[0].geometry.coordinates;

    // NB: the toast is dispatched before the request is accepted. This gives an arguably better UX because there isn't a pause of a few seconds between finishing drawing and seeing that something happened.
    store.dispatch({
      type: 'toast/ADD/SUCCESS',
      toast: {
        text: t('COMMENT_SUBMITTED_SUCCESSFULLY'),
      },
    });

    WorkspaceCommentsManager.createComment(workspaceId, message, [...pointCoordinates, 0])
      .then((item) => {
        store.dispatch({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });      
        clearDrawnVectorLayerData();
        if (isMounted()) clearFeatures();
        store.dispatch({
          type: 'comments/ADD',
          comment: item,
        });
        store.dispatch({ type: 'maptools/DISABLE_ADD_COMMENT' });  
      })
      .catch((error) => {
        if (isMounted()) {
          setCreateCommentFailed(true);
        }
        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: {
            text: t('COMMENT_SUBMIT_FAILED'),
          },
        });
        throw error;
      })
      .finally(() => {
        if (isMounted()) {
          setCreateCommentWorking(false);
        }
      });
  };

  /**
   * Cancels comment and removes the drawn item.
   */
  const cancelComment = () => {
    store.dispatch({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
    store.dispatch({ type: 'maptools/DISABLE_ADD_COMMENT' });
    store.dispatch({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
    clearDrawnVectorLayerData();
    if (isMounted()) clearFeatures();
  };

  useEffect(() => {
    const unsubscribers = [];
    const unsubscribeOnDrawnDataUpdated = onDrawnDataUpdated(drawUpdateCallback);
    const unsubscribeOnDrawingInProgressChanged = onDrawingInProgressChanged(drawingProgressChangedCallback);
    clearFeatures();
    unsubscribers.push(unsubscribeOnDrawingInProgressChanged, unsubscribeOnDrawnDataUpdated);
    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  useEffect(() => {
    store.dispatch({
      type: 'maptools/ALLOW/ADD_COMMENT',
    });
    store.dispatch({
      type: 'comments/SHOW',
    });
    return () => {
      // on unmount we reset to default tools
      store.dispatch({
        type: EMapToolActionType.RESET_ALLOWED_TOOLS,
      });

      // and hide comments
      store.dispatch({
        type: 'comments/HIDE',
      });
    };
  }, []);

  return (
    <MmgConfirmContainer
      headerTitle={t('COMMENT_ADD_CONFIRMATION_TITLE')}
      helpText={t('COMMENT_TIP')}
      showHelpText={!selectedPoint}
      buttons={
        <MikeStickyPanelBottomActions>
          <MikeButton variant="outlined" color="secondary" onClick={cancelComment} disabled={createCommentWorking}>
            {t('CANCEL')}
          </MikeButton>

          <MikeButton
            disabled={!message || !selectedPoint || createCommentWorking || drawingInProgress}
            onClick={confirmComment}
            active={createCommentWorking}
            variant="contained"
            color="secondary"
          >
            {createCommentFailed ? t('RETRY_ADD_COMMENT') : t('CONFIRM_ADD_COMMENT')}
          </MikeButton>
        </MikeStickyPanelBottomActions>
      }
    >
      <TextField
        id="message"
        label={`${t('COMMENT_MESSAGE_LABEL')}`}
        value={message}
        onChange={handleCommentChange}
        autoComplete="off"
        multiline={true}
        fullWidth={true}
        required={true}
      />
    </MmgConfirmContainer>
  );
}
