/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from '@emotion/react';
import React, { useCallback } from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { ILayerProps, MikeLayer } from './layer';
import { Checkbox } from '@mui/material';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';


const LayerErrorSelectTextStyle = css`
  color: ${MIKE_COLORS.PINK_DEFAULT};
  display: inline-block;
`;

const LayerErrorSelectNameStyle = css`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface ILayerErrorSelectProps extends ILayerProps {
  rightActions?: React.ReactNode;
  layerName: string;
  layerDescription?: string | React.ReactNode;
  layerId: string;
  isLayerFaded?: boolean;
  selected?: boolean;
  onLayerNamePressed?: (layerId: string) => void;
  onLayerSelectChanged?: (layerId: string, selected: boolean) => any;
  css?: Interpolation<Theme>;
  disabled?: boolean;
  leftIcon?: any;
}

/**
 * @name MikeLayerError
 * @summary A layer that errored out.
 *
 * @param props
 */
export function MikeLayerErrorSelect(props: ILayerErrorSelectProps) {
  const {
    rightActions,
    layerId,
    layerName,
    layerDescription,
    isLayerFaded,
    css: cssProp,
    disabled,
    selected,
    leftIcon,
    onLayerSelectChanged: onLayerSelectChangedProps = () => null,
    ...rest
  } = props;

  const checkboxStyle = leftIcon
    ? {
        marginRight:
        mikeSharedTheme.spacing(-1) /* Cancels out icon margin. TODO dan: should use theme variables in shared and then update this component (spacing) */,
      }
    : {};

  const onLayerSelectChanged = useCallback(
    (lId: string, isSelected: boolean) => {
      if (!disabled) {
        onLayerSelectChangedProps(lId, isSelected);
      }
    },
    [disabled, onLayerSelectChangedProps]
  );

  const onLayerCheckboxStateChanged = useCallback(
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        onLayerSelectChanged(id, event.target.checked);
      }
    },
    [disabled, onLayerSelectChanged]
  );

  return (
    <MikeLayer
      layerId={layerId}
      layerName={
        <span css={[LayerErrorSelectTextStyle, LayerErrorSelectNameStyle]}>
          {layerName}
        </span>
      }
      layerDescription={<div css={LayerErrorSelectTextStyle}>{layerDescription}</div>}
      leftIcon={<WarningRoundedIcon style={{ width: 30, height: 30 }} color="error" />}
      onLayerNamePressed={props.onLayerNamePressed}
      rightActions={rightActions}
      isLayerFaded={isLayerFaded}
      className={cssProp}
      leftActions={
        <Checkbox
          disabled={disabled}
          checked={selected}
          onChange={onLayerCheckboxStateChanged(props.layerId)}
          style={checkboxStyle}
        />
      }
      {...rest}
    />
  );
}
