import { IOperationConfiguration } from '../../../models/IOperations';
import { MmgOperationConfigurationSummary } from '../../../operations/operation-configuration-summary';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { getVariableParametersValueStrings } from '../mesh-configuration-util';
import { MESH_OPERATION_PARAMETER_CONDITIONS } from '../mesh-configuration-constants';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { t } from '../../../translations/i18n';

type MeshOperationQuerySummaryProps = {
  query: IWorkspaceQuery;
  operationConfiguration: IOperationConfiguration;
  workspaceVariables?: Array<IWorkspaceEnrichedVariable>;
};

/**
 * @name MmgMeshOperationQuerySummary
 * @param props
 *
 * @summary A summary a mesh child query operation.
 */
export const MmgMeshOperationQuerySummary = (props: MeshOperationQuerySummaryProps) => {
  const { operationConfiguration, workspaceVariables, query } = props;
  const variableValueStrings = getVariableParametersValueStrings(operationConfiguration, workspaceVariables);

  return (
    <MmgOperationConfigurationSummary
      inputItems={[query]}
      showInputItems={true}
      inputItemsLabel={t('MESH_OPERATION_GEOMETRY_QUERY_INPUT')}
      operationConfiguration={operationConfiguration}
      customValueStrings={variableValueStrings}
      parameterConditionDescriptions={MESH_OPERATION_PARAMETER_CONDITIONS}
    />
  );
};
