import EventEmitter from 'tiny-emitter/instance'
import { FeatureCollection } from 'geojson';

import MikeVisualizerStore from './store/MikeVisualizerStore';
import { IFeatureProperties } from './models/IFeatureProperties';
import { IIndexChanged } from './models/IIndexChanged';
import { IInteractionEvent } from './models/IInteractionEvent';

const { getState, setState } = MikeVisualizerStore;

/**
 * Available viewer events.
 */
export const EVENTS = {
  MOUSE_OR_TOUCH_INTERACTION_ENDED: 'BoundsChanged',
  POINT_ID_CHANGED: 'PointIdChanged',
  CELL_ID_CHANGED: 'CellIdChanged',
  MOUSE_MOVE: 'MouseMove',
  PRESS: 'Press',
  ALLWORK_ENDED: 'AllWorkEnded',
  WORK_STARTED: 'WorkStarted',
  WORK_ENDED: 'WorkEnded',
  DRAWN_DATA_UPDATED: 'DrawnDataUpdated',
  DRAWING_IN_PROGRESS_CHANGED: 'DrawingInProgressChanged',
  DRAW_MAP_INSPECTION_SELECTED_FEATURE_PROPERTIES_CHANGED:
    'DrawnDataInspectionSelectedFeaturePropertiesChanged',
  BASE_MAP_LAYER_CHANGED: 'BaseMapLayerChanged',
  BASE_MAP_DESTROYED: 'BaseMapDestroyed',
  BASE_MAP_PROJECTION_SETUP_FAILED: 'BaseMapProjectionSetupFailed',
  BASE_MAP_PROJECTION_FETCH_FAILED: 'BaseMapProjectionFetchFailed',
};

/**
 * All events are emitted, registered and unregistered through the event emitter.
 */
// const EventEmitter = new TinyEmitter();

/**
 * All registered callbacks are kept in this object.
 * The object should never be exposed to / mutated by any other module, it is for internal purposes only.
 * The methods contained can and should be accessed via `getEmitters`.
 */
const emitters = {
  emitAllWorkEnded: () => EventEmitter.emit(EVENTS.ALLWORK_ENDED),
  emitWorkStarted: (workItemId: string) => EventEmitter.emit(EVENTS.WORK_STARTED, workItemId),
  emitWorkEnded: (workItemId: string) => EventEmitter.emit(EVENTS.WORK_ENDED, workItemId),
  mouseOrTouchInteractionEnded: (callData: IInteractionEvent) => EventEmitter.emit(EVENTS.MOUSE_OR_TOUCH_INTERACTION_ENDED, callData),
  emitMouseMove: (coordinates: Array<number>) => EventEmitter.emit(EVENTS.MOUSE_MOVE, coordinates),
  emitCellIdChanged: (cellIndex) => EventEmitter.emit(EVENTS.CELL_ID_CHANGED, cellIndex),
  emitPointIdChanged: (pointIndex) => EventEmitter.emit(EVENTS.POINT_ID_CHANGED, pointIndex),
  emitPress: (coordinates: Array<number>) => EventEmitter.emit(EVENTS.PRESS, coordinates),
  emitDrawingInProgressChanged: (isInProgress: boolean) =>
    EventEmitter.emit(EVENTS.DRAWING_IN_PROGRESS_CHANGED, isInProgress),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emitDrawnDataUpdated: (featureCollection: FeatureCollection<any, any>) =>
    EventEmitter.emit(EVENTS.DRAWN_DATA_UPDATED, featureCollection),
  emitDrawMapInspectionChanged: (inspectionSelectedFeatureProperties: Array<IFeatureProperties>) =>
    EventEmitter.emit(
      EVENTS.DRAW_MAP_INSPECTION_SELECTED_FEATURE_PROPERTIES_CHANGED,
      inspectionSelectedFeatureProperties
    ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emitBaseMapLayerChanged: (baseMap: any) =>
    EventEmitter.emit(EVENTS.BASE_MAP_LAYER_CHANGED, baseMap),
  emitBaseMapDestroyed: () => EventEmitter.emit(EVENTS.BASE_MAP_DESTROYED),
  emitBaseMapProjectionNotSupported: () =>
    EventEmitter.emit(EVENTS.BASE_MAP_PROJECTION_SETUP_FAILED),
  emitBaseMapProjectionFetchFailed: (error: Error) =>
    EventEmitter.emit(EVENTS.BASE_MAP_PROJECTION_FETCH_FAILED, error),
};

/**
 * Allows setting callbacks for MikeVisualizer events, such as drawing, interaction start / end, etc.
 * Allows MikeVisualizer modules to emit events, adding safety checks for calls, i.e. looking for existing items before going through with the callback.
 *
 * @module MikeVisualizerEvents
 * @version 2.0.0
 */

/**
 * Registers a 'all work ended' callback.
 * Callback is skipped if there are still some workItems pending.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onAllWorkEnded = (passedCallback: () => any): (() => void) => {
  const callback = () => {
    const { workingItems } = getState();

    if (!workingItems.length) {
      if (passedCallback) {
        passedCallback();
        return true;
      }
    }

    return false;
  };

  EventEmitter.on(EVENTS.ALLWORK_ENDED, callback);
  return () => EventEmitter.off(EVENTS.ALLWORK_ENDED, callback);
};

/**
 * Registers a 'work start' callback, registering the working item to state.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onWorkStarted = (passedCallback: (workItemId: string) => any): (() => void) => {
  /**
   * @param workItemId The item to mark as 'working'. Duplicates allowed.
   */
  const callback = (workItemId: string) => {
    const { workingItems } = getState();

    setState({ workingItems: [...workingItems, workItemId] });

    if (passedCallback) {
      passedCallback(workItemId);
      return true;
    }

    return false;
  };

  EventEmitter.on(EVENTS.WORK_STARTED, callback);
  return () => EventEmitter.off(EVENTS.WORK_STARTED, callback);
};

/**
 * Registers a 'work end' callback, unregistering the working item to state.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onWorkEnded = (passedCallback: (workItemId: string) => any): (() => void) => {
  /**
   * @param workItemId The item to mark as 'working'. Duplicates allowed.
   */
  const callback = (workItemId: string) => {
    if (passedCallback) {
      passedCallback(workItemId);
      return true;
    }

    return false;
  };

  EventEmitter.on(EVENTS.WORK_ENDED, callback);
  return () => EventEmitter.off(EVENTS.WORK_ENDED, callback);
};

/**
 * Registers a 'mouse move' callback.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onMouseMove = (passedCallback: (coordinates: Array<number>) => any): (() => void) => {
  const callback = passedCallback;
  EventEmitter.on(EVENTS.MOUSE_MOVE, callback);
  return () => EventEmitter.off(EVENTS.MOUSE_MOVE, callback);
};

/**
 * Registers a 'bounds changed' callback.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onBoundsChanged = (passedCallback: (bounds: Array<Array<number>>) => any): (() => void) => {
  const callback = passedCallback;
  EventEmitter.on(EVENTS.MOUSE_OR_TOUCH_INTERACTION_ENDED, callback);
  return () => EventEmitter.off(EVENTS.MOUSE_OR_TOUCH_INTERACTION_ENDED, callback);
};

/**
 * Registers a 'mouse move' callback.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onCellIndexChanged = (passedCallback: (cellIndexChanged: IIndexChanged) => any): (() => void) => {
  const callback = passedCallback;
  EventEmitter.on(EVENTS.CELL_ID_CHANGED, callback);
  return () => EventEmitter.off(EVENTS.CELL_ID_CHANGED, callback);
};

/**
 * Registers a 'mouse move' callback.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onPointIndexChanged = (passedCallback: (cellIndexChanged: IIndexChanged) => any): (() => void) => {
  const callback = passedCallback;
  EventEmitter.on(EVENTS.POINT_ID_CHANGED, callback);
  return () => EventEmitter.off(EVENTS.POINT_ID_CHANGED, callback);
};

/**
 * Registers a 'press' callback.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onPress = (passedCallback: (coordinates: Array<number>) => any): (() => void) => {
  const callback = passedCallback;
  EventEmitter.on(EVENTS.PRESS, callback);
  return () => EventEmitter.off(EVENTS.PRESS, callback);
};

/**
 * Registers a callback for when 'drawing in progress' is toggled.
 * Note: This does not have to do with the drawn data, but only triggers a callback with a boolean to emit the state of drawing (in progress or not).
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
export const onDrawingInProgressChanged = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passedCallback: (inProgress: boolean) => any
): (() => void) => {
  const callback = (inProgress: boolean) => {
    if (passedCallback) {
      passedCallback(inProgress);
      return true;
    }

    return false;
  };

  EventEmitter.on(EVENTS.DRAWING_IN_PROGRESS_CHANGED, callback);
  return () => EventEmitter.off(EVENTS.DRAWING_IN_PROGRESS_CHANGED, callback);
};

/**
 * Registers a 'draw updated' callback. The result data is passed in the callback.
 * The contained feature collection might be a combination of polygons, polylines or points.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
export const onDrawnDataUpdated = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passedCallback: (featureCollection: FeatureCollection<any, any>) => any
): (() => void) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callback = (pointCollection: FeatureCollection<any, any>) => {
    if (passedCallback) {
      passedCallback(pointCollection);
      return true;
    }

    return false;
  };

  EventEmitter.on(EVENTS.DRAWN_DATA_UPDATED, callback);
  return () => EventEmitter.off(EVENTS.DRAWN_DATA_UPDATED, callback);
};

/**
 * Registers a callback for when the information about drawn data inspection selected feature properties have changed.
 * That is, when any feature is selected and/or deselected during inspection, this callback will fire.
 * Data emitted here can be used to show selection properties.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
export const onDrawMapInspectionChanged = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passedCallback: (inspectionSelectedFeatureProperties: Array<IFeatureProperties>) => any
): (() => void) => {
  const callback = (inspectionSelectedFeatureProperties: Array<IFeatureProperties>) => {
    if (passedCallback) {
      passedCallback(inspectionSelectedFeatureProperties);
      return true;
    }

    return false;
  };

  EventEmitter.on(EVENTS.DRAW_MAP_INSPECTION_SELECTED_FEATURE_PROPERTIES_CHANGED, callback);
  return () =>
    EventEmitter.off(EVENTS.DRAW_MAP_INSPECTION_SELECTED_FEATURE_PROPERTIES_CHANGED, callback);
};

/**
 * Registers a 'basemap change' callback. Currently it is only possible to register a single callback
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onBaseMapChanged = (passedCallback: (baseMap: any) => any): (() => void) => {
  const callback = () => {
    const { baseMap } = getState();

    if (passedCallback) {
      passedCallback(baseMap);
    }
  };

  EventEmitter.on(EVENTS.BASE_MAP_LAYER_CHANGED, callback);
  return () => EventEmitter.off(EVENTS.BASE_MAP_LAYER_CHANGED, callback);
};

/**
 * Registers a 'basemap destroy' callback. Currently it is only possible to register a single callback
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onBaseMapDestroyed = (passedCallback: () => any): (() => void) => {
  const callback = () => {
    if (passedCallback) {
      passedCallback();
    }
  };

  EventEmitter.on(EVENTS.BASE_MAP_DESTROYED, callback);
  return () => EventEmitter.off(EVENTS.BASE_MAP_DESTROYED, callback);
};

/**
 * Registers a 'projection not supported' callback.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onBaseMapProjectionNotSupported = (passedCallback: () => any): (() => void) => {
  const callback = passedCallback;
  EventEmitter.on(EVENTS.BASE_MAP_PROJECTION_SETUP_FAILED, callback);
  return () => EventEmitter.off(EVENTS.BASE_MAP_PROJECTION_SETUP_FAILED, callback);
};

/**
 * Registers a 'projection fetch failed' callback.
 *
 * @param passedCallback
 * @returns Unsubscribe function that can be called to unregister the event callback.
 *
 * @public
 */
export const onBaseMapProjectionFetchFailed = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passedCallback: (error: Error) => any
): (() => void) => {
  const callback = passedCallback;
  EventEmitter.on(EVENTS.BASE_MAP_PROJECTION_FETCH_FAILED, callback);
  return () => EventEmitter.off(EVENTS.BASE_MAP_PROJECTION_FETCH_FAILED, callback);
};

/**
 * Gets the object with all available emitters.
 */
export const getEmitters = () => emitters;

/**
 * Get the event emitter.
 * Meant for internal use i.e. cleanup.
 *
 * @private
 */
export const _getEventEmitter = () => EventEmitter;

/**
 * By default, register a work ended event callback that updates state.
 * This is needed for state to reflect actual work, even without any external event listeners.
 *
 * @internal
 */
onWorkEnded((workItemId) => {
  try {
    const { workingItems } = getState();
    const indexOfFirstWorkItemMatch = workingItems.indexOf(workItemId);

    // Remove work items one by one. There might be multiple work item registrations for a given id.
    setState({
      workingItems: [
        ...workingItems.slice(0, indexOfFirstWorkItemMatch),
        ...workingItems.slice(indexOfFirstWorkItemMatch + 1),
      ],
    });

    // Try to call all work ended after each work item end. `onAllWorkEnded` will handle this accordingly.
    emitters.emitAllWorkEnded();
  } catch (error) {
    console.error('Failed to handle work end internally', error);
  }
});
