/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash-es';

import { t } from '../../../translations/i18n';
import { usePrevious } from '../../../shared/hooks/hooks';

import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { IOperationConfiguration } from '../../../models/IOperations';

import { CircularProgress, Switch } from '@mui/material';
import { MmgOperationConfiguration } from '../../../operations/operation-configuration';

import { MmgGroup } from '../../../shared/groups/group';
import { MmgPanelSubsection } from '../../../shared/panels/panel-subsection';
import { IOperationDescription, IContextualOperationDescriptions } from '../../../models/IOperationDescriptions';
import { IWorkspaceAttribute } from '../../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../../models/IWorkspaceAttributeSettings';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { anythingToConfigure } from '../../../operations/operation-utils';
import { MmgMeshInterpolationVariablesConfigurations } from './mesh-interpolation-variables-configurations';
import {
  INTERPOLATION_TYPE_OTHER,
  INTERPOLATION_TYPE_ELEVATION,
} from '../../interpolation/summary/mesh-interpolations-summary';

const toggleTextStyle = css`
  margin-left: 5px;
  font-size: 14px;
`;

type MeshInterpolationConfigurationProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
  variables: Array<IWorkspaceEnrichedVariable>;
  initialMeshInterpolationsConfiguration?: IOperationConfiguration;
  basicMeshInterpolationDescription: IOperationDescription;
  contextualChildOperationDescriptions: IContextualOperationDescriptions;
  meshAttributes?: Array<IWorkspaceAttribute>;
  meshAttributeSettings?: Array<IWorkspaceAttributeSettings>;
  meshQueries?: Array<IWorkspaceQuery>;
  onMeshInterpolationConfigurationChanged: (operationConfiguration: IOperationConfiguration) => void;
  interpolationType?: string;
  mappedPostOperationContextualOperationDescriptions?: IOperationDescription;
};

/**
 * @name MmgMeshInterpolationsConfiguration
 * @summary Allows configuring both 'basic'/base mesh interpolations configuration as well as child operations for each variable.
 *
 * @param props
 */
export const MmgMeshInterpolationConfiguration = (props: MeshInterpolationConfigurationProps) => {
  const {
    projectId,
    workspaceId,
    meshId,
    variables,
    initialMeshInterpolationsConfiguration,
    basicMeshInterpolationDescription,
    contextualChildOperationDescriptions,
    meshAttributes,
    meshAttributeSettings,
    meshQueries,
    onMeshInterpolationConfigurationChanged,
    interpolationType,
    mappedPostOperationContextualOperationDescriptions,
  } = props;

  const [basicConfiguration, setBasicConfiguration] = useState(initialMeshInterpolationsConfiguration);
  const [showAdditionalParameters, setShowAdditionalParameters] = useState(
    initialMeshInterpolationsConfiguration && initialMeshInterpolationsConfiguration.postOperation ? true : false,
  );

  const [childConfigurations, setChildConfigurations] = useState(
    (initialMeshInterpolationsConfiguration && initialMeshInterpolationsConfiguration.childOperations) || [],
  );

  const [postOpConfigurations, setPostOpConfigurations] = useState(
    (initialMeshInterpolationsConfiguration && initialMeshInterpolationsConfiguration.postOperation) || [],
  );

  const previousBasicConfiguration = usePrevious(basicConfiguration);
  const previousChildConfigurations = usePrevious(childConfigurations);
  const previousPostOpConfigurations = usePrevious(postOpConfigurations);

  /**
   * Callback for when child operations configurations have changed.
   *
   * @param childOperations
   */
  const onChildConfigurationsChanged = useCallback(
    (childOperations: Array<IOperationConfiguration>) => {
      setChildConfigurations(childOperations);
      onMeshInterpolationConfigurationChanged({
        ...basicConfiguration,
        childOperations,
      });
    },
    [basicConfiguration, onMeshInterpolationConfigurationChanged],
  );

  const onPostOperationConfigurationsChanged = useCallback(
    (postOperation: IOperationConfiguration) => {
      setPostOpConfigurations(postOperation);
      onMeshInterpolationConfigurationChanged({
        ...basicConfiguration,
        postOperation,
      });
    },
    [onMeshInterpolationConfigurationChanged, basicConfiguration],
  );

  /**
   * Callback for when basic (interpolate mesh operation) configuration has changed.
   *
   * @param operationConfiguration
   */
  const onBasicOperationConfigurationChanged = (operationConfiguration: IOperationConfiguration) => {
    setBasicConfiguration(operationConfiguration);
  };

  useEffect(
    () => {
      if (
        !isEqual(previousBasicConfiguration, basicConfiguration) ||
        !isEqual(previousChildConfigurations, childConfigurations) ||
        !isEqual(previousPostOpConfigurations, postOpConfigurations)
      ) {
        onMeshInterpolationConfigurationChanged({
          ...basicConfiguration,
          childOperations: childConfigurations,
          postOperation: postOpConfigurations as any,
        });
      }
    },
    [
      previousBasicConfiguration,
      previousChildConfigurations,
      basicConfiguration,
      childConfigurations,
      onMeshInterpolationConfigurationChanged,
      postOpConfigurations,
      previousPostOpConfigurations,
    ],
  );

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAdditionalParameters(event.target.checked);
  };

  const isDescriptionLoading = !basicMeshInterpolationDescription && !contextualChildOperationDescriptions;

  if (isDescriptionLoading) {
    return (
      <MmgPanelSubsection>
        <CircularProgress />
      </MmgPanelSubsection>
    );
  }

  return (
    <>
      {anythingToConfigure(basicMeshInterpolationDescription) &&
        interpolationType === INTERPOLATION_TYPE_OTHER && (
          <MmgGroup groupName={t('MESH_INTERPOLATION_BASIC_CONFIGURATION')} canBeHidden={false}>
            <MmgPanelSubsection>
              <MmgOperationConfiguration
                initialOperationConfiguration={initialMeshInterpolationsConfiguration}
                operationDescription={basicMeshInterpolationDescription}
                onConfigurationChanged={onBasicOperationConfigurationChanged}
              />
            </MmgPanelSubsection>
          </MmgGroup>
        )}
      <MmgPanelSubsection>
        {interpolationType === INTERPOLATION_TYPE_ELEVATION && (
          <>
            <Switch checked={showAdditionalParameters} onChange={handleToggleChange} name="additionalParameter" />
            <span css={toggleTextStyle}>{t('OPERATION_CFL_APPLY')}</span>
          </>
        )}
        {interpolationType === INTERPOLATION_TYPE_ELEVATION &&
          showAdditionalParameters && (
            <MmgOperationConfiguration
              initialOperationConfiguration={
                initialMeshInterpolationsConfiguration && initialMeshInterpolationsConfiguration.postOperation
              }
              operationDescription={mappedPostOperationContextualOperationDescriptions}
              onConfigurationChanged={onPostOperationConfigurationsChanged}
            />
          )}
      </MmgPanelSubsection>
      <MmgGroup groupName={t('MESH_INTERPOLATION_VARIABLES_CONFIGURATION')} canBeHidden={false}>
        <MmgMeshInterpolationVariablesConfigurations
          projectId={projectId}
          workspaceId={workspaceId}
          meshId={meshId}
          variables={variables}
          contextualOperationDescriptions={contextualChildOperationDescriptions}
          initialOperationConfigurations={
            (initialMeshInterpolationsConfiguration && initialMeshInterpolationsConfiguration.childOperations) || []
          }
          meshAttributes={meshAttributes}
          meshAttributeSettings={meshAttributeSettings}
          meshQueries={meshQueries}
          onConfigurationsChanged={onChildConfigurationsChanged}
        />
      </MmgGroup>
    </>
  );
};
