import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import WorkspaceVariablesManager from '../../managers/WorkspaceVariablesManager';

/**
 * Submits exporting of selected variables.
 * @param workspaceId
 * @param variableIds
 */
export const submitExportVariables = (workspaceId: string, variableIds: Array<string>): Promise<void> => {
  return (
    WorkspaceVariablesManager.exportVariables(workspaceId, variableIds)
      /* .then(() => {
      const toast = {
        text: t('EXPORT_VARIABLES_SUCCESSFUL'),
      };

      store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
    }) */
      .catch((error) => {
        const toast = {
          text: t('EXPORT_VARIABLES_FAILED'),
          operationId: error.operationId,
        };

        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      })
  );
};

const self = {
  submitExportVariables,
};

export default self;
