import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceOperationMetadataSelectors from '../../store/selectors/WorkspaceOperationMetadataSelectors';
import { IWorkspaceMesh } from '../../models/IMeshes';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import { LinearProgress } from '@mui/material';
import { getMeshOperationPanelDescription } from '../../shared/panels/panel-utils';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgConnectedMeshEditInterpolationConfiguration } from './mesh-edit-interpolation-configuration';
import { IOperationMetadata } from '../../models/IOperations';
import { getInterpolationOperationDescription } from './mesh-interpolation-util';
import { getOperationName } from '../../store/selectors/WorkspaceOperationUtils';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

const { hideOtherLayers, isLayerWorking } = LayerUtils;

/**
 * @name MmgConnectedMeshEditInterpolationPanel
 * @summary Allows editng an existing interpolation for a mesh.
 *
 */
export const MmgConnectedMeshEditInterpolationPanel = () => {
  const { workspaceId, projectId, meshId, operationId } = useParams();
  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const getOperationMetaDataInstance = WorkspaceOperationMetadataSelectors.makeGetOperationMetadataSelector();

  const mesh: IWorkspaceMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const meshDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId }),
  );
  const interpolationMetadata: IOperationMetadata = useSelector((state: IGlobalState) =>
    getOperationMetaDataInstance(state, { operationId }),
  );

  const { goBackToReferrer } = useNavigateBack();

  const onPanelExit = () => {
    goBackToReferrer();
  };

  // When an operation is sucessfully submitted we go back
  const onOperationSubmitSuccess = (execute: boolean) => {
    // Currently we leave the panel after submitting.
    // If in the future we want to stay in the panel, be aware that the api in cetain situations replace the operation with a new one
    // In this case we would need to replace the current operation with the new one
    // Also make sure that the route gets updated as well and that browser history gets replaced, so user would be able to go back to where the edit was initiated.
    //

    //if the operation was submitted to execute the interpolation, we got back to default tab
    const omitHash = execute;
    goBackToReferrer(omitHash);
  };

  // todo hevo show/hide items should be as in mesh detail. This goes for all mesh related panels. Maybe a custom hook for the logic would be an idea?
  // Make sure the only item shown in the viewer is the mesh at hand when the mesh is loaded
  useEffect(
    () => {
      if (mesh && mesh.id) {
        hideOtherLayers(ELEMENT_CATEGORIES.MESH, mesh.id);
      }
    },
    [mesh],
  );

  const drawnMeshes = meshDrawnData ? [meshDrawnData] : [];
  const isMeshWorking = isLayerWorking(mesh, drawnMeshes);

  const name = getOperationName(interpolationMetadata);
  const title = t('MESH_INTERPOLATION_EDIT_PANEL', 1, { name });

  const descriptions = [getMeshOperationPanelDescription(mesh, drawnMeshes)];

  if (!isMeshWorking) {
    const interpolationDescription = getInterpolationOperationDescription(interpolationMetadata);
    descriptions.push(interpolationDescription);
  }

  const description = descriptions.join(' - ');

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <MmgPanelHeader panelTitle={title} panelDescription={description} onPanelExit={onPanelExit} />
        </MikeStickyPanelHeaderContainer>

        {isMeshWorking && <LinearProgress />}
        <MmgConnectedMeshEditInterpolationConfiguration
          projectId={projectId}
          workspaceId={workspaceId}
          meshId={meshId}
          operationId={operationId}
          onOperationSubmitSuccess={onOperationSubmitSuccess}
        />
      </MikeStickyPanel>
    </>
  );
};
