/** @jsxImportSource @emotion/react */
import  { useEffect, useMemo } from 'react';
import { t } from '../../../translations/i18n';
import { useSelector } from 'react-redux';
import { store } from '../../../store/store';
import Tooltip from '@mui/material/Tooltip';
import {
  ViewerToolsButtonStyle,
  ViewerToolsButtonActiveStyle,
  ViewerToolsButtonActiveStrokedStyle,
  ViewerToolsWithSubmenuMenuStyle,
  ViewerToolsWithSubmenuMenuIndicatorStyle,
} from './viewer-tools-styles';
import ViewerToolUtils from './viewer-tool-utils';
import NestedTools from '../../../icons/NestedTools.svg?react';
import Draw from '../../../icons/Draw.svg?react';
import Polygon from '../../../icons/Polygon.svg?react';
import Polyline from '../../../icons/Polyline.svg?react';
import Point from '../../../icons/Point.svg?react';
import Circle from '../../../icons/Circle.svg?react';
import Rectangle from '../../../icons/Rectangle.svg?react';
import MikeVisualizerLib from '../../../MikeVisualizer/lib/MikeVisualizer';
import { SUBMENUS } from './viewer-tool-constants';
import { CONFIRM_PANEL_IDS } from '../../../shared/confirm-containers/confirm-constants';
import { OLDRAW_CLICKTOLERANCE } from '../../../viewer/viewer-constants';
import { IMapToolState } from '../../../store/reducers/MapToolReducer';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';
import { enable2DBoxDrawing } from '../../../MikeVisualizer/lib/2d/draw/MikeVisualizer2DDrawingTools';
import MultiPoint from 'ol/geom/MultiPoint.js';
import { Fill, Stroke, Circle as CircleStyle, Style, RegularShape } from 'ol/style';
import RenderFeature from 'ol/render/Feature';
import Geometry from 'ol/geom/Geometry';
import { Polygon as OlPolygon, Point as OlPoint } from 'ol/geom';
import LineString from 'ol/geom/LineString';
import { getConfiguration } from '../../../MikeVisualizer/lib/MikeVisualizerConfiguration';
import { EWorkspaceActionType } from '../../../store/actions/WorkspaceActionType';
import { EGeometryDrawType } from '../../../store/reducers/EditReducer';
import { EGeometryItemTypes } from '../../../models/IGeometries';
import { IGlobalState } from '../../../store/reducers';
import { css } from '@emotion/react';

const { toggleSubmenu } = ViewerToolUtils;
const {
  enable2DPolygonDrawingV2,
  enable2DPolylineDrawingV2,
  enable2DPointDrawingV2,
  enable2DCircleDrawing,
  disableAllDrawingTools,
} = MikeVisualizerLib;

/**
 * @name MmgConnectedViewerTool2DDraw
 * @summary Allows enabling/disabling 2d drawing tools
 *
 */
export function MmgConnectedViewerTool2DDraw() {
  const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  const mapToolState = useSelector((state: IGlobalState) => state.MapToolReducer as IMapToolState);
  const activeConfirmPanelId = useSelector((state: IGlobalState) => state.ConfirmPanelReducer.activeConfirmPanelId);

  const {
    openSubmenu,
    polygonDrawingEnabled,
    polylineDrawingEnabled,
    pointDrawingEnabled,
    circleDrawingEnabled,
    boxDrawingEnabled,
    polygonDrawingAllowed,
    polylineDrawingAllowed,
    pointDrawingAllowed,
    circleDrawingAllowed,
    boxDrawingAllowed,
  } = mapToolState;

  const epsgCode = useMemo(
    () => {
      return workspace ? workspace.epsgCode : undefined;
    },
    [workspace],
  );

  /**
   * Toggles the draw tools submenu.
   */
  const toggleDrawSubmenu = () => {
    toggleSubmenu(SUBMENUS.DRAW);
  };

  /**
   * Opens draw panel if no other panel is open.
   * Drawing can be active in many different scenarios, so the draw panel should only be shown if no other confirm panel is shown; otherwise it might interfere with another confirm.
   * @param drawType
   */
  const openDrawPanel = (drawType: EGeometryDrawType) => {
    if (!activeConfirmPanelId) {
      const geometryType =
        drawType === EGeometryDrawType.POINT
          ? EGeometryItemTypes.POINT
          : drawType === EGeometryDrawType.LINE
            ? EGeometryItemTypes.LINE_STRING
            : EGeometryItemTypes.POLYGON;
      store.dispatch({ type: EMapToolActionType.GEOMETRY_CREATE, data: { drawType, geometryType } });
      store.dispatch({
        type: EWorkspaceActionType.ACTIVE_PANEL_SET,
        panelName: CONFIRM_PANEL_IDS.GEOMETRY_DRAW_NEW,
      });
    }
  };

  const dispatchEnablePolygonDrawing = () => {
    openDrawPanel(EGeometryDrawType.POLYGON);
    store.dispatch({ type: EMapToolActionType.ENABLE_POLYGON_DRAWING });
  };
  const dispatchEnablePolylineDrawing = () => {
    openDrawPanel(EGeometryDrawType.LINE);
    store.dispatch({ type: EMapToolActionType.ENABLE_POLYLINE_DRAWING });
  };
  const dispatchEnablePointDrawing = () => {
    openDrawPanel(EGeometryDrawType.POINT);
    store.dispatch({ type: EMapToolActionType.ENABLE_POINT_DRAWING });
  };
  const dispatchEnableCircleDrawing = () => {
    openDrawPanel(EGeometryDrawType.CIRCLE);
    store.dispatch({ type: EMapToolActionType.ENABLE_CIRCLE_DRAWING });
  };
  const dispatchEnableBoxDrawing = () => {
    openDrawPanel(EGeometryDrawType.RECTANGLE);
    store.dispatch({ type: EMapToolActionType.ENABLE_BOX_DRAWING });
  };

  useEffect(
    () => {
      const { drawColors, ol } = getConfiguration();

      const styleFunction = (feature) => {
        if (feature && feature.values_ && feature.values_.geometry && feature.values_.geometry.getType() === 'Point') {
          return [
            new Style({
              image: new RegularShape({
                fill: new Fill({
                  color: drawColors.primary,
                }),
                points: ol.pointNumber,
                radius: ol.pointRadius,
                angle: ol.pointAngle,
              }),
              fill: new Fill({
                color: drawColors.bright,
              }),
            }),
          ];
        }

        const defaultStyle = new Style({
          fill: new Fill({
            color: drawColors.background,
          }),
          stroke: new Stroke({
            color: drawColors.bright,
            width: ol.strokeWidth,
          }),
        });

        const vertexStyle = new Style({
          image: new CircleStyle({
            radius: 4,
            fill: new Fill({
              color: 'white',
            }),
            stroke: new Stroke({
              color: drawColors.bright,
              width: 2,
            }),
          }),
          geometry: () => {
            const geom = feature.getGeometry();
            if (geom instanceof RenderFeature || geom === undefined) {
              console.debug(`Geometry not valid`, geom);
              return;
            }
            const geomType = (geom as Geometry).getType();
            if (geomType === "LineString") {
              const coords = (geom as LineString).getCoordinates();
              return new MultiPoint(coords);
            }
            if (geomType === "Polygon") {
              const coords = (geom as OlPolygon).getCoordinates();
              return new MultiPoint(coords[0]);
            }
            if (geomType === "Circle") {
              // Currently do nothing
              return;
            }
            if (geomType === "Point") {
              const coords = (geom as OlPoint).getCoordinates();
              return new OlPoint(coords);
            }
            console.debug(`No match for geometry type "${geomType}"`);
            return;
          },
        });
        return [defaultStyle, vertexStyle];
      };
      disableAllDrawingTools();
      polygonDrawingEnabled && enable2DPolygonDrawingV2({ vectorLayerStyle: styleFunction });
      polylineDrawingEnabled &&
        enable2DPolylineDrawingV2({
          vectorLayerStyle: styleFunction,
          olDrawOptions: { snapTolerance: 1 },
        });
      pointDrawingEnabled && enable2DPointDrawingV2({ olDrawOptions: { clickTolerance: OLDRAW_CLICKTOLERANCE } });
      circleDrawingEnabled && enable2DCircleDrawing({ featureAttributes: { mmg_ExtendedGeometryType: 'circle' } });
      boxDrawingEnabled && enable2DBoxDrawing();
    },
    [polygonDrawingEnabled, polylineDrawingEnabled, pointDrawingEnabled, circleDrawingEnabled, boxDrawingEnabled],
  );

  if (!epsgCode) {
    return <></>;
  }

  return (
    <>
      {/* TODO dan: disable buttons if projection is not supported */}
      <Tooltip title={t('TOOL_DRAW_GEOMETRY')} placement="right">
        <button css={ViewerToolsButtonStyle} onClick={toggleDrawSubmenu}>
          <Draw />
        </button>
      </Tooltip>

      <span css={ViewerToolsWithSubmenuMenuIndicatorStyle}>
        <NestedTools />
      </span>

      {openSubmenu === SUBMENUS.DRAW && (
        <ul css={ViewerToolsWithSubmenuMenuStyle}>
          {boxDrawingAllowed && (
            <li>
              <button
                css={css`${ViewerToolsButtonStyle} ${boxDrawingEnabled && ViewerToolsButtonActiveStyle}`}
                onClick={dispatchEnableBoxDrawing}
                title={t('TOOL_DRAW_RECTANGLE')}
              >
                <Rectangle />
              </button>
            </li>
          )}
          {circleDrawingAllowed && (
            <li>
              <button
                css={css`${ViewerToolsButtonStyle} ${circleDrawingEnabled && ViewerToolsButtonActiveStyle}`}
                onClick={dispatchEnableCircleDrawing}
                title={t('TOOL_DRAW_CIRCLE')}
              >
                <Circle />
              </button>
            </li>
          )}
          {polygonDrawingAllowed && (
            <li>
              <button
                css={css`${ViewerToolsButtonStyle} ${polygonDrawingEnabled && ViewerToolsButtonActiveStyle}`}
                onClick={dispatchEnablePolygonDrawing}
                title={t('TOOL_DRAW_POLYGON')}
              >
                <Polygon />
              </button>
            </li>
          )}
          {polylineDrawingAllowed && (
            <li>
              <button
                css={css`${ViewerToolsButtonStyle} ${polylineDrawingEnabled && ViewerToolsButtonActiveStrokedStyle}`}
                onClick={dispatchEnablePolylineDrawing}
                title={t('TOOL_DRAW_LINESTRING')}
              >
                <Polyline />
              </button>
            </li>
          )}
          {pointDrawingAllowed && (
            <li>
              <button
                css={css`${ViewerToolsButtonStyle} ${pointDrawingEnabled && ViewerToolsButtonActiveStyle}`}
                onClick={dispatchEnablePointDrawing}
                title={t('TOOL_DRAW_POINT')}
              >
                <Point />
              </button>
            </li>
          )}
        </ul>
      )}
    </>
  );
}
