/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IGlobalState } from '../../store/reducers';
import MIKE_COLORS from '../styles/mike-colors';

const MmgBreadcrumbsStyle = css`
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  &:hover {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
    border-bottom: ${MIKE_COLORS.BRANDBLUE_DEFAULT} 1px solid;
  }
`;

// set last crumb as bold
const MmgBreadcrumbLastStyle = css`
  font-weight: bold;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  &:hover {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
    border-bottom: ${MIKE_COLORS.BRANDBLUE_DEFAULT} 2px solid;
  }
  &:focus {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
`;

interface ICrumb {
  name: string;
  routePath: string;
}

/**
 * @name MmgConnectedBreadcrumbs
 * @summary Breacrumbs for navigation
 *
 */
export const MmgConnectedBreadcrumbs = () => {
  const breadcrumbs: Array<ICrumb> = useSelector((state: IGlobalState) => state.AppReducer.breadcrumbs);

  return breadcrumbs.length > 0 ? (
    <Breadcrumbs maxItems={3} separator={<NavigateNextIcon fontSize="small" />}>
      {breadcrumbs.map((crumb: ICrumb, index: number) => {
        return (
          crumb && (
            <Link
              key={index}
              to={crumb.routePath}
              css={index === breadcrumbs.length - 1 ? MmgBreadcrumbLastStyle : MmgBreadcrumbsStyle}
            >
              {crumb.name}
            </Link>
          )
        );
      })}
    </Breadcrumbs>
  ) : null;
};
