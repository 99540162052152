import { useState, useEffect, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash-es';

import { t } from '../../../translations/i18n';
import { usePrevious } from '../../../shared/hooks/hooks';

import WorkspaceDataItemUtils from '../../../store/selectors/WorkspaceDataItemUtils';
import WorkspaceQueryUtils from '../../../store/selectors/WorkspaceQueryUtils';

import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { IOperationConfiguration } from '../../../models/IOperations';

import { CircularProgress } from '@mui/material';
import { MmgOperationConfiguration } from '../../../operations/operation-configuration';
import { MmgMeshOperationGeometriesConfigurationsGroup } from '../../../meshes/configure/configuration/mesh-operation-geometries-configurations-group';

import { MmgGroup } from '../../../shared/groups/group';
import { MmgPanelSubsection } from '../../../shared/panels/panel-subsection';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { IOperationDescription, IContextualOperationDescriptions } from '../../../models/IOperationDescriptions';

type MeshOperationConfigurationProps = {
  groupName: string;
  workspaceId: string;
  geometries: Array<IWorkspaceEnrichedGeometry>;
  workspaceQueries: Array<IWorkspaceQuery>; // todo hevo looks strange with api interface here. Make a UI interface. Should it be workspace queries - then filter inside? Align with edit!!!
  workspaceVariables: Array<IWorkspaceEnrichedVariable>; // used for density function
  initialCreateMeshOperationConfiguration?: IOperationConfiguration;
  basicCreateMeshOperationDescription: IOperationDescription;
  contextualChildOperationDescriptions: IContextualOperationDescriptions;
  onMeshOperationConfigurationChanged: (operationConfiguration: IOperationConfiguration) => void;
};

/**
 * @name MmgMeshOperationConfiguration
 * @summary Allows configuring both 'basic'/base mesh operation configuration as well as geometries.
 *
 * @param props
 */
export const MmgMeshOperationConfiguration = (props: MeshOperationConfigurationProps) => {
  const {
    workspaceId,
    groupName,
    geometries,
    workspaceQueries,
    workspaceVariables,
    initialCreateMeshOperationConfiguration,
    basicCreateMeshOperationDescription,
    contextualChildOperationDescriptions,
    onMeshOperationConfigurationChanged,
  } = props;

  const [basicConfiguration, setBasicConfiguration] = useState(initialCreateMeshOperationConfiguration);

  const [childConfigurations, setChildConfigurations] = useState(
    (initialCreateMeshOperationConfiguration && initialCreateMeshOperationConfiguration.childOperations) || [],
  );

  const previousBasicConfiguration = usePrevious(basicConfiguration);
  const previousChildConfigurations = usePrevious(childConfigurations);

  const queriesForGeometries = useMemo(
    () => {
      const geometryIds = WorkspaceDataItemUtils.getIds(geometries);

      // Only include saved queries related to the geometries being considered.
      return WorkspaceQueryUtils.filterQueriesByTargetItems(workspaceQueries, geometryIds);
    },
    [workspaceQueries, geometries],
  );

  /**
   * Callback for when child operations configurations have changed.
   *
   * @param childOperations
   */
  const onChildConfigurationsChanged = useCallback(
    (childOperations: Array<IOperationConfiguration>) => {
      setChildConfigurations(childOperations);
      onMeshOperationConfigurationChanged({
        ...basicConfiguration,
        childOperations,
      });
    },
    [basicConfiguration, onMeshOperationConfigurationChanged],
  );

  /**
   * Callback for when basic (create mesh operation) configuration has changed.
   *
   * @param operationConfiguration
   */
  const onBasicOperationConfigurationChanged = (operationConfiguration: IOperationConfiguration) => {
    setBasicConfiguration(operationConfiguration);
  };

  useEffect(
    () => {
      if (
        !isEqual(previousBasicConfiguration, basicConfiguration) ||
        !isEqual(previousChildConfigurations, childConfigurations)
      ) {
        onMeshOperationConfigurationChanged({
          ...basicConfiguration,
          childOperations: childConfigurations,
        });
      }
    },
    [
      previousBasicConfiguration,
      previousChildConfigurations,
      basicConfiguration,
      childConfigurations,
      onMeshOperationConfigurationChanged,
    ],
  );

  return (
    <>
      <MmgGroup groupName={t('MESH_BASIC_CONFIGURATION')} canBeHidden={false}>
        <MmgPanelSubsection>
          {basicCreateMeshOperationDescription ? (
            <MmgOperationConfiguration
              initialOperationConfiguration={initialCreateMeshOperationConfiguration}
              operationDescription={basicCreateMeshOperationDescription}
              onConfigurationChanged={onBasicOperationConfigurationChanged}
            />
          ) : (
            <CircularProgress />
          )}
        </MmgPanelSubsection>
      </MmgGroup>

      <MmgMeshOperationGeometriesConfigurationsGroup
        workspaceId={workspaceId}
        groupName={groupName}
        geometries={geometries}
        workspaceQueries={queriesForGeometries}
        workspaceVariables={workspaceVariables}
        initialOperationConfigurations={
          (initialCreateMeshOperationConfiguration && initialCreateMeshOperationConfiguration.childOperations) || []
        }
        contextualOperationDescriptions={contextualChildOperationDescriptions}
        onConfigurationsChanged={onChildConfigurationsChanged}
      />
    </>
  );
};
