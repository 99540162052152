import { useEffect, useMemo } from 'react';
import { MmgPanelHeader } from '../shared/panels/panel-header';
import { t } from '../translations/i18n';
import { store } from '../store/store';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useUnmount from 'react-use/lib/useUnmount';
import useMount from 'react-use/lib/useMount';
import { usePrevious } from '../shared/hooks/hooks';
import { isEqual } from 'lodash-es';
import { MmgConnectedCommentsList } from './comments-list';
import { MmgConnectedCommentTool2DAddComment } from './tools/comment-tool-2d-add-comment';
import { TOOL_BUTTON_TYPES } from '../shared/tools/IToolButton';
import { useShowAllButUserHidden } from '../shared/hooks/useShowAllButUserHidden';
import WorkspaceCommentSelectors from '../store/selectors/WorkspaceCommentSelectors';
import CommentUtils from '../comments/comment-utils';
import { FeatureCollection } from 'geojson';
import { EMapToolActionType } from '../store/actions/MapToolActionType';
import MikeVisualizer2DDrawCore from '../MikeVisualizer/lib/2d/draw/MikeVisualizer2DDrawCore';
import MikeVisualizer2DDataCore from '../MikeVisualizer/lib/2d/data/MikeVisualizer2DDataCore';
import { IGlobalState } from '../store/reducers';
import { MikeStickyPanelHeaderContainer } from '../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import { MikeStickyPanelBottomActions } from '../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeStickyPanel from '../shared-components/mike-sticky-panel';
import MikeStickyPanelContent from '../shared-components/mike-sticky-panel/MikeStickyPanelContent';
const { showCommentPins, loadComments, highlightPin } = CommentUtils;

const { _destroyOpenLayersDrawMap } = MikeVisualizer2DDrawCore;
const { _destroyOpenLayersDataMap } = MikeVisualizer2DDataCore;

/**
 * @name MmgConnectedCommentsPanel
 * @summary Allows adding and viewing comments.
 */
export const MmgConnectedCommentsPanel = () => {
  const { workspaceId } = useParams();

  const commentsGeoJson: FeatureCollection<any, any> | null = useSelector(
    WorkspaceCommentSelectors.getCommentsFeatureCollection,
  );
  const focusedCommentId: string = useSelector((state: IGlobalState) => state.WorkspaceCommentReducer.focusedCommentId);
  const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);

  const epsgCode = useMemo(
    () => {
      return workspace ? workspace.epsgCode : null;
    },
    [workspace],
  );

  const previousCommentsGeoJson = usePrevious(commentsGeoJson);
  const previousEpsgCode = usePrevious(epsgCode);

  useEffect(() => {
    // currently comments are only shown in 2D mode
    store.dispatch({ type: 'maptools/SET_VIEWER_MODE_TO_2D' });

    store.dispatch({
      type: 'comments/SHOW',
    });

    store.dispatch({
      type: 'maptools/ALLOW/ADD_COMMENT',
    });

    // todo dan: this should possibly be handled in the visualizer lib. In some cases, if drawing occured before navigating to the comments panel, the 'draw map' / layer would be created and placed on top of the 'data map' / layer. This would mean that click events on the 'data map' (where pins are added) would never fire, because they are underneath.
    _destroyOpenLayersDrawMap();

    return () => {
      // on unmount we reset to default tools
      store.dispatch({
        type: EMapToolActionType.RESET_ALLOWED_TOOLS,
      });

      store.dispatch({
        type: 'comments/HIDE',
      });
    };
  }, []);

  useShowAllButUserHidden();

  useEffect(
    () => {
      if (!epsgCode) {
        return; // do nothing until workspace is loaded and projection is known.
      }

      if (previousEpsgCode !== epsgCode || !isEqual(commentsGeoJson, previousCommentsGeoJson)) {
        showCommentPins(commentsGeoJson, focusedCommentId, epsgCode);
      }
    },
    [epsgCode, previousEpsgCode, commentsGeoJson, previousCommentsGeoJson, focusedCommentId],
  );

  useEffect(
    () => {
      focusedCommentId && highlightPin(focusedCommentId);
    },
    [focusedCommentId],
  );

  useMount(() => loadComments(workspaceId));

  useUnmount(() => {
    _destroyOpenLayersDataMap();
  });

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <MmgPanelHeader panelTitle={t('COMMENTS_PANEL')} />
        </MikeStickyPanelHeaderContainer>
        <MikeStickyPanelContent>
          <MmgConnectedCommentsList />
        </MikeStickyPanelContent>
        <MikeStickyPanelBottomActions>
          <MmgConnectedCommentTool2DAddComment
            toolButtonType={TOOL_BUTTON_TYPES.REGULAR_BUTTON} // todo hevo the possible types should be build into the component instead of a separate constant
          />
        </MikeStickyPanelBottomActions>
      </MikeStickyPanel>
    </>
  );
};
