import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import { upperFirst } from 'lodash-es';

import MeshInterpolationOperationsManager from '../../managers/MeshInterpolationOperationsManager';

import {
  IOperationConfiguration,
  OPERATION_DEFINITION_TYPES,
  IOperationMetadata,
  OPERATION_STATES,
} from '../../models/IOperations';
import { IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import { IOperationDescription } from '../../models/IOperationDescriptions';
import {
  getOperationName,
  getMessageFromOperation,
  isOperationOutdated,
} from '../../store/selectors/WorkspaceOperationUtils';
import { createOperationConfigurationFromDescription } from '../../operations/operation-utils';
import { getShortDateTime } from '../../translations/utils';
import { EOperationActionType } from '../../store/actions/OperationActionType';

export const INTERPOLATION_ATTRIBUTE_NAMES = {
  [OPERATION_DEFINITION_TYPES.ELEVATION_INTERPOLATION]: 'ELEVATION',
};

export const INTERPOLATION_ATTRIBUTE_PARAMETER = 'propertyName';

/**
 * Submits a interpolate  mesh operation, saving or executing the interpolation.
 *
 * @param workspaceId
 * @param interpolateConfiguration
 * @param execute
 */
export const submitMeshInterpolation = (
  workspaceId: string,
  interpolateConfiguration: IOperationConfiguration,
  execute: boolean,
): Promise<IOperationConfiguration> => {
  let interpolatePromise;

  if (execute) {
    interpolatePromise = MeshInterpolationOperationsManager.executeInterpolateMeshOperation(
      workspaceId,
      interpolateConfiguration,
    );
  } else {
    interpolatePromise = MeshInterpolationOperationsManager.saveInterpolateMeshOperation(
      workspaceId,
      interpolateConfiguration,
    );
  }

  return interpolatePromise
    .then((operation: IOperationConfiguration) => {
      const toast = {
        text: execute ? t('MESH_INTERPOLATION_SUBMITTED') : t('MESH_INTERPOLATION_SAVED'),
      };
      store.dispatch({ type: 'toast/ADD/SUCCESS', toast });

      store.dispatch({
        type: EOperationActionType.CREATE_OR_UPDATE,
        operationMetadata: operation,
      });

      return operation;
    })
    .catch((error) => {
      const toast = {
        text: execute ? t('MESH_INTERPOLATION_SUBMITTING_FAILED') : t('MESH_INTERPOLATION_SAVE_FAILED'),
        operationId: error.operationId,
      };

      store.dispatch({ type: 'toast/ADD/ERROR', toast });
      throw error;
    });
};

/**
 * @summary Determines if the mesh attributes contains an attribute corresponding to the interpolation operation
 * @param interpolationConfiguration
 * @param meshAttributes
 */
export const hasInterpolationAttribute = (
  interpolationConfiguration: IOperationConfiguration,
  meshAttributes: Array<IWorkspaceAttribute>,
) => {
  if (!interpolationConfiguration || !meshAttributes || meshAttributes.length === 0) {
    return false;
  }

  // Use default attributenames as fallback if not defined in the configuration
  const attributeName =
    getInterpolationAttributeName(interpolationConfiguration) ||
    INTERPOLATION_ATTRIBUTE_NAMES[upperFirst(interpolationConfiguration.operationType)];

  if (!attributeName) {
    return false;
  }

  return (
    meshAttributes.findIndex(({ name }) => {
      const upperName = name.toUpperCase();
      return upperName === attributeName || upperName === 'MMG_' + attributeName;
    }) !== -1
  );
};

/**
 * Creates a new interpolation child operation configuration based on the operation description
 * @param operationKey
 * @param inputIds Ids of the variables input of the operation
 * @param operationDescriptions
 */
export const createInterpolationChildConfigurationFromDescription = (
  operationKey: string,
  inputIds: Array<string>,
  operationDescriptions: {
    [operationKey: string]: IOperationDescription;
  },
): IOperationConfiguration => {
  if (!operationKey || !operationDescriptions) {
    return null;
  }

  const operationDescriptionForKey = operationDescriptions[operationKey];

  if (!operationDescriptionForKey) {
    return null;
  }

  const config = createOperationConfigurationFromDescription(operationDescriptionForKey);

  const configuration = { ...config, inputIds };
  return configuration;
};

/**
 * Will return the name of the resulting attribute as defined by the parameters
 * @param interpolationConfiguration
 */
export const getInterpolationAttributeName = (interpolationConfiguration: IOperationConfiguration) => {
  if (!interpolationConfiguration) {
    return null;
  }

  const { operationDefinition } = interpolationConfiguration;
  const { parameters } = operationDefinition || {};
  const attributeName = parameters[INTERPOLATION_ATTRIBUTE_PARAMETER] as string;

  return attributeName;
};

/**
 * Will return the name of the interpolation operation including the name of the resulting attribute if defined by the parameters
 * @param interpolationConfiguration
 */
export const getInterpolationOperationName = (interpolationConfiguration: IOperationConfiguration) => {
  if (!interpolationConfiguration) {
    return '';
  }

  const names = [getOperationName(interpolationConfiguration)];

  const attributeName = self.getInterpolationAttributeName(interpolationConfiguration);

  if (attributeName) {
    names.push(attributeName);
  }
  return names.join(': ');
};

export const getInterpolationOperationDescription = (interpolation: IOperationMetadata, includeType = true) => {
  if (!interpolation) {
    return '';
  }

  const { state, updated } = interpolation;

  if (isOperationOutdated(interpolation)) {
    return t('MESH_INTERPOLATION_OUTDATED');
  }

  if (state === OPERATION_STATES.SUCCEEDED && updated) {
    return t('MESH_INTERPOLATION_EXCUTED', 1, {
      date: getShortDateTime(updated),
    });
  }

  return getMessageFromOperation(interpolation, includeType);
};

const self = {
  submitMeshInterpolation,
  hasInterpolationAttribute,
  createInterpolationChildConfigurationFromDescription,
  getInterpolationOperationName,
  getInterpolationOperationDescription,
  getInterpolationAttributeName,
};

export default self;
