import { IWorkspaceGeometry, IWorkspaceEnrichedGeometry } from '../../models/IGeometries';

import GeometryIconUtils from '../../geometries/geometry-icon-utils';
import { MikeLayerEdit } from '../../shared-components/mike-layer/layer-edit';
const { getIcon } = GeometryIconUtils;

type GeometryListItemEditProps = {
  geometry: IWorkspaceEnrichedGeometry;
  onGeometryUpdate: (geometry: IWorkspaceGeometry) => any;
};

/**
 * @name MmgGeometryListItemEdit
 * @summary A edited item in a geometry list.
 *
 * @param props
 */
export function MmgGeometryListItemEdit(props: GeometryListItemEditProps) {
  const { geometry } = props;
  const { name, id } = geometry;

  const onGeometryUpdate = ({ name: geometryName }) => props.onGeometryUpdate({ name: geometryName, id });

  return <MikeLayerEdit layerName={name} leftIcon={getIcon(geometry)} onLayerUpdate={onGeometryUpdate} />;
}
