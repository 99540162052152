/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { MenuBarHeight, PanelZindex } from '../styles/styleConsts';
import MikeSidePanel, { IMikeSidePanelProps } from '../../shared-components/mike-sidepanel';

const MmgSidePanelCss = css`
  top: ${MenuBarHeight};
  height: calc(100% - ${MenuBarHeight});
  position: absolute;
  z-index: ${PanelZindex};
  right: 0;  
`;

/**
 * Wraps the shared sidepanel component and place it below the topbar. Will pass on any props
 * @param props
 */
const MmgSidePanel = (props: IMikeSidePanelProps) => {
  const { children, ...rest } = props;

  return (
    <MikeSidePanel   
     css={css`
        ${MmgSidePanelCss};
      `} 
      {...rest}
    >
      {children}
    </MikeSidePanel>
  );
};

export default MmgSidePanel;
