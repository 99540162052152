import { MmgConnectedLayer } from '../shared/layers/mesh-layer';
import OperationIconUtils from '../operations/operation-icon-utils';
import LayerUtils from '../shared/layers/layer-utils';
import { IOperationMetadata } from '../models/IOperations';
import WorkspaceOperationUtils from '../store/selectors/WorkspaceOperationUtils';
import { MmgOperationOverflowMenu } from './actions/operation-overflow-menu';
import { MmgLayerError } from '../shared/layers/mesh-layer-error';
import { MikeLayerWorking } from '../shared-components/mike-layer/layer-working';

const { getMessageFromOperation, getOperationName } = WorkspaceOperationUtils;
const { getIcon } = OperationIconUtils;
const { isLayerInProgress, isLayerFailed } = LayerUtils;

type OperationListItemProps = {
  projectId: string;
  workspaceId: string;
  operation: IOperationMetadata;
};

/**
 * @name MmgOperationListItem
 * @summary An item in a geometry list.
 *
 * @param props
 */
export function MmgOperationListItem(props: OperationListItemProps) {
  const { operation, projectId, workspaceId } = props;

  const { id } = operation;
  const isInProgress = isLayerInProgress(operation);

  /**
   * Gets common right actions for operation layers.
   *
   *
   */
  const getCommonRightActions = () => {
    return <MmgOperationOverflowMenu projectId={projectId} workspaceId={workspaceId} operation={operation} />;
  };

  const name = getOperationName(operation);
  const description = getMessageFromOperation(operation);

  const baseLayerProperties = {
    layerId: id,
    layerName: name,
    rightActions: getCommonRightActions(),
    isLayerFaded: true,
  };

  if (isLayerFailed(operation)) {
    return <MmgLayerError {...baseLayerProperties} layerDescription={description} />;
  }

  if (isInProgress) {
    return <MikeLayerWorking {...baseLayerProperties} layerDescription={description} />;
  }

  return <MmgConnectedLayer {...baseLayerProperties} leftIcon={getIcon(operation)} />;
}
