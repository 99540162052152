/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { MmgSidepanelTitle } from './sidepanel-title';
import mikeSharedTheme from '../styles/mikeSharedTheme';


const SidepanelHeaderStyle = css`
  background-color: white;
`;

const SidepanelHeaderTitleStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SidepanelHeaderTitleIconStyle = css`
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: ${mikeSharedTheme.spacing(2)};
`;

const SidepanelChildrenStyle = css`
  display: flex;
  padding: ${mikeSharedTheme.spacing(2)};
`;

type SidepanelHeaderProps = {
  sidepanelHeaderTitle?: string;
  sidepanelHeaderDescription?: string;
  sidepanelHeaderTitleButton?: React.ReactNode;
  children?: React.ReactNode;
};

/**
 * @name MmgSidepanelHeader
 * @summary Sidepanels can have an optional header, usually containg a sidepanel title and some buttons (i.e. create mesh, workspace settings, etc).
 *
 * @todo Dan: this should wrap panel-header and configure it instead of re-implementing some of the components.
 *
 * @param props
 */
export function MmgSidepanelHeader(props: SidepanelHeaderProps) {
  const { sidepanelHeaderTitle, sidepanelHeaderTitleButton, sidepanelHeaderDescription } = props;

  return (
    <div css={SidepanelHeaderStyle}>
      <span css={SidepanelHeaderTitleStyle}>
        {sidepanelHeaderTitle && (
          <MmgSidepanelTitle sidepanelTitle={sidepanelHeaderTitle} sidepanelDescription={sidepanelHeaderDescription} />
        )}

        {sidepanelHeaderTitleButton && (
          <span css={SidepanelHeaderTitleIconStyle}>{sidepanelHeaderTitleButton}</span>
        )}
      </span>

      {props.children && <section css={SidepanelChildrenStyle}>{props.children}</section>}
    </div>
  );
}
