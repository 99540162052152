import React, { useState } from 'react';
import { IParameterDescription, PARAMETER_VALUE_TYPES } from '../../models/IOperationDescriptions';
import { translateWithPrefix } from '../../translations/utils';
import { TextField } from '@mui/material';
import { InputFieldInfoTooltipCss, InputFieldInfoWrapperCss, MmgParameterInfoWrapper } from './parameter-info-wrapper';
import { preventNumberInputScrolling } from '../input-utils';
import { IParameterSettings } from '../../models/IParameterSettings';
import { useField } from 'formik';
import { MmgInputUnitAdornment } from './input-unit-Adornment';
import { debounce, noop } from 'lodash-es';
import { isInteger } from 'lodash-es';
import { INPUT_DEBOUNCE_DELAY } from '../../constants-misc';

type ParameterInputNumberProps = {
  parameterKey: string;
  parameterDescription?: IParameterDescription;
  name: string;
  value: number;
  label: string;
  infoText?: string;
  helperText?: string;
  error?: boolean;
  customSettings?: IParameterSettings;
  onParameterChanged?: (param: string, val: number) => void;
};

/**
 * Triggers callback wrapped in debounce to spare the user and browser.
 * Keep the function out of scope to avoid defining it on every render and avoid
 * wrapping it in useCallback or useMemo.
 */
const handleInput = debounce((callback: () => void) => {
  callback && callback();
}, INPUT_DEBOUNCE_DELAY);

/**
 * @name MmgParameterInputNumber
 * @param props
 * @summary A generic element that renders a number  parameter and accepts a callback for when it change.
 */
export const MmgParameterInputNumber = (props: ParameterInputNumberProps) => {
  const {
    parameterKey,
    name,
    value,
    parameterDescription,
    label,
    helperText,
    infoText,
    error,
    onParameterChanged,
  } = props;
  const [validInput, setValidInput] = useState(true);
  const [field] = useField({
    name: name || parameterKey,
    value: value as number,
  });

  const { valueType, unit } = parameterDescription;
  const isInputInteger = valueType === PARAMETER_VALUE_TYPES.INT32 || valueType === PARAMETER_VALUE_TYPES.INTEGER;
  const unitString = unit ? translateWithPrefix('UNIT', unit, true) : '';
  if (!parameterKey) {
    return <></>;
  }

  const onInputChanged = (event: React.ChangeEvent) => {
    event.preventDefault();
    field.onChange(event);
    const currentTarget = event.currentTarget as HTMLInputElement;
    const val = currentTarget.value;
    const numericValue = isInputInteger ? parseInt(val, 10) : parseFloat(val);
    // Check if input is valid before triggering callback:
    let valid = true;
    isNaN(numericValue) ? (valid = false) : noop();
    isInputInteger && !isInteger(parseFloat(val)) ? (valid = false) : noop();
    setValidInput(valid);
    // Only send valid values through callback:
    valid && handleInput(() => onParameterChanged(currentTarget.id, numericValue));
  };

  return (
    <MmgParameterInfoWrapper infoText={infoText} cssProp={InputFieldInfoWrapperCss} tooltipCss={InputFieldInfoTooltipCss}>
      <TextField
        id={parameterKey}
        name={name}
        label={label}
        defaultValue={value}
        onChange={onInputChanged}
        onBlur={field.onBlur}
        fullWidth
        type="number"
        slotProps={{input: {
          endAdornment: <MmgInputUnitAdornment unitString={unitString} />,
          inputProps: { step: isInputInteger ? 1 : 'any' },
        }}}
        onWheel={preventNumberInputScrolling}
        error={error || !validInput}
        helperText={helperText}
      />
    </MmgParameterInfoWrapper>
  );
};
