import { isEmpty, defer, debounce } from 'lodash-es';
import Map from 'ol/Map';
import {  Draw, Modify, Snap, Select } from 'ol/interaction.js';
import { click } from 'ol/events/condition.js';
import { Style } from 'ol/style';
import { getEmitters } from '../../MikeVisualizerEvents';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import MikeVisualizer2DEvents from './MikeVisualizer2DEvents';
import { getOlStylePresets } from '../MikeVisualizer2DDrawConstants';
import { getConfiguration } from '../../MikeVisualizerConfiguration';
import { IMikeDrawMapEvent } from '../../store/IMikeVisualizerStore';
import { Options as PointerOptions } from 'ol/interaction/Pointer';
import { DrawEvent, Options as OlDrawOptions } from 'ol/interaction/Draw';
import OlFeature from 'ol/Feature';
import OlPolygon, { fromCircle } from 'ol/geom/Polygon';
import OlLineString from 'ol/geom/LineString';
import OlMultiPoint from 'ol/geom/MultiPoint';
import { Coordinate } from 'ol/coordinate';
import Circle from 'ol/geom/Circle';

const { getState, setState } = MikeVisualizerStore;
const {
  _getVectorLayerSource,
  _getVectorLayer,
  _removeAllMapInteractions,
  _getOlFeatureId,
  _setOlFeatureId,
} = MikeVisualizer2DMapUtil;
const { _emitChanges } = MikeVisualizer2DEvents;

/**
 * Contains methods to enable 2d draw interactions.
 *
 * @module MikeVisualizer2DInteractions
 * @version 1.0.0
 *
 * @todo in the next breaking version: rename to MikeVisualizer2DDrawInteractions (see TODO.md)
 * @internal
 */

/**
 * Handles a generic draw update (features added, removed or changed). Features can be polygons, polylines or points.
 *
 * @param { Draw } draw An open layers draw interactor
 * @param clearFeaturesOnStart Clears previous features when drawing starts.
 * @param instantUpdates Emit changes instantly, without debouncing them. This can result in bad performance, because changes will potentially be emitted for each mouse move.
 *
 * @private
 */
const _handleSketchUpdates = (
  draw: Draw,
  clearFeaturesOnStart: boolean,
  instantUpdates: boolean,
  speedDrawPoints = false,
  circle2Polygon = true,
  featureAttributes = {},
  featureId: string = ''
) => {
  const drawMap = draw.getMap();
  const source = drawMap && _getVectorLayerSource(drawMap);

  const drawEndCb = (drawEvent: DrawEvent) => {
    const drawnFeature = drawEvent.feature;
    drawnFeature.setProperties(featureAttributes);
    if(featureId){
      drawnFeature.setId(featureId);
    }
    if (!_getOlFeatureId(drawnFeature)) {
      _setOlFeatureId(drawnFeature);
    }
    if (circle2Polygon){
      _circle2Polygon(drawnFeature);
    } 
    if (speedDrawPoints){
      _speedDrawToPoints(drawnFeature);
    }
  };
  draw.on('drawend', drawEndCb);

  if (clearFeaturesOnStart) {
    const drawStartCb = () => {
      // Use `any` to avoid TS error. It should never step in (but it does) because
      // interaction.getFeatures doesn't exist, also not when inspecting it in the js console:
      // Clear / reset other interactions that might have outdated features.
      if (drawMap){
        drawMap
        .getInteractions()
        .getArray()
        .forEach((interaction: any) => {
          // TS fix, .getFeatures doens't exist.
          if (interaction.getFeatures){
            interaction.getFeatures().clear();
          }
        });
      }      
      // Clear vector layer source. Interactions should listen to this event and update their own features. DO NOT perform a fast clear `source.clear(true)`, as that would prevent interactions from being 'informed' of feature changes.
      if (source){
        source.clear();
      }
    };
    draw.on('drawstart', drawStartCb);
  }

  const debounceEmitChanges = debounce(
    () => {
      const { drawMapUpdatesPaused } = getState();
      if (drawMapUpdatesPaused) {
        return false;
      }
      if (source){
        return _emitChanges(source)();
      }
      
    },
    instantUpdates ? 1 : 500
  );
  if (source){
    source.on(['addfeature', 'removefeature', 'changefeature'], debounceEmitChanges);
  }  
};

export interface IDrawInteractionOptions {
  drawMap: Map;
  type: 'Point' | 'LineString' | 'LinearRing' |'Polygon' | 'MultiPoint' | 'MultiLineString' | 'MultiPolygon' | 'GeometryCollection' | 'Circle';
  vectorLayerStyle?: (feature) => Array<Style>;
  drawInteractionStyle?: (feature) => Array<Style>;
  selectInteractionStyle?: (feature) => Array<Style>;
  singleItem?: boolean;
  instantUpdates?: boolean;
  allowFreehand?: boolean;
  speedDrawPoints?: boolean;
  circle2Polygon?: boolean;
  olDrawOptions?: Partial<OlDrawOptions>;
  featureAttributes?: { [key: string]: string | boolean | number | null | undefined };
  featureId?: string;
}
/**
 * Enables a drawing tool of a given type, doing the following:
 * - removes all previous interactions
 * - creates a draw interaction and sets it up, making sure the correct styles are applied
 * - registers a sketch update callback that notifies consumers of feature collection changes.
 *
 * NB: applying different vector layer styles when switching between enabling a tools will also affect previously drawn items. Here the optional style is meant to be used for conceptually different tools, not when switching between a type of tool: i.e. switching from drawing tools to selection tools. If this becomes a requirement, we should create vector layer instances for each drawing tool type.
 *
 * @param options.drawMap Map to enable draw interactions on.
 * @param options.type The type of tool to enable.
 * @param [options.vectorLayerStyle] A function returning an array of ol/style definitions, to be used as the style for the drawn item(s) when they are part of the vector layer.
 * @param [options.drawInteractionStyle] A function returning an array of ol/style definitions, to be used as the style for 'sketched' items; items that are being drawn but not part of the vector layer yet.
 * @param [options.selectInteractionStyle] A function returning an array of ol/style definitions, to be used as the style for selected items.
 * @param [options.singleItem] Only allow 1 drawn item at a time.
 * @param [options.instantUpdates] Emit changes instantly, without debouncing them. This can result in bad performance, because changes will potentially be emitted for each mouse move.
 * @param [options.allowFreehand] Enable freehand drawing with CTRL key to activate it.
 * @param [options.olDrawOptions] Pass any valid OpenLayers options in; will overrule other options above.
 * @param [options.featureAttributes] Key-value pairs that you want to add as attributes to the drawn feature.
 *
 * @private
 */
const _enableDrawInteraction = async (options: IDrawInteractionOptions) => {
  const {
    drawMap,
    type,
    vectorLayerStyle = getOlStylePresets().DEFAULT.vectorLayer,
    drawInteractionStyle = getOlStylePresets().DEFAULT.drawInteraction,
    selectInteractionStyle = getOlStylePresets().DEFAULT.selectInteraction,
    singleItem = false,
    instantUpdates = false,
    allowFreehand = true,
    speedDrawPoints = false,
    circle2Polygon = true,
    olDrawOptions,
    featureAttributes,
    featureId,
  } = options;
  if (!drawMap) {
    console.error(`No drawMap`);
    return false;
  }

  self._removeDrawMapInteractions(drawMap);
  const source = _getVectorLayerSource(drawMap);
  const vectorLayer = _getVectorLayer(drawMap);

  const source1 = source ? source : undefined
  const drawInteraction = new Draw({
    source: source1,
    type,
    freehandCondition: (event) => {
      const pointerEvent = (event as any).pointerEvent as PointerEvent;
      return allowFreehand && pointerEvent && pointerEvent.ctrlKey;
    },
    style: drawInteractionStyle,
    ...olDrawOptions,
  });

  vectorLayer.setStyle(vectorLayerStyle);
  drawMap.addInteraction(drawInteraction);
  self._enableEditInteractions(
    drawInteraction,
    drawInteractionStyle,
    selectInteractionStyle,
    singleItem
  );
  self._handleSketchUpdates(
    drawInteraction,
    singleItem,
    instantUpdates,
    speedDrawPoints,
    circle2Polygon,
    featureAttributes,
    featureId
  );

  return true;
};

/**
 * Enables edit interactions on a instance of 'ol/Draw'.
 * This is generally suitable while drawing any type of geometry.
 *
 * By default also enables:
 * - select
 * - snap
 * - box select
 *
 * @param drawInteraction
 * @param modifyInteractionStyle A function returning an array of ol/style definitions, to be used to style the modify interaction items.
 * @param selectInteractionStyle A function returning an array of ol/style definitions, to be used as the style for selected items.
 *
 * @private
 */
const _enableEditInteractions = (
  drawInteraction: Draw,
  modifyInteractionStyle: (feature) => Array<Style>,
  selectInteractionStyle: (feature) => Array<Style>,
  singleItem: boolean
) => {
  const drawMap = drawInteraction.getMap();
  const { emitDrawingInProgressChanged } = getEmitters();
  const { keyboardShortcuts } = getConfiguration();

  if (!drawMap) {
    console.info('draw interaction does not have a Map');
    return;
  }

  const vectorSource = _getVectorLayerSource(drawMap);
  const modifyInteraction = new Modify({
    style: modifyInteractionStyle,
    source: vectorSource ? vectorSource : undefined,
    pixelTolerance: getConfiguration().ol.tolerance,
  });
  drawMap.addInteraction(modifyInteraction);

  // Enable other edit interactions.
  const selectInteraction = self._enableSelectInteraction(drawMap, selectInteractionStyle);
  if (!selectInteraction) {
    throw Error(`No Select interaction`);
  }

  if (!singleItem) {
    // Dragbox and snap only make sense for multiple items.   
    self._enableSnapInteraction(drawMap); // Snap Interaction must be added after other interactions that should benefit from the snapping
  }

  // Setup event listeners (keyboard shortcuts) and callbacks for drawing.
  // Keyboard events for sketch in progress.
  const handleKeyBoardEventsWhenSketchInProgress = (keyEvt) => {
    const key = keyEvt.key;

    if (key === keyboardShortcuts.removeLastPoint) {
      drawInteraction.removeLastPoint();
      drawInteraction.changed();
    }

    if (key === keyboardShortcuts.cancelSketch) {
      (drawInteraction as any).abortDrawing_();
      onDrawEnded();
    }

    if (key === keyboardShortcuts.finishSketch) {
      drawInteraction.finishDrawing();
    }
  };

  // Keyboard events for sketch is not in progress
  const handleKeyBoardEventsWhenNoSketch = (keyEvt) => {
    const key = keyEvt.key;

    if (key === keyboardShortcuts.deleteSelected) {
      if (selectInteraction.getActive()) {
        const selectedFeatures = selectInteraction.getFeatures();

        if (selectedFeatures) {
          selectedFeatures.forEach((feature) => {
            const id = _getOlFeatureId(feature);
            if (id && vectorSource) {
              vectorSource.removeFeature(feature);
            }
          });
          // also need to remove all selections
          selectedFeatures.clear();
        }
      }
    }
  };

  const onDrawEnded = () => {
    // Allow editing and selecting if not sketching anymore.
    modifyInteraction.setActive(true);
    selectInteraction.setActive(true);

    // Remove 'events when sketch in progress'.
    document.removeEventListener('keydown', handleKeyBoardEventsWhenSketchInProgress);

    setState({
      drawMapDocumentListeners: getState().drawMapDocumentListeners.filter((event) => {
        const { name, cb } = event as IMikeDrawMapEvent;
        return name === 'keydown' && cb === handleKeyBoardEventsWhenSketchInProgress;
      }),
    });

    // Setup 'events when no sketch'.
    document.addEventListener('keydown', handleKeyBoardEventsWhenNoSketch, false);

    setState({
      drawMapDocumentListeners: [
        ...getState().drawMapDocumentListeners,
        {
          name: 'keydown',
          cb: handleKeyBoardEventsWhenNoSketch,
        },
      ],
    });

    // Deferring ensures that open layers went through the call stack and finished all work it has to do.
    defer(() => {
      emitDrawingInProgressChanged(false);
    });
  };

  const drawStartCb = () => {
    // Do not allow editing while sketching is ongoing.
    modifyInteraction.setActive(false);
    selectInteraction.setActive(false);

    // Remove 'events when no sketch'.
    document.removeEventListener('keydown', handleKeyBoardEventsWhenNoSketch);
    setState({
      drawMapDocumentListeners: getState().drawMapDocumentListeners.filter((event) => {
        const { name, cb } = event as IMikeDrawMapEvent;
        return name === 'keydown' && cb === handleKeyBoardEventsWhenNoSketch;
      }),
    });

    document.addEventListener('keydown', handleKeyBoardEventsWhenSketchInProgress, false);

    // Setup 'events when sketch in progress'.
    setState({
      drawMapDocumentListeners: [
        ...getState().drawMapDocumentListeners,
        {
          name: 'keydown',
          cb: handleKeyBoardEventsWhenSketchInProgress,
        },
      ],
    });

    emitDrawingInProgressChanged(true);
  };

  const drawEndCb = () => {
    onDrawEnded();
  };

  drawInteraction.on('drawstart', drawStartCb);
  drawInteraction.on('drawend', drawEndCb);

  // NB: by default, there should be a `handleKeyBoardEventsWhenNoSketch` listener. That is the implicit mode.
  document.addEventListener('keydown', handleKeyBoardEventsWhenNoSketch, false);

  // Store listeners to state so they can be unsubscribed later.
  setState({
    drawMapDocumentListeners: [
      ...getState().drawMapDocumentListeners,
      {
        name: 'keydown',
        cb: handleKeyBoardEventsWhenNoSketch,
      },
    ],
  });
};

/**
 * Enable snap interaction.
 * NB: Snap Interaction must be added after other interactions that should benefit from the snapping
 *
 * @param drawMap
 *
 * @private
 */
const _enableSnapInteraction = (drawMap: Map) => {
  if (drawMap) {
    const vectorSource = _getVectorLayerSource(drawMap);
    const pointerOptions: PointerOptions = {
      handleDownEvent: () => {
        // We mark down event not handlded, to allow the shiftPan interactor of the vtk viewer to work
        return false;
      },
    };
    // TS fix for existing hack: It is not documented in OpenLayers that PointerOptions are supported by Snap,
    // but a look in the source code reveals that it is, possibly unintentionally:
    const snapInteraction = new Snap({
      source: vectorSource ? vectorSource : undefined,
      ...pointerOptions,
    });
    drawMap.addInteraction(snapInteraction);

    return snapInteraction;
  }

  return false;
};

/**
 * Enable select interaction.
 *
 * @param drawMap
 * @param selectInteractionStyle A function returning an array of ol/style definitions, to be used as the style for selected items.
 *
 * @private
 */
const _enableSelectInteraction = (drawMap: Map, selectInteractionStyle) => {
  if (drawMap) {
    // If we do not apply the filter, then a new drawn feature will be selected before the id is set.
    // When trying to select the same feature later, the one with an id gets selected - now the featurecollection contains two geometries.
    // TODO hevo There might be a smarter way to do this, but will keep this for now.
    const selectInteraction = new Select({
      style: selectInteractionStyle,
      hitTolerance: getConfiguration().ol.tolerance,
      condition: click,
      filter: (feature) => {
        const id = _getOlFeatureId(feature);
        return !isEmpty(id);
      },
    });
    drawMap.addInteraction(selectInteraction);

    return selectInteraction;
  }

  return false;
};


/**
 * Removes draw map interactions and unsubscribes document listeners.
 *
 * @param drawMap
 *
 * @private
 */
const _removeDrawMapInteractions = (drawMap: Map) => {
  if (drawMap) {
    _removeAllMapInteractions(drawMap);
    setState({
      drawMapDocumentListeners: getState().drawMapDocumentListeners.filter((event) => {
        const { name, cb } = event as IMikeDrawMapEvent;
        return document.removeEventListener(name, cb);
      }),
    });
  }
};

/**
 * Convert the drawn feature to points after a delay and replace them in the map.
 */
const _speedDrawToPoints = (drawnFeature: OlFeature, delay = 1000) => {
  setTimeout(() => {
    const geometry = drawnFeature.getGeometry() as OlPolygon | OlLineString;
    if (!geometry) {
      return;
    }
    let coordinates: Array<Coordinate> = [];
    if (geometry instanceof OlPolygon) {
      coordinates = geometry.getCoordinates()[0];
    }
    if (geometry instanceof OlLineString) {
      coordinates = geometry.getCoordinates();
    }
    const multiPoint = new OlMultiPoint(coordinates);
    drawnFeature.setGeometry(multiPoint);
  }, delay);
};

/**
 * If the drawn feature is a circle, convert it to a polygon
 */
const _circle2Polygon = (
  drawnFeature: OlFeature,
  delay = 100,
  optSides?: number,
  optAngle?: number
) => {
  const geom = drawnFeature.getGeometry();
  const geomType = geom ? geom.getType() : '';
  if (geomType === 'Circle') {
    setTimeout(() => {
      const cPolygon = fromCircle(geom as Circle, optSides, optAngle);
      drawnFeature.setGeometry(cPolygon);
    }, delay);
  }
};

const self = {
  _enableDrawInteraction,
  _enableEditInteractions,
  _enableSnapInteraction,
  _enableSelectInteraction, 
  _handleSketchUpdates,
  _removeDrawMapInteractions,
};

export default self;
