import { useCallback } from 'react';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import { useSelector } from 'react-redux';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { MmgConnectedGeometrySelectList } from '../../geometries/select-list/geometry-select-list';
import { IGlobalState } from '../../store/reducers';

type MeshConfigurationSelectGeometries = {
  selectedGeometryIds: Array<string>;
  onSelectionChanged?: (selectedIds: Array<string>) => void;
};

/**
 * @name MmgConnectedMeshConfigurationSelectGeometries
 * @summary Allows selecting geometries for a createmesh operation.
 *
 * @param props
 */
export const MmgConnectedMeshConfigurationSelectGeometries = (props: MeshConfigurationSelectGeometries) => {
  const { selectedGeometryIds, onSelectionChanged } = props;

  const { hiddenWorkspaceGeometries } = useSelector((state: IGlobalState) => state.WorkspaceGeometryReducer);

  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );

  const workspaceGeometries: Array<IWorkspaceEnrichedGeometry> = useSelector(
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries,
  );

  const onLayerSelectChanged = useCallback(
    (layerId: string, selected: boolean) => {
      let nextSelectedIds = [];

      if (selected) {
        nextSelectedIds = [...selectedGeometryIds, layerId];
      } else {
        nextSelectedIds = selectedGeometryIds.filter((id) => id !== layerId);
      }

      if (onSelectionChanged) {
        onSelectionChanged(nextSelectedIds);
      }
    },
    [selectedGeometryIds, onSelectionChanged],
  );

  return (
    workspaceGeometries &&
    workspaceGeometries.length > 0 && (
      <MmgConnectedGeometrySelectList
        geometries={workspaceGeometries}
        hiddenGeometries={hiddenWorkspaceGeometries}
        drawnGeometries={drawnWorkspaceGeometries}
        selectedGeometries={selectedGeometryIds}
        onLayerSelectChanged={onLayerSelectChanged}
      />
    )
  );
};
