import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { IOperationDescription, IParameterConditionDescription } from '../models/IOperationDescriptions';
import { MmgOperationParameters } from './operation-parameters';
import { usePrevious } from '../shared/hooks/hooks';
import { isEqual } from 'lodash-es';
import { IOperationConfiguration, IOperationDefinition } from '../models/IOperations';
import { createOperationConfigurationFromDescription, getOperationParameterValues } from './operation-utils';
import { createValidationSchema } from '../shared/parameters/parameter-validation-utils';
import { IParameterSettings } from '../models/IParameterSettings';

type OperationConfigurationProps = {
  operationDescription: IOperationDescription;
  /**
   * @property initialOperationConfiguration Initial values to use for the configuration. For operations without any initial value provided, default value of the first operationDescription will be used.
   */
  initialOperationConfiguration?: IOperationConfiguration;
  parameterConditionDescriptions?: {
    [parameterKey: string]: IParameterConditionDescription;
  };
  parameterSettings?: {
    [parameterKey: string]: IParameterSettings;
  };
  inputItemSelector?: React.ReactNode;
  onConfigurationChanged?: (newConfiguration: IOperationConfiguration) => void;
  dirtyParentConfiguration?: IOperationConfiguration;
};

/**
 * @name MmgOperationConfiguration
 * @param props
 * @summary Allows configuring the parameters of a generic operation.
 *
 */
export const MmgOperationConfiguration = (props: OperationConfigurationProps) => {
  const {
    operationDescription,
    onConfigurationChanged,
    initialOperationConfiguration,
    parameterConditionDescriptions,
    parameterSettings,
    inputItemSelector,
    dirtyParentConfiguration,
  } = props;

  const [dirtyOperationConfiguration, setDirtyOperationConfiguration] = useState({} as IOperationConfiguration);
  const prevOperationDescription = usePrevious(operationDescription) as IOperationDescription;
  const prevOperationConfiguration = usePrevious(initialOperationConfiguration) as IOperationConfiguration;
  const validationSchema = useMemo(() => createValidationSchema((operationDescription || {}).parameterDescriptions), [
    operationDescription,
  ]);

  const initalValues = useMemo(
    () => {
      return getOperationParameterValues(operationDescription, (initialOperationConfiguration || {}).parameters);
    },
    [operationDescription, initialOperationConfiguration],
  );

  const callOnConfigurationChanged = useCallback(
    (changedConfiguration) => {
      // only call if configuration has actually changed
      if (onConfigurationChanged && !isEqual(changedConfiguration, initialOperationConfiguration)) {
        onConfigurationChanged(changedConfiguration);
      }
    },
    [onConfigurationChanged, initialOperationConfiguration],
  );

  const onParameterChanged = useCallback(
    (param: string, val: string | number | boolean | object) => {
      // For now parameters live on operationConfiguration as well as on operationDefinition. So we need to update both.
      // todo hevo parameters should only be defined in one place
      const dirtyOperationDefinition =
        (dirtyOperationConfiguration || {}).operationDefinition || ({} as IOperationDefinition);
      const changedOperationDefinition = {
        ...dirtyOperationDefinition,
        parameters: {
          ...dirtyOperationDefinition.parameters,
          [param]: val,
        },
      };
      const changedConfiguration = {
        ...dirtyOperationConfiguration,
        operationDefinition: changedOperationDefinition,
        parameters: {
          ...dirtyOperationConfiguration.parameters,
          [param]: val,
        },
      };
      setDirtyOperationConfiguration(changedConfiguration);
      callOnConfigurationChanged(changedConfiguration);
    },
    [dirtyOperationConfiguration, callOnConfigurationChanged],
  );

  useEffect(
    () => {
      if (
        !isEqual((prevOperationConfiguration || {}).parameters, (initialOperationConfiguration || {}).parameters) ||
        !isEqual(prevOperationDescription, operationDescription)
      ) {
        if (operationDescription) {
          const initialOperation = createOperationConfigurationFromDescription(
            operationDescription,
            initialOperationConfiguration,
          );
          setDirtyOperationConfiguration(initialOperation);
          callOnConfigurationChanged(initialOperation);
        }
      }
    },
    [
      operationDescription,
      callOnConfigurationChanged,
      initialOperationConfiguration,
      prevOperationDescription,
      prevOperationConfiguration,
    ],
  );

  if (!operationDescription) {
    return <></>;
  }

  const { parameterDescriptions } = operationDescription;

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={() => {
        // todo hevo for now we do not submit using formik
      }}
      validateOnMount
    >
      <>
        {inputItemSelector ? inputItemSelector : null}
        <MmgOperationParameters
          parameters={dirtyOperationConfiguration.parameters}
          parameterDescriptions={parameterDescriptions}
          parameterSettings={parameterSettings}
          onParameterChanged={onParameterChanged}
          parameterConditionDescriptions={parameterConditionDescriptions}
          dirtyOperationConfiguration={dirtyOperationConfiguration}
          dirtyParentConfiguration={dirtyParentConfiguration}
        />
      </>
    </Formik>
  );
};
