import React from 'react';
import { ClassNameMap, LinearProgress } from '@mui/material';
import {
  ConfirmStyle,
  ConfirmHeaderStyle,
  ConfirmHelpTextStyle,
  ConfirmContentStyle,
} from './confirm-styles';

export type ConfirmContainerPropsClassKey = 'root' | 'content' | 'header' | 'helptext';

type ConfirmContainerProps = {
  headerTitle: string;
  helpText?: string;
  isWorking?: boolean;
  showHelpText?: boolean;
  buttons: React.ReactNode;
  children?: React.ReactNode;
  classes?: Partial<ClassNameMap<ConfirmContainerPropsClassKey>>;
};

/**
 * @name MmgConfirmContainer
 * @summary Generic confirm component.
 * Should be used as a base component by all 'confirm' components.
 * NB: the 'buttons' prop should probably be refactored to something more deliberate if we agree that the current confirmation UI is here to stay.
 *
 * @todo dan: this will be deprecated in favor of the panel components.
 *
 * @param props
 */
export function MmgConfirmContainer(props: ConfirmContainerProps) {
  const { headerTitle, isWorking, helpText, showHelpText, buttons, children, classes } = props;

  return (
    <section css={classes && classes.root ? classes.root : ConfirmStyle}>
      <header css={classes && classes.header ? classes.header : ConfirmHeaderStyle}>
        <h4>{headerTitle}</h4>
      </header>

      {isWorking && <LinearProgress />}

      <div css={classes && classes.content ? classes.content : ConfirmContentStyle}>
        {children}

        {showHelpText && (
          <p css={classes && classes.helptext ? classes.helptext : ConfirmHelpTextStyle}>{helpText}</p>
        )}
      </div>

      {buttons}
    </section>
  );
}
