/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import LayerUtils, { fetchTiledLayer } from './layer-utils';
import { useSelector } from 'react-redux';
import { EElementCategories } from '../panels/mesh-panel-constants';
import { ILayerProps, MikeLayer } from '../../shared-components/mike-layer/layer';
import { IGlobalState } from '../../store/reducers';
import MikeVisualizerViewManager from '../../MikeVisualizer/lib/MikeVisualizerViewManager';

const { showLayer, hideLayer, fetchLayer } = LayerUtils;

interface IMmgLayerProps extends ILayerProps {
  type?: EElementCategories;
  showVisibilityIcon?: boolean;
  onLayerMouseEnter?: (id: string) => void;
  onLayerMouseLeave?: (id: string) => void;
  onVisibiltyChanged?: (show: boolean) => void;
  hovering?: boolean;
  isTiled?: boolean;
  cssProp?: any;
}

/**
 * @name MmgConnectedLayer
 * @summary A wrapper / extensions of MikeLayer, implementing some custom properties.
 *
 * @param props
 */
export const MmgConnectedLayer = (props: IMmgLayerProps) => {
  const {   
    type,
    onLayerMouseEnter,
    onLayerMouseLeave,
    onVisibiltyChanged,
    showVisibilityIcon = true,
    isTiled = false,
    cssProp
  } = props;

  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);

  const onLayerShow = useCallback(
    (lId) => {
      if (loadedData.includes(lId)) {
        showLayer(type, lId, true);
      } else {
        if (isTiled) {
          const { getCurrentViewBounds } = MikeVisualizerViewManager;
          const bounds = getCurrentViewBounds();
          fetchTiledLayer(lId, [], bounds, false);
        } else {
          fetchLayer(lId, type);
        }
      }
      onVisibiltyChanged && onVisibiltyChanged(true);
    },
    [loadedData, onVisibiltyChanged, isTiled, type],
  );

  const onLayerHide = useCallback(
    (lId) => {
      hideLayer(type, lId, true);
      onVisibiltyChanged && onVisibiltyChanged(false);
    },
    [onVisibiltyChanged, type],
  );

  const handleOnLayerMouseEnter = (layerId) => {
    onLayerMouseEnter && onLayerMouseEnter(layerId);
  };

  return (
    <MikeLayer
      onLayerMouseEnter={handleOnLayerMouseEnter}
      onLayerMouseLeave={onLayerMouseLeave}
      {...props}
      onLayerHide={onLayerHide}
      onLayerShow={onLayerShow}
      showVisibilityIcon={showVisibilityIcon}
      // hovering={hovering}
      cssProp={cssProp}
    />
  );
};
