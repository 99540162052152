/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { translateWithPrefixAndSuffix } from '../translations/utils';
import Info from '../icons/Info.svg?react';
import { EElementCategories } from '../shared/panels/mesh-panel-constants';
import { IDataArrayStatistics } from './mmg-mesh-statistics-container';
import Typography from '@mui/material/Typography/Typography';
import MIKE_COLORS from '../MikeVisualizer/colors/mike-colors';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import MikeTooltip from '../shared-components/mike-tooltip';

interface MmgStatisticsProps {
  statistics: IDataArrayStatistics;
  renderName?: (name: string, id: string) => void;
  elementId?: string;
  elementCategory?: EElementCategories;
  geometryAttributeLabels?: any;
  layerAttribute?: string;
  isTiled?: boolean;
}

const StatEntryLabelStyle = css`
  flex-shrink: 0;
  margin-right: ${mikeSharedTheme.spacing(2)};
  text-transform: none;
  font-size: 14px;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
`;

const StatEntryValueStyle = css`
  font-size: 14px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const StatEntryPStyle = css`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 0;
  width: 100%;
`;

const StatsItemWithNoRenderStyle = css`
  width: 100%;
  border: 0;
  padding: 0;
  background: 0;
  font-family: inherit;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d7d7d7;
`;

const ListContainerStyle = css`
  margin-left: 10px;
  padding: 0 ${mikeSharedTheme.spacing(2)} 0 0;
  list-style-type: none;
`;

export const MmgConnectedStatistics = (props: MmgStatisticsProps) => {
  const { statistics } = props;

  const renderInfoIcon = (id: string) => {
    let translation;
    if (id) {
      translation = translateWithPrefixAndSuffix('PROP', 'HELPTEXT', id);
    }
    let formattedTranslation;
    if (translation) {
      formattedTranslation = translation.replace(/\s+/g, '');
    }

    if (translation === undefined || formattedTranslation === undefined) {
      return null;
    }

    if (translation === formattedTranslation || formattedTranslation === id) {
      return null;
    }

    return (
      <MikeTooltip title={translation}>
        <Info width={20} height={20} viewBox={'0 0 30 30'} />
      </MikeTooltip>
    );
  };

  return (
    <>
      {statistics &&
        statistics.map(({ name, id, value }) => {
          return (
            <div key={id + value} css={ListContainerStyle}>
              <div css={StatsItemWithNoRenderStyle}>
                <div css={StatEntryPStyle}>
                  <Typography css={StatEntryLabelStyle} variant="body2">
                    {name}
                  </Typography>
                  <span css={StatEntryValueStyle} title={value}>
                    {value}
                  </span>
                </div>
                {renderInfoIcon(id)}
              </div>
            </div>
          );
        })}
    </>
  );
};
