/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

export const LAYER_ICON_SIZE = '40px';
export const LAYER_HEIGHT = '56px';

export const LayerStyle = css`
  display: flex;
  margin: 0;
  width: 100%;
  height: ${LAYER_HEIGHT};
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
  overflow: hidden;
  background-color: white;
  align-items: center;
  justify-content: space-between;
`;

export const LayerNameAndDescriptionStyle = css`
  display: flex;
  flex-direction: column;
  text-align: left;
  min-width: 0;
  width: 100%;
`;

export const LayerNameStyle = css`
  display: inline;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
  padding: 0 ${mikeSharedTheme.spacing(2)} 0 0;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const LayerDescriptionStyle = css`
  font-size: 0.75rem; /* 12px */
  line-height: 1rem;
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const LayerContentStyle = css`
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_LIGHT};
`;

export const LayerIconStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: ${mikeSharedTheme.spacing(1)};
  height: ${LAYER_ICON_SIZE};
  width: ${LAYER_ICON_SIZE};
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};

  &:focus {
    outline: 0;
  }
`;

export const LayerButtonStyle = css`
  background: 0;
  border: 0;
  padding: 0;
`;

export const LayerWithoutBorderStyle = css`
  border-bottom: none;
`;
