import { Route, Routes } from 'react-router';
import { connect } from 'react-redux';
import { workspaceEditPanelPath, workspaceExportPanelPath, workspaceOperationPanelPath, workspacePanelPath } from '../workspaces/workspace.routes';
import { geometriesDeletePanelPath, geometriesOperationPanelPath, geometriesOperationSelectItemsPanelPath, geometryDetailsPanelPath, geometryOperationPanelPath } from '../geometries/geometry.routes';
import { variableDetailsPanelPath, variablesDeletePanelPath, variablesMultipleOperationPanelPath, variablesOperationPanelPath, variablesOperationSelectItemsPanelPath } from '../variables/variable.routes';
import { meshAddGeometrySelectMeshPanelPath, meshCreateConfigurePanelPath, meshCreateInterpolationPanelPath, meshDetailsPanelPath, meshEditConfigurePanelAddGeometryPath, meshEditConfigurePanelPath, meshEditInterpolationPanelPath, meshesDeletePanelPath, meshPostOperationPanelPath } from '../meshes/mesh.routes';
import { store } from '../store/store';
import { MmgConnectedVariableDetails } from '../variables/details/variable-details-panel';
import { MmgConnectedMeshDetails } from '../meshes/details/mesh-details-panel';
import { MmgConnectedMeshCreateConfigurePanel } from '../meshes/configure/mesh-create-configure-panel';
import { MmgConnectedMeshEditConfigurePanel } from '../meshes/configure/mesh-edit-configure-panel';
import { MmgConnectedMeshAddGeometrySelectMeshPanel } from '../geometries/add-to-mesh/mesh-add-geometry-select-mesh-panel';
import { MmgConnectedGeometryDetailsPanel } from '../geometries/details/geometry-details-panel';
import MmgConnectedExportPanel from '../workspaces/export/export-panel';
import { MmgConnectedGeometryOperationPanel } from '../geometries/operations/geometry-operation-panel';
import { MmgConnectedGeometryOperationMultiplePanel } from '../geometries/multiple/geometry-operation-multiple-panel';
import { MmgConnectedGeometryOperationSelectGeometriesPanel } from '../geometries/operations/geometry-operation-select-geometries-panel';
import { MmgConnectedMeshConfirmSpatialSelection } from '../meshes/queries/mesh-confirm-spatial-selection';
import { MmgConnectedWorkspaceDetailsPanel } from '../workspaces/details/workspace-details-panel';
import { MmgConnectedGeometryExtractConfirm } from '../geometries/create/geometry-extract-confirm';
import { MmgConnectedVariableEditDataConfirm } from '../variables/edit/variable-edit-data-confirm';
import { MmgConnectedMeshCreateInterpolationPanel } from '../meshes/interpolation/mesh-create-interpolation-panel';
import { MmgConnectedMeshEditInterpolationPanel } from '../meshes/interpolation/mesh-edit-interpolation-panel';
import { MmgConnectedGeometryConfirmEditData } from '../geometries/edit/geometry-confirm-edit-data';
import { MmgConnectedWorkspaceEditPanel } from '../workspaces/edit/workspace-edit-panel';
import { MmgConnectedMeshPostOperationPanel } from '../meshes/post-operations/mesh-post-operation-panel';
import { MmgConnectedCommentsPanel } from '../comments/comments-panel';
import { MmgConnectedCommentAddConfirm } from '../comments/comment-add-confirm';
import { MmgConnectedGeometryMultipleDeletePanel } from '../geometries/multiple/geometry-multiple-delete-panel'; 
import { MmgConnectedGeometryConfirmSpatialSelection } from '../geometries/queries/geometry-confirm-spatial-selection';
import { MmgConnectedVariableMultipleDeletePanel } from '../variables/multiple/variable-multiple-delete-panel';
import { MmgConnectedWorkspaceSidePanel } from './workspace-sidepanel';
import { MmgConnectedMeshMultipleDeletePanel } from '../meshes/multiple/mesh-multiple-delete-panel';
import { CONFIRM_PANEL_IDS } from '../shared/confirm-containers/confirm-constants';
import WorkspaceUtils from '../workspaces/workspace-utils';
import { MmgConnectedConfirmEditNodes } from '../meshes/edit/mesh-confirm-edit-nodes';
import { MmgConnectedGeometryExtractSelectGeometriesConfirm } from '../geometries/create/geometry-extract-select-geometries-confirm';
import { MmgConnectedVariableOperationPanel } from '../variables/operations/variable-operation-panel';
import { MmgConnectedVariableOperationSelectVariablesPanel } from '../variables/multiple/variable-operation-select-operation-panel';
import { MmgConnectedVariableMultipleOperationPanel } from '../variables/multiple/variable-multiple-operation-panel';
import { MmgWorkspaceNavigationGroupSetter } from './workspace-navigation-group-setter';
import { MmgConnectedWorkspaceOperationPanel } from '../workspaces/workspace-operation-panel';
import { ROUTES } from '../app/routes';
import { commentsPanelPath } from '../comments/comment.routes';

const { getNavigationGroupByConfirmPanelId } = WorkspaceUtils;

type WorkspacePanelRoutesProps = {
  activeConfirmPanelId?: string;
};

/**
 * @param props
 * @name MmgConnectedWorkspacePanelRoutes
 * @summary Renders a panel based on the current url.
 *
 */
export const MmgWorkspacePanelRoutes = (props: WorkspacePanelRoutesProps) => {
  const { activeConfirmPanelId } = props; // re-write to useSelector causes indefinite loop on workspace-edit-panel
  /*   const activeConfirmPanelId: string = useSelector(
    (state: IGlobalState) => state.ConfirmPanelReducer.activeConfirmPanelId,
  ); */

  console.log(ROUTES.commentsPanel.path)

  const sidePanelOpen = (isOpen) => {
    store.dispatch({ type: 'panel/SET_PANEL_OPEN', panelHidden: !isOpen });
  };

  const getActiveConfirmPanel = () => {
    if (activeConfirmPanelId) {
      // Active confirm panels always replace any other panel that could be mounted because of a route or otherwise. They are shown regardless of the route.
      // TODO dan: confirm panels are at the moment not needed during page load, so they could be dynamically imported later instead.
      switch (activeConfirmPanelId) {
         case CONFIRM_PANEL_IDS.GEOMETRY_SELECTION:
          return <MmgConnectedGeometryConfirmSpatialSelection />;

        case CONFIRM_PANEL_IDS.MESH_SELECTION:
          return <MmgConnectedMeshConfirmSpatialSelection />;

        case CONFIRM_PANEL_IDS.MESH_EDIT_NODES:
          return <MmgConnectedConfirmEditNodes />;

        case CONFIRM_PANEL_IDS.GEOMETRY_DRAW_NEW:
        case CONFIRM_PANEL_IDS.GEOMETRY_EDIT:
          return <MmgConnectedGeometryConfirmEditData />;

        case CONFIRM_PANEL_IDS.VARIABLE_DRAW_NEW:
          return <MmgConnectedVariableEditDataConfirm />;

        case CONFIRM_PANEL_IDS.VARIABLE_EDIT:
          return <MmgConnectedVariableEditDataConfirm />;

        case CONFIRM_PANEL_IDS.EXTRACT_DATA:
          // TODO dan: should be split into 2 different elements
          return <MmgConnectedGeometryExtractConfirm />;

        case CONFIRM_PANEL_IDS.COMMENT_ADD:
          return <MmgConnectedCommentAddConfirm />;

        case CONFIRM_PANEL_IDS.GEOMETRY_MULTIPLE_EXTRACT:
          return <MmgConnectedGeometryExtractSelectGeometriesConfirm />; 

        default:
      }
    }
  };

  if (activeConfirmPanelId) {
    // Active confirm panels always replace any other panel that could be mounted because of a route or otherwise. They are shown regardless of the route.

    const activeConfirmPanel = getActiveConfirmPanel();

    return (
      activeConfirmPanelId && (
        <MmgConnectedWorkspaceSidePanel
          defaultOpen={true}
          navigationLocked={true}
          onChange={sidePanelOpen}
          navigationGroup={getNavigationGroupByConfirmPanelId(activeConfirmPanelId)}
        >
          {activeConfirmPanel}
        </MmgConnectedWorkspaceSidePanel>
      )
    );
  }

  return (
    <MmgConnectedWorkspaceSidePanel defaultOpen={true} onChange={sidePanelOpen}>
      <Routes>
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedWorkspaceOperationPanel />
            </>}
          path={workspaceOperationPanelPath}
        />
        {/* Geometry routes */}
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedGeometryMultipleDeletePanel/>
            </>}
          path={geometriesDeletePanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedGeometryOperationSelectGeometriesPanel/>
            </>}
          path={geometriesOperationSelectItemsPanelPath}
        />

        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedGeometryOperationMultiplePanel/>
            </>}
          path={geometriesOperationPanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedGeometryOperationPanel/>
            </>}
          path={geometryOperationPanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedGeometryDetailsPanel/>
            </>}
          path={geometryDetailsPanelPath}
        />
        {/* Variable routes */}
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedVariableOperationSelectVariablesPanel/>
            </>}
          path={variablesOperationSelectItemsPanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedVariableMultipleDeletePanel/>
            </>}
          path={variablesDeletePanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedVariableOperationPanel/>
            </>}
          path={variablesOperationPanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedVariableMultipleOperationPanel/>
            </>}
          path={variablesMultipleOperationPanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedVariableDetails/>
            </>}
          path={variableDetailsPanelPath}
        />
        {/* Mesh routes */}
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshMultipleDeletePanel/>
            </>}
          path={meshesDeletePanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshPostOperationPanel/>
            </>}
          path={meshPostOperationPanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshAddGeometrySelectMeshPanel/>
            </>}
          path={meshAddGeometrySelectMeshPanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshCreateConfigurePanel/>
            </>}
          path={meshCreateConfigurePanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshEditConfigurePanel/>
            </>}
          path={meshEditConfigurePanelAddGeometryPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshEditConfigurePanel/>
            </>}
          path={meshEditConfigurePanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshCreateInterpolationPanel/>
            </>}
          path={meshCreateInterpolationPanelPath}
        />

        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshEditInterpolationPanel/>
            </>}
          path={meshEditInterpolationPanelPath}
        />
        <Route element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedMeshDetails/>
            </>} path={meshDetailsPanelPath} />
        {/* Comments routes */}
        <Route element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedCommentsPanel/>
            </>} path={commentsPanelPath} />
        {/* workspace routes */}
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedWorkspaceEditPanel/>
            </>}
          path={workspaceEditPanelPath}
        />
        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedExportPanel/>
            </>}
          path={workspaceExportPanelPath}
        />

        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedWorkspaceDetailsPanel/>
            </>}
          path={workspacePanelPath}
        />

        <Route
          element={<>
            <MmgWorkspaceNavigationGroupSetter />
            <MmgConnectedWorkspaceDetailsPanel/>
            </>}
          path={"/"}
        />
      </Routes>
    </MmgConnectedWorkspaceSidePanel>
  );
};

const mapWorkspacePanelRoutesStateToProps = (state) => {
  const { activeConfirmPanelId } = state.ConfirmPanelReducer;

  return {
    activeConfirmPanelId,
  };
};

const MmgConnectedWorkspacePanelRoutes = connect(mapWorkspacePanelRoutesStateToProps)(MmgWorkspacePanelRoutes);

export default MmgConnectedWorkspacePanelRoutes;
