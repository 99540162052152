/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef, useEffect } from 'react';


import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import CheckIcon from '@mui/icons-material/Check';

import {
  PanelHeaderStyle,
  PanelHeaderWithBackArrowStyle,
  PanelHeaderTitleStyle,
  PanelHeaderBackStyle,
  PanelHeaderBackIconStyle,
} from './panel-styles';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';


type PanelHeaderEditProps = {
  panelTitle?: string;
  onPanelExit?: () => void;
  onPanelUpdate: ({name}) => void;
};

const FormStyle = css`
  width: 100%;
  padding-right: ${mikeSharedTheme.spacing(2)};
`;

const iconStyle = css`
  path {
    fill: ${MIKE_COLORS.DARKGREY_DEFAULT};
  }
`;


/**
 * @name MmgPanelHeaderEdit
 * @summary Optional panel headers can be 'edited', allowing renaming the panel title.
 *
 * @param props
 */
export function MmgPanelHeaderEdit(props: PanelHeaderEditProps) {
  const { panelTitle, onPanelExit } = props;
  const panelTitleInputEl = useRef(null);

  const handlePanelExit = (event) => {
    event.preventDefault();
    if (onPanelExit) {
      onPanelExit();
    }
  };

  const submitUpdate = (event) => {
    event.preventDefault();
    props.onPanelUpdate({ name: panelTitleInputEl.current.value });
  };

  const hasBackArrow = Boolean(onPanelExit);

  useEffect(() => {
    panelTitleInputEl.current.focus();
  }, []);

  return (
    <header css={`${PanelHeaderStyle} ${hasBackArrow && PanelHeaderWithBackArrowStyle}`}>
      <span css={PanelHeaderTitleStyle}>
        {hasBackArrow && (
          <button css={PanelHeaderBackStyle} onClick={handlePanelExit}>
            <span css={PanelHeaderBackIconStyle}>
              <ArrowBackIcon css={iconStyle} />
            </span>
          </button>
        )}

        <form onSubmit={submitUpdate} css={FormStyle}>
          <FormControl fullWidth={true}>
            <Input
              autoComplete="off"
              fullWidth={true}
              id="panel-title"
              inputRef={panelTitleInputEl}
              type={'text'}
              defaultValue={panelTitle || ''}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="save changes" onClick={submitUpdate}>
                    <CheckIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </form>
      </span>
    </header>
  );
}
