/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, CircularProgress, TextField, Tooltip, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../translations/i18n';
import { IUsageType } from '../project-datasets/mesh-project-dataset-constants';
import Delete from '../icons/Delete.svg?react';
import { MmgPanelSubsection } from '../shared/panels/panel-subsection';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import { IGlobalState } from '../store/reducers';


type IProps = {
  selectedDatasetUsageTypesChanged: (selDatasets: Array<IUsageType>) => void;
  selectedDatasetUsageTypes: Array<IUsageType>;
};

const rowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid ${mikeSharedTheme.palette.mediumGrey.main};
`;

const labelStyle = css`
  width: 50%;
  color: ${mikeSharedTheme.palette.darkGrey.main};
  text-align: left;
  padding-right: ${mikeSharedTheme.spacing(1)};
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ellipsisStyle = css`
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const usageStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * @name MmgProjectContent
 * @param props
 * @summary Component rendering the usage types of workspace datasets
 *
 */
const MmgProjectContent = (props: IProps) => {
  const { selectedDatasetUsageTypesChanged, selectedDatasetUsageTypes } = props;

  const workspaceIsLoading: boolean = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspaceLoading);

  const workspaceLoadingError: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceReducer.workspaceLoadingError,
  );
  // const [selectedDatasetUsageTypes, setSelectedUsageTypes] = useState(new Array<IUsageType>());

  const updateWorkspaceDataset = useCallback(
    (usageTypes: Array<IUsageType>) => {
      selectedDatasetUsageTypesChanged && selectedDatasetUsageTypesChanged(usageTypes);
    },
    [selectedDatasetUsageTypesChanged],
  );

  /*   useEffect(
    () => {
      if (initialDatasetUsageTypes) {
        setSelectedUsageTypes(initialDatasetUsageTypes);
      }
    },
    [initialDatasetUsageTypes],
  ); */

  const onDeleteDataset = useCallback(
    (datasetId: string) => () => {
      const newUsageTypes = selectedDatasetUsageTypes.filter((type: IUsageType) => type.datasetId !== datasetId);
      //setSelectedUsageTypes(newUsageTypes);
      updateWorkspaceDataset(newUsageTypes);
    },
    [selectedDatasetUsageTypes, updateWorkspaceDataset],
  );

  const handleDatasetTypeChange = useCallback(
    (datasetId: string) => (event) => {
      const target = event.target as HTMLInputElement;
      const usageType = target.value;
      const usageTypeIndex = selectedDatasetUsageTypes.findIndex((item) => item.datasetId === datasetId);
      const selectedDataset = selectedDatasetUsageTypes[usageTypeIndex];
      const newSelectedUsageTypes = [
        ...selectedDatasetUsageTypes.slice(0, usageTypeIndex),
        {
          datasetId,
          usageType,
          name: selectedDataset.name,
          spatialInformation: selectedDataset.spatialInformation,
          projectId: selectedDataset.projectId,
          datasetUsageTypes: selectedDataset.datasetUsageTypes,
        },
        ...selectedDatasetUsageTypes.slice(usageTypeIndex + 1),
      ];
      updateWorkspaceDataset(newSelectedUsageTypes);
    },
    [selectedDatasetUsageTypes, updateWorkspaceDataset],
  );

  const getUsageDisplayName = (usageType): string => {
    return t('USAGE_TYPE_' + usageType);
  };

  return (
    <div>
      {!workspaceIsLoading && !workspaceLoadingError && selectedDatasetUsageTypes ? (
        selectedDatasetUsageTypes.map((dataset) => {
          // Check if dataset is already imported, and if so as what:
          const datasetId = dataset.datasetId;
          const theUsage = dataset.usageType;
          const datasetUsageTypes = dataset.datasetUsageTypes;
          return (
            <div key={datasetId} css={rowStyle}>
              <div css={labelStyle}>
                <Tooltip title={dataset.name}>
                  <Typography css={ellipsisStyle} variant={'body2'}>
                    {dataset.name}
                  </Typography>
                </Tooltip>
              </div>
              <div css={usageStyle}>
                <TextField
                  select
                  inputProps={{
                    style: {
                      fontSize: '0.875rem',
                      lineHeight: 1.286,
                      paddingBottom: 16,
                      paddingTop: 16,
                      width: 100,
                    },
                    name: 'select-dataset-type',
                    id: 'select-dataset-type',
                  }}
                  value={theUsage}
                  onChange={handleDatasetTypeChange(datasetId)}
                >
                  {!theUsage && (
                    <option key={-1} value={''}>
                      {t('SELECT_USAGE_TYPE')}
                    </option>
                  )}
                  {datasetUsageTypes.map((type: number) => {
                    return (
                      <option key={type} value={type}>
                        {getUsageDisplayName(type)}
                      </option>
                    );
                  })}
                  {/*  {Object.keys(USAGE_TYPES).map((key) => {
                    const value = USAGE_TYPES[key];
                    return (
                      <option key={value} value={value}>
                        {getUsageDisplayName(USAGE_TYPES[key])}
                      </option>
                    );
                  })} */}
                </TextField>

                <Button style={{ minWidth: 40, padding: 0 }} onClick={onDeleteDataset(datasetId)}>
                  <Delete />
                </Button>
              </div>
            </div>
          );
        })
      ) : (
        <MmgPanelSubsection>
          <p>{t('LOADING', 1, { thing: t('PROJECT_DATASET') })}</p>
          <div>
            <CircularProgress />
          </div>
        </MmgPanelSubsection>
      )}
    </div>
  );
};

export default MmgProjectContent;
