/** @jsxImportSource @emotion/react */
import { css, Interpolation, SerializedStyles, Theme } from '@emotion/react';
import React, { useState } from 'react';
import { Drawer } from '@mui/material';
import MikeSidePanelNavigationBar from '../mike-sidepanel-navigationbar/MikeSidePanelNavigationBar';
import { IMikeSidePanelNavigationProps } from '../mike-sidepanel-navigation/MikeSidePanelNavigation';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';

export interface IMikeSidePanelProps {
  /**
   * Whether initiate panel open or closed.
   */
  defaultOpen?: boolean;
  /**
   * A `MikeSidePanelNavigation` containing children `MikeSidePanelNavigationItem`;
   * to put inside the `MikeSidePanelNavigationBar`.
   */
  navigation?: React.ReactElement<IMikeSidePanelNavigationProps>;
  /**
   * Nest whatever to want inside the panel.
   */
  children?: React.ReactNode;
  /**
   * Add a custom CSS className if you wish.
   */
  css?: Interpolation<Theme>;
  /**
   * Will run when that panel exands or collapses.
   */
  onChange?: (open: boolean) => void;
  /**
   * Whether or not to style the div containing your content with "height: 100%;"
   * and "position: relative;". Default is undefined.
   */
  fullHeight?: boolean;
  /**
   * Force an override of the `value` in `MikeSidePanelNavigationBar`.
   */
  forceValue?: string;

  scrollableContent?: boolean;
}

/***
 * todo hevo
 * -[ ] Do we need a variant with and without navigationbar?
 *
 */
const DRAWER_WIDTH = 400;
const TOGGLE_WIDTH = 40; // todo hevo do not have this constant in many places. Which component should 'own'/control this width?

const drawerOpenStyle =  { 
  backgroundColor: mikeSharedTheme.palette.background.default,
  border: 'none',
  position: 'relative',
  height: '100%',
  width: DRAWER_WIDTH,
  overflowX: 'hidden',
  transition: mikeSharedTheme.transitions.create('width', {
    easing: mikeSharedTheme.transitions.easing.sharp,
    duration: mikeSharedTheme.transitions.duration.enteringScreen,
  })
}

const drawerCloseStyle =  {  
  backgroundColor: mikeSharedTheme.palette.background.default,
  border: 'none',
  position: 'relative',
  height: '100%',
  transition: mikeSharedTheme.transitions.create('width', {
    easing: mikeSharedTheme.transitions.easing.sharp,
    duration: mikeSharedTheme.transitions.duration.leavingScreen,
  }),
  width: 0,
  overflowX: 'hidden'
}


const drawerStyle = (open: boolean) => {
  const drawer = {
    width: DRAWER_WIDTH,
    height: 'calc(100% - 60px)',
    position: 'absolute',
    right: 0,
    top: '60px'
  }
  if (open){
    return {...drawer, drawerOpenStyle}
  }
  else{
    return {...drawer, drawerCloseStyle}
  }
}

const sideNavBarStyle = (open: boolean): SerializedStyles => {
  const style =  css`
    z-index: 1300;   
`;

  if (open){
    return css`
      z-index: 1300;
      box-sizing: content-box;
      border-left: 1px solid;
      border-left-color: ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
       height: calc(100vh - 60px);
      top: 60px;
    `
  }  
  else{
    return css`${style}`;
  }
}

const contentStyle = (scrollableContent: boolean, fullHeight: boolean) => { 
  let overflow = 'auto'
  if (scrollableContent){
    overflow = 'hidden'
  }
  let height = '';
  let position = undefined
  if (fullHeight){
     height = '100%'
    position = 'relative'
  }
  const width = DRAWER_WIDTH - TOGGLE_WIDTH - 1
  return css`
    overflow: ${overflow};
    width: ${width}px;
    height: ${height};
    position: ${position};
  `; 
}      

// 
/**
 * @name MikeSidePanel
 * @summary A generic panel that can hold multiple views, be collapsed or expanded.
 */
export const MikeSidePanel = (props: IMikeSidePanelProps) => {
  const {
    children,
    defaultOpen,
    navigation,
    css,
    onChange,
    forceValue,
    scrollableContent,
  } = props;
  const value = navigation && navigation.props.value;
  const [drawerOpen, setDrawerOpen] = useState(defaultOpen);
  
  const handleNavBarCollapsed = (collapsed: boolean) => {
    setOpen(!collapsed);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const setOpen = (open: boolean) => {
    setDrawerOpen(open);
    if (onChange) {
      onChange(open);
    }
  };

  return (
    <>
      <div css={css}>
        <Drawer 
          variant="persistent"    
          anchor="right"
          sx={drawerStyle(drawerOpen)}    
          open={drawerOpen}
          onClose={handleClose}
          transitionDuration={{
            enter: mikeSharedTheme.transitions.duration.enteringScreen,
            exit: mikeSharedTheme.transitions.duration.leavingScreen
          }}
          PaperProps={{ elevation: drawerOpen ? 8 : 0, style:  drawerOpen ? drawerOpenStyle as any : drawerCloseStyle as any}}
        >
          <div css={contentStyle(props.fullHeight, scrollableContent)}>
            {children}
          </div>
        </Drawer>
        <MikeSidePanelNavigationBar
          collapsed={!drawerOpen}
          value={value}
          onCollapsed={handleNavBarCollapsed}
          css={sideNavBarStyle(drawerOpen)}      
          forceValue={forceValue}
        >
          {navigation as any}
        </MikeSidePanelNavigationBar>
      </div>
    </>
  );
};

export default MikeSidePanel;
