/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import ReactSelectMaterialUi from 'react-select-material-ui';
import mikeSharedTheme from '../styles/mikeSharedTheme';

const SelectMaterialUiStyles = css`
  input[type] {
    border: 0;
    margin: 0;
  }
`;

const SelectMaterialUiFocusedStyles = css`
  /* Focused select gets a higher z-index to be on top of other positioned items */
  div & {
    /* Select self with higher specificity to override the default z-index of the component */
    position: relative;
    z-index: 100;
  }
`;

/**
 * @name MmgAutocomplete
 * @summary A wrapper of the react-select-material-ui autocomplete that implements MIKE MUI styles.
 *
 * @todo Dan: this should be a shared component. Also, it needs some style work.
 *
 * @param props
 */
export const MmgAutocomplete = (props) => {
  const { onChange: onChangeProps, onBlur: onBlurProps, onFocus: onFocusProps, ...otherProps } = props;
  const [open, setOpen] = useState(false);

  const onChange = (value: any, selectOption: { label: string; value: any }) => {
    if (onChangeProps) {
      onChangeProps(value, selectOption);
    }
  };

  const onBlur = (event: React.FocusEvent) => {
    setOpen(false);

    if (onBlurProps) {
      onBlurProps(event);
    }
  };

  const onFocus = (event: React.FocusEvent) => {
    setOpen(true);

    if (onFocusProps) {
      onFocusProps(event);
    }
  };

  return (
    <ReactSelectMaterialUi
      css={css`${SelectMaterialUiStyles}; ${open && SelectMaterialUiFocusedStyles};`}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      InputLabelProps={{ style: { color: mikeSharedTheme.palette.primary.main } }}
      SelectProps={{
        ...props.SelectProps,
        styles: {
          valueContainer: () => {
            return { border: 0 };
          },
        },
      }}
      fullWidth={true}
      {...otherProps}
    />
  );
};
