import { EProgressItemsActionType } from './ProgressItemsActionType';
import { IAction } from './Action';
import { IProgressItem } from '../../shared-components/mike-topbar-progress-spinner';

export const removeProgressItem = (progressItem: IProgressItem): IAction => ({
  type: EProgressItemsActionType.REMOVE_PROGRESS_ITEM,
  data: progressItem,
});

export const updateOrAddProgressItem = (progressItem: IProgressItem): IAction => ({
  type: EProgressItemsActionType.UPDATE_OR_ADD_PROGRESS_ITEM,
  data: progressItem,
});
