/**
 * Exposes methods to handle map related requests.
 *
 * @module MapManager
 * @version 2.0.0
 * @requires MapProxy
 */
import * as MapProxy from '../proxies/MapProxy';
import { HttpErrorHandler } from './http-utils';
import * as querystring from 'query-string';
import { EMapDataTypes } from '../models/IMaps';

const qs = querystring.default;

/**
 * Transforms a request that gets a map of type for the given bounds & epsg code.
 *
 * @param mapType
 * @param xMin
 * @param yMin
 * @param xMax
 * @param yMax
 * @param epsgCode
 * @param includeElevation
 */
const getMap = (
  mapType: EMapDataTypes,
  xMin: number,
  yMin: number,
  xMax: number,
  yMax: number,
  epsgCode: number,
  includeElevation: boolean,
) => {
  const mapQuery = qs.stringify({
    xMin,
    yMin,
    xMax,
    yMax,
    epsgCode,
    includeElevation,
  });

  return MapProxy.getMap(mapType, mapQuery)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get map.', error));
};

/**
 * Transforms a request that gets map tile information (metadata) for a bounding box & epsg code.
 *
 * @param xMin
 * @param yMin
 * @param xMax
 * @param yMax
 * @param epsgCode
 */
const getMapTileInformation = (xMin: number, yMin: number, xMax: number, yMax: number, epsgCode: number) => {
  const mapTileQuery = qs.stringify({
    xMin,
    yMin,
    xMax,
    yMax,
    epsgCode,
  });

  return MapProxy.getMapTileInformation(mapTileQuery)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get map tile information.', error));
};

/**
 * Transforms a request that gets a map tile.
 *
 * @param mapType
 * @param zoom
 * @param column
 * @param row
 * @param epsgCode
 * @param [includeElevation]
 * @param [lowResolution]
 */
const getMapTile = (
  mapType: EMapDataTypes,
  zoom: number,
  column: number,
  row: number,
  epsgCode: number,
  includeElevation = true,
  lowResolution = true,
) => {
  return MapProxy.getMapTile(mapType, zoom, column, row, epsgCode, includeElevation, lowResolution)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get map tile.', error));
};

const self = {
  getMap,
  getMapTileInformation,
  getMapTile,
};
export default self;
