import { useEffect } from 'react';
import { store } from '../../store/store';
import { useSelector } from 'react-redux';
import { EWorkspaceGeometryActionType } from '../../store/actions/WorkspaceGeometryActionType';
import { EWorkspaceVariableActionType } from '../../store/actions/WorkspaceVariableActionType';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';

/**
 * Shows geometries, variables and meshes considering user hidden items
 *
 */
export const useShowAllButUserHidden = () => {
  const {
    workspaceGeometries,
    workspaceVariables,
    workspaceMeshes,
    userHiddenGeometryIds,
    userHiddenVariableIds,
    userHiddenMeshIds,
  } = useSelector(mapGlobalState);

  useEffect(
    () => {
      // update visibility of geometries
      store.dispatch({ type: EWorkspaceGeometryActionType.SHOW_ALL_BUT_USER_HIDDEN });
    },
    [userHiddenGeometryIds, workspaceGeometries],
  );

  useEffect(
    () => {
      // update visibility of variables
      store.dispatch({ type: EWorkspaceVariableActionType.SHOW_ALL_BUT_USER_HIDDEN });
    },
    [userHiddenVariableIds, workspaceVariables],
  );

  useEffect(
    () => {
      // update visibility of meshes
      store.dispatch({ type: EWorkspaceMeshActionType.SHOW_ALL_BUT_USER_HIDDEN });
    },
    [userHiddenMeshIds, workspaceMeshes],
  );
};

const mapGlobalState = (state) => {
  const { userHiddenGeometryIds, workspaceGeometries } = state.WorkspaceGeometryReducer;

  const { userHiddenVariableIds, workspaceVariables } = state.WorkspaceVariableReducer;

  const { userHiddenMeshIds, workspaceMeshes } = state.WorkspaceMeshReducer;

  return {
    workspaceGeometries,
    workspaceVariables,
    workspaceMeshes,
    userHiddenGeometryIds,
    userHiddenVariableIds,
    userHiddenMeshIds,
  };
};
