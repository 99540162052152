import { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'lodash-es';
import { IWorkspaceAttribute, IWorkspaceOptionAttributeValue } from '../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import {
  IQueryDefinitionApi,
  IAttributeRangeQueryDefinitionApi,
  ISpatialQueryDefinitionApi,
  ISelectAllQueryDefinitionApi,
  IAttributeQueryDefinitionApi,
} from '../../models/IQueryDefinitions';
import { MmgAttributeQueryCriteria } from './attribute-query-criteria';
import { usePrevious } from '../../shared/hooks/hooks';
import { isAttributeQuery, isAttributeCategorical, isPersistedSelectionQuery, isSpatialQuery } from './attribute-utils';
import { MmgPersistedSelectionQueryCriteria } from './persisted-selection-query-criteria';
import { MmgGeometriesQueryCriteria } from './geometries-query-criteria';

type QueryCriteriaConfigurationProps = {
  /**
   * @property initialOperationConfiguration Initial values to use for the configuration. For operations without any initial value provided, default value of the first QueryDefinition will be used.
   */
  initialQueryDefinition?:
    | IAttributeRangeQueryDefinitionApi
    | ISpatialQueryDefinitionApi
    | ISelectAllQueryDefinitionApi;
  attributes?: Array<IWorkspaceAttribute>;
  attributeSettings?: Array<IWorkspaceAttributeSettings>;
  getAttributeOptionValues?: (attributeName: string) => Array<IWorkspaceOptionAttributeValue>;
  getPersistedSelectionCriteriaValues?: () => Array<IWorkspaceOptionAttributeValue>;
  onQueryDefinitionChanged?: (querydefinition: IQueryDefinitionApi) => void;
  canPreviewQuery?: boolean;
  onPreviewQuery?: () => void;
};

/**
 * Allows for configuration of a query criteria given by the initialQueryDefiniton
 * Currently only attribute range criterias and persisted selection criterias are supported.
 * @param props
 */
export const MmgQueryCriteriaConfiguration = (props: QueryCriteriaConfigurationProps) => {
  const {
    initialQueryDefinition,
    attributes,
    attributeSettings,
    getAttributeOptionValues,
    getPersistedSelectionCriteriaValues,
    onQueryDefinitionChanged,
    canPreviewQuery,
    onPreviewQuery,
  } = props;

  const [dirtyQueryDefinition, setDirtyQueryDefinition] = useState(initialQueryDefinition);
  const prevQueryDefinition = usePrevious(initialQueryDefinition) as IQueryDefinitionApi;

  const callOnQueryDefinitionChanged = useCallback(
    (changedQuery: IQueryDefinitionApi) => {
      // only call if query has actually changed
      if (onQueryDefinitionChanged && !isEqual(changedQuery, initialQueryDefinition)) {
        onQueryDefinitionChanged(changedQuery);
      }
    },
    [onQueryDefinitionChanged, initialQueryDefinition],
  );

  const onQueryParametersChanged = useCallback(
    (parameters: { [param: string]: any }) => {
      const changedConfiguration = {
        ...dirtyQueryDefinition,
        ...parameters,
      };
      setDirtyQueryDefinition(changedConfiguration);
      if (onQueryDefinitionChanged) {
        onQueryDefinitionChanged(changedConfiguration);
      }
    },
    [dirtyQueryDefinition, onQueryDefinitionChanged],
  );

  useEffect(
    () => {
      if (!isEqual(prevQueryDefinition, initialQueryDefinition)) {
        const nextQueryDefinition = { ...initialQueryDefinition };
        // set initial values if needed
        if (isAttributeQuery(nextQueryDefinition)) {
          const { name, fromValue, toValue } = nextQueryDefinition as IAttributeRangeQueryDefinitionApi;
          const initialAttribute = (attributes || []).find((a) => a.name === name);
          if (
            !isAttributeCategorical(name, attributeSettings) &&
            initialAttribute &&
            initialAttribute.range &&
            initialAttribute.range.length > 1
          ) {
            if (!fromValue && fromValue !== 0) {
              (nextQueryDefinition as IAttributeRangeQueryDefinitionApi).fromValue = initialAttribute.range[0];
            }
            if (!toValue && toValue !== 0) {
              (nextQueryDefinition as IAttributeRangeQueryDefinitionApi).toValue = initialAttribute.range[1];
            }
          }
        }
        setDirtyQueryDefinition(nextQueryDefinition);
        callOnQueryDefinitionChanged(nextQueryDefinition);
      }
    },
    [initialQueryDefinition, prevQueryDefinition, attributeSettings, attributes, callOnQueryDefinitionChanged],
  );

  const isAttributeCriteria = isAttributeQuery(dirtyQueryDefinition);
  const isSelectionCriteria = isPersistedSelectionQuery(dirtyQueryDefinition);
  const isSpatialCriteria = isSpatialQuery(dirtyQueryDefinition);
  const { name: attributeName } = (dirtyQueryDefinition || {}) as IAttributeQueryDefinitionApi;
  const attribute = attributeName ? (attributes || []).find((a) => a.name === attributeName) : null;

  return (
    <>
      {isAttributeCriteria && (
        <MmgAttributeQueryCriteria
          attribute={attribute}
          attributeSettings={attributeSettings}
          queryDefinition={dirtyQueryDefinition}
          onQueryParametersChanged={onQueryParametersChanged}
          getAttributeOptionValues={getAttributeOptionValues}
          canPreviewQuery={canPreviewQuery}
          onPreviewQuery={onPreviewQuery}
        />
      )}
      {isSelectionCriteria && (
        <MmgPersistedSelectionQueryCriteria
          queryDefinition={dirtyQueryDefinition}
          getPersistedSelectionCriteriaValues={getPersistedSelectionCriteriaValues}
          onQueryParametersChanged={onQueryParametersChanged}
          canPreviewQuery={canPreviewQuery}
          onPreviewQuery={onPreviewQuery}
        />
      )}
      {isSpatialCriteria && (
        <MmgGeometriesQueryCriteria
          queryDefinition={dirtyQueryDefinition}
          onQueryParametersChanged={onQueryParametersChanged}
        />
      )}
    </>
  );
};
