import { Feature } from 'geojson';
import { isEmpty, pick } from 'lodash-es';
import { MMG_FEATURE_CODES, EMmgFeatureCodes } from '../../../managers/open-data-utils';
import { OPEN_DATA_QUERY_TYPES } from '../../../models/IOpenDataQuery';

export const DEFAULTS = {
  PROP_HEIGHT: 3,
};

export const PROP_KEYS = {
  HEIGHT: 'height',
  CODE: 'code',
  LANDCOVER: 'landcover',
  LANDUSE: 'landuse',
  NATURAL: 'natural',
  MMG_AREA: 'mmg_Area',
  MMG_PERIMETER: 'mmg_Perimeter',
};

export const KNOWN_PROPERTIES = [
  PROP_KEYS.HEIGHT,
  PROP_KEYS.CODE,
  PROP_KEYS.LANDCOVER,
  PROP_KEYS.LANDUSE,
  PROP_KEYS.NATURAL,
  PROP_KEYS.MMG_AREA,
  PROP_KEYS.MMG_PERIMETER,
];
export const BUILDING_LEVEL_AVG_HEIGHT = 3;

/**
 * Formats a key, removing spaces, dashes or underscores and uppercasing.
 * @param key
 */
export const _getFormattedKey = (key: string) => key.replace(/( |-|_)/g, '').toUpperCase();

/**
 * Enriches a proerty with a property value based on the provided `getValue` method.
 * Optionally, replaces existing properties with the value.
 *
 * @param feature
 * @param propertyName
 * @param getValue
 * @param replaceExistingValue
 */
export const _enrichWithProperty = (
  feature: Feature<any, any>,
  propertyName: string,
  getValue: (feature: Feature<any, any>) => string | number | boolean,
  replaceExistingValue = false,
): Feature<any, any> => {
  const properties = feature.properties || {};

  const existingValue = properties[propertyName];
  const value =
    existingValue === ''
      ? getValue(feature)
      : existingValue !== undefined
        ? replaceExistingValue
          ? getValue(feature)
          : existingValue
        : getValue(feature);

  return {
    ...feature,
    properties: {
      ...properties,
      [propertyName]: value,
    },
  };
};

/**
 * Determines a feature code based on its properties.
 * It looks both into property values and keys to convert to a code, otherwise uses the default code.
 *
 * @param feature
 */
export const determineFeatureCode = (feature: Feature<any, any>): EMmgFeatureCodes => {
  const { properties } = feature;

  if (!properties || isEmpty(properties)) {
    return MMG_FEATURE_CODES.DEFAULT;
  }

  const { landcover = '', landuse = '' } = properties;

  // Attempt to get code based on landcover / landuse, including plurals.
  const landcoverType =
    MMG_FEATURE_CODES[self._getFormattedKey(landcover)] ||
    MMG_FEATURE_CODES[self._getFormattedKey(`${landcover}s`)] ||
    MMG_FEATURE_CODES[self._getFormattedKey(landuse)] ||
    MMG_FEATURE_CODES[self._getFormattedKey(`${landuse}s`)];

  if (landcoverType) {
    return landcoverType;
  }
  // Fallback, get code based on any key / value in the properties, including plurals.
  // A list of feature keywords maybe from all feature property keys and values.
  const keywords = [
    ...Object.values(properties).filter((val) => val),
    ...Object.keys(properties).filter((property) => properties[property]),
  ];

  const candidates = keywords.reduce((acc: Array<string>, cur) => {
    const stringified = cur.toString();
    const codeMatch =
      MMG_FEATURE_CODES[self._getFormattedKey(stringified)] ||
      MMG_FEATURE_CODES[self._getFormattedKey(`${stringified}s`.toUpperCase())];

    if (codeMatch) {
      return [...acc, codeMatch];
    }

    return acc;
  }, []);

  return candidates[0] || MMG_FEATURE_CODES.DEFAULT;
};

/**
 * Remove all unknown properties.
 *
 * @param feature
 */
const removeUnknownProperties = (feature: Feature<any, any>): Feature<any, any> => {
  const { properties } = feature;

  if (!properties || isEmpty(properties)) {
    return feature;
  }

  return {
    ...feature,
    properties: pick(feature.properties, KNOWN_PROPERTIES),
  };
};

/**
 * Enriches a feature with a code value, representing landcover pre-defined codes.
 *
 * @param feature
 */
export const enrichFeatureWithCode = (feature: Feature<any, any>): Feature<any, any> =>
  self._enrichWithProperty(feature, 'code', self.determineFeatureCode);

/**
 * Enriches a feature with a height value (just a default value, for now).
 *
 * @param feature
 */
export const enrichFeatureWithHeight = (feature: Feature<any, any>): Feature<any, any> =>
  self._enrichWithProperty(feature, 'height', (f: Feature<any, any>) => {
    const height =
      parseInt(f.properties['building:levels'] || 0) * BUILDING_LEVEL_AVG_HEIGHT ||
      (parseInt((f.properties.tags || {})['building:levels']) || 0) * BUILDING_LEVEL_AVG_HEIGHT;

    return height || DEFAULTS.PROP_HEIGHT;
  });

/**
 * Applies all available cleaning or enrichment transformations to a feature, modifying props so it is ready to export (i.e. download as shapefile or geojosn).
 *
 * @param type Type of feature to map
 */
export const mapFeatureToExportable = (type?: string) => (feature: Feature<any, any>): Feature<any, any> => {
  let sequence = [self.enrichFeatureWithCode, self.removeUnknownProperties];

  // Based on the type, some more properties might be enriched with different attributes.
  switch (type) {
    case OPEN_DATA_QUERY_TYPES.BUILDING:
      // Enrich buildings with height.
      sequence = [self.enrichFeatureWithHeight, ...sequence];
      break;

    default:
  }

  return sequence.reduce((acc, cur) => {
    return cur(acc);
  }, feature);
};

const self = {
  _enrichWithProperty,
  _getFormattedKey,

  removeUnknownProperties,

  enrichFeatureWithCode,
  enrichFeatureWithHeight,

  determineFeatureCode,
  mapFeatureToExportable,
};

export default self;
