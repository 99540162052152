/** @jsxImportSource @emotion/react */
import Stepper, { StepperProps as MuiStepperProps } from '@mui/material/Stepper';
import { stepperBaseStyle } from './stepper-styles';
import { css } from '@emotion/react';

interface StepperProps extends MuiStepperProps {
  cssProp?: any;
}


export const MmgStepper = (props: StepperProps) => {
  const { children, cssProp, ...other } = props;

  return (
    <Stepper css={css`${stepperBaseStyle}; ${cssProp && cssProp};`} aria-label="panel-stepper" {...other}>
      {children}
    </Stepper>
  );
};

MmgStepper.muiName = 'MuiStepper';
