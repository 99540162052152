/**
 * Exposes meshing WebSocket endpoints to managers.
 *
 * @module WorkspaceSocketProxy
 * @version 2.0.0
 * @requires SocketProxy
 */
import SocketProxy from './SocketProxy';
import { endpoints } from './config';
// import Proxy from './Proxy';
import { logger } from '../managers/http-utils';

import { ISocketProxy } from '../models/ISocketProxy';
import {
  INotificationAttributeData,
  IStreamingChunk,
  IStreamingHeaderChunk,
  IStreamingDataChunk,
  INotificationItemData,
  INotificationItemDataStructure,
  IStreamedAttributeData,
  IMeshStreamRequestParameter,
  IVtkStreamPartInfo,
  IMeshStreamInfo,
  IVtkStreamChunk,
} from '../models/IWorkspaceNotifications';
import { HubConnectionState, ISubscription } from '@microsoft/signalr';
const { meshAppServiceSocket } = endpoints;

interface IWorkspaceSocketProxy extends ISocketProxy {
  streamWorkspaceVtkRawFileDefault: (
    callback: (itemData: INotificationItemData) => void,
    errorCallback: (error: string) => void,
    dataId: string,
  ) => void;

  streamMeshAsync: (
    tileStreamed: (dataItem: INotificationItemData, streamInfo: IMeshStreamInfo) => void,
    allTilesStreamed: (partIds: Array<string>, size: number, streamInfo: IMeshStreamInfo) => void,
    errorCallback: (error: string) => void,
    requestParameter: IMeshStreamRequestParameter,
  ) => void;

  streamWorkspaceVtkGeometry: (
    callback: (dataItem: INotificationItemDataStructure) => void,
    errorCallback: (error: string) => void,
    dataId: string,
  ) => void;

  streamWorkspaceVtkData: (
    callback: (dataArray: IStreamedAttributeData) => void,
    errorCallback: (error: string) => void,
    dataId: string,
    name: string,
    isCellData: boolean,
  ) => void;
}

/**
 * Instantiate WebSocket to listen to events for the workspace. Upon connection, it is expected that it fires an event with all data corresponding to the workspace.
 * Managers
 *
 * @param workspaceId
 * @param sasToken
 * @param serverTimeoutInMilliseconds
 * @param keepAliveIntervalInMilliseconds
 */
export const workspaceSocketProxy = (
  workspaceId: string,
  sasToken: string,
  serverTimeoutInMilliseconds?: number,
  keepAliveIntervalInMilliseconds?: number,
): IWorkspaceSocketProxy => {
  const API_EVENTS = {
    WORKSPACE_CONNECTED: 'workspaceConnected',
    WORKSPACE: 'workspace',
    WORKSPACE_DELETED: 'workspaceDeleted',

    VTK_FILE_CREATED_OR_UPDATED: 'vtkfileCreatedOrUpdated',
    VTK_FILE_DATAARRAY_ADDED_OR_UPDATED: 'vtkFileDataArrayAddedOrUpdated',
    VTK_FILE_DATAARRAY_DELETED: 'vtkFileDataArrayDeleted',

    GEOMETRIES_DELETED: 'geometriesDeleted',
    VARIABLES_DELETED: 'variablesDeleted',
    MESHES_DELETED: 'meshesDeleted',

    COMMENT_CREATED: 'commentCreated',
    COMMENT_DELETED: 'commentDeleted',

    OPERATION_CREATED_OR_UPDATED: 'operationCreatedOrUpdated',
    OPERATIONS_DELETED: 'operationsDeleted',

    QUERY_COMPLETED: 'queryCompleted', // notification of queries/execute2 und queries/execute
    QUERIES_DELETED: 'queriesDeleted',
    VOLATILE_OPERATION_CREATED_OR_UPDATED: 'volatileOperationCreatedOrUpdated', // status updates for non-persistant operations, e.g. operation triggered by queries/execute

    // The following notifications are currently not used for anything in the UI
    QUERIES_DATA_DELETED: 'queriesDataDeleted', // todo hevo: currently not used in UI
    GEOJSON_OPERATION_CREATED_OR_UPDATED: 'geojsonOperationCreatedOrUpdated', // todo hevo: currently not used in UI

    LABELS_CREATED: 'labelsCreated',

    EXPORT_STARTED_OR_COMPLETED: 'exportStartedOrCompleted',
    SNAPSHOT_OPERATION_CREATED_OR_UPDATED: 'snapshotOperationCreatedOrUpdated',
  };

  const STREAM_EVENTS = {
    VTK_RAW_FILE_DEFAULT: 'WorkspaceVtkRawFileDefault', // gets the raw vtk file including all cell and point data arrays in chunks (size and delay configured by API)
    VTK_DATA: 'WorkspaceVtkData', // gets the point or celldata array for a specific attribute
    VTK_GEOMETRY: 'WorkspaceVtkGeometry', //  gets the raw vtk file like WorkspaceVtkRawFileDefault, but excludes the point and cell data arrays. Is currently not chunked!,
    STREAM_MESH_ASYNC: 'StreamMeshAsync',
  };

  const proxy = SocketProxy(
    `${meshAppServiceSocket}/workspace?workspaceId=${workspaceId}&dhiSasToken=${encodeURIComponent(sasToken)}`,
    serverTimeoutInMilliseconds,
    keepAliveIntervalInMilliseconds,
  );

  const {
    connection,
    connectionStartPromise,
    onMessageEvent,
    offMessageEvent,
    on,
    off,
    emit,
    send,
    stream,
    defaultEvents,
  } = proxy;

  const trackedStreams: Array<ISubscription<any>> = [];

  const trackStream = (streamSubscription: ISubscription<any>) => trackedStreams.push(streamSubscription);

  const streamMeshAsync = (
    tileStreamed: (dataItem: INotificationItemData, streamInfo: IMeshStreamInfo) => void,
    allTilesStreamed: (partIds: Array<string>, size: number, streamInfo: IMeshStreamInfo) => void,
    errorCallback: (error: string) => void,
    meshStreamRequestParameter: IMeshStreamRequestParameter,
  ) => {
    const streamEvent = STREAM_EVENTS.STREAM_MESH_ASYNC;
    let chunk: IMeshStreamInfo | IVtkStreamPartInfo | IVtkStreamChunk,
      meshStreamInfo = {} as IMeshStreamInfo,
      receivedTiles = 0,
      size = 0,
      partIds = [],
      dataarray = [],
      receivedChunkCount = 0,
      vtkStreamPartInfo = {} as IVtkStreamPartInfo,
      index = -1;

    if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
      console.log('start streaming', streamEvent, meshStreamRequestParameter);
    }

    const handleError = (error) => {
      logStreamingError(streamEvent, error, meshStreamRequestParameter);
      const message =
        error && error.message
          ? error.message.replace('An error occurred on the server while streaming results.', '').trim()
          : defaultEvents.STREAM_ERROR;
      emit(defaultEvents.STREAM_ERROR, message);
      errorCallback(message);
    };
    const startTime = performance.now();
    try {
      const streamSubscription = connection.stream(streamEvent, meshStreamRequestParameter).subscribe({
        next: (item) => {
          if (item) {
            // Message parts are considered to be strings, but in some cases they can take falsy values, i.e. `null`. Those values should be skipped.
            try {
              chunk = JSON.parse(item);
            } catch (error) {
              logStreamingError(streamEvent, error, meshStreamRequestParameter);
              throw error; // wont try reading any furter
            }

            if (index < 0) {
              meshStreamInfo = chunk as IMeshStreamInfo;
              index++;
              if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
                console.log('meshStreamInfo chunk received: ', meshStreamInfo);
              }
            } else if (index === 0) {
              vtkStreamPartInfo = chunk as IVtkStreamPartInfo;
              const { partId, dataSize, hasData } = vtkStreamPartInfo;
              partIds = [...partIds, partId]; //meshStreamInfo.itemId + SEPERATOR + id];
              size += dataSize;
              if (!hasData) {
                // backend skipped this part as frontend returned it with previous requests
                dataarray = [];
                receivedChunkCount = 0;
                index = 0;
                tileStreamed(
                  {
                    data: '',
                    itemId: partId, //meshStreamInfo.itemId + SEPERATOR + id,
                    created: meshStreamInfo.created,
                    updated: meshStreamInfo.updated,
                    dataId: meshStreamInfo.dataId,
                    rawDataType: meshStreamInfo.rawDataType,
                  } as INotificationItemData,
                  meshStreamInfo,
                );
                dataarray = [];
                receivedChunkCount = 0;
                index = 0;
              } else {
                index++;
              }

              if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
                console.log('meshStreamInfo chunk received: ', meshStreamInfo);
              }
            } else {
              const { chunkCount, partId } = vtkStreamPartInfo;
              //const id = meshStreamInfo.meshStreamRequestReturnType === 0 ? OVERVIEW : partId;

              const { chunkData } = chunk as IVtkStreamChunk;
              dataarray = [...dataarray, chunkData];
              receivedChunkCount++;

              if (receivedChunkCount === chunkCount) {
                // Join chunks to final tile, reset variables and forward tile for rendering
                receivedTiles++;
                const data = dataarray.join('');
                dataarray = [];
                receivedChunkCount = 0;
                index = 0;
                tileStreamed(
                  {
                    data,
                    itemId: partId, //meshStreamInfo.itemId + SEPERATOR + id,
                    created: meshStreamInfo.created,
                    updated: meshStreamInfo.updated,
                    dataId: meshStreamInfo.dataId,
                    rawDataType: meshStreamInfo.rawDataType,
                  } as INotificationItemData,
                  meshStreamInfo,
                );
              }

              if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
                console.log('data chunk received: ', chunk);
              }
            }
          }
        },
        complete: () => {
          allTilesStreamed(partIds, size, meshStreamInfo);
          // console log time taken
          if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
            const endTime = performance.now();
            console.log(`Time to read:${(endTime - startTime) / 1000}sec.`);
          }
          if (receivedTiles !== (meshStreamInfo.partsCount || 0)) {
            logger.warn('Mismatch in expected and received stream data.', {
              expectedTiles: meshStreamInfo.partsCount,
              receivedTiles,
            });
          }
        },
        error: (error) => {
          handleError(error);
        },
      });

      trackStream(streamSubscription);
      return streamSubscription;
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Gets the raw vtk file including all cell and point data arrays for a specific data item
   * Streaming data using default chunk implementatation (20 ms delay, 1 MB chunk size)
 values switch to WorkspaceVtkRawFileChunked(id, delay, chunk size) (not supported in this proxy yet)
   * @param callback
   * @param errorCallback
   * @param dataId
   */
  const streamWorkspaceVtkRawFileDefault = (
    callback: (dataItem: INotificationItemData) => void,
    errorCallback: (error: string) => void,
    dataId: string,
  ) => {
    const streamEvent = STREAM_EVENTS.VTK_RAW_FILE_DEFAULT;

    let chunk = {} as IStreamingChunk,
      header = {} as IStreamingHeaderChunk,
      dataarray = [],
      receivedChunkCount = 0;

    if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
      console.log('start streaming', streamEvent, dataId);
    }

    const handleError = (error) => {
      logStreamingError(streamEvent, error, dataId);
      emit(defaultEvents.STREAM_ERROR, error);
      errorCallback(error);
    };
    const startTime = performance.now();

    // chunks are not guaranteed to come in correct order, so each chunk include information on the chunkIndex.
    // todo hevo According to OAR the order can be trusted. So some of this complexity could be removed. Needs to be aligned with backend, though
    try {
      const streamSubscription = connection.stream(streamEvent, dataId).subscribe({
        next: (item) => {
          if (item) {
            // Message parts are considered to be strings, but in some cases they can take falsy values, i.e. `null`. Those values should be skipped.
            try {
              chunk = JSON.parse(item);
            } catch (error) {
              logStreamingError(streamEvent, error, dataId);
              throw error; // wont try reading any furter
            }

            const { chunkIndex, chunkCount } = chunk || {};

            // headerchunk will have chunkIndex -1
            if (chunkIndex < 0) {
              header = chunk as IStreamingHeaderChunk;

              if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
                console.log('header chunk received: ', header);
              }
            } else {
              // Data might be split in chunks and these might come in random order.
              // So we add to coreect index in dataarray and join that later on
              if (dataarray.length === 0) {
                dataarray = new Array(chunkCount);
              }

              const { chunkData } = chunk as IStreamingDataChunk;
              dataarray[chunkIndex] = chunkData;

              receivedChunkCount++;

              if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
                console.log('data chunk received: ', chunk);
              }
            }
          }
        },
        complete: () => {
          // Join chunks to final data
          const data = dataarray.join('');

          // console log time taken
          if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
            const endTime = performance.now();

            console.log(
              `No of chunks read: ${header.chunkCount}. Time to read:${(endTime - startTime) / 1000}sec. Size read: ${
                data.length
              }. Expected dataSize: ${header.dataSize}`,
            );
          }

          const { chunkCount: expectedChunkCount, dataSize: expectedDataSize } = header;

          if (receivedChunkCount !== (expectedChunkCount || 0) || data.length !== (expectedDataSize || 0)) {
            logger.warn('Mismatch in expected and received stream data.', {
              expectedDataSize,
              expectedChunkCount,
              receivedDataSize: data.length,
              receivedChunkCount,
            });
          }

          callback({
            ...header,
            data,
          } as INotificationItemData);
        },
        error: (error) => {
          handleError(error);
        },
      });

      trackStream(streamSubscription);
      return streamSubscription;
    } catch (error) {
      handleError(error);
    }
  };

  /**
   * Gets the raw vtk file similar to streamWorkspaceVtkRawFileDefault, but excludes the point and cell data arrays
   *
   * @param callback
   * @param errorCallback
   * @param dataId
   */
  const streamWorkspaceVtkGeometry = (
    callback: (dataItem: INotificationItemDataStructure) => void,
    errorCallback: (error: string) => void,
    dataId: string,
  ) => {
    const streamEvent = STREAM_EVENTS.VTK_GEOMETRY;

    let chunkNumber = 0,
      header = {},
      data = '';

    if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
      console.log('start streaming', streamEvent, dataId);
    }

    const streamSubscription = connection.stream(streamEvent, dataId).subscribe({
      next: (item) => {
        if (item) {
          // Message parts are considered to be strings, but in some cases they can take falsy values, i.e. `null`. Those values should be skipped.
          if (chunkNumber === 0) {
            try {
              header = JSON.parse(item);
            } catch (error) {
              logger.warn('Failed to parse data header', error);
            }
          } else {
            // That might be split in several chunks.
            data += item;
          }
        }

        chunkNumber++;
      },
      complete: () => {
        callback({
          ...header,
          data,
        } as INotificationItemDataStructure);
      },
      error: (error) => {
        logStreamingError(streamEvent, error, dataId);
        emit(defaultEvents.STREAM_ERROR, { error });
        errorCallback(error);
      },
    });

    trackStream(streamSubscription);
    return streamSubscription;
  };
  /**
   * gets the point or celldata array for a specific attribute of a data item
   * @param callback
   * @param errorCallback
   * @param dataId The dataid of the data item.
   * @param name The attribute name
   * @param isCellData
   */
  const streamWorkspaceVtkData = (
    callback: (dataItem: IStreamedAttributeData) => void,
    errorCallback: (error: string) => void,
    dataId: string,
    name: string,
    isCellData: boolean,
  ) => {
    const streamEvent = STREAM_EVENTS.VTK_DATA;

    let data = '';

    if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
      console.log('start streaming', streamEvent, dataId, name);
    }

    const streamSubscription = connection.stream(streamEvent, dataId, name, isCellData).subscribe({
      next: (item) => {
        if (item) {
          // Data might be split in several chunks.
          data += item;
        }
      },
      complete: () => {
        callback({
          data,
        } as INotificationAttributeData);
      },
      error: (error) => {
        logStreamingError(streamEvent, error, dataId, name, isCellData);
        emit(defaultEvents.STREAM_ERROR, { error });
        errorCallback(error);
      },
    });

    trackStream(streamSubscription);
    return streamSubscription;
  };

  const logStreamingError = (eventName: string, error, ...params) => {
    const { message } = error;

    // This will happen when user leaves workspace while streaming. Do not want to log this as error
    if (connection.state === HubConnectionState.Disconnected) {
      logger.warn('Disconnected while streaming.', {
        message,
        eventName,
        ...params,
      });

      return;
    }

    const data = `${JSON.stringify(error)}`;

    logger.error(
      'Error while streaming.',
      {
        message,
        response: {
          status: -1,
          statusText: message,
          data,
        },
        request: {},
      },
      { eventName, message, ...params },
    );
  };

  /**
   * Stops a connection, clearing all events / listeners on the connection.
   * Before closing, unsubscribes all streams
   * Also clears tracking of the connection.
   *
   * @param eventsNames
   */
  const close = (eventsNames?: Array<string>) => {
    try {
      trackedStreams && trackedStreams.forEach((x) => x.dispose());
    } catch (error) {
      console.error('could not unsubscribe streams', error);
    }
    return proxy.close(eventsNames);
  };

  // Handle default events.
  on(defaultEvents.ERROR, (error?) => {
    logger.error('Workspace connection error.', {
      message: error,
      response: {
        status: -1,
        statusText: error,
        data: `${JSON.stringify(connection)}`,
      },
      request: {},
    });
  });

  return {
    connection,
    connectionStartPromise,
    onMessageEvent,
    offMessageEvent,
    close,
    on,
    off,
    emit,
    send,
    stream,
    streamWorkspaceVtkRawFileDefault,
    streamMeshAsync,
    streamWorkspaceVtkGeometry,
    streamWorkspaceVtkData,
    defaultEvents,
    events: API_EVENTS,
    streamEvents: STREAM_EVENTS,
  } as IWorkspaceSocketProxy;
};
