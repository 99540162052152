/** @jsxImportSource @emotion/react */
import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import { Feature, FeatureCollection } from 'geojson';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import { fetchLayer, showLayer } from '../../shared/layers/layer-utils';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { useParams } from 'react-router-dom';
import EditModes, { EDITMODE_IDS, EEditModeIds } from '../../shared/edit-modes/edit-modes';
import MmgSelectForEditingModes, { ESelectForEditingModeIds } from '../../shared/edit-modes/select-for-editing-modes';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import {
  clearFeaturesForEditing,
  getFeaturesForEditing,
  setEditMode,
  updateValue,
} from '../../store/actions/editingItems';
import { EItemType } from '../../models/IOperationDescriptions';
import { MmgGroup } from '../../shared/groups/group';
import {
  ContentBottomContainerStyle,
  ContentBottomDescriptionStyle,
  PanelBottomActionsStyle,
  PanelBottomContainerStyle,
  PanelBottomDescriptionStyle,
  buttonStyle,
  descriptionWithHelpStyle,
  fieldsStyle,
  outerPaddingStyle,
} from '../../shared/edit-modes/editStyles';
import { EGeometryItemTypes } from '../../models/IGeometries';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { INodeNeighbours } from '../../store/reducers/MeshMapToolReducer';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { EViewerCursorActionType } from '../../store/actions/ViewerCursorActionType';
import { MmgKeyboardEditHelp } from '../../shared/edit-modes/keyboard-edit-help';
import { EDIT_LAYER_ID, EDIT_MODE_MODIFIED } from '../../store/reducers/EditReducer';
import { POINTDATA } from '../../variables/create/variable-draw-constants';
import { translateWithPrefix } from '../../translations/utils';
import MikeVisualizer from '../../MikeVisualizer';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import MikeButton from '../../shared-components/mike-button';
import MikeVisualizer2DDrawCore from '../../MikeVisualizer/lib/2d/draw/MikeVisualizer2DDrawCore';
import MikeVisualizer2DDataCore from '../../MikeVisualizer/lib/2d/data/MikeVisualizer2DDataCore';

const { onDrawnDataUpdated, clearDrawnVectorLayerData, disable2DPolygonSelection, delete2DData } = MikeVisualizer;

const DEFAULT_NEIGHBOUR_OPACITY = 0.8;

/**
 * @name MmgConnectedConfirmEditNodes
 * @summary Allows retriving a mesh node & connected triangles, modifying it and then saving (confiming) changes to the mesh.
 */
export function MmgConnectedConfirmEditNodes() {
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const { workspaceId } = useParams();
  const meshEditNodesTargetId = useSelector((state: IGlobalState) => state.MeshMapToolReducer.meshEditNodesTargetId);
  const nodeNeighbours: INodeNeighbours = useSelector((state: IGlobalState) => state.EditReducer.nodeNeighbours);

  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const meshDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId: meshEditNodesTargetId }),
  );

  const {
    loadingFeaturesForEditing,
    editMode,
    propertiesForEditing,
    selectionMode,
    meshNodeSaveInProgress,
    nodesToUpdate,
    newNodeX,
    newNodeY,
    editsToApply,
  } = useSelector((state: IGlobalState) => state.EditReducer);

  const selectedFeaturesCount = useMemo(
    () => {
      return nodeNeighbours !== null ? 1 : 0;
    },
    [nodeNeighbours],
  );

  const getFields = useCallback(
    () => {
      /**
       * Callback for when a property-to-append value has changed.
       *
       * @param propertyKey
       */
      const onPropertyToAppendValueChanged = (propertyKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        store.dispatch(updateValue(propertyKey, value));
      };
      const commonTextFieldProps = {
        margin: 'normal',
        fullWidth: true,
      } as TextFieldProps;
      return (
        <div css={fieldsStyle}>
          {Object.keys(propertiesForEditing).map((key) => {
            const value = propertiesForEditing[key];
            const niceLabel = translateWithPrefix('PROP', key);
            const label = t('VARIABLE_DRAW_PROPERTY_LABEL', 1, { property: niceLabel });

            return (
              <TextField
                disabled={editMode !== EEditModeIds.EDIT_MODE_ADD && selectedFeaturesCount === 0}
                label={label}
                value={value}
                placeholder={label}
                onChange={onPropertyToAppendValueChanged(key)}
                key={key}
                {...commonTextFieldProps}
              />
            );
          })}
        </div>
      );
    },
    [propertiesForEditing, selectedFeaturesCount, editMode],
  );

  const { hasNodeToUpdate, canContinueEditing } = useMemo(
    () => {
      if (nodesToUpdate.length > 0) {
        const lastNodeToUpdate = nodesToUpdate[nodesToUpdate.length - 1];
        const canContinue = lastNodeToUpdate.editMode === EDIT_MODE_MODIFIED ? true : false;
        return { hasNodeToUpdate: true, canContinueEditing: canContinue };
      }
      return { hasNodeToUpdate: false, canContinueEditing: true };
    },
    [nodesToUpdate],
  );

  useEffect(
    () => {
      const getMeshRgbaColor = (drawnData: IDrawnDataItem): string => {
        if (!drawnData || !drawnData.surfaceColor) {
          return null;
        }

        const [r, g, b] = drawnData.surfaceColor;

        return `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${DEFAULT_NEIGHBOUR_OPACITY}`;
      };
      const colorFromMeshSurface = getMeshRgbaColor(meshDrawnData);
      store.dispatch({ type: EWorkspaceMeshActionType.SET_COLOR_FROM_MESH_SURFACE, data: colorFromMeshSurface });
      if (meshDrawnData && meshDrawnData.dataArrays) {
        store.dispatch({
          type: EMapToolActionType.SET_PROPERTIES_FOR_FEATURES_FOR_EDITING,
          data: { dataArrays: meshDrawnData.dataArrays, type: POINTDATA },
        });
      }
    },
    [meshDrawnData],
  );

  const handleApply = () => {
    store.dispatch({ type: EWorkspaceMeshActionType.SUBMIT_NODE_UPDATE });
    store.dispatch({
      type: EMapToolActionType.SET_SELECTION_MODE,
      data: ESelectForEditingModeIds.SELECT_BY_POINT,
    });
  };

  const handleUndo = useCallback(
    () => {
      if (nodesToUpdate.length > 0) {
        const lastNodeToUpdate = nodesToUpdate[nodesToUpdate.length - 1];
        store.dispatch({ type: EWorkspaceMeshActionType.UNDO_NODE_SUBMIT, data: lastNodeToUpdate.nodeIndex });
        store.dispatch({
          type: EMapToolActionType.SET_SELECTION_MODE,
          data: ESelectForEditingModeIds.SELECT_BY_POINT,
        });
      }
    },
    [nodesToUpdate],
  );

  /**
   * Exists panel and cancels node editing.
   */
  const cancelDrawing = () => {
    store.dispatch({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  };

  /**
   * Confirms node editing an send the update x/y to the API.
   */
  const confirmDrawing = () => {
    store.dispatch({ type: EWorkspaceMeshActionType.SAVE_NODE_UPDATES, data: workspaceId });
  };

  useEffect(() => {
    const drawUpdateCallback = (newFeatureCollection: FeatureCollection<any, any>) => {
      if (newFeatureCollection && newFeatureCollection.features.length > 0) {
        const pointFeature = newFeatureCollection.features[0];
        if (pointFeature.geometry && pointFeature.geometry.coordinates) {
          const newCoordinates = pointFeature.geometry.coordinates;
          store.dispatch({
            type: EWorkspaceMeshActionType.SET_NEW_NODE_COORDS,
            data: { newCoordinates, id: pointFeature.id },
          });
        }
      }
    };

    const initializeOpenLayersMapsAndeditMode = async () => {
      clearDrawnVectorLayerData();
      // These open layers maps are required for drawing the selection geometry
      const { _getOrSetupDrawMap } = MikeVisualizer2DDrawCore;
      const { _getOrSetupOpenLayersDataMap } = MikeVisualizer2DDataCore;
      await _getOrSetupDrawMap();
      await _getOrSetupOpenLayersDataMap();
      store.dispatch({
        type: EMapToolActionType.SET_SELECTION_MODE,
        data: ESelectForEditingModeIds.SELECT_BY_POINT,
      });
      store.dispatch(setEditMode(EEditModeIds.EDIT_MODE_MODIFY, EGeometryItemTypes.POINT, EItemType.MESH));
    };
    initializeOpenLayersMapsAndeditMode();

    const unsubscribers = [];
    const unsubscribeOnDrawnDataUpdated = onDrawnDataUpdated(drawUpdateCallback);
    unsubscribers.push(unsubscribeOnDrawnDataUpdated);

    return () => {
      delete2DData(EDIT_LAYER_ID);
      store.dispatch({ type: EMapToolActionType.DISABLE_POINT_DRAWING });
      store.dispatch({ type: EViewerCursorActionType.UNSET_CROSSHAIR });
      unsubscribers.forEach((unsubscribe) => unsubscribe());
      store.dispatch(clearFeaturesForEditing());
      store.dispatch({ type: EWorkspaceMeshActionType.FINISH_NODE_UPDATES });
    };
  }, []);

  useEffect(
    () => {
      if (!loadedData.includes(meshEditNodesTargetId)) {
        fetchLayer(meshEditNodesTargetId, ELEMENT_CATEGORIES.MESH);
      } else {
        showLayer(ELEMENT_CATEGORIES.MESH, meshEditNodesTargetId, false);
      }
    },
    [loadedData, meshEditNodesTargetId],
  );

  const handleChangeEditMode = (mode: EEditModeIds) => {
    clearDrawnVectorLayerData();
    store.dispatch(setEditMode(mode, EGeometryItemTypes.POINT, EItemType.MESH));
  };

  const handleChangeSelectForEditingMode = (mode: ESelectForEditingModeIds) => {
    store.dispatch({ type: EMapToolActionType.SET_SELECTION_MODE, data: mode });
  };

  const handleSelectionGeometryChanged = useCallback(
    (feature: Feature<any>) => {
      store.dispatch({ type: EMapToolActionType.SET_SELECTION_MODE, data: null });
      disable2DPolygonSelection();
      if (selectionMode) {
        clearDrawnVectorLayerData();
        if (canContinueEditing) {
          store.dispatch(
            getFeaturesForEditing(
              workspaceId,
              meshEditNodesTargetId,
              EItemType.MESH,
              { type: 'FeatureCollection', features: [feature] },
              [], // previousEdits are only relevant for geometries and variables
              EGeometryItemTypes.POINT,
            ),
          );
        }
      }
    },
    [canContinueEditing, meshEditNodesTargetId, selectionMode, workspaceId],
  );

  const getApplyTitle = useCallback(
    () => {
      switch (editMode) {
        case EEditModeIds.EDIT_MODE_MODIFY:
          return t('APPLY_CHANGES_AND_CREATE_NEW_SELECTION');
        case EEditModeIds.EDIT_MODE_ADD:
          return t('APPLY_CHANGES_AND_SUBMIT');
        case EEditModeIds.EDIT_MODE_DELETE:
          return t('APPLY_CHANGES_AND_SUBMIT');
      }
    },
    [editMode],
  );

  const getApplyGroupTitle = useCallback(
    () => {
      switch (editMode) {
        case EEditModeIds.EDIT_MODE_MODIFY:
          return t('CHANGE_MESH_NODE_GROUP_MODIFY');
        case EEditModeIds.EDIT_MODE_ATTRIBUTION:
          return t('CHANGE_MESH_NODE_GROUP_ATTRIBUTION');
        case EEditModeIds.EDIT_MODE_ADD:
          return t('CHANGE_MESH_NODE_GROUP_ATTRIBUTION');
        case EEditModeIds.EDIT_MODE_DELETE:
          return t('CHANGE_MESH_NODE_GROUP_DELETE');
      }
    },
    [editMode],
  );

  const disableApplyButton = useCallback(
    () => {
      if (!canContinueEditing) {
        return true;
      }
      if (editMode === EEditModeIds.EDIT_MODE_ADD) {
        const hasValue = newNodeX && newNodeY;
        return !hasValue && editsToApply;
      } else if (editMode === EEditModeIds.EDIT_MODE_ATTRIBUTION) {
        return !editsToApply;
      }
      const currentNodeIndex = nodeNeighbours && nodeNeighbours.nodeIndex >= 0 ? nodeNeighbours.nodeIndex : null;
      return currentNodeIndex === null || meshNodeSaveInProgress;
    },
    [canContinueEditing, editMode, nodeNeighbours, meshNodeSaveInProgress, newNodeX, newNodeY, editsToApply],
  );

  const getEditInstructionsText = useCallback(
    () => {
      const getLabel = () => {
        switch (editMode) {
          case EEditModeIds.EDIT_MODE_MODIFY:
            return t('MOVE_MESH_NODE');
          case EEditModeIds.EDIT_MODE_ADD:
            return t('FINISH_EDITS_AFTER_ADDING_MESH_NODE');
          case EEditModeIds.EDIT_MODE_DELETE:
            return t('FINISH_EDITS_AFTER_DELETING_MESH_NODE');
        }
      };
      if (editMode !== EEditModeIds.EDIT_MODE_ATTRIBUTION) {
        return (
          <Typography variant="body2" css={outerPaddingStyle}>
            {getLabel()}
          </Typography>
        );
      } else {
        return null;
      }
    },
    [editMode],
  );

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <Typography css={outerPaddingStyle} variant="h4">
          {t('MESH_EDIT_NODES_CONFIRMATION_TITLE')}
        </Typography>
        <EditModes
          onEditModeChanged={handleChangeEditMode}
          editMode={editMode as any}
          itemType={EItemType.MESH}
          createNew={false}
          disabled={loadingFeaturesForEditing}
        />
      </MikeStickyPanelHeaderContainer>
      <MikeStickyPanelContent>
        {editMode === EDITMODE_IDS.EDIT_MODE_ADD ? (
          <MmgGroup groupName={t('ADD_MESH_NODES_GROUP')}>
            <div css={descriptionWithHelpStyle}>
              <Typography variant="body2">{t('ADD_MESH_NODES')}</Typography>
              <MmgKeyboardEditHelp
                editMode={EDITMODE_IDS.EDIT_MODE_ADD}
                itemType={EItemType.MESH}
                geometryType={EGeometryItemTypes.POINT}
              />
            </div>
          </MmgGroup>
        ) : (
          <MmgGroup groupName={t('SELECT_MESH_NODE_GROUP')}>
            <div css={outerPaddingStyle}>
              <MmgSelectForEditingModes
                onSelectForEditingModeChanged={handleChangeSelectForEditingMode}
                selectionMode={selectionMode}
                onSelectionGeometryChanged={handleSelectionGeometryChanged}
                disabled={loadingFeaturesForEditing}
                itemType={EItemType.MESH}
              />
            </div>
          </MmgGroup>
        )}

        <MmgGroup groupName={getApplyGroupTitle()}>
          <div css={ContentBottomContainerStyle}>
            {[EEditModeIds.EDIT_MODE_ATTRIBUTION, EEditModeIds.EDIT_MODE_ADD].includes(editMode as any) ? getFields() : null}
            {getEditInstructionsText()}
            <Typography css={ContentBottomDescriptionStyle} variant="body2">
              {getApplyTitle()}
            </Typography>
            <div css={PanelBottomActionsStyle}>
              <MikeButton
                css={buttonStyle}
                variant="outlined" color="secondary"
                disabled={!hasNodeToUpdate || meshNodeSaveInProgress}
                onClick={handleUndo}
              >
                {t('MESH_NODE_EDIT_UNDO')}
              </MikeButton>
              <MikeButton
                css={buttonStyle}
                variant="outlined" color="secondary"
                onClick={handleApply}
                disabled={disableApplyButton()}
              >
                {t('APPLY_CHANGES')}
              </MikeButton>
            </div>
          </div>
        </MmgGroup>
      </MikeStickyPanelContent>
      <div css={PanelBottomContainerStyle}>
        <Typography css={PanelBottomDescriptionStyle} variant="body2">
          {t('FINISH_EDIT_SESSION')}
        </Typography>
        <div css={PanelBottomActionsStyle}>
          <MikeButton variant="outlined" color="secondary" onClick={cancelDrawing} disabled={meshNodeSaveInProgress}>
            {t('CANCEL')}
          </MikeButton>
          <MikeButton
            variant="contained" color="secondary"
            disabled={!hasNodeToUpdate || meshNodeSaveInProgress}
            onClick={confirmDrawing}
            active={meshNodeSaveInProgress}
          >
            {t('CONFIRM_EDITING_MESH_NODE')}
          </MikeButton>
        </div>
      </div>
    </MikeStickyPanel>
  );
}
