import { takeLeading, put, call, takeEvery } from 'redux-saga/effects';
import { getProjectList, getRecursiveSubprojectsList } from '../../proxies/ProjectProxy';
import { EProjectsActionType } from '../../store/actions/ProjectsActionType';
import {
  setProjectsRecursively,
  setLoadingProjectsRecursively,
  setLoadingProjectsRecursivelyFailed,
} from '../../store/actions/projects';

/**
 * Defines sagas for projects
 */
export default function* watchProjects() {
  // we use takeLeading to not send a new request to api if one is already ongoing.
  // Will block until saga completes, then starts listening again
  // If searching projects someday will use api, this need to change (most likely to takeLatest)
  yield takeLeading('project/LOAD_LIST', loadProjects);
  yield takeEvery(EProjectsActionType.GET_PROJECTS_RECURSIVELY, loadProjectsRecursively);
}

function* loadProjectsRecursively(action) {
  const projectId = action.data;
  yield put(setLoadingProjectsRecursively());

  try {
    const projects = yield call(getRecursiveSubprojectsList, projectId);
    const list = projects && projects.data && projects.data.data ? projects.data.data : [];
    if (projects && projects.data && projects.data.data) {
      yield put(setProjectsRecursively(list));
    }
  } catch (error) {
    console.log(error);
    yield put(setLoadingProjectsRecursivelyFailed());
  } finally {
    yield put(setLoadingProjectsRecursively(false));
  }
}

/**
 * Generator function for loading all projects
 * @param _action
 */
function* loadProjects() {
  yield put({ type: 'project/LIST_LOADING' });

  try {
    const projects = yield call(getProjectList);
    const list = projects && projects.data && projects.data.data ? projects.data.data : [];
    if (projects && projects.data && projects.data.data) {
      yield put({
        type: 'project/LIST_SUCCESS',
        projectList: list,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: 'project/LIST_FAILED' });
  }
}
