/* eslint-disable react-refresh/only-export-components */
import { useCallback, useState } from 'react';
import {  useSelector } from 'react-redux';
import { store } from '../../../store/store';
import { t } from '../../../translations/i18n';
import TimelineIcon from '@mui/icons-material/Timeline';
import WorkspaceVariablesManager from '../../../managers/WorkspaceVariablesManager';
import { EElementCategories } from '../../..//shared/panels/mesh-panel-constants';
import { getRouteByPath, ROUTES } from '../../..//app/routes';
import LayerUtils from '../../..//shared/layers/layer-utils';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import {
  getEditLayerAction,
  getDuplicateLayerAction,
  getDeleteLayerAction,
  getToggleVisibilityLayerAction,
  ILayerAction,
  getFetchLayerAction,
  getReplaceLayerAction,
} from '../../../shared/layers/actions/layer-default-actions';
import { MmgConfirmDeleteVariableDialog } from '../../../variables/details/variable-delete-dialog';
import { IDrawnDataItem } from '../../../models/IWorkspaceData';
import { CONFIRM_PANEL_IDS } from '../../../shared/confirm-containers/confirm-constants';
import { SUBMENUS } from '../../../workspaces/viewer/tools/viewer-tool-constants';
import Operations from '../../../icons/Operations.svg?react';
import { getPropertiesAction, getZoomToLayerAction } from '../../../workspaces/details/actions/layer-actions';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { getProjectContent, setProjectContentDialogOpen } from '../../../store/actions/projectContent';
import { EDATASETUSAGE_TYPE } from '../../../models/IGetDataset';
import { EWorkspaceActionType } from '../../../store/actions/WorkspaceActionType';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';
import { IGlobalState } from '../../../store/reducers';
import { IGetProject } from '../../../shared-components/mike-project-explorer/model';

const { isLayerInProgress, isLayerFailed } = LayerUtils;

type LayerVariableActionsProps = {
  layerId: string;
  variable: IWorkspaceEnrichedVariable;
  canToggleLayerVisibility?: boolean;
  canEdit?: boolean;
  updateUserSettingsOnToggle?: boolean;
  onVariableEdit?: (variableId: string) => void;
  variableDrawnData?: IDrawnDataItem;
  loadedData?: Array<string>;
};

/**
 * @name MmgConnectedLayerVariableActions
 * @summary Variable layer actions.
 *
 * @param props
 */
export function MmgConnectedLayerVariableActions(props: LayerVariableActionsProps) {
  const { layerId, variable, variableDrawnData, canToggleLayerVisibility = true, updateUserSettingsOnToggle = false, canEdit = true } = props;
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate();
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const hiddenVariableIds: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer.hiddenWorkspaceVariables,
  );
  const project: IGetProject = useSelector((state: IGlobalState) => state.ProjectContentReducer.project);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onVariableDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const onVariableReplace = useCallback(
    (variableId: string) => {
      store.dispatch(getProjectContent(project));
      store.dispatch(
        setProjectContentDialogOpen(true, true, { elementId: variableId, usageType: EDATASETUSAGE_TYPE.Variable }),
      );
    },
    [project],
  );

  const isMissing = !variable;

  const layerActions = getLayerActions(
    projectId,
    workspaceId,
    variable,
    variableDrawnData,
    canToggleLayerVisibility,
    canEdit,
    hiddenVariableIds,
    updateUserSettingsOnToggle,
    props.onVariableEdit,
    onVariableDelete,
    loadedData,
    onVariableReplace,
    navigate
  );

  return (
    <>
      <MikeLayerActionMenu layerId={layerId} layerActions={layerActions} disabled={isMissing} />
      <MmgConfirmDeleteVariableDialog
        open={deleteConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        variable={variable}
        onClose={() => setDeleteConfirmOpen(false)}
      />
    </>
  );
}

const getLayerActions = (
  projectId: string,
  workspaceId: string,
  variable: IWorkspaceEnrichedVariable,
  _variableDrawnData: IDrawnDataItem,
  _canToggleLayerVisibility: boolean,
  canEdit: boolean,
  _hiddenVariableIds: Array<string>,
  _updateUserSettingsOnToggle: boolean,
  onVariableEdit: (variableId: string) => void,
  onVariableDelete: () => void,
  _loadedData: Array<string>,
  onVariableReplace: (variableId: string) => void,
  navigate: NavigateFunction
) => {
  if (!variable) {
    return [];
  }

  const isFailed = isLayerFailed(variable);
  const isInProgress = isLayerInProgress(variable);
  const canEditData = !variable.isGrid;
  const layerId = variable.id;
  const layerActions = [];

  const toggleOperationAction = getVariableOperationAction(projectId, workspaceId, layerId, navigate);
  layerActions.push(toggleOperationAction);

  const zoomToLayerAction = getZoomToLayerAction(layerId, workspaceId, !_variableDrawnData || isInProgress || isFailed);
  layerActions.push(zoomToLayerAction);

  if (canEditData) {
    const editLayerDataAction = getEditVariableDataAction(layerId, isInProgress);
    layerActions.push(editLayerDataAction);
  }

  if (canEdit) {
    if (!onVariableEdit) {
      console.warn('When allowing editing, onVariableEdit must be provided');
    } else {
      const editLayerAction = getEditVariableAction(layerId, onVariableEdit, isInProgress);
      layerActions.push(editLayerAction);
    }
    if (!onVariableReplace) {
      console.warn('When allowing replacing, onGeometryReplace must be provided');
    } else {
      const replaceLayerAction = getReplaceVariableAction(layerId, onVariableReplace, isInProgress);
      layerActions.push(replaceLayerAction);
    }
  }

  const propertiesAction = getPropertiesAction(projectId, workspaceId, layerId, EElementCategories.VARIABLE, navigate);
  layerActions.push(propertiesAction);

  const duplicateLayerAction = getDuplicateVariableAction(projectId, workspaceId, layerId, navigate, isInProgress || isFailed);
  layerActions.push(duplicateLayerAction);

  const deleteLayerAction = getDeleteVariableAction(onVariableDelete);
  layerActions.push(deleteLayerAction);

  return layerActions;
};

export const getReplaceVariableAction = (
  layerId: string,
  onReplaceVariable: (variableId: string) => void,
  disabled?: boolean,
): ILayerAction => {
  const editLayerAction = getReplaceLayerAction(() => onReplaceVariable && onReplaceVariable(layerId), disabled);
  return editLayerAction;
};

export const getToggleVariableVisibilityAction = (
  layerId: string,
  hiddenVariableIds,
  updateUserSettings: boolean,
  _disabled?: boolean,
  loadedData?: Array<string>,
): ILayerAction => {
  const toggleVisibilityAction = getToggleVisibilityLayerAction(
    EElementCategories.VARIABLE,
    layerId,
    hiddenVariableIds,
    false,
    updateUserSettings,
    loadedData,
  );
  return toggleVisibilityAction;
};

export const getFetchVariableAction = (onVariableFetch: () => void, disabled?: boolean): ILayerAction => {
  const fetchLayerAction = getFetchLayerAction(() => onVariableFetch(), disabled);
  return fetchLayerAction;
};

export const getEditVariableDataAction = (layerId: string, disabled?: boolean): ILayerAction => {
  const onEditData = async () => {
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.VARIABLE_EDIT,
    });
    store.dispatch({
      type: EMapToolActionType.VARIABLE_EDIT,
      variableEditTargetId: layerId,
    });
    store.dispatch({
      type: EMapToolActionType.SET_OPEN_SUBMENU,
      openSubmenu: SUBMENUS.VARIABLE_DRAW,
    });
  };

  return {
    actionId: 'VARIABLE_EDIT_DATA',
    actionDisplayName: t('VARIABLE_EDIT_DATA'),
    actionIcon: <TimelineIcon />,
    onAction: onEditData,
    disabled,
  };
};

export const getEditVariableAction = (
  layerId: string,
  onVariableEdit: (variableId) => void,
  disabled?: boolean,
): ILayerAction => {
  const editLayerAction = getEditLayerAction(() => onVariableEdit(layerId), disabled);
  return editLayerAction;
};

export const getVariableOperationAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onOperation = (variableId: string) => {
    return navigate(getRouteByPath(ROUTES.variablesOperationPanel.path, { variableId, workspaceId, projectId }, ROUTES.workspace.path));
  };

  const operationLayerAction = {
    actionId: 'VARIABLE_OPERATIONS',
    actionDisplayName: t('VARIABLE_OPERATIONS'),
    actionIcon: <Operations />,
    onAction: () => onOperation(layerId),
    disabled,
  };
  return operationLayerAction;
};

export const getDuplicateVariableAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onVariableDuplicate = (variableId: string) => {
    return WorkspaceVariablesManager.duplicateVariable(workspaceId, variableId)
      .then(() => {
        const toast = { text: t('DUPLICATED', 1, { thing: t('VARIABLE') }) };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));  
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('DUPLICATE') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  const duplicateLayerAction = getDuplicateLayerAction(() => onVariableDuplicate(layerId), disabled);
  return duplicateLayerAction;
};

export const getDeleteVariableAction = (onVariableDelete: () => void, disabled?: boolean): ILayerAction => {
  const deleteLayerAction = getDeleteLayerAction(() => onVariableDelete(), disabled);
  return deleteLayerAction;
};
