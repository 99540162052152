/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MapPinOutline from '../../icons/MapPinOutline.svg?react';
import { EToolButtonType } from '../../shared/tools/IToolButton';
import { CONFIRM_PANEL_IDS } from '../../shared/confirm-containers/confirm-constants';
import { ViewerToolsButtonStyle, ViewerToolsButtonActiveStyle } from '../../workspaces/viewer/tools/viewer-tools-styles';
import { MmgToolButton } from '../../shared/tools/tool-button';
import { t } from '../../translations/i18n';
import MikeVisualizer from '../../MikeVisualizer/lib/MikeVisualizer';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { IGlobalState } from '../../store/reducers';
import { store } from '../../store';
import { css } from '@emotion/react';
const { enable2DPointDrawing, disable2DPointDrawing, clearDrawnVectorLayerData } = MikeVisualizer;

type CommentTool2DAddCommentProps = {
  toolButtonType?: EToolButtonType;
};

/**
 * @name MmgCommentTool2DAddComment
 * @summary Allow adding a comment pin to the workspace.
 *
 * @param props
 */
export function MmgConnectedCommentTool2DAddComment(props: CommentTool2DAddCommentProps) {
  const { toolButtonType } = props;
  const { addCommentEnabled } = useSelector((state: IGlobalState) => state.MapToolReducer);

  const onAddCommentToggled = () => {
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.COMMENT_ADD,
    });
    store.dispatch({ type: 'maptools/ENABLE_ADD_COMMENT' });
  };

  /*
   * Enable point drawing widget.
   */
  const enablePointDrawingTool = useCallback(() => {
    enable2DPointDrawing(true);
  }, []);

  /*
   * Disable point drawing widget.
   */
  const disablePointDrawingTool = () => {
    store.dispatch({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
    store.dispatch({ type: 'maptools/DISABLE_ADD_COMMENT' });
    disable2DPointDrawing();
    clearDrawnVectorLayerData();
  };

  useEffect(
    () => {
      if (addCommentEnabled) {
        enablePointDrawingTool();
      } else {
        disablePointDrawingTool();
      }
    },
    [addCommentEnabled, enablePointDrawingTool],
  );

  return (
    <MmgToolButton
      toolName={t('TOOL_COMMENT_ADD')}
      toolIcon={<MapPinOutline />}
      toolType={toolButtonType}
      css={css`${ViewerToolsButtonStyle}; ${addCommentEnabled && ViewerToolsButtonActiveStyle};`}
      onClick={onAddCommentToggled}
    />
  );
}
