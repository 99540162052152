import { useState, useEffect, useCallback } from 'react';

import MeshInterpolationOperationsManager from '../../managers/MeshInterpolationOperationsManager';
import { IOperationConfiguration } from '../../models/IOperations';
import { usePrevious } from '../../shared/hooks/hooks';
import { sortOperationsByCreated } from '../../managers/model-utils';

/**
 * Custom hook that loads the current interpolation operations for the mesh given
 * Interpolation operations will be sorted by created date
 * @param workspaceId
 * @param meshId
 */
export const useAsyncLoadCurrentInterpolationOperations = (
  workspaceId: string,
  meshId: string,
): {
  interpolationOperations: Array<IOperationConfiguration>;
  updateInterpolations: (interpolations: Array<IOperationConfiguration>) => void;
  interpolationOperationsLoading: boolean;
  interpolationOperationsLoadingFailed: boolean;
  retryLoadCurrentInterpolationOperations: () => void;
} => {
  const [interpolationOperationsLoading, setInterpolationOperationsLoading] = useState(false);

  const [interpolationOperationsLoadingFailed, setInterpolationOperationsLoadingFailed] = useState(false);

  const [interpolationOperations, setInterpolationOperations] = useState<Array<IOperationConfiguration>>(null);

  const prevWorkspaceId = usePrevious(workspaceId);
  const prevMeshId = usePrevious(meshId);

  /**
   * Gets the latest mesh interpolation operations configuration
   */
  const getMeshInterpolationOperationsConfigurations = useCallback(
    (force: boolean) => {
      if (!workspaceId || !meshId || (!force && prevWorkspaceId === workspaceId && prevMeshId === meshId)) {
        return Promise.resolve();
      }

      setInterpolationOperationsLoading(true);
      setInterpolationOperationsLoadingFailed(false);

      return MeshInterpolationOperationsManager.getCurrentInterpolateMeshOperations(workspaceId, meshId)
        .then((operations) => {
          if (!operations || operations.length === 0) {
            setInterpolationOperations(null);
          } else {
            updateInterpolations(operations);
          }
        })
        .catch((error) => {
          setInterpolationOperationsLoadingFailed(true);
          console.error('Failed to get interpolate mesh operations');
          throw error;
        })
        .finally(() => {
          setInterpolationOperationsLoading(false);
        });
    },
    [workspaceId, meshId, prevWorkspaceId, prevMeshId],
  );

  const retryLoadCurrentInterpolationOperations = useCallback(
    () => {
      getMeshInterpolationOperationsConfigurations(true);
    },
    [getMeshInterpolationOperationsConfigurations],
  );

  const updateInterpolations = (interpolations: Array<IOperationConfiguration>) => {
    const sortedOperations = sortOperationsByCreated(interpolations);
    setInterpolationOperations(sortedOperations);
  };

  // load mesh current interpolation operations
  useEffect(
    () => {
      if (meshId) {
        getMeshInterpolationOperationsConfigurations(false);
      }
    },
    [getMeshInterpolationOperationsConfigurations, meshId],
  );

  return {
    interpolationOperations,
    updateInterpolations,
    interpolationOperationsLoading,
    interpolationOperationsLoadingFailed,
    retryLoadCurrentInterpolationOperations,
  };
};
