import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { t } from '../../translations/i18n';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { store } from '../../store/store';
import { hideAllItems, isLayerWorking } from '../../shared/layers/layer-utils';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { IWorkspaceMesh } from '../../models/IMeshes';
import { useSelector } from 'react-redux';
import { MmgConnectedMeshEditOperationConfiguration } from './mesh-edit-operation-configuration';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { getMeshDescription } from '../../shared/panels/panel-utils';
import { LinearProgress } from '@mui/material';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { PANEL_TABS } from '../../shared/panels/mesh-panel-constants';
import { EWorkspaceGeometryActionType } from '../../store/actions/WorkspaceGeometryActionType';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

/**
 * @name MmgConnectedMeshEditConfigurePanel
 * @summary Allows configuration of an exisiting mesh
 *
 */
export const MmgConnectedMeshEditConfigurePanel = () => {
  const { projectId, workspaceId, meshId, geometryId } = useParams();
  const navigate = useNavigate()
  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const mesh: IWorkspaceMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const meshDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId }),
  ); 

  const title = t('MESH_CONFIGURATION_EDIT_MESH_PANEL');
  const { name: meshName } = mesh || {};

  const drawnMeshes = meshDrawnData ? [meshDrawnData] : [];
  const isMeshWorking = isLayerWorking(mesh, drawnMeshes);

  const descriptions = [meshName, getMeshDescription(mesh, drawnMeshes, false)];
  const description = descriptions.join(' - ');

  // When an operation is sucessfully submitted we always go to the mesh details panel
  const onOperationSubmitSuccess = (generateMesh: boolean) => {
    navigate(getRouteByPath(ROUTES.meshDetailsPanel.path, { meshId, workspaceId, projectId }, ROUTES.workspace.path, generateMesh ? PANEL_TABS.MESH_OVERVIEW_TAB : PANEL_TABS.MESH_CONFIGURATION_TAB)); 
  };

  // Make sure no meshes or variables are shown in the viewer when this component is mounting.
  // Whatever geometries are currently shown, will be kept visibile
  // todo hevo need to also handle items loaded after this panel is opened.
  useEffect(() => {
    hideAllItems();
    store.dispatch({ type: EWorkspaceGeometryActionType.SHOW_ALL_BUT_USER_HIDDEN });
  }, []);

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={title} panelDescription={description} />
      </MikeStickyPanelHeaderContainer>

      {isMeshWorking && <LinearProgress />}
      <MmgConnectedMeshEditOperationConfiguration
        projectId={projectId}
        workspaceId={workspaceId}
        meshId={meshId}
        geometryIdToAdd={geometryId}
        onOperationSubmitSuccess={onOperationSubmitSuccess}
      />
    </MikeStickyPanel>
  );
};
