import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WorkspaceStatisticsManager from '../../managers/WorkspaceStatisticsManager';
import { OPERATION_STATES } from '../../models/IOperations';
import { IAttributeStatistics } from '../../models/IStatistics';
import { ISettingsLayer, IUserSettings } from '../../models/IUserSettings';
import { ATTRIBUTE_SETTINGS } from '../../models/IWorkspaceAttributeSettings';
import { IWorkspaceEnrichedDataItem } from '../../models/IWorkspaceData';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { EElementCategories } from '../panels/mesh-panel-constants';
import MmgBarchart from './bar-chart';
import { IGlobalState } from '../../store/reducers';
import { useParams } from 'react-router-dom';

interface IProps {
  enrichedDataItem: IWorkspaceEnrichedDataItem;
  dataArrays: Array<any>;
  elementId: string;
  dataItemCategory: EElementCategories;
}

export const MmgConnectedHistograms = (props: IProps) => {
  const dispatch = useDispatch();
  const {workspaceId} = useParams();
  const { dataItemCategory, elementId, dataArrays, enrichedDataItem } = props;
  const userSettings: IUserSettings = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.settings);
  const chartDataArrayId = useSelector((state: IGlobalState) => state.WorkspaceChartReducer.chartDataArrayId);

  const [chartStatistics, setChartStatistics] = React.useState<IAttributeStatistics | null>(null);
  const [statisticsLoading, setStatisticsLoading] = React.useState(false);
  const [statisticsFailed, setStatisticsFailed] = React.useState(false);

  const getStatistics = React.useCallback(
    (workspaceId2, eId, dataArrayId) => {
      setChartStatistics(null);
      if (!workspaceId2 || !dataArrayId || !eId) {
        return;
      }
      let needCall = false;
      if (dataArrays.some((e) => e.id === dataArrayId)) {
        needCall = true;
      }

      if (!needCall) {
        return;
      }

      // do not get statistics for categorical data
      if (isCategorical(dataArrayId)) {
        return;
      }

      setStatisticsLoading(true);

      WorkspaceStatisticsManager.getAttributeStatistics(workspaceId, eId, dataItemCategory, dataArrayId)
        .then((statistics: IAttributeStatistics) => {
          setChartStatistics(statistics);
          setStatisticsFailed(false);
        })
        .catch((error) => {
          console.error(error);
          setStatisticsFailed(true);
        })
        .finally(() => {
          setStatisticsLoading(false);
        });
    },
    [dataItemCategory, workspaceId, dataArrays],
  );

  useEffect(() => {
    return () => {
      dispatch({
        type: EWorkspaceActionType.CHART_CLEAR,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (userSettings) {
        const layer = userSettings.tableOfContents.layers.find((l: ISettingsLayer) => l.vtkItemId === elementId);
        if (layer !== undefined && layer.renderedAttribute) {
          dispatch({
            type: EWorkspaceActionType.CHART_SET,
            chartDataArrayId: layer.renderedAttribute,
            chartElementId: elementId,
          });
        }
      }
    },
    [dispatch, elementId, userSettings],
  );

  useEffect(
    () => {
      if (
        chartDataArrayId &&
        enrichedDataItem &&
        enrichedDataItem.state &&
        enrichedDataItem.state !== OPERATION_STATES.FAILED &&
        enrichedDataItem.state !== OPERATION_STATES.PROCESSING
      ) {
        getStatistics(workspaceId, elementId, chartDataArrayId);
      }
    },
    [chartDataArrayId, getStatistics, workspaceId, elementId, enrichedDataItem],
  );

  const isCategorical = (attributeName) => {
    if (attributeName) {
      const settings = ATTRIBUTE_SETTINGS.find((a) => a.name === attributeName);

      if (settings) {
        return settings.isCategorical;
      }
    }
    return false;
  };

  return (
    <>
      {statisticsLoading && <CircularProgress />}
      {chartDataArrayId &&
        chartStatistics &&
        chartStatistics.histogram &&
        enrichedDataItem &&
        !statisticsLoading &&
        !statisticsFailed && (
          <MmgBarchart
            chartStatistics={chartStatistics}
            chartDataArrayId={chartDataArrayId}
            targetDataItem={enrichedDataItem}
          />
        )}
    </>
  );
};
