import { IOperationConfiguration } from '../../../models/IOperations';
import { MmgOperationConfigurationSummary } from '../../../operations/operation-configuration-summary';
import { IWorkspaceAttribute } from '../../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../../models/IWorkspaceAttributeSettings';
import { IWorkspaceQuery } from '../../../models/IQueries';

type MeshInterpolationVariableSummaryProps = {
  operationConfiguration: IOperationConfiguration;
  meshAttributes?: Array<IWorkspaceAttribute>;
  meshAttributeSettings?: Array<IWorkspaceAttributeSettings>;
  meshQueries?: Array<IWorkspaceQuery>;
};

/**
 * @name MmgMeshOperationVariableSummary
 * @param props
 *
 * @summary A summary of a mesh interpolation child variable operation.
 */
export const MmgMeshInterpolationVariableSummary = (props: MeshInterpolationVariableSummaryProps) => {
  const { operationConfiguration, meshAttributes, meshAttributeSettings, meshQueries } = props;

  return (
    <MmgOperationConfigurationSummary
      operationConfiguration={operationConfiguration}
      showOperationName={false}
      attributes={meshAttributes}
      attributeSettings={meshAttributeSettings}
      persitedQueries={meshQueries}
    />
  );
};
