/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { MmgPanelTitle } from './panel-title';
import {
  PanelHeaderStyle,
  PanelHeaderWithBackArrowStyle,
  PanelHeaderTitleStyle,
  PanelHeaderBackStyle,
  PanelHeaderBackIconStyle,
  PanelHeaderNoBorderStyle,
} from './panel-styles';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';

const PanelHeaderActionsStyle = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
`;

const PanelHeaderChildrenStyle = css`
  display: flex;
  width: 100%;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: ${mikeSharedTheme.spacing(2)};
`;

const iconStyle = css`
  path {
    fill: ${MIKE_COLORS.DARKGREY_DEFAULT};
  }
`;

type PanelHeaderProps = {
  panelTitle?: string;
  panelDescription?: string;
  panelHeaderActions?: React.ReactNode;
  noBorder?: boolean;
  children?: React.ReactNode;
  onPanelExit?: () => void;
};


/**
 * @name MmgPanelHeader
 * @summary Panels can have an optional header, usually containg a title, an icon and a backbutton
 *
 * @param props
 */
export function MmgPanelHeader(props: PanelHeaderProps) {
  const { panelTitle, panelHeaderActions, panelDescription, noBorder, onPanelExit } = props;

  const handlePanelExit = (event) => {
    event.preventDefault();
    if (onPanelExit) {
      onPanelExit();
    }
  };

  const hasBackArrow = Boolean(onPanelExit);

  return (
    <header
      css={css`${PanelHeaderStyle}; ${noBorder && PanelHeaderNoBorderStyle}; ${hasBackArrow && PanelHeaderWithBackArrowStyle};`}
    >
      <div css={PanelHeaderTitleStyle}>
        {hasBackArrow && (
          <button css={PanelHeaderBackStyle} onClick={handlePanelExit}>
            <span css={PanelHeaderBackIconStyle}>
              <ArrowBackIcon css={iconStyle} />
            </span>
          </button>
        )}

        {panelTitle && <MmgPanelTitle panelTitle={panelTitle} panelDescription={panelDescription} />}

        {panelHeaderActions && <span css={PanelHeaderActionsStyle}>{panelHeaderActions}</span>}
      </div>

      {props.children && <section css={PanelHeaderChildrenStyle}>{props.children}</section>}
    </header>
  );
}
