/* eslint-disable react-refresh/only-export-components */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../store/store';
import { t } from '../../../translations/i18n';
import { NavigateFunction, useNavigate } from 'react-router';
import { getRouteByPath, ROUTES } from '../../../app/routes';
import Operations from '../../../icons/Operations.svg?react';
import MeshOperation from '../../../icons/MeshOperation.svg?react';
import MeshNodeEdit from '../../../shared/Icons/MeshNodeEdit.svg?react';
import MeshGenerate from '../../../shared/Icons/MeshGenerate.svg?react';

import {
  getEditLayerAction,
  getDuplicateLayerAction,
  getDeleteLayerAction,
  ILayerAction,
  getFetchLayerAction,
} from '../../../shared/layers/actions/layer-default-actions';
import { MmgConfirmDeleteMeshDialog } from '../../../meshes/details/mesh-delete-dialog';
import { MmgConfirmGenerateMeshDialog } from '../../../meshes/details/mesh-generate-dialog';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import { EElementCategories } from '../../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../../shared/layers/layer-utils';
import WorkspaceMeshesManager from '../../../managers/WorkspaceMeshesManager';
import { IDrawnDataItem } from '../../../models/IWorkspaceData';
import { IWorkspaceEnrichedMesh } from '../../../models/IMeshes';
import MeshDetailsUtil from '../../../meshes/details/mesh-details-util';
import { CONFIRM_PANEL_IDS } from '../../../shared/confirm-containers/confirm-constants';
import { EWorkspaceMeshActionType } from '../../../store/actions/WorkspaceMeshActionType';
import { getZoomToLayerAction, getPropertiesAction } from '../../../workspaces/details/actions/layer-actions';
import { useParams } from 'react-router-dom';
import { EWorkspaceActionType } from '../../../store/actions/WorkspaceActionType';
import { IGlobalState } from '../../../store/reducers';
const { isLayerInProgress, isLayerFailed } = LayerUtils;

type LayerMeshActionsProps = {
  layerId: string;
  mesh: IWorkspaceEnrichedMesh;
  canToggleLayerVisibility?: boolean;
  canEdit?: boolean;
  canGenerate?: boolean;
  canApplyPostOperations?: boolean;
  canEditMeshNodes?: boolean;
  canOpenInterpolations?: boolean;
  updateUserSettingsOnToggle?: boolean;
  onMeshEdit?: (meshId: string) => void;
  meshDrawnData?: IDrawnDataItem;
  loadedData?: Array<string>;
};

/**
 * @name MmgConnectedLayerMeshActions
 * @summary Mesh layer actions.
 *
 * @param props
 */
export function MmgConnectedLayerMeshActions(props: LayerMeshActionsProps) {
  const {
    layerId,
    mesh,
    meshDrawnData,
    canToggleLayerVisibility = true,
    updateUserSettingsOnToggle = false,
    canEdit = true,
    canGenerate = true,
    canApplyPostOperations = true,
    canOpenInterpolations = true,
    canEditMeshNodes = true,
  } = props;

  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate(); 
  const hiddenMeshIds: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer.hiddenWorkspaceMeshes,
  );
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [generateConfirmOpen, setGenerateConfirmOpen] = useState(false);

  const onMeshDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const onMeshGenerate = () => {
    return MeshDetailsUtil.onMeshGenerate(projectId, workspaceId, mesh.id);
  };

  const isMissing = !mesh;

  const layerActions = getLayerActions(
    projectId,
    workspaceId,
    mesh,
    meshDrawnData,
    canToggleLayerVisibility,
    canEdit,
    canGenerate,
    canApplyPostOperations,
    canEditMeshNodes,
    canOpenInterpolations,
    hiddenMeshIds,
    updateUserSettingsOnToggle,
    props.onMeshEdit,
    onMeshDelete,
    onMeshGenerate,   
    navigate
  );

  return (
    <>
      <MikeLayerActionMenu layerId={layerId} layerActions={layerActions} disabled={isMissing} />
      <MmgConfirmDeleteMeshDialog
        open={deleteConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        mesh={mesh}
        onClose={() => setDeleteConfirmOpen(false)}
      />
      <MmgConfirmGenerateMeshDialog
        open={generateConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        mesh={mesh}
        onClose={() => setGenerateConfirmOpen(false)}
      />
    </>
  );
}

const getLayerActions = (
  projectId: string,
  workspaceId: string,
  mesh: IWorkspaceEnrichedMesh,
  _meshDrawnData: IDrawnDataItem,
  _canToggleLayerVisibility: boolean,
  canEdit: boolean,
  canGenerate: boolean,
  canApplyPostOperations: boolean,
  canEditMeshNodes: boolean,
  canOpenInterpolations: boolean,
  _hiddenMeshIds: Array<string>,
  _updateUserSettingsOnToggle: boolean,
  onMeshEdit: (meshId) => void,
  onMeshDelete: () => void,
  onMeshGenerate: () => void, 
  navigate: NavigateFunction
) => {
  if (!mesh) {
    return [];
  }

  const isTiled = mesh.isTiled;

  const isFailed = isLayerFailed(mesh);

  const isWorking = false;
  const isProcessing = isLayerInProgress(mesh);
  const layerId = mesh.id;
  const layerActions = [];

  if (canGenerate && !isProcessing) {
    const generateMeshLayerActions = getGenerateMeshAction(onMeshGenerate, isProcessing);
    layerActions.push(generateMeshLayerActions);
  }

  const zoomToLayerAction = getZoomToLayerAction(layerId, workspaceId, !_meshDrawnData || isWorking || isFailed);
  layerActions.push(zoomToLayerAction);

  if (canApplyPostOperations) {
    const meshPostOperationAction = getMeshPostOperationAction(
      projectId,
      workspaceId,
      layerId,
      navigate,
      isWorking || isFailed,    
    );
    layerActions.push(meshPostOperationAction);
  }

  if (canOpenInterpolations) {
    const openInterpolationsAction = getOpenInterpolationsAction(
      projectId,
      workspaceId,
      layerId,
      navigate,
      isWorking || isFailed,
    );
    layerActions.push(openInterpolationsAction);
  }

  if (!isTiled && canEditMeshNodes) {
    const editMeshNodesAction = getEditMeshNodesAction(mesh, isWorking);
    layerActions.push(editMeshNodesAction);
  }

  if (canEdit) {
    if (!onMeshEdit) {
      console.warn('When allowing editing, onMeshEdit must be provided');
    } else {
      const editLayerAction = getEditMeshAction(layerId, onMeshEdit, isWorking);
      layerActions.push(editLayerAction);
    }
  }

  const propertiesAction = getPropertiesAction(projectId, workspaceId, layerId, EElementCategories.MESH, navigate);
  layerActions.push(propertiesAction);

  const duplicateLayerAction = getDuplicateMeshAction(projectId, workspaceId, layerId, navigate, isProcessing || isFailed);
  layerActions.push(duplicateLayerAction);

  const deleteLayerAction = getDeleteMeshAction(onMeshDelete);
  layerActions.push(deleteLayerAction);

  return layerActions;
};


export const getEditMeshAction = (layerId: string, onMeshEdit: (meshId) => void, disabled?: boolean): ILayerAction => {
  const editLayerAction = getEditLayerAction(() => onMeshEdit(layerId), disabled);
  return editLayerAction;
};

export const getDuplicateMeshAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onMeshDuplicate = (meshId: string) => {
    return WorkspaceMeshesManager.duplicateMesh(workspaceId, meshId)
      .then(() => {
        const toast = {
          text: t('DUPLICATED', 1, {
            thing: t('MESH'),
          }),
        };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('DUPLICATE') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  const duplicateLayerAction = getDuplicateLayerAction(() => onMeshDuplicate(layerId), disabled);
  return duplicateLayerAction;
};

export const getFetchMeshAction = (onMeshFetch: () => void, disabled?: boolean): ILayerAction => {
  const fetchLayerAction = getFetchLayerAction(() => onMeshFetch(), disabled);
  return fetchLayerAction;
};

export const getDeleteMeshAction = (onMeshDelete: () => void, disabled?: boolean): ILayerAction => {
  const deleteLayerAction = getDeleteLayerAction(() => onMeshDelete(), disabled);
  return deleteLayerAction;
};

export const getGenerateMeshAction = (onMeshGenerate: () => void, disabled?: boolean): ILayerAction => {
  const generateMeshLayerAction = {
    actionId: 'GENERATE_MESH',
    actionDisplayName: t('GENERATE_MESH'),
    actionIcon: <MeshGenerate />,
    onAction: () => onMeshGenerate(),
    disabled,
  };
  return generateMeshLayerAction;
};

export const getMeshPostOperationAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onOperation = (meshId: string) => {
    navigate(getRouteByPath(ROUTES.meshPostOperationPanel.path, { workspaceId, projectId, meshId }, ROUTES.workspace.path));
  };

  const operationLayerAction = {
    actionId: 'POST_OPERATIONS',
    actionDisplayName: t('POST_OPERATIONS_ACTION'),
    actionIcon: <Operations />,
    onAction: () => onOperation(layerId),
    disabled,
  };
  return operationLayerAction;
};

export const getEditMeshNodesAction = (mesh: IWorkspaceEnrichedMesh, disabled?: boolean) => {
  const onEditMeshNodesAction = () => {
    store.dispatch({
      type: EWorkspaceMeshActionType.SHOW_ITEM,
      workspaceMeshId: mesh.id,
    });
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.MESH_EDIT_NODES,
    });
    store.dispatch({
      type: EWorkspaceMeshActionType.EDIT_NODES,
      data: mesh.id,
    });
  };

  const editMeshNodes = {
    actionId: 'EDIT_MESH_NODES',
    actionDisplayName: t('EDIT_MESH_NODES_ACTION'),
    actionIcon: <MeshNodeEdit />,
    onAction: onEditMeshNodesAction,
    disabled,
  };
  return editMeshNodes;
};

export const getOpenInterpolationsAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onOpenInterpolations = (meshId: string) => {
    navigate(getRouteByPath(ROUTES.meshDetailsPanel.path, { meshId, workspaceId, projectId }, ROUTES.workspace.path)); 
  };

  const openInterpolationAction = {
    actionId: 'MESH_INTERPOLATIONS',
    actionDisplayName: t('MESH_INTERPOLATIONS'),
    actionIcon: <MeshOperation />,
    onAction: () => onOpenInterpolations(layerId),
    disabled,
  };
  return openInterpolationAction;
};
