import { useState } from 'react';
import { MikeLayerActionMenu } from '../../shared/layers/actions/layer-action-menu';
import { IOperationMetadata } from '../../models/IOperations';
import { getOperationActions } from './layer-operation-actions';
import { MmgConfirmDeleteOperationDialog } from '../operation-delete-dialog';

type OperationOverflowMenuProps = {
  projectId: string;
  workspaceId: string;
  operation: IOperationMetadata;
};

/**
 * @name MmgOperationOverflowMenu
 * @summary Overflow menu for an operation.
 *
 * @param props
 */
export function MmgOperationOverflowMenu(props: OperationOverflowMenuProps) {
  const { projectId, workspaceId, operation } = props;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onOperationDelete = () => {
    setDeleteConfirmOpen(true);
  };

  if (!operation) {
    return <></>;
  }
  const isMissing = !operation;

  const layerActions = getOperationActions(operation, onOperationDelete);

  return (
    <>
      <MikeLayerActionMenu layerId={operation.id} layerActions={layerActions} disabled={isMissing} />

      <MmgConfirmDeleteOperationDialog
        open={deleteConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        operation={operation}
        onClose={() => setDeleteConfirmOpen(false)}
      />
    </>
  );
}
