/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const LayerColorComplicationStyle = css`
  position: absolute;
  bottom: 0.4rem;
  right: 0.4rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 2px solid transparent;
  display: inline-block;
  box-sizing: border-box;
`;

type LayerColorComplicationProps = {
  edgeColor: Array<number>;
  surfaceColor: Array<number>;
};

/**
 * @name MikeLayerColorComplication
 * @summary Displays a color icon for a given layer.
 * Meant to be used as a complication on the left side of the layer icon.
 *
 * @param props
 */
export function MikeLayerColorComplication(props: LayerColorComplicationProps) {
  const { edgeColor, surfaceColor } = props;

  const getLayerColor = (rgbaColors: Array<number>) => {
    if (rgbaColors && rgbaColors.length) {
      return `rgba(
        ${rgbaColors[0] * 255},
        ${rgbaColors[1] * 255},
        ${rgbaColors[2] * 255},
        1
      )`;
    }

    return 'rgba(0, 0, 0, 1)';
  };

  return (
    <span
      css={LayerColorComplicationStyle}
      style={{
        borderColor: getLayerColor(surfaceColor),
        backgroundColor: getLayerColor(edgeColor),
      }}
    />
  );
}
