import { IProject } from '../../models/IProject';
import { EProjectsActionType } from '../actions/ProjectsActionType';

const initialState = {
  projectList: [],
  project: null,
  projectCreating: false,
  projectCreateError: false,
  projectListLoading: false,
  projectListLoadingFailed: false,
  // TODO: Consider to put this in localStorage, so that the user only has to dismiss it the first time s/he loads the project iso each time:
  projectViewOnlyWarningDismissed: false,
  projectSelectedForDelete: [],
  subprojects: [],
  recursiveProjectList: [],
  recursiveProjectListLoading: false,
  recursiveProjectListLoadingFailed: false,
};

type ProjectState = typeof initialState;
export interface IProjectState extends ProjectState {
  project: IProject | null;
}

/**
 * Project Reducer.
 * - returns new states for matched project actions.
 *
 * @name ProjectReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IProjectState = initialState, action): IProjectState {
  switch (action.type) {
    case EProjectsActionType.SET_PROJECTS_RECURSIVELY: {
      return {
        ...state,
        recursiveProjectList: action.data,
        recursiveProjectListLoadingFailed: false,
      };
    }
    case EProjectsActionType.LOADING_PROJECTS_RECURSIVELY: {
      return {
        ...state,
        recursiveProjectListLoading: action.data,
      };
    }
    case EProjectsActionType.LOADING_PROJECTS_RECURSIVELY_FAILED: {
      return {
        ...state,
        recursiveProjectListLoadingFailed: action.data,
        recursiveProjectList: [],
      };
    }

    case 'project/LIST':
      return {
        ...state,
        projectList: action.projectList,
      };

    case 'project/SUBPROJECT_LIST_SUCCES':
      return {
        ...state,
        subprojects: action.subprojects,
      };

    case 'project/LIST_LOADING':
      return {
        ...state,
        projectListLoading: true,
        projectListLoadingFailed: false,
      };

    case 'project/LIST_SUCCESS':
      return {
        ...state,
        projectList: action.projectList,
        projectListLoading: false,
        projectListLoadingFailed: false,
      };

    case 'project/LIST_FAILED':
      return {
        ...state,
        projectListLoading: false,
        projectListLoadingFailed: true,
      };

    case 'project/LOAD_SUCCESS':
      return {
        ...state,
        project: action.project,
        projectViewOnlyWarningDismissed: false,
      };

    case 'project/WORKSPACE_DELETE_ADD':
      return {
        ...state,
        projectSelectedForDelete: [...state.projectSelectedForDelete, action.id],
      };

    case 'project/WORKSPACE_DELETE_REMOVE':
      return {
        ...state,
        projectSelectedForDelete: state.projectSelectedForDelete.filter((p) => p !== action.id),
      };

    case 'project/CREATE':
      return {
        ...state,
        projectCreating: true,
        projectCreateError: false,
      };

    case 'project/CREATE_SUCCESS': {
      // Add the created project to the projectList, if not already present.
      const newProject = action.project;
      const newProjectList = [newProject, ...state.projectList.filter((p) => p.id !== newProject.id)];

      return {
        ...state,
        projectList: [...newProjectList],
        projectCreating: false,
        projectCreateError: false,
      };
    }

    case 'project/DISMISS_VIEWONLY_WARNUNG':
      return {
        ...state,
        projectViewOnlyWarningDismissed: true,
      };

    case 'project/CREATE_FAILED':
      return {
        ...state,
        projectCreating: false,
        projectCreateError: true,
      };

    default:
      return state;
  }
}
