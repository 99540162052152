/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { store } from '../../store/store';
import { isEmpty } from 'lodash-es';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { OSM_DATA_PROVIDER_DOCUMENTATION } from '../../managers/OpenDataQueryManager';
import { MmgPanel } from '../../shared/panels/panel';
import { GEOMETRY_EXTRACT_STEPS, MmgGeometryExtractStepper } from '../../geometries/create/geometry-extract-stepper';
import { MmgPanelBottomActions } from '../../shared/panels/panel-bottom-actions';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { ConfirmHeaderStyle } from '../../shared/confirm-containers/confirm-styles';
import { CircularProgress, Typography } from '@mui/material';
import { IOpenDataProvider, IDatasetDescription, IParameter, IParameterValues } from '../../models/IOpenDataProvidersApi';
import { WorkspaceActionType } from '../../workspaces/store/WokspaceActionType';
import { FeatCollAny } from '../../models/IGeometryUtils';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { useParams } from 'react-router-dom';
import { MmgParameterInfoWrapper } from '../../shared/parameters/parameter-info-wrapper';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import MikeVisualizer from '../../MikeVisualizer';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import MikeButton from '../../shared-components/mike-button';
import { IGlobalState } from '../../store/reducers';

const {
  onDrawnDataUpdated,
  onDrawingInProgressChanged,
  getCurrentlyDrawnGeojson,
  clearDrawnVectorLayerData,
} = MikeVisualizer;

const selectLabel = css`
  padding-bottom: ${mikeSharedTheme.spacing(0.5)};
`;

const InfoWrapperCss = css`
  align-items: center;
  // height and margins must be the same as the inputfield, without the helpertext
  // todo hevo There is a risc that hardcoding these will not work for all devices/resolutions, but could not find better solution for now.
  .MmgParameterInfo {
    margin-left: 8px;
  }
`;

const OSMPROVIDERID = '00000000-0000-0000-0000-000000000000';

const PROVIDERINFO = 'providerInfoText';
const PROVIDERDOS = 'providerInfoLink';
const PROVIDERPARAMETERNAME = 'providerParameterName';
const PROVIDERPARAMETERINFO = 'providerParameterInfo';

const emptyFeatureColl = {} as FeatCollAny;

/**
 * By changing this method, a promise can be delayed by [time] in ms.
 *
 * @param time
 */
// const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

interface IParameterSettings {
  name: string;
  nameKey: string;
  required: boolean;
  allowedValues: Array<IAllowedParameterValues>;
  defaultValue: any;
}

interface IAllowedParameterValues {
  value: any;
  displayName: string;
}

/**
 * @name MmgConnectedGeometryExtractConfirm Allows submitting (confirming) an extraction, adding some drawing to it and then creating a geometry.
 * @summary This component shows a summary of the extraction and then of the drawn content.
 *
 */
export function MmgConnectedGeometryExtractConfirm() {
  const { workspaceId } = useParams();

  const providersLoading: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceOpenDataReducer.providersLoading,
  );
  const openDataProviders: Array<IOpenDataProvider> = useSelector(
    (state: IGlobalState) => state.WorkspaceOpenDataReducer.providers,
  );

  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [parameters, setParameters] = useState<{ [key: string]: number }>({});

  const { parameterSettings, providerDatasetDescriptions } = useMemo(
    () => {
      let providerDescriptions = new Array<IDatasetDescription>();
      if (selectedProviderId) {
        const selectedProvider = openDataProviders.find((odp: IOpenDataProvider) => odp.id === selectedProviderId);
        if (selectedProvider !== undefined) {
          providerDescriptions = selectedProvider.datasetDescriptions;
        }

        const getValueSettings = (vals: IParameterValues) => {
          const keys = Object.keys(vals);
          return keys.map((key: string) => {
            return { value: parseInt(key), displayName: vals[key] };
          });
        };

        if (selectedProvider && selectedProvider.parameters && selectedProvider.parameters.length > 0) {
          const settings = selectedProvider.parameters.map((parameter: IParameter) => {
            return {
              required: parameter.required,
              name: parameter.name.toLowerCase(),
              nameKey: parameter.nameKey.toLowerCase(),
              allowedValues: getValueSettings(parameter.values),
              defaultValue: parameter.defaultValue,
            };
          });
          return { parameterSettings: settings, providerDatasetDescriptions: providerDescriptions };
        }
      }
      return { parameterSettings: new Array<IParameterSettings>(), providerDatasetDescriptions: providerDescriptions };
    },
    [openDataProviders, selectedProviderId],
  );

  useEffect(
    () => {
      let defaultParameter = {};
      parameterSettings.forEach((ps: IParameterSettings) => {
        const param = ps.nameKey;
        if (param.length > 1) {
          defaultParameter = {
            ...defaultParameter,
            [param]: ps.defaultValue,
          };
        }
      });
      setParameters(defaultParameter);
    },
    [parameterSettings],
  );

  const [extractionFeatureType, setExtractionFeatureType] = useState('');
  const [selectionFeatureCollection, setSelectionFeatureCollection] = useState(emptyFeatureColl);
  const [drawingInProgress, setDrawingInProgress] = useState(false);

  const submitExtraction = useCallback(
    async () => {
      const selectedProvider = openDataProviders.find((odp: IOpenDataProvider) => odp.id === selectedProviderId);
      if (selectedProvider !== undefined) {
        clearDrawnVectorLayerData();
        // Disallow extract features during edit. This needs to be done before the request for open data is made so it does not create render race conditions.
        store.dispatch({
          type: EMapToolActionType.DISALLOW_EXTRACT_FEATURES,
        });
        let geometryLayerName = extractionFeatureType;
        if (selectedProvider !== undefined) {
          const selectedFeatureType = selectedProvider.datasetDescriptions.find(
            (dd: IDatasetDescription) => dd.id === extractionFeatureType,
          );
          if (selectedFeatureType !== undefined) {
            geometryLayerName = selectedFeatureType.displayName;
          }
        }
        const payload = {
          featureCollection: selectionFeatureCollection,
          providerType: selectedProvider.type,
          providerId: selectedProviderId,
          datasetId: extractionFeatureType,
          name: geometryLayerName,
        };
        store.dispatch({
          type: WorkspaceActionType.OPENDATA_PROVIDERS_EXTRACT,
          openDataExtraction: isEmpty(parameters)
            ? payload
            : {
                ...payload,
                parameters,
              },
        });
        store.dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('EXTRACTION_OPERATION_SUBMITTED_SUCCESSFULLY'),
          },
        });
        clean();
      }
    },
    [openDataProviders, selectionFeatureCollection, extractionFeatureType, selectedProviderId, parameters],
  );

  /**
   * Callback for when the drawn selection has updated.
   * Updates the local reference to the feature collection so it can be eventually sent to the API.
   *
   * @param featureCollection The feature collection that holds the updated selection
   */
  function selectionUpdateCallback(featureCollection: FeatCollAny) {
    setSelectionFeatureCollection(featureCollection);
  }

  /**
   * After cancel extraction or extraction without preview support, disabling tools that are not relevant, exiting confirmation panel and clearing submenus.
   */
  function clean() {
    store.dispatch({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
    store.dispatch({ type: EMapToolActionType.DISABLE_FEATURE_INFO });

    store.dispatch({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
    clearDrawnVectorLayerData();
    store.dispatch({
      type: EMapToolActionType.CLEAR_OPEN_SUBMENU,
    });
  }

  /**
   * Callback for when drawing in progress changes.
   *
   * @param inProgress
   */
  function drawingProgressChangedCallback(inProgress: boolean) {
    setDrawingInProgress(inProgress);
  }

  /**
   * Callback for when a feature type has been selected.
   *
   * @param event
   */
  function handleFeatureTypeChange(event) {
    const target = event.target as HTMLInputElement;
    setExtractionFeatureType(target.value);
  }

  /**
   * Callback for when a parameter has been selected.
   *
   * @param event
   * @param key
   */
  function handleParameterChange(event, key: string) {
    const target = event.target as HTMLInputElement;
    if (key.length > 2) {
      const param = key[0].toLowerCase() + key.slice(1);
      setParameters((previous) => {
        return { ...previous, [param]: parseInt(target.value) };
      });
    }
  }

  const getParameterValue = useCallback(
    (key: string) => {
      if (key.length > 2) {
        const param = key[0].toLowerCase() + key.slice(1);
        return parameters ? parameters[param] : undefined;
      }
      return undefined;
    },
    [parameters],
  );

  /**
   * Callback for when a provider has been selected.
   *
   * @param event
   */
  function handleExtractionProviderChange(event) {
    const target = event.target as HTMLInputElement;
    const selectedProvider = openDataProviders.find((odp: IOpenDataProvider) => odp.id === target.value);
    setSelectedProviderId(selectedProvider.id);
  }

  useEffect(
    () => {
      openDataProviders.length > 0 && setSelectedProviderId(openDataProviders[0].id);
    },
    [openDataProviders],
  );

  useEffect(
    () => {
      if (selectedProviderId && openDataProviders.length > 0) {
        const selectedProvider = openDataProviders.find((odp: IOpenDataProvider) => odp.id === selectedProviderId);
        if (selectedProvider !== undefined) {
          setExtractionFeatureType(
            selectedProvider.datasetDescriptions.length > 0 ? selectedProvider.datasetDescriptions[0].id : '',
          );
        }
      }
    },
    [openDataProviders, selectedProviderId],
  );

  useEffect(() => {
    const unsubscribers = [];
    const unsubscribeOnDrawnDataUpdated = onDrawnDataUpdated(selectionUpdateCallback);
    const unsubscribeOnDrawingInProgressChanged = onDrawingInProgressChanged(drawingProgressChangedCallback);
    setSelectionFeatureCollection(getCurrentlyDrawnGeojson());
    unsubscribers.push(unsubscribeOnDrawingInProgressChanged, unsubscribeOnDrawnDataUpdated);

    // Only allow extract data while drawing the selection polygon. We should not show the tool during edit.
    store.dispatch({
      type: EMapToolActionType.ALLOW_EXTRACT_FEATURES,
    });
    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
      // on unmount we reset to default tools
      store.dispatch({ type: EMapToolActionType.RESET_ALLOWED_TOOLS });
    };
  }, []);

  useEffect(
    () => {
      if (workspaceId) {
        store.dispatch({
          type: WorkspaceActionType.OPENDATA_PROVIDERS_LOAD,
        });
      }
    },
    [workspaceId],
  );

  const handleBackClick = () => {
    clean();
  };

  const getTranslations = (providerId: string, keyToTranslate: string) => {
    switch (providerId) {
      case OSMPROVIDERID: {
        switch (keyToTranslate) {
          case PROVIDERINFO: {
            return t('DATAPROVIDER_OSM_SETIINGS_INFO');
          }
          case PROVIDERDOS: {
            return OSM_DATA_PROVIDER_DOCUMENTATION;
          }
          case PROVIDERPARAMETERNAME + 'timeout': {
            return t('DATAPROVIDER_OSM_SETIINGS_ALLOWED_RUNTIME');
          }
          case PROVIDERPARAMETERINFO + 'timeout': {
            return t('DATAPROVIDER_OSM_SETIINGS_ALLOWED_RUNTIME_INFO');
          }
          case PROVIDERPARAMETERNAME + 'maxsize': {
            return t('DATAPROVIDER_OSM_SETIINGS_ALLOWED_MEMORY');
          }
          case PROVIDERPARAMETERINFO + 'maxsize': {
            return t('DATAPROVIDER_OSM_SETIINGS_ALLOWED_MEMORY_INFO');
          }
        }
      }
    }
    return '';
  };

  return (
    <MmgPanel>
      <header css={ConfirmHeaderStyle}>
        <h4>{t('SELECT_GEOMETRIES_TO_EXTRACT_PANEL')}</h4>
      </header>
      <MmgGeometryExtractStepper activeStep={GEOMETRY_EXTRACT_STEPS.EXTRACT} />
      <header css={ConfirmHeaderStyle}>
        <h4>{t('DEFINE_DATA_SOURCE_TO_EXTRACT')}</h4>
      </header>
      {openDataProviders.length === 0 || providersLoading ? (
        <MmgPanelSubsection>
          <p>{t('LOADING', 1, { thing: t('OPEN_DATA_PROVIDER') })}</p>
          <div>
            <CircularProgress />
          </div>
        </MmgPanelSubsection>
      ) : (
        <>
          <MmgPanelSubsection>
            <Typography css={selectLabel} variant={'h4'}>
              {t('DATAPROVIDER_FOR_EXTRACTION')}
            </Typography>

            <FormControl variant="outlined">
              <Select
                native
                variant="standard"
                value={selectedProviderId}
                onChange={handleExtractionProviderChange}
                inputProps={{
                  name: 'select-data-provider',
                  id: 'select-data-provider',
                }}
              >
                {openDataProviders.map((openDataProvider: IOpenDataProvider) => {
                  const id = openDataProvider.id;
                  return (
                    <option key={id} value={id}>
                      {openDataProvider.displayName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </MmgPanelSubsection>
          <MmgPanelSubsection>
            <Typography css={selectLabel} variant={'h4'}>
              {t('DATASOURCE_TO_EXTRACT')}
            </Typography>

            <FormControl variant="outlined">
              <Select
                native
                variant="standard"
                value={extractionFeatureType}
                onChange={handleFeatureTypeChange}
                inputProps={{
                  name: 'select-feature-type',
                  id: 'select-feature-type',
                }}
              >
                {providerDatasetDescriptions.map((datasetDesc: IDatasetDescription) => {
                  const id = datasetDesc.id;
                  return (
                    <option key={id} value={id}>
                      {datasetDesc.displayName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </MmgPanelSubsection>

          {parameterSettings &&
            parameterSettings.length > 0 && (
              <>
                <header css={ConfirmHeaderStyle}>
                  <MmgParameterInfoWrapper
                    infoText={getTranslations(selectedProviderId, PROVIDERINFO)}
                    cssProp={InfoWrapperCss}
                  >
                    <a
                      href={getTranslations(selectedProviderId, PROVIDERDOS)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h4>{t('DATAPROVIDER_SETIINGS')} </h4>
                    </a>
                  </MmgParameterInfoWrapper>
                </header>
                {parameterSettings.map((ps: IParameterSettings) => (
                  <MmgPanelSubsection>
                    <Typography css={selectLabel} variant={'h4'}>
                      {getTranslations(selectedProviderId, PROVIDERPARAMETERNAME + ps.nameKey.toLowerCase())}
                    </Typography>
                    <MmgParameterInfoWrapper
                      infoText={getTranslations(selectedProviderId, PROVIDERPARAMETERINFO + ps.nameKey.toLowerCase())}
                      cssProp={InfoWrapperCss}
                    >
                      <FormControl variant="outlined">
                        <Select
                          native
                          variant="standard"
                          value={getParameterValue(ps.nameKey)}
                          onChange={(event) => handleParameterChange(event, ps.nameKey)}
                          inputProps={{
                            name: ps.nameKey,
                            id: ps.nameKey,
                          }}
                        >
                          {ps.allowedValues.map((setting: IAllowedParameterValues) => {
                            const id = setting.value;
                            return (
                              <option key={id} value={id}>
                                {setting.displayName}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </MmgParameterInfoWrapper>
                  </MmgPanelSubsection>
                ))}
              </>
            )}
        </>
      )}
      <MmgPanelBottomActions>
        <MikeButton variant="outlined" color="secondary" onClick={handleBackClick}>
          {t('CANCEL')}
        </MikeButton>
        <MikeButton
          variant="contained"
          color="secondary"
          disabled={
            !(selectionFeatureCollection.features && selectionFeatureCollection.features.length) || drawingInProgress
          }
          onClick={submitExtraction}
        >
          {t('CONFIRM_EXTRACTION')}
        </MikeButton>
      </MmgPanelBottomActions>
    </MmgPanel>
  );
}
