import { IWorkspaceGeometry } from './IGeometries';
import { IWorkspaceVariable } from './IVariables';
import { IWorkspaceMesh } from './IMeshes';
import { IWorkspaceQuery } from './IQueries';
import { IOperationMetadata } from './IOperations';
import { IUserSettings } from './IUserSettings';

export interface IWorkspaceDataset {
  id: string;
  projectId: string;
  usageType?: string;
  datasetType?: string;
  spatialInformation?: {
    primarySpatialReference?: string;
    srid?: number;
  };
}

export interface IWorkspaceOverview {
  id?: string;
  projectId?: string;
  name?: string;
  description?: string;
  image?: string;
  bounds?: IWorkspaceBounds;
  epsgCode?: number;
  isLatLon?: boolean;
  customerId?: string;
  created?: string;
  updated?: string;
  createdBy?: string;
  updatedBy?: string;
  settings?: IUserSettings;
  proj4String?: string;
}

export interface IWorkspace extends IWorkspaceOverview {
  /**
   * @property The datasets of the workspace. Important! Is not guaranteed to be kept in sync with data in the backend.
   */
  datasets?: Array<IWorkspaceDataset>;

  /**
   * @property Geometries of the workspace. Important! Is not guaranteed to be kept in sync with data in the backend.
   * Should use workspaceGeometries from WorkspaceGeometrySelectors instead.
   */
  geometries?: Array<IWorkspaceGeometry>;

  /**
   * @property Saved queries (selections) of the workspace. Important! Is not guaranteed to be kept in sync with data in the backend.
   * Should use the queries stored in WorkspaceQueryReducer instead.
   */
  queries?: Array<IWorkspaceQuery>;

  /**
   * @property Meshes of the workspace. Important! Is not guaranteed to be kept in sync with data in the backend.
   * Should use workspaceMeshes from WorkspaceMeshSelectors instead.
   */
  meshes?: Array<IWorkspaceMesh>;

  /**
   * @property Variables of the workspace. Important! Is not guaranteed to be kept in sync with data in the backend.
   * Should use workspaceVariables from WorkspaceVariableSelectors instead.
   */
  variables?: Array<IWorkspaceVariable>;

  /**
   * @property Operations of the workspace. Important! Is not guaranteed to be kept in sync with data in the backend.
   * Should use the operations stored in WorkspaceOperationReducer instead.
   */
  operations?: Array<IOperationMetadata>;
}

// Bounds as we get them from the api
export interface IWorkspaceBounds extends Array<number> {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  zMin: number;
  zMax: number;
}

export interface IRecentWorkspace {
  id: string;
  timestamp: number;
  name: string;
}

export const WORKSPACE_DEFAULT_PROJECTION = 4326;
