/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MeshGenerator from '../icons/MeshGenerator.svg?react';
import CircularProgress from '@mui/material/CircularProgress';

const IconStyle = css`
  width: 100px;
  height: 100px;
`;

export const MmgAnimatedIcon = () => (
  <>
    <MeshGenerator viewBox={`0 0 40 40`} css={IconStyle} />

    <div>
      <CircularProgress color="secondary" />
    </div>
  </>
);
