/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { IWorkspaceQuery } from '../../models/IQueries';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceMeshesManager from '../../managers/WorkspaceMeshesManager';
import { useSelector } from 'react-redux';
import { IOperationConfiguration } from '../../models/IOperations';
import { EElementCategories, ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { MmgMeshOperationConfiguration } from './configuration/mesh-operation-configuration';
import { t } from '../../translations/i18n';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import LayerUtils, { deselectLayer } from '../../shared/layers/layer-utils';
import { getFilteredCreateMeshChildOperations, submitCreateMeshOperation } from './mesh-configuration-util';
import { useAsyncLoadContextualCreateMeshOperationDescription } from './useAsyncLoadContextualCreateMeshOperationDescription';
import { useIsMounted } from '../../shared/hooks/hooks';
import { useStepper } from '../../shared/steppers/useStepper';
import { getDataItemsByIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { MmgStepper } from '../../shared/steppers/stepper';
import { MmgStep } from '../../shared/steppers/step';
import { MmgStepContent } from '../../shared/steppers/step-content';
import { MmgGroup } from '../../shared/groups/group';
import { stepperStickyPanelStyle } from '../../shared/steppers/stepper-styles';
import { MmgConnectedMeshConfigurationSelectGeometries } from '../configure/mesh-configuration-select-geometries';
import { isCreateMeshChildOperationsValid } from '../../managers/MeshOperationValidation';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import { IGlobalState } from '../../store/reducers';
import MikeExpandableButton from '../../shared-components/mike-expandable-button';

const { showLayer, hideLayer } = LayerUtils;

const meshCreateOperationConfigurationStyleReadOnly = css`
  pointer-events: none;
`;

type MeshCreateOperationConfigurationProps = {
  projectId: string;
  workspaceId: string;
  onOperationSubmitSuccess?: (execute: boolean) => void;
};

/**
 * @name MmgConnectedMeshCreateOperationConfiguration
 * @summary Allows configuring new  mesh operations.
 * Supports both 'basic'/'base' configurations, geometry configurations.
 *
 * @param props
 */
export const MmgConnectedMeshCreateOperationConfiguration = (props: MeshCreateOperationConfigurationProps) => {
  const { workspaceId, onOperationSubmitSuccess } = props;
  const workspaceGeometries: Array<IWorkspaceEnrichedGeometry> = useSelector(
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries,
  );
  const workspaceVariables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );
  const workspaceQueries: Array<IWorkspaceQuery> = useSelector(
    (state: IGlobalState) => state.WorkspaceQueryReducer.workspaceQueries,
  );

  const CREATE_MESH_STEPS = {
    SELECT_GEOMETRIES: 0,
    CONFIGURE: 1,
  };

  const isMounted = useIsMounted();
  const { goBackToReferrer } = useNavigateBack();

  const [meshingInProgress, setMeshingInProgress] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [meshOperationConfiguration, setMeshOperationConfiguration] = useState(null as IOperationConfiguration);

  const [selectedGeometryIds, setSelectedGeometryIds] = React.useState([] as Array<string>);

  const { activeStep, handleNext } = useStepper(CREATE_MESH_STEPS.SELECT_GEOMETRIES);

  /**
   * Effectively checks if any geometries are selected to be included in the configuration.
   */
  const anyGeometriesSelected = selectedGeometryIds && selectedGeometryIds.length > 0;

  /**
   * Saves changes without generating the mesh.
   */
  const saveMesh = () => submitMeshConfiguration(false);

  /**
   * Saves changes, generating the mesh.
   */
  const createMesh = () => submitMeshConfiguration(true);

  /**
   * Creates a mesh request, optionally generating the mesh.
   *
   * @param generateMesh
   */
  const submitMeshConfiguration = (generateMesh: boolean) => {
    if (generateMesh) {
      setMeshingInProgress(true);
    } else {
      setSaveInProgress(true);
    }

    // todo hevo needs to filter childre like when editing
    return WorkspaceMeshesManager.createMesh(workspaceId)
      .then((newMesh: IOperationConfiguration) => {
        const meshId = newMesh.id;

        // only include child operations of selected geometries
        const filteredChildOperations = getFilteredChildOperations();

        const operationConfigurationToSubmit = {
          ...meshOperationConfiguration,
          childOperations: filteredChildOperations,
          inputIds: [meshId],
        };

        submitCreateMeshOperation(workspaceId, meshId, operationConfigurationToSubmit, generateMesh).then(() => {
          if (onOperationSubmitSuccess) {
            onOperationSubmitSuccess(generateMesh);
          }
        });
      })
      .finally(() => {
        if (isMounted()) {
          setMeshingInProgress(false);
          setSaveInProgress(false);
        }
      });
  };

  // Only include child operations corresponding to selected geometries.
  // This allows user to remove a geometry and add it back with the same parameters set
  const getFilteredChildOperations = useCallback(
    () =>
      getFilteredCreateMeshChildOperations(
        meshOperationConfiguration,
        selectedGeometryIds,
        workspaceGeometries,
        workspaceQueries,
      ),
    [meshOperationConfiguration, selectedGeometryIds, workspaceGeometries, workspaceQueries],
  );

  /**
   * Callback for when operation configuration has changed
   *
   * @param operationConfiguration
   */
  const onMeshOperationConfigurationChanged = (operationConfiguration: IOperationConfiguration) => {
    setMeshOperationConfiguration(operationConfiguration);
  };

  /**
   * Sets the selected geometries the selected ones.
   *
   * @param selectedIds
   */
  const onSelectedGeometriesChanged = (selectedIds: Array<string>) => {
    setSelectedGeometryIds(selectedIds);
    // we do not reset configuration as we do not want to loose any changes already made by the user
  };

  // Make sure all selected geoemtries are shown in the viewer
  useEffect(
    () => {
      if (selectedGeometryIds) {
        selectedGeometryIds.forEach((id) => {
          showLayer(ELEMENT_CATEGORIES.GEOMETRY, id, false);
        });
      }
    },
    [selectedGeometryIds],
  );

  const selectedGeometries = useMemo(
    () => {
      return getDataItemsByIds(workspaceGeometries, selectedGeometryIds);
    },
    [workspaceGeometries, selectedGeometryIds],
  );
  /**
   * Callback for when the panel back (exit) button is pressed.
   */
  const onPanelExit = () => {
    goBackToReferrer();
  };

  /**
   * Gets the contextual operation descriptions based on geometries and queries.
   */
  const {
    basicCreateMeshOperationDescription,
    contextualChildOperationDescriptions,
    contextualOperationDescriptionsLoadingFailed,
    contextualOperationDescriptionsLoading,
    retryLoadContextualDescriptions,
  } = useAsyncLoadContextualCreateMeshOperationDescription(
    workspaceId,
    selectedGeometryIds,
    workspaceQueries,
    workspaceVariables || [],
  );

  const isCreateMeshOperationValid = useMemo(
    () => {
      // we only consider childoperations corresponding to selected geometry Ids
      const childOperationsForSelected = getFilteredChildOperations();
      return isCreateMeshChildOperationsValid(childOperationsForSelected);
    },
    [getFilteredChildOperations],
  );

  const saveDisabled =
    contextualOperationDescriptionsLoading ||
    meshingInProgress ||
    saveInProgress ||
    !meshOperationConfiguration ||
    !isCreateMeshOperationValid;

  const generateDisabled = saveDisabled;

  const isReadOnly = meshingInProgress || saveInProgress || !meshOperationConfiguration;

  const isStepComplete = (step: number) => {
    switch (step) {
      case CREATE_MESH_STEPS.SELECT_GEOMETRIES:
        return anyGeometriesSelected;
      case CREATE_MESH_STEPS.CONFIGURE:
        return isCreateMeshOperationValid;
      default:
        return false;
    }
  };

  if (contextualOperationDescriptionsLoadingFailed) {
    return (
      <MmgPanelSubsection>
        <p>{t('FAILED_TO_LOAD_AVAILABLE_CREATE_MESH_OPERATIONS')}</p>

        <MikeButton color="secondary" variant="contained" onClick={retryLoadContextualDescriptions}>{t('RETRY')}</MikeButton>
      </MmgPanelSubsection>
    );
  }

  const getExpandableButtonOptions = () => {
    return [
      {
        label: t('MESH_SAVE_AND_GENERATE'),
        callBack: createMesh,
        disable: generateDisabled,
      },
      {
        label: t('MESH_SAVE'),
        callBack: saveMesh,
        disabled: saveDisabled,
      },
    ];
  };

  const deselectSelectedGeometries = () => {
    const geomIds = workspaceGeometries.map((geom) => {
      return geom.id;
    });
    const notSelectedIds = geomIds.filter((g) => !selectedGeometryIds.includes(g));

    notSelectedIds &&
      notSelectedIds.length > 0 &&
      notSelectedIds.forEach((id) => {
        hideLayer(EElementCategories.GEOMETRY, id, false);
      });
    selectedGeometryIds &&
      selectedGeometryIds.length > 0 &&
      selectedGeometryIds.forEach((id) => {
        deselectLayer(EElementCategories.GEOMETRY, id);
      });
    handleNext();
  };
  return (
    <>
      {/* rename stepperInterpolationStyle if it can be used in both places -maybe stickyPanelStepperStyle ?? */}
      <MmgStepper cssProp={stepperStickyPanelStyle} activeStep={activeStep}>
        <MmgStep key={'STEP_SELECT_GEOMETRIES'} index={0} />
        <MmgStep key={'STEP_CONFIGURE'} index={1} last={true} />
      </MmgStepper>

      <MikeStickyPanelContent>
        <MmgStepContent
          step={CREATE_MESH_STEPS.SELECT_GEOMETRIES}
          activeStep={activeStep}
          index={0}
          className={isReadOnly ? meshCreateOperationConfigurationStyleReadOnly : ''}
        >
          {/* todo hevo - consider to move group inside component  */}
          <MmgGroup groupName={t('CREATE_MESH_SELECT_GEOMETRIES')} canBeHidden={false}>
            <MmgConnectedMeshConfigurationSelectGeometries
              selectedGeometryIds={selectedGeometryIds}
              onSelectionChanged={onSelectedGeometriesChanged}
            />
          </MmgGroup>
        </MmgStepContent>

        <MmgStepContent
          step={CREATE_MESH_STEPS.CONFIGURE}
          activeStep={activeStep}
          index={1}
          className={isReadOnly ? meshCreateOperationConfigurationStyleReadOnly : ''}
        >
          <MmgMeshOperationConfiguration
            groupName={t('MESH_MEMBERS_CONFIGURATION')}
            workspaceId={workspaceId}
            initialCreateMeshOperationConfiguration={meshOperationConfiguration}
            geometries={selectedGeometries}
            workspaceQueries={workspaceQueries}
            workspaceVariables={workspaceVariables}
            basicCreateMeshOperationDescription={basicCreateMeshOperationDescription}
            contextualChildOperationDescriptions={contextualChildOperationDescriptions}
            onMeshOperationConfigurationChanged={onMeshOperationConfigurationChanged}
          />
        </MmgStepContent>
      </MikeStickyPanelContent>

      <MikeStickyPanelBottomActions>
        {activeStep !== CREATE_MESH_STEPS.CONFIGURE && (
          <>
            <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
              {t('CANCEL')}
            </MikeButton>
            <MikeButton variant="outlined" onClick={deselectSelectedGeometries} disabled={!isStepComplete(activeStep)}>
              {t('NEXT')}
            </MikeButton>
          </>
        )}

        {activeStep === CREATE_MESH_STEPS.CONFIGURE && (
          <>
            <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
              {t('BACK')}
            </MikeButton>
            <MikeExpandableButton options={getExpandableButtonOptions()} />
          </>
        )}
      </MikeStickyPanelBottomActions>
    </>
  );
};
