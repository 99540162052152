/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { t } from '../../../translations/i18n';
import { store } from '../../../store';
import { useSelector } from 'react-redux';
import NestedTools from '../../../icons/NestedTools.svg?react';
import Terrain3D from '../../../icons/Terrain3D.svg?react';
import Tooltip from '@mui/material/Tooltip';
import {
  ViewerToolsButtonStyle,
  ViewerToolsWithSubmenuMenuIndicatorStyle,
  ViewerToolsWithSubmenuMenuStyle,
  ViewerToolsButtonFluidStyle,
} from '../../../workspaces/viewer/tools/viewer-tools-styles';
import ViewerToolUtils from '../../../workspaces/viewer/tools/viewer-tool-utils';
import MapManager from '../../../managers/MapManager';
import { MAP_DATA_TYPES, EMapDataTypes } from '../../../models/IMaps';
import { IWorkspace, IWorkspaceBounds } from '../../../models/IWorkspaces';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';
import { SUBMENUS, AVAILABLE_MAP_DATA_TYPES } from './viewer-tool-constants';
import { usePrevious } from '../../../shared/hooks/hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { IGlobalState } from '../../../store/reducers';

let activeMapTiles = [];
let MAP_BETA_MESSAGE_SHOWN = false;

const { toggleSubmenu } = ViewerToolUtils;
const { getVisualisationBounds, drawTile, deleteData } = MikeVisualizer;

type ViewerTool3DMapDataProps = {
  disabled?: boolean;
};

/**
 * @name MmgConnectedViewerTool3DMapData
 * @summary Allows turning on a layer with 3D map data
 *
 * @param props
 */
export function MmgConnectedViewerTool3DMapData(props: ViewerTool3DMapDataProps) {
  const [mapLoading, setMapLoading] = useState(false);

  const { disabled } = props;

  const openSubmenu: string = useSelector((state: IGlobalState) => state.MapToolReducer.openSubmenu);
  const mapDataType: EMapDataTypes = useSelector((state: IGlobalState) => state.MapToolReducer.mapDataType);
  const workspace: IWorkspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);

  const { boundingBox, epsgCode } = useMemo(
    () => {
      return workspace
        ? { boundingBox: workspace.bounds, epsgCode: workspace.epsgCode }
        : { boundingBox: null, epsgCode: null };
    },
    [workspace],
  );

  const prevMapDataType = usePrevious(mapDataType);

  /**
   * Applies a 3D map to the current visualization.
   * If boundingBox is not defined, the renderer bounds are used as a fallback.
   *
   * @param mapBoundingBox
   * @param mapType
   * @param mapEpsgCode
   */
  function applyMap(mapBoundingBox: null | IWorkspaceBounds, mapType: EMapDataTypes, mapEpsgCode: number) {
    if (!MAP_BETA_MESSAGE_SHOWN) {
      store.dispatch({
        type: 'toast/ADD/NOTICE',
        toast: { text: t('3D_MAPS_BETA_NOTICE') },
      });

      MAP_BETA_MESSAGE_SHOWN = true;
    }

    let gradientPreset;
    const bounds = mapBoundingBox || getVisualisationBounds();

    if (bounds) {
      const [xMin, xMax, yMin, yMax] = bounds as IWorkspaceBounds;
      const getElevation = true;

      switch (mapType) {
        case MAP_DATA_TYPES.SATELLITE:
          gradientPreset = 'Asymmtrical Earth Tones (6_21b)';
          break;

        case MAP_DATA_TYPES.TERRAIN:
          gradientPreset = 'gist_earth';
          break;

        case MAP_DATA_TYPES.STREETS:
          gradientPreset = 'X Ray';
          break;

        default:
          gradientPreset = null;
      }

      setMapLoading(true);
      removeMap();

      MapManager.getMapTileInformation(xMin, yMin, xMax, yMax, mapEpsgCode)
        .then(({ zoom, x, y }) => {
          const tiles = [];
          for (let row = x.min; row <= x.max; row++) {
            for (let column = y.min; column <= y.max; column++) {
              tiles.push({ row, column, zoom });
            }
          }

          const handleTile = (tileZoom: number, row: number, column: number) => {
            return (mapData: string) => {
              const tileId = `${tileZoom}/${row}/${column}`;
              activeMapTiles.push(tileId);

              // TODO dan: some of this could be moved to the tile manager.
              // Passing the map type to the tile manager could be a good idea.
              // The `drawTile` api can also be simplified to accept opacity only instead of rgba colors.
              drawTile(
                mapData,
                tileId,
                [0, 0, 0, 0.6],
                [0, 0, 0, 0.6],
                {
                  gradientPreset: mapType === MAP_DATA_TYPES.ELEVATION ? 'gist_earth' : gradientPreset,
                },
                mapType === MAP_DATA_TYPES.ELEVATION ? 'Elevation' : 'ARGB',
              );
            };
          };

          const getTilePromises = tiles.map(({ zoom: tileZoom, row, column }) =>
            MapManager.getMapTile(
              mapType === MAP_DATA_TYPES.ELEVATION ? MAP_DATA_TYPES.TERRAIN : mapType,
              tileZoom,
              row,
              column,
              mapEpsgCode,
              getElevation,
              false,
            ).then(handleTile(tileZoom, row, column)),
          );

          return Promise.all(getTilePromises);
        })
        .then(() => {
          store.dispatch({
            type: 'maptools/SET_MAPDATA_TYPE',
            mapDataType: mapType,
          });
        })
        .finally(() => setMapLoading(false));
    }
  }

  /**
   * Removes the 3D map from the visualization.
   *
   * TODO dan: some of this could be moved to the tile manager.
   * TODO dan: maps should be hidden instead, not removed to avoid the extra load & improve the UX.
   */
  function removeMap() {
    activeMapTiles.forEach((mapTileId) => deleteData(mapTileId));
    activeMapTiles = [];

    store.dispatch({
      type: 'maptools/SET_MAPDATA_TYPE',
      mapDataType: MAP_DATA_TYPES.NONE,
    });
  }

  /**
   * Makes a function that can apply the given map type.
   *
   * @param type
   */
  function setBasemap(type: EMapDataTypes) {
    return () => {
      toggleMapSubmenu();

      if (type !== MAP_DATA_TYPES.NONE) {
        applyMap(boundingBox, type, epsgCode);
        dispatchEnableMapData(true);
      } else {
        removeMap();
        dispatchEnableMapData(false);
      }
    };
  }

  const dispatchEnableMapData = (enable: boolean) => {
    if (enable) {
      store.dispatch({ type: 'maptools/ENABLE_3D_MAP_DATA' });
    } else {
      store.dispatch({ type: 'maptools/DISABLE_3D_MAP_DATA' });
    }
  };

  /**
   * Toggles the 3d map submenu.
   */
  function toggleMapSubmenu() {
    toggleSubmenu(SUBMENUS.MAP_TYPE);
  }

  useEffect(
    () => {
      if (mapDataType !== prevMapDataType) {
        // todo hevo Currently we only remove mapdata if set to none in global state.
        // we should consider also updating the data if set to soemthing else.
        // However, this will require a major rewrite of this component because of the async loading
        if (mapDataType === MAP_DATA_TYPES.NONE) {
          removeMap();
          dispatchEnableMapData(false);
        }
      }
    },
    [mapDataType, prevMapDataType],
  );

  if (!epsgCode || !boundingBox) {
    return <></>;
  }

  return (
    <>
      <Tooltip title={t('TOOL_TERRAIN_3D')} placement="right">
        <button css={ViewerToolsButtonStyle} onClick={toggleMapSubmenu} hidden={mapLoading} disabled={disabled}>
          {mapDataType === MAP_DATA_TYPES.NONE ? <Terrain3D /> : t('MAP_TYPE_SHORT_' + mapDataType)}
        </button>
      </Tooltip>

      <button css={ViewerToolsButtonStyle} hidden={!mapLoading}>
        <CircularProgress size={14} />
      </button>

      <span css={ViewerToolsWithSubmenuMenuIndicatorStyle}>
        <NestedTools />
      </span>

      <ul css={ViewerToolsWithSubmenuMenuStyle} hidden={openSubmenu !== SUBMENUS.MAP_TYPE}>
        {AVAILABLE_MAP_DATA_TYPES.filter((type) => type !== mapDataType).map((type: EMapDataTypes) => (
          <li key={type}>
            <button
              css={css`
                ${ViewerToolsButtonStyle} ${ViewerToolsButtonFluidStyle};
              `}
              onClick={setBasemap(type)}
            >
              {t('MAP_TYPE_' + type.toUpperCase())}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}
