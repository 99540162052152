import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { ROUTES, getRouteByPath } from '../../app/routes';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { t } from '../../translations/i18n';
import { MmgConnectedVariableOperationsSection } from '../operations/variable-operation-section';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { LinearProgress } from '@mui/material';
import { getVariableOperationPanelDescription } from '../../shared/panels/panel-utils';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

const { hideOtherLayers, isLayerWorking } = LayerUtils;

/**
 * @name MmgConnectedVariableMultipleOperationPanel
 * @summary Allows performing 'geometry operation' on the current geometry, former referred to as 'transforms'.
 *
 * If the geometry does not exists, it will redirect to geometry details panel
 *
 */
export const MmgConnectedVariableMultipleOperationPanel = () => {
  const { workspaceId, projectId, variableId } = useParams();
  const navigate = useNavigate()
  const getVariableSelectorInstance = WorkspaceVariableSelectors.makeGetVariable();
  const getDrawnDataSelectorInstance = WorkspaceVariableSelectors.makeGetVariableDrawnData();
  const variable: IWorkspaceEnrichedVariable = useSelector((state: IGlobalState) =>
    getVariableSelectorInstance(state, { variableId }),
  );
  const variableDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { variableId }),
  );
  const selectedWorkspaceVariables: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer.selectedWorkspaceVariables,
  );

  const title = t('VARIABLE_OPERATION_PANEL');

  // When an operation is submitted succesfully we currently navigate to workspace panel
  const onOperationSubmitted = (success: boolean) => {
    if (success) {
      // always go to workspace panel on success, since all geoemtry operations currently will create a new geometry
      navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));       
    }
  };

  // todo hevo show/hide items should be as in geoemtry detail
  // make sure the only item shown in the viewer is the geometry at hand when the geometry is loaded
  useEffect(
    () => {
      if (variable && variable.id) {
        hideOtherLayers(ELEMENT_CATEGORIES.VARIABLE, variable.id);
      }
      else{
        navigate(getRouteByPath(ROUTES.variableDetailsPanel.path, { projectId, workspaceId, variableId }, ROUTES.workspace.path))   
      }
    },
    [navigate, projectId, variable, variableId, workspaceId],
  );

  const drawnVariables = variableDrawnData ? [variableDrawnData] : [];
  const isVariableWorking = isLayerWorking(variable, drawnVariables);

  const description = getVariableOperationPanelDescription(variable, drawnVariables);

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={title} panelDescription={description} />
        {isVariableWorking && <LinearProgress />}
      </MikeStickyPanelHeaderContainer>

      <MmgConnectedVariableOperationsSection
        projectId={projectId}
        workspaceId={workspaceId}
        variableIds={selectedWorkspaceVariables}
        onOperationSubmitted={onOperationSubmitted}
      />
    </MikeStickyPanel>
  );
};
