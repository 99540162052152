/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useShowAllButUserHidden } from '../../shared/hooks/useShowAllButUserHidden';
import { graticuleHandler } from '../../workspaces/viewer/tools/makeGraticule';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import { IGlobalState } from '../../store/reducers';
import { store } from '../../store';
import { IViewerMode } from '../../models/IViewMode';
import { useSelector } from 'react-redux';
import { FormControlLabel, Switch } from '@mui/material';
import { MmgGroup } from '../../shared/groups/group';
import { t } from '../../translations/i18n';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { getRouteByPath, ROUTES } from '../../app/routes';
import MmgWorkspaceEdit from './workspace-edit';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { useNavigate, useParams } from 'react-router-dom';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

const gridCtrlRootStyle = css`
  margin: 2rem;
`;

const gridCtrlLabelStyle = {
 display: 'inline',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  paddingLeft: mikeSharedTheme.spacing(1),
} 

/**
 * @name MmgConnectedWorkspaceEditPanel
 * @summary Allows editing workspace settings.
 *
 */
export const MmgConnectedWorkspaceEditPanel = () => {
  const { projectId, workspaceId } = useParams();
  const navigate = useNavigate();
  const viewerModeSettings: IViewerMode = useSelector((state: IGlobalState) => state.ViewerModeReducer);
  const { showGridOverlay } = viewerModeSettings || {};
  useShowAllButUserHidden();

  const onGraticuleClick = () => {
    store.dispatch({
      type: EMapToolActionType.SET_GRIDOVERLAY,
      showGridOverlay: !showGridOverlay,
    });
  };

  const onWorkspaceDeleted = useCallback(
    () => {
      const workspaceListRoutePath = getRouteByPath(ROUTES.workspaceList.path, {
        projectId,
      });
      navigate(workspaceListRoutePath);
    },
    [navigate, projectId],
  );

  const onWorkspaceUpdated = () => {
    navigateToWorkspacePanel();
  };

  const navigateToWorkspacePanel = useCallback(
    () => {
      const workspacePanelPath = getRouteByPath(ROUTES.workspace.path, {
        projectId,
        workspaceId,
      });
      navigate(workspacePanelPath);
    },
    [navigate, projectId, workspaceId],
  );

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={t('WORKSPACE_EDIT_PANEL')} />
      </MikeStickyPanelHeaderContainer>
      <MmgWorkspaceEdit onWorkspaceUpdated={onWorkspaceUpdated} onWorkspaceDeleted={onWorkspaceDeleted} />
      {graticuleHandler &&
        !graticuleHandler.error && (
          <>
            <MmgGroup groupName={t('WORKSPACE_OVERLAYS')}>
              <div css={gridCtrlRootStyle}>
                <FormControlLabel
                  control={<Switch onChange={onGraticuleClick} checked={!!showGridOverlay} />}
                  label={t('WORKSPACE_GRID_SWITCH_LABEL')}
                  title={t('WORKSPACE_GRID_SWITCH_LABEL')}
                  sx={{ label: gridCtrlLabelStyle }}
                />
              </div>
            </MmgGroup>
          </>
        )}
    </MikeStickyPanel>
  );
};
