/* eslint-disable react-refresh/only-export-components */
import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { t } from '../../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../../app/routes';
import Delete from '../../../icons/Delete.svg?react';
import Operations from '../../../icons/Operations.svg?react';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import { ILayerAction } from '../../../shared/layers/actions/layer-default-actions';

/**
 * @name MmgConnectedVariableMultipleActions
 * @summary Variable multi-selection actions.
 *
 */
export function MmgConnectedVariableMultipleActions() {
  const { projectId, workspaceId } = useParams();
  const navigate = useNavigate();
  const layerActions = getLayerActions(projectId, workspaceId, navigate);

  return <MikeLayerActionMenu layerId="multi-variables" layerActions={layerActions} />;
}

const getLayerActions = (projectId: string, workspaceId: string, navigate: NavigateFunction) => {
  const layerActions = [];

  const deleteLayerAction = getDeleteVariablesAction(projectId, workspaceId, navigate, false);

  layerActions.push(deleteLayerAction);

  const operationLayerAction = getVariableOperationAction(projectId, workspaceId, navigate, false);

  layerActions.push(operationLayerAction);

  return layerActions;
};

export const getDeleteVariablesAction = (projectId: string, workspaceId: string, navigate: NavigateFunction, disabled?: boolean): ILayerAction => {
  const onDelete = () => {
    return navigate(getRouteByPath(ROUTES.variablesDeletePanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
  };

  const deleteLayersAction = {
    actionId: 'VARIABLES_DELETE',
    actionDisplayName: t('VARIABLES_DELETE'),
    actionIcon: <Delete />,
    onAction: () => onDelete(),
    disabled,
  };

  return deleteLayersAction;
};

export const getVariableOperationAction = (
  projectId: string,
  workspaceId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onOperation = () => {
    return navigate(getRouteByPath(ROUTES.variablesOperationSelectItemsPanel.path, { workspaceId, projectId }, ROUTES.workspace.path));     
  };

  const operationLayerAction = {
    actionId: 'VARIABLE_OPERATIONS',
    actionDisplayName: t('VARIABLE_OPERATIONS'),
    actionIcon: <Operations />,
    onAction: () => onOperation(),
    disabled,
  };

  return operationLayerAction;
};
