import { useState, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash-es';
import { CircularProgress } from '@mui/material';
import { IContextualOperationDescriptions, IOperationDescription } from '../../../models/IOperationDescriptions';
import { MmgPanelSubsection } from '../../../shared/panels/panel-subsection';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { MmgMeshInterpolationVariableConfiguration } from './mesh-interpolation-variable-configuration';
import { usePrevious } from '../../../shared/hooks/hooks';
import { useGroupedAndSortedVariableConfigurations } from '../useGroupedAndSortedVariableConfigurations';
import { createInterpolationChildConfigurationFromDescription } from '../mesh-interpolation-util';
import { IWorkspaceAttribute } from '../../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../../models/IWorkspaceAttributeSettings';
import { IWorkspaceQuery } from '../../../models/IQueries';

type MeshInterpolationVariablesConfigurationsProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
  variables: Array<IWorkspaceEnrichedVariable>;
  initialOperationConfigurations?: Array<IOperationConfiguration>;
  contextualOperationDescriptions: IContextualOperationDescriptions;
  meshAttributes?: Array<IWorkspaceAttribute>;
  meshAttributeSettings?: Array<IWorkspaceAttributeSettings>;
  meshQueries?: Array<IWorkspaceQuery>;

  onConfigurationsChanged: (operationConfigurations: Array<IOperationConfiguration>) => void;
};

/**
 * @name MmgMeshInterpolationVariablesConfigurations
 * @summary Generic component that given a list of variables and mesh interpolation descriptions, allows configuring operations for each of them.
 * Will supply the complete list of updated configuration objects via a callback.
 *
 * @param props
 */
export const MmgMeshInterpolationVariablesConfigurations = (props: MeshInterpolationVariablesConfigurationsProps) => {
  const {
    projectId,
    workspaceId,
    meshId,
    variables,
    initialOperationConfigurations,
    contextualOperationDescriptions,
    meshAttributes,
    meshAttributeSettings,
    meshQueries,
    onConfigurationsChanged,
  } = props;

  const previousContextualDescriptions = usePrevious(contextualOperationDescriptions);

  const previousVariables = usePrevious(variables);

  const [childConfigurations, setChildConfigurations] = useState(initialOperationConfigurations); // Object with current configurations for variables and related queries. This will be sent higher up the component chain and used for submission.

  const callOnConfigurationsChanged = useCallback(
    (nextChildConfigurations) => {
      if (onConfigurationsChanged) {
        onConfigurationsChanged(nextChildConfigurations);
      }
    },
    [onConfigurationsChanged],
  );

  const onVariableConfigurationChanged = useCallback(
    (variableId: string) => (operationConfiguration: IOperationConfiguration) => {
      const nextChildConfigurations = (childConfigurations || []).filter(
        ({ inputIds }) => inputIds && inputIds.indexOf(variableId) === -1,
      );

      nextChildConfigurations.push({
        ...operationConfiguration,
      });

      setChildConfigurations(nextChildConfigurations);
      callOnConfigurationsChanged(nextChildConfigurations);
    },
    [childConfigurations, callOnConfigurationsChanged],
  );

  // If descriptions or variables change we create operation configurations for variables that do not already have a configuration
  useEffect(
    () => {
      if (
        !isEqual(previousContextualDescriptions, contextualOperationDescriptions) ||
        !isEqual(previousVariables, variables)
      ) {
        // Nothing to to if no descriptions are available
        if (!contextualOperationDescriptions) {
          return;
        }
        // Create operation configurations for variables that do not already have a configuration
        const newConfigurations = variables.reduce((acc, { id }) => {
          if (
            initialOperationConfigurations &&
            initialOperationConfigurations.findIndex(({ inputIds }) => inputIds && inputIds.indexOf(id) !== -1) !== -1
          ) {
            return acc;
          }
          const operationDescriptions = contextualOperationDescriptions[id] || {};
          const firstOperationKey = Object.keys(operationDescriptions)[0] || '';

          const config = createInterpolationChildConfigurationFromDescription(
            firstOperationKey,
            [id],
            operationDescriptions,
          );

          if (!config) {
            return acc;
          }

          return [...acc, config];
        }, []);

        const nextChildConfigurations = [...(initialOperationConfigurations || []), ...newConfigurations];

        setChildConfigurations(nextChildConfigurations);
        callOnConfigurationsChanged(nextChildConfigurations);
      }
    },
    [
      initialOperationConfigurations,
      previousContextualDescriptions,
      contextualOperationDescriptions,
      previousVariables,
      variables,
      callOnConfigurationsChanged,
    ],
  );

  const groupedVariableConfigurations = useGroupedAndSortedVariableConfigurations(childConfigurations, variables);

  // eslint-disable-next-line no-extra-boolean-cast
  const meshOperationsLoading = !Boolean(contextualOperationDescriptions);

  if (meshOperationsLoading) {
    return (
      <MmgPanelSubsection>
        <CircularProgress />
      </MmgPanelSubsection>
    );
  }

  return (
    groupedVariableConfigurations && (
      <>
        {Object.keys(groupedVariableConfigurations).map((variableId) => {
          const { variable, variableConfiguration } = groupedVariableConfigurations[variableId];

          const variableInterpolationDescriptions = (contextualOperationDescriptions
            ? contextualOperationDescriptions[variableId] || {}
            : {}) as { [operationKey: string]: IOperationDescription };

          return (
            variableConfiguration && (
              <MmgMeshInterpolationVariableConfiguration
                key={variableId}
                projectId={projectId}
                workspaceId={workspaceId}
                meshId={meshId}
                variable={variable}
                initialVariableConfiguration={variableConfiguration}
                variableInterpolationDescriptions={variableInterpolationDescriptions}
                meshAttributes={meshAttributes}
                meshAttributeSettings={meshAttributeSettings}
                meshQueries={meshQueries}
                onVariableInterpolationConfigurationChanged={onVariableConfigurationChanged(variable.id)}
              />
            )
          );
        })}
      </>
    )
  );
};
