import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { MmgLayerSelect } from '../../shared/layers/mesh-layer-select';
import { MmgConnectedLayerVariableSelectActions } from './actions/layer-variable-select-actions';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import VariableIconUtils from '../variable-icon-utils';
import LayerUtils, { fetchLayer } from '../../shared/layers/layer-utils';
import { MmgLayerErrorSelect } from '../../shared/layers/mesh-layer-error-select';
import { MmgLayerError } from '../../shared/layers/mesh-layer-error';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IGlobalState } from '../../store/reducers';
import { MikeLayerWorking } from '../../shared-components/mike-layer/layer-working';
import { MikeLayerColorComplication } from '../../shared-components/mike-layer/complications/layer-color-complications';

const { getIcon } = VariableIconUtils;
const {
  isLayerWorking,
  isLayerFailed,
  isLayerHidden,
  getLayerSurfaceColor,
  getLayerEdgeColor,
  selectLayer,
  deselectLayer,
} = LayerUtils;

type VariableSelectListProps = {
  variables: Array<IWorkspaceEnrichedVariable>;
  hiddenVariables: Array<string>;
  drawnVariables: Array<IDrawnDataItem>;
  selectedVariables: Array<string>;
  disabledVariables?: Array<string>;
  isFailedSelectable?: boolean;
  onLayerSelectChanged?: (layerId: string, selected: boolean) => void;
  isInterpolation?: boolean;
};

/**
 * @name MmgConnectedVariableSelectList
 * @summary Given an array of variables, renders them and allows their selection.
 *
 * @param props
 */
export const MmgConnectedVariableSelectList = (props: VariableSelectListProps) => {
  const {
    variables,
    hiddenVariables,
    drawnVariables,
    selectedVariables,
    disabledVariables = [],
    isFailedSelectable,
    onLayerSelectChanged: onLayerSelectChangedProps,
    isInterpolation,
  } = props;

  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);

  useEffect(
    () => {
      variables.forEach((v: IWorkspaceEnrichedVariable) => {
        if (!loadedData.includes(v.id)) {
          fetchLayer(v.id, ELEMENT_CATEGORIES.VARIABLE, isInterpolation);
        }
      });
    },
    [isInterpolation, loadedData, variables],
  );

  const onLayerSelectChanged = (layerId: string, selected: boolean) => {
    if (selected) {
      selectLayer(ELEMENT_CATEGORIES.VARIABLE, layerId);
    } else {
      deselectLayer(ELEMENT_CATEGORIES.VARIABLE, layerId);
    }

    if (onLayerSelectChangedProps) {
      onLayerSelectChangedProps(layerId, selected);
    }
  };

  if (!variables || variables.length === 0) {
    return null;
  }

  return (
    <div>
      {variables.map((variable) => {
        const { id, name, message } = variable;
        const isWorking = isLayerWorking(variable, drawnVariables);
        const isDisabled = disabledVariables.indexOf(id) !== -1;

        const baseLayerProperties = {         
          layerId: id,
          layerName: name,
          rightActions: <MmgConnectedLayerVariableSelectActions layerId={id} variable={variable} />,
          isLayerFaded: isDisabled || isLayerHidden(ELEMENT_CATEGORIES.VARIABLE, id, hiddenVariables),
        };

        if (isLayerFailed(variable)) {
          return isFailedSelectable ? (
            <MmgLayerErrorSelect
              key={id}
              {...baseLayerProperties}
              selected={selectedVariables.indexOf(id) !== -1}
              layerDescription={message}
              onLayerSelectChanged={onLayerSelectChanged}
              disabled={isDisabled}
            />
          ) : (
            <MmgLayerError key={id} {...baseLayerProperties} layerDescription={message} />
          );
        }

        if (isWorking) {
          return <MikeLayerWorking key={id} {...baseLayerProperties} />;
        }

        return (
          <MmgLayerSelect
            key={id}
            {...baseLayerProperties}
            selected={selectedVariables.indexOf(id) !== -1}
            leftIcon={getIcon(variable)}
            leftComplications={
              <MikeLayerColorComplication
                edgeColor={getLayerSurfaceColor(variable, drawnVariables)}
                surfaceColor={getLayerEdgeColor(variable, drawnVariables)}
              />
            }
            onLayerSelectChanged={onLayerSelectChanged}
            disabled={isDisabled}
          />
        );
      })}
    </div>
  );
};
