/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useCallback } from 'react';
import Fab from '@mui/material/Fab';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import Search from '../../icons/Search.svg?react'
import Close from '../../icons/Close.svg?react';
import GenericMikeTable from './GenericMikeTable';
import { Typography } from '@mui/material';
import { orderBy } from 'lodash';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';

interface IProps {
  rows: Array<any>; // TODO: Add real type iso any
  loading: boolean;
  onSelect?: (row: any) => void; // TODO: Add real type iso any
  onAdd?: (project: any) => void; // TODO: Add real type iso any
  title?: string;
  searchPlaceHolder?: string;
  tooltipAddButton?: string;
  addButtonText?: string;
  config: any; // TODO: Add real type iso any
  columns: any; // TODO: Add real type iso any
  onUpdate?: (row: any) => void; // TODO: Add real type iso any
  onDelete?: (row: any) => void; // TODO: Add real type iso any
  actions?: any; // TODO: Add real type iso any
  maxHeight?: string;
}

const containerStyle = css`
  background: ${MIKE_COLORS.XLIGHTGREY};
  vertical-align: center;
  margin-top: 32px;
  box-sizing: content-box;
`;
const containerNoMarginsStyle = css`
  background: ${MIKE_COLORS.XLIGHTGREY};
  vertical-align: center;
  box-sizing: content-box;
`;
const tableContainerStyle = css`
  margin-top: 40px;
`;
  const tableContainerNoMarginsStyle = css`
    padding: 0;
`;
  const textFieldStyle = css`
    width: 320px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 25px;
    border: 1px solid #DBE4E9 !important;
    maring: 0;
    margin-left: 32px;
    margin-top: 6px;
`;
  const titleStyle = css`
    margin: 0;
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
    margin-left: 32px;
`;
  const fabStyle = css`
    float: right;
    text-transform: none;
    margin: 0;
    margin-right: 32px;
`;
  const headStyle = css`
    padding: 0;
    margin-bottom: 24px;
`;


/**
 * @name MikeTable
 * @summary A generic data table component, built on top of Material UI tables.
 */
export const MikeTable: React.FC<IProps> = ({
  columns,
  config,
  rows,
  title,
  searchPlaceHolder,
  onSelect,
  loading,
  addButtonText,
  onAdd,
  actions,
  maxHeight,
}) => {
  const [filter, setFilter] = useState('');
  const [filteredRows, setFilteredRows] = React.useState(rows);
  const [isSorting, setIsSorting] = React.useState(false);
  const [orderByField, setOrderByField] = React.useState({
    field: config.defaultSort.field,
    dir: 'desc',
    type: config.defaultSort.type,
  });  

  React.useEffect(() => {
    let dates: Array<any> = [];
    if (filter === '') {
      let orderedRows: Array<any> = [];
      if (orderByField.type === 'date') {
        orderedRows = rows.sort((a: any, b: any) => {
          const aDate = new Date(a[orderByField.field]);
          const bDate = new Date(b[orderByField.field]);
          return aDate.getTime() - bDate.getTime();
        });
        dates = orderByField.dir === 'desc' ? orderedRows.reverse() : orderedRows;
      } else {
        // Ignore case when ordering by string:
        const sortHandler = (row) => {
          return row[orderByField.field].toLowerCase
            ? row[orderByField.field].toLowerCase()
            : row[orderByField.field];
        };
        dates = orderBy(rows, sortHandler, [orderByField.dir as any]);
      }
      setFilteredRows(dates);
      setIsSorting(false);
      return;
    }
    const filterRows: Array<any> = [];
    for (const row of rows) {
      let contains = false;
      for (const col of columns) {
        if (contains || !col.filtrable) {
          break;
        }
        contains = false;
        const objValues: Array<string> = Object.values(row);
        for (const value of objValues) {
          if (
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase())
          ) {
            filterRows.push(row);
            contains = true;
            break;
          }
        }
      }
    }
    let orderedFilteredRows: Array<any> = [];
    if (orderByField.type === 'date') {
      orderedFilteredRows = filterRows.sort((a: any, b: any) => {
        const aDate = new Date(a[orderByField.field]);
        const bDate = new Date(b[orderByField.field]);
        return aDate.getTime() - bDate.getTime();
      });
      orderedFilteredRows =
        orderByField.dir === 'desc' ? orderedFilteredRows.reverse() : orderedFilteredRows;
    } else {
      orderedFilteredRows = orderBy(filterRows, orderByField.field, [orderByField.dir as any]);
    }
    setFilteredRows(orderedFilteredRows);
    setIsSorting(false);
  }, [columns, filter, orderByField, rows]);

  const handleClearFilter = useCallback(() => {
    setFilter('');
  }, []);

  const handleSetFilter = useCallback((e: any) => {
    setFilter(e.target.value);
  }, []);

  // TODO: Use real type iso any:
  const handleSetSorting = useCallback((param: any) => {
    setIsSorting(true);
    setOrderByField(param);
  }, []);

  return (
    <>
      <div
        css={
          config.addButton || config.filtered ? containerStyle : containerNoMarginsStyle
        }
      >
        <div>
          <div css={headStyle}>
            <div>
              {title && (
                <Typography variant="h1" css={titleStyle}>
                  {title ? title : null}
                </Typography>
              )}
            </div>
            <div>
              {config.addButton && (
                <Fab color="secondary" css={fabStyle} onClick={onAdd} variant="extended">
                  <AddIcon />
                  <span style={{ padding: 5 }}>{addButtonText ? addButtonText : null}</span>
                </Fab>
              )}
            </div>
            <div>
              {config.filtered && (
                <Input
                  autoFocus
                  css={textFieldStyle}
                  onChange={handleSetFilter}
                  disableUnderline={true}
                  placeholder={searchPlaceHolder ? searchPlaceHolder : 'What are you looking for?'}
                  value={filter}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  endAdornment={
                    filter !== '' ? (
                      <InputAdornment position="end" onClick={handleClearFilter}>
                        <Close />
                      </InputAdornment>
                    ) : null
                  }
                />
              )}
            </div>
           <div>
              <div />
           </div>
          </div>
        </div>
        <div
          css={
            config.addButton || config.filtered
              ? tableContainerStyle
              : tableContainerNoMarginsStyle
          }
        >
          <GenericMikeTable
            rows={filteredRows}
            filter={filter}
            loading={loading}
            isSorting={isSorting}
            columns={columns}
            config={config}
            actions={actions}
            onSelect={onSelect}
            maxHeight={maxHeight}
            onSort={handleSetSorting}
            sortByField={orderByField}
          />
        </div>
      </div>
    </>
  );
};

export default MikeTable;
