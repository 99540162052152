import { store } from '../store/store';
/* import { getRouteNameByPath } from './routes';
import { trackPageView } from '../managers/application-insights'; */
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

type ShellProps = {
  readonly RouteComponent: any;
  readonly route: any;
};


/**
 * The shell is used as a wrapper that has access to the component route params.
 * Do not put any other component inside except for <RouteComponent/>, there can be a potential performance penalty.
 * Components that should be available on all routes should go into <App>.
 */
export const MmgShell = (props: ShellProps)  => {
  const params = useParams(); 

  useEffect(() => {
    // This makes route properties available in the AppReducer,
      // allowing any component to function without knowledge of React Router.
      //
      // This helps by removing the need to nest components under a route to access params (HoC).
      // In some cases, that is not possible due to the layout of the application.
      //
      // NB: this does not capture events (or paramters) of sub-routes.
      store.dispatch({
        type: 'app/router/NEW_ROUTE',
        routeParams: params,
      });

      // trackPageView(routeMatch.url, getRouteNameByPath(routeMatch.path));

  }, [params])
 
      
      

 
    const { RouteComponent } = props;

    return <RouteComponent />;
  
}
