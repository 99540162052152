import { useMemo } from 'react';
import { IWorkspaceGeometry } from '../../models/IGeometries';
import { IWorkspaceQuery } from '../../models/IQueries';
import WorkspaceQueryUtils from '../../store/selectors/WorkspaceQueryUtils';

/**
 *
 * @param geometry Custom hook that filters the workspace queries given to those having the geometry as target
 * @param workspaceQueries
 */
export const useFilterQueriesForGeometry = (geometry: IWorkspaceGeometry, workspaceQueries: Array<IWorkspaceQuery>) => {
  const queriesForGeometry = useMemo(
    () => {
      if (!geometry || !workspaceQueries || workspaceQueries.length === 0) {
        return [];
      }

      return WorkspaceQueryUtils.filterQueriesByTargetItem(workspaceQueries, geometry.id);
    },
    [workspaceQueries, geometry],
  );

  return queriesForGeometry;
};
