import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';
import { store } from '../../../store';
import { MMG_BASE_MAPS } from './viewer-tool-constants';

const { enable2DPolygonSelection, disableAllDrawingTools } = MikeVisualizer;

/**
 * Enables feature extractions, setting the view mode to 2D and applying a base map if none active.
 *
 * @param viewerBaseMapId
 */
export const enableExtractFeatures = async (viewerBaseMapId: string) => {
  store.dispatch({ type: 'maptools/SET_VIEWER_MODE_TO_2D' });

  // Makes sure there is a type of basemap set. This is deliberate for this tool - it is assumed a map is useful when extracting features.
  if (!viewerBaseMapId || viewerBaseMapId === MMG_BASE_MAPS.NONE.id) {
    store.dispatch({ type: 'maptools/SET_BASEMAP_TO_DEFAULT' });
  }

  disableAllDrawingTools();
  await enable2DPolygonSelection();
};
