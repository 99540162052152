/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { Typography } from '@mui/material';
import { MmgMetas } from '../../shared/metas/metas';
import { ROUTES, getRouteByPath } from '../../app/routes';
import MmgProjectsTable from '../../projects/projects-table/projects-table';
import MmgFilterInput from '../../shared/filter-input/filter-input';
import { MmgConnectedWorkspaceRecents } from '../../workspaces/recent/workspace-recents';
import MmgConnectedCreateFolder from '../../workspaces/create/create-folder';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';
import { IGlobalState } from '../../store/reducers';
import { store } from '../../store';

const HomePageGridStyle = css`
  background-color: ${MIKE_COLORS.XLIGHTGREY};
  margin: ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(2)} 0 ${mikeSharedTheme.spacing(3)};
`;

const HeaderContainerStyle= css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HomePageGridWorkspaceStyle = css`
  padding-top: ${mikeSharedTheme.spacing(2)};
`;

const homeCrumb = {
  name: t('HOME'),
  routePath: getRouteByPath(ROUTES.home.path),
};

/**
 * @name MmgConnectedHomePage
 * @summary Connected homepage with filterable table and recent workespaces
 *
 */
export const MmgConnectedHomePage = () => {
  const { projectList, projectListLoading } = useSelector((state: IGlobalState) => state.ProjectReducer);

  const [filter, setFilter] = useState('');

  // on mount load Projects
  useEffect(() => {
    store.dispatch({ type: 'project/LOAD_LIST' });
  }, []);

  // on mount set breadcrumbs to home
  useEffect(() => {
    store.dispatch({
      type: 'app/breadcrumbs/SET',
      breadcrumbs: homeCrumb,
    });
  }, []);

  const handleClearFilter = useCallback(() => {
    setFilter('');
    store.dispatch({
      type: 'app/filter/SET',
      filter: '',
    });
  }, []);

  const handleSetFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    store.dispatch({
      type: 'app/filter/SET',
      filter: e.target.value,
    });
  }, []);

  return (
    <>
      <MmgMetas metaTitle={t('HOME_PAGE_SUBTITLE')} />
      <div css={HomePageGridStyle}>
        <div css={HeaderContainerStyle}>
          <Typography variant="h1">{t('HOME_PAGE_SUBTITLE')}</Typography>
          <MmgConnectedCreateFolder />
        </div>        
        <MmgFilterInput
          value={filter}
          onChangeValue={handleSetFilter}
          onResetValue={handleClearFilter}
          data-testid="mmg-projects-filter"
        />       
       <div css={HomePageGridWorkspaceStyle}><MmgConnectedWorkspaceRecents /></div>
      </div>
      <MmgProjectsTable
        filter={filter}
        projectList={projectList}
        projectListLoading={projectListLoading}
        data-testid="mmg-projects-list"
      />
    </>
  );
};
