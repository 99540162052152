import { store } from '../../store/store';
import { useSelector } from 'react-redux';
import { MmgViewerTool2DSpatialSelection } from '../../workspaces/viewer/tools/viewer-tool-2d-spatial-selection';
import { EToolButtonType } from '../../shared/tools/IToolButton';
import { CONFIRM_PANEL_IDS } from '../../shared/confirm-containers/confirm-constants';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { IGlobalState } from '../../store/reducers';

type MeshTool2DSpatialSelectionProps = {
  toolButtonType?: EToolButtonType;
};

/**
 * @name MmgConnectedMeshTool2DSpatialSelection
 * @summary Allow making a spatial selection on a mesh.
 *
 * @param props
 */
export function MmgConnectedMeshTool2DSpatialSelection(props: MeshTool2DSpatialSelectionProps) {
  const { toolButtonType } = props;
  const activeConfirmPanelId = useSelector((state: IGlobalState) => state.ConfirmPanelReducer.activeConfirmPanelId);

  const onSpatialSelectionToggled = () => {
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.MESH_SELECTION,
    });
  };

  return (
    <MmgViewerTool2DSpatialSelection
      spatialSelectionActive={activeConfirmPanelId === CONFIRM_PANEL_IDS.MESH_SELECTION}
      onSpatialSelectionToggled={onSpatialSelectionToggled}
      toolButtonType={toolButtonType}
    />
  );
}
