/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import MoreVert from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { TableContainer, Paper, Tooltip } from '@mui/material';
import { formatDate } from '../../mike-shared-helpers/date';

interface IProps {
  rows: Array<any>; // TODO: Add real type iso any
  filter: string;
  loading: boolean;
  columns: any; // TODO: Add real type iso any
  config: any; // TODO: Add real type iso any
  actions?: any; // TODO: Add real type iso any
  onSelect?: (row: any) => void; // TODO: Add real type iso any
  maxHeight?: string;
  onSort?: (param: any) => void; // TODO: Add real type iso any
  sortByField: any; // TODO: Add real type iso any
  isSorting: boolean;
}

interface ISortIconProps {
  id: string;
  label: string;
}

const iconButtonStyle = css`
  border-radius: 50px;
`;

  const actionCellStyle = css`
    width: 40px;
`;
  const actionCellHeadStyle = css`
    width: 40px;
    background: white;
`;

  const headCellStyle = css`
    background: white;
`;
  const cellStyle = css`
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: table-cell;
`;

const GenericMikeTable = (props: IProps) => {
  const  
  {rows,
  onSelect,
  loading,
  columns,
  actions,
  config,
  maxHeight,
  onSort,
  sortByField,
  isSorting} = props;
 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const maxTableHeight = maxHeight ? maxHeight : '500px';

  React.useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortClick = (id: string, colType: string) => {
    if (onSort) {
      onSort({
        field: id,
        dir: sortByField.dir === 'asc' ? 'desc' : 'asc',
        type: colType,
      });
    }
  };

  const SortIcon: React.FC<ISortIconProps> = ({ id, label }) => {
    return (
      <Tooltip title={'Sort for ' + label}>
        <IconButton size="small" css={iconButtonStyle}>
          {id === sortByField.field ? (
            <ArrowUpward
              className={
                sortByField.dir === 'asc'
                  ? 'c-ProjectsTable-SortIcon-Up'
                  : 'c-ProjectsTable-SortIcon-Down'
              }
              fontSize="small"
            />
          ) : (
            <ArrowUpward className={'c-ProjectsTable-SortIcon-Disabled'} fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    );
  };

  const TableHeader: React.FC = () => {
    return (
      <TableHead>
        <TableRow>
          {columns.map((row: any, index: number) => {
            return (
              <TableCell
                css={headCellStyle}
                id={row.id}
                key={row.id}
                onClick={() => {
                  if (row.sortable) {
                    handleSortClick(row.id, row.type);
                  }
                }}
                align={row.align ? row.align : 'left'}
              >
                <b style={{ marginLeft: index === 0 ? 32 : 0 }}>{row.label}</b>
                {row.sortable ? <SortIcon id={row.id} label={row.label} /> : null}
              </TableCell>
            );
          })}
          {actions ? <TableCell css={actionCellHeadStyle} align="center" /> : null}
        </TableRow>
      </TableHead>
    );
  };

  const TableBodySkeleton: React.FC = () => {
    const skeletonRows = [1, 2, 3, 4, 5, 6];
    const skeletonHead = [...Array(columns.length).keys()];
    return (
      <TableBody>
        {skeletonRows.map((ind: any) => (
          <TableRow key={ind}>
            {skeletonHead.map((_head, index) => {
              return (
                <TableCell key={index} align="left">
                  <Skeleton height={38} />
                </TableCell>
              );
            })}
            <TableCell align="left" css={actionCellStyle}>
              <Skeleton height={38} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const Actions = ({ row }) => {
    return (
      <PopupState variant="popover" popupId="action-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <MoreVert {...bindTrigger(popupState)} />
            <Menu
              {...bindMenu(popupState)}
              // getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              MenuListProps={{
                style: {
                  padding: 0,
                },
              }}
            >
              {actions.map((a: any, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      popupState.close();
                      a.callBack(row);
                    }}
                  >
                    {/* {a.icon ? <ActionIcon name={a.icon} /> : ''} */}
                    {a.render ? a.render(row) : a.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
  };

  const MikeTableBody: React.FC = () => {
    const colIds = columns.map((col: any) => {
      return col.id;
    });
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rows
        ).map((row: any, index: number) => (
          <TableRow
            key={index}
            onClick={() => {
              if (onSelect) {
                onSelect(row);
              }
            }}
          >
            {columns.map((col: any, i: number) => {
              const cellValue =
                col.type === 'date' ? formatDate(row[colIds[i]], col.showTime) : row[colIds[i]];
              return (
                <TableCell
                  css={col.className ? col.className : cellStyle}
                  key={i}
                  onClick={() => {
                    if (col.onClick) {
                      col.onClick(row);
                    }
                  }}
                  align={col.align ? col.align : 'left'}
                >
                  <div style={{ marginLeft: i === 0 ? 32 : 0, display: 'flex' }}>
                    {col.render ? col.render(row, cellValue) : cellValue}
                  </div>
                </TableCell>
              );
            })}
            {actions ? (
              <TableCell align="center" css={actionCellStyle}>
                <span style={{ marginRight: 32 }}>
                  <IconButton css={iconButtonStyle}>
                    <Actions row={row} />
                  </IconButton>
                </span>
              </TableCell>
            ) : null}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <Paper>
      <TableContainer style={{ maxHeight: `${maxTableHeight}`, overflow: 'auto' }}>
        <Table stickyHeader aria-label="simple table">
          <TableHeader />
          {loading || isSorting ? <TableBodySkeleton /> : <MikeTableBody />}
        </Table>
      </TableContainer>
      {config.pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
          colSpan={3}
          component="div"
          count={rows && rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{select: {
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default GenericMikeTable;
