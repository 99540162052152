import { useState, useEffect, useMemo, useCallback } from 'react';
import { t } from '../../translations/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { MmgGroup } from '../../shared/groups/group';
import { MmgConnectedMeshSelectList } from '../select-list/mesh-select-list';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { IDrawnDataItem, MESH_FORMATS, EMeshFormats } from '../../models/IWorkspaceData';
import { getIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { MmgSelectMeshExportFormat } from './select-mesh-export-format';
import { submitExportMeshes } from './export-mesh-utils';
import { useIsMounted } from '../../shared/hooks/hooks';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { CircularProgress } from '@mui/material';
import { MmgExportItemsMessage } from '../../workspaces/export/export-items-message';
import { IGlobalState } from '../../store/reducers';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../shared-components/mike-button';

type ExportMeshesProps = {
  workspaceId: string;
  isExportAllowed: boolean;
};

/**
 * @name MmgConnectedExportMeshes
 * @summary Allows exporting selected meshes in the specified format.
 *
 * @param props
 */
export const MmgConnectedExportMeshes = (props: ExportMeshesProps) => {
  const { workspaceId, isExportAllowed } = props;

  const { hiddenWorkspaceMeshes, selectedWorkspaceMeshes } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer,
  );
  const meshes: Array<IWorkspaceEnrichedMesh> = useSelector(WorkspaceMeshSelectors.getSortedEnrichedWorkspaceMeshes);
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceMeshesByIds,
  );
  const { exportableMeshIds, exportableMeshesLoading } = useSelector(
    (state: IGlobalState) => state.WorkspaceExportReducer,
  );

  const [selectedExportFormat, setSelectedExportFormat] = useState(MESH_FORMATS.DOTMESH);
  const [exportInProgress, setExportInProgress] = useState(false);

  const isMounted = useIsMounted();

  const dispatch = useDispatch();

  const anyMeshes = meshes && meshes.length > 0;
  const anyExportableMeshes = exportableMeshIds && exportableMeshIds.length > 0;

  const exportTip = anyMeshes ? t('EXPORT_MESHES_TIP') : t('NO_MESHES_TO_EXPORT');

  // if no permission to export or export is already in progress all items are disabled.
  // Otherwise those that are not exportable are disabled
  const disabledMeshIds = useMemo(
    () => {
      if (!meshes) {
        return [];
      }

      const disabledMeshes =
        !isExportAllowed || exportInProgress || !anyExportableMeshes || exportableMeshesLoading
          ? meshes
          : meshes.filter(({ id }) => exportableMeshIds.indexOf(id) === -1);

      return getIds(disabledMeshes);
    },
    [meshes, isExportAllowed, exportInProgress, exportableMeshIds, anyExportableMeshes, exportableMeshesLoading],
  );

  const isSelectionValid = selectedWorkspaceMeshes && selectedWorkspaceMeshes.length > 0;

  const clearSelectedMeshes = useCallback(
    () => {
      dispatch({ type: 'workspace/meshes/DESELECT_ALL' });
    },
    [dispatch],
  );

  const loadExportableMeshes = useCallback(
    () => {
      dispatch({
        type: 'workspace/export/LOAD_EXPORTABLE_MESHES',
        workspaceId,
      });
    },
    [workspaceId, dispatch],
  );

  const clearExportableMeshes = useCallback(
    () => {
      dispatch({ type: 'workspace/export/RESET_EXPORTABLE_MESHES' });
    },
    [dispatch],
  );

  const onExportFormatChanged = (format: EMeshFormats) => {
    setSelectedExportFormat(format);
  };

  const exportMeshes = (meshIds: Array<string>, exportFormat: EMeshFormats) => {
    setExportInProgress(true);

    return submitExportMeshes(workspaceId, meshIds, exportFormat)
      .then(() => {
        clearSelectedMeshes();
      })
      .finally(() => {
        isMounted() && setExportInProgress(false);
      });
  };

  useEffect(
    () => {
      loadExportableMeshes();

      return () => {
        clearExportableMeshes();
      };
    },
    [loadExportableMeshes, clearExportableMeshes],
  );

  useEffect(
    () => {
      clearSelectedMeshes();

      return () => {
        clearSelectedMeshes();
      };
    },
    [clearSelectedMeshes],
  );

  return (
    <>
      {exportableMeshesLoading && (
        <MmgPanelSubsection>
          <CircularProgress />
        </MmgPanelSubsection>
      )}
      {!exportableMeshesLoading && (
        <>
          <MmgExportItemsMessage message={exportTip} />

          <MmgConnectedMeshSelectList
            meshes={meshes}
            hiddenMeshes={hiddenWorkspaceMeshes}
            drawnMeshes={drawnWorkspaceMeshes}
            selectedMeshes={selectedWorkspaceMeshes}
            disabledMeshes={disabledMeshIds}
            isFailedSelectable={true}
          />

          {/* select mesh format */}
          <MmgGroup groupName={t('EXPORT_MESH_FORMAT_LABEL')} canBeHidden={false}>
            <MmgSelectMeshExportFormat
              selectedFormat={selectedExportFormat}
              onFormatChanged={onExportFormatChanged}
              disabled={!isExportAllowed || !anyExportableMeshes}
            />
          </MmgGroup>
        </>
      )}
      <MikeStickyPanelBottomActions>
        <MikeButton
          color="secondary" variant="contained"
          disabled={!isExportAllowed || !isSelectionValid || exportInProgress}
          onClick={() => exportMeshes(selectedWorkspaceMeshes, selectedExportFormat)}
          active={exportInProgress}
          fullWidth
        >
          {t('MESH_EXPORT', selectedWorkspaceMeshes.length)}
        </MikeButton>
      </MikeStickyPanelBottomActions>
    </>
  );
};
