import { useState, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash-es';
import { CircularProgress } from '@mui/material';

import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import { IContextualOperationDescriptions, IOperationDescription } from '../../../models/IOperationDescriptions';
import { MmgPanelSubsection } from '../../../shared/panels/panel-subsection';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { MmgConnectedMeshOperationGeometryConfiguration } from './mesh-operation-geometry-configuration';
import { usePrevious } from '../../../shared/hooks/hooks';
import { createMeshChildConfigurationFromDescription } from '../mesh-configuration-util';
import { useGroupQueryIdsByTargetIdMemo } from './useGroupQueryIdsByTargetIdMemo';
import { useGroupedAndSortedGeometryConfigurations } from '../useGroupedAndSortedGeometryConfigurations';

type MeshOperationGeometriesConfigurationsProps = {
  workspaceId: string;
  geometries: Array<IWorkspaceEnrichedGeometry>;
  workspaceQueries: Array<IWorkspaceQuery>;
  workspaceVariables: Array<IWorkspaceEnrichedVariable>;
  initialOperationConfigurations?: Array<IOperationConfiguration>;
  contextualOperationDescriptions: IContextualOperationDescriptions;
  onConfigurationsChanged: (operationConfigurations: Array<IOperationConfiguration>) => void;
};

/**
 * @name MmgMeshOperationGeometriesConfigurations
 * @summary Generic component that given a list of geometries and mesh operation descriptions, allows configuring operations for each of them.
 * Will supply the complete list of updated configuration objects via a callback.
 *
 * @param props
 */
export const MmgMeshOperationGeometriesConfigurations = (props: MeshOperationGeometriesConfigurationsProps) => {
  const {
    geometries,
    workspaceQueries,
    workspaceVariables,
    initialOperationConfigurations,
    contextualOperationDescriptions,
    onConfigurationsChanged,
  } = props;

  const previousContextualDescriptions = usePrevious(contextualOperationDescriptions);

  const [childConfigurations, setChildConfigurations] = useState(initialOperationConfigurations); // Object with current configurations for geometries and related queries. This will be sent higher up the component chain and used for submission.

  // group the queries by their target id for easy lookup
  const queryIdsByTarget = useGroupQueryIdsByTargetIdMemo(workspaceQueries);

  const callOnConfigurationsChanged = useCallback(
    (nextChildConfigurations) => {
      if (onConfigurationsChanged) {
        onConfigurationsChanged(nextChildConfigurations);
      }
    },
    [onConfigurationsChanged],
  );

  const onGeometryConfigurationChanged = useCallback(
    (geometryId: string) => (operationConfiguration: IOperationConfiguration) => {
      const nextChildConfigurations = (childConfigurations || []).filter(
        ({ inputIds }) => inputIds.indexOf(geometryId) === -1,
      );

      nextChildConfigurations.push({
        ...operationConfiguration,
      });

      setChildConfigurations(nextChildConfigurations);
      callOnConfigurationsChanged(nextChildConfigurations);
    },
    [childConfigurations, callOnConfigurationsChanged],
  );

  const onQueryConfigurationsChanged = (geometryId: string) => (
    operationConfigurations: Array<IOperationConfiguration>,
  ) => {
    // remove all child configuration for queries related to the geometry
    const queryIds = queryIdsByTarget[geometryId];

    const nextChildConfigurations = queryIds
      ? (childConfigurations || []).filter(
          ({ inputIds }) => inputIds.filter((inputId) => queryIds.indexOf(inputId) === -1).length !== 0,
        )
      : [...childConfigurations];

    nextChildConfigurations.push(...operationConfigurations);

    setChildConfigurations(nextChildConfigurations);
    callOnConfigurationsChanged(nextChildConfigurations);
  };

  useEffect(
    () => {
      if (
        contextualOperationDescriptions &&
        !isEqual(previousContextualDescriptions, contextualOperationDescriptions)
      ) {
        // Create operation configurations for geometries that do not already have a configuration
        // We do not create operation configurations for queries, untill the user choose to include them
        const newConfigurations = geometries.reduce((acc, { id }) => {
          if (
            initialOperationConfigurations &&
            initialOperationConfigurations.findIndex(({ inputIds }) => inputIds.indexOf(id) !== -1) !== -1
          ) {
            return acc;
          }
          const operationDescriptions = contextualOperationDescriptions[id] || {};
          const firstOperationKey = Object.keys(operationDescriptions)[0];

          const config = createMeshChildConfigurationFromDescription(firstOperationKey, [id], operationDescriptions);

          if (!config) {
            return acc;
          }

          return [...acc, config];
        }, []);

        const nextChildConfigurations = [...(initialOperationConfigurations || []), ...newConfigurations];

        setChildConfigurations(nextChildConfigurations);
        callOnConfigurationsChanged(nextChildConfigurations);
      }
    },
    [
      initialOperationConfigurations,
      previousContextualDescriptions,
      contextualOperationDescriptions,
      geometries,
      callOnConfigurationsChanged,
    ],
  );

  const groupedGeometryConfigurations = useGroupedAndSortedGeometryConfigurations(
    childConfigurations,
    geometries,
    workspaceQueries,
  );

  // eslint-disable-next-line no-extra-boolean-cast
  const meshOperationsLoading = !Boolean(contextualOperationDescriptions);

  if (meshOperationsLoading) {
    return (
      <MmgPanelSubsection>
        <CircularProgress />
      </MmgPanelSubsection>
    );
  }

  return (
    groupedGeometryConfigurations && (
      <>
        {Object.keys(groupedGeometryConfigurations).map((geometryId) => {
          const { geometry, geometryConfiguration, queryConfigurations } = groupedGeometryConfigurations[geometryId];

          const geometryOperationDescriptions = (contextualOperationDescriptions
            ? contextualOperationDescriptions[geometryId] || {}
            : {}) as { [operationKey: string]: IOperationDescription };

          return (
            geometryConfiguration && (
              <MmgConnectedMeshOperationGeometryConfiguration
                key={geometryId}
                geometry={geometry}
                workspaceVariables={workspaceVariables}
                initialGeometryConfiguration={geometryConfiguration}
                geometryOperationDescriptions={geometryOperationDescriptions}
                workspaceQueries={workspaceQueries}
                contextualQueryOperations={contextualOperationDescriptions}
                initialQueryOperationsConfigurations={queryConfigurations}
                onGeometryOperationConfigurationChanged={onGeometryConfigurationChanged(geometry.id)}
                onQueryOperationConfigurationsChanged={onQueryConfigurationsChanged(geometry.id)}
              />
            )
          );
        })}
      </>
    )
  );
};
