import { EMapToolActionType } from '../actions/MapToolActionType';

export interface IVariableMapToolState {
  variableEditTargetId: string | null;
}

const initialState = {
  // State related to editing variables.
  variableEditTargetId: null,
};

/**
 * Variable Map Tool Reducer.
 * - returns new states for matched Variable Map tool message actions. They are typically related to drawing, editing, selections, etc.
 *
 * @name VariableMapToolReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IVariableMapToolState = initialState, action) {
  switch (action.type) {
    case EMapToolActionType.VARIABLE_EDIT:
      return {
        ...state,
        variableEditTargetId: action.variableEditTargetId,
      };

    case EMapToolActionType.VARIABLES_UNLOAD: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
}
