import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { t } from '../../../translations/i18n';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import MikeButton from '../../../shared-components/mike-button';

type ConfirmDeleteMeshOperationGeometryDialogProps = {
  childOperation: IOperationConfiguration;
  geometry: IWorkspaceEnrichedGeometry;
  open: boolean;
  onClose: (confirmed: boolean) => void;
  onOperationDelete: (operationId: string) => void;
};

/**
 * Shows a confimation dialog for removing an childoperation from a mesh and updates the mesh if user confirms
 * @param props
 *
 */
export const MmgConfirmDeleteMeshOperationGeometryDialog = (props: ConfirmDeleteMeshOperationGeometryDialogProps) => {
  const { open, onClose, childOperation, onOperationDelete, geometry } = props;

  if (!childOperation) {
    return <></>;
  }

  const handleClose = () => {
    onClose(false);
  };

  const handleOk = () => {
    onChildOperationDelete();
    closeDialog(true);
  };

  const handleCancel = () => {
    closeDialog(false);
  };

  const closeDialog = (confirmed: boolean) => {
    onClose(confirmed);
  };

  const onChildOperationDelete = () => {
    onOperationDelete(childOperation.id);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      id="confirm-delete-childoperation"
      aria-labelledby="delete-childoperation-dialog-title"
      aria-describedby="delete-childoperation-dialog-description"
    >
      <DialogTitle id="delete-childoperation-dialog-title">
        {t('MESH_MEMBER_CONFIRM_DELETE_TITLE')}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="delete-childoperation-dialog-description">
          {t('MESH_MEMBER_CONFIRM_DELETE_TEXT', 1, {
            name: geometry.name,
          })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <MikeButton onClick={handleCancel} variant="outlined" color="secondary">
          {t('CANCEL')}
        </MikeButton>

        <MikeButton onClick={handleOk} variant="contained" color="secondary" autoFocus>
          {t('DELETE')}
        </MikeButton>
      </DialogActions>
    </Dialog>
  );
};
