/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { getSpatialSelectionActions } from '../../../queries/spatial-selections/actions/spatial-selection-layer-actions';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../store/reducers';
import { useParams } from 'react-router-dom';

type SpatialSelectionOverflowMenuProps = {
  selection: IWorkspaceQuery;
};

const MENU_ICON_SIZE = '2.5rem';

export const LayerIconStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;

  height: ${MENU_ICON_SIZE};
  width: ${MENU_ICON_SIZE};
  color: pink;

  &:focus {
    outline: 0;
  }
`;

/**
 * @name MmgSpatialSelectionVisibilityAction
 * @summary Overflow menu for a saved spatial selection (query).
 *
 * @param props
 */
export function MmgSpatialSelectionVisibilityAction(props: SpatialSelectionOverflowMenuProps) {
  const {workspaceId } = useParams()
  const shownSelectionIds = useSelector((state: IGlobalState) => state.WorkspaceQueryReducer.shownSelectionIds);
  const { selection } = props;

  if (!selection) {
    return <></>;
  }

  const isMissing = !selection;

  const layerActions = getSpatialSelectionActions(shownSelectionIds, selection, isMissing, workspaceId);

  const onMenuItemClick = (action: () => void) => {
    action();
  };

  return (
    <>
      {layerActions.map((action) => (
        <MenuItem
          key={action.actionId}
          dense={true}
          onClick={() => onMenuItemClick(action.onAction)}
          disabled={action.disabled}
          disableGutters={true}
          style={{ color: 'pink' }}
        >
          <ListItemIcon css={LayerIconStyle} style={{ color: '#0B4566' }}>
            {action.actionIcon}
          </ListItemIcon>
        </MenuItem>
      ))}
    </>
  );
}
