import { useState, useEffect, useMemo, useCallback } from 'react';
import { t } from '../../translations/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { MmgConnectedVariableSelectList } from '../select-list/variable-select-list';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { getIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { submitExportVariables } from './export-variable-utils';
import { useIsMounted } from '../../shared/hooks/hooks';
import { CircularProgress } from '@mui/material';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgExportItemsMessage } from '../../workspaces/export/export-items-message';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../shared-components/mike-button';
import { IGlobalState } from '../../store/reducers';

type ExportVariablesProps = {
  projectId: string;
  workspaceId: string;
  isExportAllowed: boolean;
};

/**
 * @name MmgConnectedExportVariables
 * @summary Allows exporting selected variables.
 *
 * @param props
 */
export const MmgConnectedExportVariables = (props: ExportVariablesProps) => {
  const { workspaceId, isExportAllowed } = props;

  const { hiddenWorkspaceVariables, selectedWorkspaceVariables } = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer,
  );
  const variables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );
  const drawnWorkspaceVariables: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceVariablesByIds,
  );
  const { exportableVariableIds, exportableVariablesLoading } = useSelector(
    (state: IGlobalState) => state.WorkspaceExportReducer,
  );

  const [exportInProgress, setExportInProgress] = useState(false);

  const isMounted = useIsMounted();

  const dispatch = useDispatch();

  const anyVariables = variables && variables.length > 0;
  const anyExportableVariables = exportableVariableIds && exportableVariableIds.length > 0;

  const exportTip = anyVariables ? t('EXPORT_VARIABLES_TIP') : t('NO_VARIABLES_TO_EXPORT');

  // if no permission to export or export is already in progress all items are disabled.
  // Otherwise those that are not exportable are disabled
  const disabledVariableIds = useMemo(
    () => {
      if (!variables) {
        return [];
      }

      const disabledItems =
        !isExportAllowed || exportInProgress || !anyExportableVariables || exportableVariablesLoading
          ? variables
          : variables.filter(({ id }) => exportableVariableIds.indexOf(id) === -1);

      return getIds(disabledItems);
    },
    [
      variables,
      isExportAllowed,
      exportInProgress,
      exportableVariableIds,
      anyExportableVariables,
      exportableVariablesLoading,
    ],
  );

  const isSelectionValid = selectedWorkspaceVariables && selectedWorkspaceVariables.length > 0;

  const clearSelectedVariables = useCallback(
    () => {
      dispatch({ type: 'workspace/variables/DESELECT_ALL' });
    },
    [dispatch],
  );

  const loadExportableVariables = useCallback(
    () => {
      dispatch({
        type: 'workspace/export/LOAD_EXPORTABLE_VARIABLES',
        workspaceId,
      });
    },
    [workspaceId, dispatch],
  );

  const clearExportableVariables = useCallback(
    () => {
      dispatch({ type: 'workspace/export/RESET_EXPORTABLE_VARIABLES' });
    },
    [dispatch],
  );

  const exportVariables = (variableIds: Array<string>) => {
    setExportInProgress(true);
    return submitExportVariables(workspaceId, variableIds)
      .then(() => {
        clearSelectedVariables();
      })
      .finally(() => {
        isMounted() && setExportInProgress(false);
      });
  };

  useEffect(
    () => {
      loadExportableVariables();

      return () => {
        clearExportableVariables();
      };
    },
    [loadExportableVariables, clearExportableVariables],
  );

  useEffect(
    () => {
      clearSelectedVariables();
      return () => {
        clearSelectedVariables();
      };
    },
    [clearSelectedVariables],
  );

  return (
    <>
      {exportableVariablesLoading && (
        <MmgPanelSubsection>
          <CircularProgress />
        </MmgPanelSubsection>
      )}

      {!exportableVariablesLoading && (
        <>
          <MmgExportItemsMessage message={exportTip} />

          <MmgConnectedVariableSelectList
            variables={variables}
            hiddenVariables={hiddenWorkspaceVariables}
            drawnVariables={drawnWorkspaceVariables}
            selectedVariables={selectedWorkspaceVariables}
            disabledVariables={disabledVariableIds}
            isFailedSelectable={true}
          />
        </>
      )}

      <MikeStickyPanelBottomActions>
        <MikeButton
          color="secondary" variant="contained"
          disabled={!isExportAllowed || !isSelectionValid || exportInProgress}
          onClick={() => exportVariables(selectedWorkspaceVariables)}
          active={exportInProgress}
          fullWidth
        >
          {t('VARIABLE_EXPORT', selectedWorkspaceVariables.length)}
        </MikeButton>
      </MikeStickyPanelBottomActions>
    </>
  );
};
