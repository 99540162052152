import { MikeLayerErrorSelect } from "../../shared-components/mike-layer/layer-error-select";
import MikeTooltip from "../../shared-components/mike-tooltip";

/**
 * @name MmgLayerErrorSelect
 * @summary A wrapper / extensions of MikeLayerErrorSelect, implementing some custom properties.
 *
 * @param props
 */
export const MmgLayerErrorSelect = (props: any) => {
  const { layerDescription, ...otherProps } = props;

  return (
    <MikeLayerErrorSelect
      layerDescription={<MikeTooltip title={layerDescription}>{layerDescription}</MikeTooltip>}
      {...otherProps}
    />
  );
};
