/**
 * Base module that exposes means to connect/disconnect to websockets.
 * It's similar to the Proxy module, but maintains a permanent connection to an endpoint (or until terminated).
 *
 * @module SocketProxy
 * @version 3.0.0
 */
// import Proxy from './Proxy';
import * as SignalR from '@microsoft/signalr';
import { logger } from '../managers/http-utils';
import { ISocketProxy, ISocketConnection } from '../models/ISocketProxy';

import EventEmitter from 'tiny-emitter/instance'

const DEFAULT_EVENTS = {
  CLOSE: 'close',
  ERROR: 'error',
  OPEN: 'open',
  RECONNECTING: 'reconnecting',
  RECONNECTED: 'reconnected',
  RECONNECTING_FAILED: 'reconnecting-failed',
  TOKEN_INVALID: 'token-invalid',
  STREAM_ERROR: 'stream-error',
};

let _openProxyConnections: Array<ISocketConnection> = [];

const trackProxyConnection = (connectionRef: ISocketConnection) => _openProxyConnections.push(connectionRef);
const untrackProxyConnection = (connectionId: string) => {
  _openProxyConnections = _openProxyConnections.filter(({ id }) => id !== connectionId);
};
const getTrackedProxyConnection = (connectionId: string) => _openProxyConnections.find(({ id }) => id === connectionId);

/**
 * Opens a websocket connection and tracks it locally, so it can be reused.
 * If useTrackedConnection is true and a connection is already open, returns that connection instead.
 *
 * @param endpoint
 * @param emit
 * @param useTrackedConnection If true, will reuse existing connection, if any
 * @param serverTimeoutInMilliseconds Timeout for server activity. If the server hasn't sent a message in this interval, the client considers the server disconnected and triggers the onclose event.
 * @param keepAliveIntervalInMilliseconds Determines the interval at which the client sends ping messages.
 */
const openConnection = (
  endpoint: string, 
  useTrackedConnection: boolean,
  serverTimeoutInMilliseconds?: number,
  keepAliveIntervalInMilliseconds?: number,
): ISocketConnection => {
  if (useTrackedConnection) {
    const existingConnection = getTrackedProxyConnection(endpoint);

    if (existingConnection) {
      if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
        console.log('Will use tracked connection', existingConnection.id);
      }
      return existingConnection;
    }
  }

  // Each call of the proxy will create a hub connection (will be started below).
  const HubConnection = new SignalR.HubConnectionBuilder()
    .configureLogging(
      localStorage.getItem('DEBUG_SOCKET_ON') === 'true' ? SignalR.LogLevel.Trace : SignalR.LogLevel.Information,
    )
    .withAutomaticReconnect(getRetryPolicy())
    .withUrl(endpoint)
    .build();

  if (serverTimeoutInMilliseconds) {
    HubConnection.serverTimeoutInMilliseconds = serverTimeoutInMilliseconds;
  }

  if (keepAliveIntervalInMilliseconds) {
    HubConnection.keepAliveIntervalInMilliseconds = keepAliveIntervalInMilliseconds;
  }

  /**
   * Opens the connection, listening to all default events.
   * More listeners can be registered via `onMessageEvent`.
   */

  // todo hevo: should we handle if token is not valid here. If start() fails, it does not reconnect automatically, see https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-3.1

  const connectionStartPromise = HubConnection.start()
    .then(() => {
      EventEmitter.emit(DEFAULT_EVENTS.OPEN, 'Connection open')
    })
    .catch((error: any) => {
      const statusCode = error || {};

      if (error && error.statusCode === 401) {
        EventEmitter.emit(DEFAULT_EVENTS.TOKEN_INVALID);
        logger.warn('Invalid token while connecting to hub', error);
      }

      // log the error if not invalid token
      if (error && error.statusCode !== 401) {
        logger.error('Error while connecting to hub.', {
          message: error,
          response: {
            status: statusCode || -1,
            statusText: error,
          },
          request: {},
        });
      }

      EventEmitter.emit(DEFAULT_EVENTS.ERROR, error);
    });

  const connectionRef = {
    id: endpoint,
    connection: HubConnection,
    connectionStartPromise,
  } as ISocketConnection;

  trackProxyConnection(connectionRef);

  return connectionRef;
};

/**
 * Reconnect with increasing intervals: 500, 1000, 1500, 2000, ..., 50000ms, then fail after 5 min.
 *
 * Why do we need so many? Some operating systems will either try to reconnect while sleeping
 * or while the computer is coming back from sleep, before the network is available.
 * Other than that, 5 minutes is arbitrary.
 *
 */
const getRetryPolicy = (): SignalR.IRetryPolicy => {
  return {
    nextRetryDelayInMilliseconds: (retryContext: SignalR.RetryContext) => {
      const { previousRetryCount, elapsedMilliseconds } = retryContext;

      /**
       * With each reconnect, verify that the token is still valid.
       * If invalid, stop further reconnections.
       */
      /*       if (!Proxy.isJwtTokenValid()) {
        logger.log('Stopped reconnecting to ws because the token is invalid.');
        emit(DEFAULT_EVENTS.TOKEN_INVALID);

        return null; // stop reconnecting
      } */

      if (elapsedMilliseconds < 15000000) {
        // increase how long we will wait with each retry
        return previousRetryCount * 500;
      } else {
        EventEmitter.emit(DEFAULT_EVENTS.RECONNECTING_FAILED);
        logger.log('Stopped reconnecting, ran out of retries.');
        // Stop reconnecting.
        return null;
      }
    },
  };
};


  /**
   * The socket proxy is a generic mechanism to connect to a notification protocol & handle events emitted by it (typically, a webscoket protocal, with potential fallbacks to http long-polling, etc.).
   * The overall flow is represented below:
   *
   * SocketProxy <> SpecializedSocketProxy <> SpecializedSocketManager <> Component
   *      |                   |                           |                   |
   *      |                   |                           |                   |
   *     (1)          decouples from libs       decouples from socket      consumer
   *                   knows url, jwt           not aware of api events   uses manager evts
   *                   knows api events         creates abstract events
   * ╚══════════════════════════════════════════╦═════════════════════════════════════════════╝
   *                                            ║
   *                                 all aware of default events
   *
   * (1) The socket proxy's main role is to decouple between used libraries (at the moment we use SignalR & TinyEmitter. In the past we used raw websockets, etc) and application proxies / managers (`specialized` proxies / managers).
   * This way, switching between communication protocols / libraries can be done without affecting business logic. Implementation details are then decoupled from specialized proxies / managers.
   *
   * The generic socket proxy then is expected to be used by a 'specialized' socket proxy, which in turn should be used by a specialized socket manager. How it works:
   * - A specialized socket proxy uses the generic proxy to establish a connection.
   *  + All connections are tracked in the socket Proxy. Subsequent calls to the specialized proxies / managers will reuse this connection. This way, multiple managers can use the same proxy without receiving/sending redundant events through duplicate websocket connections. Closing a connection will also clear tracking.
   * - Socket managers then listen to known events on that connection, by registering callbacks via `onMessageEvent`. Typically, socket managers will then emit synthetic/abstract events by combining or reducing proxy message events as they see fit. Managers work as a contract between the proxy and consumer component.
   *    + Socket managers can listen to default events, via `on`, in order to introduce i.e. default error handling.
   * - Components use socket managers to listen to either events defined by the manager.
   *    + Components can also listen to default events.
   *
   * Consider an example for the message named `fileUpdated`.
   * @example
   * ```
   * // 1. Create a specialized socket proxy, i.e `fileSocketProxy`
   * // This proxy sets the connection url and optionaly jwt token. It should also contain an exhaustive list of known events.
   * export const fileSocketProxy = (fileId) => {
   *   const API_EVENTS = { UPDATED: 'fileUpdated' }
   *
   *   return {
   *     ...SocketProxy(url + filedId, jwtToken),
   *     events: API_EVENTS
   *   }
   * };
   *
   * // 2. Create a manager, i.e. `fileSocketManager`
   * // Managers should emit synthetic events, to decouple Socket <-> Manager <-> Component
   * export const fileSocketManager = (fileId) => {
   *  const MANAGER_EVENTS = { CREATED_OR_UPDATED: 'fileUpdated' };
   *  const fileProxy = fileSocketProxy(fileId)
   *  const { emit, onMessageEvent, on, off } = fileProxy;
   *
   *  // 2.1. Register message event, and use a synthetic event to emit changes.
   *  onMessageEvent(API_EVENTS.UPDATED, (file) => emit(MANAGER_EVENTS.CREATED_OR_UPDATED, file))
   *
   *  // 2.2. Expose methods and manager events.
   *  return {
   *    on,
   *    off,
   *    managerEvents: MANAGER_EVENTS
   *  };
   * }
   *
   * // 3. Use in component.
   * const ReactComponent = () => {
   *   useEffect(() => {
   *     const { on, managerEvents } = fileSocketManager(fileId);
   *
   *     on(managerEvents.CREATED_OR_UPDATED, (file) => {
   *       // do something with `file`
   *     });
   *   }, []);
   *
   *   return <></>;
   * }
   * ```
   *
   * @param endpoint The url to connect to.
   * @param serverTimeoutInMilliseconds
   * @param keepAliveIntervalInMilliseconds
   */
  const SocketProxy = (
    endpoint: string,
    serverTimeoutInMilliseconds?: number,
    keepAliveIntervalInMilliseconds?: number,
  ): ISocketProxy => {
    // const EventEmitter = new TinyEmitter();

    const { connection, connectionStartPromise } = openConnection(
      endpoint,    
      true,
      serverTimeoutInMilliseconds,
      keepAliveIntervalInMilliseconds,
    );

    /**
     * Subscribe to a hub connection message event.
     * Listens for messages with the provided event name.
     * This is expected to be used strictly by managers, not components.
     *
     * @param eventName
     * @param callback
     */
    const onMessageEvent = (eventName: string, callback: (data: string) => void) => connection.on(eventName, callback);

    /**
     * Unsubscribe to a hub connection message event.
     * Stops listerning to the event with the provided name.
     *
     * @param eventName
     */
    const offMessageEvent = (eventName: string) => connection.off(eventName);

    /**
     * Subscribe to a generic emitted event.
     *
     * This should be used by components, but can also be used by managers for i.e. error handling.
     * They contain all default events, as well as any events the manager registers / is expected to emit.
     *
     * @param eventName
     * @param callback
     */
    const on = (eventName: string, callback: (data: string) => void) => EventEmitter.on(eventName, callback);

    /**
     * Unsubscribe from a generic emitted event.
     *
     * @param eventName
     */
    const off = (eventName: string) => EventEmitter.off(eventName);

    /**
     * Emits an event, given the event name and params.
     *
     * @param eventName
     * @param params
     */
    const emit = (eventName: string, ...params: Array<any>) => EventEmitter.emit(eventName, ...params);

    /**
     * Sends an event to the server through the open connection
     *
     * @param eventName
     * @param params
     */
    const send = (eventName: string, ...params: Array<any>) => {
      if (connection.state !== SignalR.HubConnectionState.Connected) {
        return logger.warn(
          'Attempted to send message before signalr connection was ready. Use `connectionStartPromise` to send messages immediately upon connection.',
        );
      }

      return connection.send(eventName, ...params);
    };

    /**
     * Stream data through signalr (once).
     * Data will be returned via the provided callback once all data is streamed.
     * To get updates, the stream has to be initiated again.
     *
     * @param eventName
     * @param callback This will be invoked with the streamed data.
     * @param errorCallback This will be invoked if streaming fails.
     * @param params
     */
    const stream = (
      eventName: string,
      callback: (data: string) => void,
      errorCallback: (error: string) => void,
      ...params: Array<any>
    ) => {
      if (connection.state !== SignalR.HubConnectionState.Connected) {
        return logger.warn(
          'Attempted to stream message before signalr connection was ready. Use `connectionStartPromise` to stream messages immediately upon connection.',
        );
      }

      let data = '';

      return connection.stream(eventName, ...params).subscribe({
        next: (item) => {
          if (item) {
            // Message parts are considered to be strings, but in some cases they can take falsy values, i.e. `null`. Those values should be skipped.
            data += item;
          }
        },
        complete: () => {
          callback(data);
        },
        error: (error) => {
          EventEmitter.emit(DEFAULT_EVENTS.STREAM_ERROR, { error });
          errorCallback(error);
        },
      });
    };

    /**
     * Stops a connection, clearing all events / listeners on the connection.
     * Also clears tracking of the connection.
     *
     * @param eventsNames
     */
    const close = (eventsNames?: Array<string>) => {
      connection
        .stop()
        .then(() => {
          untrackProxyConnection(endpoint);
          if (eventsNames) {
            [...eventsNames].forEach((e) => EventEmitter.off(e));
          }
        })
        .catch((error) => {
          logger.warn('Failed to stop hub connection!', error);
        });
    };

    /**
     * Handles websocket close events.
     * This will be invoked as a result of either the client or server terminating the connection.
     */
    connection.onclose((error?) => {
      if (error) {
        EventEmitter.emit(DEFAULT_EVENTS.ERROR, error);

        logger.error('Socket connection closed due to error', null, {
          error,
        });
      }

      EventEmitter.emit(DEFAULT_EVENTS.CLOSE, 'Connection closed');
    });

    /**
     * Handles websocket reconnecting events.
     * This will be invoked when the connection starts reconnecting.
     */
    connection.onreconnecting((error?) => {
      EventEmitter.emit(DEFAULT_EVENTS.RECONNECTING, error);

      /**
       * With each reconnect error, verify that the token is still valid.
       * If invalid, close the connection and stop further reconnections.
       */
      /* if (!Proxy.isJwtTokenValid()) {
        logger.log('Stopped reconnecting to ws because the token is invalid.');
        EventEmitter.emit(DEFAULT_EVENTS.TOKEN_INVALID);
        close();
      } */
    });

    /**
     * Handles websocket successfull reconnection events.
     * This will be invoked after 'onreconnecting' is invoked and a connection is successfull made again.
     */
    connection.onreconnected(() => EventEmitter.emit(DEFAULT_EVENTS.RECONNECTED));

    return {
      connection,
      connectionStartPromise,
      on,
      off,
      onMessageEvent,
      offMessageEvent,
      close,
      emit,
      send,
      stream,
      defaultEvents: DEFAULT_EVENTS,
    };
  };


export default SocketProxy;
