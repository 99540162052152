import { IParameterDescription, PARAMETER_VALUE_TYPES } from '../../models/IOperationDescriptions';
import { MmgParameterInputNumber } from './parameter-input-number';
import { IParameterSettings } from '../../models/IParameterSettings';

type ParameterInputPercentProps = {
  parameterKey: string;
  parameterDescription?: IParameterDescription;
  name: string;
  value: number | string;
  label: string;
  infoText?: string;
  helperText?: string;
  error?: boolean;
  customSettings?: IParameterSettings;
  onParameterChanged?: (param: string, val: string | number) => void;
};

/**
 * @name MmgParameterInputPercent
 * @param props
 * @summary A generic element that renders a percent parameter and accepts a callback for when it change.
 */
export const MmgParameterInputPercent = (props: ParameterInputPercentProps) => {
  const { value, parameterDescription, onParameterChanged, ...others } = props;

  // Check for value type; convert value if required; then callback:
  const onInputChanged = (param: string, numericValue: number) => {
    let percentValue: string | number;
    switch (parameterDescription.valueType) {
      case PARAMETER_VALUE_TYPES.STRING:
        percentValue = numericValue ? `${numericValue}%` : '0%';
        break;
      case PARAMETER_VALUE_TYPES.INTEGER:
      case PARAMETER_VALUE_TYPES.INT32:
      case PARAMETER_VALUE_TYPES.DOUBLE:
        percentValue = numericValue;
        break;
      default:
        percentValue = '<valueType error>';
        throw Error(`Unsupported valueType: ${parameterDescription.valueType}`);
    }
    onParameterChanged && onParameterChanged(param, percentValue);
  };

  const numericValue = value ? parseFloat(value.toString()) : 0;

  return (
    <MmgParameterInputNumber
      value={numericValue}
      parameterDescription={parameterDescription}
      onParameterChanged={onInputChanged}
      {...others}
    />
  );
};
