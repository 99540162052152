/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback } from 'react';
import { updateWorkspaceDataItemRepresentation } from '../../store/actions/workspaceDataActions';
import { translateWithPrefix } from '../../translations/utils';
import { Button, Typography } from '@mui/material';
import { t } from '../../translations/i18n';
import { IRepresentation } from '../../MikeVisualizer/lib/models/IRepresentation';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';
import { REPRESENTATION } from '../../MikeVisualizer/lib/MikeVisualizerConstants';

interface IProps {
  elementId: string;
  currentRepresentation?: IRepresentation;
  disabledRepresentations: Array<IRepresentation>;
  isTiled?: boolean;
}

const rootStyle = css`
  display: flex;
  flex-wrap: wrap;
`;

const containerStyle = css`
  padding-right: ${mikeSharedTheme.spacing(0.5)};
  padding-top: ${mikeSharedTheme.spacing(0.5)};
`;

const titleStyle = css`
  padding-bottom: ${mikeSharedTheme.spacing(0.5)};
`;

const activeRepresentationStyle = css`
  &.MuiButtonBase-root {
    background-color: ${mikeSharedTheme.palette.primary.main};
    &:hover {
      background-color: ${mikeSharedTheme.palette.primary.light};
    }
  }
  &.MuiButton-textSecondary {
    color: ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
  }
  &.MuiButton-text {
    height: ${mikeSharedTheme.spacing(4)};
    white-space: nowrap;
  }
`;

const inactiveRepresentationStyle = css`
  &.MuiButtonBase-root {
    background-color: ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
    &:hover {
      background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
    }
  }
  &.MuiButton-textSecondary {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
  &.MuiButton-text {
    height: ${mikeSharedTheme.spacing(4)};
    white-space: nowrap;
  }
`;

/**
 * @name MmgApplyRepresentation
 * @summary Component to configure visual representation of a geometry or a variable
 *
 * @param props
 *
 */
const MmgApplyRepresentation = (props: IProps) => {
  const { elementId, disabledRepresentations = [], currentRepresentation, isTiled } = props;

  const applyRepresentation = useCallback(
    (representation: IRepresentation) => {
      updateWorkspaceDataItemRepresentation(elementId, representation, true, isTiled);
    },
    [elementId, isTiled],
  );

  const activeRepresentation: IRepresentation = currentRepresentation || ({} as IRepresentation);
  const enabledRepresentationsWithNames = Object.keys(REPRESENTATION)
    .filter((key) => disabledRepresentations.indexOf(REPRESENTATION[key]) === -1)
    .map((key) => {
      return {
        ...REPRESENTATION[key],
        name: translateWithPrefix('REPR', key),
      };
    });

  return (
    <>
      <Typography css={titleStyle} variant={'body2'}>
        <b>{t('REPRESENTATION')}</b>
      </Typography>
      <div css={rootStyle}>
        {enabledRepresentationsWithNames.map(({ name, representation, edgeVisibility }) => (
          <div css={containerStyle} key={name}>
            <Button
              css={
                activeRepresentation.representation === representation &&
                activeRepresentation.edgeVisibility === edgeVisibility
                  ? activeRepresentationStyle
                  : inactiveRepresentationStyle
              }
              onClick={() => applyRepresentation({ representation, edgeVisibility })}
            >
              {name}
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};

export default MmgApplyRepresentation;
