import React from 'react';
import { ListItemButton, ListItemButtonProps, ListItemIcon, SxProps, Theme, Tooltip } from '@mui/material';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';

const NAV_ITEM_SIZE = 40; // todo hevo make 0.875rem??

export interface IMikeSidePanelNavigationItemProps extends ListItemButtonProps {
  value: any;
  noHighlight?: boolean;
  icon?: React.ReactElement;
  onItemClicked?: (value: any) => void;
  tooltipTitle?: string;
  tooltipPlacement?: 
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
}

const listItemStyle = (noHighlight: boolean, sx?: SxProps<Theme>) => {
  let style;
  if (noHighlight){
    style = {      
      padding: 0,
      backgroundColor: mikeSharedTheme.palette.background.default,
      color: mikeSharedTheme.palette.primary.main,
      flexGrow: 0,
      flexShrink: 1,
      '&:hover, &.Mui-selected:hover': {
        backgroundColor: MIKE_COLORS.MEDIUMGREY_DEFAULT,
      },
  
      '&:focus, &:active, &.Mui-selected': {
        backgroundColor: mikeSharedTheme.palette.background.default,
      }
    }
  }
  else{
    style = {
      flexGrow: 0,
      flexShrink: 1,
      padding: 0,
      backgroundColor: mikeSharedTheme.palette.background.default,
      color: mikeSharedTheme.palette.primary.main,
      '&:hover, &:focus': {
        backgroundColor: MIKE_COLORS.MEDIUMGREY_DEFAULT
      },
      '&.Mui-selected, &.Mui-selected:focus': {
        backgroundColor: mikeSharedTheme.palette.primary.main,
        color: mikeSharedTheme.palette.primary.contrastText,
      },
      '&.Mui-selected:hover': {
        backgroundColor: MIKE_COLORS.BRANDBLUE_DEFAULT,
        color: mikeSharedTheme.palette.primary.contrastText,
      },    
      '&.Mui-selected svg': {
        fill: mikeSharedTheme.palette.primary.contrastText,
      },
      '&.Mui-selected:hover svg': {
        fill: mikeSharedTheme.palette.primary.contrastText,
      }
    }
  }
  if (sx){
    return {...style, sx}
  }
  else{
    return style
  }   
}

const listItemIconStyle =  {
  color: 'inherit',
  minWidth: NAV_ITEM_SIZE,
  width: NAV_ITEM_SIZE, 
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  margin: 0,
  flexShrink: 0,
  flexGrow: 0,

  '& svg > path': {
    fill: 'currentColor',
  }
}

/**
 * @name MikeSidePanelNavigationItem
 * @summary A single navigation item, meant to be used in the MikeNavigationBar.
 *
 * @param props
 */
export const MikeSidePanelNavigationItem = (props: IMikeSidePanelNavigationItemProps) => {
  const {
    value,
    icon,
    onItemClicked,
    noHighlight,
    onClick: onClickProps,
    sx: sxProp,
    tooltipTitle,
    tooltipPlacement,
    ...rest
  } = props;

  const handleClick = (event) => {
    if (onItemClicked) {
      onItemClicked(value);
    }
    if (onClickProps) {
      onClickProps(event);
    }
  };
  
  if (tooltipTitle){
    return  (
      <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
        <ListItemButton
            
          key={value}
          sx={listItemStyle(noHighlight, sxProp)}
          onClick={handleClick}       
          {...rest}
        >     
          {icon && <ListItemIcon sx={listItemIconStyle}>{icon}</ListItemIcon>}
        </ListItemButton>
      </Tooltip>
    )
  }
  else{    
    return (
      <ListItemButton       
        key={value}
        sx={listItemStyle(noHighlight, sxProp)}
        onClick={handleClick}
        {...rest}
      >     
        {icon && <ListItemIcon sx={listItemIconStyle}>{icon}</ListItemIcon>}        
      </ListItemButton>
    ); 
  }
  
};

MikeSidePanelNavigationItem.muiName = 'MuiListItem';

export default MikeSidePanelNavigationItem;
