/**
 * Exposes projection REST endpoints to managers.
 *
 * @module ProjectionProxy
 * @version 2.0.0
 * @requires Proxy
 */
import { endpoints, paths } from './config';
import Proxy from './Proxy';

/**
 * Gets a list of all projections.
 *
 */
export const getProjections = () =>
  Proxy.http.get(`${endpoints.meshAppService}/${paths.workspaces}/${paths.projections}`);

/**
 * Gets a list of all projections  matching the provided keywords (usually, matching the projection name).
 *
 * @param keyword
 */
export const searchProjections = (keyword: string) =>
  Proxy.http.get(`${endpoints.meshAppService}/${paths.workspaces}/${paths.projections}/?nameFragment=${keyword}`);

/**
 * Get information about a projection by epsg code.
 *
 * @param espgCode
 */
export const getProjectionByEpsg = (espgCode: number) =>
  Proxy.http.get(`${endpoints.meshAppService}/${paths.workspaces}/${paths.projections}/${espgCode}`);
