import { IParameterConditionDescription } from '../../models/IOperationDescriptions';
import NIL_UUID from '../../shared/mesh-uuid';
import { every, has } from 'lodash-es';
/**
 * Evaluates all conditional parameters of the operationConfiguration given and returns weather they are fullfilled or not. Only conditional parameters will be included.
 * This does not include any childOperations of the operationConfiguration.
 * If no conditional parameters exists, null will be returned.
 *
 * @param parameters
 * @param parameterConditionDescriptions
 */
export const getParameterConditions = (
  parameters: { [key: string]: number | boolean | string | object },
  parameterConditionDescriptions: {
    [parameterKey: string]: IParameterConditionDescription;
  },
): {
  [parameterKey: string]: boolean;
} => {
  if (!parameters || !parameterConditionDescriptions) {
    return null;
  }
  const isConditionTrue = (required) => {
    const value = parameters[required];
    return value && value !== NIL_UUID; // todo hevo do we need to handle 0 as true????
  };
  const parameterConditions = Object.keys(parameters).reduce((acc, param) => {
    const condition: IParameterConditionDescription = parameterConditionDescriptions[param];
    // no condition exist for the parameter
    if (!condition) {
      return acc;
    }
    const { requires } = condition;
    // condition exist but does not require anything
    if (!requires || requires.length === 0) {
      return acc;
    }
    return { ...acc, [param]: every(requires, isConditionTrue) };
  }, {});
  return parameterConditions;
};

export const isParameterConditionOk = (
  parameterKey: string,
  parameterConditions: {
    [parameterKey: string]: boolean;
  },
) => {
  if (!parameterConditions || !has(parameterConditions, parameterKey)) {
    return true;
  }
  return parameterConditions[parameterKey];
};

const self = {
  getParameterConditions,
  isParameterConditionOk,
};

export default self;
