import { useSelector } from 'react-redux';

import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceVariableOperationSelectors from '../../store/selectors/WorkspaceVariableOperationSelectors';

import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';

import { MmgVariableList } from '../../variables/list/variable-list';
import { IOperationMetadata } from '../../models/IOperations';
import { MmgOperationList } from '../../operations/operation-list';
import { useParams } from 'react-router-dom';
import { IGlobalState } from '../../store/reducers';

/**
 * @name MmgConnectedVariableListContainer
 * @summary Gets variables in a workspace from state and renders them via MmgVariableList.
 *
 */
export const MmgConnectedVariableListContainer = () => {
  const { workspaceId, projectId } = useParams();
  const hiddenWorkspaceVariables: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer.hiddenWorkspaceVariables,
  );
  const workspaceVariables: Array<IWorkspaceEnrichedVariable> = useSelector((state: IGlobalState) =>
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables(state),
  );
  const drawnWorkspaceVariables: Array<IDrawnDataItem> = useSelector((state: IGlobalState) =>
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceVariablesByIds(state),
  );
  const variableOperationsWithoutOutput: Array<IOperationMetadata> = useSelector((state: IGlobalState) =>
    WorkspaceVariableOperationSelectors.getVariableOperationsWithoutOutput(state),
  );

  const VariableOperationsWithoutOutputList = () => (
    <MmgOperationList projectId={projectId} workspaceId={workspaceId} operations={variableOperationsWithoutOutput} />
  );

  const VariableList = () => (
    <MmgVariableList
      projectId={projectId}
      workspaceId={workspaceId}
      variables={workspaceVariables}
      hiddenVariables={hiddenWorkspaceVariables}
      drawnVariables={drawnWorkspaceVariables}
    />
  );

  return (
    <>
      <VariableOperationsWithoutOutputList />
      <VariableList />
    </>
  );
};
