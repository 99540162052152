import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ILayerProps, MikeLayer } from './layer';

const PROGRESS_SIZE = 24;

export interface ILayerWorkingProps extends ILayerProps {
  rightActions?: React.ReactNode;
  layerName: string;
  layerDescription?: string | React.ReactNode;
  layerId: string;
  isLayerFaded?: boolean;
  onLayerNamePressed?: (layerId: string) => void;  
}

/**
 * @name MikeLayerWorking
 * @summary A layer that is working; waiting for some sort of async / background task to finish.
 *
 * @param props
 */
export function MikeLayerWorking(props: ILayerWorkingProps) {
  const {
    rightActions,
    layerId,
    layerName,
    layerDescription,
    isLayerFaded,
    cssProp,
    ...rest
  } = props;

  return (
    <MikeLayer
      layerId={layerId}
      layerName={layerName}
      layerDescription={layerDescription}
      leftIcon={
        <CircularProgress
          style={{ width: PROGRESS_SIZE, height: PROGRESS_SIZE }}
          color="secondary"
        />
      }
      onLayerNamePressed={props.onLayerNamePressed}
      rightActions={rightActions}
      isLayerFaded={isLayerFaded}
      css={cssProp}
      {...rest}
    />
  );
}
