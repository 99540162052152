/**
 * Exposes mesh move node operation REST endpoints related to managers.
 *
 * @module MeshMoveNodeOperationsProxy
 * @version 2.0.0
 * @requires Proxy
 */
import { endpoints, paths } from './config';
import Proxy from './Proxy';
import { IChangedNode } from '../store/reducers/MeshMapToolReducer';
import { IPatchFeature } from '../models/IQueryCompleted';

const { meshAppService } = endpoints;
const { workspaces, meshes, nodes, data } = paths;

/**
 * Finds nearest mesh node at x, y coordinates, optionally returning neighbouring features.
 *
 * @param workspaceId
 * @param meshId
 * @param xCoord
 * @param yCoord
 * @param previousNodesToUpdate
 * @param [includeNeighbouringFeatures]
 */
export const findNearestAt = (
  workspaceId: string,
  meshId: string,
  xCoord: number,
  yCoord: number,
  previousNodesToUpdate: Array<IChangedNode>,
  includeNeighbouringFeatures = true,
) => {
  const payload = {
    x: xCoord,
    y: yCoord,
    returnNeighbouringFeatures: includeNeighbouringFeatures,
    changedNodes: previousNodesToUpdate,
  };
  return Proxy.http.post(
    `${meshAppService}/${workspaces}/${workspaceId}/${meshes}/${meshId}/${nodes}/find-nearest-at`,
    payload,
  );
};

/**
 * Move nodes to new x, y.
 *
 * @param workspaceId
 * @param meshId
 * @param nodesToMove
 */
export const moveNodes = (workspaceId: string, meshId: string, nodesToMove: { [key: string]: [number, number] }) => {
  return Proxy.http.patch(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}/${meshId}/${nodes}`, nodesToMove);
};

/**
 * Updates mesh nodes.
 *
 * @param workspaceId
 * @param meshId
 * @param nodesToChange
 */
export const updateNodes = (workspaceId: string, meshId: string, nodesToChange: Array<IPatchFeature>) => {
  return Proxy.http.patch(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}/${meshId}/${data}`, nodesToChange);
};
