/**
 * Exposes comments REST endpoints to managers.
 *
 * @module WorkspaceCommentsProxy
 * @deprecated See the same file in ./src
 *
 * @version 1.0.0
 * @requires Proxy
 *
 *
 * todo hevo Also include this in v2 proxies!
 *
 */

import { endpoints, paths } from './config';
import Proxy from './Proxy';
import { IWorkspaceComment } from '../models/IComments';

const { workspaces, comments, comment } = paths;

/**
 * Get comments for a given workspace.
 *
 * @param workspaceId
 */
export const getComments = (workspaceId: string) =>
  Proxy.http.get(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/${comments}/list`);

/**
 * Create a new comment in the workspace
 *
 * @param workspaceId
 * @param commentPayload
 */
export const createComment = (workspaceId: string, commentPayload: IWorkspaceComment) =>
  Proxy.http.post(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/${comments}`, commentPayload);

/**
 * Delete a comment
 *
 * @param workspaceId
 * @param commentId
 */
export const deleteComment = (workspaceId: string, commentId: string) =>
  Proxy.http.delete(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/${comment}/${commentId}`);
