import { useSelector } from 'react-redux';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceGeometryOperationSelectors from '../../store/selectors/WorkspaceGeometryOperationSelectors';

import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';

import { MmgGeometryList } from '../../geometries/list/geometry-list';
import { IOperationMetadata } from '../../models/IOperations';
import { MmgOperationList } from '../../operations/operation-list';
import { useParams } from 'react-router-dom';
import { IGlobalState } from '../../store/reducers';

/**
 * @name MmgConnectedGeometryListContainer
 * @summary Gets geometries in a workspace from state and renders them via MmgGeometryList.
 *
 */
export const MmgConnectedGeometryListContainer = () => {
  const { workspaceId, projectId } = useParams();
  const hiddenWorkspaceGeometries: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries,
  );
  const workspaceGeometries: Array<IWorkspaceEnrichedGeometry> = useSelector((state: IGlobalState) =>
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries(state),
  );
  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector((state: IGlobalState) =>
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds(state),
  );
  const geometryOperationsWithoutOutput: Array<IOperationMetadata> = useSelector((state: IGlobalState) =>
    WorkspaceGeometryOperationSelectors.getGeometryOperationsWithoutOutput(state),
  );

  const GeometryOperationsWithoutOutputList = () => (
    <MmgOperationList projectId={projectId} workspaceId={workspaceId} operations={geometryOperationsWithoutOutput} />
  );

  const GeometryList = () => (
    <MmgGeometryList
      projectId={projectId}
      workspaceId={workspaceId}
      geometries={workspaceGeometries}
      hiddenGeometries={hiddenWorkspaceGeometries}
      drawnGeometries={drawnWorkspaceGeometries}
    />
  );

  return (
    <>
      <GeometryOperationsWithoutOutputList />
      <GeometryList />
    </>
  );
};
