/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from 'react';
import { t } from '../../../translations/i18n';
import { useSelector } from 'react-redux';
import { store } from '../../../store/store';
import RandomPoint from '../../../icons/RandomPoint.svg?react';
import Tooltip from '@mui/material/Tooltip';
import { ViewerToolsButtonStyle } from './viewer-tools-styles';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';
import { CONFIRM_PANEL_IDS } from '../../../shared/confirm-containers/confirm-constants';
import { pointDrawVectorLayerStyle, pointSelectVectorLayerStyle } from '../../../variables/create/variable-draw-styles';
import { OLDRAW_CLICKTOLERANCE } from '../../../viewer/viewer-constants';
import { EWorkspaceActionType } from '../../../store/actions/WorkspaceActionType';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';
import { IGlobalState } from '../../../store/reducers';

const { enable2DPointDrawingV2, disable2DPointDrawing } = MikeVisualizer;

/**
 * @name MmgConnectedViewerTool2DVariableDraw
 * @summary Allows enabling/disabling 2d variable drawing.
 *
 */
export function MmgConnectedViewerTool2DVariableDraw() {
  const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  const { pointDrawingEnabled } = useSelector((state: IGlobalState) => state.MapToolReducer);

  const epsgCode = useMemo(
    () => {
      return workspace ? workspace.epsgCode : undefined;
    },
    [workspace],
  );

  /**
   * Opens variable draw panel.
   */
  const openVariableDrawPanel = () => {
    store.dispatch({ type: EMapToolActionType.VARIABLE_CREATE });
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.VARIABLE_DRAW_NEW,
    });
  };

  useEffect(
    () => {
      if (pointDrawingEnabled) {
        enable2DPointDrawingV2({
          vectorLayerStyle: pointDrawVectorLayerStyle,
          selectInteractionStyle: pointSelectVectorLayerStyle,
          olDrawOptions: { clickTolerance: OLDRAW_CLICKTOLERANCE },
        });
      }
      return () => disable2DPointDrawing();
    },
    [pointDrawingEnabled],
  );

  if (!epsgCode) {
    return <></>;
  }

  return (
    <>
      <Tooltip title={t('TOOL_DRAW_VARIABLE')} placement="right">
        <button css={ViewerToolsButtonStyle} onClick={openVariableDrawPanel}>
          <RandomPoint />
        </button>
      </Tooltip>
    </>
  );
}
