/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { Button, Typography } from '@mui/material';
import { ROUTES } from '../../app/routes';
import { Link } from 'react-router-dom';
import { MmgMetas } from '../../shared/metas/metas';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';
import { IGlobalState } from '../../store/reducers';

const ErrorPageSectionStyle = css`
  max-width: 500px;
  padding-top: ${mikeSharedTheme.spacing(2)};
`;

const ErrorPageTitleStyle = css`
  padding-bottom: ${mikeSharedTheme.spacing(3)};
`;

const ErrorPageRootStyle = css`
  $(ErrorPageSectionStyle);
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;  
`;

const ErrorPagePreStyle = css`
  white-space: pre-wrap;
  color: gray;
  font-size: 0.8rem;
`;

const ErrorPageHelpStyle = css`
  $(ErrorPageSectionStyle);
  margin-top: ${mikeSharedTheme.spacing(4)};
  padding-top: ${mikeSharedTheme.spacing(2)};
  border-top: 1px solid ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT}
`;

const ErrorPageTextColorStyle = css`
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  font-size: 0.875rem;
`;

/**
 * @name MmgConnectedErrorPage
 * @summary Connected error page
 *
 */
export const MmgConnectedErrorPage = () => {
  const { userLoggedIn, userSessionExpired } = useSelector((state: IGlobalState) => state.UserReducer);
  const { applicationError, applicationErrorTitle, applicationErrorDesc, applicationErrorDebugText } = useSelector(
    (state: IGlobalState) => state.AppReducer,
  );

  const [helpExpanded, setHelpExpanded] = useState(false);

  const expandHelp = (e) => {
    e.preventDefault();
    setHelpExpanded(true);
  };

  return (
    <>
      <MmgMetas metaTitle={t('GENERIC_ERROR_DESC')} />
      <div css={ErrorPageRootStyle}>
        <Typography css={ErrorPageTitleStyle} variant="h1">
          {applicationErrorTitle ? applicationErrorTitle : t('GENERIC_ERROR_TITLE')}
        </Typography>
        <Typography variant="body2">{applicationErrorDesc ? applicationErrorDesc : t('GENERIC_ERROR_DESC')}</Typography>
        <div css={ErrorPageSectionStyle}>
          {userLoggedIn && !userSessionExpired ? (
            <Link css={ErrorPageTextColorStyle} to={ROUTES.home.path}>
              {t('GO_TO', 1, { thing: t('HOME_PAGE') })}
            </Link>
          ) : (
            <Link css={ErrorPageTextColorStyle} to={ROUTES.home.path}>
              {t('GO_TO', 1, { thing: t('LOGIN_PAGE') })}
            </Link>
          )}
        </div>
        <div css={ErrorPageHelpStyle}>
          {!helpExpanded ? (
            <Button css={ErrorPageTextColorStyle} variant="text" onClick={expandHelp}>
              <Typography variant="body2">{t('GENERIC_ERROR_HELP_TITLE')}</Typography>
            </Button>
          ) : (
            <>
              <Typography variant="body2">{t('GENERIC_ERROR_HELP_DESC')}</Typography>
              <div css={ErrorPagePreStyle}>{applicationError}</div>
              <div css={ErrorPagePreStyle}>{applicationErrorDebugText}</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
