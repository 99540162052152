import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { store } from '../../store';

/**
 * Custom hook that holds the current tab for a tab based panel (such as geometry details etc)
 * If useBrowserHistory is specified, the default tab will be based on location hash and defaultTabValue will be used as fallback.
 *
 * @param defaultTabValue The value corresponding to the default tab.
 * @param useBrowserHistory if true the browser history will be replaced whenever the tab changes
 * @param workspaceId
 * @param layerId
 */

export const useCurrentTab = (
  defaultTabValue: any, //Since mui allows 'any' as value for tabs, we do the same
  workspaceId: string,
  layerId: string,
  useBrowserHistory = true,
): {
  currentTab: any;
  onTabChange: (_event, newValue: any, workspaceId: string, layerId: string) => void;
} => {
  const location = useLocation();
  const navigate = useNavigate();

  const getDefaultTab = () => {
    if (useBrowserHistory && location.hash) {
      return location.hash.slice(1) || defaultTabValue;
    }
    return defaultTabValue;
  };

  const [currentTab, setCurrentTab] = useState(getDefaultTab());

  const onTabChange = (_event, newValue: any) => {
    setCurrentTab(newValue);
    if (useBrowserHistory) {
      navigate(location.pathname + '#' + newValue, {replace: true});
    }

    if (workspaceId) {
      if (newValue.indexOf('mesh') > -1) {
        store.dispatch({
          type: 'panel/SET_MESH_ACTIVE_PANEL',
          meshPanelActive: newValue,
          workspaceId,
          layerId,
        });
      } else if (newValue.indexOf('geometry') > -1) {
        store.dispatch({
          type: 'panel/SET_GEOMETRY_ACTIVE_PANEL',
          geometryPanelActive: newValue,
          workspaceId,
          layerId,
        });
      }
    }
  };

  return {
    currentTab,
    onTabChange,
  };
};
