import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import GeometryOperationsManager from '../../managers/GeometryOperationsManager';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { t } from '../../translations/i18n';
import { MmgOperationDescription } from '../../operations/operation-description';
import { MmgOperationSelector } from '../../operations/operation-selector';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgOperationConfiguration } from '../../operations/operation-configuration';
import { IOperationDescription, IOperationDescriptionsKVP } from '../../models/IOperationDescriptions';
import { IOperationConfiguration } from '../../models/IOperations';
import { MmgConnectedGeometryAttributeQueryConfiguration } from './geometry-attribute-query-configuration';
import { IQueryDefinitionApi } from '../../models/IQueryDefinitions';
import QueryUtils from '../../meshes/mesh-query/mesh-query-utils';
import { isAnyLayerWorking } from '../../shared/layers/layer-utils';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgGroup } from '../../shared/groups/group';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { useIsMounted } from '../../shared/hooks/hooks';
import { useMount } from 'react-use';
import { EOperationActionType } from '../../store/actions/OperationActionType';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../shared-components/mike-button';
import { IGlobalState } from '../../store/reducers';

type GeometryOperationsSectionProps = {
  projectId: string;
  workspaceId: string;
  geometryIds: Array<string>;
  onOperationSubmitted?: (success: boolean) => void;
};

/**
 * @name MmgGeometryOperationsSection
 * @param props
 * @summary Allows applying operations from the api to a geometry.
 *
 */
export const MmgConnectedGeometryOperationsSection = (props: GeometryOperationsSectionProps) => {
  const { projectId, workspaceId, geometryIds, onOperationSubmitted } = props;

  const getGeometrySelectorInstance = WorkspaceGeometrySelectors.makeGetGeometries();
  const geometriesDrawnData: Array<IDrawnDataItem> = useSelector((state: IGlobalState) =>
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds(state),
  );
  const geometries: Array<IWorkspaceEnrichedGeometry> = useSelector((state: IGlobalState) =>
    getGeometrySelectorInstance(state, { geometryIds }),
  );

  const [operationDescriptions, setOperationDescriptions] = React.useState({} as IOperationDescriptionsKVP);
  const [operationsLoading, setOperationsLoading] = React.useState(false);
  const [operationsLoadingFailed, setOperationsLoadingFailed] = React.useState(false);
  const [operationInProgress, setOperationInProgress] = React.useState(false);
  const [selectedOperationKey, setSelectedOperationKey] = React.useState(null as string);
  const [operationConfiguration, setOperationConfiguration] = useState(null as IOperationConfiguration);
  const [queryDefinition, setQueryDefinition] = React.useState({} as IQueryDefinitionApi);
  const isMounted = useIsMounted();
  const { goBackToReferrer } = useNavigateBack();

  const onPanelExit = () => {
    goBackToReferrer();
  };

  /**
   * Callback for mesh-query to call whenever something changes in the query.
   * The query can be submitted later.
   *
   * @param newQueryDefinition
   */
  const onQueryDefinitionChanged = (newQueryDefinition: IQueryDefinitionApi) => {
    setQueryDefinition(newQueryDefinition);
  };

  const onOperationChanged = (event) => {
    const operationKey = event.target.value as string;
    setSelectedOperationKey(operationKey);
    setOperationConfiguration(null);
  };

  const onConfigurationChanged = (newConfiguration: IOperationConfiguration) => {
    setOperationConfiguration(newConfiguration);
  };

  const submitOperation = (event) => {
    event.preventDefault();
    if (!selectedOperationKey) {
      return;
    }   
    executeGeometriesOperation();
  };

  const executeGeometriesOperation = () => {
    setOperationInProgress(true);
    // Always ask for elements
    // todo hevo might consider if we sometimes wants points, e.g. for for nodemarkers
    // todo hevo should this be passed in? Or should it be set from the outside afterwards or should it be set to NOT_ET or set by the api alltogether?????????
    let success = false;
    GeometryOperationsManager.executeGeometriesOperation(workspaceId, {
      ...operationConfiguration,
      inputIds: geometryIds,
      queryDefinition,
    })
      .then((operationMetadata) => {
        success = true;
        // todo hevo Might not need to show the toast
        store.dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('GEOMETRY_OPERATION_SUBMITTED_SUCCESSFULLY'),
          },
        });
        store.dispatch({
          type: EOperationActionType.CREATE_OR_UPDATE,
          operationMetadata,
        });
        store.dispatch({ type: 'workspace/geometries/DESELECT_ALL' });
      })
      .catch((error) => {
        success = false;
        const toast = {
          text: t('GEOMETRY_OPERATION_SUBMITTED_FAILED'),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      })
      .finally(() => {
        isMounted() && setOperationInProgress(false);
        onOperationSubmitted && onOperationSubmitted(success);
      });
  };

  // load available operations when mounting
  useMount(() => {
    if (!workspaceId) {
      return;
    }
    setOperationsLoading(true);
    setOperationsLoadingFailed(false);
    GeometryOperationsManager.getGeometryOperationDescriptions(workspaceId, geometryIds)
      .then((operations) => {
        isMounted() && setOperationDescriptions(operations);
      })
      .catch((error) => {
        isMounted() && setOperationsLoadingFailed(true);
        console.error('Failed to get geometry operations');
        throw error;
      })
      .finally(() => {
        isMounted() && setOperationsLoading(false);
      });
  });

  const supportedOperationKeys = useMemo(
    () => {
      const operationKeys = Object.keys(operationDescriptions);     
      return operationKeys;    
    },
    [operationDescriptions],
  );

  // Check conditions:
  const isMissing = !geometries || geometries.length === 0;
  const isAnyWorking = isAnyLayerWorking(geometries, geometriesDrawnData);
  const selectedOperationDescription = (operationDescriptions || {})[selectedOperationKey] as IOperationDescription;
  const isOperationsAvailable = supportedOperationKeys && supportedOperationKeys.length > 0;
  const { supportsQuery: selecteOperationSupportQuery } = selectedOperationDescription || {};
  // If query is not supported, the QueryDefinition is by default complete.
  const isQueryComplete = !selecteOperationSupportQuery || QueryUtils.isQueryDefinitionComplete(queryDefinition);
  const isOperationValid = selectedOperationKey && geometryIds && geometryIds.length;
  const submitDisabled = !isOperationValid || !isQueryComplete || isAnyWorking || operationInProgress;
  if (isMissing && !isAnyWorking) {
    return <></>;
  }

  return (
    <>
      {(isAnyWorking || operationInProgress) && (
        <MmgPanelSubsection>
          <p>{t('CONFIGURE_AFTER_DATA_PROCESSED')}</p>
        </MmgPanelSubsection>
      )}

      <MmgGroup groupName={t('GEOMETRY_OPERATION_GROUP')} canBeHidden={false}>
        {isOperationsAvailable && (
          <>
            <MmgPanelSubsection>
              <MmgOperationSelector
                label={t('SELECT_GEOMETRY_OPERATION')}
                placeholder={t('SELECT_GEOMETRY_OPERATION').toLowerCase()}
                operationKeys={supportedOperationKeys}
                operationsLoading={operationsLoading}
                operationsLoadingFailed={operationsLoadingFailed}
                onOperationChanged={onOperationChanged}
                selectedOperationKey={selectedOperationKey}
              />

              <MmgOperationDescription operationKey={selectedOperationKey} />

              {/* Todo currently api only supports querydefintion if only one geometry is included. We take the first. */}
              {selecteOperationSupportQuery && (
                <MmgConnectedGeometryAttributeQueryConfiguration
                  projectId={projectId}
                  workspaceId={workspaceId}
                  geometryId={geometryIds[0]}
                  onQueryDefinitionChanged={onQueryDefinitionChanged}
                />
              )}
            
              <MmgOperationConfiguration
                operationDescription={selectedOperationDescription}
                onConfigurationChanged={onConfigurationChanged}
              />
           
            </MmgPanelSubsection>

            <MikeStickyPanelBottomActions>
              <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
                {t('CANCEL')}
              </MikeButton>
              <MikeButton color="secondary" variant="contained" disabled={submitDisabled} onClick={submitOperation} active={operationInProgress}>
                {t('SUBMIT_GEOMETRY_OPERATION')}
              </MikeButton>
            </MikeStickyPanelBottomActions>
          </>
        )}
      </MmgGroup>
    </>
  );
};
