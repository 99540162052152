import { IWorkspace } from '../../models/IWorkspaces';

export enum EProjectActionType {
  GET_PROJECT_PATH = 'project/GET_PATH',
}

export const getProjectPath = (projectId: string, workspace?: IWorkspace) => ({
  type: EProjectActionType.GET_PROJECT_PATH,
  data: {projectId,
  workspace}
});
