import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import { t } from '../../../translations/i18n';
import { ILayerAction } from '../../../shared/layers/actions/layer-default-actions';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { store } from '../../../store';
import { EWorkspaceQueryActionType } from '../../../store/actions/WorkspaceQueryActionType';

/**
 *
 * @param shownSelectionIds
 * @param selection
 * @param disabled
 */
export const getSpatialSelectionActions = (
  shownSelectionIds: Array<string>,
  selection: IWorkspaceQuery,
  disabled: boolean,
  workspaceId: string
) => {
  if (!selection) {
    return [];
  }

  const selectionId = selection.id;

  const layerActions = [];

  const toggleVisibilityAction = getToggleSelectionResultLayerAction(shownSelectionIds, selectionId, disabled, workspaceId);
  if (toggleVisibilityAction) {
    layerActions.push(toggleVisibilityAction);
  }

  return layerActions;
};

export const getToggleSelectionResultLayerAction = (
  shownSelectionIds: Array<string>,
  selectionId: string,
  disabled: boolean,
  workspaceId: string
) => {
  if (!selectionId) {
    return null;
  }

  let toggleAction: ILayerAction;

  if (!shownSelectionIds.includes(selectionId)) {
    toggleAction = getShowLayerAction(() =>
      store.dispatch({ type: EWorkspaceQueryActionType.SELECTION_RESULT_TOGGLE_VISIBILITY, data: {workspaceId, selectionId } }),
    );
  } else {
    toggleAction = getHideLayerAction(() =>
      store.dispatch({ type: EWorkspaceQueryActionType.SELECTION_RESULT_TOGGLE_VISIBILITY, data: {workspaceId, selectionId } }),
    );
  }

  toggleAction.disabled = disabled;

  return toggleAction;
};

const getShowLayerAction = (onAction: () => void) => {
  return {
    actionId: 'SHOW',
    actionDisplayName: t('SHOW_SELECTION_RESULT'),
    actionIcon: <VisibilityOffIcon />,
    onAction,
  };
};

const getHideLayerAction = (onAction: () => void) => {
  return {
    actionId: 'HIDE',
    actionDisplayName: t('HIDE_SELECTION_RESULT'),
    actionIcon: <VisibilityIcon />,
    onAction,
  };
};

const self = {
  getSpatialSelectionActions,
  getToggleSelectionResultLayerAction,
};

export default self;
