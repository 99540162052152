import { NavigateFunction, useNavigate, useParams } from 'react-router';
import { t } from '../../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../../app/routes';
import Delete from '../../../icons/Delete.svg?react';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import { ILayerAction } from '../../../shared/layers/actions/layer-default-actions';

/**
 * @name MmgConnectedMeshMultipleActions
 * @summary Mesh multi-selection actions.
 *
 */
export function MmgConnectedMeshMultipleActions() {
  const { projectId, workspaceId } = useParams();
  const navigate = useNavigate();
  const layerActions = getLayerActions(projectId, workspaceId, navigate);
  return <MikeLayerActionMenu layerId="multi-meshes" layerActions={layerActions} />;
}

const getLayerActions = (projectId: string, workspaceId: string, navigate: NavigateFunction): Array<ILayerAction> => {
  const layerActions = [];
  const deleteLayerAction = getDeleteMeshesAction(projectId, workspaceId, navigate, false);
  layerActions.push(deleteLayerAction);
  return layerActions;
};

// eslint-disable-next-line react-refresh/only-export-components
export const getDeleteMeshesAction = (projectId: string, workspaceId: string, navigate: NavigateFunction, disabled?: boolean): ILayerAction => {
  const onDelete = () => {
    return navigate(getRouteByPath(ROUTES.meshesDeletePanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
  };
  const deleteLayersAction = {
    actionId: 'MESHES_DELETE',
    actionDisplayName: t('MESHES_DELETE'),
    actionIcon: <Delete />,
    onAction: () => onDelete(),
    disabled,
  };
  return deleteLayersAction;
};
