import { useState, useEffect, useCallback } from 'react';

import MeshInterpolationOperationsManager from '../../managers/MeshInterpolationOperationsManager';
import { IOperationDescription, IContextualOperationDescriptions } from '../../models/IOperationDescriptions';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { usePrevious } from '../../shared/hooks/hooks';
import { isEqual } from 'lodash-es';
import WorkspaceDataItemUtils from '../../store/selectors/WorkspaceDataItemUtils';

/**
 * Custom hook that loads the contextual interpolation operation descriptions for the variables given
 * @param workspaceId
 * @param variables
 */
export const useAsyncLoadContextualInterpolationDescriptions = (
  workspaceId: string,
  variables: Array<IWorkspaceEnrichedVariable>,
): {
  interpolationDescriptions: {
    [operationType: string]: {
      basicOperationDescription: IOperationDescription;
      childContextualOperationDescriptions: IContextualOperationDescriptions;
    };
  };
  interpolationDescriptionsLoading: boolean;
  interpolationDescriptionsLoadingFailed: boolean;
  retry: () => void;
} => {
  const [interpolationDescriptionsLoading, setInterpolationDescriptionsLoading] = useState(false);

  const [interpolationDescriptionsLoadingFailed, setInterpolationDescriptionsLoadingFailed] = useState(false);

  const [interpolationDescriptions, setInterpolationDescriptions] = useState<{
    [operationType: string]: {
      basicOperationDescription: IOperationDescription;
      childContextualOperationDescriptions: IContextualOperationDescriptions;
    };
  }>(null);

  const prevVariables = usePrevious(variables);
  const prevWorkspaceId = usePrevious(workspaceId);

  /**
   * Gets the contextual interpolation operation descriptions for the variables given
   */
  const getMeshInterpolationOperationsDescriptions = useCallback(
    (force: boolean) => {
      if (
        !workspaceId ||
        !variables ||
        !variables.length ||
        (!force && (prevWorkspaceId === workspaceId && isEqual(prevVariables, variables)))
      ) {
        return Promise.resolve();
      }

      setInterpolationDescriptionsLoading(true);
      setInterpolationDescriptionsLoadingFailed(false);

      const variableIds = WorkspaceDataItemUtils.getIds(variables);
      return MeshInterpolationOperationsManager.getContextualMeshInterpolationOperationDescriptions(
        workspaceId,
        variableIds,
      )
        .then((descriptions) => {
          setInterpolationDescriptions(descriptions);
        })
        .catch((error) => {
          setInterpolationDescriptionsLoadingFailed(true);
          console.error('Failed to get contextual interpolate operation descriptions');
          throw error;
        })
        .finally(() => {
          setInterpolationDescriptionsLoading(false);
        });
    },
    [workspaceId, variables, prevWorkspaceId, prevVariables],
  );

  const retry = () => {
    getMeshInterpolationOperationsDescriptions(true);
  };

  // load  interpolation descriptions
  useEffect(
    () => {
      getMeshInterpolationOperationsDescriptions(false);
    },
    [getMeshInterpolationOperationsDescriptions],
  );

  return {
    interpolationDescriptions,
    interpolationDescriptionsLoading,
    interpolationDescriptionsLoadingFailed,
    retry,
  };
};
