/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { Typography } from '@mui/material';
import BreadcrumbUtils from '../../app/topbar/breadcrumb-utils';
import Left from '../../icons/Left.svg?react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { getRouteByPath, ROUTES } from '../../app/routes';
import MmgWorkspaceEdit from './workspace-edit';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgMetas } from '../../shared/metas/metas';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';
import { IGlobalState } from '../../store/reducers';
import { store } from '../../store';

const WorkspaceEditPageLinkContainerStyle = css`
  padding: ${mikeSharedTheme.spacing(2)};
`;

const WorkspaceEditPageLinkStyle = css`
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
`;

const WorkspaceEditPageContainerStyle = css`
  width: 30vw;
  padding-top: ${mikeSharedTheme.spacing(3)};
`;

/**
 * @name MmgConnectedWorkspaceEditPage
 * @summary Connected workspace page - allows editing workspace datasets
 *
 */
const MmgConnectedWorkspaceEditPage = () => {
  const match = useMatch({
    path: '/project/:id/workspace/:workspaceId'
  });
  const currentProjectId = match && match.params.id;
  const currentWorkspaceId = match && match.params.workspaceId;

  const navigate = useNavigate();

  const { project } = useSelector((state: IGlobalState) => state.ProjectReducer);
  const { workspace } = useSelector((state: IGlobalState) => state.WorkspaceReducer);

  // on change of projectId and workspaceId load workspace metadata and update breadcrumps
  useEffect(
    () => {
      if (currentProjectId && currentWorkspaceId) {        
        store.dispatch({ type: WorkspaceActionType.WORKSPACE_LOAD_METADATA, data: { pId: currentProjectId, wsId: currentWorkspaceId, navigate } });
      }
    },
    [currentProjectId, currentWorkspaceId, navigate],
  );

  const setBreadcrumb = () => {
    if (!workspace) {
      return;
    }

    const crumbs = [
      BreadcrumbUtils.getHomeCrumb(),
      BreadcrumbUtils.getProjectCrumb(currentProjectId, getProjectName()),
      BreadcrumbUtils.getWorkspaceCrumb(currentWorkspaceId, currentProjectId, getWorkspaceName()),
    ];

    store.dispatch({
      type: 'app/breadcrumbs/SET',
      breadcrumbs: crumbs,
    });
  };

  const getProjectName = () => {
    if (project && project.id === currentProjectId) {
      return project.name;
    }

    // we do not know the name yet
    return '';
  };

  const getWorkspaceName = () => {
    if (workspace && workspace.id === currentWorkspaceId) {
      return workspace.name;
    }
    // we do not know the name yet
    return '';
  };

  const onWorkspaceUpdated = () => {
    setBreadcrumb();
  };

  const onWorkspaceDeleted = (projectId) => {
    const workspaceListRoutePath = getRouteByPath(ROUTES.workspaceList.path, {
      projectId,
    });
    navigate(workspaceListRoutePath);
  };

  return (
    <>
      <MmgMetas
        metaTitle={workspace ? t('EDITING', 1, { thing: workspace.name }) : t('WORKSPACE')}
        metaDescription={workspace ? workspace.description : ''}
      />
      <div css={WorkspaceEditPageLinkContainerStyle}>
        <Link css={WorkspaceEditPageLinkStyle} to={'/project/' + currentProjectId + '/workspace-list'}>
          <Left />
          <Typography> {t('BACK_TO_WORKSPACES')}</Typography>
        </Link>
      </div>
      <div css={WorkspaceEditPageContainerStyle}>
        <MmgPanelSubsection>
          <Typography variant={'h3'}>
            {t('EDITING', 1, { thing: t('WORKSPACE') })}
            <b>{workspace ? ' ' + workspace.name : ''}</b>
          </Typography>
        </MmgPanelSubsection>
        <MmgWorkspaceEdit onWorkspaceUpdated={onWorkspaceUpdated} onWorkspaceDeleted={onWorkspaceDeleted} />
      </div>
    </>
  );
};
export default MmgConnectedWorkspaceEditPage;
