import { useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { MmgWorkspaceNoItemsMessage } from '../../workspaces/details/workspace-no-items-message';
import { MmgSidepanelHeader } from '../../shared/sidepanel/sidepanel-header';
import { MmgConnectedGroup } from '../../shared/groups/connected-group';
import { MmgConnectedGroupVisibilityAction } from '../../shared/groups/actions/group-visibility-action';
import { MmgConnectedMeshListContainer } from '../../meshes/list/mesh-list-container';
import { MmgConnectedGeometryListContainer } from '../../geometries/list/geometry-list-container';
import { MmgConnectedVariableListContainer } from '../../variables/list/variable-list-container';
import { MmgConnectedGeometryMultipleActions } from '../../geometries/multiple/actions/geometry-multiple-actions';
import { MmgConnectedVariableMultipleActions } from '../../variables/multiple/actions/variable-multiple-actions';
import { MmgConnectedMeshMultipleActions } from '../../meshes/multiple/actions/mesh-multiple-actions';
import { IWorkspaceGeometry } from '../../models/IGeometries';
import { IWorkspaceMesh } from '../../models/IMeshes';
import { IWorkspaceVariable } from '../../models/IVariables';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { useShowAllButUserHidden } from '../../shared/hooks/useShowAllButUserHidden';
import { GROUP_IDS } from '../../shared/groups/group-constants';
import { IProject } from '../../models/IProject';
import { EWorkspaceQueryActionType } from '../../store/actions/WorkspaceQueryActionType';
import { useNavigate, useParams } from 'react-router-dom';
import MmgProjectContentExplorer from '../../project-datasets/project-content-explorer';
import { IGetDataset } from '../../models/IGetDataset';
import { getProjectAndParent, replaceProjectContent } from '../../store/actions/projectContent';
import { IItemToReplace } from '../../store/reducers/ProjectContentReducer';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import MikeButton from '../../shared-components/mike-button';
import { IGlobalState } from '../../store/reducers';

// export const useMikeIconButtonStyles = makeStyles((_theme) => createStyles({ root: { padding: '4px' } }));

/**
 * @name MmgConnectedWorkspaceDetailsPanel
 * @summary Panel for displaying mesh geometries, variables, meshes.
 * Also responsible for showing/hidding user items.
 *
 */
export const MmgConnectedWorkspaceDetailsPanel = () => {
  const navigate = useNavigate();
  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);  
  const workspaceLoading: boolean = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspaceLoading);
  const workspaceGeometriesLoading: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer.workspaceGeometriesLoading,
  );
  const workspaceVariablesLoading: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer.workspaceVariablesLoading,
  );
  const workspaceMeshesLoading: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer.workspaceMeshesLoading,
  );
  const workspaceGeometries: Array<IWorkspaceGeometry> = useSelector((state: IGlobalState) =>
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries(state),
  );
  const workspaceVariables: Array<IWorkspaceVariable> = useSelector((state: IGlobalState) =>
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables(state),
  );
  const workspaceMeshes: Array<IWorkspaceMesh> = useSelector((state: IGlobalState) =>
    WorkspaceMeshSelectors.getSortedEnrichedWorkspaceMeshes(state),
  );

  const itemTypeToReplace: IItemToReplace | null = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.itemToReplace,
  );

  const { workspaceId, projectId } = useParams();

  const anyGeometries = workspaceGeometries && workspaceGeometries.length !== 0;
  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  // on change of selected projectId load project and parent
  useEffect(
    () => {
      projectId && store.dispatch(getProjectAndParent(projectId, navigate));
    },
    [navigate, projectId],
  );

  /**
   * Callback for when the new mesh buttons is pressed.
   * Deselects all geoms & vars & navigates to select members panel.
   */
  const onNewMeshButtonPressed = () => {
    navigate(getRouteByPath(ROUTES.meshCreateConfigurePanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
  };

  useShowAllButUserHidden();

  useEffect(() => {
    store.dispatch({
      type: EWorkspaceQueryActionType.OTHER_SELECTIONS_RESHOW,
    });
    store.dispatch({
      type: EMapToolActionType.ALLOW_EXTRACT_FEATURES,
    });
    store.dispatch({
      type: 'maptools/ALLOW/GEOMETRY_DRAWING',
    });
    store.dispatch({
      type: EMapToolActionType.ALLOW_VARIABLE_DRAWING,
      variablePropertyEditingAllowed: false,
    });

    // on unmount we reset to default tools
    return () => {
      store.dispatch({
        type: EMapToolActionType.RESET_ALLOWED_TOOLS,
      });
    };
  }, []);

  /**
   * Callback for when a dataset is added via project explorer.
   *
   * @param selectedItems
   */
  const onReplaceDatasetSource = useCallback(
    (selectedItems: Array<IGetDataset>) => {
      if (selectedItems && selectedItems.length > 0) {
        const selectedItem = selectedItems[0];
        store.dispatch(replaceProjectContent(selectedItem.id, workspaceId, itemTypeToReplace));
      }
    },
    [workspaceId, itemTypeToReplace],
  );

  // If there are no meshes, geometries and variables in a workspace, show a help message.
  if (
    projectId &&
    !workspaceLoading &&
    !(workspaceGeometriesLoading && workspaceVariablesLoading && workspaceMeshesLoading) &&
    !workspaceGeometries.length &&
    !workspaceVariables.length &&
    !workspaceMeshes.length
  ) {
    return (
      <>
        <MmgWorkspaceNoItemsMessage projectId={projectId} workspaceId={workspaceId} />
      </>
    );
  }

  return (
    <>
      <MmgProjectContentExplorer onSelectedDatasetsChanged={onReplaceDatasetSource} />
      <MmgSidepanelHeader>
        {canUpdateWorkspace && (
          <MikeButton variant="outlined" color="secondary" onClick={onNewMeshButtonPressed} disabled={!anyGeometries}>
            <span css="mesh-button-text">{t('NEW', 1, { thing: t('MESH') })}</span>
          </MikeButton>
        )}
      </MmgSidepanelHeader>

      <MmgConnectedGroup
        groupId={GROUP_IDS.WORKSPACE_DETAILS_MESH}
        groupTools={
          <>
            <MmgConnectedGroupVisibilityAction actionElementCategory={ELEMENT_CATEGORIES.MESH} />
            {canUpdateWorkspace && <MmgConnectedMeshMultipleActions />}
          </>
        }
      >
        <MmgConnectedMeshListContainer />
      </MmgConnectedGroup>

      <MmgConnectedGroup
        groupId={GROUP_IDS.WORKSPACE_DETAILS_GEOMETRY}
        groupTools={
          <>
            <MmgConnectedGroupVisibilityAction actionElementCategory={ELEMENT_CATEGORIES.GEOMETRY} />
            {canUpdateWorkspace && <MmgConnectedGeometryMultipleActions />}
          </>
        }
      >
        <MmgConnectedGeometryListContainer />
      </MmgConnectedGroup>

      <MmgConnectedGroup
        groupId={GROUP_IDS.WORKSPACE_DETAILS_VARIABLE}
        groupTools={
          <>
            <MmgConnectedGroupVisibilityAction actionElementCategory={ELEMENT_CATEGORIES.VARIABLE} />
            {canUpdateWorkspace && <MmgConnectedVariableMultipleActions />}
          </>
        }
      >
        <MmgConnectedVariableListContainer />
      </MmgConnectedGroup>
    </>
  );
};
