/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';

export const outerPaddingStyle = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
  padding-right: ${mikeSharedTheme.spacing(2)};
  padding-bottom: ${mikeSharedTheme.spacing(1)};
  padding-top: ${mikeSharedTheme.spacing(1)};
`;

export const geometryDrawingPaddingStyle = css`
  padding-left: ${mikeSharedTheme.spacing(1)};
  padding-right: ${mikeSharedTheme.spacing(1)};
  padding-bottom: ${mikeSharedTheme.spacing(1)};
  padding-top: ${mikeSharedTheme.spacing(0)};
`;

export const descriptionWithHelpStyle = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
  padding-right: ${mikeSharedTheme.spacing(2)};
  padding-bottom: ${mikeSharedTheme.spacing(1)};
  padding-top: ${mikeSharedTheme.spacing(1)};
  display: flex;
  align-items: center;
`;

export const instructionWithHelpStyle = css`
  padding-bottom: ${mikeSharedTheme.spacing(1)};
  padding-top: ${mikeSharedTheme.spacing(1)};
  display: flex;
  align-items: center;
`;

export const fieldsStyle = css`
  padding-top: ${mikeSharedTheme.spacing(1)};
  padding-left: ${mikeSharedTheme.spacing(2)};
  padding-right: ${mikeSharedTheme.spacing(2)};
  padding-bottom ${mikeSharedTheme.spacing(1)};
  justify-content: space-between;
`;

export const ContentBottomContainerStyle = css`
  background: ${mikeSharedTheme.palette.background.paper};
  position: sticky;
  bottom: 0;
  z-index: 2;
`;

export const PanelBottomDescriptionStyle = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
  padding-right: ${mikeSharedTheme.spacing(2)};
  padding-top: ${mikeSharedTheme.spacing(3)};
`;

export const ContentBottomDescriptionStyle = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
  padding-right: ${mikeSharedTheme.spacing(2)};
  padding-top: ${mikeSharedTheme.spacing(1)};
`;

export const PanelBottomContainerStyle = css`
  background: ${mikeSharedTheme.palette.background.paper};
  position: sticky;
  bottom: 0;
  z-index: 2;
  border-top: 2px solid ${MIKE_COLORS.DARKGREY_DEFAULT};
  margin-top: -1px;
`;

export const PanelBottomActionsStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(2)};
`;

export const buttonStyle = css`
  z-index: 2;
`;
