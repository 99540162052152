import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import WorkspaceGeometriesManager from '../../managers//WorkspaceGeometriesManager';

/**
 * Submits exporting of selected geometries.
 * @param workspaceId
 * @param geometryIds
 */
export const submitExportGeometries = (workspaceId: string, geometryIds: Array<string>): Promise<void> => {
  return (
    WorkspaceGeometriesManager.exportGeometries(workspaceId, geometryIds)
      /*  .then(() => {
      const toast = {
        text: t('EXPORT_GEOMETRIES_SUCCESSFUL'),
      };

      store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
    }) */
      .catch((error) => {
        const toast = {
          text: t('EXPORT_GEOMETRIES_FAILED'),
          operationId: error.operationId,
        };

        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      })
  );
};

const self = {
  submitExportGeometries,
};

export default self;
