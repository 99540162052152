import { omit } from 'lodash-es';
import { IWorkspace, IWorkspaceDataset, IWorkspaceOverview } from '../../models/IWorkspaces';
import { createSelector } from 'reselect';
import { IWorkspaceQuery } from '../../models/IQueries';
import { IUserSettings } from '../../models/IUserSettings';
import { IGlobalState } from '../reducers';

const getHiddenWorkspaceGeometries = (state: IGlobalState): Array<string> =>
  state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries;
const getHiddenWorkspaceVariables = (state: IGlobalState): Array<string> =>
  state.WorkspaceVariableReducer.hiddenWorkspaceVariables;
const getHiddenWorkspaceMeshes = (state: IGlobalState): Array<string> =>
  state.WorkspaceMeshReducer.hiddenWorkspaceMeshes;

const getUserHiddenWorkspaceGeometries = (state: IGlobalState): Array<string> =>
  state.WorkspaceGeometryReducer.userHiddenGeometryIds;
const getUserHiddenWorkspaceVariables = (state: IGlobalState): Array<string> =>
  state.WorkspaceVariableReducer.userHiddenVariableIds;
const getUserHiddenWorkspaceMeshes = (state: IGlobalState): Array<string> =>
  state.WorkspaceMeshReducer.userHiddenMeshIds;

export const getWorkspaceQueries = (state: IGlobalState): Array<IWorkspaceQuery> => {
  return state.WorkspaceQueryReducer.workspaceQueries;
};

/**
 * returns the workspace, including datsets, meshes etc in case they have been loaded. Also see @getWorkspaceOverview
 * @param state
 */
export const getWorkspace = (state: IGlobalState): IWorkspace => {
  return state.WorkspaceReducer.workspace;
};

export const getUserSettings = (state: IGlobalState): IUserSettings => {
  return state.WorkspaceDataReducer.settings;
};

export const getSavedUserSettings = (state: IGlobalState): IUserSettings => {
  return state.WorkspaceDataReducer.savedUserSettings;
};

export const getUserSettingsInitialized = (state: IGlobalState): boolean => {
  return state.WorkspaceDataReducer.settingsInitialized;
};

/**
 * Selector to return user hidden elements
 */
export const getUserHiddenElements = createSelector(
  [getUserHiddenWorkspaceGeometries, getUserHiddenWorkspaceVariables, getUserHiddenWorkspaceMeshes],
  (userHiddenGeometryIds, userHiddenMeshIds, userHiddenVariableIds) => {
    const userHiddenElementIds = [...userHiddenGeometryIds, ...userHiddenMeshIds, ...userHiddenVariableIds];

    return userHiddenElementIds;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

/**
 * Selector to return hidden elements
 */
export const getHiddenElements = createSelector(
  [getHiddenWorkspaceGeometries, getHiddenWorkspaceVariables, getHiddenWorkspaceMeshes],
  (hiddenGeometryIds, hiddenMeshIds, hiddenVariableIds) => {
    const hiddenElementIds = [...hiddenGeometryIds, ...hiddenMeshIds, ...hiddenVariableIds];

    return hiddenElementIds;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

/**
 * returns the workspaceOveriew - omits datasets, meshes, geometries, variables, queries and operations if loaded.
 * Also see @getWorkspace
 */
export const getWorkspaceOverview = createSelector([getWorkspace], (workspace: IWorkspace) => {
  if (!workspace) {
    return workspace;
  }

  const workspaceOverview: IWorkspaceOverview = omit(workspace, [
    'datasets',
    'geometries',
    'meshes',
    'variables',
    'queries',
    'operations',
  ]);

  return workspaceOverview;
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

// createSelector<any, IWorkspace>

/**
 *  Selector to return the datasets of the workspace
 */
export const getWorkspaceDatasets = createSelector([getWorkspace], (workspace: IWorkspace) => {
  // todo hevo currently we take these from the workspace. Might want to store them seperately in the WorkspaceReducer
  return workspace ? workspace.datasets : undefined;
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

// createSelector<any, Array<IWorkspaceDataItem>>

/**
 *  Selector to return true if the workspace contains any datasets, otherwise false
 */
export const hasAnyWorkspaceDatasets = createSelector([getWorkspaceDatasets], (datasets: Array<IWorkspaceDataset>) => {
  return datasets && datasets.length > 0;
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

// createSelector<any, boolean>

/**
 * Detemines wheater the entire workspace has been loaded or not.
 * This does only consider the workspace 'structure', not if any data has been loaded or not!
 *
 * Will be true if the entire workspace is loaded, false if only the workspace overview is loaded or if not loaded at all
 * @param state
 */
export const isWorkspaceFullyLoaded = (state: IGlobalState): boolean => {
  const workspace = getWorkspace(state);

  if (!workspace) {
    return false;
  }

  const workspaceProperties = Object.keys(workspace);

  if (
    workspaceProperties.indexOf('datasets') === -1 ||
    workspaceProperties.indexOf('geometries') === -1 ||
    workspaceProperties.indexOf('meshes') === -1 ||
    workspaceProperties.indexOf('variables') === -1
  ) {
    return false;
  }

  return true;
};

const self = {
  getWorkspace,
  getWorkspaceOverview,
  getWorkspaceDatasets,
  isWorkspaceFullyLoaded,
  getUserHiddenElements,
};

export default self;
