import { useState } from 'react';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import { IOperationConfiguration, IOperationMetadata } from '../../../models/IOperations';
import { IWorkspaceEnrichedMesh } from '../../../models/IMeshes';
import { getInterpolationActions } from './mesh-interpolation-actions';
import { MmgConfirmDeleteMeshInterpolationDialog } from '../mesh-interpolation-delete-dialog';
import { useNavigate } from 'react-router-dom';

type MeshInterpolationOverflowMenuProps = {
  projectId: string;
  workspaceId: string;
  interpolationConfiguration: IOperationConfiguration;
  mesh: IWorkspaceEnrichedMesh;
  onInterpolationDeleted?: (operationId) => void;
  onInterpolationExecuted?: (result: Promise<IOperationMetadata>) => void;
};


/**
 * @name MmgMeshInterpolationOverflowMenu
 * @summary Overflow menu for a single mesh interpolation operation.
 *
 * @param props
 */
export function MmgMeshInterpolationOverflowMenu(props: MeshInterpolationOverflowMenuProps) {
  const {
    projectId,
    workspaceId,
    interpolationConfiguration,
    mesh,
    onInterpolationDeleted: onInterpolationDeletedProp,
    onInterpolationExecuted,
  } = props;

  const navigate = useNavigate();

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onInterpolationDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const onInterpolationDeleted = (operationId) => {
    if (onInterpolationDeletedProp) {
      onInterpolationDeletedProp(operationId);
    }
  };

  if (!interpolationConfiguration) {
    return <></>;
  }
  const isMissing = !interpolationConfiguration || !mesh;

  const layerActions = getInterpolationActions(
    projectId,
    workspaceId,
    mesh,
    interpolationConfiguration,
    navigate,
    onInterpolationDelete,
    onInterpolationExecuted,
  );

  return (
    <>
      <MikeLayerActionMenu layerId={interpolationConfiguration.id} layerActions={layerActions} disabled={isMissing} />

      <MmgConfirmDeleteMeshInterpolationDialog
        open={deleteConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        meshId={mesh.id}
        interpolation={interpolationConfiguration}
        onClose={() => setDeleteConfirmOpen(false)}
        onInterpolationDeleted={onInterpolationDeleted}
      />
    </>
  );
}
