import { IOperationMetadata } from '../../models/IOperations';
import { EOperationActionType } from '../actions/OperationActionType';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';

const initialState: IWorkspaceOperationState = {
  workspaceOperations: [],
};

export interface IWorkspaceOperationState {
  workspaceOperations: Array<IOperationMetadata>;
}


/**
 * Workspace Operation Reducer.
 * - returns new states for matched workspace operation actions.
 *
 * @name WorkspaceOperationReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IWorkspaceOperationState = initialState, action) {
  switch (action.type) {
    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case EOperationActionType.LOAD_SUCCESS: {
      return {
        ...state,
        workspaceOperations: action.workspaceOperations,
      };
    }

    case EOperationActionType.CREATE_OR_UPDATE: {
      const operationMetadata = action.operationMetadata;

      if (!operationMetadata) {
        return state;
      }

      const existingIndex = state.workspaceOperations.findIndex((item) => item.id === operationMetadata.id);

      // Add new operation if not found
      if (existingIndex === -1) {
        return {
          ...state,
          workspaceOperations: [...state.workspaceOperations, operationMetadata],
        };
      }

      const existingOperation = state.workspaceOperations[existingIndex];

      if (operationMetadata.state === 'Cancelled') {
        const wOp = state.workspaceOperations.filter((op) => {
          return op.id !== operationMetadata.id;
        });
        return {
          ...state,
          workspaceOperations: wOp,
        };
      }
      // Replace only if it was updated more recently
      if (existingOperation.updated <= operationMetadata.updated) {
        return {
          ...state,
          workspaceOperations: [
            ...state.workspaceOperations.slice(0, existingIndex),
            operationMetadata,
            ...state.workspaceOperations.slice(existingIndex + 1),
          ],
        };
      }

      return state;
    }

    case EOperationActionType.DELETE: {
      const workspaceOperations = state.workspaceOperations.filter((item) => item.id !== action.operationId);

      return {
        ...state,
        workspaceOperations,
      };
    }

    case EOperationActionType.ITEMS_DELETED: {
      const idsToDelete = action.operationIds;

      if (!idsToDelete || idsToDelete.length === 0) {
        return state;
      }

      const workspaceOperations = state.workspaceOperations.filter((o) => idsToDelete.indexOf(o.id) === -1);

      return {
        ...state,
        workspaceOperations,
      };
    }
    default:
      return state;
  }
}
