/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { IParameterDescription } from '../../models/IOperationDescriptions';
import { TextField } from '@mui/material';
import { InputFieldInfoTooltipCss, InputFieldInfoWrapperCss, MmgParameterInfoWrapper } from './parameter-info-wrapper';
import { MmgParameterInputSelect } from './parameter-input-select';
import { IParameterSettings } from '../../models/IParameterSettings';
import { useField } from 'formik';

type ParameterInputStringProps = {
  param: string;
  parameterDescription?: IParameterDescription;
  name: string;
  value: string;
  label: string;
  placeholder: string;
  infoText?: string;
  helperText?: string;
  error?: boolean;
  customSettings?: IParameterSettings;
  onParameterChanged?: (param: string, val: string) => void;
};

/**
 * @name MmgParameterInputString
 * @param props
 * @summary A generic element that renders a string parameter and accepts a callback for when it change.
 */
export const MmgParameterInputString = (props: ParameterInputStringProps) => {
  const {
    param,
    name,
    value,
    label,
    helperText,
    infoText,
    placeholder,
    parameterDescription,
    customSettings,
    error,
    onParameterChanged,
  } = props;

  const onInputChanged = (event: React.ChangeEvent) => {
    field.onChange(event);

    const eventTarget = event.target as HTMLInputElement;

    if (onParameterChanged) {
      onParameterChanged(eventTarget.id, eventTarget.value);
    }
  };

  const [field] = useField({
    name: name || param,
    value: value as string,
  });

  const selectParameterDescription: IParameterDescription = useMemo(
    () => {
      const { allowedValues } = parameterDescription || {};

      // api returns sometimes an empty list of allowed values.
      // This does not mean that no values are allowed, but rather that any value is allowed.
      if (!allowedValues || allowedValues.length === 0) {
        return null;
      }

      const values = allowedValues.reduce((acc, cur) => {
        return { ...acc, [cur]: cur };
      }, {});

      return { ...parameterDescription, values };
    },
    [parameterDescription],
  );

  if (!param) {
    return <></>;
  }

  const showAsInputSelect = Boolean(selectParameterDescription);

  if (showAsInputSelect) {
    // we use the strings as is without any translation
    const getValueDisplayName = (val: number | boolean | string | object) => {
      return val as string;
    };

    return (
      <MmgParameterInputSelect
        parameterKey={param}
        name={name}
        value={value}
        parameterDescription={selectParameterDescription}
        label={label}
        placeholder={placeholder}
        infoText={infoText}
        getValueDisplayName={getValueDisplayName}
        customSettings={customSettings}
        error={error}
        helperText={helperText}
        onParameterChanged={onParameterChanged}
      />
    );
  }

  return (
    <MmgParameterInfoWrapper infoText={infoText} cssProp={InputFieldInfoWrapperCss} tooltipCss={InputFieldInfoTooltipCss}>
      <TextField
        id={param}
        name={name}
        label={label}
        value={value || ''}
        onChange={onInputChanged}
        onBlur={field.onBlur}
        autoComplete="off"
        fullWidth={true}
        error={error}
        helperText={helperText}
      />
    </MmgParameterInfoWrapper>
  );
};
