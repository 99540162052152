/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Tabs } from '@mui/material';
import { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import { PANEL_HEADER_SIZE } from '../panels/panel-styles';
import MIKE_COLORS from '../styles/mike-colors';

const TABS_OFFSET_TOP = PANEL_HEADER_SIZE; // Tabs for now are always used with a full-height panel header. The resulting (not defined) size of the header is 72px. If this becomes more complex in the future, it is worth considering a sticky height property or finding other css solutions to achieve the tab stickiness :)

const tabsStyle = css`
  background: white;
  position: sticky;
  top: ${TABS_OFFSET_TOP};
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
`;

export interface MmgTabsProps extends MuiTabsProps {
  onChange: any; //(event: any, newValue: any) => void; // Hevo: Hack to avoid Typescript compile errors of wrong type. See issue: https://github.com/mui-org/material-ui/issues/17454
}

export const MmgTabs = (props: MmgTabsProps) => {
  const { children, ...other } = props;

  return (
    <Tabs css={tabsStyle} variant="scrollable" scrollButtons={true} aria-label="panel-tabs" {...other}>
      {children}
    </Tabs>
  );
};
