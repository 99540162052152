/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { t } from '../../../translations/i18n';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import {
  ViewerToolsButtonStyle,
  ViewerToolsWithSubmenuMenuStyle,
} from '../../../workspaces/viewer/tools/viewer-tools-styles';
import ViewerToolUtils from '../../../workspaces/viewer/tools/viewer-tool-utils';
import Crosshair from '../../../icons/Crosshair.svg?react';
import ArrowRight from '../../../icons/ArrowRight.svg?react';
import ArrowLeft from '../../../icons/ArrowLeft.svg?react';
import ArrowDown from '../../../icons/ArrowDown.svg?react';
import ArrowUp from '../../../icons/ArrowUp.svg?react';
import Down from '../../../icons/Down.svg?react';
import Up from '../../../icons/Up.svg?react';
import Right from '../../../icons/Right.svg?react';
import Left from '../../../icons/Left.svg?react';
import { VIEWER_MODES } from '../../../MikeVisualizer/lib/IMikeVisualizerModels';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';
import { SUBMENUS } from './viewer-tool-constants';
import { IGlobalState } from '../../../store/reducers';

const { toggleSubmenu } = ViewerToolUtils;
const { disableAllDrawingTools, panHorizontally, panVertically, tiltVertically, tiltHorizontally } = MikeVisualizer;

/**
 * @name MmgConnectedViewerTool3DMove
 * @summary Allows enabling/disabling 3d moving tools
 *
 */
export function MmgConnectedViewerTool3DMove() {
  const openSubmenu = useSelector((state: IGlobalState) => state.MapToolReducer.openSubmenu);
  const viewerMode = useSelector((state: IGlobalState) => state.ViewerModeReducer.viewerMode);

  /**
   * Toggles the measure tools submenu.
   */
  const toggleMove3DSubmenu = () => {
    toggleSubmenu(SUBMENUS.MOVE_3D);
  };

  useEffect(() => {
    disableAllDrawingTools();
  }, []);

  return (
    <>
      {viewerMode === VIEWER_MODES.THREE_D && (
        <Tooltip title={'Tilt or pan 3D map'} placement="right">
          <button css={ViewerToolsButtonStyle} onClick={toggleMove3DSubmenu}>           
            <Crosshair />            
          </button>
        </Tooltip>
      )}

      {openSubmenu === SUBMENUS.MOVE_3D && (
        <ul css={ViewerToolsWithSubmenuMenuStyle} style={{ top: '300px' }}>
          <li>
            <button
              css={ViewerToolsButtonStyle}
              onClick={() => {
                panHorizontally(10);
              }}
              title={t('PAN_HORIZONTALY')}
            >
              <ArrowRight />
            </button>
            <button
              css={ViewerToolsButtonStyle}
              onClick={() => {
                panHorizontally(-10);
              }}
              title={t('PAN_HORIZONTALY')}
            >
              <ArrowLeft />
            </button>
            <button
              css={ViewerToolsButtonStyle}
              onClick={() => {
                panVertically(10);
              }}
              title={t('PAN_VERTICALLY')}
            >
              <ArrowDown />
            </button>

            <button
              css={ViewerToolsButtonStyle}
              onClick={() => {
                panVertically(-10);
              }}
              title={t('PAN_VERTICALLY')}
            >
              <ArrowUp />
            </button>
            <button
              css={ViewerToolsButtonStyle}
              onClick={() => {
                tiltVertically(-10);
              }}
              title={t('TILT_VERTICALLY')}
            >
              <Down />
            </button>
            <button
              css={ViewerToolsButtonStyle}
              onClick={() => {
                tiltVertically(10);
              }}
              title={t('TILT_VERTICALLY')}
            >
              <Up />
            </button>
            <button
              css={ViewerToolsButtonStyle}
              onClick={() => {
                tiltHorizontally(-10);
              }}
              title={t('TILT_HORIZONTALY')}
            >
              <Right />
            </button>

            <button
              css={ViewerToolsButtonStyle}
              onClick={() => {
                tiltHorizontally(10);
              }}
              title={t('TILT_HORIZONTALY')}
            >
              <Left />
            </button>
          </li>
        </ul>
      )}
    </>
  );
}
