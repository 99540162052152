import { EOperationStates, IItemOperationState } from './IOperations';
import { EElementCategories } from '../shared/panels/mesh-panel-constants';

export enum EMeshItemTypes {
  MESH = 'Mesh',
  MESH_SETTINGS = 'MeshSettings',
  MESH_CONFLICTING = 'MeshConflicting',
}

export const MESH_ITEM_TYPES = {
  MESH: EMeshItemTypes.MESH,
  MESH_SETTINGS: EMeshItemTypes.MESH_SETTINGS,
  MESH_CONFLICTING: EMeshItemTypes.MESH_CONFLICTING,
};

export interface IWorkspaceMesh {
  id?: string;
  dataId?: string; // should be replaced by vtkFileId
  // vtkFileId?: string; // will be included ny api soon
  projectId?: string;
  dataset?: string;
  name?: string;
  itemType?: EMeshItemTypes;
  created?: string;
  updated?: string;
  isTiled?: boolean;
  // queries?: Array<???> // send by api?
}

export interface IWorkspaceEnrichedMesh extends IWorkspaceMesh {
  message?: string;
  state?: EOperationStates;
  conflictingState?: IItemOperationState;
  category?: EElementCategories;
}
