import { useMemo } from 'react';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IContextualOperationDescriptions } from '../../../models/IOperationDescriptions';
import ModelUtils from '../../../managers/model-utils';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { MmgMeshOperationQuerySummary } from './mesh-operation-query-summary';

type MeshOperationQueriesSummariesProps = {
  geometryQueries: Array<IWorkspaceQuery>;
  workspaceVariables: Array<IWorkspaceEnrichedVariable>;
  operationConfigurations?: Array<IOperationConfiguration>;
  contextualOperationDescriptions?: IContextualOperationDescriptions;
};

/**
 * @name MmgMeshOperationQueriesSummaries
 * @summary Generic component that creates a list of summaries for each query configuration
 * @param props
 */
export const MmgMeshOperationQueriesSummaries = (props: MeshOperationQueriesSummariesProps) => {
  const { geometryQueries, workspaceVariables, operationConfigurations } = props;

  const sortedGeometryQueries = useMemo(
    () => {
      return ModelUtils.sortQueries(geometryQueries);
    },
    [geometryQueries],
  );

  return (
    <>
      {sortedGeometryQueries.map((query: IWorkspaceQuery) => {
        const queryConfiguration = (operationConfigurations || []).find(
          ({ inputIds }) => inputIds && inputIds.length > 0 && inputIds.indexOf(query.id) !== -1,
        );

        return (
          queryConfiguration && (
            <MmgMeshOperationQuerySummary
              key={query.id}
              query={query}
              operationConfiguration={queryConfiguration}
              workspaceVariables={workspaceVariables}
            />
          )
        );
      })}
    </>
  );
};
