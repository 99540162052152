/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Typography, Link } from '@mui/material';
import ArrowRight from '../../icons/ArrowRight.svg?react';
import mikeSharedTheme, { mikePalette } from '../../shared/styles/mikeSharedTheme';
import { ILinkConfig } from '../mike-support';

const LinkStyle = css`
  height: ${mikeSharedTheme.spacing(7.5)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: ${mikeSharedTheme.spacing(0)};
  padding-left: ${mikeSharedTheme.spacing(0.5)};
  padding-right: ${mikeSharedTheme.spacing(2)};
  color: ${mikePalette.primary.main};
  background-color: ${mikePalette.lightGrey?.main};
  &:hover {
    background-color: ${mikePalette.mediumGrey?.main};
  }
`;

const LinkTextStyle = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
  color: ${mikePalette.primary.main};
  font-size: 0.875rem;
  line-height: 1.286;
`;

interface IProps {
  /**
   * Configuration.
   */
  config: ILinkConfig;
  /**
   * Click callback.
   */
  onClickCallBack: () => void;
}

/**
 * @name MikeSubscribedAppsLinkItem
 * @summary A generic app link. Meant to be placed into the MikeSubscribedApps popper.
 */
export const MikeSubscribedAppsLinkItem = (props: IProps) => {
  const { config = {} as ILinkConfig, onClickCallBack } = props;

  return (
    <Link
      rel="noopener noreferrer"
      href={config.href}
      target={config.target ? config.target : ''}
      css={LinkStyle}
      underline="none"
      onClick={onClickCallBack}
    >
      {config.icon ? config.icon : <ArrowRight />}
      {config.label && <Typography css={LinkTextStyle}>{config.label}</Typography>}
    </Link>
  );
};

export default MikeSubscribedAppsLinkItem;
