/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  currentValue: any;
}

export const PanelTabStyle = css`
  width: 100%;
`;

export const MmgTabPanel = (props: TabPanelProps) => {
  const { children, value, index, currentValue, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== currentValue}
      id={`mmg-tabpanel-${index}`}
      aria-labelledby={`mmg-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
};
