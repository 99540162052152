/**
 * Models used to type UI inputs / results.
 * These typically get modified before being sent to the API (@see IWorkspaceAPIQueries).
 */
import { EAttributeDataTypes } from './IWorkspaceAttributes';

export enum EUIQueryType {
  STRING = 'query-type-string',
  RANGE = 'query-type-range',
  OPTION = 'query-type-option',
  SELECT_ALL = 'query-type-select-all',
}

export const QUERY_TYPE = {
  STRING: EUIQueryType.STRING,
  RANGE: EUIQueryType.RANGE,
  OPTION: EUIQueryType.OPTION,
  SELECT_ALL: EUIQueryType.SELECT_ALL,
};

export interface IWorkspaceUIGenericQuery {
  queryAttribute?: string;
  queryAttributeDataType?: EAttributeDataTypes;
  rangeQueryMin?: number;
  rangeQueryMax?: number;
  stringQueryText?: string;
  stringQueryMatches?: Array<string>;
  optionQueryValue?: string;
  queryType?: EUIQueryType;
}

export interface IWorkspaceUIQueryRangeValues {
  minValue: number;
  maxValue: number;
}

export interface IWorkspaceUIQuerOptionItem {
  value: string;
  name: string;
}

export interface IWorkspaceMeshUIQueryContainer {
  numberOfIterations?: number;
  id: string;
  meshQuery: IWorkspaceMeshUIQuery;
}

export interface IWorkspaceMeshUIQuery {
  id: string;
  name?: string;
  fromValue?: number;
  toValue?: number;
}

export const QUERY_CRITERIA_NAMES = {
  SAVED_SELECTIONS: 'saved_selections',
  SPATIAL_SELECTION: 'spatial_selection',
  GEOMETRIES: 'geometries',
};
