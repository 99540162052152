/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import MikeVisualizerUtil from '../MikeVisualizer/lib/MikeVisualizerUtil';
import { IIndexChanged } from '../MikeVisualizer/lib/models/IIndexChanged';
import { useSelector } from 'react-redux';
import { CellInfoZIndex } from '../shared/styles/styleConsts';
import { IDrawnDataItem } from '../models/IWorkspaceData';
import WorkspaceMeshSelectors from '../store/selectors/WorkspaceMeshSelectors';
import { IWorkspaceEnrichedMesh } from '../models/IMeshes';
import { IWorkspaceMeshTiles } from '../store/reducers/WorkspaceMeshTilesReducer';
import mikeSharedTheme from './styles/mikeSharedTheme';
import { IGlobalState } from '../store/reducers';
const {
  onPointIndexChanged,
  setCellIndexActorIds,
  enableMouseMovePointIdPicker,
  enableMouseMoveCellIdPicker,
  setPointIndexActorIds,
  onCellIndexChanged,
} = MikeVisualizerLib;

const CellInfoStyle = css`
  position: absolute;
  z-index: ${CellInfoZIndex};
  border: 1px black solid;
  background-color: ${mikeSharedTheme.palette.lightGrey.main};
  padding: ${mikeSharedTheme.spacing(0.5)};
  color: ${mikeSharedTheme.palette.text.primary};
`;

const CellInfo = ({ elementId }) => {
  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const mesh: IWorkspaceEnrichedMesh = useSelector((state: IGlobalState) =>
    getMeshSelectorInstance(state, { meshId: elementId }),
  );
  const [cellIndexObject, setCellIndexObject] = useState<any | null>(null);
  const [value, setValue] = useState<string | null>();

  const drawnDataItems = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.workspaceData);
  const meshTiles: { [key: string]: Array<IWorkspaceMeshTiles> } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.meshTiles,
  );

  const isTiled = useMemo(
    () => {
      return mesh && mesh.isTiled;
    },
    [mesh],
  );

  const elementIds = useMemo(
    () => {
      if (!isTiled) {
        return [elementId];
      } else if (meshTiles && meshTiles[elementId]) {
        const tiles = meshTiles[elementId];
        if (tiles.length > 0) {
          const lastTiles: IWorkspaceMeshTiles = tiles[tiles.length - 1];
          return lastTiles.partIds;
        }
      }
      return [];
    },
    [isTiled, meshTiles, elementId],
  );

  const attributeName: string = useMemo(
    () => {
      if (!elementId || !drawnDataItems || drawnDataItems.length === 0) {
        return '';
      }
      const drawnDataItem = drawnDataItems.find((d: IDrawnDataItem) => d.id === elementId);
      if (drawnDataItem === undefined) {
        return '';
      }
      return drawnDataItem.statisticsAttributeName ? drawnDataItem.statisticsAttributeName : '';
    },
    [drawnDataItems, elementId],
  );

  const storageType = useMemo(
    () => {
      const id = elementIds && elementIds.length > 0 ? elementIds[0] : '';
      if (id) {
        if (MikeVisualizerUtil.isPointData(id, attributeName)) {
          return 0;
        } else if (MikeVisualizerUtil.isCellData(id, attributeName)) {
          return 1;
        }
      }
      return -1;
    },
    [elementIds, attributeName],
  );

  useEffect(
    () => {
      if (storageType === 0) {
        setPointIndexActorIds(elementIds);
        setCellIndexActorIds([]);
        enableMouseMovePointIdPicker(0.001);
      } else if (storageType === 1) {
        setCellIndexActorIds(elementIds);
        setPointIndexActorIds([]);
        enableMouseMoveCellIdPicker(0);
      } else {
        setCellIndexActorIds([]);
        setPointIndexActorIds([]);
      }
    },
    [elementIds, storageType],
  );

  const handlePointIndexChanged = useCallback(
    (pointIndexChanged: IIndexChanged) => {
      if (storageType !== 0) {
        return;
      }
      setCellIndexObject(pointIndexChanged);
      if (pointIndexChanged.index === -1) {
        setValue(null);
        return;
      }
      const values = MikeVisualizerUtil.getDataValues(pointIndexChanged.actorId, attributeName);
      if (values && values.length > pointIndexChanged.index) {
        const res = values[pointIndexChanged.index].toString();
        setValue(res);
      }
    },
    [attributeName, storageType],
  );

  const handleCellIndexChanged = useCallback(
    (cellIndexChanged: IIndexChanged) => {
      if (storageType !== 1) {
        return;
      }
      setCellIndexObject(cellIndexChanged);
      if (cellIndexChanged.index === -1) {
        setValue(null);
        return;
      }
      const values = MikeVisualizerUtil.getDataValues(cellIndexChanged.actorId, attributeName);
      if (values && values.length > cellIndexChanged.index) {
        const res = values[cellIndexChanged.index].toString();
        setValue(res);
      }
    },
    [attributeName, storageType],
  );

  useEffect(
    () => {
      const events = [onPointIndexChanged(handlePointIndexChanged), onCellIndexChanged(handleCellIndexChanged)];

      return () => {
        setCellIndexActorIds([]);
        setPointIndexActorIds([]);
        events.forEach((unsubscribe) => unsubscribe());
      };
    },
    [handleCellIndexChanged, handlePointIndexChanged],
  );

  const x = cellIndexObject && Math.trunc(cellIndexObject.displayCoordinates.x);
  const y = cellIndexObject && Math.trunc(cellIndexObject.displayCoordinates.y);

  return (
    <>
      {value &&
        cellIndexObject && (
          <div
            css={CellInfoStyle}
            style={{
              bottom: `${y}px`,
              left: `${x}px`,
            }}
          >
            {value}
          </div>
        )}
    </>
  );
};

export default CellInfo;
