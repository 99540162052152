/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { Typography } from '@mui/material';
import { ROUTES } from '../../app/routes';
import { Link } from 'react-router-dom';
import { MmgMetas } from '../../shared/metas/metas';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';
import { IGlobalState } from '../../store/reducers';

const NotFoundPageSectionStyle = css`
  max-width: 500px;
  padding-top: ${mikeSharedTheme.spacing(2)};
`;

const ErrorPageTitleStyle = css`
  padding-bottom: ${mikeSharedTheme.spacing(3)};
`;

const NotFoundPageStyle = css`
  $(NotFoundPageSectionStyle);
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column; 
`;

const NotFoundPageColorStyle = css`
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  font-size: 0.875rem;
  text-decoration: underline;
`;

/**
 * @name MmgConnectedNotFoundPage
 * @summary Connected not found / 404 page.
 *
 */
export const MmgConnectedNotFoundPage = () => {
  const { userLoggedIn, userSessionExpired, user } = useSelector((state: IGlobalState) => state.UserReducer);
  return (
    <>
      <MmgMetas metaTitle={t('NOT_FOUND')} />
      <div css={NotFoundPageStyle}>
        <Typography css={ErrorPageTitleStyle} variant="h1">
          {'404'}
        </Typography>
        <Typography variant="body2">
          {t('NOT_FOUND')}
          {user && user.tenantName ? ' ' + user.tenantName : ''}
        </Typography>
        <div css={NotFoundPageSectionStyle}>
          {userLoggedIn && !userSessionExpired ? (
            <Link css={NotFoundPageColorStyle} to={ROUTES.home.path}>
              {t('GO_TO', 1, { thing: t('HOME_PAGE') })}
            </Link>
          ) : (
            <Link css={NotFoundPageColorStyle} to={ROUTES.home.path}>
              {t('GO_TO', 1, { thing: t('LOGIN_PAGE') })}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
