
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { IParameterDescription, PARAMETER_VALUE_TYPES } from '../../models/IOperationDescriptions';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import { MmgParameterInfoWrapper } from './parameter-info-wrapper';
import { toNumber } from 'lodash-es';
import { IParameterSettings } from '../../models/IParameterSettings';
import { isNumericValueType } from './parameter-utils';
import { useField } from 'formik';
import mikeSharedTheme from '../styles/mikeSharedTheme';

type ParameterInputRadioProps = {
  parameterKey: string;
  parameterDescription: IParameterDescription;
  name: string;
  value: number | string | boolean | object; // todo hevo - only allow strings!
  label: string;
  infoText?: string;
  helperText?: string;
  placeholder: string;
  error?: boolean;
  customSettings?: IParameterSettings;
  getValueDisplayName?: (parameterValue: number | boolean | string | object) => string;
  onParameterChanged?: (param: string, val: number | string | boolean | object) => void;
};

const FormControlCss = css`
  &.MuiFormControl-root {
    margin-top: ${mikeSharedTheme.spacing(1)};
  }
`;

// todo hevo not sure if adding ellipsis this is a general rule to be added in the theme, or if it depends on the usecase
const FormLabelCss = css`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const FormlabelInfoWrapperCss = css`
  align-items: center;
`;

/**
 * @name MmgParameterInputRadio
 * @param props
 * @summary A generic element that renders a group of radio buttons and accepts a callback for when selection change.
 * The 'values' of the parameterDescription will be used as labels.
 * If getValueDisplayName is passed in, it will be used for displaying the labels. Otherwise the value will be used directly
 *
 */
export const MmgParameterInputRadio = (props: ParameterInputRadioProps) => {
  const {
    parameterKey,
    name,
    value,
    label,
    helperText,
    infoText,
    error,
    onParameterChanged,
    getValueDisplayName,
    parameterDescription,
  } = props;

  const [field] = useField({
    name: name || parameterKey,
    value: value as number,
  });
  const { values, valueType, defaultValue } = parameterDescription;

  const onInputChanged = (event: React.ChangeEvent) => {
    field.onChange(event);

    const eventTarget = event.target as HTMLInputElement;
    const { name: targetName, value: newValue } = eventTarget;

    let val: string | number = newValue;

    if (isNumericValueType(valueType) || valueType === PARAMETER_VALUE_TYPES.ENUM) {
      // For enums the api might also require a number in some cases, so if the value is a number, we keep it as a number
      // If nothing is selected we keep the value as undefined

      // eslint-disable-next-line no-extra-boolean-cast
      const numberVal = Boolean(newValue) ? toNumber(newValue) : undefined;
      val = !isNaN(numberVal) ? numberVal : newValue;
    }

    if (onParameterChanged) {
      onParameterChanged(targetName, val);
    }
  };

  const getValueName = (val) => {
    if (getValueDisplayName) {
      return getValueDisplayName(val);
    }
    return val;
  };

  if (!parameterKey || !values) {
    return <></>;
  }
  const selectedValue = value || value === 0 ? value : defaultValue;

  // radiobuttons treats values as strings, so we need to convert to string to get the correct inital selected radioubutton
  const selectedValueAsString = selectedValue || selectedValue === 0 ? selectedValue.toString() : undefined;

  return (
    <FormControl fullWidth component="fieldset" css={FormControlCss}>
      <MmgParameterInfoWrapper infoText={infoText} cssProp={FormlabelInfoWrapperCss}>
        <FormLabel
          title={label}
          className={FormLabelCss + ' MuiInputLabel-shrink'} //this is a hack to make the label look like the shrinked labels for input fields.
          component="legend"
        >
          {label}
        </FormLabel>
      </MmgParameterInfoWrapper>

      <RadioGroup
        aria-label={parameterKey}
        name={name}
        value={selectedValueAsString}
        onChange={onInputChanged}
        onBlur={field.onBlur}
      >
        {values &&
          Object.keys(values).map((key) => {
            return <FormControlLabel key={key} value={key} control={<Radio />} label={getValueName(key)} />;
          })}
      </RadioGroup>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
