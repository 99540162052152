import { useEffect, useMemo } from 'react';
import { useMatch } from 'react-router';
import { SIDEPANEL_NAVIGATION_GROUPS } from './workspace-utils';
import { store } from '../store';
import { workspaceEditPanelPath, workspaceExportPanelPath } from './workspace.routes';
import { commentsPanelPath } from '../comments/comment.routes';
import { workspacePath } from '../app/app.routes';

/**
 * @name MmgWorkspaceNavigationGroupSetter
 * @summary Sets the navigation group in the store, based on the current route.
 * This component needs to be nested under the route <Switch> that contains all panel routes.
 */
export const MmgWorkspaceNavigationGroupSetter = () => { 

  const settingsRoute = useMatch(workspacePath + workspaceEditPanelPath);
  const downloadRoute = useMatch(workspacePath + workspaceExportPanelPath)
  const commentsRoute = useMatch(workspacePath + commentsPanelPath)

  const currentNavGroup = useMemo(() => {
    if (settingsRoute){
      return SIDEPANEL_NAVIGATION_GROUPS.SETTINGS;
    }
    else if (downloadRoute){
      return SIDEPANEL_NAVIGATION_GROUPS.DOWNLOAD;
    }
    else if (commentsRoute){
      return SIDEPANEL_NAVIGATION_GROUPS.COMMENTS;
    }
    else{
      return SIDEPANEL_NAVIGATION_GROUPS.MAIN_PANEL;
    }
    
  }, [commentsRoute, downloadRoute, settingsRoute])



  useEffect(
    () => {
      store.dispatch({ type: 'panel/SET_CURRENT_NAV_GROUP', currentNavGroup });
    },
    [currentNavGroup],
  );

  return <></>;
};
