/**
 * Exposes methods to handle workspace geometries requests.
 *
 * @module WorkspaceGeometriesManager
 * @version 2.0.0
 * @requires WorkspaceGeometriesProxy
 * @requires OperationsProxy
 */
import * as WorkspaceGeometriesProxy from '../proxies/WorkspaceGeometriesProxy';
import { t } from '../translations/i18n';
import { HttpErrorHandler } from './http-utils';
import { IWorkspaceGeometry } from '../models/IGeometries';
import { FeatureCollection } from 'geojson';

/**
 * Transforms a request that performs deletion of multiple geometries.
 *
 * @param workspaceId
 * @param geometryIds
 */
export const deleteGeometries = (workspaceId: string, geometryIds: Array<string>) => {
  if (!geometryIds || geometryIds.length < 1) {
    return Promise.resolve();
  }
  return WorkspaceGeometriesProxy.deleteGeometries(workspaceId, geometryIds)
    .then((res) => {
      return res.data;
    })
    .catch((error) => HttpErrorHandler('Failed to delete geometries.', error)) as Promise<void>;
};

/**
 * Transforms a request that updates a list of geometries.
 *
 * @param workspaceId
 * @param geometries
 */
const updateGeometries = (workspaceId: string, geometries: Array<IWorkspaceGeometry>): Promise<any> => {
  return WorkspaceGeometriesProxy.updateGeometries(workspaceId, geometries).catch((error) =>
    HttpErrorHandler('Failed to update geometries.', error),
  );
};

/**
 * Transforms a request that renames a geometry.
 *
 * @param workspaceId
 * @param geometryId
 * @param geometryName
 */
export const renameGeometry = (workspaceId: string, geometryId: string, geometryName: string) =>
  self
    .updateGeometries(workspaceId, [{ id: geometryId, name: geometryName }])
    .catch((error) => HttpErrorHandler('Failed to rename geometry.', error));

/**
 * Transforms a request that duplicates a geometry.
 *
 * @param workspaceId
 * @param geometryId
 */
export const duplicateGeometry = (workspaceId: string, geometryId: string) => {
  return WorkspaceGeometriesProxy.duplicateGeometry(workspaceId, geometryId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to duplicate geometry.', error));
};

/**
 * Transforms a request that duplicates a list of geometries.
 *
 * @param workspaceId
 * @param geometryIds
 */
export const batchDuplicateGeometries = (workspaceId: string, geometryIds: Array<string>) => {
  if (!geometryIds) {
    return Promise.resolve();
  }

  return Promise.all(geometryIds.map((geometryId) => self.duplicateGeometry(workspaceId, geometryId))).catch((error) =>
    HttpErrorHandler('Failed to bulk duplicate geometries.', error),
  );
};

/**
 * Transforms a request that creates a geometry from a feature collection.
 *
 * @param workspaceId Workspace to create the geometry in
 * @param featureCollection
 * @param name
 * @param properties Arbitrary object with key: value pairs of global properties.
 */
export const createGeometry = (
  workspaceId: string,
  featureCollection: FeatureCollection<any, any>,
  name: string,
  properties: { [key: string]: string } = {},
) => {
  return WorkspaceGeometriesProxy.createGeometry(workspaceId, name, featureCollection, properties).catch((error) =>
    HttpErrorHandler('Failed to create geometry.', error),
  );
};

/**
 * Transforms a request that creates geometries by grouping features by their type.
 * This can result in a maximum of 3 created geometries, for Points, Lines and Polygons.
 *
 * @param workspaceId
 * @param featureCollection
 * @param [name] The name of the extracted feature(s). Name will be repeated for each type of feature.
 * @param properties Arbitrary object with key: value pairs of global properties.
 */
export const createGeometriesFromFeatureCollection = (
  workspaceId: string,
  featureCollection: FeatureCollection<any, any>,
  name = '',
  properties: { [key: string]: string } = {},
) => {
  const createGeometryPromises = [];
  const nonEmptyFeatures = featureCollection.features.filter((feature) => feature.geometry);

  // TODO dan: if this stays, should do a smart groupBy.
  const polygonFeatures = nonEmptyFeatures.filter(
    (feature) => feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon',
  );
  const polylineFeatures = nonEmptyFeatures.filter(
    (feature) => feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString',
  );
  const pointFeatures = nonEmptyFeatures.filter(
    (feature) => feature.geometry.type === 'Point' || feature.geometry.type === 'MultiPoint',
  );

  if (polygonFeatures.length) {
    createGeometryPromises.push(
      self.createGeometry(
        workspaceId,
        { type: 'FeatureCollection', features: polygonFeatures },
        name || t('POLYGON'),
        properties,
      ),
    );
  }

  if (polylineFeatures.length) {
    createGeometryPromises.push(
      self.createGeometry(
        workspaceId,
        { type: 'FeatureCollection', features: polylineFeatures },
        name || t('LINESTRING'),
        properties,
      ),
    );
  }

  if (pointFeatures.length) {
    createGeometryPromises.push(
      self.createGeometry(
        workspaceId,
        { type: 'FeatureCollection', features: pointFeatures },
        name || t('POINT'),
        properties,
      ),
    );
  }

  return Promise.all(createGeometryPromises).catch((error) =>
    HttpErrorHandler('Failed to create geometries from feature collection.', error),
  );
};

/**
 * Transforms a request that gets a geometry as GeoJSON.
 *
 * @param workspaceId
 * @param geometryId
 */
export const getGeometryGeojson = (workspaceId: string, geometryId: string): Promise<FeatureCollection<any, any>> => {
  return WorkspaceGeometriesProxy.getGeometryGeojson(workspaceId, geometryId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get geometry geojson data.', error));
};

/**
 * Transforms a request that replaces the data of a geometry with the provided featureCollection.
 *
 * @param workspaceId
 * @param geometryId
 * @param featureCollection
 */
export const updateGeometryData = (
  workspaceId: string,
  geometryId: string,
  featureCollection: FeatureCollection<any, any>,
) => {
  return WorkspaceGeometriesProxy.updateGeometryData(workspaceId, geometryId, featureCollection).catch((error) =>
    HttpErrorHandler('Failed to update geometry data with geojson.', error),
  );
};

/**
 * Transforms a request that gets geometry global properties.
 *
 * @param workspaceId
 * @param geometryId
 */
export const getGeometryProperties = (workspaceId: string, geometryId: string): Promise<{ [key: string]: string }> => {
  return WorkspaceGeometriesProxy.getGeometryProperties(workspaceId, geometryId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get geometry properties.', error));
};

/**
 * Transforms a request that exports a list of geometries.
 *
 * @param workspaceId
 * @param geometryIds
 */
export const exportGeometries = (workspaceId: string, geometryIds: Array<string>) => {
  if (!geometryIds || geometryIds.length < 1) {
    return Promise.resolve();
  }
  return WorkspaceGeometriesProxy.exportGeometries(workspaceId, geometryIds)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get geometry properties.', error)) as Promise<void>;
};

/**
 * Transforms a request that gets a list of geometry ids that allows export to the platform.
 *
 * @param workspaceId
 */
export const getExportableGeometries = (workspaceId: string): Promise<Array<string> | void> => {
  return WorkspaceGeometriesProxy.getExportableGeometries(workspaceId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get exportable geometries.', error));
};

const self = {
  deleteGeometries,
  updateGeometries,
  renameGeometry,
  duplicateGeometry,
  batchDuplicateGeometries,
  createGeometry,
  createGeometriesFromFeatureCollection,
  getGeometryGeojson,
  updateGeometryData,
  getGeometryProperties,
  exportGeometries,
  getExportableGeometries,
};

export default self;
