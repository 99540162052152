import { orderBy } from 'lodash-es';
import { IParameterDescription, IParameterConditionDescription } from '../models/IOperationDescriptions';
import { MmgParameterInput } from '../shared/parameters/parameter-input';
import { isParameterConditionOk, getParameterConditions } from '../shared/parameters/parameter-condition-utils';
import { IParameterSettings } from '../models/IParameterSettings';
import { IOperationConfiguration } from '../models/IOperations';

type OperationParametersProps = {
  parameters: { [key: string]: number | boolean | string | object };
  parameterDescriptions?: { [parameterKey: string]: IParameterDescription };
  parameterConditionDescriptions?: {
    [parameterKey: string]: IParameterConditionDescription;
  };
  parameterSettings?: {
    [parameterKey: string]: IParameterSettings;
  };
  onParameterChanged?: (param: string, val: number | boolean | string | object) => void;
  onPostParameterChanged?: (param: string, val: number | boolean | string | object) => void;
  postParameters?: { [key: string]: number | boolean | string | object };
  dirtyOperationConfiguration?: IOperationConfiguration;
  dirtyParentConfiguration?: IOperationConfiguration;
};

/**
 * @name MmgOperationParameters
 * @param props
 * @summary A generic element that renders a list of parameters and accepts a callback for when they change.
 * This can be used to display & change parameters for: creating mesh, editing mesh, creating transform, applying post-operation, etc.
 *
 */
export const MmgOperationParameters = (props: OperationParametersProps) => {
  const {
    parameters,
    parameterDescriptions,
    parameterConditionDescriptions,
    parameterSettings,
    onParameterChanged,
    dirtyOperationConfiguration,
    dirtyParentConfiguration,
  } = props;
  const parameterConditions = parameterConditionDescriptions
    ? getParameterConditions(parameters, parameterConditionDescriptions)
    : null;
  if (!parameters) {
    return <></>;
  }

  const ordererParameters = orderBy(
    Object.keys(parameters)
      .map((param) => {
        const conditionOk = !parameterConditions || isParameterConditionOk(param, parameterConditions);
        if (!conditionOk) {
          return null;
        }
        const parameterValue = parameters[param];
        const parameterDescription = parameterDescriptions[param] || ({} as IParameterDescription);
        return {
          parameterKey: param,
          parameterValue,
          parameterDescription,
          order: parameterDescription.order,
        };
      })
      .filter((p) => p),
    ['order'],
  );

  return (
    <>
      {ordererParameters.map(({ parameterKey, parameterValue, parameterDescription }) => (
        <MmgParameterInput
          key={parameterKey}
          parameterKey={parameterKey}
          value={parameterValue}
          parameterDescription={parameterDescription}
          customParameterSettings={parameterSettings}
          onParameterChanged={onParameterChanged}
          dirtyOperationConfiguration={dirtyOperationConfiguration}
          dirtyParentConfiguration={dirtyParentConfiguration}
        />
      ))}
    </>
  );
};
