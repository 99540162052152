import { Feature, Point } from 'geojson';
import { intersectionWith, isNaN as _isNaN, merge } from 'lodash-es';
import { EDIT_MODE, IAppendProperties } from '../..//store/reducers/EditReducer';

export const DEFAULT_VARIABLE_ELEVATION_PROPERTY = 'elevation';

export const IGNORE_VARIABLE_PROPERTIES = ['id', 'region'];

export const MMG_ = 'mmg_';

export const CELLDATA = 'celldata';
export const POINTDATA = 'pointdata';

export const DEFAULT_VARIABLE_PROPERTIES = {
  [DEFAULT_VARIABLE_ELEVATION_PROPERTY]: 0, // TODO dan: for now, this is hardcoded as per our PO's requirements. Later on, we should allow arbitrary properties to be appended as well; users should be able to add as many properties as they wish
  // Alternatively, these default values can be retrieved from the API or there can be a few common ones hardcoded that can be picked with i.e. a typeahead text input
};

export const hasZElevationProperty = (props: IAppendProperties) => {
  const keys = Object.keys(props);
  return keys && keys.includes(DEFAULT_VARIABLE_ELEVATION_PROPERTY);
};

export const isZElevationProperty = (key: string) => {
  return key && key === DEFAULT_VARIABLE_ELEVATION_PROPERTY;
};

export const getZValue = (value: number | string | undefined | null) => {
  const parsedValue = parseFloat(value as string);
  return parsedValue && !_isNaN(parsedValue) ? parsedValue : 0;
};

export const transferElevationToZValue = (
  newFeatures: Array<Feature<Point>>,
  propertiesForEditing: IAppendProperties,
  editModeValue: number,
) => {
  const keys = Object.keys(propertiesForEditing);
  const validProps: IAppendProperties = { [EDIT_MODE]: editModeValue };
  keys.forEach((key: string) => {
    const value = propertiesForEditing[key];
    const isValueMixed = value && value.toString().startsWith('range');
    validProps[key] = isValueMixed || value === undefined ? 0 : value;
    validProps[EDIT_MODE] = editModeValue;
  });

  const elevationKey = validateFeaturePropertyElevation(validProps);
  const zVal = !elevationKey ? 0 : getZValue(validProps[elevationKey]);
  const updatedFeatures = newFeatures.map((feature: Feature<any>) => {
    return {
      type: 'Feature',
      geometry: {
        ...feature.geometry,
        coordinates: [...feature.geometry.coordinates.slice(0, 2), zVal],
      },
      properties: merge(feature.properties, validProps),
    } as Feature<any>;
  });
  return updatedFeatures;
};

export const addEditValue = (newFeatures: Array<Feature<Point>>, editModeValue: number) => {
  const validProps: IAppendProperties = { [EDIT_MODE]: editModeValue };
  const updatedFeatures = newFeatures.map((feature: Feature<any>) => {
    return {
      ...feature,
      properties: merge(feature.properties, validProps),
    } as Feature<any>;
  });
  return updatedFeatures;
};

export const getNumericValue = (value: number | string | undefined | null) => {
  const parsedValue = parseFloat(value as string);
  return !_isNaN(parsedValue) ? parsedValue : null;
};

export const validateFeaturePropertyElevation = (properties: { [key: string]: string | number | boolean }) => {
  const elevationKeys = [DEFAULT_VARIABLE_ELEVATION_PROPERTY];
  const keys = Object.keys(properties);

  return intersectionWith(
    keys,
    elevationKeys,
    (key: string, guesses: string) => key === guesses || key === guesses.toLowerCase(),
  )[0];
};
