/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactNode } from 'react';
import { Grow, ThemeProvider, Typography } from '@mui/material';
import Logo from '../../icons/LandingPage.svg?react';
import MeshGenerator from '../../icons/MeshGenerator.svg?react';
import splash from './splashscreen.jpg';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

const LandingPageStyle = 
  css` 
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  width: 100vw;
  position: relative;
  `;

const LandingPageLogoStyle = 
  css`
  position: fixed;
  bottom: 45px;
  right: 34px;
  `;

const LandingPageContentStyle = 
  css`
  background: white;
  width: 538px;
  height: 258px;
  margin: auto;
  margin-left: -269px;
  margin-top: -129px;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  position: fixed;
  left: 50%;
  top: 50%;
  `;

const containerStyle = 
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
  `;

const wrapperStyle = 
  css`
    padding: 50px 40px 25px 40px;
  `;

const headerStyle = 
  css`
    margin-bottom: 0px;
  `;


type Props = {
  children?: ReactNode;
};

const LandingLayout = ({ children }: Props) => {
  const background = {
    backgroundImage: `url(${splash})`,
  };

  return (
    <ThemeProvider theme={mikeSharedTheme}>      
      <div css={LandingPageStyle} style={background}>
        <div css={LandingPageLogoStyle}>
          <Logo />
        </div>

        <div css={LandingPageContentStyle}>
          <Grow in={true}>
            <div css={wrapperStyle}>             
              <div css={containerStyle}>
                <MeshGenerator />
                <Typography color="primary" gutterBottom={true} variant="h1" css={headerStyle}>
                  Mesh Builder
                </Typography>
              </div>
              {children}             
            </div>
          </Grow>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default LandingLayout;
