import { useMemo } from 'react';
import { IWorkspaceQuery } from '../../models/IQueries';
import ModelUtils from '../../managers/model-utils';
import { IOperationConfiguration } from '../../models/IOperations';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { useGroupQueryIdsByTargetIdMemo } from './configuration/useGroupQueryIdsByTargetIdMemo';

export interface IGroupedGeometryConfigurations {
  [geometryId: string]: {
    geometry: IWorkspaceEnrichedGeometry;
    geometryConfiguration: IOperationConfiguration;
    queryConfigurations: Array<IOperationConfiguration>;
  };
}

/**
 * Group the given child configurations by geometry id for easy lookup. The groups will be sorted by the geometry items
 * Will include the configúration of the geometry as well as configurations of any queries related to the geometry
 *
 * @param childConfigurations Is expected to hold configruations of geometries and queries.
 * @param geometries
 * @param queries
 */
export const useGroupedAndSortedGeometryConfigurations = (
  childConfigurations: Array<IOperationConfiguration>,
  geometries: Array<IWorkspaceEnrichedGeometry>,
  queries: Array<IWorkspaceQuery>,
): IGroupedGeometryConfigurations => {
  // group the queries by their target id for easy lookup
  const queryIdsByTarget = useGroupQueryIdsByTargetIdMemo(queries);

  // todo hevo might be better to use useEffect instead and keep result in state. This would allow checking if dependencies has actually changed.
  const groupedGeometryConfigurations = useMemo(
    () => {
      if (!childConfigurations || childConfigurations.length === 0 || !geometries || geometries.length === 0) {
        return {} as IGroupedGeometryConfigurations;
      }

      const sortedGeometries = ModelUtils.sortGeometries(geometries) as Array<IWorkspaceEnrichedGeometry>;

      const grouped = sortedGeometries.reduce(
        (acc, geometry) => {
          const geometryConfiguration = childConfigurations.find(
            ({ inputIds }) => inputIds.indexOf(geometry.id) !== -1,
          );

          // skip geometries for which we do not have a configuration
          if (!geometryConfiguration) {
            return acc;
          }

          const queryIds = queryIdsByTarget[geometry.id];

          const queryConfigurations =
            queryIds && queryIds.length
              ? (childConfigurations || []).filter(({ inputIds }) => queryIds.indexOf(inputIds[0]) !== -1)
              : ([] as Array<IOperationConfiguration>);

          return {
            ...acc,
            [geometry.id]: {
              geometry,
              geometryConfiguration,
              queryConfigurations,
            },
          };
        },
        {} as IGroupedGeometryConfigurations,
      );

      return grouped;
    },
    [geometries, childConfigurations, queryIdsByTarget],
  );

  return groupedGeometryConfigurations;
};
