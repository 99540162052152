import { useMemo } from 'react';
import { IWorkspaceQuery } from '../../../models/IQueries';
import ModelUtils from '../../../managers/model-utils';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IOperationDescription, IContextualOperationDescriptions } from '../../../models/IOperationDescriptions';
import { store } from '../../../store/store';

export interface IGroupedQueryConfigurations {
  [queryId: string]: {
    query: IWorkspaceQuery;
    queryConfiguration: IOperationConfiguration;
    queryOperationDescriptions: {
      [operationKey: string]: IOperationDescription;
    };
  };
}

/**
 * Group the given child configurations by query id for easy lookup. The groups will be sorted by the query items
 * Will include the configúration of the query as well as contextual descriptions.
 * only queries having a contextual description for the operationType given will be included
 *
 * @param queries
 * @param childConfigurations Only configurations matching the queries given will be considered.
 * @param contextualOperationDescriptions
 * @param operationType Only configurations having a corresponding description will be included
 */

export const useGroupedAndSortedQueryConfigurations = (
  queries: Array<IWorkspaceQuery>,
  childConfigurations: Array<IOperationConfiguration>,
  contextualOperationDescriptions: IContextualOperationDescriptions,
  operationType?: string,
) => {
  const groupedQueryConfigurations = useMemo(
    () => {
      if (!contextualOperationDescriptions || !queries || queries.length === 0) {
        return {} as IGroupedQueryConfigurations;
      }
      store.dispatch({
        type: 'workspace/SET_GROUPING_SELECTION',
        isGrouping: true,
      });
      const sortedQueries = ModelUtils.sortQueries(queries) as Array<IWorkspaceQuery>;

      const grouped = sortedQueries.reduce(
        (acc, query) => {
          const queryConfiguration = childConfigurations
            ? childConfigurations.find(({ inputIds }) => inputIds.indexOf(query.id) !== -1)
            : null;

          const operationDescriptions = (contextualOperationDescriptions
            ? contextualOperationDescriptions[query.id] || {}
            : {}) as { [operationKey: string]: IOperationDescription };

          // only consider the operation description for the passed in operationType
          const operationTypeDescription = operationDescriptions[operationType];

          // leave out desxriptions for other operationTypes
          const queryOperationDescriptions = {
            [operationType]: operationDescriptions[operationType],
          };

          // skip queries for which we do not have an operation description for the operationType
          if (!operationTypeDescription) {
            return acc;
          }

          return {
            ...acc,
            [query.id]: {
              query,
              queryConfiguration,
              queryOperationDescriptions,
            },
          };
        },
        {} as IGroupedQueryConfigurations,
      );
      return grouped;
    },
    [contextualOperationDescriptions, queries, childConfigurations, operationType],
  );
  store.dispatch({
    type: 'workspace/SET_GROUPING_SELECTION',
    isGrouping: false,
  });
  return groupedQueryConfigurations;
};
