/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { useEffect } from 'react';
import { List } from '@mui/material';
import { IMikeSidePanelNavigationItemProps } from '../mike-sidepanel-navigationitem/MikeSidePanelNavigationItem';

export interface IMikeSidePanelNavigationProps {
  /**
   * Identifies the current selected navigation item
   */
  value?: string;

  /**
   * @event Callback fired when the navigation change
   */
  onNavigationChange?: (value: any) => void;

  /**
   * Navigation contents. Should be an array of either @?ref SidePanelNavigationItem or @?ref ListItem
   */
  children?: Array<React.ReactElement>;

  /**
   * The css. WIll be passed on to the containing mui List element.
   */
  cssStyle?: SerializedStyles

  /**
   * If true, the navigation will be locked to the currently selected navigation item by disabling all other items.
   */
  navigationLocked?: boolean; // locks the navigation
}

const NAV_ITEM_SIZE = '40px'; // todo hevo make 0.875rem or use some size from theme?

const rootStyle = (cssStyle?: SerializedStyles) => {
  const style = css`
    width: ${NAV_ITEM_SIZE};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
 `;
  if (cssStyle){
    return css`
    ${style};
    ${cssStyle};
    `
  }
  else{
    return style
  }
}



/**
 * @name MikeSidePanelNavigation
 * @summary Holds navigation items for sidepanels
 *
 * @param props
 */
export const MikeSidePanelNavigation = (props: IMikeSidePanelNavigationProps) => {  
  const {
    value: propsValue,
    onNavigationChange: onNavigationChangeProps,
    children,
    cssStyle,
    navigationLocked,
  } = props;

  const [value, setValue] = React.useState(propsValue);

  const handleItemClicked = (newValue: string) => {
    setValue(newValue);

    if (onNavigationChangeProps) {
      onNavigationChangeProps(newValue);
    }
  };

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  return (
    <List css={rootStyle(cssStyle)} disablePadding={true}>
      {React.Children.map(
        children as Array<React.ReactElement>,
        (child: React.ReactElement<IMikeSidePanelNavigationItemProps>, childIndex) => {
          const { value: childPropsValue, onItemClicked: childItemClicked } = child.props;
          const childValue = childPropsValue === undefined ? childIndex : childPropsValue;

          const onItemClicked = (val: any) => {
            handleItemClicked(val);
            if (childItemClicked) {
              childItemClicked(val);
            }
          };

          return React.cloneElement(child, {
            selected: childValue === value,
            disabled: navigationLocked && childValue !== value,
            value: childValue,
            onItemClicked,
          });
        }
      )}
    </List>
  );
};

export default MikeSidePanelNavigation;
