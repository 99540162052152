/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from '@emotion/react';
import React from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { ILayerProps, MikeLayer } from './layer';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';

const LayerErrorTextStyle = css`
  color: ${MIKE_COLORS.PINK_DEFAULT};
  display: inline-block;
`;

const LayerErrorNameStyle = css`
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface ILayerErrorProps extends ILayerProps {
  rightActions?: React.ReactNode;
  layerName: string;
  layerDescription?: string | React.ReactNode;
  layerId: string;
  isLayerFaded?: boolean;
  onLayerNamePressed?: (layerId: string) => void;
  css?: Interpolation<Theme>;
}

/**
 * @name MikeLayerError
 * @summary A layer that errored out.
 *
 * @param props
 */
export function MikeLayerError(props: ILayerErrorProps) {
  const {
    rightActions,
    layerId,
    layerName,
    layerDescription,
    isLayerFaded,
    css: cssProp,
    ...rest
  } = props;

  return (
    <MikeLayer
      layerId={layerId}
      layerName={
        <span css={[LayerErrorTextStyle, LayerErrorNameStyle]}>{layerName}</span>
      } 
      layerDescription={<div css={LayerErrorTextStyle}>{layerDescription}</div>}
      leftIcon={<WarningRoundedIcon style={{ width: 30, height: 30 }} color="error" />}
      onLayerNamePressed={props.onLayerNamePressed}
      rightActions={rightActions}
      isLayerFaded={isLayerFaded}
      css={cssProp}
      {...rest}
    />
  );
}
