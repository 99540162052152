/**
 * Exposes methods to transform or combine query requests.
 *
 * @module WorkspaceQueryManager
 * @version 2.0.0
 * @requires WorkspaceQueryProxy
 */
import * as WorkspaceQueryProxy from '../proxies/WorkspaceQueryProxy';
import { HttpErrorHandler } from './http-utils';
import { t } from '..//translations/i18n';
import { FeatureCollection } from 'geojson';
import {
  QUERY_DEFINITION_TYPES,
  QUERY_RELATIONS,
  QUERY_RESULT_TYPES,
  ISpatialQueryDefinitionApi,
} from '../models/IQueryDefinitions';

import { EElementCategories, ELEMENT_CATEGORIES } from '../shared/panels/mesh-panel-constants';
import { IWorkspaceQuery } from '../models/IQueries';
import { store } from '../store';

/**
 * Transforms a request that creates a spatial query for a given element category type.
 *
 * @param workspaceId
 * @param itemIds i.e. one or more geometries
 * @param name
 * @param spatialSelection
 * @param elementCategory The category of the target elements. This is used to enforce defaults at the moment.
 * @param parameters Defines additional parameters to control the selection will slit. Currently only relevant for queries on geometries having a splitType parameter.
 * @param sourceItemId
 */
const createSpatialQuery = (
  workspaceId: string,
  itemIds: Array<string>,
  name: string,
  spatialSelection: FeatureCollection<any, any>,
  elementCategory: EElementCategories,
  parameters?: { [key: string]: number | boolean | string | object },
  sourceItemId?: string,
) => {
  switch (elementCategory) {
    case ELEMENT_CATEGORIES.GEOMETRY:
      return WorkspaceQueryProxy.createQuery(
        QUERY_DEFINITION_TYPES.SPATIAL,
        QUERY_RELATIONS.ISWITHIN,
        workspaceId,
        itemIds,
        name || t('SELECTION'),
        QUERY_RESULT_TYPES.GEOMETRY,
        spatialSelection,
        parameters,
        sourceItemId,
      ).catch((error) => HttpErrorHandler('Failed to create spatial query for a geometry.', error));

    case ELEMENT_CATEGORIES.MESH:
      return WorkspaceQueryProxy.createQuery(
        QUERY_DEFINITION_TYPES.SPATIAL,
        QUERY_RELATIONS.ISWITHIN,
        workspaceId,
        itemIds,
        name || t('SELECTION'),
        QUERY_RESULT_TYPES.POLYGON,
        spatialSelection,
        parameters,
        sourceItemId,
      ).catch((error) => HttpErrorHandler('Failed to create spatial query for a mesh.', error));

    default:
      return Promise.resolve();
  }
};

/**
 * Transforms an attribute query update to a request
 * GET request
 *
 * @param options
 */
const createPropertyTableQuery = (options) => {
  const { meshId, workspaceId, queryDefinition, resultTablePropertyNames, ordering, limit, offset } = options;

  const rtpNames =
    resultTablePropertyNames && resultTablePropertyNames.length ? resultTablePropertyNames : [queryDefinition.name];
  const params = {
    targetItems: [meshId],
    resultReturnType: 'PropertyTable',
    resultTablePropertyNames: rtpNames,
    SortOrder: ordering ? ordering : 'Ascending',
    ResultReturnLimit: limit ? limit : 10,
    ResultReturnOffset: offset ? offset : 0,
    SortProperty: queryDefinition.name,
    resultType: QUERY_RESULT_TYPES.POLYGON,
    ...queryDefinition,
  };

  return WorkspaceQueryProxy.createPropertyTableQuery(workspaceId, params)
    .then(() =>
      store.dispatch({
        type: 'toast/ADD/SUCCESS',
        toast: { text: t('QUERY_SUBMITTED') },
      }),
    )
    .catch((error) => HttpErrorHandler('Failed to create a property tableQuery.', error));
};

// Note by: This endpoint has been used on geometries-query-criteria.tsx only but is now out commented
// Note by: Result was used as a map preview only but call can become expensive especially for large / tiled meshes
const createSpatialQuery2 = (workspaceId, geojson, meshId) => {
  const params = {
    type: 'SpatialQueryDefinition2',
    relation: 2,
    featureCollection: geojson,
    targetItems: [meshId],
    resultType: 5,
    resultReturnType: 1,
  };

  return WorkspaceQueryProxy.createSpatialQuery(workspaceId, params).catch((error) =>
    HttpErrorHandler('Failed to create a property tableQuery.', error),
  );
};

/**
 * Transforms a request that updates a spatial query by completely replacing it.
 * Similar to a PUT request, only the API does not support it at the moment, so it is done via PATCH.
 *
 * @param workspaceId
 * @param queryId
 * @param name
 * @param queryDefinition
 */
const putSpatialQuery = (
  workspaceId: string,
  queryId: string,
  name?: string,
  queryDefinition?: ISpatialQueryDefinitionApi,
) =>
  WorkspaceQueryProxy.patchQuery(workspaceId, queryId, name, queryDefinition).catch((error) =>
    HttpErrorHandler('Failed to update (put) spatial query.', error),
  );

/**
 * Transforms a request that deletes a query.
 *
 * @param workspaceId
 * @param queryId
 */
const deleteQuery = (workspaceId: string, queryId: string) =>
  WorkspaceQueryProxy.deleteQuery(workspaceId, queryId).catch((error) =>
    HttpErrorHandler('Failed to delete query.', error),
  );

/**
 * Gets a query.
 *
 * @param workspaceId
 * @param queryId
 */
const getQuery = (workspaceId: string, queryId: string) => {
  return WorkspaceQueryProxy.getQuery(workspaceId, queryId)
    .then((res) => res.data as IWorkspaceQuery)
    .catch((error) => HttpErrorHandler('Failed to get query.', error)) as Promise<IWorkspaceQuery>;
};

const self = {
  createSpatialQuery2,
  createSpatialQuery,
  putSpatialQuery,
  deleteQuery,
  getQuery,
  createPropertyTableQuery,
};

export default self;
