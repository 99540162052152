import { IOperationConfiguration } from '../../../models/IOperations';
import { MmgOperationConfigurationSummary } from '../../../operations/operation-configuration-summary';

type MeshInterpolationBasicSummaryProps = {
  operationConfiguration: IOperationConfiguration;
};

/**
 * @name MmgMeshOperationBasicSummary
 * @summary A summary of the basic settings of a mesh interpolation operation.
 *
 * @param props
 */
export const MmgMeshInterpolationBasicSummary = (props: MeshInterpolationBasicSummaryProps) => {
  const { operationConfiguration } = props;

  return <MmgOperationConfigurationSummary operationConfiguration={operationConfiguration} showOperationName={false} />;
};
