import { useEffect } from 'react';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgLayerSelect } from '../../shared/layers/mesh-layer-select';
import { MmgConnectedLayerGeometrySelectActions } from '../../geometries/select-list/actions/layer-geometry-select-actions';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import GeometryIconUtils from '../../geometries/geometry-icon-utils';
import LayerUtils, { fetchLayer } from '../../shared/layers/layer-utils';
import { MmgLayerErrorSelect } from '../../shared/layers/mesh-layer-error-select';
import { MmgLayerError } from '../../shared/layers/mesh-layer-error';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../store/reducers';
import { MikeLayerColorComplication } from '../../shared-components/mike-layer/complications/layer-color-complications';
import { MikeLayerWorking } from '../../shared-components/mike-layer/layer-working';

const { getIcon } = GeometryIconUtils;
const {
  isLayerWorking,
  isLayerFailed,
  isLayerHidden,
  getLayerSurfaceColor,
  getLayerEdgeColor,
  selectLayer,
  deselectLayer,
} = LayerUtils;

type GeometrySelectListProps = {
  geometries: Array<IWorkspaceEnrichedGeometry>;
  hiddenGeometries: Array<string>;
  drawnGeometries: Array<IDrawnDataItem>;
  selectedGeometries: Array<string>;
  disabledGeometries?: Array<string>;
  isFailedSelectable?: boolean;
  onLayerSelectChanged?: (layerId: string, selected: boolean) => void;
  loadedData?: Array<string>;
  unselectOthers?: boolean;
};

/**
 * @name MmgConnectedGeometrySelectList
 * @summary Given an array of geometries, renders them and allows their selection.
 *
 * @param props
 */
export const MmgConnectedGeometrySelectList = (props: GeometrySelectListProps) => {
  const {
    geometries,
    hiddenGeometries,
    drawnGeometries,
    selectedGeometries,
    disabledGeometries = [],
    isFailedSelectable,
    onLayerSelectChanged: onLayerSelectChangedProps,
    unselectOthers,
  } = props;

  const loadedData: string[] = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);

  useEffect(
    () => {
      geometries.forEach((v: IWorkspaceEnrichedGeometry) => {
        if (!loadedData.includes(v.id)) {
          fetchLayer(v.id);
        }
      });
    },
    [geometries, loadedData],
  );

  const onLayerSelectChanged = (layerId: string, selected: boolean) => {
    if (selected) {
      selectLayer(ELEMENT_CATEGORIES.GEOMETRY, layerId, unselectOthers);
    } else {
      deselectLayer(ELEMENT_CATEGORIES.GEOMETRY, layerId);
    }

    if (onLayerSelectChangedProps) {
      onLayerSelectChangedProps(layerId, selected);
    }
  };

  if (!geometries || geometries.length === 0) {
    return null;
  }

  return (
    <div>
      {geometries.map((geometry) => {
        const { id, name, message } = geometry;
        const isWorking = isLayerWorking(geometry, drawnGeometries);
        const isDisabled = disabledGeometries.indexOf(id) !== -1;

        const baseLayerProperties = {          
          layerId: id,
          layerName: name,
          rightActions: <MmgConnectedLayerGeometrySelectActions layerId={id} geometry={geometry} />,
          isLayerFaded: isDisabled || isLayerHidden(ELEMENT_CATEGORIES.GEOMETRY, id, hiddenGeometries),
        };

        if (isLayerFailed(geometry)) {
          return isFailedSelectable ? (
            <MmgLayerErrorSelect
              key={id}
              {...baseLayerProperties}
              selected={selectedGeometries.indexOf(id) !== -1}
              layerDescription={message}
              onLayerSelectChanged={onLayerSelectChanged}
              disabled={isDisabled}
            />
          ) : (
            <MmgLayerError key={id} {...baseLayerProperties} layerDescription={message} />
          );
        }

        if (isWorking) {
          return <MikeLayerWorking key={id} {...baseLayerProperties} />;
        }

        return (
          <MmgLayerSelect
            key={id}
            {...baseLayerProperties}
            selected={selectedGeometries.indexOf(id) !== -1}
            leftIcon={getIcon(geometry)}
            leftComplications={
              <MikeLayerColorComplication
                edgeColor={getLayerSurfaceColor(geometry, drawnGeometries)}
                surfaceColor={getLayerEdgeColor(geometry, drawnGeometries)}
              />
            }
            onLayerSelectChanged={onLayerSelectChanged}
            disabled={isDisabled}
          />
        );
      })}
    </div>
  );
};
