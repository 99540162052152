/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import { isNumber } from 'lodash-es';
import { TextField, TextFieldProps } from '@mui/material';
import { t } from '../../translations/i18n';
import { toLocaleRoundedString } from '../../translations/utils';
import { preventNumberInputScrolling } from '../../shared/input-utils';
import EyeVisible from '../../icons/EyeVisible.svg?react';
import mikeSharedTheme, { mikePalette } from '../../shared/styles/mikeSharedTheme';

type QueryCriteriaRangeInputProps = {
  rangeMin: number; // Fixed min for the current attribute
  rangeMax: number; // Fixed max for the current attribute
  rangeQueryMin?: number; // Actual typed min value
  rangeQueryMax?: number; // Actual typed max value

  onQueryParameterChanged?: (param: string, value: number) => void;
  canPreviewQuery?: boolean;
  onPreviewQuery?: () => void;
};

// emulate gap, see https://coryrylan.com/blog/css-gap-space-with-flexbox
export const RangeCss = css`
  display: inline-flex;
  margin-left: -${mikeSharedTheme.spacing(1)};
  width: calc(100% + ${mikeSharedTheme.spacing(1)});
`;

export const RangeInputCss = css`
  &.MuiTextField-root {
    margin-left: ${mikeSharedTheme.spacing(1)};
  }
`;

export const ButtonIconCss = css`
  padding-top: ${mikeSharedTheme.spacing(1.5)};
`;

// eslint-disable-next-line react-refresh/only-export-components
export const iconStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main;
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `;
};

const PARAM_FROM = 'fromValue';
const PARAM_TO = 'toValue';

/**
 * Render input range input fields for a query criteria
 * @param props
 */
export const MmgQueryCriteriaRangeInput = (props: QueryCriteriaRangeInputProps) => {
  const {
    rangeMin,
    rangeMax,
    rangeQueryMin,
    rangeQueryMax,
    onQueryParameterChanged,
    canPreviewQuery,
    onPreviewQuery,
  } = props;

  const roundedRangeMax = toLocaleRoundedString(rangeMax, 3);
  const roundedRangeMin = toLocaleRoundedString(rangeMin, 3);

  const minPlaceHolder = '\u2265 ' + roundedRangeMin;
  const maxPlaceHolder = '\u2264 ' + roundedRangeMax;

  const callOnQueryParameterChanged = useCallback(
    (param: string, value: number) => {
      if (onQueryParameterChanged) {
        onQueryParameterChanged(param, value);
      }
    },
    [onQueryParameterChanged],
  );
  /**
   * Callback for keyup on range query min input.
   *
   * @param event
   */
  const onQueryMinChange = (event: React.ChangeEvent) => {
    event.preventDefault();

    const target = event.target as HTMLInputElement;
    const value = parseFloat(target.value);

    // use range min value if no value is given
    const newValue = !value && value !== 0 ? rangeMin : value;
    callOnQueryParameterChanged(PARAM_FROM, newValue);
  };

  /**
   * Callback for keyup on range query max input.
   *
   * @param event
   */
  const onQueryMaxChange = (event: React.ChangeEvent) => {
    event.preventDefault();

    const target = event.target as HTMLInputElement;
    const value = parseFloat(target.value);

    // use range max value if no value is given
    const newValue = !value && value !== 0 ? rangeMax : value;

    callOnQueryParameterChanged(PARAM_TO, newValue);
  };

  const basePropertries: TextFieldProps = {
    InputLabelProps: {
      shrink: true,
    },
    margin: 'normal',
    type: 'number',
    InputProps: {
      inputProps: { step: 'any' },
    },
    onWheel: preventNumberInputScrolling,
    css: RangeInputCss,
  };
  return (
    <section css={RangeCss}>
      <TextField
        {...basePropertries}
        id="query-min-value"
        label={t('MIN')}
        placeholder={minPlaceHolder}
        value={isNumber(rangeQueryMin) && rangeQueryMin !== rangeMin ? rangeQueryMin : ''}
        onChange={onQueryMinChange}
      />

      <TextField
        {...basePropertries}
        id="query-max-value"
        label={t('MAX')}
        placeholder={maxPlaceHolder}
        value={isNumber(rangeQueryMax) && rangeQueryMax !== rangeMax ? rangeQueryMax : ''}
        onChange={onQueryMaxChange}
      />
      {onPreviewQuery && (
        <button css={ButtonIconCss} onClick={onPreviewQuery} disabled={!canPreviewQuery}>
          <EyeVisible css={iconStyle(!canPreviewQuery)} />
        </button>
      )}
    </section>
  );
};
