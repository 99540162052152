import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { IWorkspaceMesh } from '../../models/IMeshes';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import { LinearProgress } from '@mui/material';
import { getMeshOperationPanelDescription } from '../../shared/panels/panel-utils';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { MmgConnectedMeshCreateInterpolationConfiguration } from './mesh-create-interpolation-configuration';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

const { hideOtherLayers, isLayerWorking } = LayerUtils;

/**
 * @name MmgConnectedMeshCreateInterpolationPanel
 * @summary Allows creating and configuring a new interpolation for a mesh.
 *
 */
export const MmgConnectedMeshCreateInterpolationPanel = () => {
  const { workspaceId, projectId, meshId } = useParams();
  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const mesh: IWorkspaceMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const meshDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId }),
  );

  const { goBackToReferrer } = useNavigateBack();

  const title = t('MESH_INTERPOLATION_CREATE_PANEL');

  /*   const onPanelExit = () => {
    goBackToReferrer();
  }; */

  // When an operation is sucessfully submitted we go back
  const onOperationSubmitSuccess = (execute: boolean) => {
    // Currently we leave the panel after submitting.
    // If in the future we want to stay in the panel, we would need to replace the current operation with the new one returned from api
    // Could maye redirect to edit panel instead, but that browser history gets replaced, so user would be able to go back to where the edit was initiated.
    //

    //if the operation was submitted to execute the interpolation, we go back to default tab.
    const omitHash = execute;
    goBackToReferrer(omitHash);
  };

  // todo hevo show/hide items should be as in mesh detail. This goes for all mesh related panels. Maybe a custom hook for the logic would be an idea?
  // Make sure the only item shown in the viewer is the mesh at hand when the mesh is loaded
  useEffect(
    () => {
      if (mesh && mesh.id) {
        hideOtherLayers(ELEMENT_CATEGORIES.MESH, mesh.id);
      }
    },
    [mesh],
  );

  const drawnMeshes = meshDrawnData ? [meshDrawnData] : [];
  const isMeshWorking = isLayerWorking(mesh, drawnMeshes);

  const description = getMeshOperationPanelDescription(mesh, drawnMeshes);

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <MmgPanelHeader panelTitle={title} panelDescription={description} />
        </MikeStickyPanelHeaderContainer>

        {isMeshWorking && <LinearProgress />}

        <MmgConnectedMeshCreateInterpolationConfiguration
          projectId={projectId}
          workspaceId={workspaceId}
          meshId={meshId}
          onOperationSubmitSuccess={onOperationSubmitSuccess}
        />
      </MikeStickyPanel>
    </>
  );
};
