import React, { useState } from 'react';

const MikeStickyPanelContent = (props) => {
  const [height, setHeight] = useState(0);

  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().top);
    }
  }, []);

  return (
    <div
      ref={measuredRef}
      style={{
        maxHeight: `calc(100vh - ${height}px`,
        overflow: 'auto',
      }}
    >
      {props.children && props.children}
    </div>
  );
};

export default MikeStickyPanelContent;
