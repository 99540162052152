import { IVtuPropertyBounds } from '../../models/IPropertyTableQuery';
import { IWorkspace } from '../../models/IWorkspaces';
import ProjectionManager from '../../managers/ProjectionManager';
import { IProjection } from '../../models/IProjections';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { store } from '../../store';
import MikeVisualizer from '../../MikeVisualizer';
import MikeVisualizerIO from '../../MikeVisualizer/lib/MikeVisualizerIO';
import { IViewerBounds } from '../../MikeVisualizer/lib/IMikeVisualizerModels';
const { resetCameraToBounds } = MikeVisualizer;

export const getVtpBounds = (vtp: string) => {
  const { _parseVtkDataXml } = MikeVisualizerIO;
  const parsedVtpData = _parseVtkDataXml(vtp);
  const bounds = parsedVtpData.getBounds(); // xMin, xMax, yMin, yMax, zMin, zMax
  return {
    minX: bounds[0],
    maxX: bounds[1],
    minY: bounds[2],
    maxY: bounds[3],
    minZ: 0,
    maxZ: 0,
    isValid: true,
  };
};

// Zoom to element. First add buffer if its area is too small. Buffer size depends on
// projection units, which is why the workspace is required, containing epsgCode, isLatLon etc.
export const zoomToElement = async (vtp: string, area: number, workspace: IWorkspace) => {
  const bounds: IVtuPropertyBounds = getVtpBounds(vtp);
  const checkedBounds = await bufferBounds(bounds, area, workspace);
  const formattedBounds = [
    checkedBounds.minX,
    checkedBounds.maxX,
    checkedBounds.minY,
    checkedBounds.maxY,
    0,
    0,
  ] as IViewerBounds;
  resetCameraToBounds(formattedBounds);
  store.dispatch({ type: EWorkspaceMeshActionType.CHECK_VIEW_BOUNDS, data: {workspaceId: workspace.id, wait: null} });
};

// If area of bounds is too small, return buffered bounds:
export const bufferBounds = async (
  bounds: IVtuPropertyBounds,
  area: number,
  workspace: IWorkspace,
): Promise<IVtuPropertyBounds> => {
  const checkArea = !area || area < 0 ? 1 : area;
  if (checkArea < 200) {
    const epsgNumber = workspace.epsgCode || -1;
    const projInfo = await getEpsgInfo(epsgNumber);
    const parsedWkt: IWktParserResult = await getParsedWkt(epsgNumber, projInfo.wkt);
    if (!projInfo || !parsedWkt.units) {
      console.error(`No meta data for epsg=${epsgNumber}`);
    }
    const unitIs = (str: string) => parsedWkt.units.toLowerCase().includes(str);
    let buffer = 1;
    if (unitIs('metre') || unitIs('meter')) {
      buffer = 10;
    } else if (unitIs('degree') || workspace.isLatLon) {
      buffer = 0.00025;
    } else if (!workspace.isLatLon) {
      buffer = 10; // assuming unit is metre or feet when not LatLon projection
    } else {
      console.error(`Buffer not supported for unit: "${parsedWkt.units}". Proceeding with buffer=${buffer}.`);
    }
    // Return buffered bounds:
    const bufferedBounds: IVtuPropertyBounds = {
      ...bounds,
      minX: bounds.minX - buffer,
      minY: bounds.minY - buffer,
      maxX: bounds.maxX + buffer,
      maxY: bounds.maxY + buffer,
    };
    return bufferedBounds;
  }
  return bounds;
};

// Fetch projection info from our own endpoint or get from cache:
const epsgInfo: Record<string | number, IProjection> = {};
const getEpsgInfo = async (epsgNumber: number) => {
  if (epsgInfo[epsgNumber]) {
    return epsgInfo[epsgNumber];
  }
  const projInfo = await ProjectionManager.getProjectionByEpsg(epsgNumber);
  epsgInfo[epsgNumber] = projInfo;
  return projInfo;
};

// Parse WKT string or get from cache:
const parsedWkts: Record<string | number, IWktParserResult> = {};
const getParsedWkt = async (epsgNumber: number, wkt: string) => {
  if (parsedWkts[epsgNumber]) {
    return parsedWkts[epsgNumber];
  }
  const wktParser: IWktParser = (await import('wkt-parser')).default;
  const parsed = wktParser(wkt);
  parsedWkts[epsgNumber] = parsed;
  return parsed;
};

type IWktParser = (wkt: string) => IWktParserResult;

interface IWktParserResult {
  AUTHORITY: { EPSG: string };
  AXIS: { Northing: string };
  GEOGCS: {
    name: string;
    // DATUM: {…},
    // PRIMEM: {…},
    // UNIT: {…},
    // AUTHORITY: {…}
  };
  PROJECTION: string;
  UNIT: {
    name: string;
    convert: number;
    // AUTHORITY: {…}
  };
  a: number;
  central_meridian: number;
  datumCode: string;
  ellps: string;
  false_easting: number;
  false_northing: number;
  k0: number;
  lat0: number;
  latitude_of_origin: number;
  long0: number;
  name: string;
  projName: string;
  rf: number;
  scale_factor: number;
  srsCode: string;
  to_meter: number;
  type: string;
  units: string;
  x0: number;
  y0: number;
}
