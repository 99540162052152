/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useCallback } from 'react';
import { isEqual } from 'lodash-es';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IContextualOperationDescriptions } from '../../../models/IOperationDescriptions';
import { MmgMeshOperationQueryConfiguration } from './mesh-operation-query-configuration';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { t } from '../../../translations/i18n';
import { Typography } from '@mui/material';
import { MmgParameterInfoWrapper } from '../../../shared/parameters/parameter-info-wrapper';
import { useGroupedAndSortedQueryConfigurations } from './useGroupedAndSortedQueryConfigurations';
import { useMount } from 'react-use';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../store/reducers';

type MeshOperationQueriesConfigurationsProps = {
  geometryQueries: Array<IWorkspaceQuery>;
  workspaceVariables: Array<IWorkspaceEnrichedVariable>;
  initialOperationConfigurations?: Array<IOperationConfiguration>;
  operationType: string;
  contextualOperationDescriptions: IContextualOperationDescriptions;
  onOperationConfigurationChanged?: (operationConfigurations: Array<IOperationConfiguration>) => void;
  dirtyParentConfiguration?: IOperationConfiguration;
};

export const HeaderStyle = css`
  &.MuiTypography-subtitle2 {
    height: 2.5rem;
    line-height: 2.5rem;
  }
`;

/**
 * @name MmgConnectedMeshOperationQueriesConfigurations
 * @summary Generic component that given a list of saved queries and mesh operation descriptions, allows configuring operations for each of them.
 * Will supply the complete list of updated configuration objects via a callback.
 * NB: Make sure to re-mount when operationType changes, because configurations, queries etc
 * are only fetched on mount.
 * @param props
 */
export const MmgConnectedMeshOperationQueriesConfigurations = (props: MeshOperationQueriesConfigurationsProps) => {
  //check geomtryQueries
  const {
    geometryQueries,
    workspaceVariables,
    initialOperationConfigurations,
    contextualOperationDescriptions,
    operationType,
    onOperationConfigurationChanged,
    dirtyParentConfiguration,
  } = props;

  const isGroupingSelections: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceReducer.isGroupingSelections,
  );

  const [childConfigurations, setChildConfigurations] = useState([]); // Array with current configurations. This will be sent higher up the component chain and used for submission.

  //here we get the included queries
  const getInitialIncludedQueries = useCallback(
    (operationConfigurations) =>
      geometryQueries.reduce((obj, { id }) => {
        const included = operationConfigurations
          ? operationConfigurations.findIndex(({ inputIds }) => inputIds.indexOf(id) !== -1) !== -1
          : false;
        obj[id] = included;
        return obj;
      }, {}) as {
        [queryId: string]: boolean;
      },
    [geometryQueries],
  );

  // included queries to match the configurations being passed in
  const [includedGeometryQueries, setIncludedGeometryQueries] = useState(
    getInitialIncludedQueries(initialOperationConfigurations),
  );

  const onQueryConfigurationsChanged = (queryId: string) => (operationConfiguration: IOperationConfiguration) => {
    const nextChildConfigurations = childConfigurations.filter(({ inputIds }) => inputIds.indexOf(queryId) === -1);

    nextChildConfigurations.push({
      ...operationConfiguration,
    });
    setChildConfigurations(nextChildConfigurations);
    callOnOperationConfigurationChanged(includedGeometryQueries, nextChildConfigurations);
  };

  const onQueryIncludedChanged = (queryId: string, isIncluded: boolean) => {
    const included = { ...includedGeometryQueries, [queryId]: isIncluded };
    setIncludedGeometryQueries(included);
    callOnOperationConfigurationChanged(included, childConfigurations);
  };

  const callOnOperationConfigurationChanged = (includedQueries, configurations) => {
    if (onOperationConfigurationChanged) {
      const includedConfigurations = configurations.filter(
        ({ inputIds }) => inputIds && inputIds.length > 0 && includedQueries[inputIds[0]],
      );
      // only call back if something has actually changed
      if (!isEqual(initialOperationConfigurations, includedConfigurations)) {
        onOperationConfigurationChanged(includedConfigurations);
      }
    }
  };

  useMount(() => {
    const nextChildConfigurations = initialOperationConfigurations.filter(({ operationType: configOperationType }) => {
      return configOperationType === operationType;
    });
    const nextIncluded = getInitialIncludedQueries(nextChildConfigurations);
    setChildConfigurations(nextChildConfigurations);
    setIncludedGeometryQueries(nextIncluded);
    callOnOperationConfigurationChanged(nextIncluded, nextChildConfigurations);
  });

  const groupedAndSortedQueryConfigurations = useGroupedAndSortedQueryConfigurations(
    geometryQueries,
    childConfigurations,
    contextualOperationDescriptions,
    operationType,
  );

  const configurationKeys = Object.keys(groupedAndSortedQueryConfigurations);

  return (
    configurationKeys &&
    configurationKeys.length > 0 && (
      <>
        <MmgParameterInfoWrapper infoText={t('MESH_OPERATION_GEOMETRY_QUERIES_INFO')}>
          <Typography variant="subtitle2" noWrap css={HeaderStyle}>
            {t('MESH_OPERATION_GEOMETRY_QUERIES_TITEL')}
          </Typography>
        </MmgParameterInfoWrapper>
        {isGroupingSelections ? (
          <CircularProgress />
        ) : (
          configurationKeys.map((queryId) => {
            const { query, queryConfiguration, queryOperationDescriptions } = groupedAndSortedQueryConfigurations[
              queryId
            ];

            return (
              <MmgMeshOperationQueryConfiguration
                key={queryId}
                geometryQuery={query}
                workspaceVariables={workspaceVariables}
                initialQueryConfiguration={queryConfiguration}
                queryOperationDescriptions={queryOperationDescriptions}
                isQueryIncluded={includedGeometryQueries[queryId]}
                onOperationConfigurationChanged={onQueryConfigurationsChanged(query.id)}
                onQueryIncludedChanged={onQueryIncludedChanged}
                dirtyParentConfiguration={dirtyParentConfiguration}
              />
            );
          })
        )}
      </>
    )
  );
};
