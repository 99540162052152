import { t } from '../../translations/i18n';
import { store } from '../../store/store';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceGeometry, IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import WorkspaceGeometriesManager from '../../managers/WorkspaceGeometriesManager';
import { MmgConnectedGeometryListItem } from '../../geometries/list/geometry-list-item';
import { MmgGeometryListItemEdit } from '../../geometries/list/geometry-list-item-edit';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import { useDispatch, useSelector } from 'react-redux';
import { addEditingGeometry, removeEditingGeometry } from '../../store/actions/editingItems';
import { IGlobalState } from '../../store/reducers';
import { useNavigate } from 'react-router-dom';

const { setActiveLayer } = LayerUtils;

type GeometryListProps = {
  workspaceId: string;
  projectId: string;
  geometries: Array<IWorkspaceEnrichedGeometry>;
  hiddenGeometries: Array<string>;
  drawnGeometries: Array<IDrawnDataItem>;
};

/**
 * @name MmgGeometryList
 * @summary Given an array of geometries, renders them and sets up their interactions.
 *
 * @param props
 */
export const MmgGeometryList = (props: GeometryListProps) => {
  const navigate = useNavigate();
  const { workspaceId, projectId, geometries, hiddenGeometries, drawnGeometries } = props;
  const dispatch = useDispatch();
  const editingGeometryIds: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceDataReducer.editingGeometryIds,
  );

  /**
   * Callback for when geometry open is pressed.
   * Opens the geometry panel for it.
   *
   * @param geometryId
   */
  const onGeometryOpenPressed = (geometryId: string) => {
    setActiveLayer(ELEMENT_CATEGORIES.GEOMETRY, geometryId, workspaceId, projectId, navigate);
  };

  /**
   * Callback for when a geometry edit button is pressed.
   *
   * @param geometryId
   */
  const onGeometryEdit = (geometryId: string) => {
    dispatch(addEditingGeometry(geometryId));
  };

  /**
   * Callback for when a geometry updates.
   * Sends an api request and displays relevant toasts.

   * @param geometry
   * @param geometry.id
   * @param geometry.name
   */
  const onGeometryUpdate = ({ id, name }: IWorkspaceGeometry) => {
    dispatch(removeEditingGeometry(id));

    WorkspaceGeometriesManager.renameGeometry(workspaceId, id, name)
      .then(() => {
        store.dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('GEOMETRY_UPDATED_SUCCESSFULLY'),
          },
        });
      })
      .catch((error) => {
        dispatch(addEditingGeometry(id));

        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: {
            text: t('GEOMETRY_UPDATE_FAILED'),
          },
        });

        throw error;
      });
  };

  return (
    <>
      {geometries.map((geometry) => {
        const geometryIsEditing = editingGeometryIds.includes(geometry.id);

        if (geometryIsEditing) {
          return <MmgGeometryListItemEdit key={geometry.id} geometry={geometry} onGeometryUpdate={onGeometryUpdate} />;
        }

        return (
          <MmgConnectedGeometryListItem
            key={geometry.id}
            geometry={geometry}
            hiddenGeometries={hiddenGeometries}
            drawnGeometries={drawnGeometries}
            onGeometryEdit={onGeometryEdit}
            onGeometryOpenPressed={onGeometryOpenPressed}
          />
        );
      })}
    </>
  );
};
