/**
 * Exposes utility methods for mapping operations.
 *
 * @module OperationMappingUtils
 * @version 2.0.0
 */
import { camelCase, upperFirst, lowerFirst } from 'lodash-es';

import {
  IOperationDescription,
  IContextualOperationDescriptions,
  IParameterDescription,
} from '../models/IOperationDescriptions';
import { IOperationDescriptionApi, IParameterDescriptionApi } from '../models/IOperationDescriptionsApi';
import { IOperationApi, IOperationDefinitionApi } from '../models/IOperationApi';
import { IOperationConfiguration, IOperationDefinition } from '../models/IOperations';
import { IQueryDefinitionApi } from '../models/IQueryDefinitions';
import { logger } from './http-utils';

// todo hevo split this into onefile that handles descriptions and another handling the operations

/**
 * Maps an array of operation descriptions as received from the api to IOperationDescription Object.
 *
 * @note ! Careful there cowboys, if multiple operation descriptions of a type exist in `childOperationDescriptions`, the last one (array order) will be used.
 *
 * @param apiOperationDescriptions
 */
const mapOperationDescriptions = (
  apiOperationDescriptions: Array<IOperationDescriptionApi>,
): {
  [operationKey: string]: IOperationDescription;
} => {
  if (!apiOperationDescriptions) {
    return null;
  }

  const operationDescriptions = apiOperationDescriptions.reduce((acc, cur) => {
    const {
      operationType: apiOperationType,
      resultType,
      parameterDescriptions: apiParameterDescriptions,
      allowedInputIds,
      childOperationDescriptions,
      ...rest
    } = cur;

    const operationType = lowerFirst(apiOperationType); // api returns type in PascalCase so we transform it

    const parameterDescriptions: {
      [parameterKey: string]: IParameterDescription;
    } = self.mapParameterDescriptions(apiParameterDescriptions);

    return {
      ...acc,
      [operationType]: {
        operationType,
        resultType,
        ...(parameterDescriptions ? { parameterDescriptions } : {}),
        ...(allowedInputIds ? { allowedInputIds } : {}),
        ...(childOperationDescriptions
          ? {
              childOperationDescriptions: self.mapOperationDescriptions(childOperationDescriptions),
            }
          : {}),
        ...rest,
      },
    };
  }, {});

  return operationDescriptions;
};

/**
 * Maps an array of parameter descriptions as received from the api to IParameterDescription Object
 *
 * @param apiParameterDescriptions
 */
const mapParameterDescriptions = (
  apiParameterDescriptions: Array<IParameterDescriptionApi>,
): { [parameterKey: string]: IParameterDescription } => {
  if (!apiParameterDescriptions) {
    return null;
  }

  const parameterDescriptions = apiParameterDescriptions.reduce((descriptions, cur, index) => {
    // Want to be robust in case api should include null values in the array (has occured)
    if (!cur) {
      logger.warn('Unexpected null parameterdescription', apiParameterDescriptions);
      return descriptions;
    }

    const { name, ...params } = cur;

    const key = camelCase(name); // api returns name in PascalCase so we transform it

    return {
      ...descriptions,
      [key]: {
        order: index,
        name,
        ...params,
      },
    };
  }, {});

  return parameterDescriptions;
};

/**
 * Maps an array of api operation descriptions to contextual operation descriptions.
 * Contextual operations descriptions are the preferred model for UI rendering, typically reducing work done in the component level.
 * In practical terms, contextual operations group operation descriptions by allowedInputIds.
 *
 * This is a different type of mapping and is only relevant to createMeshOperations and interpolationOperations, because of contextuality.
 * The other important difference between this and `mapOperationDescriptions` is that operations are not unique for a given type, i.e. it is expected that there can be multiple TriangleOperation descriptions.
 *
 * @param apiOperationDescriptions
 */
const mapOperationDescriptionsToContextualDescriptions = (
  apiOperationDescriptions: Array<IOperationDescriptionApi>,
): IContextualOperationDescriptions => {
  if (!apiOperationDescriptions) {
    return null;
  }

  return apiOperationDescriptions.reduce((acc, cur) => {
    const {
      allowedInputIds,
      operationType: apiOperationType,
      parameterDescriptions,
      childOperationDescriptions,
      ...rest
    } = cur;
    const nextAcc = { ...acc };

    if (!allowedInputIds || !allowedInputIds.length) {
      return nextAcc;
    }
    const operationType = lowerFirst(apiOperationType); // api returns type in PascalCase so we transform it

    for (const inputItemId of allowedInputIds) {
      const operationDescriptionUi = {
        operationType,
        ...(parameterDescriptions
          ? {
              parameterDescriptions: self.mapParameterDescriptions(parameterDescriptions),
            }
          : {}),
        ...(allowedInputIds ? { inputIds: allowedInputIds } : {}),
        ...(childOperationDescriptions && childOperationDescriptions.length
          ? {
              childOperationDescriptions: self.mapOperationDescriptionsToContextualDescriptions(
                childOperationDescriptions,
              ),
            }
          : {}),
        ...rest,
      };

      nextAcc[inputItemId] = nextAcc[inputItemId]
        ? {
            ...nextAcc[inputItemId],
            [operationType]: operationDescriptionUi,
          }
        : { [operationType]: operationDescriptionUi };
    }

    return nextAcc;
  }, {});
};

/**
 * Maps an operation configuration (and childOperations) to API operation.
 * This is needed generically for saving / executing operations of any type.
 *
 * @param operationConfiguration
 */
const mapOperationConfigurationToOperationApi = (operationConfiguration: IOperationConfiguration): IOperationApi => {
  if (!operationConfiguration) {
    return null;
  }

  const {
    inputIds,
    queryDefinition,
    childOperations,
    type,
    operationType,
    parameters,
    postOperation,
  } = operationConfiguration;

  return {
    operationType: upperFirst(operationType),
    operationDefinition: {
      type: upperFirst(type),
      ...parameters,
    },
    ...(inputIds && { inputIds }),
    ...(childOperations && {
      childOperations: childOperations.map((childOperationConfiguration) =>
        self.mapOperationConfigurationToOperationApi(childOperationConfiguration),
      ),
    }),
    ...(postOperation && {
      postOperation: self.mapOperationConfigurationToOperationApi(postOperation),
    }),
    ...(queryDefinition && { queryDefinition }),
  };
};

/**
 * Maps an operation received from the API to the operation configuration used by the UI.
 *
 * @param operationApi
 */
const mapOperationApiToOperationConfiguration = (operationApi: IOperationApi): IOperationConfiguration => {
  if (!operationApi) {
    return null;
  }

  const {
    operationType: apiOperationType,
    childOperations: childOperationsApi,
    operationDefinition: operationDefinitionApi,
    queryDefinition: queryDefinitionApi,
    parameterDescriptions: apiParameterDescriptions,
    postOperation: apiPostOperation,
    ...rest
  } = operationApi;

  const type = apiOperationType;

  const operationType = lowerFirst(apiOperationType); // api returns type in PascalCase so we transform it

  const postOperation = apiPostOperation && self.mapOperationApiToOperationConfiguration(apiPostOperation);

  const childOperations = childOperationsApi
    ? childOperationsApi.map((child) => {
        return self.mapOperationApiToOperationConfiguration(child);
      })
    : [];

  const operationDefinition = self.mapOperationDefinitionApiToOperationDefinition(operationDefinitionApi);

  const parameterDescriptions: {
    [parameterKey: string]: IParameterDescription;
  } = self.mapParameterDescriptions(apiParameterDescriptions);

  const queryDefinition = self.mapQueryDefinitionApiToQueryDefinition(queryDefinitionApi);

  const operation: IOperationConfiguration = {
    ...rest,
    type,
    postOperation,
    operationType,
    operationDefinition,
    queryDefinition,
    childOperations,
    parameterDescriptions,
    parameters: operationDefinition ? operationDefinition.parameters : null, // todo hevo keep this for now to not have to refactor all over
  };

  return operation;
};

/**
 * Maps an operation definition received from the API to the operation definition used by the UI.
 *
 * @param operationDefinitionApi
 */
const mapOperationDefinitionApiToOperationDefinition = (
  operationDefinitionApi: IOperationDefinitionApi,
): IOperationDefinition => {
  if (!operationDefinitionApi) {
    return null;
  }

  const {
    type,
    operationResultType,
    operationSupportsQuery, // send by api, ignored by ui. Will use supportQuery from operation description instead
    queryDefinition, // send by api, ignored by ui. Will use queryDefinition from operation configuration level instead
    ...params
  } = operationDefinitionApi;

  const operationDefinition: IOperationDefinition = {
    type,
    operationResultType,
    parameters: params,
  };
  return operationDefinition;
};

/**
 * Maps an query definition received from the API to the query definition used by the UI.
 *
 * @param queryDefinitionApi
 */
const mapQueryDefinitionApiToQueryDefinition = (queryDefinitionApi: IQueryDefinitionApi): IOperationDefinition => {
  if (!queryDefinitionApi) {
    return null;
  }

  return { ...queryDefinitionApi }; // todo hevo handle this
};

const self = {
  mapOperationDescriptions,
  mapParameterDescriptions,
  mapOperationDescriptionsToContextualDescriptions,

  mapOperationConfigurationToOperationApi,
  mapOperationApiToOperationConfiguration,
  mapOperationDefinitionApiToOperationDefinition,
  mapQueryDefinitionApiToQueryDefinition,
};

export default self;
