/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CircularProgress, TextField } from '@mui/material';
import { translateWithPrefix } from '../translations/utils';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';


const SelectWrapperStyle = css`
  margin-bottom: ${mikeSharedTheme.spacing(
    1,
  )}; /* todo: dan this is needed to align with lit-parameters. maybe we can remove when refactoring parameters to React (& we use MUI for all inputs) */
`;

type OperationSelectorProps = {
  operationKeys: Array<string>;
  operationsLoading: boolean;
  operationsLoadingFailed: boolean;
  onOperationChanged: (event) => void;
  selectedOperationKey?: string;
  label: string;
  placeholder: string;
};

/**
 * Renders a drop down for selection operations
 * @param props
 */
export const MmgOperationSelector = (props: OperationSelectorProps) => {
  const { operationKeys, operationsLoading, selectedOperationKey, onOperationChanged, label, placeholder } = props;

  if (operationsLoading) {
    return <CircularProgress />;
  }

  const selectedValue = selectedOperationKey || '';
  const operationKeysAvailable = Boolean(operationKeys && operationKeys.length);

  return (
    <div css={SelectWrapperStyle}>
      {operationKeysAvailable && (
        <TextField
          id="select-operation"
          select
          label={label}
          value={selectedValue}
          onChange={onOperationChanged}
          fullWidth
          SelectProps={{
            displayEmpty: true,
          }}
          InputLabelProps={{ shrink: true }}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {operationKeys.map((operationKey) => {
            const key = operationKey.toUpperCase();
            const displayName = translateWithPrefix('OP', operationKey);

            const notSupported = !displayName;

            return (
              !notSupported && (
                <option key={key} value={operationKey}>
                  {displayName}
                </option>
              )
            );
          })}
        </TextField>
      )}
    </div>
  );
};
