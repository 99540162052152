import { useState, ReactNode } from 'react';
import { t } from '../../translations/i18n';
import { MmgGroup } from '../../shared/groups/group';
import { MmgSpatialSelection } from './spatial-selection';
import { MmgSpatialSelectionEdit } from './spatial-selection-edit';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import WorkspaceQueryManager from '../../managers/WorkspaceQueryManager';
import { IWorkspaceQuery } from '../../models/IQueries';
import SpatialSelectionUtils from './spatial-selection-utils';
import { useDispatch, useSelector } from 'react-redux';
import { EWorkspaceQueryActionType } from '../../store/actions/WorkspaceQueryActionType';
import { IGlobalState } from '../../store/reducers';
const { deleteSelection } = SpatialSelectionUtils;

type MmgSpatialSelectionListProps = {
  createSelectionButton?: ReactNode;
  selections?: void | Array<IWorkspaceQuery>;
  workspaceId: string;
};

/**
 * @name MmgSpatialSelectionList
 * @summary Lists spatial selections, handles callbacks to edit or delete selections.
 *
 * @param props
 */
export const MmgSpatialSelectionList = (props: MmgSpatialSelectionListProps) => {
  const dispatch = useDispatch();
  const shownSelectionIds = useSelector((state: IGlobalState) => state.WorkspaceQueryReducer.shownSelectionIds);
  const { createSelectionButton, selections, workspaceId } = props;
  const [selectionsEdited, setSelectionsEdited] = useState([]);

  /**
   * Callback for when a selection updates.
   * Sends an api request and displays relevant toasts.
   *
   * @param query
   * @param query.id
   * @param query.name
   */
  const onSelectionUpdate = ({ id, name }: IWorkspaceQuery) => {
    setSelectionsEdited(selectionsEdited.filter((selectionId) => selectionId !== id));

    WorkspaceQueryManager.putSpatialQuery(workspaceId, id, name)
      .then(() => {
        dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('SELECTION_UPDATED_SUCCESSFULLY'),
          },
        });
      })
      .catch((error) => {
        setSelectionsEdited([...selectionsEdited, id]);

        dispatch({
          type: 'toast/ADD/ERROR',
          toast: {
            text: t('SELECTION_UPDATE_FAILED'),
          },
        });

        throw error;
      });
  };

  /**
   * Switches state of selection to 'edit'.
   *
   * @param selectionId
   */
  const onSelectionEdit = (selectionId: string) => {
    setSelectionsEdited([...selectionsEdited, selectionId]);
  };

  /**
   * Callback for when a selection is deleted.
   * Sends an api request and displays relevant toasts.
   * Removes data from visualization.
   *
   * @param selectionId
   */
  const onSelectionDelete = (selectionId: string) => {
    deleteSelection(workspaceId, selectionId).then(() => {
      dispatch({ type: EWorkspaceQueryActionType.SHOWN_SELECTION_GEOMETRY_REMOVE, data: selectionId });
    });
  };

  return (
    //check this component selection bug
    <MmgGroup groupName={t('SELECTION', 2)}>
      {selections && selections.length ? (
        selections.map(({ name, id }, index) => {
          const isEditing = selectionsEdited.find((selectionId) => selectionId === id);

          const isShown = shownSelectionIds.includes(id);

          if (isEditing) {
            return (
              <MmgSpatialSelectionEdit
                key={`selection-edit-${id}`}
                selectionName={name}
                selectionId={id}
                onSelectionUpdate={onSelectionUpdate}
              />
            );
          }

          return (
            <MmgSpatialSelection
              isSelection={true}
              key={`selection-${id}`}
              selectionName={name}
              selectionId={id}
              isShown={isShown}
              onSelectionEdit={onSelectionEdit}
              onSelectionDelete={onSelectionDelete}
              selection={selections[index]}
            />
          );
        })
      ) : (
        <MmgPanelSubsection>
          {t('SELECTION_EMPTY_LIST')}
        </MmgPanelSubsection>
      )}

      {createSelectionButton && <MmgPanelSubsection>{createSelectionButton}</MmgPanelSubsection>}
    </MmgGroup>
  );
};
