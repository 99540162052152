import { Location } from 'history';
import { useLocation, useNavigate } from 'react-router-dom';

export interface ILocationState {
  referrer: Location;
}
/**
 * Custom hook that allows navigating back to referrer, assuming that the referrer is set in the location state.
 * If referrer is not set, it goes back to previous location defined by browser history.
 *
 * If omitHash is specified, any hash-tag specified by the referrer will be omitted
 *
 */
export const useNavigateBack = (): {
  goBackToReferrer: (omitHash?: boolean) => void;
  referringLocation: Location;
} => {
  const location = useLocation();
  const navigate = useNavigate();

  const { referrer } = location.state || {};
  /**
   * @param omitHash If true any hash-tag specified by the referrer will be omitted
   */
  const goBackToReferrer = (omitHash = false) => {
    if (referrer) {
      navigate({
        ...referrer,
        // eslint-disable-next-line no-extra-boolean-cast
        hash: !Boolean(omitHash) ? referrer.hash : '',
      });
    } else {
      navigate(-1);
    }
  };

  return {
    goBackToReferrer,
    referringLocation: referrer,
  };
};
