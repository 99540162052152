import { orderBy, lowerFirst } from 'lodash-es';
import { translateWithPrefix, translateWithPrefixAndSuffix } from '../../translations/utils';

import {
  IOperationMetadata,
  OPERATION_STATES,
  EOperationStates,
  IOperationConfiguration,
  OPERATION_DEFINITION_TYPES,
  IItemOperationState,
} from '../../models/IOperations';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { IWorkspaceEnrichedDataItem } from '../../models/IWorkspaceData';
import { IOperationDescription } from '../../models/IOperationDescriptions';
import { IWorkspaceQuery } from '../../models/IQueries';

interface IItemOperationMetadata {
  message?: string;
  state?: EOperationStates;

  conflictingState?: IItemOperationState;
}

const OPERATION_MSG_CODE_PREFIX = 'OP_MSG_CODE';
const OPERATION_PREFIX = 'OP';

/**
 * Attempts to translate an operation message:
 * - if the type should be included
 *  - tries to translate based on operationType
 *  - tried to translate based on resultType
 * - otherwise, tries to translate only using state, if state available.
 *
 * @param operation
 * @param includeType
 */
export const translateOperationMessage = (operation: IOperationMetadata, includeType = false) => {
  if (!operation) {
    return '';
  }
  const operationState = operation.state;
  const getFallbackTranslation = () => (operationState ? translateWithPrefix(OPERATION_PREFIX, operationState) : '');

  if (!includeType) {
    return getFallbackTranslation();
  }

  const { resultType, operationType } = operation;

  const translationWithOperationType = translateWithPrefixAndSuffix(
    OPERATION_PREFIX,
    operationState,
    operationType,
    true,
  );

  if (translationWithOperationType) {
    return translationWithOperationType;
  }

  const translationWithResultType = translateWithPrefixAndSuffix(OPERATION_PREFIX, operationState, resultType, true);

  if (translationWithResultType) {
    return translationWithResultType;
  }

  return getFallbackTranslation();
};

/**
 * Gets the message of a given operation depending on the operation state.
 *
 * Rules:
 *  - some operations return a translated state, skipping the message / code altogether
 *  - the rest return the translated message.code
 *  - if message.code is not available or translatable, then message.message is returned instead.
 * todo hevo should it be possible to bypass this logic when used for an operation
 * @param operation
 * @param includeType If true inforamtion on the operation name (with fallback to resulttype) will be included if available.
 */
export const getMessageFromOperation = (operation: IOperationMetadata, includeType = false): string => {
  if (!operation) {
    return '';
  }

  const operationMessage = operation.latestMessage;

  switch (operation.state) {
    case OPERATION_STATES.DRAFT:
    case OPERATION_STATES.SUCCEEDED:
    case OPERATION_STATES.PROCESSING:
    case OPERATION_STATES.SCHEDULED:
    case OPERATION_STATES.OUTDATED:
      return self.translateOperationMessage(operation, includeType);

    default: {
      if (operationMessage) {
        const messageCodeTranslation = operationMessage.code
          ? translateWithPrefix(OPERATION_MSG_CODE_PREFIX, operationMessage.code, true)
          : '';

        return messageCodeTranslation || operationMessage.message;
      }

      return self.translateOperationMessage(operation, includeType);
    }
  }
};

/**
 * Gets the state of a given operation.
 *
 * @param operation
 */
export const getStateFromOperation = (operation: IOperationMetadata): EOperationStates => {
  if (!operation) {
    return null;
  }

  if (self.isOperationOutdated(operation)) {
    return OPERATION_STATES.OUTDATED;
  }

  return operation.state || null;
};

/**
 * Gets the state of a given mesh operation.
 * Similar to `getStateFromOperation`, but takes into account the conflicting operation.
 *
 * @param createMeshOperation
 * @param conflictingOperation
 */
export const getMeshStateFromOperation = (
  createMeshOperation: IOperationMetadata,
  conflictingOperation: IOperationMetadata,
): EOperationStates => {
  if (!createMeshOperation && !conflictingOperation) {
    return null;
  }

  const operationForState = self.getMeshOperationForState(createMeshOperation, conflictingOperation);

  if (!operationForState) {
    return null;
  }

  if (
    operationForState !== createMeshOperation &&
    (operationForState.state === OPERATION_STATES.FAILED || self.isOperationOutdated(operationForState))
  ) {
    return OPERATION_STATES.CONFLICTING;
  }

  return self.getStateFromOperation(operationForState);
};

/**
 * Gets the operation that determines the state of a given mesh .
 * Similar to `getStateFromOperation`, but takes into account the conflicting operation.
 *
 * @param createMeshOperation
 * @param conflictingOperation
 */
export const getMeshOperationForState = (
  createMeshOperation: IOperationMetadata,
  conflictingOperation: IOperationMetadata,
): IOperationMetadata => {
  if (!createMeshOperation && !conflictingOperation) {
    return null;
  }

  // Not expected to happen, but just in case
  if (!createMeshOperation) {
    return conflictingOperation || null;
  }

  // If no other operation just use the createMeshOperation
  if (!conflictingOperation || conflictingOperation.operationType === createMeshOperation.operationType) {
    return createMeshOperation;
  }

  // If conflicting operation is DRAFT/SUCCEED, it does not interfere with the state
  if (
    conflictingOperation.state === OPERATION_STATES.DRAFT ||
    conflictingOperation.state === OPERATION_STATES.SUCCEEDED
  ) {
    return createMeshOperation;
  }

  // state will be determined by the most recent of the two
  const latestOperation = self.getLatestOperation([createMeshOperation, conflictingOperation]);

  return latestOperation;
};

/**
 *
 * Get the translated name of a given oepration
 *
 * @param operation
 */
export const getOperationName = (operation: IOperationMetadata): string => {
  if (!operation || !operation.operationType) {
    return '';
  }
  const name = translateWithPrefixAndSuffix('OP', 'NAME', operation.operationType);
  return name;
};

/**
 * Given an operation corresponding to an item (Geometry or Variable or Operation),
 * gets relevant operation metadata corresponding.
 * The passed-in operation is assumed to be the operation corresponding to the last state of that item.
 * @see getLatestOperation
 *
 * For now, the following metadata is deducted / considered relevant:
 * - state
 * - message
 *
 * @param operation
 */
export const getItemOperationMetadata = (operation: IOperationMetadata): IItemOperationMetadata | null => {
  if (!operation) {
    return null;
  }

  return {
    message: self.getMessageFromOperation(operation, true),
    state: self.getStateFromOperation(operation),
  };
};

/**
 * Similar to `getItemOperationMetadata`, but calling mesh-specific methods.
 *
 * The passed-in createMeshOperation is assumed to be the last CreateMeshoperation of that mesh.
 * The passed-in currentOperations are assumed to be all operations applied to the mesh, and not being marked superseded
 * The passed-in currentOperations might contain the createmeshOperation, but does not have to
 *
 * @see getItemOperationMetadata
 *
 * @param lastCreateMeshOperation
 * @param currentOperations
 */
export const getMeshItemOperationMetadata = (
  lastCreateMeshOperation: IOperationMetadata,
  currentOperations: Array<IOperationMetadata>,
): IItemOperationMetadata | null => {
  if (!lastCreateMeshOperation && (!currentOperations || currentOperations.length === 0)) {
    return null;
  }

  const conflictingOperation = self.getConflictingMeshOperationForState(lastCreateMeshOperation, currentOperations);

  // If no conflicting operation just use the create mesh operation
  if (!conflictingOperation) {
    return {
      message: self.getMessageFromOperation(lastCreateMeshOperation, true),
      state: self.getStateFromOperation(lastCreateMeshOperation),
      conflictingState: null,
    };
  }

  const conflictingState: IItemOperationMetadata = {
    message: self.getMessageFromOperation(conflictingOperation, true),
    state: self.getStateFromOperation(conflictingOperation),
  };

  const state = self.getMeshStateFromOperation(lastCreateMeshOperation, conflictingOperation);

  // if state is conflicting ot no create mesh operation use the conflicting operation even if not the latest. Otherwise use the latest
  const operationForMessage =
    state === OPERATION_STATES.CONFLICTING || !lastCreateMeshOperation
      ? conflictingOperation
      : self.getLatestOperation([lastCreateMeshOperation, conflictingOperation]);

  return {
    message: self.getMessageFromOperation(operationForMessage, true),
    state,
    conflictingState,
  };
};

/**
 * Gets the operation that defines the state of operations applied to the mesh (in addition to the createmesh operation itself)
 * @param createMeshOperation
 * @param currentOperations
 */
export const getConflictingMeshOperationForState = (
  createMeshOperation: IOperationMetadata,
  currentOperations: Array<IOperationMetadata>,
): IOperationMetadata | null => {
  if (!currentOperations || !currentOperations.length) {
    return null;
  }

  // do not consider the operation itself
  const appliedOperations = createMeshOperation
    ? currentOperations.filter(({ id }) => {
        return id !== createMeshOperation.id;
      })
    : currentOperations;

  if (!appliedOperations || !appliedOperations.length) {
    return null;
  }

  const latestAppliedOperation = self.getLatestOperation(appliedOperations);

  // Not expected to happen, but could in case the item was created without executing an operation (eg when duplicating)
  if (!createMeshOperation) {
    return latestAppliedOperation;
  }

  // always return the latest if it is in progress or failed
  if (
    latestAppliedOperation.state !== OPERATION_STATES.DRAFT &&
    latestAppliedOperation.state !== OPERATION_STATES.SUCCEEDED
  ) {
    return latestAppliedOperation;
  }

  // search for failed interpolations even if they are not the latest
  const failedInterpolations = appliedOperations.filter((operation) => {
    return self.isInterpolationOperation(operation.operationType) && operation.state === OPERATION_STATES.FAILED;
  });

  // if any failed interpolations use the latest of these
  if (failedInterpolations && failedInterpolations.length) {
    return self.getLatestOperation(failedInterpolations);
  }

  // search for outdated interpolations even if they are not the latest
  const outdatedInterpolations = appliedOperations.filter((operation) => {
    return self.isInterpolationOperation(operation.operationType) && self.isOperationOutdated(operation);
  });

  // if any outdated interpolations use the latest of these
  if (outdatedInterpolations && outdatedInterpolations.length) {
    return self.getLatestOperation(outdatedInterpolations);
  }

  return null;
};

/**
 * Given a collection of operations for an item id (Geometry or Variable or Mesh), returns the latest operation.
 *
 * @param operationList
 */
export const getLatestOperation = (operationList: Array<IOperationMetadata>): IOperationMetadata | null => {
  if (!operationList || !operationList.length) {
    return null;
  }

  // if there is only one item, just return that one.
  if (operationList.length === 1) {
    return operationList[0];
  }

  const nonFinalizedOperations = operationList.filter(({ endExecuting }) => !endExecuting);
  const finalizedOperations = operationList.filter(({ endExecuting }) => endExecuting);

  // If there are any non-finalized operations, they will be prioritized over finalized operations.
  // Finalized operations are not relevant if non-finalized are present.
  // In both cases, they will be sorted chronologically (by 'created' and 'endExecuting', respectively).
  const operationsByDate: Array<IOperationMetadata> = nonFinalizedOperations.length
    ? orderBy(nonFinalizedOperations, ['created'], ['desc'])
    : orderBy(finalizedOperations, ['endExecuting'], ['desc']);

  return operationsByDate[0];
};

/**
 * Returns true if the operation is outdated.
 * @param operation
 */
export const isOperationOutdated = (operation: IOperationMetadata): boolean => {
  if (!operation || !operation.state) {
    return false;
  }

  return operation.state === OPERATION_STATES.OUTDATED;
};

/**
 * Filters the list of operations, keeping only those containing the provided outputId in their list of outputIds.
 *
 * @param operations
 * @param outputId
 */
export const filterOperationsByOutputId = (
  operations: Array<IOperationMetadata>,
  outputId: string,
): Array<IOperationMetadata> => {
  if (!operations || !outputId) {
    return [];
  }

  return operations.filter(({ outputIds }) => outputIds && outputIds.indexOf(outputId) !== -1);
};

/**
 * Filters the list of operations, keeping only those containing the provided inputId in their list of inputIds.
 *
 * @param operations
 * @param inputId
 */
export const filterOperationsByInputId = (
  operations: Array<IOperationMetadata>,
  inputId: string,
): Array<IOperationMetadata> => {
  if (!operations || !inputId) {
    return [];
  }

  return operations.filter(({ inputIds }) => inputIds && inputIds.includes(inputId));
};

/**
 * Filters the list of operations, keeping only those without any outputIds.
 *
 * @param operations
 */
export const filterOperationsWithoutOutputIds = (operations: Array<IOperationMetadata>): Array<IOperationMetadata> => {
  if (!operations) {
    return [];
  }

  return operations.filter(({ outputIds }) => !outputIds || !outputIds.length);
};

/**
 * Filters the list of operations, keeping only those being of the opertiontype given and containing the provided outputId in their list of outputIds.
 *
 * @param operations
 * @param outputId
 * @param operationType
 */
export const filterOperationsByOutputIdAndType = (
  operations: Array<IOperationMetadata>,
  outputId: string,
  operationType: string,
): Array<IOperationMetadata> => {
  if (!operations || !outputId) {
    return [];
  }

  return operations.filter(
    ({ outputIds, operationType: type }) => type === operationType && outputIds && outputIds.indexOf(outputId) !== -1,
  );
};

/**
 * Filters the list of operations, keeping only those not superseded.
 *
 * @param operations
 */
export const filterOperationsNotSuperseded = (operations: Array<IOperationMetadata>): Array<IOperationMetadata> => {
  if (!operations) {
    return [];
  }

  return operations.filter(({ isSuperseded, isSupersededBy }) => !Boolean(isSuperseded) && !Boolean(isSupersededBy));
};

/**
 * Gets the inputItems corresponding to the inputIds of the operations. Will include inputItems of childOperations
 * @param operations
 * @param geometries
 * @param variables
 * @param meshes
 * @param queries
 <*/
export const getInputItemsForOperations = (
  operations: Array<IOperationConfiguration>,
  geometries?: Array<IWorkspaceEnrichedGeometry>,
  variables?: Array<IWorkspaceEnrichedVariable>,
  meshes?: Array<IWorkspaceEnrichedMesh>,
  queries?: Array<IWorkspaceQuery>,
): { [operationId: string]: Array<IWorkspaceEnrichedDataItem> } => {
  if (!operations) {
    return null;
  }

  if (!geometries && !variables && !meshes && !queries) {
    return null;
  }

  const operationInputItems = operations.reduce((acc, cur) => {
    if (!cur) {
      return acc;
    }

    const childOperationsInputItems = self.getInputItemsForOperations(
      cur.childOperations,
      geometries,
      variables,
      meshes,
      queries,
    );

    const inputItems = self.getInputItemsForOperation(cur, geometries, variables, meshes, queries);
    return {
      ...acc,
      [cur.id]: inputItems,
      ...childOperationsInputItems,
    };
  }, {});

  return operationInputItems;
};

/**
 * Gets the inputItems corresponding to the inputIds of the operation itself. Does not consider childoperations
 * Will look for the input items in the geoemtries/variables/meshes passed in.
 *
 * todo hevo TEST THIS!!!
 *
 * @param operation
 * @param geometries
 * @param variables
 * @param meshes
 * @param queries
 */
export const getInputItemsForOperation = (
  operation: IOperationConfiguration,
  geometries?: Array<IWorkspaceEnrichedGeometry>,
  variables?: Array<IWorkspaceEnrichedVariable>,
  meshes?: Array<IWorkspaceEnrichedMesh>,
  queries?: Array<IWorkspaceQuery>,
): Array<IWorkspaceEnrichedGeometry | IWorkspaceEnrichedVariable | IWorkspaceEnrichedMesh | IWorkspaceQuery> => {
  if (!operation) {
    return null;
  }

  const { inputIds } = operation;

  const inputItems = getItemsByIds(inputIds, geometries, variables, meshes, queries);

  return [...inputItems];
};

/**
 * Gets the allowed inputItems corresponding to the allowed inputIds for each operation description given. Will  include allowed inputItems of childOperations
 * @param operationDescriptions
 * @param geometries
 * @param variables
 * @param meshes
 * @param queries
 <*/
export const getAllowedInputItemsForOperations = (
  operationDescriptions: {
    [operationKey: string]: IOperationDescription;
  },
  geometries?: Array<IWorkspaceEnrichedGeometry>,
  variables?: Array<IWorkspaceEnrichedVariable>,
  meshes?: Array<IWorkspaceEnrichedMesh>,
  queries?: Array<IWorkspaceQuery>,
): { [operationId: string]: Array<IWorkspaceEnrichedDataItem> } => {
  if (!operationDescriptions) {
    return null;
  }

  if (!geometries && !variables && !meshes && !queries) {
    return null;
  }

  const operationInputItems = Object.keys(operationDescriptions).reduce((acc, cur) => {
    const operationDescription = operationDescriptions[cur];

    if (!operationDescription) {
      return acc;
    }

    const childOperationsAllowedInputItems = self.getAllowedInputItemsForOperations(
      operationDescription.childOperationDescriptions,
      geometries,
      variables,
      meshes,
      queries,
    );

    const allowedInputItems = self.getAllowedInputItemsForOperation(
      operationDescription,
      geometries,
      variables,
      meshes,
      queries,
    );
    return {
      ...acc,
      [lowerFirst(cur)]: allowedInputItems,
      ...childOperationsAllowedInputItems,
    };
  }, {});

  return operationInputItems;
};

/**
 * Gets the allowed inputItems corresponding to the allowed inputIds of the operationdescription itself. Does not consider childoperations
 * Will look for the input items in the geometries/variables/meshes/queries passed in.
 *
 * todo hevo TEST THIS!!!
 *
 * @param operationDescription
 * @param geometries
 * @param variables
 * @param meshes
 * @param queries
 */
export const getAllowedInputItemsForOperation = (
  operationDescription: IOperationDescription,
  geometries?: Array<IWorkspaceEnrichedGeometry>,
  variables?: Array<IWorkspaceEnrichedVariable>,
  meshes?: Array<IWorkspaceEnrichedMesh>,
  queries?: Array<IWorkspaceQuery>,
): Array<IWorkspaceEnrichedGeometry | IWorkspaceEnrichedVariable | IWorkspaceEnrichedMesh | IWorkspaceQuery> => {
  if (!operationDescription) {
    return null;
  }

  const { allowedInputIds } = operationDescription;

  const allowedInputItems = self.getItemsByIds(allowedInputIds, geometries, variables, meshes, queries);

  return [...allowedInputItems];
};

/**
 * Gets the items corresponding to the itemIds given
 * Will look for the input items in the geometries/variables/meshes/queries passed in.
 *
 * todo hevo TEST THIS!!!
 *
 * @param itemIds
 * @param geometries
 * @param variables
 * @param meshes
 * @param queries
 */
export const getItemsByIds = (
  itemIds: Array<string>,
  geometries?: Array<IWorkspaceEnrichedGeometry>,
  variables?: Array<IWorkspaceEnrichedVariable>,
  meshes?: Array<IWorkspaceEnrichedMesh>,
  queries?: Array<IWorkspaceQuery>,
): Array<IWorkspaceEnrichedGeometry | IWorkspaceEnrichedVariable | IWorkspaceEnrichedMesh | IWorkspaceQuery> => {
  if (!itemIds) {
    return null;
  }

  if (!geometries && !variables && !meshes && !queries) {
    return [];
  }

  const items = itemIds
    .map((itemId) => {
      return [...(geometries || []), ...(meshes || []), ...(variables || []), ...(queries || [])].find(
        ({ id }) => id === itemId,
      );
    })
    .filter((item) => item !== undefined);

  return [...items];
};

/**
 * Gets a flat list of childOperation inputIds.
 *
 * @param operation
 */
export const getChildOperationInputIds = (operation: IOperationConfiguration): Array<string> => {
  if (!operation || !operation.childOperations) {
    return [];
  }

  return (operation.childOperations || []).reduce((acc, cur) => {
    return [...acc, ...cur.inputIds];
  }, []);
};

/**
 * returns true of the operation type is an interpolation type
 * @param operationType
 */
export const isInterpolationOperation = (operationType) =>
  operationType === OPERATION_DEFINITION_TYPES.ELEVATION_INTERPOLATION ||
  operationType === OPERATION_DEFINITION_TYPES.OTHER_INTERPOLATION;

const self = {
  OPERATION_MSG_CODE_PREFIX,
  OPERATION_PREFIX,

  translateOperationMessage,
  getLatestOperation,
  getItemOperationMetadata,
  getMessageFromOperation,
  getStateFromOperation,
  getOperationName,
  isOperationOutdated,

  filterOperationsByOutputId,
  filterOperationsWithoutOutputIds,
  filterOperationsByOutputIdAndType,
  filterOperationsNotSuperseded,
  filterOperationsByInputId,

  getInputItemsForOperation,
  getInputItemsForOperations,
  getChildOperationInputIds,

  getMeshItemOperationMetadata,
  getMeshStateFromOperation,
  getMeshOperationForState,
  getConflictingMeshOperationForState,

  getAllowedInputItemsForOperations,
  getAllowedInputItemsForOperation,
  getItemsByIds,

  isInterpolationOperation,
};

export default self;
