import { ReactNode } from 'react';
import { ButtonProps } from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { EToolButtonType, TOOL_BUTTON_TYPES } from './IToolButton';
import MikeButton from '../../shared-components/mike-button';

interface ToolButtonProps extends ButtonProps {
  toolName: string;
  toolType?: EToolButtonType;
  toolIcon?: ReactNode;
}

/**
 * @name MmgToolButton
 * @summary A base component for creating tools. Based on the type, it can either render a tool button (icon) or a regular button.
 *
 * @param props
 */
export const MmgToolButton = (props: ToolButtonProps) => {
  const { toolName, toolType, toolIcon, ...other } = props;

  switch (toolType) {
    case TOOL_BUTTON_TYPES.REGULAR_BUTTON:
      return (
        <MikeButton variant="contained" color="secondary" fullWidth {...other}>
          {toolName}
        </MikeButton>
      );

    case TOOL_BUTTON_TYPES.TOOL_BUTTON:
    default:
      return (
        <Tooltip title={toolName} placement="right">
          <button {...other}>{toolIcon}</button>
        </Tooltip>
      );
  }
};
