export enum EParameterSettingsSelectType {
  DROPDOWN = 'dropdown',
  RADIO = 'radio',
}

export const PARAMETER_SETTINGS_SELECTTYPE = {
  DROPDOWN: EParameterSettingsSelectType.DROPDOWN,
  RADIO: EParameterSettingsSelectType.RADIO,
};

export interface IParameterSettings {
  selectType?: EParameterSettingsSelectType; // if nothing is specified a dropdown will be rendered
}
