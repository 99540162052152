import React, { useEffect, useCallback, useState } from 'react';
import OperationsManager from '../managers/OperationsManager';
import { t } from '../translations/i18n';
import { MmgGroup } from '../shared/groups/group';
import { MmgPanelSubsection } from '../shared/panels/panel-subsection';
import QueryUtils from '../meshes/mesh-query/mesh-query-utils';
import { MmgOperationSelector } from '../operations/operation-selector';
import { IOperationDescription } from '../models/IOperationDescriptions';
import { MmgOperationDescription } from '../operations/operation-description';
import { MmgOperationConfiguration } from '../operations/operation-configuration';
import { IQueryDefinitionApi, ISpatialQueryDefinitionApi } from '../models/IQueryDefinitions';
import { IOperationConfiguration } from '../models/IOperations';
import { useNavigateBack } from '../app/navigation/useNavigateBack';
import { useIsMounted } from '../shared/hooks/hooks';
import { isSpatialQuery } from '../queries/attribute-queries/attribute-utils';
import { getGeometryGeojson } from '../managers/WorkspaceGeometriesManager';
import { store } from '../store/store';
import { getRouteByPath, ROUTES } from '../app/routes';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { MikeStickyPanelBottomActions } from '../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../shared-components/mike-button';
import MikeStickyPanelContent from '../shared-components/mike-sticky-panel/MikeStickyPanelContent';

type WorkspaceOperationsSectionProps = {
  projectId: string;
  workspaceId: string;
  onOperationSubmitted?: (success: boolean) => void;
  selectedItems: Array<string>;
};

/**
 * @name MmgConnectedWorkspaceOperationsSection
 * @param props
 * @summary Allows querying a mesh and applying post operations on it.
 *
 */
export const MmgConnectedWorkspaceOperationsSection = (props: WorkspaceOperationsSectionProps) => {
  const { projectId, workspaceId, selectedItems } = props;
  const navigate = useNavigate();
  const [operationDescriptions, setOperationDescriptions] = React.useState({} as {
    [operationKey: string]: IOperationDescription;
  });
  const [operationsLoading, setOperationsLoading] = React.useState(false);
  const [operationsLoadingFailed, setOperationsLoadingFailed] = React.useState(false);
  const [operationInProgress, setOperationInProgress] = React.useState(false);
  const [selectedOperationKey, setSelectedOperationKey] = React.useState(null as string);
  const [operationConfiguration, setOperationConfiguration] = useState(null as IOperationConfiguration);
  const [queryDefinition, setQueryDefinition] = React.useState({} as IQueryDefinitionApi);
  const prevQueryDefinition = usePrevious(queryDefinition);
  const isMounted = useIsMounted();

  /**
   * Fetch geojson for selected geometry, if the user has selected one of those,
   * which in turn sets a new queryDefinition each time a geometry is selected.
   */
  useEffect(() => {
    //is here where we fetch the data from
    const queryDef = queryDefinition as ISpatialQueryDefinitionApi;
    if (isSpatialQuery(queryDefinition)) {
      // Abort if equal to previous:
      if (isEqual(queryDefinition, prevQueryDefinition)) {
        return;
      }
      // Abort if no id or if geojson is already fetched:
      if (!queryDef.queryId || queryDef.featureCollection) {
        return;
      }
      getGeometryGeojson(workspaceId, queryDef.queryId)
        .then((geojson) => {
          isMounted() &&
            setQueryDefinition({
              featureCollection: geojson,
              ...queryDef,
            });
        })
        .catch((error) => console.error(`Error fetching GeoJson for "${queryDef.queryId}": ${error}`));
    }
  });

  const submitOperation: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (!selectedOperationKey) {
      return;
    }
    setOperationInProgress(true);

    const opConfig: IOperationConfiguration = {
      ...operationConfiguration,
      inputIds: selectedItems,
      queryDefinition,
    };
    OperationsManager.executeOperation(workspaceId, opConfig)
      .then(() => {
        // Route back to overview after a delay, so the user can see the new mesh:
        setTimeout(() => {
          navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
        }, 1500);
      })
      .catch((error) => {
        const toast = {
          text: error.response && error.response.data ? error.response.data : t('MESH_POST_OPERATION_SUBMITTED_FAILED'),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      })
      .finally(() => {
        isMounted() && setOperationInProgress(false);
      });
  };

  /**
   * Gets geometry operations
   */
  const getMeshPostOperations = useCallback(
    async () => {
      if (!workspaceId) {
        return;
      }
      setOperationsLoading(true);
      setOperationsLoadingFailed(false);
      try {
        const operations = await OperationsManager.getWorkspaceOperations(workspaceId);
        setOperationDescriptions(operations);
      } catch (error) {
        setOperationsLoadingFailed(true);
        console.error('Failed to get mesh post operations');
        throw error;
      } finally {
        setOperationsLoading(false);
      }
    },
    [workspaceId],
  );

  /**
   * Sets the selected post operation to the selected one.
   *
   * @param event
   */
  const onOperationChanged = (event: React.MouseEvent) => {
    const operationKey = (event.target as HTMLSelectElement).value;
    setSelectedOperationKey(operationKey);
    setOperationConfiguration(null);
  };

  const { goBackToReferrer } = useNavigateBack();

  const onConfigurationChanged = (newConfiguration: IOperationConfiguration) => {
    setOperationConfiguration(newConfiguration);
  };

  // load available operations when mounting
  useEffect(
    () => {
      getMeshPostOperations();
    },
    [getMeshPostOperations],
  );

  const operationKeys = Object.keys(operationDescriptions);
  const selectedOperationDescription = (operationDescriptions || {})[selectedOperationKey];
  const isOperationsAvailable = operationKeys && operationKeys.length > 0;

  // If query is not supported, the QueryDefinition is by default complete.
  const { supportsQuery } = selectedOperationDescription || {};
  const isQueryComplete = !supportsQuery || QueryUtils.isQueryDefinitionComplete(queryDefinition);

  const submitDisabled = !isQueryComplete || operationInProgress;

  const onPanelExit = () => {
    goBackToReferrer();
  };

  return (
    <>
      {operationInProgress && (
        <MikeStickyPanelContent>
          <MmgPanelSubsection>
            <p>{t('CONFIGURE_AFTER_DATA_PROCESSED')}</p>
          </MmgPanelSubsection>
        </MikeStickyPanelContent>
      )}
      <MmgGroup groupName={'Operations'} canBeHidden={false}>
        {isOperationsAvailable && (
          <>
            <MikeStickyPanelContent>
              <MmgPanelSubsection>
                <MmgOperationSelector
                  label={'Operations'}
                  placeholder={'Operations'.toLowerCase()}
                  operationKeys={operationKeys}
                  operationsLoading={operationsLoading}
                  operationsLoadingFailed={operationsLoadingFailed}
                  onOperationChanged={onOperationChanged}
                  selectedOperationKey={selectedOperationKey}
                />
                <MmgOperationDescription operationKey={selectedOperationKey} />
                <MmgOperationConfiguration
                  operationDescription={selectedOperationDescription}
                  onConfigurationChanged={onConfigurationChanged}
                />
              </MmgPanelSubsection>
            </MikeStickyPanelContent>
            <MikeStickyPanelBottomActions>
              <MikeButton variant="contained" color="secondary" onClick={onPanelExit}>
                {t('CANCEL')}
              </MikeButton>
              <MikeButton variant="contained" disabled={submitDisabled} onClick={submitOperation} active={operationInProgress}>
                {t('SUBMIT_MESH_POST_OPERATION')}
              </MikeButton>
            </MikeStickyPanelBottomActions>
          </>
        )}
      </MmgGroup>
    </>
  );
};
