import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceMeshOperationSelectors from '../../store/selectors/WorkspaceMeshOperationSelectors';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { MmgMeshList } from '../../meshes/list/mesh-list';
import { IOperationMetadata } from '../../models/IOperations';
import { MmgOperationList } from '../../operations/operation-list';
import { useParams } from 'react-router-dom';
import { IGlobalState } from '../../store/reducers';

/**
 * @name MmgMeshListContainer
 * @summary Gets meshes in a workspace from state and renders them via MmgMeshList.
 *
 */
export const MmgConnectedMeshListContainer = () => {
  const { workspaceId, projectId } = useParams();
  const hiddenWorkspaceMeshes: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer.hiddenWorkspaceMeshes,
  );
  const failedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.failedData);
  const workspaceMeshes: Array<IWorkspaceEnrichedMesh> = useSelector((state: IGlobalState) =>
    WorkspaceMeshSelectors.getSortedEnrichedWorkspaceMeshes(state),
  );
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = useSelector((state: IGlobalState) =>
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceMeshesByIds(state),
  );
  const processingMeshOperations: Array<IOperationMetadata> = useSelector((state: IGlobalState) =>
    WorkspaceMeshOperationSelectors.getLatestProcessingMeshOperations(state),
  );

  const meshesNotProcessing = useMemo(
    () => {
      if (!workspaceMeshes) {
        return [];
      }
      let outputIds = new Array<string>();
      if (processingMeshOperations) {
        processingMeshOperations.forEach((operation) => {
          if (operation.outputIds) {
            outputIds = outputIds.concat(operation.outputIds);
          }
        });
      }
      return outputIds.length === 0 ? workspaceMeshes : workspaceMeshes.filter((mesh) => !outputIds.includes(mesh.id));
    },
    [processingMeshOperations, workspaceMeshes],
  );

  const MeshOperationsWithoutOutputList = () => (
    <MmgOperationList projectId={projectId} workspaceId={workspaceId} operations={processingMeshOperations} />
  );
  const MeshList = () => (
    <MmgMeshList
      projectId={projectId}
      workspaceId={workspaceId}
      meshes={meshesNotProcessing}
      hiddenMeshes={hiddenWorkspaceMeshes}
      drawnMeshes={drawnWorkspaceMeshes}
      failedData={failedData}
    />
  );
  return (
    <>
      <MeshOperationsWithoutOutputList />
      <MeshList />
    </>
  );
};
