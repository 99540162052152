import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { EAttributeDataTypes, IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { t } from '../../translations/i18n';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import {
  IQueryDefinitionApi,
  IAttributeRangeQueryDefinitionApi,
  ISpatialQueryDefinitionApi,
  ISelectAllQueryDefinitionApi,
} from '../../models/IQueryDefinitions';
import { MmgAttributeQueryConfiguration } from '../../queries/attribute-queries/query-configuration';
import { useParams } from 'react-router-dom';
import { IDataArrayStatistics } from '../../statistics-react/mmg-mesh-statistics-container';
import { IGlobalState } from '../../store/reducers';

type MeshAttributeQueryConfigurationProps = {
  initialQueryDefinition?:
    | IAttributeRangeQueryDefinitionApi
    | ISpatialQueryDefinitionApi
    | ISelectAllQueryDefinitionApi;
  onQueryDefinitionChanged?: (newQueryDefinition: IQueryDefinitionApi) => void;
};

/**
 * @name MmgConnectedMeshAttributeQueryConfiguration
 * @param props
 * @summary Allows querying a mesh and applying post operations on it.
 *
 */
export const MmgConnectedMeshAttributeQueryConfiguration = (props: MeshAttributeQueryConfigurationProps) => {
  const { initialQueryDefinition, onQueryDefinitionChanged } = props;

  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getMeshAttributesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributes();
  const getMeshAttributeSettingsSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributeSettings();
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const getMeshQueriesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshQueries();

  const { projectId, workspaceId, meshId } = useParams<any>();

  const mesh: IWorkspaceEnrichedMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const meshAttributes: IWorkspaceAttribute[] = useSelector(
    (state: IGlobalState) => getMeshAttributesSelectorInstance(state, { meshId }) || [],
  );
  const meshAttributeSettings = useSelector(
    (state: IGlobalState) =>
      getMeshAttributeSettingsSelectorInstance(state, {
        meshId,
      }) || [],
  );
  const meshQueries = useSelector((state: IGlobalState) =>
    getMeshQueriesSelectorInstance(state, {
      meshId,
    }),
  );

  const meshDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId }),
  );

  const loadingFieldStatistics: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.loadingFieldStatistics,
  );

  const dataStatistics: { [key: string]: IDataArrayStatistics } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.dataStatistics,
  );

  const isTiled = useMemo(
    () => {
      return mesh && mesh.isTiled;
    },
    [mesh],
  );

  const attributes = useMemo(
    () => {
      if (isTiled) {
        if (!loadingFieldStatistics && dataStatistics && dataStatistics[meshId]) {
          const dataArrays = dataStatistics[meshId];
          const atts: Array<IWorkspaceAttribute> = dataArrays.map((d: any) => {
            return { name: d.id, range: d.range, dataType: EAttributeDataTypes.DOUBLE };
          });
          return atts;
        } else {
          return [];
        }
      }
      return meshAttributes;
    },
    [isTiled, meshAttributes, loadingFieldStatistics, dataStatistics, meshId],
  );

  const callOnQueryDefinitionChanged = useCallback(
    (newQueryDefinition) => {
      if (onQueryDefinitionChanged) {
        onQueryDefinitionChanged(newQueryDefinition);
      }
    },
    [onQueryDefinitionChanged],
  );

  if (!meshId) {
    return null;
  }

  const drawnData = meshDrawnData ? [meshDrawnData] : [];

  return (
    <MmgAttributeQueryConfiguration
      projectId={projectId}
      workspaceId={workspaceId}
      itemId={meshId}
      item={mesh}
      attributes={attributes}
      attributeSettings={meshAttributeSettings}
      itemDrawnDatas={drawnData}
      savedQueries={meshQueries}
      initialQueryDefinition={initialQueryDefinition}
      onQueryDefinitionChanged={callOnQueryDefinitionChanged}
      selectCriteriaLabel={t('SELECT_MESH_ELEMENTS_QUERY').toLowerCase()}
      allowSelectAll={true}
    />
  );
};
