/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { MmgAttributeQueryConfiguration } from '../../queries/attribute-queries/query-configuration';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceQueryManager from '../../managers/WorkspaceQueryManager';
import { Skeleton } from '@mui/material';
import { IQueryDefinitionApi, QUERY_DEFINITION_TYPES } from '../../models/IQueryDefinitions';
import { store } from '../../store/store';
import { CONFIRM_PANEL_IDS } from '../../shared/confirm-containers/confirm-constants';
import { EAttributeDataTypes, IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IPropertyTableQuery } from '../../models/IPropertyTableQuery';
import { zoomToElement } from './mesh-queries-utils';
import { MmgConnectedMeshQueryElementTable } from './mesh-query-elements-table';
import QueryUtils from '../../meshes/mesh-query/mesh-query-utils';
import { PROP_KEYS } from '../../shared/operations/download/download-operations';
import { IWorkspace } from '../../models/IWorkspaces';
import { getSpatialQueryLayer } from '../../store/reducers/PanelSettingsReducer';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { hideLayer } from '../../shared/layers/layer-utils';
import { SELECTION_RESULT_HIGHLIGHT_COLOR } from '../../queries/spatial-selections/spatial-selection-utils';
import { useParams } from 'react-router-dom';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { EPanelActionType } from '../../store/actions/PanelActionType';
import { IDataArrayStatistics } from '../../statistics-react/mmg-mesh-statistics-container';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { IGlobalState } from '../../store/reducers';
import { REPRESENTATION } from '../../MikeVisualizer/lib/MikeVisualizerConstants';
import MikeButton from '../../shared-components/mike-button';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';

export const SmallerCellStyle = css`
  max-width: 40px;
`;

interface IMmgMeshQuerySummaryProps {
  initialQueryDefinition?: IQueryDefinitionApi;
}

const ELEMENT = 'ElementLayer';

export const MmgConnectedMeshQuerySummary = (props: IMmgMeshQuerySummaryProps) => {
  const { initialQueryDefinition } = props;

  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getMeshAttributesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributes();
  const getMeshAttributeSettingsSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributeSettings();
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const getMeshQueriesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshQueries();

  const { projectId, workspaceId, meshId } = useParams<any>();
  const mesh: IWorkspaceEnrichedMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const meshAttributes: IWorkspaceAttribute[] = useSelector(
    (state: IGlobalState) => getMeshAttributesSelectorInstance(state, { meshId }) || [],
  );
  const meshAttributeSettings = useSelector(
    (state: IGlobalState) =>
      getMeshAttributeSettingsSelectorInstance(state, {
        meshId,
      }) || [],
  );
  const meshQueries = useSelector((state: IGlobalState) =>
    getMeshQueriesSelectorInstance(state, {
      meshId,
    }),
  );

  const meshDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId }),
  );
  const workspace: IWorkspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  const workspacePanelSettings = useSelector(
    (state: IGlobalState) => state.PanelSettingsReducer.workspacePanelSettings,
  );

  const queryResponse: any = useSelector((state: IGlobalState) => state.WorkspaceQueryReducer.queryResponse);

  const loadingFieldStatistics: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.loadingFieldStatistics,
  );

  const dataStatistics: { [key: string]: IDataArrayStatistics } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.dataStatistics,
  );

  const isTiled = useMemo(
    () => {
      return mesh && mesh.isTiled;
    },
    [mesh],
  );

  const attributes = useMemo(
    () => {
      if (isTiled) {
        if (!loadingFieldStatistics && dataStatistics && dataStatistics[meshId]) {
          const dataArrays = dataStatistics[meshId];
          const atts: Array<IWorkspaceAttribute> = dataArrays.map((d: any) => {
            return { name: d.id, range: d.range, dataType: EAttributeDataTypes.DOUBLE };
          });
          return atts;
        } else {
          return [];
        }
      }
      return meshAttributes;
    },
    [isTiled, meshAttributes, loadingFieldStatistics, dataStatistics, meshId],
  );

  const [isFetching, setIsFetching] = React.useState(false);
  const [steps, setsteps] = useState<IPropertyTableQuery['vtuProperties']>([]);
  const [meshPropNames, setMeshPropNames] = useState<IPropertyTableQuery['propertyNames']>([]);
  const [page, setPage] = useState(0);
  const [order, setOrdering] = useState('Ascending');
  const [stepsNumber, setStepsNumber] = useState(0);

  const drawnData = meshDrawnData ? [meshDrawnData] : [];

  useEffect(
    () => {
      return () => {
        const lastQueryId = getSpatialQueryLayer(workspaceId, meshId);
        lastQueryId && hideLayer(ELEMENT_CATEGORIES.GEOMETRY, lastQueryId, false);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const meshQuerySettings = useMemo(
    () => {
      const layerSettings =
        mesh &&
        workspacePanelSettings &&
        workspacePanelSettings.find((sett) => {
          return sett.id === mesh.id;
        });
      const qd = layerSettings && layerSettings.queryDefinition;
      if (qd && qd.updated && qd.updated === mesh.updated) {
        return qd;
      }
      return null;
    },
    [workspacePanelSettings, mesh],
  );

  useEffect(
    () => {
      if (!meshQuerySettings) {
        setsteps([]);
        setMeshPropNames([]);
        setStepsNumber(0);
        setIsFetching(false);
      }
    },
    [meshQuerySettings],
  );

  useEffect(
    () => {
      if (
        queryResponse &&
        queryResponse.queryDefinition &&
        queryResponse.queryDefinition.targetItems &&
        queryResponse.queryDefinition.targetItems.includes(meshId)
      ) {
        setsteps(queryResponse.vtuProperties ? queryResponse.vtuProperties : []);
        setMeshPropNames(queryResponse.propertyNames ? queryResponse.propertyNames : []);
        setStepsNumber(queryResponse.totalAmountOfQueryResults ? queryResponse.totalAmountOfQueryResults : 0);
        setIsFetching(false);
      }
    },
    [queryResponse, meshId],
  );

  const fetchSteps = useCallback(
    (querySettings: any, p: number, ord?: string) => {
      setIsFetching(true);
      const onlyAreaTypes = [
        QUERY_DEFINITION_TYPES.SELECT_ALL,
        QUERY_DEFINITION_TYPES.SPATIAL,
        QUERY_DEFINITION_TYPES.PERSISTED_SELECTION,
      ];
      const onlyArea =
        querySettings.name === PROP_KEYS.MMG_AREA || onlyAreaTypes.includes(querySettings.type) || !querySettings.name;
      const resultTablePropertyNames = onlyArea ? [PROP_KEYS.MMG_AREA] : [querySettings.name, PROP_KEYS.MMG_AREA];

      WorkspaceQueryManager.createPropertyTableQuery({
        meshId,
        workspaceId,
        queryDefinition: querySettings,
        resultTablePropertyNames,
        ordering: ord,
        limit: 10,
        offset: p * 10,
      });
    },
    [meshId, workspaceId],
  );

  const callOnQueryDefinitionChanged = useCallback(
    (newQueryDefinition) => {
      if (workspaceId) {
        const newQueryId = newQueryDefinition && newQueryDefinition.queryId ? newQueryDefinition.queryId : '';
        const lastQueryId = getSpatialQueryLayer(workspaceId, meshId);
        lastQueryId && lastQueryId !== newQueryId && hideLayer(ELEMENT_CATEGORIES.GEOMETRY, lastQueryId, false);
      }
      const queryDefinition =
        newQueryDefinition.type === QUERY_DEFINITION_TYPES.SELECT_ALL ||
        newQueryDefinition.type === QUERY_DEFINITION_TYPES.SPATIAL ||
        newQueryDefinition.type === QUERY_DEFINITION_TYPES.PERSISTED_SELECTION
          ? { ...newQueryDefinition, name: PROP_KEYS.MMG_AREA }
          : newQueryDefinition;
      const isQueryComplete = QueryUtils.isQueryDefinitionComplete(queryDefinition);
      if (isQueryComplete) {
        store.dispatch({
          type: EPanelActionType.SET_MESH_QUERY_PANEL,
          queryDefinition: { ...queryDefinition, updated: mesh.updated },
          workspaceId,
          layerId: meshId,
        });
      }
    },
    [workspaceId, meshId, mesh],
  );

  const handleChangeOrder = useCallback(
    (ord: string) => {
      setOrdering(ord);
      fetchSteps(meshQuerySettings, page, ord);
    },
    [fetchSteps, page, meshQuerySettings],
  );

  const handleChangePage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      fetchSteps(meshQuerySettings, newPage, order);
    },
    [fetchSteps, order, meshQuerySettings],
  );

  useEffect(
    () => {
      meshQuerySettings && fetchSteps(meshQuerySettings, 0);
      return () => {
        const { deleteData } = MikeVisualizerLib;
        deleteData(ELEMENT);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const TableSkeleton = () => {
    return (
      <>
        {[...Array(10)].map((_num: number, index: number) => {
          return <Skeleton animation="wave" key={index} />;
        })}
      </>
    );
  };

  const editNodes = (vtp: string, area: number) => {
    zoomToElement(vtp, area, workspace);
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.MESH_EDIT_NODES,
    });
    store.dispatch({
      type: EWorkspaceMeshActionType.EDIT_NODES,
      data: mesh.id,
    });
  };

  const renderMeshElement = useCallback((vtp: string) => {
    const { updateData } = MikeVisualizerLib;
    updateData(
      vtp,
      ELEMENT,
      SELECTION_RESULT_HIGHLIGHT_COLOR.edge,
      SELECTION_RESULT_HIGHLIGHT_COLOR.surface,
      REPRESENTATION.SURFACE,
    );
  }, []);

  const handleZoomToElement = useCallback(
    async (vtp: string, area: number) => {
      renderMeshElement(vtp);
      zoomToElement(vtp, area, workspace);
    },
    [renderMeshElement, workspace],
  );

  const onApplyToTableClicked = useCallback(
    () => {
      setPage(0);
      setOrdering('Ascending');
      meshQuerySettings && fetchSteps(meshQuerySettings, 0);
    },
    [fetchSteps, meshQuerySettings],
  );

  const TimeStepsTable = () => {
    return !isFetching ? (
      <MmgConnectedMeshQueryElementTable
        steps={steps}
        meshPropNames={meshPropNames}
        editNodes={editNodes}
        zoomToElement={handleZoomToElement}
        setOrder={handleChangeOrder}
        ordering={order}
        fetching={isFetching}
        workspace={workspace}
        onChangePage={handleChangePage}
        stepsNumber={stepsNumber}
        queryPage={page}
        isTiled={isTiled}
      />
    ) : (
      <>
        <TableSkeleton />
      </>
    );
  };

  return (
    <div>
      <MmgGroup groupName={'Query'}>
        <MmgPanelSubsection>
          <MmgAttributeQueryConfiguration
            projectId={projectId}
            workspaceId={workspaceId}
            itemId={meshId}
            item={mesh}
            attributes={attributes}
            attributeSettings={meshAttributeSettings}
            itemDrawnDatas={drawnData}
            savedQueries={meshQueries}
            initialQueryDefinition={meshQuerySettings ? meshQuerySettings : initialQueryDefinition}
            onQueryDefinitionChanged={callOnQueryDefinitionChanged}
            selectCriteriaLabel={t('SELECT_MESH_ELEMENTS_QUERY').toLowerCase()}
            allowSelectAll={true}
          />
          <MikeButton variant="contained" disabled={isFetching || !meshQuerySettings} onClick={onApplyToTableClicked}>
            {t('QUERY_APPLY_TO_TABLE')}
          </MikeButton>
          <TimeStepsTable />
        </MmgPanelSubsection>
      </MmgGroup>
    </div>
  );
};
