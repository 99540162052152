/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import MmgSidePanel from '../shared/sidepanel/sidepanel';
import MmgWorkspaceSidePanelNavigation from './workspace-sidepanel-navigation';
import { IMikeSidePanelProps } from '../shared-components/mike-sidepanel/MikeSidePanel';
import { IGlobalState } from '../store/reducers';
import MmgConnectedLayerWorkspaceActions from './workspace-layer-actions';
import { MmgConnectedWorkspaceSnapshotDialog } from './workspace-snapshot-dialog';

interface IWorkspaceSidePanelProps extends IMikeSidePanelProps {
  navigationLocked?: boolean;
  navigationGroup?: string;
}



/**
 * Wraps the sidepanel component and adds  navigation for workspace panels. Will pass on any props
 * @param props
 */
export const MmgConnectedWorkspaceSidePanel = (props: IWorkspaceSidePanelProps) => {
  const { children, navigationLocked, ...rest } = props;
  const currentNavGroup: string = useSelector((state: IGlobalState) => state.PanelReducer.currentNavGroup);
  return (
    <MmgSidePanel
      navigation={<MmgWorkspaceSidePanelNavigation value={currentNavGroup} navigationLocked={navigationLocked} />}
      forceValue={currentNavGroup}
      {...rest}
    >
      <MmgConnectedWorkspaceSnapshotDialog />
      <MmgConnectedLayerWorkspaceActions />
      {children}
    </MmgSidePanel>
  );
};
