import { every, isEmpty } from 'lodash-es';
import { IOperationConfiguration } from '../models/IOperations';
import QueryUtils from '../meshes/mesh-query/mesh-query-utils';

/**
 * Exposes methods related to validation of Create Mesh operations.
 *
 * @module MeshOperationValidation
 * @version 2.0.0
 */

/**
 *
 * @param createMeshConfiguration Validates the create mesh operation
 */
export const isCreateMeshOperationValid = (createMeshConfiguration: IOperationConfiguration) => {
  if (!createMeshConfiguration) {
    return false;
  }
  // Must have correct input id (the meshId)
  if (!self.isCreateMeshOperationInputIdValid(createMeshConfiguration)) {
    return false;
  }

  // Must have valid childOperations
  const { childOperations } = createMeshConfiguration;
  if (!self.isCreateMeshChildOperationsValid(childOperations)) {
    return false;
  }

  return true;
};

/**
 * Validates the inputIds ([meshId]) of the create mesh operation given.
 * Be aware that this only consider inputids of the parentoperation itself. To validate childoperations @see isCreateMeshChildOperationsValid
 * @param interpolateMeshConfiguration
 */
export const isCreateMeshOperationInputIdValid = (interpolateMeshConfiguration: IOperationConfiguration) => {
  if (!interpolateMeshConfiguration) {
    return false;
  }

  // Must have exactly one input id (the meshId)
  if (!interpolateMeshConfiguration.inputIds || interpolateMeshConfiguration.inputIds.length !== 1) {
    return false;
  }

  return true;
};
/**
 * Validates the childoperations of a create mesh operation.
 * @param createMeshChildOperations
 */
export const isCreateMeshChildOperationsValid = (createMeshChildOperations: Array<IOperationConfiguration>) => {
  // Must have at least one childOperation
  if (!createMeshChildOperations || !createMeshChildOperations.length) {
    return false;
  }

  // childoperations must have input ids
  const childrenValid = every(createMeshChildOperations, ({ inputIds, queryDefinition }) => {
    if (!inputIds || !inputIds.length) {
      return false;
    }

    // if a non empty queryDefinition exists is must be valid
    if (queryDefinition && !isEmpty(queryDefinition)) {
      return QueryUtils.isQueryDefinitionComplete(queryDefinition);
    }

    return true;
  });

  if (!childrenValid) {
    return false;
  }

  return true;
};

const self = {
  isCreateMeshOperationValid,
  isCreateMeshChildOperationsValid,
  isCreateMeshOperationInputIdValid,
};

export default self;
