/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import RightCompress from '../../icons/RightCompress.svg?react';
import { IMikeSidePanelNavigationProps } from '../mike-sidepanel-navigation/MikeSidePanelNavigation';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';

export interface IMikeSidePanelNavigationBarProps {
  /**
   * Identifies the initially selected navigation item
   */
  value?: string;

  /**
   * Force an override of the locally managed `value`.
   */
  forceValue?: string;

  /**
   * If true, the navigation bar will be collapsed, only showing the children. Otherwise it will be expandend and include a 'collapse' button.
   */
  collapsed: boolean;

  /**
   * @event Callback fired when the navigation bar collaption change
   */
  onCollapsed?: (collapsed: boolean) => void;

  /**
   * Navigation contents. I expected to be an @?ref MikeSidePanelNavigation element
   */
  children?: Array<React.ReactElement>;

  /**
   * The emotion css. Will be passed on to the root element.
   */
  css?: any;

  /**
   * The tooltip to show when hovering the collapse button. Default is "Close panel".
   * For no tooltip, use "".
   */
  tooltipTitle?: string;
}

const NAV_BAR_SIZE = '40px'; // todo hevo make 0.875rem?? And define this in one place only


     //${mikeSharedTheme.palette.background.default};

const rootStyle = (collapsed: boolean, cssStyleProp?: any) => {
  const rStye = css`
    width: ${NAV_BAR_SIZE};
    height: calc(100% - 60px);  
    background-color: red; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 0;
    pointer-events: all;
    top: 60px;
  `;
  const collapsedStye = css`
    background-color: transparent;
  `; 
    if (collapsed){
      if (cssStyleProp){
        return css`${rStye}; ${collapsedStye}; ${cssStyleProp};`;
      }
      else{
        return css`${rStye}; ${collapsedStye};`;
      }
    }
    else{
      if (cssStyleProp){
        return css`${rStye}; ${cssStyleProp};`;
      }
      else{
        return rStye
      }
    }
  }

  const collapseButtonStyle = css`
    width: ${NAV_BAR_SIZE};
    min-width: ${NAV_BAR_SIZE};
    height: ${NAV_BAR_SIZE};
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    position: absolute;
    top: 0px;
    padding: 0px;
    z-index: 99999;
 `;
  const collapseButtonContainerStyle = css`
    display: flex;
    justify-self: flex-start;
  `;

const contentStyle = (collapsed: boolean) => {
  const cStyle = css`
    flex-grow: 1;
    display: flex;
    justify-self: center;
    z-index: 1300;
    background-color: ${MIKE_COLORS.XLIGHTGREY}
  `;
  const contentCollapsedStyle = css`
    flex-grow: 0;
    flex-shrink: 1;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.16); 
  `;
  if (collapsed){
    return css`
    ${cStyle}; 
    ${contentCollapsedStyle};
    `
  }
  else{
    return cStyle
  }
} 

/**
 * @name MikeSidePanelNavigationBar
 * @summary A navigation that allows switching content inside a MikeSidePanel.
 *
 * @param props
 */
export const MikeSidePanelNavigationBar = (props: IMikeSidePanelNavigationBarProps) => {
  const {
    collapsed,
    onCollapsed,
    children,
    css: cssStyleProp,
    value,
    forceValue,
    tooltipTitle = 'Close panel',
  } = props;

  const [currentValue, setCurrentValue] = useState(value);
  const toggleCollapse = () => {
    if (onCollapsed) {
      onCollapsed(!collapsed);
    }
  };
  const expand = () => {
    if (onCollapsed) {
      onCollapsed(false);
    }
  };

  // Change currentValue if consumer wants to control it:
  useEffect(() => {
    if (forceValue) {
      setCurrentValue(forceValue);
    }
  }, [forceValue]);

  return (
    <>
      <aside
        css={rootStyle(collapsed, cssStyleProp)}
      >
        {!collapsed && (
          <div css={collapseButtonContainerStyle}>
            <Tooltip title={tooltipTitle} placement="left">
              <IconButton onClick={toggleCollapse} css={collapseButtonStyle}>              
                <RightCompress />              
              </IconButton>
            </Tooltip>
          </div>
        )}
        <div css={contentStyle(collapsed)}>
          {React.Children.map(
            children as Array<React.ReactElement>,
            (child: React.ReactElement<IMikeSidePanelNavigationProps>) => {
              const { onNavigationChange: childNavigationChange, value: childValue } = child.props;
              const actuelValue = childValue ? childValue : currentValue;
              const handleChildNav = (newValue: any) => {
                if (newValue === currentValue) {
                  toggleCollapse();
                } else {
                  expand();
                }
                setCurrentValue(newValue);
                if (childNavigationChange) {
                  childNavigationChange(newValue);
                }
              };
              return React.cloneElement(child, {
                value: actuelValue,
                onNavigationChange: handleChildNav,
              });
            }
          )}
        </div>
      </aside>
    </>
  );
};

export default MikeSidePanelNavigationBar;
