/** @jsxImportSource @emotion/react */
import { t } from '../../../translations/i18n';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import { ViewerToolsButtonStyle } from '../../../workspaces/viewer/tools/viewer-tools-styles';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';;
import { store } from '../../../store';
import { EWorkspaceMeshActionType } from '../../../store/actions/WorkspaceMeshActionType';
import { useParams } from 'react-router-dom';

const { zoomOut } = MikeVisualizer;
/**
 * @name MmgViewerToolZoomOut
 * @summary Allows zooming out.
 */
export const MmgViewerToolZoomOut = () => {
  const {workspaceId } = useParams();
  const handleZoomOut = () => {
    zoomOut();
    store.dispatch({ type: EWorkspaceMeshActionType.CHECK_VIEW_BOUNDS, data: {workspaceId, wait: 1000} });
  };
  return (
    <Tooltip title={t('TOOL_ZOOM_OUT')} placement="right">
      <button css={ViewerToolsButtonStyle} onClick={handleZoomOut}>
        <RemoveIcon />
      </button>
    </Tooltip>
  );
};
