import { t } from '../../translations/i18n';
import { store } from '../../store/store';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceVariable, IWorkspaceEnrichedVariable } from '../../models/IVariables';
import WorkspaceVariablesManager from '../../managers/WorkspaceVariablesManager';
import { MmgVariableListItem } from '../../variables/list/variable-list-item';
import { MmgVariableListItemEdit } from '../../variables/list/variable-list-item-edit';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import { useDispatch, useSelector } from 'react-redux';
import { addEditingVariable, removeEditingVariable } from '../../store/actions/editingItems';
import { useNavigate } from 'react-router-dom';
import { IGlobalState } from '../../store/reducers';
const { setActiveLayer } = LayerUtils;

type VariableListProps = {
  projectId: string;
  workspaceId: string;
  variables: Array<IWorkspaceEnrichedVariable>;
  hiddenVariables: Array<string>;
  drawnVariables: Array<IDrawnDataItem>;
};

/**
 * @name MmgVariableList
 * @summary Given an array of variables, renders them and sets up their interactions.
 *
 * @param props
 */
export const MmgVariableList = (props: VariableListProps) => {
  const navigate = useNavigate();
  const { workspaceId, projectId, variables, hiddenVariables, drawnVariables } = props;
  const dispatch = useDispatch();
  const editingVariableIds: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceDataReducer.editingVariableIds,
  );

  /**
   * Callback for when variable open is pressed.
   * Opens the variable panel for it.
   *
   * @param variableId
   */
  const onVariableOpenPressed = (variableId: string) => {
    setActiveLayer(ELEMENT_CATEGORIES.VARIABLE, variableId, workspaceId, projectId, navigate);
  };

  /**
   * Callback for when a variable edit button is pressed.
   *
   * @param variableId
   */
  const onVariableEdit = (variableId: string) => {
    dispatch(addEditingVariable(variableId));
  };

  /**
   * Callback for when a variable updates.
   * Sends an api request and displays relevant toasts.

   * @param variable
   * @param variable.id
   * @param variable.name
   */
  const onVariableUpdate = ({ id, name }: IWorkspaceVariable) => {
    dispatch(removeEditingVariable(id));

    WorkspaceVariablesManager.renameVariable(workspaceId, id, name)
      .then(() => {
        store.dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('VARIABLE_UPDATED_SUCCESSFULLY'),
          },
        });
      })
      .catch((error) => {
        dispatch(addEditingVariable(id));

        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: {
            text: t('VARIABLE_UPDATE_FAILED'),
          },
        });

        throw error;
      });
  };

  return (
    <>
      {variables.map((variable) => {
        const variableIsEditing = editingVariableIds.includes(variable.id);

        if (variableIsEditing) {
          return <MmgVariableListItemEdit key={variable.id} variable={variable} onVariableUpdate={onVariableUpdate} />;
        }

        return (
          <MmgVariableListItem
            key={variable.id}
            variable={variable}
            hiddenVariables={hiddenVariables}
            drawnVariables={drawnVariables}
            onVariableEdit={onVariableEdit}
            onVariableOpenPressed={onVariableOpenPressed}
          />
        );
      })}
    </>
  );
};
