/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../store/store';
import { t } from '../../../translations/i18n';
import ViewerMode2D from '../../../shared/Icons/ViewerMode2D.svg?react';
import ViewerMode3D from '../../../shared/Icons/ViewerMode3D.svg?react';
import MapToolSelectors from '../../../store/selectors/MapToolSelectors';
import { translateWithPrefix } from '../../../translations/utils';
import { MMG_BASE_MAPS } from './viewer-tool-constants';
import { getBaseMapConfigurationById } from '../../../workspaces/viewer/tools/viewer-mode-utils';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';
import { VIEWER_MODES } from '../../../MikeVisualizer/lib/IMikeVisualizerModels';
import { Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCubeWidget } from '../../../workspaces/localSettings/useCubeWidget';
import { MAX_WIDGET_SIZE } from './tools-constants';
import { makeGraticule } from '../../../workspaces/viewer/tools/makeGraticule';
import { usePrevious } from '../../../shared/hooks/hooks';
import { DefaultIconSize, MapViewerModesZindex } from '../../../shared/styles/styleConsts';
import { EWorkspaceQueryActionType } from '../../../store/actions/WorkspaceQueryActionType';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';
import { IGlobalState } from '../../../store/reducers';
import mikeSharedTheme from '../../../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../../../MikeVisualizer/colors/mike-colors';

const { setViewModeTo2D, setViewModeTo3D, setViewerBaseMapUrl, turnBaseMapOffAndOn } = MikeVisualizer;
const basemapSwitcherSize = '75px';

export const IconLightStyle = css`
  fill: white;
`; 

export const GenericIconButtonStyle = css`
  background-color: rgba(255, 255, 255, 0.5);
  width: ${basemapSwitcherSize};
  height: ${basemapSwitcherSize};
`;
const ViewerModesStyle = css`
  display: flex;
  position: absolute;
  z-index: ${MapViewerModesZindex};
  top: 0;
  left: ${MAX_WIDGET_SIZE * 0.85}px;
  padding: ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(2)};
`;

export const ToggleStyle = css`  
    display: inline-block;
    background-color: white;
    width: ${DefaultIconSize};
    height: ${DefaultIconSize};
    border-radius: 50%;
    margin: ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(2)} 0;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
`;

export const ToggleActiveStyle = css`
  background-color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
`;

export const ViewerModesBaseMapSwitcherStyle = css`
  ${GenericIconButtonStyle};
  position: absolute;
  z-index: ${MapViewerModesZindex};
  top: ${mikeSharedTheme.spacing(4)};
  left: ${mikeSharedTheme.spacing(2)};
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);

  p {
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    text-indent: ${mikeSharedTheme.spacing(1 / 3)};
    margin: 0;
    padding: ${mikeSharedTheme.spacing(1)} 0;
    font-size: 0.8rem;
    width: 100%;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  }

`;

export const ReloadBasemapButtonStyle = css`  
    background: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
    color: white;
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    border-radius: 0.1rem;
`;

export const ReloadIconStyle = css`
  background: transparent;
  transform: translate(0px, 0px) scale(0.9);
`;

const ReloadBaseMapButton = () => {
  return (
    <Tooltip title={t('BASEMAP_REFRESH')}>
      <div
        css={ReloadBasemapButtonStyle}
        onClick={(e) => {
          e.stopPropagation();
          turnBaseMapOffAndOn();
        }}
      >
        <RefreshIcon css={ReloadIconStyle} />
      </div>
    </Tooltip>
  );
};

/**
 * @name MmgConnectedViewerModes
 * @summary Allows changing between viewer modes and maps.
 *
 */
export function MmgConnectedViewerModes() {
  const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);

  const is2DModeRequired: boolean = useSelector(MapToolSelectors.is2DModeRequired);
  const resetViewCount = useSelector((state: IGlobalState) => state.MapToolReducer.resetViewCount);
  const epsgCode = useMemo(
    () => {
      return workspace ? workspace.epsgCode : null;
    },
    [workspace],
  );

  const { enable2DMode, enable3DMode } = useMemo(
    () => {
      return { enable2DMode: true, enable3DMode: !is2DModeRequired };
    },
    [is2DModeRequired],
  );

  const { viewerBaseMapId, viewerMode, viewerBaseMapSupported } = useSelector(
    (state: IGlobalState) => state.ViewerModeReducer,
  );
  const prevViewerMode = usePrevious(viewerMode);
  const viewerModeChanged = prevViewerMode !== viewerMode;
  const prevEpsgCode = usePrevious(epsgCode);
  const epsgCodeChanged = prevEpsgCode !== epsgCode;

  useCubeWidget({ viewerMode, resetViewCount });

  function renderMapSwitcher() {
    switch (viewerBaseMapId) {
      case MMG_BASE_MAPS.MAPBOX_SATELLITE.id: {
        const mapName = translateWithPrefix('BASEMAP', MMG_BASE_MAPS.MAPTILER_STREET.id.toUpperCase());

        return (
          <button
            css={ViewerModesBaseMapSwitcherStyle}
            onClick={() =>
              store.dispatch({
                type: EMapToolActionType.SET_BASEMAP,
                data: MMG_BASE_MAPS.MAPTILER_STREET.id,
              })
            }
          >
            <img src={MMG_BASE_MAPS.MAPTILER_STREET.image} alt={mapName} />
            <p>{mapName}</p>
            <ReloadBaseMapButton />
          </button>
        );
      }

      case MMG_BASE_MAPS.MAPTILER_STREET.id: {
        return (
          <button
            css={ViewerModesBaseMapSwitcherStyle}
            onClick={() =>
              store.dispatch({
                type: EMapToolActionType.SET_BASEMAP,
                data: MMG_BASE_MAPS.NONE.id,
              })
            }
          >
            <p>{t('BASEMAP_NONE')}</p>
            <ReloadBaseMapButton />
          </button>
        );
      }

      default: {
        const mapName = translateWithPrefix('BASEMAP', MMG_BASE_MAPS.MAPBOX_SATELLITE.id.toUpperCase());

        return (
          <button
            css={ViewerModesBaseMapSwitcherStyle}
            onClick={() => {
              store.dispatch({
                type: EMapToolActionType.SET_BASEMAP,
                data: MMG_BASE_MAPS.MAPBOX_SATELLITE.id,
              });
            }}
          >
            <img src={MMG_BASE_MAPS.MAPBOX_SATELLITE.image} alt={mapName} />
            <p>{mapName}</p>
          </button>
        );
      }
    }
  }

  useEffect(
    () => {
      switch (viewerMode) {
        case VIEWER_MODES.TWO_D: {
          // To mitigate a bug i visualizer state, first set viewer mode with blanks,
          // then set basemap. Can't use async/wait within useEffect:
          setViewModeTo2D('', [])
            .then(() => {
              const { url, attributions } = getBaseMapConfigurationById(viewerBaseMapId) || {};
              setViewerBaseMapUrl(url, attributions).then(() => {
                if (viewerModeChanged || epsgCodeChanged) {
                  makeGraticule();
                }
                if (viewerModeChanged) {
                  store.dispatch({ type: EWorkspaceQueryActionType.SELECTIONS_RE_ADD_LABELS });
                }
              });
            })
            .catch((e) => {
              console.error(e);
            });
          break;
        }

        case VIEWER_MODES.THREE_D: {
          // TODO: joel; Why dispatch an action that doesn't exist? (`type: 'maptools/DISABLE_ALL_TOOLS'`)
          store.dispatch({ type: 'maptools/DISABLE_ALL_TOOLS' });
          setViewModeTo3D() /* .then(()=>{  }) */
            .catch((e) => {
              console.error(e);
            });
          break;
        }

        default:
          break;
      }
    },
    [viewerBaseMapId, viewerMode, epsgCode, viewerModeChanged, epsgCodeChanged],
  );

  return (
    <>
      <nav css={ViewerModesStyle}>
        <button
          css={css`${GenericIconButtonStyle}; ${ToggleStyle}; ${viewerMode === VIEWER_MODES.TWO_D && ToggleActiveStyle};`}
          disabled={!enable2DMode}
          onClick={() => store.dispatch({ type: 'maptools/SET_VIEWER_MODE_TO_2D' })}
        >
          {viewerMode === VIEWER_MODES.TWO_D ? <ViewerMode2D css={IconLightStyle} /> : <ViewerMode2D />}
        </button>

        <button
          css={css`${GenericIconButtonStyle}; ${ToggleStyle}; ${viewerMode === VIEWER_MODES.THREE_D && ToggleActiveStyle};`}
          disabled={!enable3DMode}
          onClick={() => store.dispatch({ type: 'maptools/SET_VIEWER_MODE_TO_3D' })}
        >
          {viewerMode === VIEWER_MODES.THREE_D ? <ViewerMode3D css={IconLightStyle} /> : <ViewerMode3D />}
        </button>
      </nav>

      {viewerMode === VIEWER_MODES.TWO_D && viewerBaseMapSupported && epsgCode && renderMapSwitcher()}
    </>
  );
}
