type MapAttributionsProps = {
  attributionHtmlStrings: Array<string>;
};

/**
 * renders a list of map attributions in the same way as done by OpenLayers, relying on ol classnames
 * todo hevo should be moved to mike-shared-frontend, to keep knowledge of OpenLayers in there.
 * @param props
 */
export const MmgMapAttributionList = (props: MapAttributionsProps) => {
  const { attributionHtmlStrings } = props;
  return (
    <div css="ol-attribution ol-unselectable ol-control ol-uncollapsible">
      <ul>
        {attributionHtmlStrings.map((attribution, indx) => {
          return <li key={attribution + '_' + indx} dangerouslySetInnerHTML={{ __html: attribution }} />;
        })}
      </ul>
    </div>
  );
};
