import { useDispatch, useSelector } from 'react-redux';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import SpatialSelection from '../../icons/SpatialSelection.svg?react';
import { SpatialSelectionLayer } from './spatial-selection-layer';
import { IWorkspaceQuery } from '../../models/IQueries';
import { EWorkspaceQueryActionType } from '../../store/actions/WorkspaceQueryActionType';
import { IGlobalState } from '../../store/reducers';
import { useParams } from 'react-router-dom';

interface IMmgConnectedSpatialSelectionListItemsProps {
  geometryId?: string;
  isParentHidden?: boolean;
}

export const MmgConnectedSpatialSelectionListItems = (props: IMmgConnectedSpatialSelectionListItemsProps) => {
  const getGeometryQueriesSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryQueries();
  const { workspaceId } = useParams();
  const dispatch = useDispatch();
  const { isParentHidden, geometryId } = props;

  const geometryQueries: Array<IWorkspaceQuery> = useSelector((state: IGlobalState) =>
    getGeometryQueriesSelectorInstance(state, {
      geometryId,
    }),
  );

  const onLayerShow = (selectionId: string) => {
    dispatch({ type: EWorkspaceQueryActionType.SELECTION_RESULT_TOGGLE_VISIBILITY, data: {workspaceId, selectionId } });
  };

  const onLayerHide = (selectionId: string) => {
    dispatch({ type: EWorkspaceQueryActionType.SHOWN_SELECTION_GEOMETRY_REMOVE, data: selectionId });
  };

  const SelectionList = () => {
    if (!geometryQueries) {
      return <></>;
    }
    return (
      <>
        {geometryQueries &&
          geometryQueries.map((geometryQuery: IWorkspaceQuery) => {
            return (
              <SpatialSelectionLayer
                layerName={geometryQuery.name}
                layerId={geometryQuery.id}
                leftIcon={<SpatialSelection />}
                key={geometryQuery.id}
                onLayerHide={onLayerHide}
                onLayerShow={onLayerShow}
                isParentHidden={isParentHidden}
                showVisibilityIcon={!isParentHidden}
              />
            );
          })}
      </>
    );
  };

  return <SelectionList />;
};
