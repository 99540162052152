import { useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash-es';
import { store } from '../../store';
import { t } from '../../translations/i18n';
import { ROUTES, getRouteByPath } from '../../app/routes';
import useMount from 'react-use/lib/useMount';
import { usePrevious } from '../../shared/hooks/hooks';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgConnectedMeshSelectList } from '../../meshes/select-list/mesh-select-list';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { getGeometryCurrentMeshIds } from '../../managers/WorkspaceMeshesManager';
import { LinearProgress } from '@mui/material';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';

/**
 * @name MmgConnectedMeshAddGeometrySelectMeshPanel
 * @summary Allows adding the current geometry to an exisiting mesh.
 *
 */
export const MmgConnectedMeshAddGeometrySelectMeshPanel = () => {
  const { workspaceId, projectId, geometryId } = useParams();
  const navigate = useNavigate();
  const getGeometrySelectorInstance = WorkspaceGeometrySelectors.makeGetGeometry();
  const geometry = useSelector((state: IGlobalState) => getGeometrySelectorInstance(state, { geometryId }));
  const { hiddenWorkspaceMeshes, selectedWorkspaceMeshes } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer,
  );
  const meshes = useSelector(WorkspaceMeshSelectors.getSortedEnrichedWorkspaceMeshes);
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceMeshesByIds,
  );

  const [alreadyIncludedMeshIds, setAlreadyIncludedMeshIds] = useState([]);
  const anyAvailableMeshes = meshes && meshes.length > 0;
  const selectedMeshId = selectedWorkspaceMeshes && selectedWorkspaceMeshes[0];
  const isSelectionValid = Boolean(selectedMeshId);
  const previousGeometry = usePrevious(geometry);
  const [alreadyIncludeMeshIdsLoadingFailed, setAlreadyIncludeMeshIdsLoadingFailed] = useState(false);
  const [alreadyIncludeMeshIdsLoading, setAlreadyIncludeMeshIdsLoading] = useState(false);

  const { goBackToReferrer } = useNavigateBack();

  const onNext = () => {
    navigate(getRouteByPath(ROUTES.meshEditConfigurePanelAddGeometry.path, { workspaceId,
      projectId,
      meshId: selectedMeshId,
      geometryId }, ROUTES.workspace.path));     
  };

  const onPanelExit = () => {
    goBackToReferrer();
  };

  const onLayerSelectChanged = useCallback((layerId: string, selected: boolean) => {
    if (selected) {
      // When a new layer is selected, deselect all previous mesh selections.
      store.dispatch({ type: 'workspace/meshes/DESELECT_ALL' });
      store.dispatch({
        type: EWorkspaceMeshActionType.SELECT,
        workspaceMeshId: layerId,
      });
    }
  }, []);

  const getAlreadyIncludedMeshIds = useCallback(
    () => {
      setAlreadyIncludeMeshIdsLoadingFailed(false);
      setAlreadyIncludeMeshIdsLoading(true);

      getGeometryCurrentMeshIds(workspaceId, geometry.id)
        .then((meshIds) => setAlreadyIncludedMeshIds(meshIds))
        .catch(() => setAlreadyIncludeMeshIdsLoadingFailed(true))
        .finally(() => setAlreadyIncludeMeshIdsLoading(false));
    },
    [workspaceId, geometry],
  );

  useMount(() => {
    store.dispatch({ type: 'workspace/meshes/DESELECT_ALL' });
  });

  // NB: this effect needs to run after useMount, that clears selected meshes :)
  useEffect(
    () => {
      if (meshes && meshes.length === 1) {
        // If no mesh is already selected and only one mesh is available, select the first
        onLayerSelectChanged(meshes[0].id, true);
      }
    },
    [onLayerSelectChanged, meshes],
  );

  useEffect(
    () => {
      if (anyAvailableMeshes && geometry && !isEqual(geometry, previousGeometry)) {
        getAlreadyIncludedMeshIds();
      }
    },
    [geometry, previousGeometry, getAlreadyIncludedMeshIds, anyAvailableMeshes],
  );

  // todo hevo redirecting to geometry details indicate that this belongs to the geometry domain, not the mesh domain.
  if (!geometry) {
    navigate(getRouteByPath(ROUTES.geometryDetailsPanel.path, { workspaceId,projectId,geometryId }, ROUTES.workspace.path));
  }

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader
          panelTitle={t('GEOMETRY_ADDTOMESH_PANEL', 1, {
            geometryName: geometry.name,
          })}
        />
        {alreadyIncludeMeshIdsLoading && <LinearProgress />}
      </MikeStickyPanelHeaderContainer>
      <MikeStickyPanelContent>
        <MmgGroup groupName={t('MESH', 2)}>
          {anyAvailableMeshes &&
            (!alreadyIncludeMeshIdsLoadingFailed ? (
              <MmgConnectedMeshSelectList
                meshes={meshes}
                hiddenMeshes={hiddenWorkspaceMeshes}
                drawnMeshes={drawnWorkspaceMeshes}
                selectedMeshes={[...alreadyIncludedMeshIds, ...selectedWorkspaceMeshes]}
                disabledMeshes={alreadyIncludedMeshIds}
                onLayerSelectChanged={onLayerSelectChanged}
              />
            ) : (
              <MmgPanelSubsection>
                <p>{t('MESH_ADD_TO_MESH_GET_ALREADY_INCLUDED_FAILED')}</p>
                <MikeButton variant="contained" color="secondary" onClick={getAlreadyIncludedMeshIds}>{t('RETRY')}</MikeButton>
              </MmgPanelSubsection>
            ))}

          {!anyAvailableMeshes && (
            <MmgPanelSubsection>
              <p>{t('NO_MESHES_TO_ADD_TO_TIP')}</p>

              <MikeButton variant="outlined" color="secondary" onClick={onPanelExit} fullWidth>
                {t('GOT_IT')}
              </MikeButton>
            </MmgPanelSubsection>
          )}
        </MmgGroup>
      </MikeStickyPanelContent>
      <MikeStickyPanelBottomActions>
        <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
          {t('CANCEL')}
        </MikeButton>
        <MikeButton disabled={!isSelectionValid} variant="contained" color="secondary" onClick={onNext}>
          {t('MESH_GO_TO_CONFIGURATION')}
        </MikeButton>
      </MikeStickyPanelBottomActions>
    </MikeStickyPanel>
  );
};
