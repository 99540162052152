import { t } from '../../translations/i18n';
import { IDrawnDataItem, IWorkspaceEnrichedDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { getShortDateTime, translateWithPrefix, translateWithSuffix } from '../../translations/utils';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import LayerUtils, { isLayerOutdated } from '../../shared/layers/layer-utils';
import { ELEMENT_CATEGORIES } from './mesh-panel-constants';

const { isLayerLoading, isLayerFailed, isLayerInProgress, isLayerConflicting } = LayerUtils;

/**
 * Gets mesh description text (typically shown under the mesh name).
 *
 * @param mesh
 * @param drawnMeshes // if given the loading state of the mesh will be considered
 * @param includeErrorMessage If true error message for failed and conflicting states will be included
 */
export const getMeshDescription = (
  mesh: IWorkspaceEnrichedMesh,
  drawnMeshes?: Array<IDrawnDataItem>,
  includeErrorMessage = true,
) => {
  if (!mesh) {
    return '';
  }

  // If drawnMeshes is not provided, we do not consider the loading state when getting the description
  const isLoading = drawnMeshes ? isLayerLoading(mesh, drawnMeshes) : false;

  if (isLoading) {
    return t('MESH_LOADING');
  }

  if (!includeErrorMessage && isLayerFailed(mesh)) {
    return t('OP_MESH_FAILED');
  }

  if (isLayerFailed(mesh) || isLayerInProgress(mesh)) {
    return mesh.message;
  }

  if (isLayerConflicting(mesh) && includeErrorMessage) {
    return mesh.message;
  }

  if (isLayerOutdated(mesh)) {
    return t('OP_MESH_OUTDATED');
  }
  if (mesh.updated) {
    const lastUpdated = t('UPDATED', 1, {
      thing: getShortDateTime(mesh.updated),
    });
    return lastUpdated;
  }

  return '';
};

/**
 * Gets variable description text (typically shown under the variable name).
 *
 * @param variable
 * @param drawnVariables
 */
export const getVariableDescription = (
  variable: IWorkspaceEnrichedVariable,
  drawnVariables?: Array<IDrawnDataItem>,
) => {
  // If drawnVariables is not provided, we do not consider the loading state when getting the description
  const isLoading = drawnVariables ? isLayerLoading(variable, drawnVariables) : false;

  if (!variable) {
    return '';
  }

  if (isLoading) {
    return t('VARIABLE_LOADING');
  }

  return translateWithPrefix('VARTYPE', variable.itemType || 'VARIABLE');
};

/**
 * Gets geometry description text (typically shown under the geometry name).
 *
 * @param geometry
 * @param drawnGeometries
 * @param includeErrorMessage If true error message for failed and conflicting states will be included
 */
export const getGeometryDescription = (
  geometry: IWorkspaceEnrichedGeometry,
  drawnGeometries?: Array<IDrawnDataItem>,
  includeErrorMessage = true,
) => {
  // If drawnGeometries is not provided, we do not consider the loading state when getting the description
  const isLoading = drawnGeometries ? isLayerLoading(geometry, drawnGeometries) : false;

  if (!geometry) {
    return '';
  }

  if (isLoading) {
    return t('GEOMETRY_LOADING');
  }

  if (!includeErrorMessage && isLayerFailed(geometry)) {
    return t('OP_GEOMETRY_FAILED');
  }

  if (!includeErrorMessage && isLayerConflicting(geometry)) {
    return t('OP_GEOMETRY_CONFLICTING');
  }

  if (isLayerFailed(geometry) || isLayerInProgress(geometry) || isLayerConflicting(geometry)) {
    return geometry.message;
  }

  return geometry.isHole ? translateWithSuffix('HOLE', geometry.itemType) : t(geometry.itemType);
};

export const getLayerDescription = (layer: IWorkspaceEnrichedDataItem): React.ReactNode => {
  if (!layer) {
    return '';
  }

  switch (layer.category) {
    case ELEMENT_CATEGORIES.GEOMETRY:
      return getGeometryDescription(layer);
    case ELEMENT_CATEGORIES.VARIABLE:
      return getVariableDescription(layer);
    case ELEMENT_CATEGORIES.MESH:
      return getMeshDescription(layer);
    default: {
      console.debug(`No layer description found for ${layer.category} `);
      return '';
    }
  }
};

/**
 * Gets description text for panels showing operations on meshes, such as Interpolations or Post-Operations
 *
 * @param mesh
 * @param drawnMeshes // if given the loading state of the mesh will be considered
 * @param includeErrorMessage If true error message for failed and conflicting states will be included
 */
export const getMeshOperationPanelDescription = (
  mesh: IWorkspaceEnrichedMesh,
  drawnMeshes?: Array<IDrawnDataItem>,
  includeErrorMessage = true,
) => {
  const descriptions = [];
  const meshName = mesh && mesh.name;

  if (meshName) {
    descriptions.push(meshName);
  }

  const includeMeshStatus = drawnMeshes ? isLayerLoading(mesh, drawnMeshes) : isLayerInProgress(mesh);

  if (includeMeshStatus) {
    const meshStatusMessage = getMeshDescription(mesh, drawnMeshes, includeErrorMessage);
    descriptions.push(meshStatusMessage);
  }

  return descriptions.join(' - ');
};

/**
 * Gets description text for panels showing operations on geometries, such as GeometryOperations
 * @param geometry
 * @param drawnGeometries // if given the loading state of the geometry will be considered
 * @param includeErrorMessage If true error message for failed and conflicting states will be included
 */
export const getGeometryOperationPanelDescription = (
  geometry: IWorkspaceEnrichedGeometry,
  drawnGeometries?: Array<IDrawnDataItem>,
  includeErrorMessage = true,
) => {
  const descriptions = [];
  const geometryName = geometry && geometry.name;

  if (geometryName) {
    descriptions.push(geometryName);
  }

  const includeGeometryStatus = drawnGeometries
    ? isLayerLoading(geometry, drawnGeometries)
    : isLayerInProgress(geometry);

  if (includeGeometryStatus) {
    const statusMessage = getGeometryDescription(geometry, drawnGeometries, includeErrorMessage);
    descriptions.push(statusMessage);
  }

  return descriptions.join(' - ');
};

/**
 * Gets description text for panels showing operations on variable, such as VariableOperations
 * @param variable
 * @param drawnVariables // if given the loading state of the variable will be considered
 */
export const getVariableOperationPanelDescription = (
  variable: IWorkspaceEnrichedVariable,
  drawnVariables?: Array<IDrawnDataItem>,
) => {
  const descriptions = [];
  const variableName = variable && variable.name;

  if (variableName) {
    descriptions.push(variableName);
  }

  const includeVariableStatus = drawnVariables ? isLayerLoading(variable, drawnVariables) : isLayerInProgress(variable);

  if (includeVariableStatus) {
    const statusMessage = getVariableDescription(variable, drawnVariables);
    descriptions.push(statusMessage);
  }

  return descriptions.join(' - ');
};
