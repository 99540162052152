/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useState } from 'react';
import { t } from '../../../translations/i18n';
import { useSelector } from 'react-redux';
import { store } from '../../../store';
import Terrain3DExaggeration from '../../../icons/Terrain3DExaggeration.svg?react';
import Tooltip from '@mui/material/Tooltip';
import {
  ViewerToolsButtonStyle,
  ViewerToolsWithSubmenuMenuStyle,
} from '../../../workspaces/viewer/tools/viewer-tools-styles';
import ViewerToolUtils from '../../../workspaces/viewer/tools/viewer-tool-utils';
import { SUBMENUS, DEFAULT_MULTIPLIERS } from '../../../workspaces/viewer/tools/viewer-tool-constants';
import { EViewerModes } from '../../../MikeVisualizer/lib/IMikeVisualizerModels';
import { usePrevious } from '../../../shared/hooks/hooks';
import { IGlobalState } from '../../../store/reducers';

const { toggleSubmenu } = ViewerToolUtils;

const ViewerToolsButtonExaggerationIndicatorStyle = css`
  position: absolute;
  right: 2px;
  bottom: 2px;
  font-size: 0.55em;
  padding: 4px;
  background-color: white;
  border-radius: 50%;
`;

type ViewerTool3DExaggerationProps = {
  viewerZScaleMultiplier: number;
  viewerZScale: number;
  updateViewerZScaleMultiplier: (multiplier: number, zScale: number) => void;
  disabled?: boolean;
};

/**
 * @name MmgConnectedViewerTool3DExaggeration
 * @summary Allows changing 3d viewer exaggeration
 *
 * @param props
 */
export function MmgConnectedViewerTool3DExaggeration(props: ViewerTool3DExaggerationProps) {
  const openSubmenu: string = useSelector((state: IGlobalState) => state.MapToolReducer.openSubmenu);
  const viewerMode: EViewerModes = useSelector((state: IGlobalState) => state.ViewerModeReducer.viewerMode);
  const [viewerZScaleMultipliers] = useState(DEFAULT_MULTIPLIERS);
  const { viewerZScaleMultiplier, viewerZScale, updateViewerZScaleMultiplier, disabled } = props;
  const previousViewerMode = usePrevious(viewerMode);

  // Toggles the z-scale submenu to allow selection of a certain scale.
  function toggleZScaleSubmenu() {
    toggleSubmenu(SUBMENUS.Z_SCALE);
  }

  const updateViewerZScale = useCallback((multiplier: number, scale: number) => {
    toggleZScaleSubmenu();
    updateViewerZScaleMultiplier(multiplier, scale);
    // A multiplier of 1 is considered default, and does not set the tool enabled
    if (multiplier !== 1) {
      store.dispatch({ type: 'maptools/ENABLE_3D_EXAGGARATION' });
    }
    // The values above shall not change during component life cycle:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Make sure that the current viewerZScaleMultiplier is applied again when viewer is 3D:
  // (The value is reset in mesh-viewer when 2D is activated)
  useEffect(
    () => {
      if (viewerMode !== previousViewerMode && viewerMode === EViewerModes.THREE_D) {
        updateViewerZScale(viewerZScaleMultiplier, viewerZScale);
      }
    },
    [viewerMode, previousViewerMode, viewerZScaleMultiplier, viewerZScale, updateViewerZScale],
  );

  return (
    <>
      <Tooltip title={t('TOOL_TERRAIN_3D_EXAGGERATION')} placement="right">
        <button css={ViewerToolsButtonStyle} onClick={toggleZScaleSubmenu} disabled={disabled}>
          <Terrain3DExaggeration />
          <span css={ViewerToolsButtonExaggerationIndicatorStyle}>{viewerZScaleMultiplier}x</span>
        </button>
      </Tooltip>

      <ul css={ViewerToolsWithSubmenuMenuStyle} hidden={openSubmenu !== SUBMENUS.Z_SCALE}>
        {viewerZScaleMultipliers.filter((multiplier) => multiplier !== viewerZScaleMultiplier).map((multiplier) => (
          <li key={multiplier}>
            <button css={ViewerToolsButtonStyle} onClick={() => updateViewerZScale(multiplier, viewerZScale)}>
              {multiplier}x
            </button>
          </li>
        ))}
      </ul>
    </>
  );
}
