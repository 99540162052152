/**
 * Exposes methods to transform or combine operation requests.
 *
 * @module OperationsManager
 * @version 2.0.0
 * @requires OperationsProxy
 */
import * as OperationsProxy from '../proxies/OperationsProxy';
import { HttpErrorHandler } from './http-utils';
import OperationMappingUtils from './operation-mapping-utils';

import { IOperationConfiguration } from '../models/IOperations';

/**
 * Transforms a request that executes an existing operation by id.
 *
 * @param workspaceId
 * @param operationId
 */
const executeOperationById = (workspaceId: string, operationId: string) => {
  OperationsProxy.executeOperationById(workspaceId, operationId)
    .then((res) => {
      if (!res) {
        return Promise.resolve<IOperationConfiguration>(null);
      }

      const operation = OperationMappingUtils.mapOperationApiToOperationConfiguration(res.data);

      return operation;
    })
    .catch((error) => HttpErrorHandler('Failed to exceute operation by id.', error));
};

/**
 * Transforms a request that exceute an operation of any kind

 * @param workspaceId
 * @param operationConfiguration
 */
const executeOperation = (
  workspaceId: string,
  operationConfiguration: IOperationConfiguration,
): Promise<IOperationConfiguration | void> => {
  const operationPayload = OperationMappingUtils.mapOperationConfigurationToOperationApi(operationConfiguration);

  return OperationsProxy.executeOperation(workspaceId, operationPayload)
    .then((res) => {
      if (!res) {
        return Promise.resolve<IOperationConfiguration>(null);
      }

      const operation = OperationMappingUtils.mapOperationApiToOperationConfiguration(res.data);

      return operation;
    })
    .catch((error) => HttpErrorHandler('Failed to exceute operation.', error));
};

/**
 * Transforms a request that creates an operation of any kind without executing it
 *
 * @param workspaceId
 * @param operationConfiguration
 */
const createOperation = (
  workspaceId: string,
  operationConfiguration: IOperationConfiguration,
): Promise<IOperationConfiguration | void> => {
  const operationPayload = OperationMappingUtils.mapOperationConfigurationToOperationApi(operationConfiguration);

  return OperationsProxy.createOperation(workspaceId, operationPayload)
    .then((res) => {
      if (!res) {
        return Promise.resolve<IOperationConfiguration>(null);
      }
      const operation = OperationMappingUtils.mapOperationApiToOperationConfiguration(res.data);

      return operation;
    })
    .catch((error) => HttpErrorHandler('Failed to create operation.', error));
};

/**
 * Transforms a request that gets an operation given by the operation id.
 *
 * @param workspaceId
 * @param operationId
 */
const getOperation = (workspaceId: string, operationId: string) => {
  if (!operationId) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  return OperationsProxy.getOperation(workspaceId, operationId)
    .then((res) => {
      const operationConfiguration = OperationMappingUtils.mapOperationApiToOperationConfiguration(res.data);

      return operationConfiguration;
    })
    .catch((error) => HttpErrorHandler('Failed to get operation.', error)) as Promise<IOperationConfiguration>;
};

/**
 * Transforms a request that deletes an operation given by the operation id.
 *
 * @param workspaceId
 * @param operationId
 */
const deleteOperation = (workspaceId: string, operationId: string) => {
  return OperationsProxy.deleteOperation(workspaceId, operationId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to delete operation.', error));
};

const cancelOperation = (workspaceId: string, operationId: string) => {
  return OperationsProxy.cancelOperation(workspaceId, operationId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to delete operation.', error));
};

const getMeshPostOperations = (workspaceId: string, meshId: string) => {
  return OperationsProxy.getCurrentMeshPostOperations(workspaceId, meshId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to delete operation.', error));
};

const getWorkspaceOperations = async (workspaceId: string) => {
  const res = await OperationsProxy.getWorkspaceOperations(workspaceId);
  const apiOperationDescriptions = res.data as Array<any>;

  const operationDescriptions = OperationMappingUtils.mapOperationDescriptions(apiOperationDescriptions);

  return operationDescriptions;
};

const self = {
  executeOperation,
  createOperation,
  getOperation,
  deleteOperation,
  executeOperationById,
  cancelOperation,
  getMeshPostOperations,
  getWorkspaceOperations,
};

export default self;
