import { IGeometryLabel } from '../../workspaces/viewer/viewer-utils';
import { IDrawnDataGradient, IDrawnDataItem } from '../../models/IWorkspaceData';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EWorkspaceDataActionType } from '../actions/WorkspaceDataActionType';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';
import { IUserSettings } from '../../models/IUserSettings';
import { uniq } from 'lodash-es';

const { updateElementGradient, setRepresentation, setColorToElement, setPointSize } = MikeVisualizerLib;

export interface IWorkspaceDataState {
  workspaceData: Array<IDrawnDataItem>;
  workspaceDataGradients: Array<IDrawnDataGradient>;
  statistic: string | null;
  loadedData: Array<string>;
  failedData: Array<string>;
  settings: IUserSettings | null;
  savedUserSettings: IUserSettings | null;
  settingsInitialized: boolean;
  geometryAttributeLabels: IGeometryLabelConfig | null;
  editingGeometryIds: Array<string>;
  editingMeshIds: Array<string>;
  editingVariableIds: Array<string>;
}

interface IGeometryLabelData {
  attributeName: string;
  labels: Array<IGeometryLabel>;
}

export interface IGeometryLabelConfig {
  [key: string]: Array<IGeometryLabelData>;
}

const initialState: IWorkspaceDataState = {
  workspaceData: [], // Data that has been rendered in the current workspace.
  workspaceDataGradients: [],
  statistic: null,
  loadedData: [],
  failedData: [],
  settings: null,
  savedUserSettings: null,
  settingsInitialized: false,
  geometryAttributeLabels: null,
  editingGeometryIds: [],
  editingMeshIds: [],
  editingVariableIds: [],
};

/**
 * Workspace Data Reducer.
 * - returns new states for matched workspace actions.
 * It holds state for drawn items and their related visual properties.
 *
 * @name WorkspaceDataReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IWorkspaceDataState = initialState, action) {
  // Quickly get index of dataItem:
  const getItemIndex = (itemId: string) => state.workspaceData.findIndex((item) => item.id === itemId);

  switch (action.type) {
    case EWorkspaceDataActionType.ADD_EDITING_GEOMETRY: {
      const without = state.editingGeometryIds.filter((id: string) => id !== action.data);
      return {
        ...state,
        editingGeometryIds: without.concat(action.data),
      };
    }
    case EWorkspaceDataActionType.ADD_EDITING_MESH: {
      const without = state.editingMeshIds.filter((id: string) => id !== action.data);
      return {
        ...state,
        editingMeshIds: without.concat(action.data),
      };
    }
    case EWorkspaceDataActionType.ADD_EDITING_VARIABLE: {
      const without = state.editingMeshIds.filter((id: string) => id !== action.data);
      return {
        ...state,
        editingVariableIds: without.concat(action.data),
      };
    }
    case EWorkspaceDataActionType.REMOVE_EDITING_GEOMETRY: {
      return {
        ...state,
        editingGeometryIds: state.editingGeometryIds.filter((id: string) => id !== action.data),
      };
    }
    case EWorkspaceDataActionType.REMOVE_EDITING_MESH: {
      return {
        ...state,
        editingMeshIds: state.editingMeshIds.filter((id: string) => id !== action.data),
      };
    }
    case EWorkspaceDataActionType.REMOVE_EDITING_VARIABLE: {
      return {
        ...state,
        editingVariableIds: state.editingVariableIds.filter((id: string) => id !== action.data),
      };
    }
    case EWorkspaceDataActionType.CLEAR_EDITING_GEOMETRY: {
      return {
        ...state,
        editingGeometryIds: [],
      };
    }
    case EWorkspaceDataActionType.CLEAR_EDITING_MESH: {
      return {
        ...state,
        editingMeshIds: [],
      };
    }
    case EWorkspaceDataActionType.CLEAR_EDITING_VARIABLE: {
      return {
        ...state,
        editingVariableIds: [],
      };
    }
    case EWorkspaceDataActionType.SET_UPDATED_USER_SETTINGS: {
      return {
        ...state,
        savedUserSettings: action.data,
      };
    }
    case EWorkspaceDataActionType.UPDATE_USER_SETTINGS: {
      const saveSettings = state.savedUserSettings === null ? action.data.settings : state.savedUserSettings;
      return {
        ...state,
        settings: action.data.settings,
        settingsInitialized: true,
        savedUserSettings: saveSettings,
      };
    }
    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case EWorkspaceDataActionType.ADD_LABELS: {
      const { labels } = action.labels;

      return {
        ...state,
        geometryAttributeLabels: labels,
      };
    }

    case EWorkspaceDataActionType.ADD_FAILED_ITEM: {
      return {
        ...state,
        failedData: [...state.failedData, action.failedItemId],
      };
    }

    case EWorkspaceDataActionType.ADD_DATA_ITEM: {
      const workspaceItem: IDrawnDataItem = {
        length: action.workspaceLength,
        id: action.workspaceItemId,
        dataId: action.workspaceItemDataId,
        representation: action.workspaceRepresentation,
        pointSize: action.workspacePointSize,
        edgeColor: action.workspaceDataEdgeColor,
        surfaceColor: action.workspaceDataSurfaceColor,
        dataArrays: action.workspaceDataArrays,
        fieldData: action.workspaceFieldData,
        attributes: action.workspaceAttributes,
        itemType: action.workspaceItemType,
        drawn: action.workspaceItemDrawn,
        updated: action.workspaceItemUpdated,
        statisticsAttributeName: action.statisticsAttributeName || null,
        zAttributeName: action.zAttributeName,
      };
      return {
        ...state,
        //do the same when you want to check if the layer is loaded
        workspaceData: [...state.workspaceData.filter(({ id }) => id !== workspaceItem.id), workspaceItem],
        loadedData: uniq([action.workspaceItemId, ...state.loadedData]),
      };
    }

    case EWorkspaceDataActionType.REMOVE_GRADIENT: {
      const { isTiled, elementId } = action.data;
      !isTiled && updateElementGradient(elementId, '');
      const workspaceDataGradients = state.workspaceDataGradients.filter((item) => {
        return item.elementId !== elementId;
      });
      return {
        ...state,
        workspaceDataGradients,
      };
    }

    case EWorkspaceDataActionType.UPDATE_DATA_ITEM: {
      const workspaceItemIndex = getItemIndex(action.workspaceItemId);
      const oldItem = state.workspaceData[workspaceItemIndex];
      const workspaceItem: IDrawnDataItem = {
        ...oldItem,
        id: action.workspaceItemId,
        length: newOrOld(action.workspaceLength, oldItem.length),
        dataId: newOrOld(action.workspaceItemDataId, oldItem.dataId),
        representation: newOrOld(action.workspaceRepresentation, oldItem.representation),
        pointSize: newOrOld(action.workspacePointSize, oldItem.pointSize),
        edgeColor: newOrOld(action.workspaceDataEdgeColor, oldItem.edgeColor),
        surfaceColor: newOrOld(action.workspaceDataSurfaceColor, oldItem.surfaceColor),
        dataArrays: newOrOld(action.workspaceDataArrays, oldItem.dataArrays),
        fieldData: newOrOld(action.workspaceFieldData, oldItem.fieldData),
        attributes: newOrOld(action.workspaceAttributes, oldItem.attributes),
        itemType: newOrOld(action.workspaceItemType, oldItem.itemType),
        drawn: newOrOld(action.workspaceItemDrawn, oldItem.drawn),
        updated: newOrOld(action.workspaceItemUpdated, oldItem.updated),
        statisticsAttributeName: newOrOld(action.statisticsAttributeName, oldItem.statisticsAttributeName),
        zAttributeName: newOrOld(action.zAttributeName, oldItem.zAttributeName),
      };

      // TODO: joel; Require a match or abandon:
      // Add new item if not found
      if (workspaceItemIndex === -1) {
        return {
          ...state,
          workspaceData: [...state.workspaceData, workspaceItem],
        };
      }

      // replace exisitng item
      return {
        ...state,
        workspaceData: [
          ...state.workspaceData.slice(0, workspaceItemIndex),
          workspaceItem,
          ...state.workspaceData.slice(workspaceItemIndex + 1),
        ],
      };
    }

    case EWorkspaceDataActionType.DELETE_DATA_ITEM: {
      const workspaceData = state.workspaceData.filter((item) => item.id !== action.workspaceItemId);

      const workspaceDataGradients = state.workspaceDataGradients.filter(
        (item) => item.elementId !== action.workspaceItemId,
      );

      return {
        ...state,
        workspaceData,
        workspaceDataGradients,
      };
    }

    case EWorkspaceDataActionType.DATA_ITEMS_DELETED: {
      const idsToDelete = action.workspaceItemIds as Array<string>;

      if (!idsToDelete || idsToDelete.length === 0) {
        return state;
      }

      const workspaceData = state.workspaceData.filter((d) => idsToDelete.indexOf(d.id) === -1);
      const workspaceDataGradients = state.workspaceDataGradients.filter(
        (dg) => idsToDelete.indexOf(dg.elementId) === -1,
      );

      return {
        ...state,
        workspaceData,
        workspaceDataGradients,
      };
    }

    case EWorkspaceDataActionType.UPDATE_ITEM_DATAARRAYS: {
      const { itemId, dataId, dataArrays, attributes, itemUpdated } = action;
      const dataItemIndex = getItemIndex(itemId);
      // nothing to update
      if (dataItemIndex === -1) {
        return state;
      }

      const updatedDataItem: IDrawnDataItem = {
        ...(state.workspaceData[dataItemIndex] as IDrawnDataItem),

        id: itemId,
        dataId,
        dataArrays,
        attributes,
        updated: itemUpdated,
        // ,length // todo hevo????
      };

      // replace existing item
      return {
        ...state,
        workspaceData: [
          ...state.workspaceData.slice(0, dataItemIndex),
          updatedDataItem,
          ...state.workspaceData.slice(dataItemIndex + 1),
        ],
      };
    }

    case EWorkspaceDataActionType.ITEM_DATAARRAY_DELETED: {
      const { itemId, dataId, attributeName, itemUpdated } = action;
      const dataItemIndex = getItemIndex(action.itemId);
      // nothing to update
      if (dataItemIndex === -1) {
        return state;
      }

      const dataItem = state.workspaceData[dataItemIndex] as IDrawnDataItem;
      // nothing to update
      if (!dataItem.dataArrays && !dataItem.attributes) {
        return state;
      }

      const dataArrays = dataItem.dataArrays
        ? dataItem.dataArrays.filter(({ id }) => id !== attributeName)
        : dataItem.dataArrays;

      const attributes = dataItem.attributes
        ? dataItem.attributes.filter(({ name }) => name !== attributeName)
        : dataItem.attributes;

      const updatedDataItem: IDrawnDataItem = {
        ...dataItem,

        id: itemId,
        dataId,
        dataArrays,
        attributes,
        // length, // todo hevo????
        updated: itemUpdated,
      };

      // if a gradient is currently applied to the attribute being delete, we remove the gradient
      const workspaceDataGradients = state.workspaceDataGradients.filter(
        (item) => !(item.gradientAttributeName === attributeName && item.elementId === action.itemId),
      );

      // replace existing item
      return {
        ...state,
        workspaceData: [
          ...state.workspaceData.slice(0, dataItemIndex),
          updatedDataItem,
          ...state.workspaceData.slice(dataItemIndex + 1),
        ],
        workspaceDataGradients,
      };
    }

    case EWorkspaceDataActionType.UPDATE_GRADIENT: {
      const {
        isTiled,
        workspaceGradientId,
        workspaceGradientAttributeName,
        workspaceGradientSettings,
        colorRange,
      } = action.data;

      if (!isTiled) {
        updateElementGradient(
          workspaceGradientId,
          workspaceGradientAttributeName,
          workspaceGradientSettings,
          colorRange,
        );
      }

      const currentGradientIndex = state.workspaceDataGradients.findIndex(
        ({ elementId }) => elementId === workspaceGradientId,
      );

      // Add new gradient if not found
      if (currentGradientIndex === -1) {
        return {
          ...state,
          workspaceDataGradients: [
            ...state.workspaceDataGradients,
            {
              elementId: workspaceGradientId,
              gradientAttributeName: workspaceGradientAttributeName,
              gradientSettings: workspaceGradientSettings,
              colorRange,
              isTiled,
            },
          ],
        };
      }

      // replace existing gradient with new one
      const updatedGradients = state.workspaceDataGradients.map((d: IDrawnDataGradient) => {
        return d.elementId !== workspaceGradientId
          ? d
          : {
              elementId: workspaceGradientId,
              gradientAttributeName: workspaceGradientAttributeName,
              gradientSettings: workspaceGradientSettings,
              colorRange,
              isTiled,
            };
      });
      return {
        ...state,
        workspaceDataGradients: updatedGradients,
      };
    }

    case EWorkspaceDataActionType.UPDATE_ITEM_REPRESENTATION: {
      const { isTiled, ignoreOverview, workspaceRepresentation, workspaceItemId } = action.data;
      const ignore = isTiled && ignoreOverview; // do not apply representation to overview polygon
      !ignore && setRepresentation(workspaceItemId, workspaceRepresentation);

      return {
        ...state,
        workspaceData: state.workspaceData.map((di: IDrawnDataItem) => {
          return di.id === workspaceItemId
            ? {
                ...di,
                representation: workspaceRepresentation,
              }
            : di;
        }),
      };
    }

    case EWorkspaceDataActionType.UPDATE_ITEM_COLOR: {
      const { elementId, workspaceDataEdgeColor, workspaceDataSurfaceColor } = action.data;
      setColorToElement(elementId, workspaceDataEdgeColor, workspaceDataSurfaceColor);

      // Setting a color clears the current gradient.
      const workspaceDataGradients = state.workspaceDataGradients.filter(
        (de: IDrawnDataGradient) => de.elementId !== elementId,
      );

      return {
        ...state,
        workspaceDataGradients,
        workspaceData: state.workspaceData.map((di: IDrawnDataItem) => {
          return di.id === elementId
            ? {
                ...di,
                edgeColor: workspaceDataEdgeColor,
                surfaceColor: workspaceDataSurfaceColor,
              }
            : di;
        }),
      };
    }

    case EWorkspaceDataActionType.UPDATE_ITEM_POINTSIZE: {
      const { isTiled, ignoreOverview, workspaceItemId, pointSize } = action.data;
      const ignore = isTiled && ignoreOverview; // do not apply representation to overview polygon
      !ignore && setPointSize(workspaceItemId, pointSize);

      return {
        ...state,
        workspaceData: state.workspaceData.map((di: IDrawnDataItem) => {
          return di.id === workspaceItemId
            ? {
                ...di,
                pointSize,
              }
            : di;
        }),
      };
    }

    case EWorkspaceDataActionType.UPDATE_ITEM_ZATTRIBUTENAME: {
      const dataItemIndex = getItemIndex(action.workspaceItemId);
      if (dataItemIndex === -1) {
        return state;
      }
      const dataItem = state.workspaceData[dataItemIndex];
      return {
        ...state,
        workspaceData: [
          ...state.workspaceData.slice(0, dataItemIndex),
          { ...dataItem, zAttributeName: action.zAttributeName },
          ...state.workspaceData.slice(dataItemIndex + 1),
        ],
      };
    }

    case EWorkspaceDataActionType.UPDATE_ITEM_STATISTICATTRIBUTENAME: {
      const { workspaceItemId, statisticsAttributeName } = action.data;
      const dataItemIndex = getItemIndex(workspaceItemId);
      if (dataItemIndex === -1) {
        return state;
      }
      const dataItem = state.workspaceData[dataItemIndex];
      return {
        ...state,
        workspaceData: [
          ...state.workspaceData.slice(0, dataItemIndex),
          { ...dataItem, statisticsAttributeName },
          ...state.workspaceData.slice(dataItemIndex + 1),
        ],
      };
    }

    default:
      return state;
  }
}

/**
 * Check for undefined: If new value is defined return it, if undefined, return the old value.
 * @param newValue
 * @param oldValue
 * @returns
 */
function newOrOld<T = any, U = any>(newValue: T, oldValue: U) {
  if (newValue !== undefined) {
    return newValue;
  } else {
    return oldValue;
  }
}
