/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import MikeVisualizerLib from '../../../MikeVisualizer/lib/MikeVisualizer';
import { MAPBOX_ATTRIBUTIONS } from '../../../MikeVisualizer/MikeBaseMapConfigurations';
import { MmgMapboxWordmap } from './mapbox-wordmap';
import { useSelector } from 'react-redux';
import { isMapboxMap } from '../../../workspaces/viewer/tools/viewer-mode-utils';
import { VIEWER_MODES } from '../../../MikeVisualizer/lib/IMikeVisualizerModels';
import { MmgMapAttributionList } from './map-attribution-list';
import {  MAP_DATA_TYPES } from '../../../models/IMaps';
import { IGlobalState } from '../../../store/reducers';

const { onBaseMapChanged, onBaseMapDestroyed, setupMapAttributionControl } = MikeVisualizerLib;


const ViewerAttributionContainerCss = css`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: baseline;
`;

const ViewerAttributionsCss = css`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;

  & > .ol-attribution {
    position: relative;
  }
`;

/**
 * @name MmgConnectedViewerMapAttributions
 * @summary Holds the map attributions of the viewer
 *
 */
export const MmgConnectedViewerMapAttributions = () => {
  const { viewerBaseMapId, viewerMode } = useSelector((state: IGlobalState) => state.ViewerModeReducer);
  const { mapDataType } = useSelector((state: IGlobalState) => state.MapToolReducer);

  const MAP_ATTRIBUTION_CONTAINER_ID = 'MAP_ATTRIBUTION_CONTAINER';

  // currently all available map data rely on mapbox. If other sources get added later on, this need to be more generic.
  const attributionsForMapData = mapDataType !== MAP_DATA_TYPES.NONE ? MAPBOX_ATTRIBUTIONS : null;

  const showMapboxWordMap =
    (viewerMode === VIEWER_MODES.THREE_D && attributionsForMapData && attributionsForMapData.length > 0) ||
    (viewerMode === VIEWER_MODES.TWO_D && isMapboxMap(viewerBaseMapId));

  const getAttributionControlTarget = () => {
    return document.getElementById(MAP_ATTRIBUTION_CONTAINER_ID);
  };

  const setupAttributionControl = () => {
    const target = getAttributionControlTarget();
    setupMapAttributionControl(target);
    return;
  };

  const clearAttributionControl = () => {
    const target = getAttributionControlTarget();

    if (target) {
      target.innerHTML = '';
    }
    return target;
  };

  useEffect(() => {
    const events = [
      onBaseMapChanged(() => setupAttributionControl()),
      onBaseMapDestroyed(() => clearAttributionControl()),
    ];

    return () => events.forEach((unsubscribe) => unsubscribe());
  });

  return (
    <div css={ViewerAttributionContainerCss}>
      {showMapboxWordMap && <MmgMapboxWordmap />}

      {viewerMode === VIEWER_MODES.TWO_D && <div id={MAP_ATTRIBUTION_CONTAINER_ID} css={ViewerAttributionsCss} />}

      {viewerMode === VIEWER_MODES.THREE_D &&
        attributionsForMapData &&
        attributionsForMapData.length > 0 && (
          <div css={ViewerAttributionsCss}>
            <MmgMapAttributionList attributionHtmlStrings={attributionsForMapData} />
          </div>
        )}
    </div>
  );
};
