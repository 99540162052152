/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IDrawnDataLegend } from '../models/IWorkspaceData';
import { IGlobalState } from '../store/reducers';
import WorkspaceLegendSelectors from '../store/selectors/WorkspaceLegendSelectors';
import MmgViewerColorLegendItem, {
  ellipsisStyle,
  legendListitemStyle,
  legendListitemTextStyle,
} from './viewer-color-legenditem';
import { FeatureCollection } from 'geojson';
import { LABELFONTSIZE } from '../workspaces/viewer/viewer-utils';
import { Typography } from '@mui/material';
import { EGeometryItemTypes } from '../models/IGeometries';
import { addLineColor, deleteLineColor, modifyLineColor } from '../store/reducers/EditReducer';
import { EItemType } from '../models/IOperationDescriptions';
import { IChangedNode } from '../store/reducers/MeshMapToolReducer';
import mikeSharedTheme, { FONTFAMILY } from '../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../MikeVisualizer/colors/mike-colors';

const containerStyle = css`
  background-color: ${mikeSharedTheme.palette.background.default};
`;

const legendListitemColorStyle = (paddingLeft: number) => {
  return css`
    width: ${mikeSharedTheme.spacing(2)};
    height: ${mikeSharedTheme.spacing(2)};
    padding-left: ${mikeSharedTheme.spacing(paddingLeft)};
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: ${MIKE_COLORS.PINK_DEFAULT};
    color: ${MIKE_COLORS.WHITE};
    border-radius: 50%;
    font: ${LABELFONTSIZE} ${FONTFAMILY};
  `;
};

const legendListLineitemColorStyle = (color: string) => {
  return css`
    width: ${mikeSharedTheme.spacing(3)};
    height: ${mikeSharedTheme.spacing(0.5)};
    background-color: ${color};
  `;
};

const MmgViewerColorLegend = () => {
  const workspaceDataLegends: Array<IDrawnDataLegend> = useSelector((state: IGlobalState) =>
    WorkspaceLegendSelectors.getLegends(state),
  );

  const previousEdits: Array<FeatureCollection<any, any>> = useSelector(
    (state: IGlobalState) => state.EditReducer.previousEdits,
  );

  const nodesToUpdate: Array<IChangedNode> = useSelector((state: IGlobalState) => state.EditReducer.nodesToUpdate);

  const geometryEditItemType: EGeometryItemTypes = useSelector(
    (state: IGlobalState) => state.EditReducer.geometryEditItemType,
  );

  const itemType: EItemType = useSelector((state: IGlobalState) => state.EditReducer.itemType);

  const getLegends = useCallback(
    () => {
      return !workspaceDataLegends || !workspaceDataLegends.length ? null : (
        <>
          {workspaceDataLegends.map((data: IDrawnDataLegend, index) => (
            <MmgViewerColorLegendItem legendData={data} key={index} />
          ))}
        </>
      );
    },
    [workspaceDataLegends],
  );

  const getEditLegend = useCallback(
    () => {
      const isLine = [EGeometryItemTypes.LINE_STRING, EGeometryItemTypes.MULTI_LINE_STRING].includes(
        geometryEditItemType,
      );
      const items = [
        { annotation: 'Added', short: 'A', color: addLineColor },
        { annotation: 'Modified', short: 'M', color: modifyLineColor },
        { annotation: 'Deleted', short: 'D', color: deleteLineColor },
      ];
      const showLegend = itemType === EItemType.MESH ? nodesToUpdate.length > 0 : previousEdits.length > 0;
      return showLegend ? (
        <div css={containerStyle}>
          <Typography css={ellipsisStyle} variant={'body2'}>
            <b>{'Edit ' + itemType.toLowerCase()}</b>
          </Typography>
          <Typography variant={'body2'}>
            <b>{'Planned changes'}</b>
          </Typography>
          {items.map((item, index) => (
            <div key={index} css={legendListitemStyle}>
              <div css={legendListitemTextStyle}>{item.annotation}</div>
              {isLine ? (
                <div css={legendListLineitemColorStyle(item.color)} />
              ) : (
                <div css={legendListitemColorStyle(item.short === 'M' ? 0.25 : 0.5)}>{item.short}</div>
              )}
            </div>
          ))}
        </div>
      ) : null;
    },
    [geometryEditItemType, itemType, nodesToUpdate.length, previousEdits.length],
  );

  return (
    <>
      {getLegends()}
      {getEditLegend()}
    </>
  );
};

export default MmgViewerColorLegend;
