/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactNode } from 'react';

import { PanelBodyStyle } from './panel-styles';

const PanelStyle = css`
  width: 100%;

  .panel__body {
    background-color: white;
  }
`;

type PanelProps = {
  children?: ReactNode;
};

/**
 * @name MmgPanel
 * @summary A generic container that should be placed inside a sidepanel.
 *
 * @param props
 */
export const MmgPanel = (props: PanelProps) => {
  const { children } = props;

  return <div css={PanelStyle}>{children && <section css={PanelBodyStyle}>{children}</section>}</div>;
};
