/** @jsxImportSource @emotion/react */
import React from 'react';
import DataTable from '../TableComponents/DataTable';
import { IColumn } from '../TableComponents/support';
import { IGetProject } from '../mike-project-explorer/model';
import { IGetDataset } from '../../models/IGetDataset';

interface IProps {
  columns: Array<IColumn>;
  data: Array<IGetProject | IGetDataset>;
  firstTableCellRender?: (
    item: IGetProject | IGetDataset,
    isSelectable: boolean,
    isSelected: boolean,
    onClick: (
      event: any,
      item: IGetProject | IGetDataset
    ) => void
  ) => React.ReactNode;
  loading: boolean;
  selectedItems: Array<IGetProject | IGetDataset >;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onItemClick: (item: IGetProject | IGetDataset) => void;
  onSelectionChange: (selectedItems: Array<IGetProject | IGetDataset>) => void;
  onHandleRequestSort: (
    orderBy: string | ((item: IGetProject | IGetDataset) => string | number),
    order: 'asc' | 'desc'
  ) => void;
  order: 'asc' | 'desc';
  orderBy: string | ((item: IGetProject | IGetDataset) => string | number);
  pagination?: boolean;
  page?: number;
  rowsPerPage?: number;
  topOffset: number;
  selectable: boolean;
  totalCount?: number;
}

const MikeProjectContentTable = (props: IProps) => {
  const {
    columns,
    data,
    firstTableCellRender,
    loading,
    selectedItems,
    onChangePage,
    onChangeRowsPerPage,
    onHandleRequestSort,
    onItemClick,
    onSelectionChange,
    order,
    orderBy,
    page,
    pagination,
    rowsPerPage,
    topOffset,
    selectable,
    totalCount,
  } = props;

  return (
    <DataTable
      loading={loading}
      columns={columns}
      idField="id"
      firstTableCellRender={firstTableCellRender}
      data={data}
      selectedRows={selectedItems}
      onCellClick={onItemClick}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSelectionChange={onSelectionChange}
      onHandleRequestSort={onHandleRequestSort}
      orderBy={orderBy}
      order={order}
      pagination={pagination}
      page={page}
      rowsPerPage={rowsPerPage}
      selectable={selectable}
      topOffset={topOffset}
      totalCount={totalCount}
    />
  );
};

export default MikeProjectContentTable;
