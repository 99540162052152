import { useState } from 'react';
import { t } from '../../translations/i18n';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import { useShowAllButUserHidden } from '../../shared/hooks/useShowAllButUserHidden';
import { MmgConnectedExportMeshes } from '../../meshes/export/export-meshes';
import { MmgConnectedExportGeometries } from '../../geometries/export/export-geometries';
import { MmgConnectedExportVariables } from '../../variables/export/export-variables';
import { EElementCategories, ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { MmgSelectExportItemType } from './select-export-item-type';
import { IProject } from '../../models/IProject';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

/**
 * @name MmgExportPanel
 * @summary Allows exporting selected meshes, geometries and variables.
 *
 */
const MmgConnectedExportPanel = () => {
  const { projectId, workspaceId } = useParams();
  const project: IProject | null = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const isExportAllowed = project && project.capabilities && project.capabilities.canCreateContent;

  const [selectedItemType, setSelectedItemType] = useState(ELEMENT_CATEGORIES.MESH);

  const onItemTypechanged = (itemType: EElementCategories) => {
    setSelectedItemType(itemType);
  };

  useShowAllButUserHidden();

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={t('EXPORT_PANEL')} />
      </MikeStickyPanelHeaderContainer>

      {!project ? (
        <MmgPanelSubsection>
          <CircularProgress />
        </MmgPanelSubsection>
      ) : (
        <>
          {!isExportAllowed && (
            <MmgMessageBanner
              messageTitle={t('MESSAGE_EXPORT_NOT_ALLOWED')}
              message={t('MESSAGE_EXPORT_NOT_ALLOWED_DETAILS')}
              messageType="warning"
            />
          )}

          {isExportAllowed && (
            <>
              <MmgSelectExportItemType
                selectedItemType={selectedItemType}
                disabled={!isExportAllowed}
                onItemTypechanged={onItemTypechanged}
              />
              {selectedItemType === EElementCategories.MESH && (
                <MmgConnectedExportMeshes workspaceId={workspaceId} isExportAllowed={isExportAllowed} />
              )}
              {selectedItemType === EElementCategories.GEOMETRY && (
                <MmgConnectedExportGeometries workspaceId={workspaceId} isExportAllowed={isExportAllowed} />
              )}
              {selectedItemType === EElementCategories.VARIABLE && (
                <MmgConnectedExportVariables
                  projectId={projectId}
                  workspaceId={workspaceId}
                  isExportAllowed={isExportAllowed}
                />
              )}
            </>
          )}
        </>
      )}
    </MikeStickyPanel>
  );
};

export default MmgConnectedExportPanel;
