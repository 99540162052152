/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { store } from '../store/store';
import { IGlobalState } from '../store/reducers';
import { IWorkspaceOverview } from '../models/IWorkspaces';
import { t } from '../translations/i18n';
import { MmgMetas } from '../shared/metas/metas';
import { MmgConnectedViewerBounding } from './viewer/viewer-bounding';
import { MmgConnectedViewerProjection } from './viewer/viewer-projection';
import { getWorkspaceOverview } from '../store/selectors/WorkspaceSelectors';
import { WorkspaceActionType } from './store/WokspaceActionType';
import { MenuBarHeight, ViewerZindex } from '../shared/styles/styleConsts';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import { useDefaultHiddenItems } from './useDefaultHiddenItems';

import MmgConnectedWorkspacePanelRoutes from './workspace-panel-routes';
import MmgConnectedViewer from './viewer/viewer-react';
/* import React from 'react';
const MmgConnectedWorkspacePanelRoutes = React.lazy(() => import('./workspace-panel-routes'));
const MmgConnectedViewer = React.lazy(() => import('./viewer/viewer-react'));
 */
/* ${theme.spacing(4)} is the foother height; see file mesh-viewer-info-bars.ts
To override vtk default cursor [style] attrs !important is used */
const getViewerStyle = (working: boolean, waiting: boolean, crosshair: boolean) => css`
  position: relative;
  width: 100vw;
  height: calc(100vh - ${MenuBarHeight} - ${mikeSharedTheme.spacing(4)});
  z-index: ${ViewerZindex};
  overflow: hidden;
  & div {
    cursor: ${working
      ? 'progress!important'
      : waiting
        ? 'wait!important'
        : crosshair
          ? 'crosshair!important'
          : 'inherit'};
  }
`;

/**
 * @name MmgWorkspace Contains all workspace components.
 * @summary Parent of the viewer and panels.
 */
const MmgConnectedWorkspace = () => {
  const navigate = useNavigate();
  const workspace: IWorkspaceOverview = useSelector(getWorkspaceOverview);
  const match = useMatch({
    path: '/project/:id/workspace/:workspaceId/*'
  });
  const projectId = match && match.params.id;
  const workspaceId = match && match.params.workspaceId;

  const { viewerWorking, viewerWaiting, viewerCrosshair } = useSelector(
    (state: IGlobalState) => state.ViewerCursorReducer,
  );

  useEffect(
    () => {
      if (projectId && workspaceId) {       
        store.dispatch({ type: WorkspaceActionType.WORKSPACE_LOAD_METADATA, data: { pId: projectId, wsId: workspaceId, navigate } });
      }
    },
    [navigate, projectId, workspaceId],
  );

  useEffect(
    () => {
      if (projectId && workspaceId){        
        store.dispatch({ type: WorkspaceActionType.SOCKET_INIT, data: {projectId, workspaceId} });
      }      
    },
    [projectId, workspaceId],
  );

  useDefaultHiddenItems(workspaceId);

  const renderMetas = (workspaceOverview: IWorkspaceOverview) => {
    if (workspaceOverview) {
      const metaTitle = `${workspaceOverview.name} | ${t('WORKSPACE')}`;
      return <MmgMetas metaTitle={metaTitle} metaDescription={workspaceOverview.description} />;
    }
    return <MmgMetas metaTitle={t('WORKSPACE')} />;
  };

  /**
   * Reset highlighted element id when mouse entering the viewer.
   */
  const onViewerEnter = () => {
    store.dispatch({
      type: 'workspace/element/HIGHLIGHT',
      highlightedWorkspaceElementId: null,
    });
  };

  return (
    <>
      {renderMetas(workspace)}
      <main css={getViewerStyle(viewerWorking, viewerWaiting, viewerCrosshair)} onMouseEnter={onViewerEnter}>
        <MmgConnectedViewer />
        {/* Set correct bounding and projection on MikeVisualizer */}
        <MmgConnectedViewerProjection />
        <MmgConnectedViewerBounding />        
      </main>
      <MmgConnectedWorkspacePanelRoutes />
    </>
  );
};

export default MmgConnectedWorkspace;
