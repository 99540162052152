/**
 * Exposes statistics REST endpoints to managers.
 *
 * @module WorkspaceStatisticsProxy
 * @version 2.0.0
 * @requires Proxy
 *
 */

import { endpoints, paths } from './config';
import Proxy from './Proxy';

const { workspaces, meshes, geometries, variables, attributes, statistics } = paths;

/**
 * Get statistics for a given mesh and attribute.
 *
 * @param workspaceId
 * @param meshId
 * @param attributeName
 */
export const getMeshStatistics = (workspaceId: string, meshId: string, attributeName: string) =>
  Proxy.http.get(
    `${
      endpoints.meshAppService
    }/${workspaces}/${workspaceId}/${meshes}/${meshId}/${attributes}/${attributeName}/${statistics}`,
  );

/**
 * Get statistics for a given geometry and attribute.
 *
 * @param workspaceId
 * @param geometryId
 * @param attributeName
 */
export const getGeometryStatistics = (workspaceId: string, geometryId: string, attributeName: string) =>
  Proxy.http.get(
    `${
      endpoints.meshAppService
    }/${workspaces}/${workspaceId}/${geometries}/${geometryId}/${attributes}/${attributeName}/${statistics}`,
  );

/**
 * Get statistics for a given variable and attribute.
 *
 * @param workspaceId
 * @param variableId
 * @param attributeName
 */
export const getVariableStatistics = (workspaceId: string, variableId: string, attributeName: string) =>
  Proxy.http.get(
    `${
      endpoints.meshAppService
    }/${workspaces}/${workspaceId}/${variables}/${variableId}/${attributes}/${attributeName}/${statistics}`,
  );
