/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect, useCallback } from 'react';
import { t } from '../translations/i18n';
import { SelectOption } from 'react-select-material-ui';
import CircularProgress from '@mui/material/CircularProgress';
import { MmgAutocomplete } from '../shared/autocomplete/autocomplete';
import { useSelector } from 'react-redux';
import { IProject } from '../models/IProject';
import { store } from '../store/store';
import { usePrevious } from '../shared/hooks/hooks';
import { useParams } from 'react-router-dom';
import { getProjectsRecursively } from '../store/actions/projects';
import { IGlobalState } from '../store/reducers';
import MikeButton from '../shared-components/mike-button';

const ProjectSelectorStyle = css`
  width: 100%;
`;

type ProjectSelectorProps = {
  selectedProjectId?: string;
  projectList?: Array<IProject>;
  projectListLoading?: false;
  projectListLoadingFailed?: false;
  onProjectSelected: (selectedProjectId: string) => void;
  onProjectListLoaded?: () => void;
};

/**
 * @name MmgConnectedProjectSelector
 * @summary Allows selecting a project from a list.
 *
 * @param props
 */
export const MmgConnectedProjectSelector = (props: ProjectSelectorProps) => {
  const { selectedProjectId, onProjectSelected, onProjectListLoaded } = props;
  const { projectId } = useParams();
  const { recursiveProjectList, recursiveProjectListLoading, recursiveProjectListLoadingFailed } = useSelector(
    (state: IGlobalState) => state.ProjectReducer,
  );

  const [projectSelectOptions, setProjectSelectOptions] = useState([] as Array<SelectOption>);

  const prevProjectListLoading = usePrevious(recursiveProjectListLoading);
  const hasProjects = recursiveProjectList && recursiveProjectList.length > 0;

  const loadProjectList = useCallback(
    () => {
      projectId && store.dispatch(getProjectsRecursively(projectId));
    },
    [projectId],
  );

  useEffect(
    () => {
      projectId && store.dispatch(getProjectsRecursively(projectId));
    },
    [projectId],
  );

  useEffect(
    () => {
      setProjectSelectOptions(
        recursiveProjectList
          .filter((p: IProject) => p.capabilities && p.capabilities.canCreateContent)
          .map(({ name, id }) => {
            return { label: name, value: id };
          }),
      );
    },
    [recursiveProjectList],
  );

  useEffect(
    () => {
      if (selectedProjectId) {
        onProjectSelected(selectedProjectId);
      }
    },
    [selectedProjectId, onProjectSelected],
  );

  useEffect(
    () => {
      if (prevProjectListLoading !== recursiveProjectListLoading && !recursiveProjectListLoading) {
        if (onProjectListLoaded) {
          onProjectListLoaded();
        }
      }
    },
    [onProjectListLoaded, recursiveProjectListLoading, prevProjectListLoading],
  );

  return (
    <div css={ProjectSelectorStyle}>
      {recursiveProjectListLoadingFailed && (
        <>
          {t('PROJECT_LIST_FAILED')}

          <MikeButton variant="contained" onClick={loadProjectList}>{t('RETRY')}</MikeButton>
        </>
      )}

      {recursiveProjectListLoading && <CircularProgress />}

      {hasProjects && (
        <MmgAutocomplete
          placeholder={t('WORKSPACE_SELECT_PROJECT_PLACEHOLDER')}
          options={projectSelectOptions}
          SelectProps={{
            msgNoOptionsAvailable: '...',
            msgNoOptionsMatchFilter: t('WORKSPACE_SELECT_PROJECT_NO_MATCH'),
            msgNoValidValue: t('WORKSPACE_SELECT_PROJECT_NO_MATCH'),
          }}
          onChange={props.onProjectSelected}
          value={selectedProjectId}
          data-testid="mmg-project-autocomplete"
        />
      )}
    </div>
  );
};
