/**
 * Exposes methods to handle ModifyMesh operation requests.
 *
 * @module ModifyMeshOperationsManager
 * @version 2.0.0
 * @requires OperationsProxy
 */
import { HttpErrorHandler } from './http-utils';
import * as OperationsProxy from '../proxies/OperationsProxy';
import OperationsManager from './OperationsManager';
import { IOperationDescriptionApi } from '../models/IOperationDescriptionsApi';
import { IOperationConfiguration } from '../models/IOperations';
import OperationMappingUtils from '../managers/operation-mapping-utils';
import { IResponse } from '../models/IResponse';

/**
 * Transforms a request that gets modify mesh operation descriptions for the workspace.
 * @param workspaceId
 * @param meshIds
 */
const getModifyMeshOperationDescriptions = async (workspaceId: string, meshIds: Array<string>) => {
  try {
    const res: IResponse = await OperationsProxy.getModifyMeshOperationDescriptions(workspaceId, meshIds);
    const apiOperationDescriptions = res.data as Array<IOperationDescriptionApi>;

    const operationDescriptions = OperationMappingUtils.mapOperationDescriptions(apiOperationDescriptions);

    return operationDescriptions;
  } catch (error: any) {
    HttpErrorHandler('Failed to get modify mesh operation descriptions.', error);
  }
};

/**
 * Transforms a request that performs a modify mesh operation on a mesh.
 *
 * @param workspaceId
 * @param modifyMeshOperationConfiguration
 * @param supportsQuery
 */
const executeModifyMeshOperation = (
  workspaceId: string,
  modifyMeshOperationConfiguration: IOperationConfiguration,
  supportsQuery?: boolean,
) => {
  if (!modifyMeshOperationConfiguration) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  if (!modifyMeshOperationConfiguration.inputIds || !modifyMeshOperationConfiguration.inputIds.length) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  if (!supportsQuery) {
    delete modifyMeshOperationConfiguration.queryDefinition;
  }

  return OperationsManager.executeOperation(workspaceId, {
    ...modifyMeshOperationConfiguration,
  }).catch((error) => HttpErrorHandler('Failed to excute modifyMeshOperation.', error)) as Promise<
    IOperationConfiguration
  >;
};

const self = {
  getModifyMeshOperationDescriptions,
  executeModifyMeshOperation,
};

export default self;
