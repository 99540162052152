import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { useNavigate, useParams } from 'react-router';
import { ROUTES, getRouteByPath } from '../../app/routes';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils, { fetchLayer } from '../../shared/layers/layer-utils';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { t } from '../../translations/i18n';
import { MmgConnectedGeometryOperationsSection } from './geometry-operation-section';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { LinearProgress } from '@mui/material';
import { getGeometryOperationPanelDescription } from '../../shared/panels/panel-utils';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

const { hideOtherLayers, isLayerWorking } = LayerUtils;

/**
 * @name MmgConnectedGeometryOperationPanel
 * @summary Allows performing 'geometry operation' on the current geometry, former referred to as 'transforms'.
 *
 * If the geometry does not exists, it will redirect to geometry details panel
 *
 */
export const MmgConnectedGeometryOperationPanel = () => {
  const { projectId, workspaceId, geometryId } = useParams();
  const navigate = useNavigate();
  const getGeometrySelectorInstance = WorkspaceGeometrySelectors.makeGetGeometry();
  const getDrawnDataSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryDrawnData();
  const { loadedData } = useSelector((state: IGlobalState) => state.WorkspaceDataReducer);

  const geometry: IWorkspaceEnrichedGeometry = useSelector((state: IGlobalState) =>
    getGeometrySelectorInstance(state, { geometryId }),
  );
  const geometryDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, {
      geometryId,
    }),
  );

  const title = t('GEOMETRY_OPERATION_PANEL');

  // When an operation is submitted succesfully we currently navigate to workspace panel
  const onOperationSubmitted = (success: boolean) => {
    if (success) {
      // always go to workspace panel on success, since all geoemtry operations currently will create a new geometry
      navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));  
    }
  };

  // todo hevo show/hide items should be as in geoemtry detail
  // make sure the only item shown in the viewer is the geometry at hand when the geometry is loaded
  useEffect(
    () => {
      if (!loadedData.includes(geometryId)) {
        fetchLayer(geometryId);
      }
      if (geometry && geometry.id) {
        hideOtherLayers(ELEMENT_CATEGORIES.GEOMETRY, geometry.id);
      }
    },
    [geometry, geometryId, loadedData],
  );

  if (!geometry) {
    navigate(getRouteByPath(ROUTES.geometryDetailsPanel.path, { projectId, workspaceId, geometryId }, ROUTES.workspace.path));
  }

  // Why is geometryIds an array when it is always either one or none?
  const geometryIds = geometryId ? [geometryId] : [];
  const drawnGeometries = geometryDrawnData ? [geometryDrawnData] : [];
  const isGeometryWorking = isLayerWorking(geometry, drawnGeometries);

  const description = getGeometryOperationPanelDescription(geometry, drawnGeometries);

  return (
   
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={title} panelDescription={description} />
        {isGeometryWorking && <LinearProgress />}
      </MikeStickyPanelHeaderContainer>

      <MmgConnectedGeometryOperationsSection
        projectId={projectId}
        workspaceId={workspaceId}
        geometryIds={geometryIds}
        onOperationSubmitted={onOperationSubmitted}
      />
    </MikeStickyPanel> 
  );
};
