/**
 * Exposes utility functions for setting up validations of parameters. ¨
 * Uses yup
 */

import { isNumber } from 'lodash-es';
import { IParameterDescription, PARAMETER_VALUE_TYPES } from '../../models/IOperationDescriptions';
import { t } from '../../translations/i18n';
import {
  NumberSchema,
  StringSchema,
  BooleanSchema,
  ObjectSchema,
  object as yupObject,
  number as yupNumber,
  string as yupString,
  bool as yupBool,
  mixed as yupMixed,
} from 'yup';
import { getParamLabel, isPercentageType } from './parameter-utils';

/**
 * Creates a yup validation schema for a parameter of type number
 * @param parameterDescription
 */
const createParameterNumberSchema = (parameterDescription: IParameterDescription): NumberSchema | null => {
  if (!parameterDescription) {
    return null;
  }

  const { valueType, minValue, maxValue, defaultValue } = parameterDescription;

  let validation = yupNumber();

  if (valueType === PARAMETER_VALUE_TYPES.INT32 || valueType === PARAMETER_VALUE_TYPES.INTEGER) {
    validation = validation.integer();
  }

  if (isNumber(minValue)) {
    validation = validation.min(minValue);
  }

  if (isNumber(maxValue)) {
    validation = validation.max(maxValue);
  }

  // todo hevo: should default value be taken from inital parameters instead? I guess it is used for resetting?
  if (isNumber(defaultValue)) {
    validation = validation.default(defaultValue) as any;
  }

  return validation;
};

/**
 * Creates a yup validation schema for a parameter of type percentage
 * currently percentage fields are considered strings by the api (including the % character, eg. '27%')
 * @param parameterDescription
 */
const createParameterPercentageSchema = (parameterDescription: IParameterDescription) => {
  if (!parameterDescription) {
    return null;
  }

  return yupString();
};

/**
 * Creates a yup validation schema for a parameter of type stríng
 * @param parameterDescription
 */
const createParameterStringSchema = (parameterDescription: IParameterDescription): StringSchema | null => {
  if (!parameterDescription) {
    return null;
  }

  return yupString();
};

/**
 * Creates a yup validation schema for a parameter of type boolean
 * @param parameterDescription
 */
const createParameterBooleanSchema = (parameterDescription: IParameterDescription): BooleanSchema | null => {
  if (!parameterDescription) {
    return null;
  }

  return yupBool();
};

/**
 * Creates a yup validation schema for a parameter defined by the parameterDescription
 * Will create schema depending on the type of paramater and add general validation rleevant for all types, such as required.
 * @param parameterDescription
 */
const createParameterSchema = (parameterDescription: IParameterDescription) => {
  if (!parameterDescription) {
    return null;
  }

  const { valueType } = parameterDescription;

  let schema;

  switch (valueType) {
    case PARAMETER_VALUE_TYPES.INT32:
    case PARAMETER_VALUE_TYPES.INTEGER:
    case PARAMETER_VALUE_TYPES.DOUBLE: {
      schema = createParameterNumberSchema(parameterDescription);
      break;
    }

    case PARAMETER_VALUE_TYPES.STRING:
    case PARAMETER_VALUE_TYPES.GUID:
      // currently percentage parameters have valueType string, eg '27%'
      if (isPercentageType(parameterDescription)) {
        schema = createParameterPercentageSchema(parameterDescription);
      } else {
        schema = createParameterStringSchema(parameterDescription);
      }
      break;

    case PARAMETER_VALUE_TYPES.BOOLEAN:
      schema = createParameterBooleanSchema(parameterDescription);
      break;

    case PARAMETER_VALUE_TYPES.ENUM:
    default:
      schema = yupMixed();
      break;
  }

  if (schema) {
    const { name, nameKey, required } = parameterDescription;
    schema = schema.label(getParamLabel(name, nameKey)).nullable();

    if (required) {
      schema = schema.required(t('ERROR_MSG_REQUIRED'));
    }
  }

  return schema;
};
/**
 * Creates an validation schema for the parameter descriptions given
 * @param parameterDescriptions
 */
export const createValidationSchema = (parameterDescriptions: {
  [parameterKey: string]: IParameterDescription;
}): ObjectSchema<any> | null => {
  if (!parameterDescriptions) {
    return null;
  }

  const validations = Object.keys(parameterDescriptions).reduce((acc, key) => {
    const parameterDescription = parameterDescriptions[key];

    const schema = createParameterSchema(parameterDescription);

    if (schema) {
      return { ...acc, [key]: schema };
    }

    return acc;
  }, {});

  return yupObject().shape(validations);
};

const self = {
  createValidationSchema,
};
export default self;
