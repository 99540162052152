/** @jsxImportSource @emotion/react */
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../../translations/i18n';
import { ViewerToolsButtonStyle, ViewerToolsButtonActiveStyle } from '../../../workspaces/viewer/tools/viewer-tools-styles';
import Inspect from '../../../icons/Inspect.svg?react';
import Tooltip from '@mui/material/Tooltip';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';;
import { store } from '../../../store';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';
import { EViewerCursorActionType } from '../../../store/actions/ViewerCursorActionType';
import { IGlobalState } from '../../../store/reducers';
import { css } from '@emotion/react';
const { enable2DDrawInspection, disable2DInspection } = MikeVisualizer;

/**
 * @name MmgConnectedViewerToolFeatureInfo
 * @summary Allows enabling/disabling 2d feature info tool.
 *
 */
export const MmgConnectedViewerToolFeatureInfo = () => {
  const featureInfoEnabled = useSelector((state: IGlobalState) => state.MapToolReducer.featureInfoEnabled);

  /*
   * Enable feature info.
   */
  const enableFeatureInfoTool = useCallback(() => {
    enable2DDrawInspection();
  }, []);

  /*
   * Disable feature info.
   */
  const disableFeatureInfoTool = () => {
    disable2DInspection();
  };

  const toggleFeatureInfoTool = () => {
    if (featureInfoEnabled) {
      store.dispatch({ type: EMapToolActionType.DISABLE_FEATURE_INFO });
    } else {
      store.dispatch({ type: EMapToolActionType.ENABLE_FEATURE_INFO });
    }
  };

  useEffect(
    () => {
      if (featureInfoEnabled) {
        enableFeatureInfoTool();
        // The cursor should be crosshair while selecting features to inspect.
        store.dispatch({ type: EViewerCursorActionType.SET_CROSSHAIR });
      } else {
        store.dispatch({ type: EViewerCursorActionType.UNSET_CROSSHAIR });
        disableFeatureInfoTool();
      }
    },
    [featureInfoEnabled, enableFeatureInfoTool],
  );

  return (
    <Tooltip title={t('TOOL_FEATURE_INFO')} placement="right">
      <button
        css={css`${ViewerToolsButtonStyle} ${featureInfoEnabled && ViewerToolsButtonActiveStyle}`}
        onClick={toggleFeatureInfoTool}
      >
        <Inspect />
      </button>
    </Tooltip>
  );
};
