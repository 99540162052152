
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import capitalize from 'lodash/capitalize';
import toLower from 'lodash/fp/toLower';
import { Checkbox, TableCell } from '@mui/material';
import Folder from '../../icons/Folder.svg?react';
import { IGetProject } from '../mike-project-explorer/model';
import { IGetDataset } from '../../models/IGetDataset';
import { isDataset } from '../mike-project-explorer/MikeProjectContentTable/support';
import LongText from '../mike-project-explorer/renders/LongText';
import DateTime from '../mike-project-explorer/renders/DateTime';

export const firstTableCellRender = (
  item: IGetProject | IGetDataset,
  isSelectable: boolean,
  isSelected: boolean,
  onClick: (
    event: any,
    item: IGetProject | IGetDataset
  ) => void
) => {
  if (isDataset(item)) {
    if (isSelectable) {
      return (
        <TableCell padding="checkbox" onClick={(event) => onClick(event, item)}>
          <Checkbox checked={isSelected} />
        </TableCell>
      );
    } else {
      return <TableCell padding="checkbox" />;
    }
  } else {
    return (
      <TableCell padding="checkbox">
        <Folder />
      </TableCell>
    );
  }
};

const hoverStyle = (item: IGetProject | IGetDataset) => {
  const className =
    'datasetType' in item
      ? null
      : item['capabilities'] && item['capabilities'].canListContent
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : null;
  return className;
};

const typeRender = (value: string, item: IGetProject | IGetDataset) => {
  const properties = item['properties']
  const geometryType = properties && properties['GeometryType'];
  return value
    ? capitalize(value) + (geometryType ? ' / ' + capitalize(geometryType) : '')
    : 'Folder';
};
const typeComparator = (item: IGetProject | IGetDataset) => {
  const properties = item['properties']
  const geometryType = properties && properties['GeometryType'];
  const type =
    'datasetType' in item
      ? geometryType
        ? toLower(item.datasetType) + ' / ' + toLower(geometryType)
        : toLower(item.datasetType)
      : 'zzzz';
  return type;
};

const itemNameRender = (value: string) => <LongText text={value} />;
const nameComparator = (item: IGetProject | IGetDataset) =>
  item.name.toLowerCase();

const dateTimeRender = (value: string) => <DateTime jsonTime={value} />;

export const projectContentColumns = [
  {
    field: 'name',
    label: 'Name',
    render: itemNameRender,
    comparator: nameComparator,
    className: hoverStyle,
  },
  {
    field: 'datasetType',
    label: 'Type',
    render: typeRender,
    comparator: typeComparator,
    className: hoverStyle,
  },
  {
    field: 'updatedAt',
    label: 'Edited',
    render: dateTimeRender,
    className: hoverStyle,
  },
];
