import { useState, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash-es';

import MeshOperationsManager from '../../managers/MeshOperationsManager';
import { IOperationDescription, IContextualOperationDescriptions } from '../../models/IOperationDescriptions';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { usePrevious } from '../../shared/hooks/hooks';
import { filterQueriesByTargetItems } from '../../store/selectors/WorkspaceQueryUtils';
import { IWorkspaceQuery } from '../../models/IQueries';

/**
 * Custom hook that loads the contextual create mesh operation description for the geoemtries and queries given
 * @param workspaceId
 * @param geometryIds
 * @param queries
 * @param workspaceVariables
 */
export const useAsyncLoadContextualCreateMeshOperationDescription = (
  workspaceId: string,
  geometryIds: Array<string>,
  queries: Array<IWorkspaceQuery>,
  workspaceVariables: Array<IWorkspaceEnrichedVariable>,
): {
  basicCreateMeshOperationDescription: IOperationDescription;
  contextualChildOperationDescriptions: IContextualOperationDescriptions;
  contextualOperationDescriptionsLoading: boolean;
  contextualOperationDescriptionsLoadingFailed: boolean;
  retryLoadContextualDescriptions: () => void;
} => {
  const filtered = filterQueriesByTargetItems(queries, geometryIds);
  const queryIds = filtered.map((item) => item.id);

  const [contextualOperationDescriptionsLoading, setContextualOperationDescriptionsLoading] = useState(false);

  const [contextualOperationDescriptionsLoadingFailed, setContextualOperationDescriptionsLoadingFailed] = useState(
    false,
  );

  const [basicCreateMeshOperationDescription, setBasicCreateMeshOperationDescription] = useState(
    null as IOperationDescription,
  );

  const [contextualChildOperationDescriptions, setContextualChildOperationDescriptions] = useState(
    null as IContextualOperationDescriptions,
  );

  const prevWorkspaceId = usePrevious(workspaceId);
  const previousGeometryIds = usePrevious(geometryIds || []);
  const previousVariables = usePrevious(workspaceVariables);

  /**
   * Gets the contextual interpolation operation description for the geoemtries and queries given.
   * Variables will be used to determine possible parameters for density function.
   */
  const getContextualCreateMeshOperationDescription = useCallback(
    (force: boolean) => {
      // if there are no geometries, no reason to load anything
      if (!workspaceId || (!geometryIds || geometryIds.length === 0)) {
        return Promise.resolve();
      }

      // if nothing have changed, no reason to load again unless force is true
      if (
        !force &&
        (prevWorkspaceId === workspaceId &&
          isEqual(previousGeometryIds || [], geometryIds || []) &&
          isEqual(previousVariables || [], workspaceVariables || []))
      ) {
        return Promise.resolve();
      }

      setContextualOperationDescriptionsLoading(true);
      setContextualOperationDescriptionsLoadingFailed(false);

      return MeshOperationsManager.getContextualCreateMeshOperationDescriptions(
        workspaceId,
        geometryIds,
        queryIds,
        workspaceVariables,
      )
        .then(({ basicOperationDescription, childContextualOperationDescriptions }) => {
          setBasicCreateMeshOperationDescription(basicOperationDescription);
          setContextualChildOperationDescriptions(childContextualOperationDescriptions);
        })
        .catch((error) => {
          setContextualOperationDescriptionsLoadingFailed(true);
          console.error('Failed to get contextual create mesh operation descriptions');
          throw error;
        })
        .finally(() => {
          setContextualOperationDescriptionsLoading(false);
        });
    },
    [workspaceId, geometryIds, queryIds, workspaceVariables, prevWorkspaceId, previousGeometryIds, previousVariables],
  );

  const retryLoadContextualDescriptions = () => {
    getContextualCreateMeshOperationDescription(true);
  };

  // load contextual descriptions
  useEffect(
    () => {
      getContextualCreateMeshOperationDescription(false);
    },
    [getContextualCreateMeshOperationDescription],
  );

  return {
    basicCreateMeshOperationDescription,
    contextualChildOperationDescriptions,
    contextualOperationDescriptionsLoading,
    contextualOperationDescriptionsLoadingFailed,
    retryLoadContextualDescriptions,
  };
};
