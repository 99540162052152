/** @jsxImportSource @emotion/react */
import { IOperationConfiguration } from '../../../models/IOperations';
import { MmgItemCard } from '../../../shared/cards/item-card';
import { MmgNonHoverLayer } from '../../../shared/layers/mesh-non-hover-layer';
import LayerUtils from '../../../shared/layers/layer-utils';
import { getLayerDescription } from '../../../shared/panels/panel-utils';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { MmgMeshInterpolationVariableSummary } from './mesh-interpolation-variable-summary';
import { IWorkspaceAttribute } from '../../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../../models/IWorkspaceAttributeSettings';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { Interpolation, Theme } from '@emotion/react';
import { LayerWithoutBorderStyle } from '../../../queries/spatial-selections/spatial-selection-layer';

type MeshInterpolationVariableSummaryCardProps = {
  variableConfiguration: IOperationConfiguration;
  inputVariables?: Array<IWorkspaceEnrichedVariable>;
  showInputDescriptions?: boolean;
  meshAttributes?: Array<IWorkspaceAttribute>;
  attributeSettings?: Array<IWorkspaceAttributeSettings>;
  meshQueries?: Array<IWorkspaceQuery>;
  css?: Interpolation<Theme>;
  onOperationDelete?: (operationId: string) => void;
};

/**
 * @name MmgMeshInterpolationVariableSummaryCard
 * @param props
 *
 * @summary An itemcard for a mesh interpolation child operation. Includes the input layer and summary.
 *
 */
export const MmgMeshInterpolationVariableSummaryCard = (props: MeshInterpolationVariableSummaryCardProps) => {
  const {
    variableConfiguration,
    inputVariables,
    showInputDescriptions,
    meshAttributes,
    attributeSettings,
    meshQueries,
    css: cssProp,
  } = props;

  // Currently there will be only one variable per operation. So we take the first.
  const inputVariable = inputVariables && inputVariables.length > 0 ? inputVariables[0] : {};

  return (
    <MmgItemCard
      cardLayer={
        <MmgNonHoverLayer
          layerId={inputVariable.id}
          layerName={inputVariable.name}
          leftIcon={LayerUtils.getLayerIcon(inputVariable)}
          layerDescription={showInputDescriptions && getLayerDescription(inputVariable)}
          cssProp={LayerWithoutBorderStyle}
        />
      }
      cardSummary={
        <>
          <MmgMeshInterpolationVariableSummary
            operationConfiguration={variableConfiguration}
            meshAttributes={meshAttributes}
            meshAttributeSettings={attributeSettings}
            meshQueries={meshQueries}
          />
        </>
      }
      open={false}
      cssProp={cssProp}
    />
  );
};
