/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { t } from '../../translations/i18n';
import MmgConnectedCreateFolder, { ButtonStyle } from '../../workspaces/create/create-folder';
import MikeButton from '../../shared-components/mike-button';

type WorkspaceCreateButtonsProps = {
  canCreateWorkspace: boolean;
  onCreateInExistingProject: () => void;
  canDeleteWorkspace?: boolean;
  handleDialogClose: () => void;
  handleDialogOpen: () => void;
  projectSelectedForDelete?: Array<string>;
};

const WorkspaceCreateButtonsCss = css`
  padding: 5px;
`;

export const MmgWorkspaceCreateButtons = (props: WorkspaceCreateButtonsProps) => {
  const { canCreateWorkspace = false, onCreateInExistingProject, handleDialogOpen, projectSelectedForDelete } = props;

  const onDeleteClick = () => {
    handleDialogOpen();
  };

  return (
    <>
      {canCreateWorkspace && (
        <>
          <div css={WorkspaceCreateButtonsCss}>
            <MmgConnectedCreateFolder />
          </div>
          <div css={WorkspaceCreateButtonsCss}>
            <MikeButton variant="contained" color="secondary" css={ButtonStyle} onClick={onCreateInExistingProject} disabled={!canCreateWorkspace}>
              {t('WORKSPACE_CREATE_BUTTON')}
            </MikeButton>
          </div>
          <div css={WorkspaceCreateButtonsCss}>
            <MikeButton
              color="secondary" variant="contained"
              css={ButtonStyle}
              onClick={onDeleteClick}
              disabled={projectSelectedForDelete && projectSelectedForDelete.length === 0 ? true : false}
            >
              {t('WORKSPACE_DELETE_BUTTON')}
            </MikeButton>
          </div>
        </>
      )}
    </>
  );
};
