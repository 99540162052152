import  { useEffect } from 'react';
import { t } from '../../translations/i18n';
import { store } from '../../store/store';
import { MmgSpatialSelectionConfirm } from '../../queries/spatial-selections/spatial-selection-confirm';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { useMatch } from 'react-router-dom';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';

/**
 * @name MmgConnectedMeshConfirmSpatialSelection
 * @summary Wraps the generic confirm spatial selection to implement mesh spatial selections.
 *
 */
export const MmgConnectedMeshConfirmSpatialSelection = () => {
  const meshMatch = useMatch({
    path: '/project/:id/workspace/:workspaceId/mesh/:meshId'
  });

  const { workspaceId, meshId } = meshMatch && meshMatch.params;

  /**
   * Cancels the mesh spatial selection, deactivating the tool.
   */
  const cancelMeshSpatialSelection = () => {
    store.dispatch({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  };

  useEffect(() => {
    store.dispatch({
      type: 'maptools/mesh/ALLOW/SPATIAL_SELECTION',
    });

    return () => {
      // on unmount we reset to default tools
      store.dispatch({
        type: EMapToolActionType.RESET_ALLOWED_TOOLS,
      });
    };
  }, []);

  return (
    <MmgSpatialSelectionConfirm
      workspaceId={workspaceId}
      targetItemId={meshId}
      elementCategory={ELEMENT_CATEGORIES.MESH}
      successMessage={t('MESH_SPATIAL_SELECTION_SUBMITTED_SUCCESSFULLY')}
      errorMessage={t('MESH_SPATIAL_SELECTION_SUBMIT_FAILED')}
      onSelectionCancelled={cancelMeshSpatialSelection}
    />
  );
};
