/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceGeometriesManager from '../../managers/WorkspaceGeometriesManager';
import LayerUtils, { fetchLayer } from '../../shared/layers/layer-utils';
import { ELEMENT_CATEGORIES, PANEL_TABS } from '../../shared/panels/mesh-panel-constants';
import { MmgSpatialSelectionList } from '../../queries/spatial-selections/spatial-selection-list';
import { IWorkspaceQuery } from '../../models/IQueries';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import { MmgConnectedLayerGeometryActions } from '../list/actions/layer-geometry-actions';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgPanelHeaderEdit } from '../../shared/panels/panel-header-edit';
import { getGeometryDescription } from '../../shared/panels/panel-utils';
import { LinearProgress } from '@mui/material';
import { MmgTabPanel } from '../../shared/tabs/tab-panel';
import { MmgGeometryOverview } from './geometry-overview';
import { MmgConnectedGeometryTool2DSpatialSelection } from '../../geometries/tools/geometry-tool-2d-spatial-selection';
import { TOOL_BUTTON_TYPES } from '../../shared/tools/IToolButton';
import { MmgTabs } from '../../shared/tabs/tabs';
import { MmgTab } from '../../shared/tabs/tab';
import { useCurrentTab } from '../../shared/tabs/useCurrentTab';
import { useHideOtherLayers } from '../../shared/hooks/useHideOtherLayers';
import { IProject } from '../../models/IProject';
import { useNavigate, useParams } from 'react-router-dom';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

const { isLayerWorking, isLayerFailed, isLayerLoading } = LayerUtils;

/**
 * @name MmgConnectedGeometryDetailsPanel
 * @summary Displays geometry tools & detailed information, such as statistics.
 *
 */
export const MmgConnectedGeometryDetailsPanel = () => {
  const { workspaceId, projectId, geometryId } = useParams();
  const navigate = useNavigate();
  const getGeometrySelectorInstance = WorkspaceGeometrySelectors.makeGetGeometry();
  const getDrawnDataSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryDrawnData();
  const getGeometryQueriesSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryQueries();

  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const workspacePanelSettings = useSelector(
    (state: IGlobalState) => state.PanelSettingsReducer.workspacePanelSettings,
  );

  const geometry: IWorkspaceEnrichedGeometry = useSelector((state: IGlobalState) =>
    getGeometrySelectorInstance(state, { geometryId }),
  );
  const geometryDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { geometryId }),
  );
  const geometryQueries: Array<IWorkspaceQuery> = useSelector((state: IGlobalState) =>
    getGeometryQueriesSelectorInstance(state, { geometryId }),
  );

  const drawnGeometries = geometryDrawnData ? [geometryDrawnData] : [];
  const title = geometry && geometry.name ? geometry.name : t('GEOMETRY');
  const description = getGeometryDescription(geometry, drawnGeometries, false);
  const missingGeometry = !geometry || !geometry.id;
  const isFailed = isLayerFailed(geometry);
  const isWorking = isLayerWorking(geometry, drawnGeometries);
  const isLoading = isLayerLoading(geometry, drawnGeometries);
  const [isEditingName, setIsEditingName] = useState(false);
  const { onTabChange } = useCurrentTab(
    PANEL_TABS.GEOMETRY_OVERVIEW_TAB,
    workspaceId,
    geometry && geometry.id ? geometry.id : '',
  );
  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  const layerSettings =
    workspacePanelSettings &&
    workspacePanelSettings.find((sett) => {
      return sett.id === geometry.id;
    });

  const currentTab = layerSettings ? layerSettings.geometryPanelActive : PANEL_TABS.GEOMETRY_OVERVIEW_TAB;

  const rootStyle = css`
    flex-grow: 1;
    width: 100%;
    backgroundColor: ${mikeSharedTheme.palette.background.paper};
  `;


  useHideOtherLayers({
    elementId: geometryId,
    elementCategory: ELEMENT_CATEGORIES.GEOMETRY,
  });

  useEffect(
    () => {
      if (loadedData && !loadedData.includes(geometryId)) {
        fetchLayer(geometryId);
      }
    },
    [geometryId, loadedData],
  );

  useEffect(
    () => {
      // Set the selection target. This will be used if the selection tool is activated.
      store.dispatch({
        type: EMapToolActionType.GEOMETRY_SET_SPATIAL_SELECTION_TARGET,
        geometrySpatialSelectionTargetId: geometryId,
      });
    },
    [geometryId],
  );

  useEffect(() => {
    store.dispatch({
      type: EMapToolActionType.GEOMETRY_ALLOW_SPATIAL_SELECTION,
    });
    return () => {
      // on unmount we reset to default tools
      store.dispatch({
        type: EMapToolActionType.RESET_ALLOWED_TOOLS,
      });
    };
  }, []);

  // always go to workspace panel, no matter how we got here
  const onPanelExit = () => {
    navigateToWorkspacePanel();
  };

  const navigateToWorkspacePanel = () => {
    navigate(getRouteByPath(ROUTES.workspace.path, { workspaceId, projectId })); 
  };

  const toggleGeometryNameEdit = () => {
    setIsEditingName(!isEditingName);
  };

  const onPanelUpdate = ({ name }) => {
    WorkspaceGeometriesManager.renameGeometry(workspaceId, geometryId, name)
      .then(() => {
        store.dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('GEOMETRY_UPDATED_SUCCESSFULLY'),
          },
        });
        setIsEditingName(false);
      })
      .catch((error) => {
        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: {
            text: t('GEOMETRY_UPDATE_FAILED'),
          },
        });
        throw error;
      });
  };

  /**
   * Gets header actions for panel more-menu.
   */
  const getPanelHeaderActions = () => {
    if (!geometry || !canUpdateWorkspace) {
      return null;
    }
    return (
      <MmgConnectedLayerGeometryActions
        layerId={geometry.id}
        geometry={geometry}
        geometryDrawnData={geometryDrawnData}
        canToggleLayerVisibility={false}
        onGeometryEdit={toggleGeometryNameEdit}
      />
    );
  };

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          {!isEditingName ? (
            <MmgPanelHeader
              noBorder={true}
              panelTitle={title}
              panelDescription={description}
              onPanelExit={onPanelExit}
              panelHeaderActions={getPanelHeaderActions()}
            />
          ) : (
            <MmgPanelHeaderEdit panelTitle={title} onPanelExit={onPanelExit} onPanelUpdate={onPanelUpdate} />
          )}

          {!missingGeometry && (
            <MmgTabs value={currentTab} onChange={onTabChange}>
              <MmgTab label={t('GEOMETRY_OVERVIEW_TAB')} value={PANEL_TABS.GEOMETRY_OVERVIEW_TAB} index={0} />
              <MmgTab label={t('GEOMETRY_SELECTIONS_TAB')} value={PANEL_TABS.GEOMETRY_SELECTIONS_TAB} index={1} />
            </MmgTabs>
          )}

          {isWorking && <LinearProgress />}
        </MikeStickyPanelHeaderContainer>

        {missingGeometry && !isLoading ? (
          <MmgPanelSubsection>
            <p>{t('GEOMETRY_MISSING')}</p>
          </MmgPanelSubsection>
        ) : (
          <>
            <div css={rootStyle}>
              {isFailed && (
                <MmgMessageBanner
                  messageTitle={t('MESSAGE_GEOMETRY_FAILED_TITLE')}
                  message={geometry.message}
                  messageType="failed"
                />
              )}

              {/* todo hevo we should move content of individual tabs to seperate tabpanel/container component */}

              <MmgTabPanel value={PANEL_TABS.GEOMETRY_OVERVIEW_TAB} index={0} currentValue={currentTab}>
                <MmgGeometryOverview
                  workspaceId={workspaceId}
                  geometry={geometry}
                  geometryDrawnData={geometryDrawnData}
                />
              </MmgTabPanel>

              <MmgTabPanel value={PANEL_TABS.GEOMETRY_SELECTIONS_TAB} index={1} currentValue={currentTab}>
                {workspaceId && (
                  <MmgSpatialSelectionList
                    createSelectionButton={
                      canUpdateWorkspace && (
                        <MmgConnectedGeometryTool2DSpatialSelection toolButtonType={TOOL_BUTTON_TYPES.REGULAR_BUTTON} />
                      )
                    }
                    selections={geometryQueries}
                    workspaceId={workspaceId}
                  />
                )}
              </MmgTabPanel>
            </div>
          </>
        )}
      </MikeStickyPanel>
    </>
  );
};
