import tinycolor from 'tinycolor2';
import { FeatureCollection } from 'geojson';
import { Style, Icon, Fill, Text } from 'ol/style';
import { store } from '../store/store';
import WorkspaceCommentsManager from '../managers/WorkspaceCommentsManager';
import { LABELFONTSIZE } from '../workspaces/viewer/viewer-utils';
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import MIKE_COLORS from '../MikeVisualizer/colors/mike-colors';
import { FONTFAMILY } from '../shared/styles/mikeSharedTheme';
const { getState, update2DData, delete2DData, setEpsgCode } = MikeVisualizerLib;

let subscriptions: Array<() => void> = [];
const COMMENT_LAYER_ID = 'comments';
const PIN_COLOR = tinycolor(MIKE_COLORS.ACTIONBLUE_DEFAULT).toRgbString();
const PIN_SVG_FILLED = `<svg width="16" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 8.4C0 3.8 3.6 0 8 0s8 3.8 8 8.4C16 14.7 8 24 8 24S0 14.7 0 8.4zM11 8a3 3 0 10-6 0 3 3 0 006 0z" fill-rule="evenodd" fill="${PIN_COLOR}"/></svg>`;
const PIN_SVG_OUTLINED = `<svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2C11.3 2 14 4.9 14 8.4C14 12.1 10.5 17.5 8 20.8C5.5 17.5 2 12.1 2 8.4C2 4.9 4.7 2 8 2ZM8 0C3.6 0 0 3.8 0 8.4C0 14.7 8 24 8 24C8 24 16 14.7 16 8.4C16 3.8 12.4 0 8 0Z" fill="${PIN_COLOR}"/><path d="M7.79999 11C9.45684 11 10.8 9.65685 10.8 8C10.8 6.34315 9.45684 5 7.79999 5C6.14313 5 4.79999 6.34315 4.79999 8C4.79999 9.65685 6.14313 11 7.79999 11Z" fill="${PIN_COLOR}"/></svg>`;

const getPinStyle = (focusedCommentId) => {
  const p = 4; //mikeSharedTheme.spacing(0.5);
  const offsetY = 32;
  const pinStyle = (feature) => {
    const values = feature && feature.values_ ? feature.values_ : null;
    const label = values && values.message ? values.message : '';
    const labelStyle = label
      ? new Text({
          text: label,
          fill: new Fill({ color: PIN_COLOR }),
          font: `${LABELFONTSIZE} ${FONTFAMILY}`,
          backgroundFill: new Fill({ color: MIKE_COLORS.WHITE }),
          padding: [p, p, p, p],
          offsetY: offsetY //mikeSharedTheme.spacing(4),
        })
      : null;
    const focusedLabelStyle = label
      ? new Text({
          text: label,
          fill: new Fill({ color: MIKE_COLORS.WHITE }),
          font: `${LABELFONTSIZE} ${FONTFAMILY}`,
          backgroundFill: new Fill({ color: PIN_COLOR }),
          padding: [p, p, p, p],
          offsetY: offsetY //mikeSharedTheme.spacing(4),
        })
      : null;
    if (values && values.id === focusedCommentId) {
      return new Style({
        image: new Icon({
          // NB: this does not seem to work with imported svgs. They need to be inlined as strings.
          src: `data:image/svg+xml;utf8,${PIN_SVG_FILLED}`,
          opacity: 0.9,
          scale: 2,
        }),
        text: focusedLabelStyle,
      });
    }
    return new Style({
      image: new Icon({
        // NB: this does not seem to work with imported svgs. They need to be inlined as strings.
        src: `data:image/svg+xml;utf8,${PIN_SVG_OUTLINED}`,
        opacity: 0.9,
        scale: 1.5,
      }),
      text: labelStyle,
    });
  };
  return pinStyle;
};

const showCommentPins = async (
  commentsGeoJson: FeatureCollection<any, any>,
  focusedCommentId: string,
  epsg: number,
) => {
  // Force map to re-render by deleting first:
  await delete2DData(COMMENT_LAYER_ID);
  /*   if (!commentsGeoJson || commentsGeoJson.features.length === 0) {  
    return;
  } */
  const { epsgCode } = getState();
  if (!epsgCode) {
    setEpsgCode(epsg);
  }
  await update2DData(commentsGeoJson, COMMENT_LAYER_ID, null, null, 1, getPinStyle(focusedCommentId) as any);
  unsubscribeFromAllEvents();
  setupPinPressEvents();
};

const hideCommentPins = () => {
  const { rendered2DElements } = getState();
  const element = rendered2DElements.find((p) => p.id === COMMENT_LAYER_ID);
  if (element) {
    delete2DData(COMMENT_LAYER_ID);
  }
};

const highlightPin = (pinId: string) => {
  const { dataMap } = getState();
  if (!dataMap) {
    return false;
  }
  dataMap
    .getLayers()
    .getArray()[0]
    .set('style', getPinStyle(pinId) as any);
};

const setupPinPressEvents = () => {
  const { dataMap } = getState();
  if (!dataMap) {
    return false;
  }
  const mapClickListener = (event) => {
    const matchedTargets = dataMap.getFeaturesAtPixel(event.pixel, {
      hitTolerance: 30,
    });
    if (matchedTargets && matchedTargets.length) {
      event.preventDefault();
      const commentId = matchedTargets[0].get('id');
      store.dispatch({
        type: 'comments/SET_FOCUSED_COMMENT',
        commentId,
      });
      self.highlightPin(commentId);
    }
  };
  dataMap.on('click', mapClickListener);
  const unsubscribeListener = () => {
    dataMap.un('click', mapClickListener);
  };
  subscriptions.push(unsubscribeListener);
};

const unsubscribeFromAllEvents = () => {
  subscriptions = subscriptions.filter((unsubscribe) => unsubscribe());
};

/**
 * Loads all comments for the workspace given
 *
 * @param workspaceId
 */
const loadComments = async (workspaceId: string) => {
  if (!workspaceId) {
    return;
  }
  // load comments
  store.dispatch({ type: 'comments/LIST_LOAD' });
  try {
    const commentList = await WorkspaceCommentsManager.getComments(workspaceId);
    store.dispatch({
      type: 'comments/LIST_SUCCESS',
      comments: commentList,
    });
  } catch {
    store.dispatch({ type: 'comments/LIST_FAILED' });
  }
};

const self = {
  showCommentPins,
  hideCommentPins,
  loadComments,
  highlightPin,
};

export default self;
