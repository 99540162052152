import { useCallback } from 'react';
import { IWorkspaceOptionAttributeValue } from '../../models/IWorkspaceAttributes';
import { TextField } from '@mui/material';
import { t } from '../../translations/i18n';
import { ButtonIconCss, RangeCss, RangeInputCss, iconStyle } from './query-criteria-range-input';
import EyeVisible from '../../icons/EyeVisible.svg?react';

type QueryCriteriaOptionsInputProps = {
  optionValues: Array<IWorkspaceOptionAttributeValue>;
  value?: string | number; // Actual selected option value
  label?: string;
  placeholder?: string;
  onSelectedOptionChanged?: (value: string | number) => void;
  canPreviewQuery?: boolean;
  onPreviewQuery?: () => void;
};

/**
 * Renders dropdown with options for a query criteria
 * @param props
 */
export const MmgQueryCriteriaOptionsInput = (props: QueryCriteriaOptionsInputProps) => {
  const { optionValues, value, onSelectedOptionChanged, label, placeholder, canPreviewQuery, onPreviewQuery } = props;

  const callOnSelectedOptionChanged = useCallback(
    (newValue: number) => {
      if (onSelectedOptionChanged) {
        onSelectedOptionChanged(newValue);
      }
    },
    [onSelectedOptionChanged],
  );

  /**
   * Callback for selected option changed.
   *
   * @param event
   */
  const onQueryOptionChanged = (event) => {
    event.preventDefault();
    const newValue = event.target.value;
    callOnSelectedOptionChanged(newValue);
  };

  return (
    <section css={RangeCss}>
      <TextField
        css={RangeInputCss}
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        value={value || value === 0 ? value : ''}
        onChange={onQueryOptionChanged}
        margin="dense"
        fullWidth
        select
        SelectProps={{
          native: true,
        }}
      >
        <option value="" disabled>
          {placeholder || t('SELECT')}
        </option>
        {optionValues &&
          optionValues.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.displayName}
              </option>
            );
          })}
      </TextField>
      {onPreviewQuery && (
        <button css={ButtonIconCss} onClick={onPreviewQuery} disabled={!canPreviewQuery}>
          <EyeVisible css={iconStyle(!canPreviewQuery)} />
        </button>
      )}
    </section>
  );
};
