/**
 * Exposes methods to transform or combine projection requests.
 *
 * @module ProjectionManager
 * @version 2.0.0
 * @requires ProjectionProxy
 */
import * as ProjectionProxy from '../proxies/ProjectionProxy';
import { HttpErrorHandler } from './http-utils';
import { IProjection } from '../models/IProjections';
import { betterEncodeURIComponent } from '../translations/utils';

/**
 * Transforms a request that gets a list of all projections in a project matching the provided keywords.
 *
 * @param keyword Partial projection keyword to search by, i.e. 'WGS' or 'Australia'.
 */
const searchProjections = (keyword: string): Promise<Array<IProjection>> => {
  return ProjectionProxy.searchProjections(betterEncodeURIComponent(keyword))
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to search for projection.', error));
};

/**
 * Transforms a requests that gets information about a projection by epsgCode.
 *
 * @param epsgCode
 */
const getProjectionByEpsg = (epsgCode: number): Promise<IProjection> => {
  return ProjectionProxy.getProjectionByEpsg(epsgCode)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get projection information.', error));
};

const self = {
  searchProjections,
  getProjectionByEpsg,
};

export default self;
