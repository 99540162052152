import { t } from '../../translations/i18n';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgGroup } from '../../shared/groups/group';
import { MmgConnectedApplyColor } from './apply-color';
import MmgApplyRepresentation from './apply-representation';
import { IRepresentation } from '../../MikeVisualizer/lib/models/IRepresentation';

type VisualizationSettingsProps = {
  canApplyColor?: boolean;
  canApplyRepresentation?: boolean;
  representation?: IRepresentation;
  disabledRepresentations?: Array<IRepresentation>;
  edgeColor?: Array<number>;
  surfaceColor?: any;
  elementId: string;
  isMesh?: boolean;
  isTiled?: boolean;
};

/**
 * @name MmgVisualizationSettingsSection
 * @summary Generic element that allows for changing the current visual settings of an item.
 *
 * @param props
 */
export const MmgVisualizationSettingsSection = (props: VisualizationSettingsProps) => {
  const {
    elementId,
    canApplyRepresentation,
    canApplyColor,
    representation,
    disabledRepresentations,
    edgeColor,
    surfaceColor,
    isMesh,
    isTiled,
  } = props;

  return (
    <>
      <MmgGroup groupName={t('STYLE_SETTINGS')}>
        <MmgPanelSubsection>
          {canApplyRepresentation && (
            <MmgApplyRepresentation
              currentRepresentation={representation}
              disabledRepresentations={disabledRepresentations}
              elementId={elementId}
              isTiled={isTiled}
            />
          )}

          {canApplyColor && (
            <MmgConnectedApplyColor
              elementId={elementId}
              currentEdgeColor={edgeColor}
              currentSurfaceColor={surfaceColor}
              isMesh={isMesh}
              isTiled={isTiled}
            />
          )}
        </MmgPanelSubsection>
      </MmgGroup>
    </>
  );
};
