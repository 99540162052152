const mode = import.meta.env.MODE;

// Setup polyfills.
// import 'react-app-polyfill/ie11'; // We do not support IE at the moment. NB: this needs to be loaded before anything else.

// Setup i18n. NB: should happen before other modules are loaded.
import { en } from './translations/translations.js';
import { load, turnOnDebug } from './translations/i18n';

if (mode === 'development') {
  turnOnDebug();
}
load('en', en);
