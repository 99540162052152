/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useSelector } from 'react-redux';
import { store } from '../store';
import { t } from '../translations/i18n';
import { WorkspaceActionType } from './store/WokspaceActionType';
import { IGlobalState } from '../store/reducers';
import MikeButton from '../shared-components/mike-button';
import { useParams } from 'react-router-dom';

const DialogTextStyle = css`
  word-break: normal;
`;

export const MmgConnectedWorkspaceSnapshotDialog = () => {
  const { workspaceId } = useParams();
  const isSnapshotDialogOpen: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceReducer.isSnapshotDialogOpen,
  );

  const handleClose = () => {
    store.dispatch({ type: 'workspace/SET_SNAPSHOT_DIALOG_CLOSE' });
  };

  const handleCreate = () => {
    store.dispatch({ type: WorkspaceActionType.CREATE_SNAPSHOT, data: {isSupportSnapshot: true, workspaceId} });
  };

  return (
    <div>
      <Dialog
        open={isSnapshotDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText css={DialogTextStyle} id="alert-dialog-description">
            {t('WORKSPACE_SNAPSHOT_DIALOG_TEXT')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MikeButton variant="contained" onClick={handleClose} color="secondary">
            Cancel
          </MikeButton>
          <MikeButton variant="contained" onClick={handleCreate} color="secondary" autoFocus>
            Create
          </MikeButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
