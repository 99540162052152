import { useSelector } from 'react-redux';
import { IGlobalState } from '../../store/reducers';
import { store } from '../../store';
import MikeSnackbar from '../../shared-components/mike-snackbar';

export const MmgConnectedSnackbar = () => {
  const toasts = useSelector((state: IGlobalState) => state.ToastReducer.toasts);
  const onRemoveSnackbar = (id) => {
    store.dispatch({ type: 'toast/DELETE', toastId: id });
  };

  const toast = toasts && toasts.length > 0 && toasts[toasts.length - 1];
  if (!toast) {
    return null;
  }

  return (
    <MikeSnackbar
      action={'OK'}
      open={toast ? true : false}
      mikeSnackbarType={toast.type}
      autoHideDuration={5000}
      onClose={() => {
        onRemoveSnackbar(toast.id);
      }}
      id={toast.id}
      mikeOnAction={() => {
        onRemoveSnackbar(toast.id);
      }}
    >
      {toast.text}
    </MikeSnackbar>
  );
};
