import { useEffect } from 'react';
import { IParameterDescription } from '../../models/IOperationDescriptions';
import { IParameterSettings } from '../../models/IParameterSettings';
import { MmgParameterInputSelect } from './parameter-input-select';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import { useSelector } from 'react-redux';
import { useAllowedDataItemsAsSelectValues } from './useAllowedDataItemsAsSelectValues';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';
import { IWorkspaceVariable } from '../../models/IVariables';
const { showElement, hideElements } = MikeVisualizerLib;

type ParameterVariableSelectProps = {
  parameterKey: string;
  parameterDescription: IParameterDescription;
  name: string;
  value: string;
  label: string;
  placeholder: string;
  infoText?: string;
  helperText?: string;
  error?: boolean;
  customSettings?: IParameterSettings;
  onParameterChanged?: (param: string, val: string) => void;
};

/**
 * @name MmgConnectedParameterVariableSelect
 * @param props
 * @summary A generic element that renders a select parameter for meshes and accepts a callback for when it change.
 *
 * The 'allowedValues' of the parameterDescription holds geoemtry ids and will be used for the select options
 *
 *
 */
export const MmgConnectedParameterVariableSelect = (props: ParameterVariableSelectProps) => {
  const { parameterDescription, value, ...rest } = props;
  const workspaceVariables: Array<IWorkspaceVariable> = useSelector(WorkspaceVariableSelectors.getWorkspaceVariables);

  const { parameterDescriptionWithValues, getValueDisplayName } = useAllowedDataItemsAsSelectValues(
    parameterDescription,
    workspaceVariables,
  );

  useEffect(
    () => {
      // Show selected geometry when there is a selected value:
      hideElements(parameterDescription.allowedValues);
      value && showElement(value);
      return () => {
        // hide geometries when unmounting:
        hideElements(parameterDescription.allowedValues);
      };
    },
    [value, parameterDescription.allowedValues],
  );

  return (
    <MmgParameterInputSelect
      parameterDescription={parameterDescriptionWithValues}
      getValueDisplayName={getValueDisplayName}
      value={value}
      {...rest}
    />
  );
};
