import React from 'react';
import { ILayerErrorProps, MikeLayerError } from '../../shared-components/mike-layer/layer-error';
import MikeTooltip from '../../shared-components/mike-tooltip';

/**
 * @name MmgLayerError
 * @summary A wrapper / extensions of MikeLayerError, implementing a tooltip for the description
 *
 * @param props
 */
export const MmgLayerError = (props: ILayerErrorProps) => {
  const { layerDescription, ...otherProps } = props;
  return (
    <MikeLayerError
      {...otherProps}
      layerDescription={
        <MikeTooltip title={layerDescription}>{layerDescription as React.ReactElement<any, any>}</MikeTooltip>
      }
    />
  );
};
