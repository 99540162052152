
export const geometryDetailsPanelPath = '/geometry/:geometryId';
export const geometryOperationPanelPath = '/geometry/:geometryId/operation';
export const geometriesOperationPanelPath = '/geometries/operation';
export const geometriesDeletePanelPath = '/geometries/delete';
export const geometriesOperationSelectItemsPanelPath = '/geometries/operation/select';

const GeometryRoutes = {
  geometryDetailsPanel: {
    path: geometryDetailsPanelPath,
  },
  geometryOperationPanel: {
    path: geometryOperationPanelPath,
  },
  geometriesOperationPanel: {
    path: geometriesOperationPanelPath,
  },
  geometriesDeletePanel: {
    path: geometriesDeletePanelPath,
  },
  geometriesOperationSelectItemsPanel: {
    path: geometriesOperationSelectItemsPanelPath,
  },
};

export default GeometryRoutes