/**
 * Exposes operation REST endpoints related to the API OperationsController.
 *
 * @module OperationsProxy
 * @version 2.0.0
 * @requires Proxy
 *
 */
import { endpoints, paths } from './config';
import Proxy from './Proxy';
import queryString  from 'query-string';
import { IOperationApi } from '../models/IOperationApi';

const {
  workspaces,
  operations,
  geometries,
  meshes,
  modifyMesh,
  descriptions,
  creation,
  execute,
  interpolate,
  variables,
} = paths;

/**
 * Get geometry operation descriptions.
 *
 * @param workspaceId
 * @param geometryIds
 */
export const getGeometryOperationDescriptions = (workspaceId: string, geometryIds: Array<string>) => {
  const queryParams = queryString.stringify({ geometryIds });

  return Proxy.http.get(
    `${
      endpoints.meshAppService
    }/${workspaces}/${workspaceId}/${operations}/${geometries}/${descriptions}?${queryParams}`,
  );
};

/**
 * Get variable operation descriptions.
 *
 * @param workspaceId
 * @param variableIds
 */
export const getVariableOperationDescriptions = (workspaceId: string, variableIds: Array<string>) => {
  const queryParams = queryString.stringify({ variableIds });

  return Proxy.http.get(
    `${
      endpoints.meshAppService
    }/${workspaces}/${workspaceId}/${operations}/${variables}/${descriptions}?${queryParams}`,
  );
};

/**
 * Get create mesh operation descriptions.
 *
 * @param workspaceId
 * @param geometryIds
 * @param variableIds
 * @param queryIds
 */
export const getCreateMeshOperationDescriptions = (
  workspaceId: string,
  geometryIds: Array<string>,
  variableIds: Array<string>,
  queryIds: Array<string>,
) => {
  const queryParams = queryString.stringify({ geometryIds, variableIds, queryIds });

  return Proxy.http.get(
    `${
      endpoints.meshAppService
    }/${workspaces}/${workspaceId}/${operations}/${meshes}/${creation}/${descriptions}?${queryParams}`,
  );
};

/**
 * Get mesh interpolation operation descriptions.
 *
 * @param workspaceId
 * @param variableIds
 *
 * @Note The api currently returns only one description with each possible interpolation as child operation description
 */
export const getMeshInterpolationOperationDescriptions = (workspaceId: string, variableIds: Array<string>) => {
  const queryParams = queryString.stringify({ variableIds });

  return Proxy.http.get(
    `${
      endpoints.meshAppService
    }/${workspaces}/${workspaceId}/${operations}/${meshes}/${interpolate}/${descriptions}?${queryParams}`,
  );
};

/**
 * Get modify mesh operation descriptions.
 * The meshIds array query parameter should contain the id of the mesh from which the operations button was clicked
 *
 * @param workspaceId
 * @param meshIds
 *
 */
export const getModifyMeshOperationDescriptions = async (workspaceId: string, meshIds: Array<string>) => {
  const queryParams = queryString.stringify({ meshIds });
  return await Proxy.http.get(
    `${
      endpoints.meshAppService
    }/${workspaces}/${workspaceId}/${operations}/${meshes}/${modifyMesh}/${descriptions}?${queryParams}`,
  );
};

/**
 * Executes an existing operation by id.
 *
 * @param workspaceId
 * @param operationId
 */
export const executeOperationById = (workspaceId: string, operationId: string) =>
  Proxy.http.post(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}/${operationId}/${execute}`,
    {},
  );

/**
 * Exceutes an operation.
 *
 * @param workspaceId
 * @param operationPayload
 */
export const executeOperation = (workspaceId: string, operationPayload: IOperationApi) => {
  return Proxy.http.post(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}/${execute}`,
    operationPayload,
  );
};

/**
 * Post an operation without executing it.
 *
 * @param workspaceId
 * @param operationPayload
 */
export const createOperation = (workspaceId: string, operationPayload: IOperationApi) =>
  Proxy.http.post(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}`, operationPayload);

/**
 * Get an operation.
 * TODO HEVO Needs to be aligned with the api endpoint when it becomes available
 *
 * @param workspaceId
 * @param operationId
 */
export const getOperation = (workspaceId: string, operationId: string) =>
  Proxy.http.get(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}/${operationId}`);

/**
 * Delete an operation
 *
 * @param workspaceId
 * @param operationId
 */
export const deleteOperation = (workspaceId: string, operationId: string) =>
  Proxy.http.delete(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}/${operationId}`);

export const cancelOperation = (workspaceId: string, operationId: string) =>
  Proxy.http.post(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}/${operationId}/cancel`);

/**
 * Gets all interpolations currently applied on the mesh vtk data; 'draft' operations are included.
 *
 * @param workspaceId
 * @param meshId
 */
export const getCurrentInterpolateMeshOperations = (workspaceId: string, meshId: string) => {
  return Proxy.http.get(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}/${meshes}/${meshId}/${interpolate}`,
  );
};

/**
 * Gets current operation that resulted in the latest mesh vtk data; 'draft' operations are included.
 * Description from API: Retrieves the latest operations implementing ICreateMeshOperationDefinition from the database and returns it as an array of OperationDtos.
 *
 * @param workspaceId
 * @param meshId
 */
export const getCurrentCreateMeshOperations = (workspaceId: string, meshId: string) =>
  Proxy.http.get(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}/${meshes}/${meshId}/create-mesh`,
  );

/**
 * @param workspaceId
 * @param meshId
 */
export const getCurrentMeshPostOperations = (workspaceId: string, meshId: string) =>
  Proxy.http.get(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${operations}/${meshes}/${meshId}/modify-mesh`,
  );

export const getWorkspaceOperations = (workspaceId: string) =>
  Proxy.http.get(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/operations/workspace/descriptions`);
