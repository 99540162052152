/**
 * Exposes methods related to CreateMesh operations.
 *
 * @module MeshOperationsManager
 * @version 2.0.0
 * @requires OperationsProxy
 * @requires OperationsManager
 *
 */
import { HttpErrorHandler } from './http-utils';
import * as OperationsProxy from '../proxies/OperationsProxy';

import { IOperationDescription } from '../models/IOperationDescriptions';
import { IContextualOperationDescriptions, DENSITY_FUNCTION_PARAMETERS } from '../models/IOperationDescriptions';
import {
  IOperationDescriptionApi,
  IParameterDescriptionApi,
  IParameterValuesApi,
} from '../models/IOperationDescriptionsApi';

import OperationMappingUtils from '../managers/operation-mapping-utils';
import { IWorkspaceEnrichedVariable, IWorkspaceVariable } from '../models/IVariables';
import { IOperationConfiguration } from '../models/IOperations';
import OperationsManager from './OperationsManager';
import { isCreateMeshOperationValid } from './MeshOperationValidation';

/**
 * Transforms a request that gets available mesh operation descriptions for the workspace, takes into consideration geometries & variables and returns a basic create mesh operation together with contextual descriptions for children.
 *
 * @param workspaceId
 * @param geometryIds List of geometries to consider for contextualization
 * @param queryIds List of saved queries to consider for contextualization
 * @param workspaceVariables List of variables to consider for contextualization. Is expected to be all available workspace variables
 *
 * @private
 */
export const getContextualCreateMeshOperationDescriptions = (
  workspaceId: string,
  geometryIds: Array<string>,
  queryIds: Array<string>,
  workspaceVariables: Array<IWorkspaceEnrichedVariable>,
) => {
  // The density function only supports gridded variables.
  // This logic could be moved to the api, letting the api return the values as part of the parameter description
  const densityFunctionCompatibleVariables = (workspaceVariables || []).filter((variable) => variable.isGrid);
  const variableIds = densityFunctionCompatibleVariables.map(({ id }) => id);

  return OperationsProxy.getCreateMeshOperationDescriptions(workspaceId, geometryIds, variableIds, queryIds)
    .then((res) => {
      if (!res) {
        return Promise.resolve({});
      }

      const apiOperationDescription = res.data as IOperationDescriptionApi;

      const { childOperationDescriptions, ...basicOperationDescriptionApi } = apiOperationDescription;

      const patchedChildOperationDescriptions = patchAllowedValuesForDensityVariables(
        childOperationDescriptions,
        densityFunctionCompatibleVariables,
      );

      const mappedBasicOperation = OperationMappingUtils.mapOperationDescriptions([basicOperationDescriptionApi]);

      const mappedChildContextualOperationDescriptions = OperationMappingUtils.mapOperationDescriptionsToContextualDescriptions(
        patchedChildOperationDescriptions,
      );

      const mappedDescriptions = {
        basicOperationDescription: mappedBasicOperation[Object.keys(mappedBasicOperation)[0]],
        childContextualOperationDescriptions: mappedChildContextualOperationDescriptions,
      };
      return mappedDescriptions;
    })
    .catch((error) =>
      HttpErrorHandler('Failed to get contextual create mesh operation descriptions.', error),
    ) as Promise<{
    basicOperationDescription: IOperationDescription;
    childContextualOperationDescriptions: IContextualOperationDescriptions;
  }>;
};

/**
 * Will add parametervalues containing variable id and name to parameterdescrion of any VariableDataId property available
 * This is needed until api adds these data
 * */

const patchAllowedValuesForDensityVariables = (
  apiOperationDescriptions: Array<IOperationDescriptionApi>,
  allowedVariables: Array<IWorkspaceVariable>,
) => {
  // no need for patching if no descriptions or allowedVariables
  if (!apiOperationDescriptions || !allowedVariables || allowedVariables.length === 0) {
    return apiOperationDescriptions;
  }

  const patchedOperationDescriptions = apiOperationDescriptions.map(
    (operationDescription: IOperationDescriptionApi) => {
      const { parameterDescriptions, ...other } = operationDescription;

      const patchedParameterDescriptions: Array<IParameterDescriptionApi> = (parameterDescriptions || []).map(
        (parameterDescription) => {
          if (!parameterDescription) {
            return parameterDescription; // return null or undefined, like we got it
          }

          const hasVariableId =
            parameterDescription.name.toUpperCase() === DENSITY_FUNCTION_PARAMETERS.VARIABLE_ID.toUpperCase();

          if (!hasVariableId) {
            return parameterDescription;
          }

          const values: IParameterValuesApi = allowedVariables.reduce(
            (obj, { id, name }) => {
              obj[id] = name;
              return obj;
            },
            {} as IParameterValuesApi,
          );

          return {
            ...parameterDescription,
            values,
          };
        },
      );

      return {
        ...other,
        parameterDescriptions: [...patchedParameterDescriptions],
      };
    },
  );

  return patchedOperationDescriptions;
};

/**
 * Transforms a request that excutes a create mesh operation on a mesh. Basically this generates the mesh.
 *
 * @param workspaceId
 * @param meshConfiguration
 */
export const executeCreateMeshOperation = (workspaceId: string, meshConfiguration: IOperationConfiguration) => {
  if (!meshConfiguration) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  if (!isCreateMeshOperationValid(meshConfiguration)) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  return OperationsManager.executeOperation(workspaceId, {
    ...meshConfiguration,
  }).catch((error) => HttpErrorHandler('Failed to excute createMeshOperation.', error)) as Promise<
    IOperationConfiguration
  >;
};

/**
 * Transforms a request that saves a create mesh operation on a mesh without exceuting it.
 *
 * @param workspaceId
 * @param meshConfiguration
 */
export const saveCreateMeshOperation = (workspaceId: string, meshConfiguration: IOperationConfiguration) => {
  if (!meshConfiguration) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  if (!isCreateMeshOperationValid(meshConfiguration)) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  return OperationsManager.createOperation(workspaceId, meshConfiguration).catch((error) =>
    HttpErrorHandler('Failed to save createMeshOperation.', error),
  ) as Promise<IOperationConfiguration>;
};

/**
 * Transforms a request that gets current create mesh operations ( @see OperationsProxy ).
 *
 * @param workspaceId
 * @param meshId
 */
export const getCurrentCreateMeshOperations = (
  workspaceId: string,
  meshId: string,
): Promise<Array<IOperationConfiguration>> => {
  return OperationsProxy.getCurrentCreateMeshOperations(workspaceId, meshId)
    .then((res) => {
      const operationConfigurations = res.data.map((operation) =>
        OperationMappingUtils.mapOperationApiToOperationConfiguration(operation),
      );
      return operationConfigurations;
    })
    .catch((error) => HttpErrorHandler('Failed to get current create mesh operations.', error));
};

const self = {
  getContextualCreateMeshOperationDescriptions,
  getCurrentCreateMeshOperations,
  executeCreateMeshOperation,
  saveCreateMeshOperation,
};

export default self;
