export const meshDetailsPanelPath = '/mesh/:meshId';
export const meshCreateConfigurePanelPath = '/mesh/create/configure';
export const meshEditConfigurePanelPath = '/mesh/:meshId/configure';
export const meshEditConfigurePanelAddGeometryPath = '/mesh/:meshId/addgeometry/:geometryId/configure';
export const meshCreateInterpolationPanelPath = '/mesh/:meshId/interpolation/create';
export const meshEditInterpolationPanelPath = '/mesh/:meshId/interpolation/:operationId/edit'
export const meshPostOperationPanelPath = '/mesh/:meshId/postoperation'
export const meshAddGeometrySelectMeshPanelPath = '/mesh/addgeometry/:geometryId/selectmesh';
export const meshesDeletePanelPath = '/meshes/delete';

const MeshRoutes = {
  meshDetailsPanel: {
    path: meshDetailsPanelPath,
  },
  meshCreateConfigurePanel: {
    path: meshCreateConfigurePanelPath,
  },
  meshEditConfigurePanel: {
    path: meshEditConfigurePanelPath,
  },
  meshEditConfigurePanelAddGeometry: {
    path: meshEditConfigurePanelAddGeometryPath,
  },
  meshCreateInterpolationPanel: {
    path: meshCreateInterpolationPanelPath,
  },
  meshEditInterpolationPanel: {
    path: meshEditInterpolationPanelPath,
  },
  meshPostOperationPanel: {
    path: meshPostOperationPanelPath,
  },
  meshAddGeometrySelectMeshPanel: {
    path: meshAddGeometrySelectMeshPanelPath,
  },
  meshesDeletePanel: {
    path: meshesDeletePanelPath,
  },
};

export default MeshRoutes
