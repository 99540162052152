/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { t } from '../../../translations/i18n';
import DefaultView from '../../../icons/DefaultView.svg?react';
import Tooltip from '@mui/material/Tooltip';
import { ViewerToolsButtonStyle } from '../../../workspaces/viewer/tools/viewer-tools-styles';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';;
import { store } from '../../../store';
import { EWorkspaceMeshActionType } from '../../../store/actions/WorkspaceMeshActionType';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';
import { useSelector } from 'react-redux';
import { IWorkspace } from '../../../models/IWorkspaces';
import { IGlobalState } from '../../../store/reducers';

const { resetCamera, updateViewer2DLayers, resetCameraToBounds, getState } = MikeVisualizer;

/**
 * @name MmgViewerToolReset
 * @summary Allows resetting camera to fit visible data.
 */
export function MmgViewerToolReset() {
  const workspace: IWorkspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);

  const {bounds, workspaceId} = useMemo(
    () => {
      if (workspace && workspace.bounds) {
        return {bounds: workspace.bounds, workspaceId: workspace.id};
      }
    },
    [workspace],
  );
  /**
   * Resets camera to fit all visible data.
   * Previously, this used to reset to initial bounds. Since initial bounds might be the world view, fitting visible data is a more reliable approach.
   */
  const resetCameraExtent = useCallback(
    () => {
      const activeElement = document.activeElement as HTMLElement;
      activeElement.blur(); // Removes focus from the reset camera button.
      const { renderer } = getState();
      const actors = renderer.getActors();
      const visibleLayers = actors.filter((actor) => actor.getVisibility() === true);

      if (bounds && visibleLayers.length === 0) {
        resetCameraToBounds(bounds);
        updateViewer2DLayers();
      } else {
        resetCamera();
      }

      store.dispatch({
        type: EMapToolActionType.RESET_VIEW,
      });
      store.dispatch({ type: EWorkspaceMeshActionType.CHECK_VIEW_BOUNDS, data: {workspaceId, wait: 1000} });
    },
    [bounds, workspaceId],
  );

  return (
    <Tooltip title={t('TOOL_RESET_VIEW')} placement="right">
      <button css={ViewerToolsButtonStyle} onClick={resetCameraExtent}>
        <DefaultView />
      </button>
    </Tooltip>
  );
}
