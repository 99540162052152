import { useCallback, useMemo } from 'react';
import { sortDataItems } from '../../managers/model-utils';
import { IWorkspaceGeometry } from '../../models/IGeometries';
import { IWorkspaceMesh } from '../../models/IMeshes';
import { IParameterDescription } from '../../models/IOperationDescriptions';
import { IWorkspaceQuery } from '../../models/IQueries';
import { IWorkspaceVariable } from '../../models/IVariables';
import { getDataItemsByIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { getParamValueString } from './parameter-utils';

/**
 * Custom hook that finds select values corresponding to allowed data items of a parameter description
 * Will return an updated parameterDescription with 'values' defined based on the allowedValues prop as well as a function to get the displayname from a value
 * @param parameterDescription
 * @param dataItems
 */
export const useAllowedDataItemsAsSelectValues = (
  parameterDescription: IParameterDescription,
  dataItems: Array<IWorkspaceGeometry> | Array<IWorkspaceMesh> | Array<IWorkspaceVariable> | Array<IWorkspaceQuery>,
): {
  parameterDescriptionWithValues: IParameterDescription;
  getValueDisplayName: (val: string) => string;
} => {
  const selectValues: { [id: string]: string } = useMemo(
    () => {
      const { allowedValues } = parameterDescription || {};

      // an empty list means that no items are available to select.
      if (!allowedValues || allowedValues.length === 0) {
        return {} as { [id: string]: string } ;
      }

      const allowedItems = getDataItemsByIds(dataItems, allowedValues);
      const sortedItems = sortDataItems(allowedItems);

      const values = sortedItems.reduce((acc, cur) => {
        return { ...acc, [cur.id]: cur.name };
      }, {});

      return values as { [id: string]: string };
    },
    [parameterDescription, dataItems],
  );

  const selectParameterDescription: IParameterDescription = useMemo(
    () => {
      return { ...parameterDescription, values: selectValues };
    },
    [selectValues, parameterDescription],
  );

  const getValueDisplayName = useCallback(
    (val: string) => {
      return getParamValueString(val, selectParameterDescription) as string;
    },
    [selectParameterDescription],
  );

  return {
    parameterDescriptionWithValues: selectParameterDescription,
    getValueDisplayName,
  };
};
