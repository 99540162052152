import { store } from '../../store/store';
import { t } from '../../translations/i18n';

import OperationsManager from '../../managers/OperationsManager';
import MeshOperationsManager from '../../managers/MeshOperationsManager';

/**
 * Handles a mesh generation event, that can typically be triggered from a context menu or 'Generate button'.
 *
 * @param _projectId
 * @param workspaceId
 * @param meshId
 */
export const onMeshGenerate = (_projectId: string, workspaceId: string, meshId: string) => {
  return MeshOperationsManager.getCurrentCreateMeshOperations(workspaceId, meshId)
    .then((operations) => {
      if (!operations || operations.length === 0) {
        return Promise.resolve(null);
      }

      return OperationsManager.executeOperationById(workspaceId, operations[0].id);
    })
    .then(() => {
      const toast = {
        text: t('MESH_SUBMITTED'),
      };

      store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
    })
    .catch((error) => {
      const toast = {
        text: t('MESH_SUBMITTING_FAILED'),
        operationId: error.operationId,
      };

      store.dispatch({ type: 'toast/ADD/ERROR', toast });
      throw error;
    });
};

const self = {
  onMeshGenerate,
};

export default self;
