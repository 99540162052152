/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../../translations/i18n';
import { ViewerToolsButtonStyle, ViewerToolsButtonActiveStyle } from './viewer-tools-styles';
import Extracting from '../../../icons/Extracting.svg?react';
import Tooltip from '@mui/material/Tooltip';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';
import { CONFIRM_PANEL_IDS } from '../../../shared/confirm-containers/confirm-constants';
import { enableExtractFeatures } from './viewer-tool-2d-extract-features-util';
import { EWorkspaceActionType } from '../../../store/actions/WorkspaceActionType';
import { IGlobalState } from '../../../store/reducers';
import { store } from '../../../store';
const { disable2DPolygonSelection, clearDrawnVectorLayerData } = MikeVisualizer;

/**
 * @name MmgConnectedViewerTool2DExtractFeatures
 * @summary Allows enabling/disabling 2d extracting
 *
 */
export function MmgConnectedViewerTool2DExtractFeatures() {
  const activeConfirmPanelId: string = useSelector(
    (state: IGlobalState) => state.ConfirmPanelReducer.activeConfirmPanelId,
  );
  const viewerBaseMapId: string = useSelector((state: IGlobalState) => state.ViewerModeReducer.viewerBaseMapId);

  const extractFeaturesToolActive =
    activeConfirmPanelId === CONFIRM_PANEL_IDS.EXTRACT_DATA ||
    activeConfirmPanelId === CONFIRM_PANEL_IDS.GEOMETRY_MULTIPLE_EXTRACT;

  /*
   * Enable closed polygon drawing widget.
   */
  const enableExtractFeaturesTool = useCallback(
    () => {
      enableExtractFeatures(viewerBaseMapId);
    },
    [viewerBaseMapId],
  );

  /*
   * Disable closed polygon drawing widget, clearing geometry selections.
   */
  const disableExtractFeaturesTool = () => {
    disable2DPolygonSelection();
    clearDrawnVectorLayerData();
    store.dispatch({ type: 'workspace/geometries/DESELECT_ALL' });
  };

  /**
   * Opens extract features tool, setting the active panel to extract features.
   */
  const toggleExtractFeaturesTool = () => {
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.GEOMETRY_MULTIPLE_EXTRACT,
    });
  };

  useEffect(
    () => {
      if (extractFeaturesToolActive) {
        enableExtractFeaturesTool();
      } else {
        disableExtractFeaturesTool();
      }
    },
    [extractFeaturesToolActive, enableExtractFeaturesTool],
  );

  return (
    <Tooltip title={t('TOOL_EXTRACT_FEATURES')} placement="right">
      <button
        css={css`${ViewerToolsButtonStyle} ${extractFeaturesToolActive && ViewerToolsButtonActiveStyle}`}
        onClick={toggleExtractFeaturesTool}
      >
        <Extracting />
      </button>
    </Tooltip>
  );
}
