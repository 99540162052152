import { useMemo } from 'react';
import ModelUtils from '../../managers/model-utils';
import { IOperationConfiguration } from '../../models/IOperations';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';

export interface IGroupedVariableConfigurations {
  [variableId: string]: {
    variable: IWorkspaceEnrichedVariable;
    variableConfiguration: IOperationConfiguration;
  };
}

/**
 * Group the given child configurations by variable id for easy lookup. The groups will be sorted by the variable items
 *
 * @param childConfigurations Is expected to hold configruations of variables.
 * @param variables
 */
export const useGroupedAndSortedVariableConfigurations = (
  childConfigurations: Array<IOperationConfiguration>,
  variables: Array<IWorkspaceEnrichedVariable>,
): IGroupedVariableConfigurations => {
  // todo hevo might be better to use useEffect instead and keep result in state. This would allow checking if dependencies has actually changed.
  const groupedVariableConfigurations = useMemo(
    () => {
      if (!childConfigurations || childConfigurations.length === 0 || !variables || variables.length === 0) {
        return {} as IGroupedVariableConfigurations;
      }

      const sortedVariables = ModelUtils.sortVariables(variables) as Array<IWorkspaceEnrichedVariable>;

      const grouped = sortedVariables.reduce(
        (acc, variable) => {
          const variableConfiguration = childConfigurations.find(
            ({ inputIds }) => inputIds && inputIds.indexOf(variable.id) !== -1,
          );

          // skip variables for which we do not have a configuration
          if (!variableConfiguration) {
            return acc;
          }

          return {
            ...acc,
            [variable.id]: {
              variable,
              variableConfiguration,
            },
          };
        },
        {} as IGroupedVariableConfigurations,
      );

      return grouped;
    },
    [variables, childConfigurations],
  );

  return groupedVariableConfigurations;
};
