import { t } from '../../translations/i18n';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { onMeshGenerate } from './mesh-details-util';
import MikeButton from '../../shared-components/mike-button';

type ConfirmGenerateMeshDialogProps = {
  projectId: string;
  workspaceId: string;
  mesh: IWorkspaceEnrichedMesh;
  open: boolean;
  onClose: (confirmed: boolean) => void;
};

/**
 * Shows a confimation dialog for generating a mesh.
 * @param props
 *
 * Todo hevo confirm dialogs for all layer types (meshes, geometry and variables) are avery similar. We could maybe like make something more reusable.
 */
export const MmgConfirmGenerateMeshDialog = (props: ConfirmGenerateMeshDialogProps) => {
  const { open, onClose, mesh, projectId, workspaceId } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleOk = () => {
    onMeshGenerate(projectId, workspaceId, mesh.id);
    closeDialog(true);
  };

  const handleCancel = () => {
    closeDialog(false);
  };

  const closeDialog = (confirmed: boolean) => {
    onClose(confirmed);
  };

  if (!mesh) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      id="confirm-generate-mesh"
      aria-labelledby="generate-mesh-dialog-title"
      aria-describedby="generate-mesh-dialog-description"
    >
      <DialogTitle id="generate-mesh-dialog-title">
        {t('MESH_CONFIRM_GENERATE_TITLE')}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="generate-mesh-dialog-description">
          {t('MESH_CONFIRM_GENERATE_TEXT', 1, {
            name: mesh.name,
          })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <MikeButton onClick={handleCancel} variant="outlined" color="secondary">
          {t('CANCEL')}
        </MikeButton>

        <MikeButton onClick={handleOk} variant="contained" color="secondary" autoFocus>
          {t('GENERATE_MESH')}
        </MikeButton>
      </DialogActions>
    </Dialog>
  );
};
