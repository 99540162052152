/** @jsxImportSource @emotion/react */
import { useState, useCallback } from 'react';
import { t } from '../../../translations/i18n';
import { isEqual, isEmpty } from 'lodash-es';
import { MmgNonHoverLayer } from '../../../shared/layers/mesh-non-hover-layer';
import { MmgItemCard } from '../../../shared/cards/item-card';
import VariableIconUtils from '../../../variables/variable-icon-utils';
import { MmgOperationConfiguration } from '../../../operations/operation-configuration';
import { IOperationDescription } from '../../../models/IOperationDescriptions';
import { MmgPanelSubsection } from '../../../shared/panels/panel-subsection';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { MmgMeshInterpolationVariableSummary } from '../../../meshes/interpolation/summary/mesh-interpolation-variable-summary';
import { MmgConnectedMeshAttributeQueryConfiguration } from '../../../meshes/post-operations/mesh-attribute-query-configuration';
import { IQueryDefinitionApi } from '../../../models/IQueryDefinitions';
import { IWorkspaceAttribute } from '../../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../../models/IWorkspaceAttributeSettings';
import { anythingToConfigure } from '../../../operations/operation-utils';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { LayerWithoutBorderStyle } from '../../../queries/spatial-selections/spatial-selection-layer';

type MeshInterpolationVariableConfigurationProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
  variable: IWorkspaceEnrichedVariable;
  initialVariableConfiguration?: IOperationConfiguration;
  variableInterpolationDescriptions: {
    [operationKey: string]: IOperationDescription;
  };
  meshAttributes?: Array<IWorkspaceAttribute>;
  meshAttributeSettings?: Array<IWorkspaceAttributeSettings>;
  meshQueries?: Array<IWorkspaceQuery>;

  onVariableInterpolationConfigurationChanged?: (operationConfiguration: IOperationConfiguration) => void;
};

/**
 * @name MmgMeshInterpolationVariableConfiguration
 * @summary Generic component that given a variable and a list of interpolation childoperation descriptions for that variable, allows configuring the interpolation operation for the variable.
 * Will supply the complete list of updated configuration objects via a callback.
 *
 * @param props
 */
export const MmgMeshInterpolationVariableConfiguration = (props: MeshInterpolationVariableConfigurationProps) => {
  const {
    variable,
    initialVariableConfiguration,
    variableInterpolationDescriptions,
    meshAttributes,
    meshAttributeSettings,
    meshQueries,
    onVariableInterpolationConfigurationChanged,
  } = props;

  const [dirtyVariableConfiguration, setDirtyVariableConfiguration] = useState(initialVariableConfiguration); // Object with current configuration of the variable. This will be sent higher up the component chain and used for submission.
  const [isItemCardOpen, setIsItemCardOpen] = useState(false);

  const onItemCardOpenStateToggled = (isOpen: boolean) => {
    setIsItemCardOpen(isOpen);
  };

  const onVariableConfigurationChanged = (newVariableConfiguration: IOperationConfiguration) => {
    const nextConfiguration = {
      ...newVariableConfiguration,
      inputIds: [variable.id],
    };

    setDirtyVariableConfiguration(nextConfiguration);
    callOnVariableOperationConfigurationChanged(newVariableConfiguration);
  };

  /**
   * Callback for mesh-query to call whenever something changes in the query.
   * The query can be submitted later.
   *
   * @param newQueryDefinition
   */
  const onQueryDefinitionChanged = (newQueryDefinition: IQueryDefinitionApi) => {
    const nextConfiguration = {
      ...dirtyVariableConfiguration,
      queryDefinition: { ...newQueryDefinition },
    };

    setDirtyVariableConfiguration(nextConfiguration);
    callOnVariableOperationConfigurationChanged(nextConfiguration);
  };

  const callOnVariableOperationConfigurationChanged = useCallback(
    (newVariableConfiguration) => {
      // only call back if configuration actually changed
      if (
        onVariableInterpolationConfigurationChanged &&
        !isEqual(newVariableConfiguration, initialVariableConfiguration)
      ) {
        onVariableInterpolationConfigurationChanged(newVariableConfiguration);
      }
    },
    [onVariableInterpolationConfigurationChanged, initialVariableConfiguration],
  );

  // if no supported operations exists, do not show anything
  if (!variableInterpolationDescriptions || isEmpty(variableInterpolationDescriptions)) {
    return null;
  }

  // currently we assume that there will only be one type of operation for each variable
  const firstOperationDescription = (variableInterpolationDescriptions
    ? variableInterpolationDescriptions[Object.keys(variableInterpolationDescriptions)[0]]
    : {}) as IOperationDescription;

  const selectedOperationDescription = dirtyVariableConfiguration
    ? variableInterpolationDescriptions[dirtyVariableConfiguration.operationType]
    : firstOperationDescription;

  const { supportsQuery } = selectedOperationDescription || {};
  const { queryDefinition } = dirtyVariableConfiguration || {};

  const allowToggle = anythingToConfigure(selectedOperationDescription);

  return (
    <MmgItemCard
      openLabel={t('EDIT')}
      closeLabel={t('CLOSE')}
      cardLayer={
        /* TODO dan: this should show if the item on this layer is 'in progress' / show a spinner or failed, etc */
        <MmgNonHoverLayer
          layerId={variable.id}
          layerName={variable.name}
          leftIcon={VariableIconUtils.getIcon(variable)}
          cssProp={LayerWithoutBorderStyle}
        />
      }
      cardSummary={
        <>
          <MmgMeshInterpolationVariableSummary
            operationConfiguration={dirtyVariableConfiguration}
            meshAttributes={meshAttributes}
            meshAttributeSettings={meshAttributeSettings}
            meshQueries={meshQueries}
          />
        </>
      }
      onOpenStateChanged={onItemCardOpenStateToggled}
      open={isItemCardOpen}
      canBeToggled={allowToggle}
    >
      <MmgPanelSubsection>
        <MmgOperationConfiguration
          operationDescription={selectedOperationDescription}
          initialOperationConfiguration={dirtyVariableConfiguration}
          onConfigurationChanged={onVariableConfigurationChanged}
        />

        {supportsQuery && (
          <MmgConnectedMeshAttributeQueryConfiguration
            initialQueryDefinition={queryDefinition}
            onQueryDefinitionChanged={onQueryDefinitionChanged}
          />
        )}
      </MmgPanelSubsection>
    </MmgItemCard>
  );
};
