/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export interface IToast {
  id: string;
  type: EToastTypes;
  text: string;
  operationId?: string;
  timeout: number;
}

export enum EToastTypes {
  INFO = 'regular',
  SUCCESS = 'success',
  WARNING = 'error',
  ERROR = 'error',
  WORKING = 'success',
}

/**
 * A plain object is also exported with the data types in order to be used as literal values.
 * The EToastTypes enum can only be used for type definitions.
 */
export const TOAST_TYPES = {
  INFO: EToastTypes.INFO,
  SUCCESS: EToastTypes.SUCCESS,
  WARNING: EToastTypes.WARNING,
  ERROR: EToastTypes.ERROR,
  WORKING: EToastTypes.WORKING,
};
