import Skeleton from '@mui/material/Skeleton';
import { CARD_SPACING, CARD_WIDTH, CARD_HEIGHT } from './workspace-card-styles';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';

/**
 * @name MmgCardPlaceholders
 * @summary Renders 'n' workspace card placeholders, using Skeletons.
 * NB: treat this as a temporary component :)
 *
 * @param props
 */
export const MmgCardPlaceholders = (props) => {
  const { size, excludeWrapper } = props;

  const Wrapper = (wrapperProps) =>
    !excludeWrapper ? (
      <div
        style={{
          marginTop: CARD_SPACING,
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        {wrapperProps.children}
      </div>
    ) : (
      <>{wrapperProps.children}</>
    );

  return (
    <Wrapper>
      {[...Array(size)].map((_val, index) => (
        <div
          key={index}
          style={{
            width: CARD_WIDTH,
            height: CARD_HEIGHT,
            display: 'flex',
            alignItems: 'flex-end',
            flexShrink: 0,
            background: MIKE_COLORS.MEDIUMGREY_LIGHT,
            margin: `0 ${CARD_SPACING} ${CARD_SPACING} 0`,
            padding: CARD_SPACING,
          }}
        >
          <div style={{ width: '100%' }}>
            <Skeleton variant="rectangular" />
            <Skeleton />
            <Skeleton width="60%" />
          </div>
        </div>
      ))}
    </Wrapper>
  );
};
