import ITenant from '../../models/ITenant';
import { IUser } from '../../models/IUser';
import { IAction } from '../actions/Action';
import { EIAMActionType } from '../actions/IAMActionType';

const initialState = {
  user: null,
  userLoggedIn: null,
  userSessionExpired: false,
  tenants: new Array<ITenant>(),
  loadingTenants: false,
};

export interface IUserState {
  user: IUser | null;
  userLoggedIn: boolean | null;
  tenants: Array<ITenant>;
  loadingTenants: boolean;
  userSessionExpired: boolean;
}

/**
 * User Reducer.
 * - returns new states for matched user actions.
 *
 * @name UserReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default (state: IUserState = initialState, action: IAction): IUserState => {
  switch (action.type) {
    case EIAMActionType.SET_MY_TENANTS:{
      const currentUser = state.user;
      if (currentUser && currentUser.tenantId && !currentUser.tenantName){
        const tenants: Array<ITenant> = action.data
        const currentTenant = tenants.find((t: ITenant) => t.id === currentUser.tenantId)
        if (currentTenant !== undefined){
          return {
            ...state,
            user: {...currentUser, tenantName: currentTenant.name},
            tenants: action.data,
          };
        }
      }
      return {
        ...state,
        tenants: action.data,
      };
    }
      
    
      
    case EIAMActionType.LOADING_MY_TENANTS:
      return {
        ...state,
        loadingTenants: action.data,
      };
    case EIAMActionType.USER_LOGIN:
      return {
        ...state,
        user: action.data,
        userLoggedIn: true,
        userSessionExpired: false,
      };

    case EIAMActionType.USER_SESSION_EXPIRED:
      return {
        ...state,
        userSessionExpired: action.data,
        userLoggedIn: false,
      };

    case EIAMActionType.USER_LOGOUT:
      return {
        ...state,
        user: null,
        userLoggedIn: false,
      };

    default:
      return state;
  }
};
