import React from 'react';
import { MmgTabPanel } from '../../shared/tabs/tab-panel';
import { MmgTabs } from '../../shared/tabs/tabs';
import { MmgTab } from '../../shared/tabs/tab';
import { PANEL_TABS } from '../../shared/panels/mesh-panel-constants';
import { MmgConnectedMeshOperationSummary } from '../../meshes/configure/summary/mesh-operation-summary';
import MmgMeshPostOperationSummary from '../../meshes/post-operations/mesh-post-operation-summary';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';

const MmgMeshConfigureTab = ({ projectId, workspaceId, meshId }) => {
  const [value, setValue] = React.useState(PANEL_TABS.MESH_CONFIGURE_GEOM_TAB);

  const handleTabChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <MmgTabs value={value} onChange={handleTabChange}>
        <MmgTab label="Geometries configuration" value={PANEL_TABS.MESH_CONFIGURE_GEOM_TAB} index={0} />
        <MmgTab label="Applied operations" value={PANEL_TABS.MESH_OPERATION_LIST_TAB} index={1} />
      </MmgTabs>
      <MmgPanelSubsection>
        <MmgTabPanel value={PANEL_TABS.MESH_CONFIGURE_GEOM_TAB} index={0} currentValue={value}>
          <MmgConnectedMeshOperationSummary projectId={projectId} workspaceId={workspaceId} meshId={meshId} />
        </MmgTabPanel>

        <MmgTabPanel value={PANEL_TABS.MESH_OPERATION_LIST_TAB} index={1} currentValue={value}>
          <MmgMeshPostOperationSummary meshId={meshId} workspaceId={workspaceId} />
        </MmgTabPanel>
      </MmgPanelSubsection>
    </>
  );
};

export default MmgMeshConfigureTab;
