/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from '@emotion/react';
import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import CheckIcon from '@mui/icons-material/Check';

import { LayerIconStyle, LayerButtonStyle, LayerStyle } from './layer-styles';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

const FormStyle = css`
  width: 100%;
  padding-right: ${mikeSharedTheme.spacing(1)};
`;

export type LayerEditProps = {
  leftIcon?: React.ReactNode;
  layerName: string;
  onLayerUpdate: (layer: any) => void;
  css?: Interpolation<Theme>;
};

/**
 * @name MikeLayerEdit
 * @summary A generic layer edit that allows updating the name.
 *
 * @param props
 */
export function MikeLayerEdit(props: LayerEditProps) {
  const { layerName, leftIcon, css: cssProp } = props;
  const layerNameEl = useRef<HTMLInputElement>();

  const submitUpdate = () => {
    props.onLayerUpdate({
      name: (layerNameEl.current as HTMLInputElement).value,
    });
  };

  return (
    <article css={[LayerStyle,cssProp && cssProp]}>
      <button css={[LayerButtonStyle, LayerIconStyle]}>{leftIcon}</button>

      <form onSubmit={submitUpdate} css={FormStyle}>
        <FormControl fullWidth={true}>
          <Input
            autoComplete="off"
            fullWidth={true}
            id="layer-name"
            inputRef={layerNameEl}
            type={'text'}
            defaultValue={layerName}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="save changes" onClick={submitUpdate}>
                  <CheckIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
    </article>
  );
}
