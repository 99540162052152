import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { LinearProgress } from '@mui/material';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgPanelHeaderEdit } from '../../shared/panels/panel-header-edit';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import { TOOL_BUTTON_TYPES } from '../../shared/tools/IToolButton';
import { MmgTabs } from '../../shared/tabs/tabs';
import { MmgTab } from '../../shared/tabs/tab';
import { ELEMENT_CATEGORIES, PANEL_TABS } from '../../shared/panels/mesh-panel-constants';
import { MmgConnectedLayerMeshActions } from '../list/actions/layer-mesh-actions';
import { MmgTabPanel } from '../../shared/tabs/tab-panel';
import { MmgMeshOverview } from './mesh-overview';
import { MmgSpatialSelectionList } from '../../queries/spatial-selections/spatial-selection-list';
import { MmgConnectedMeshTool2DSpatialSelection } from '../../meshes/tools/mesh-tool-2d-spatial-selection';
import LayerUtils, { fetchLayer, fetchTiledLayer } from '../../shared/layers/layer-utils';
import { MmgConnectedMeshQuerySummary } from '../queries/mesh-queries-summary';
import { getMeshDescription } from '../../shared/panels/panel-utils';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceMeshesManager from '../../managers/WorkspaceMeshesManager';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { IWorkspaceQuery } from '../../models/IQueries';
import { MmgConnectedMeshInterpolationsSummary } from '../interpolation/summary/mesh-interpolations-summary';
import { IOperationMetadata, OPERATION_STATES } from '../../models/IOperations';
import { useCurrentTab } from '../../shared/tabs/useCurrentTab';
import { useHideOtherLayers } from '../../shared/hooks/useHideOtherLayers';
import { IProject } from '../../models/IProject';
import MmgMeshConfigureTab from '../../meshes/details/mesh-configure-tab';
import { MmgOperationOverflowMenu } from '../../operations/actions/operation-overflow-menu';
import WorkspaceMeshOperationSelectors from '../../store/selectors/WorkspaceMeshOperationSelectors';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { useNavigate, useParams } from 'react-router-dom';
import { IGlobalState } from '../../store/reducers';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import MikeVisualizerViewManager from '../../MikeVisualizer/lib/MikeVisualizerViewManager';

const { isLayerFailed, isLayerWorking, isLayerLoading, isLayerConflicting } = LayerUtils;

/**
 * @name MmgConnectedMeshDetails  Displays mesh detailed information, such as containing members, statistics, etc.
 * @summary Parent of the viewer and panels.
 *
 */
export const MmgConnectedMeshDetails = () => {
  const { projectId, workspaceId, meshId } = useParams();
  const navigate = useNavigate();
  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const getMeshQueriesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshQueries();

  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const workspaceOperations: any = useSelector(
    (state: IGlobalState) => state.WorkspaceOperationReducer.workspaceOperations,
  );
  const workspacePanelSettings = useSelector(
    (state: IGlobalState) => state.PanelSettingsReducer.workspacePanelSettings,
  );
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const streamingIds: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.streamingIds,
  );

  const mesh: IWorkspaceEnrichedMesh | null = useSelector((state: IGlobalState) =>
    getMeshSelectorInstance(state, { meshId }),
  );
  const meshDrawnData: IDrawnDataItem | null = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId }),
  );
  const meshQueries: Array<IWorkspaceQuery> = useSelector((state: IGlobalState) =>
    getMeshQueriesSelectorInstance(state, {
      meshId,
    }),
  );

  const processingMeshOperation: IOperationMetadata | null = useSelector((state: IGlobalState) =>
    WorkspaceMeshOperationSelectors.getLatestProcessingMeshOperationForMesh(state, { meshId }),
  );

  const [isEditingName, setIsEditingName] = useState(false);
  const { onTabChange } = useCurrentTab(PANEL_TABS.MESH_OVERVIEW_TAB, workspaceId, meshId);
  const title = mesh && mesh.name ? mesh.name : t('MESH');
  const drawnMeshes = meshDrawnData ? [meshDrawnData] : [];
  const missingMesh = !mesh || !mesh.id;
  const isLoading = isLayerLoading(mesh, drawnMeshes);
  const isFailed = isLayerFailed(mesh);
  const isConflicting = isLayerConflicting(mesh);
  const isWorking = isLayerWorking(mesh, drawnMeshes);
  const description = getMeshDescription(mesh, drawnMeshes, false);
  const conflictingState = mesh && mesh.conflictingState ? mesh.conflictingState : undefined;
  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  const layerSettings =
    workspacePanelSettings &&
    workspacePanelSettings.find((sett) => {
      return sett.id === mesh.id;
    });

  const currentTab = layerSettings ? layerSettings.meshPanelActive : PANEL_TABS.MESH_OVERVIEW_TAB;

  useHideOtherLayers({
    elementId: meshId,
    elementCategory: ELEMENT_CATEGORIES.MESH,
  });

  const tiledMeshIdToLoad = useMemo(
    () => {
      if (!mesh || !mesh.isTiled) {
        return null;
      }
      const id = mesh.id;
      if (loadedData.includes(id) || streamingIds.includes(id)) {
        return null;
      }
      return id;
    },
    [loadedData, mesh, streamingIds],
  );

  useEffect(
    () => {
      if (tiledMeshIdToLoad) {
        const { getCurrentViewBounds } = MikeVisualizerViewManager;
        const bounds = getCurrentViewBounds();
        fetchTiledLayer(tiledMeshIdToLoad, [], bounds, false);
      }
    },
    [tiledMeshIdToLoad],
  );

  useEffect(
    () => {
      if (!mesh) {
        return;
      }
      if (!loadedData.includes(mesh.id)) {
        if (!mesh.isTiled) {
          fetchLayer(mesh.id, ELEMENT_CATEGORIES.GEOMETRY);
        }
      }
    },
    [mesh, loadedData],
  );

  const getConflicts = () => {
    if (mesh.state === 'Outdated' || mesh.state === 'Conflicting') {
      const filteredOp = workspaceOperations.filter((op) => {
        return op.outputIds && op.outputIds.includes(mesh.id);
      });

      const messages = filteredOp.filter((op) => {
        return op.messages && op.messages.length > 0;
      });

      const relevantMessages = messages.map((message) => {
        const f = message.messages.filter((m) => {
          return m.code === 'INPUT_DELETED' || m.code === 'INPUT_CHANGED' ? m : null;
        });
        return f;
      });
      return relevantMessages;
    }
  };

  const conflicts = mesh && getConflicts();
  const flattenConflicts = conflicts && conflicts.flat();

  useEffect(
    () => {
      // Set the selection target. This will be used if the selection tool is activated.
      store.dispatch({
        type: 'maptools/mesh/SET_SPATIAL_SELECTION_TARGET',
        meshSpatialSelectionTargetId: meshId,
      });
    },
    [meshId],
  );

  useEffect(() => {
    store.dispatch({
      type: 'maptools/mesh/ALLOW/SPATIAL_SELECTION',
    });

    return () => {
      // on unmount we reset to default tools
      store.dispatch({
        type: EMapToolActionType.RESET_ALLOWED_TOOLS,
      });
    };
  }, []);

  // always go to workspace panel, no matter how we got here
  const onPanelExit = () => {
    navigateToWorkspacePanel();
  };

  const navigateToWorkspacePanel = () => {
    navigate(getRouteByPath(ROUTES.workspace.path, { workspaceId, projectId })); 
  };

  /**
   * Handles mesh panel updates, submitting the name to the API.
   *
   * @param panel
   * @param panel.name
   */
  const onPanelUpdate = ({ name }) => {
    WorkspaceMeshesManager.renameMesh(workspaceId, meshId, name)
      .then(() => {
        store.dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('MESH_UPDATED_SUCCESSFULLY'),
          },
        });

        setIsEditingName(false);
      })
      .catch((error) => {
        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: {
            text: t('MESH_UPDATE_FAILED'),
          },
        });

        throw error;
      });
  };

  /**
   * Gets header actions for panel more-menu.
   */
  const getPanelHeaderActions = useCallback(
    () => {
      if (!mesh || !canUpdateWorkspace) {
        return null;
      }

      if (processingMeshOperation) {
        return (
          <MmgOperationOverflowMenu
            projectId={projectId}
            workspaceId={workspaceId}
            operation={processingMeshOperation}
          />
        );
      }

      /**
       * Toggles editing state of mesh name.
       */
      const toggleMeshNameEdit = () => {
        setIsEditingName(!isEditingName);
      };

      return (
        <MmgConnectedLayerMeshActions
          layerId={mesh.id}
          mesh={mesh}
          meshDrawnData={meshDrawnData}
          canToggleLayerVisibility={false}
          canOpenInterpolations={false}
          onMeshEdit={toggleMeshNameEdit}
        />
      );
    },
    [canUpdateWorkspace, isEditingName, mesh, meshDrawnData, processingMeshOperation, projectId, workspaceId],
  );

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          {!isEditingName ? (
            <MmgPanelHeader
              noBorder={true}
              panelTitle={title}
              panelDescription={description}
              onPanelExit={onPanelExit}
              panelHeaderActions={getPanelHeaderActions()}
            />
          ) : (
            <MmgPanelHeaderEdit panelTitle={title} onPanelExit={onPanelExit} onPanelUpdate={onPanelUpdate} />
          )}

          {!missingMesh && (
            <MmgTabs value={currentTab} onChange={onTabChange}>
              <MmgTab label={t('MESH_OVERVIEW_TAB')} value={PANEL_TABS.MESH_OVERVIEW_TAB} index={0} />
              <MmgTab label={t('MESH_CONFIGURATION_TAB')} value={PANEL_TABS.MESH_CONFIGURATION_TAB} index={1} />

              <MmgTab label={t('MESH_INTERPOLATION_TAB')} value={PANEL_TABS.MESH_INTERPOLATION_TAB} index={2} />
              <MmgTab label={t('MESH_SELECTIONS_TAB')} value={PANEL_TABS.MESH_SELECTIONS_TAB} index={3} />
              <MmgTab label={t('MESH_QUERY_TAB')} value={PANEL_TABS.MESH_QUERY_TAB} index={4} />
            </MmgTabs>
          )}

          {isWorking && <LinearProgress />}
        </MikeStickyPanelHeaderContainer>

        {missingMesh && !isLoading ? (
          <MmgPanelSubsection>
            <p>{t('MESH_MISSING')}</p>
          </MmgPanelSubsection>
        ) : (
          <>
            <div>
              {isFailed && (
                <MmgMessageBanner
                  messageTitle={t('MESSAGE_MESH_FAILED_TITLE')}
                  message={mesh.message}
                  messageType="failed"
                />
              )}

              {isConflicting &&
                conflictingState.state === OPERATION_STATES.FAILED && (
                  <MmgMessageBanner
                    messageTitle={t('OP_MESH_CONFLICTING')}
                    message={conflictingState.message}
                    messageType="failed"
                  />
                )}

              {isConflicting &&
                conflictingState.state !== OPERATION_STATES.FAILED && (
                  <MmgMessageBanner messageTitle={mesh.message} message={''} messageType="warning" />
                )}

              {flattenConflicts &&
                flattenConflicts.map((k) => {
                  return <MmgMessageBanner messageTitle={k.message} message={''} messageType="warning" />;
                })}
              {currentTab === PANEL_TABS.MESH_OVERVIEW_TAB && (
                <MmgTabPanel value={PANEL_TABS.MESH_OVERVIEW_TAB} currentValue={currentTab} index={0}>
                  <MmgMeshOverview
                    projectId={projectId}
                    workspaceId={workspaceId}
                    mesh={mesh}
                    meshDrawnData={meshDrawnData}
                  />
                </MmgTabPanel>
              )}

              {currentTab === PANEL_TABS.MESH_CONFIGURATION_TAB && (
                <MmgTabPanel value={PANEL_TABS.MESH_CONFIGURATION_TAB} currentValue={currentTab} index={1}>
                  <MmgMeshConfigureTab projectId={projectId} workspaceId={workspaceId} meshId={meshId} />
                </MmgTabPanel>
              )}

              {currentTab === PANEL_TABS.MESH_INTERPOLATION_TAB && (
                <MmgTabPanel value={PANEL_TABS.MESH_INTERPOLATION_TAB} currentValue={currentTab} index={2}>
                  <MmgConnectedMeshInterpolationsSummary
                    projectId={projectId}
                    workspaceId={workspaceId}
                    meshId={meshId}
                  />
                </MmgTabPanel>
              )}

              {currentTab === PANEL_TABS.MESH_SELECTIONS_TAB && (
                <MmgTabPanel value={PANEL_TABS.MESH_SELECTIONS_TAB} currentValue={currentTab} index={3}>
                  {workspaceId && (
                    <MmgSpatialSelectionList
                      createSelectionButton={
                        canUpdateWorkspace && (
                          <MmgConnectedMeshTool2DSpatialSelection toolButtonType={TOOL_BUTTON_TYPES.REGULAR_BUTTON} />
                        )
                      }
                      selections={meshQueries}
                      workspaceId={workspaceId}
                    />
                  )}
                </MmgTabPanel>
              )}
              {currentTab === PANEL_TABS.MESH_QUERY_TAB && (
                <MmgTabPanel value={PANEL_TABS.MESH_QUERY_TAB} currentValue={currentTab} index={4}>
                  <MmgConnectedMeshQuerySummary />
                </MmgTabPanel>
              )}
            </div>
          </>
        )}
      </MikeStickyPanel>
    </>
  );
};
