import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../store/store';
import { MmgConnectedMeshSelectList } from '../meshes/select-list/mesh-select-list';
import { MmgConnectedGeometrySelectList } from '../geometries/select-list/geometry-select-list';
import { MmgConnectedVariableSelectList } from '../variables/select-list/variable-select-list';
import WorkspaceDrawnDataSelectors from '../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceGeometrySelectors from '../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceMeshSelectors from '../store/selectors/WorkspaceMeshSelectors';
import WorkspaceVariableSelectors from '../store/selectors/WorkspaceVariableSelectors';
import { EWorkspaceMeshActionType } from '../store/actions/WorkspaceMeshActionType';
import { IDrawnDataItem } from '../models/IWorkspaceData';
import { IGlobalState } from '../store/reducers';

export const MmgConnectedWorkspaceSelectLists = () => {
  const { hiddenWorkspaceMeshes, selectedWorkspaceMeshes } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer,
  );
  const { hiddenWorkspaceGeometries, selectedWorkspaceGeometries } = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer,
  );
  const { hiddenWorkspaceVariables, selectedWorkspaceVariables } = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer,
  );

  const meshes = useSelector(WorkspaceMeshSelectors.getSortedEnrichedWorkspaceMeshes);
  const geometries = useSelector(WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries);
  const variables = useSelector(WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables);

  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceMeshesByIds,
  );
  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );
  const drawnWorkspaceVariables: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceVariablesByIds,
  );

  const onMeshSelectChanged = useCallback((layerId: string, selected: boolean) => {
    if (selected) {
      // When a new layer is selected, deselect all previous mesh selections.
      store.dispatch({ type: 'workspace/meshes/DESELECT_ALL' });
      store.dispatch({
        type: EWorkspaceMeshActionType.SELECT,
        workspaceMeshId: layerId,
      });
    }
  }, []);

  const onLayerSelectChanged = useCallback(() => {}, []);
  return (
    <>
      <MmgConnectedMeshSelectList
        meshes={meshes}
        hiddenMeshes={hiddenWorkspaceMeshes}
        drawnMeshes={drawnWorkspaceMeshes}
        selectedMeshes={selectedWorkspaceMeshes}
        onLayerSelectChanged={onMeshSelectChanged}
      />
      <MmgConnectedGeometrySelectList
        geometries={geometries}
        hiddenGeometries={hiddenWorkspaceGeometries}
        drawnGeometries={drawnWorkspaceGeometries}
        selectedGeometries={selectedWorkspaceGeometries}
      />
      <MmgConnectedVariableSelectList
        variables={variables}
        hiddenVariables={hiddenWorkspaceVariables}
        drawnVariables={drawnWorkspaceVariables}
        selectedVariables={selectedWorkspaceVariables}
        onLayerSelectChanged={onLayerSelectChanged}
      />
    </>
  );
};
