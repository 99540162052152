/**
 * Exposes reselect-selectors methods for comments.
 *
 * @module WorkspaceCommentSelectors
 * @version 1.0.0
 */

import { createSelector } from 'reselect';
import ModelUtils from '../../managers/model-utils';
import { point, featureCollection } from '@turf/helpers';
import { FeatureCollection } from 'geojson';
import { IGlobalState } from '../reducers';

const workspaceComments = (state: IGlobalState) => state.WorkspaceCommentReducer.comments;

/**
 * Selector to return sorted workspace comments
 */
const getSortedComments = createSelector([workspaceComments], (comments) => {
  if (!comments) {
    return [];
  }

  return ModelUtils.sortComments(comments);
},{
  devModeChecks: {identityFunctionCheck: 'never'}
});

const getCommentsPoints = createSelector([workspaceComments], (comments) => {
  if (!comments) {
    const empty: any[] = [];
    return empty;
  }

  return comments.map(({ coordinate, id, message }) => {
    return point(coordinate, { id, message });
  });
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});
/**
 * Selector to return workspace comments featurecollection
 */
const getCommentsFeatureCollection = createSelector([getCommentsPoints], (points) => {
  if (!points) {
    return {} as FeatureCollection<any, any>;
  }

  return featureCollection(points);
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

const self = {
  getSortedComments,
  getCommentsFeatureCollection,
};

export default self;
