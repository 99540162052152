import { useMemo } from 'react';
import { IWorkspaceQuery } from '../../../models/IQueries';

/**
 * group the queries by their target id for easy lookup
 * @param queries
 */
export const useGroupQueryIdsByTargetIdMemo = (queries: Array<IWorkspaceQuery>) => {
  const groupedQueryIds = useMemo(
    () => {
      if (!queries || queries.length === 0) {
        return {};
      }

      const grouped = queries.reduce(
        (acc, cur) => {
          const targetId = cur.queryDefinition.targetItems[0]; // currently we allow only one inputitem per query
          const queryId = cur.id;

          return {
            ...acc,
            [targetId]: [...(acc[targetId] || []), queryId],
          };
        },
        {} as { [targetId: string]: Array<string> },
      );

      return grouped;
    },
    [queries],
  );

  return groupedQueryIds;
};
