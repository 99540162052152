/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Clear';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

const titleBarStyle = css`
  display: flex;
  justify-content: space-between; 
  align-items: flex-start;
`

const onlyIconBarStyle = css`
  display: flex;
  justify-content: flex-end; 
`

const titleHeadingsStyle = css`
  padding-top: ${mikeSharedTheme.spacing(2)};   
  padding-bottom: ${mikeSharedTheme.spacing(4)};  
`
const closeButtonStyle = css`
  padding: 0;
  color: ${mikeSharedTheme.palette.primary.main}
`
interface IProps { 
  title?: string;
  onClose: () => void;
}

const MikeDialogTitle = (props: IProps) => {
  
  const { title, onClose } = props;

  return (
    <div
      css={
        title
          ? titleBarStyle
          : onlyIconBarStyle
      }
    >
      {title ? (
        <div css={titleHeadingsStyle}>
          <Typography variant="h3">{title}</Typography>
        </div>
      ) : null}
      <div>
        <IconButton
          aria-label="Close"
          onClick={onClose}
          css={closeButtonStyle}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default MikeDialogTitle;
