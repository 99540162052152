import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { t } from '../../translations/i18n';
import MikeButton from '../../shared-components/mike-button';


type ConfirmVariableMultipleDeleteDialogProps = {
  open: boolean;
  onClose: (confirmed: boolean) => void;
};

/**
 * Shows a confimation dialog for deleting multiple variables and notifies a parent component of deletion through a callback.
 *
 * @param props
 */
export const MmgConfirmVariableMultipleDeleteDialog = (props: ConfirmVariableMultipleDeleteDialogProps) => {
  const { open, onClose } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleOk = () => {
    closeDialog(true);
  };

  const handleCancel = () => {
    closeDialog(false);
  };

  const closeDialog = (confirmed: boolean) => {
    onClose(confirmed);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      id="confirm-delete-variable"
      aria-labelledby="delete-variable-dialog-title"
      aria-describedby="delete-variable-dialog-description"
    >
      <DialogTitle id="delete-variable-dialog-title">
        {t('VARIABLE_CONFIRM_DELETE_TITLE', 2)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-variable-dialog-description">
          {t('VARIABLE_CONFIRM_DELETE_TEXT', 2)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MikeButton onClick={handleCancel} variant="outlined" color="secondary">
          {t('CANCEL')}
        </MikeButton>
        <MikeButton onClick={handleOk} variant="contained" color="secondary" autoFocus>
          {t('DELETE')}
        </MikeButton>
      </DialogActions>
    </Dialog>
  );
};
