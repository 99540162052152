import { uniq, sortBy } from 'lodash-es';
import { t } from '../translations/i18n';
import { toLocaleRoundedString } from '../translations/utils';
import { IDrawnDataGradientSettings, IElementDataArray } from '../MikeVisualizer/lib/IMikeVisualizerModels';
import { IAnnotatedValue } from '../models/IWorkspaceData';
import { DEFAULTS } from '../MikeVisualizer/lib/MikeVisualizerConfiguration';
import MikeVisualizerAnnotations from '../MikeVisualizer/lib/MikeVisualizerAnnotations';
import { ATTRIBUTE_SETTINGS } from '../models/IWorkspaceAttributeSettings';

const { getDataRangeColors, getUniqueValuesColors } = MikeVisualizerAnnotations;

interface IAnnotation {
  value: number;
  annotation: string;
}

/**
 * Gets values to be annotated for an given attribute of an element.
 *
 * @param elementId The element.
 * @param gradientDataArray
 * @param gradientSettings
 *
 * @public
 */
export const getAnnotatedValues = (
  elementId: string,
  gradientDataArray: IElementDataArray,
  gradientSettings: IDrawnDataGradientSettings,
): Array<IAnnotatedValue> => {
  if (!elementId || !gradientDataArray) {
    return [];
  }

  const attributeName = gradientDataArray.id;
  if (!attributeName) {
    return [];
  }

  const { gradientColorMap } = gradientSettings || DEFAULTS.gradientSettings;

  if (gradientColorMap) {
    return self.getSelectAnnotatedValues();
  }

  const attributeSetting = ATTRIBUTE_SETTINGS.find((a) => a.name === attributeName);

  const isCategorical = attributeSetting ? attributeSetting.isCategorical : false;

  if (isCategorical) {
    return self.getCategoricalAnnotatedValues(elementId, attributeName);
  }

  const { range } = gradientDataArray;
  return self.getDataRangeAnnotatedValues(elementId, attributeName, range ? range : Array<number>(), gradientSettings);
};

/**
 * Will create legends for selctions.
 * Currently no legends are returned
 */
const getSelectAnnotatedValues = (): Array<IAnnotatedValue> => {
  return [];
};

/**
 * Will create annotations for categorical values for a given attribute such as elementtypes, regions etc.
 * An annotation will be created for each unique value in the data.
 *
 * @param elementId
 * @param attributeName
 */
const getCategoricalAnnotatedValues = (elementId: string, attributeName: string): Array<IAnnotatedValue> => {
  if (!elementId || !attributeName) {
    return [];
  }

  const valueColors = getUniqueValuesColors(elementId, attributeName);

  if (valueColors && valueColors.length) {
    const annotations = valueColors.map(({ value, rgba }) => ({
      value,
      rgba,
      annotation: getCategoryDisplayName(attributeName, value),
    }));

    return sortBy(annotations, 'value');
  }

  return [];
};

/**
 * Will create annotated values for the data range of the attribute.
 * Annotations will be rounded according to gradientSettings
 *
 * @param elementId
 * @param attributeName
 * @param range An array holding the min and max value
 * @param gradientSettings Specifies the number of annotated values and the rounding of numbers
 */
const getDataRangeAnnotatedValues = (
  elementId: string,
  attributeName: string,
  range: Array<number>,
  gradientSettings: IDrawnDataGradientSettings,
): Array<IAnnotatedValue> => {
  if (!elementId || !attributeName || !range) {
    return [];
  }

  const { numberOfLegends, numberOfSignificantDigits } = gradientSettings || DEFAULTS.gradientSettings;

  const maxAnnotationTextLength = 7;

  const valueColors = getDataRangeColors(elementId, attributeName, range, numberOfLegends);

  if (valueColors && valueColors.length) {
    const annotations = valueColors.map(({ value, rgba }) => ({
      value,
      rgba,
      annotation: toLocaleRoundedString(value, numberOfSignificantDigits as any, maxAnnotationTextLength as any),
    }));

    return annotations;
  }

  return [];
};

/**
 * Will create display names (value and annotation) for categorical values for a given attribute such as elementtypes, regions etc.
 * A display name  will be created for each unique value in the data.
 *
 * @param data Contains the data
 * @param attributeName The name of attribute
 */
export const getCategoricalNames = (data: Array<any>, attributeName: string): Array<IAnnotation> => {
  const categories = uniq(data);
  const annotations = categories.map((value) => ({
    value,
    annotation: getCategoryDisplayName(attributeName, value),
  }));

  return sortBy(annotations, 'value');
};

/**
 * Gets the displayname of a categorcal value
 *
 * @param attributeName The name of the categorical attribute
 * @param value The value of the categorical attribute
 */
export const getCategoryDisplayName = (attributeName: string, value): string => {
  const upperName = attributeName.toUpperCase();
  const valuestring = value.toString();

  let key = `PROP_CATEGORY_${upperName}_${valuestring}`;

  let display = t(key);

  if (display === key) {
    key = `PROP_CATEGORY_${upperName}`;
    display = t(key, 1, { value: valuestring });
  }

  if (display === key) {
    display = value;
  }

  return display;
};

const self = {
  getAnnotatedValues,
  getCategoricalAnnotatedValues,
  getDataRangeAnnotatedValues,
  getSelectAnnotatedValues,
};

export default self;
