/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PANEL_HEADER_SIZE } from '../panels/panel-styles';
import { PanelStepperZindex } from '../styles/styleConsts';
import mikeSharedTheme from '../styles/mikeSharedTheme';

const STEPPER_INTERPOLATION_OFFSET_TOP = PANEL_HEADER_SIZE; // Steppers for now are always used with a full-height panel header. The resulting (not defined) size of the header is 72px. If this becomes more complex in the future, it is worth considering a sticky height property or finding other css solutions to achieve the tab stickiness :)
const STEPPER_EXTRACTION_OFFSET_TOP = '0'; // like the above tab offset, but for extract panel

export const stepperBaseStyle = css`
  background: ${mikeSharedTheme.palette.background.paper};
  border-bottom: 1px solid ${mikeSharedTheme.palette.mediumGrey.main};
  z-index: ${PanelStepperZindex};
`;

export const stepperStickyPanelStyle = css`
  ${stepperBaseStyle};
  position: sticky;
  top: ${STEPPER_INTERPOLATION_OFFSET_TOP};
`;

export const stepperExtractionStyle = css`
  ${stepperBaseStyle};
  position: sticky;
  top: ${STEPPER_EXTRACTION_OFFSET_TOP};
  padding: 10px 15px 10px 15px !important;
`;
