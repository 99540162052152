import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgConnectedGeometrySelectList } from '../../geometries/select-list/geometry-select-list';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { EWorkspaceVariableActionType } from '../../store/actions/WorkspaceVariableActionType';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { IGlobalState } from '../../store/reducers';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';

/**
 * @name MmgConnectedGeometryOperationSelectGeometriesPanel
 * @summary Allows selecting multiple geometries to transform by vector operations.
 *
 */
export const MmgConnectedGeometryOperationSelectGeometriesPanel = () => {
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate();
  const { hiddenWorkspaceGeometries, selectedWorkspaceGeometries } = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer,
  );
  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );
  const geometries: Array<IWorkspaceEnrichedGeometry> = useSelector(
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries,
  );

  const anyAvailableGeometries = geometries && geometries.length > 0;
  const isSelectionValid = selectedWorkspaceGeometries && selectedWorkspaceGeometries.length > 0;

  const clearSelectedGeometries = () => {
    store.dispatch({ type: 'workspace/geometries/DESELECT_ALL' });
  };

  const onPanelExit = () => {
    clearSelectedGeometries();
    navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));    
  };

  const onNext = () => {
    navigate(getRouteByPath(ROUTES.geometriesOperationPanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
  };

  // Make sure only geometries are shown in the viewer when this component is mounting.
  // Whatever geometries are currently shown, will be kept visibile.
  useEffect(() => {
    store.dispatch({
      type: EWorkspaceMeshActionType.HIDE_ALL,
    });
    store.dispatch({
      type: EWorkspaceVariableActionType.HIDE_ALL,
    });
  }, []);

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={t('SELECT_GEOMETRIES_TO_TRANSFORM_PANEL')} onPanelExit={onPanelExit} />
      </MikeStickyPanelHeaderContainer>
      <MikeStickyPanelContent>
        <MmgGroup groupName={t('GEOMETRY', 2)}>
          {anyAvailableGeometries && (
            <MmgConnectedGeometrySelectList
              geometries={geometries}
              hiddenGeometries={hiddenWorkspaceGeometries}
              drawnGeometries={drawnWorkspaceGeometries}
              selectedGeometries={selectedWorkspaceGeometries}
            />
          )}

          {!anyAvailableGeometries && (
            <MmgPanelSubsection>
              <p>{t('NO_GEOM_TIP')}</p>

              <MikeButton variant="outlined" color="secondary" onClick={onPanelExit} fullWidth>
                {t('GOT_IT')}
              </MikeButton>
            </MmgPanelSubsection>
          )}
        </MmgGroup>
      </MikeStickyPanelContent>
      <MikeStickyPanelBottomActions>
        <MikeButton color="secondary" variant="contained" disabled={!isSelectionValid} onClick={onNext} fullWidth>
          {t('GO_TO_GEOMETRY_OPERATION')}
        </MikeButton>
      </MikeStickyPanelBottomActions>
    </MikeStickyPanel>
  );
};
