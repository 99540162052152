/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MmgCardPlaceholders } from '../card/workspace-card-placeholders';
import { IWorkspaceOverview } from '../../models/IWorkspaces';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { IGlobalState } from '../../store/reducers';
import { store } from '../../store';
import MmgWorkspaceOverview from '../workspace-overview';

const WorkspaceRecentsStyle = css`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

/**
 * @name MmgConnectedWorkspaceRecents
 * @summary Lists recent workspaces based on usage.
 *
 */
export const MmgConnectedWorkspaceRecents = () => {
  const { recentWorkspaces, loadingRecentWorkspaces } = useSelector((state: IGlobalState) => state.WorkspaceReducer);
  const projectList = useSelector((state: IGlobalState) => state.ProjectReducer.projectList);
  const filter = useSelector((state: IGlobalState) => state.AppReducer.filter);

  useEffect(() => {
    store.dispatch({ type: WorkspaceActionType.GET_RECENTS });
  }, []);

  const filteredWorkspaces = useMemo(
    () => {
      if (filter) {
        return recentWorkspaces.filter((w: IWorkspaceOverview) => w.name && w.name.includes(filter));
      } else {
        return recentWorkspaces;
      }
    },
    [filter, recentWorkspaces],
  );

  return (
    <div css={WorkspaceRecentsStyle}>
      {loadingRecentWorkspaces ? (
        <MmgCardPlaceholders size={5} excludeWrapper={true} />
      ) : (
        filteredWorkspaces &&
        filteredWorkspaces.map((w, index) => (
          <MmgWorkspaceOverview key={index} workspaceId={w.id} projectList={projectList} />
        ))
      )}
    </div>
  );
};
