import React from 'react';

import Hole from '../icons/Hole.svg?react';
import Point from '../icons/Point.svg?react';
import Polygon from '../icons/Polygon.svg?react';
import Polyline from '../icons/Polyline.svg?react';
import PolygonHole from '../icons/PolygonHole.svg?react';
import Mystery from '../icons/Mystery.svg?react';
import { ITEM_TYPES } from '../models/IWorkspaceData';
import { IWorkspaceEnrichedGeometry } from '../models/IGeometries';

// eslint-disable-next-line react-refresh/only-export-components
const HolePostFix = '_' + ITEM_TYPES.HOLE;

const getIcon = (item: IWorkspaceEnrichedGeometry) => {
  const iconName = getIconName(item);
  return getIconByName(iconName as any);
};

const getIconName = (item: IWorkspaceEnrichedGeometry) => {
  if ('isHole' in item) {
    return item.isHole ? item.itemType + HolePostFix : item.itemType;
  }

  return item.itemType;
};

const getIconByName = (iconName: string): React.ReactNode => {
  switch (iconName) {
    case ITEM_TYPES.HOLE:
      return <Hole />;

    case ITEM_TYPES.POINT:
    case ITEM_TYPES.MULTI_POINT:
      return <Point />;

    case ITEM_TYPES.POLYGON:
    case ITEM_TYPES.MULTI_POLYGON: {
      return <Polygon />;
    }

    case ITEM_TYPES.POLYGON + HolePostFix:
    case ITEM_TYPES.MULTI_POLYGON + HolePostFix:
      return <PolygonHole />;

    case ITEM_TYPES.LINE_STRING:
    case ITEM_TYPES.MULTI_LINE_STRING:
      return <Polyline />;

    default: {
      // Use general Hole icon as fallback for geometry types marked as holes
      if (iconName && iconName.endsWith(ITEM_TYPES.HOLE)) {
        return <Hole />;
      }

      console.debug(`No icon found for ${iconName} `);
      return <Mystery />;
    }
  }
};

const self = {
  getIcon,
  getIconName,
  getIconByName,
};

export default self;
