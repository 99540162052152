/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { orderBy } from 'lodash-es';
import { IOperationConfiguration } from '../models/IOperations';
import { IParameterConditionDescription } from '../models/IOperationDescriptions';
import { t } from '../translations/i18n';
import { pickBy } from 'lodash-es';
import {
  getParamShortName,
  getParamUnitString,
  getParameterDescription,
  getParamValueString,
} from '../shared/parameters/parameter-utils';
import { isParameterConditionOk, getParameterConditions } from '../shared/parameters/parameter-condition-utils';
import { IWorkspaceEnrichedGeometry } from '../models/IGeometries';
import { IWorkspaceEnrichedVariable } from '../models/IVariables';
import { IWorkspaceEnrichedMesh } from '../models/IMeshes';
import WorkspaceOperationUtils from '../store/selectors/WorkspaceOperationUtils';
import { IWorkspaceQuery } from '../models/IQueries';
import { getQueryConfigurationSummaryItems } from '../queries/attribute-queries/query-configuration-summary-utils';
import { IWorkspaceAttribute } from '../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../models/IWorkspaceAttributeSettings';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import NIL_UUID from '../shared/mesh-uuid';
const { getOperationName } = WorkspaceOperationUtils; // todo hevo move this to opeation util in current folder

type OperationConfigurationSummaryProps = {
  operationConfiguration: IOperationConfiguration;
  showOperationName?: boolean;
  operationLabel?: string;
  customValueStrings?: { [parameterKey: string]: string };
  parameterConditionDescriptions?: {
    [parameterKey: string]: IParameterConditionDescription;
  };
  showInputItems?: boolean;
  inputItemsLabel?: string;
  inputItems?: Array<
    IWorkspaceEnrichedGeometry | IWorkspaceEnrichedVariable | IWorkspaceEnrichedMesh | IWorkspaceQuery
  >;
  attributes?: Array<IWorkspaceAttribute>;
  attributeSettings?: Array<IWorkspaceAttributeSettings>;
  persitedQueries?: Array<IWorkspaceQuery>;
};

export const ConfigurationParameterStyle = css`
  padding-top: ${mikeSharedTheme.spacing(0.5)};
`;

export const ConfigurationParameterContainerStyle = css`
  margin-bottom: ${mikeSharedTheme.spacing(2)};
`;

/**
 * @name MmgOperationConfigurationSummary
 * @param props
 * @summary Show a summary of a generic operation. Does not consider any child operations
 *
 */
export const MmgOperationConfigurationSummary = (props: OperationConfigurationSummaryProps) => {
  // TODO dan: this component can be optimized, there are redundant calls to getParameterDescription without changes. Perhaps a chance to optimize with useMemo.
  // Would not venture into refactoring without some tests in place :)
  const {
    operationConfiguration,
    showOperationName,
    operationLabel,
    customValueStrings,
    parameterConditionDescriptions,
    showInputItems,
    inputItems,
    inputItemsLabel,
    attributes,
    attributeSettings,
    persitedQueries,
  } = props;

  const { operationType, type, parameters, queryDefinition } = operationConfiguration || {};

  const { parameterDescriptions } = operationConfiguration || {};

  const parameterConditions = parameterConditionDescriptions
    ? getParameterConditions(parameters, parameterConditionDescriptions)
    : null;

  const getOperationNameOrNone = () => {
    const NONE = '-';

    if (!operationType) {
      return NONE;
    }

    const operationName = getOperationName(operationConfiguration) || NONE;
    return operationName;
  };

  const getUnitString = (param: string) => {
    const parameterDescription = getParameterDescription(param, parameterDescriptions);

    return getParamUnitString(parameterDescription);
  };

  const getValueString = (param: string) => {
    const customValueString = (customValueStrings || {})[param];
    if (customValueString) {
      return customValueString;
    }

    const value = parameters[param];
    const parameterDescription = getParameterDescription(param, parameterDescriptions);

    return getParamValueString(value, parameterDescription);
  };

  // We only show values not being 0, false or empty uuid are considered to be default values. Conditional parameters are left out if condition is not met.
  // Parameter values that are not present in parameter descriptions are also discarded, because they are considered 'private' parameters.
  // todo hevo it could be considered to only show non-default values bases on parameterDescriptions, but that will leave out minAngle and rows/cols
  const filteredParameters = useMemo(
    () => {
      const parameterDescriptionKeys = Object.keys(parameterDescriptions || {});
      const filteredParameterValues = pickBy(parameters, (value, key) => {
        const conditionOk = !parameterConditions || isParameterConditionOk(key, parameterConditions);
        const notPrivate = parameterDescriptionKeys.indexOf(key) !== -1;

        return conditionOk && notPrivate && value && value !== NIL_UUID;
      });

      return filteredParameterValues;
    },
    [parameterDescriptions, parameters, parameterConditions],
  );

  // here we use keys to get the translation but we have to keep in mind also the new NameKey Attribute
  const filteredParameterKeys = Object.keys(filteredParameters);
  const hasParameters = filteredParameterKeys && filteredParameterKeys.length > 0;

  const hasInputItems = inputItems && inputItems.length > 0;

  const querySummaryItems = getQueryConfigurationSummaryItems(
    queryDefinition,
    attributes,
    attributeSettings,
    persitedQueries,
  );

  // nothing to render
  if (
    !hasParameters &&
    !showOperationName &&
    !showInputItems &&
    (!querySummaryItems || querySummaryItems.length === 0)
  ) {
    return null;
  }

  // Order parameter values by the order of parameters descriptions.
  const orderedParameters = hasParameters
    ? orderBy(
        filteredParameterKeys.map((key) => {
          const parameterDescription = getParameterDescription(key, parameterDescriptions) || {
            order: 0,
            nameKey: null,
          };

          return {
            key,
            name: getParamShortName(key, parameterDescription.nameKey),
            valueString: getValueString(key),
            unit: getUnitString(key),
            order: parameterDescription.order,
          };
        }),
        ['order'],
      )
    : [];

  if (!operationConfiguration) {
    return <></>;
  }

  return (
    <ul>
      {showOperationName && (
        <li key={type}>
          {operationLabel || t('OPERATION')}: {getOperationNameOrNone()}
        </li>
      )}

      {showInputItems &&
        (hasInputItems ? (
          inputItems.map((dataItem) => (
            <li key={dataItem.id} css={ConfigurationParameterStyle}>
              {inputItemsLabel || t('OPERATION_INPUT', inputItems.length)}:{dataItem.name}
            </li>
          ))
        ) : (
          <li key={'input-id-none'} css={ConfigurationParameterStyle}>
            {inputItemsLabel || t('OPERATION_INPUT', 0)}:{'-'}
          </li>
        ))}
      {querySummaryItems &&
        querySummaryItems.map(({ itemKey, itemDisplayName, itemValueString }) => (
          <li key={itemKey}>
            {itemDisplayName}: {itemValueString}
          </li>
        ))}

      {hasParameters &&
        orderedParameters.map(({ key, name, valueString, unit }) => (
          <li key={key} css={ConfigurationParameterStyle}>
            {name}: {valueString as any}
            {unit}
          </li>
        ))}
    </ul>
  );
};
