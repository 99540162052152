/**
 * Exposes project REST endpoints to managers.
 * Note: the mesh Front-end API proxies these requests to WDP (metadata service).
 *
 * @module ProjectContentProxy
 * @version 2.0.0
 * @requires Proxy
 */
import { endpoints, paths } from './config';
import Proxy from './Proxy';

const { project, subprojects, dataset, path } = paths;
const { meshAppService } = endpoints;

/**
 * Get subprojects.
 *  @param projectId
 */
export const getSubProjects = async (projectId: string) =>
  await Proxy.http.get(`${meshAppService}/${project}/${projectId}/${subprojects}`);

/**
 * Get datasets.
 *
 * @param projectId
 */
export const getDatasets = async (projectId: string) =>
  await Proxy.http.get(`${endpoints.meshAppService}/${project}/${projectId}/${dataset}`);

/**
 * Get path.
 *
 * @param projectId
 */
export const getPath = async (projectId: string) =>
  await Proxy.http.get(`${endpoints.meshAppService}/${project}/${projectId}/${path}`);

/**
 * Get a project.
 *
 * @param projectId
 */
export const getProject = async (projectId: string) =>
  await Proxy.http.get(`${meshAppService}/${project}/${projectId}`);
