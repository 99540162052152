export const workspacePanelPath = '/details';
export const workspaceExportPanelPath = '/export';
export const workspaceOperationPanelPath = '/operation';
export const workspaceEditPanelPath = '/panel/edit';

const WorkspaceRoutes = {
  workspacePanel: {
    path: workspacePanelPath,
  },

  workspaceExportPanel: {
    path: workspaceExportPanelPath,
  },
  workspaceOperationPanel: {
    path: workspaceOperationPanelPath
  },

  workspaceEditPanel: {
    // NB: ${base}/edit is already in use by the standalone edit route (see app.routes.ts).
    path: workspaceEditPanelPath,
  },
};

export default WorkspaceRoutes
