import { Action } from 'redux';
import { Buffer } from 'redux-saga';
import { EElementCategories } from '../../shared/panels/mesh-panel-constants';
import { isEqual } from 'lodash-es';
import { WorkspaceActionType } from '../store/WokspaceActionType';

/**
 * A custom saga buffer, that priorities the order of taking items and attributes to stream from the queue
 *
 * Data will be taken from the queue in the priority order: attributes, geometries, meshes, variables. For same category the most recent created will have higher priority.
 * Loading of attributes are currently prioritized higher as they are assumed not to be loaded untill needed.
 *
 * TODO hevo when featureflag useStreamVtkGeometry becomes ready for production, a change in prioritizaton of attributes might be needed.
 *
 * Important! the final order of streaming items is not guaranteed to be in the priority order described above.
 * The reason is that data are added to the queue in the order notications arrive from backend, and streaming starts as soon as there is something to stream.
 * Therefor the order of the streaming will be affected by the order in which notifications are send from the backend
 *
 * TODO HEVO Later on (when added by api) the size of the data could be included in the prioritazion, taking the smallest first.
 */
export const streamingPriorityBuffer = (): Buffer<Action<any>> => {
  let queue = [];

  /**
   * Compare two items by category, then by created date
   * @param msg1
   * @param msg2
   *
   * @returns 1 if msg1 has highest priority, -1 if msg2 has higest priority, 0 if they have equal priority
   */
  const comparePriority = (msg1, msg2) => {
    const { type: actionType1, category: category1, created: created1 } = msg1;
    const { type: actionType2, category: category2, created: created2 } = msg2;

    // will load attributes first
    let priority = compareActionType(actionType1, actionType2);

    if (priority !== 0) {
      return priority;
    }

    // if not attribute, compare category
    priority = compareCategory(category1, category2);

    if (priority !== 0) {
      return priority;
    }

    // If same category, the most recent created has highest priority (will also be on top in lists due to sort order)
    priority = compareDate(created1, created2);

    return priority;
  };

  /**
   *  @returns the index of the next msg to take from the queue based on priority
   */
  const findNextIndexToTake = () => {
    let nextIndex = 0; // queue.length - 1;

    // nothing to take
    if (queue.length === 0) {
      return -1;
    }

    // There is only one - just take it
    if (queue.length === 1) {
      return nextIndex;
    }

    // take the one with highest priority
    for (let i = 1; i < queue.length; i++) {
      // if compare is negative queue[i] has highest priority and we update next index to take
      if (comparePriority(queue[nextIndex], queue[i]) < 0) {
        nextIndex = i;
      }
    }
    return nextIndex;
  };

  const isEmpty = () => {
    return queue.length === 0;
  };

  // We alwas just add the message to the end of the queue, unless its already there
  const put = (message) => {
    const existingMessage = queue.find(
      ({ type, identity }) => type === message.type && isEqual(identity, message.identity),
    );

    // if no existing message on loading the same data is alredy there, just push
    if (!existingMessage) {
      queue.push(message);
      return;
    }

    const newDate = new Date(message.updated);
    const existingDate = new Date(existingMessage.updated);

    // if updated data of exisiting message is more recent we keep the existing one, unless one shold exclude dataArrays and the othe rnot
    if (existingDate > newDate && existingMessage.excludeDataArrays === message.excludeDataArrays) {
      return;
    }

    // No need to process the old message on the same item or attribute, so replace with the new message
    queue = queue.filter(({ identity }) => !isEqual(identity, message.identity));
    queue.push(message);
  };

  // we take the message that has highest priority
  const take = (): any | undefined => {
    const index = findNextIndexToTake();
    const msg = queue[index];

    // remove from queue
    queue = queue.slice(0, index).concat(queue.slice(index + 1, queue.length));

    return msg;
  };

  const flush = (): any[] => {
    const items = [...queue];
    queue = [];
    return items;
  };

  return { isEmpty, put, take, flush };
};

/**
 * Compare element categories. The order of the priority is geometry, mesh, variable.
 * @param category1
 * @param category2
 *
 * @returns 1 if category1 has highest priority, -1 if category2 has higest priority, 0 if they are equal
 */
const compareCategory = (category1: EElementCategories, category2: EElementCategories) => {
  if (category1 === category2) {
    return 0;
  }

  // If none is geometry or mesh but any of them is variable, that defines the priority
  if (category1 === EElementCategories.VARIABLE) {
    return 1;
  }

  if (category2 === EElementCategories.VARIABLE) {
    return -1;
  }

  // If any of them is geometry, that defines the priority
  if (category1 === EElementCategories.GEOMETRY) {
    return 1;
  }

  if (category2 === EElementCategories.GEOMETRY) {
    return -1;
  }

  // If none is geometry but any of them is mesh, that defines the priority
  if (category1 === EElementCategories.MESH) {
    return 1;
  }

  if (category2 === EElementCategories.MESH) {
    return -1;
  }

  return 0;
};

/**
 * Compares date strings. The most recent has highest priority.
 * @param date1
 * @param date2
 *
 * @returns 1 if date1 is most recent, -1 if date2 is most recent, 0 if they are equal
 */
const compareDate = (date1: string, date2: string) => {
  return date1 === date2 ? 0 : new Date(date1) > new Date(date2) ? 1 : -1;
};

/**
 * Compares action types. Loading of attributes are currently prioritized higher as they are assumed not to be loaded untill needed.
 * @param actionType1
 * @param actionType2
 *
 * @returns 1 if actionType1 is LOAD_DATA_ATTRIBUTE, -1 if actionType2 is LOAD_DATA_ATTRIBUTE, 0 otherwise
 */
const compareActionType = (
  actionType1: WorkspaceActionType.LOAD_DATA | WorkspaceActionType.LOAD_DATA_ATTRIBUTE,
  actionType2: WorkspaceActionType.LOAD_DATA | WorkspaceActionType.LOAD_DATA_ATTRIBUTE,
) => {
  // loading attributes gets highest priority. If they are both of type LOAD_DATA_ATTRIBUTE we take the first.
  if (actionType1 === WorkspaceActionType.LOAD_DATA_ATTRIBUTE) {
    return 1;
  }

  if (actionType2 === WorkspaceActionType.LOAD_DATA_ATTRIBUTE) {
    return -1;
  }

  return 0;
};
