/** @jsxImportSource @emotion/react */
import Settings from '../icons/Settings.svg?react';
import MainPanel from '../icons/MainPanelAlt.svg?react';
import Download from '../icons/Download.svg?react';
import Comment from '../icons/Comment.svg?react';
import { SIDEPANEL_NAVIGATION_GROUPS } from '../workspaces/workspace-utils';
import { getRouteByPath } from '../app/routes';
import { useNavigate, useParams } from 'react-router';
import MikeSidePanelNavigation, { IMikeSidePanelNavigationProps } from '../shared-components/mike-sidepanel-navigation/MikeSidePanelNavigation';
import MikeSidePanelNavigationItem from '../shared-components/mike-sidepanel-navigationitem';
import { workspacePath } from '../app/app.routes';
import { workspaceEditPanelPath, workspaceExportPanelPath, workspacePanelPath } from './workspace.routes';
import { commentsPanelPath } from '../comments/comment.routes';

/**
 * Wraps the shared sidepanel navigation component and sets up the navigation for workspace sidepanels. Will pass on any props.
 *
 * @param props
 */

const MmgWorkspaceSidePanelNavigation = (props: IMikeSidePanelNavigationProps) => {
  const { value } = props;
  const { projectId, workspaceId } = useParams();
  const navigate = useNavigate();

  /**
   * Navigates to the panel correspoiding to the routePath.
   * @param newPath
   */
  const navigateToPanel = (newPath: string) => {
    const path = getRouteByPath(newPath, { workspaceId, projectId }, newPath && newPath.startsWith(workspacePath) ? "" : workspacePath);   
    navigate(path); 
  };

  /**
   * Will create a navigation item. Navigation to the panel defined by routePath will only happen if the navGroup has changed
   * @param navigationGroup
   * @param icon
   * @param routePath
   */
  const getNavigationItem = (navigationGroup, icon: React.ReactElement, rp: string) => {
    return (
      <MikeSidePanelNavigationItem      
        value={navigationGroup}
        key={navigationGroup}
        icon={icon}
        onItemClicked={() => navigateToPanel(rp)}
      />
    );
  };

  return (
    <MikeSidePanelNavigation {...props}>
      {value && [
        getNavigationItem(SIDEPANEL_NAVIGATION_GROUPS.SETTINGS, <Settings />, workspacePath + workspaceEditPanelPath),

        getNavigationItem(SIDEPANEL_NAVIGATION_GROUPS.MAIN_PANEL, <MainPanel />, workspacePath + workspacePanelPath),

        getNavigationItem(SIDEPANEL_NAVIGATION_GROUPS.COMMENTS, <Comment />, workspacePath + commentsPanelPath),

        getNavigationItem(SIDEPANEL_NAVIGATION_GROUPS.DOWNLOAD, <Download />, workspacePath + workspaceExportPanelPath),
      ]}
    </MikeSidePanelNavigation>
  );
};

export default MmgWorkspaceSidePanelNavigation;
