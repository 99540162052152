/**
 * @namespace Store
 * @version 2.0.0
 */
import appReducers from '../store/reducers';
import { applyMiddleware, compose } from 'redux';
import { legacy_createStore as createStore} from 'redux'
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';

//todo hevo: consider better error handling for unexpected errors
const sagaMiddleware = createSagaMiddleware({
  onError: (err) => console.error(err),
});

const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


// const middlewares = [sagaMiddleware];
// const middlewareEnhancer = applyMiddleware(...middlewares);
// const enhancers = [middlewareEnhancer];
const initialState = {};

/* export const store = createStore(
  appReducers,
  initialState,
  enhancers as any,
); */

export const store = createStore(appReducers, 
  composeEnhancer(
  applyMiddleware(  
  sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export const preloadedState = initialState;
