/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactNode } from 'react';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { getPadding, IColumn } from './support';
import { IAction } from './Actions';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import ContextHelp from '../../shared/edit-modes/ContextHelp';

const tableHeaderStyle = css`
  z-index: 1;
`;

const noBorderBottomStyle = css`
  border-bottom: none;
`;

const actionButtonStyle = css`
  width 40px; 
  padding-right: ${mikeSharedTheme.spacing(4)};
`;

const stickyHeaderStyle = (topOffset: number) => {
  return css`
    position: sticky;
    top: ${topOffset}px;
    z-index: 1;
    white-space: nowrap;
  `
};

const headerStyle = (topOffset: number, noBottomBorder: boolean) => {
  if (noBottomBorder){
    return css`
      ${stickyHeaderStyle(topOffset)};
      ${noBorderBottomStyle};
    `
  }
  else{
    return css`
      ${stickyHeaderStyle(topOffset)};
    `
  }
};

const stickyActionHeaderStyle = (topOffset: number) => {
  return css`
    position: sticky;
    top: ${topOffset}px;
    z-index: 1;
    width: 40px;
  `
};

const actionHeaderStyle = (topOffset: number, noBottomBorder: boolean) => {
  if (noBottomBorder){
    return css`
      ${actionButtonStyle}
      ${stickyActionHeaderStyle(topOffset)}
      ${noBorderBottomStyle}
    `
  }
  else{
    return css`
      ${actionButtonStyle}
      ${stickyActionHeaderStyle(topOffset)}
    `
  }
};

interface IProps {
  actions?: Array<IAction>;
  columns: Array<IColumn>;
  firstHeaderCell?: ReactNode;
  noBottomBorder?: boolean;
  notSortableColumns?: Array<any>;
  onRequestSort: (
    orderBy: string | ((item: any) => string | number),
    order: 'asc' | 'desc'
  ) => void;
  sortDirection: 'asc' | 'desc' | undefined;
  sortBy: string | number | ((item?: any) => string | number);
  topOffset: number;
  selectable?: boolean;
}

const EnhancedTableHead = (props: IProps) => { 
  const {
    actions,
    columns,
    firstHeaderCell,
    noBottomBorder = false,
    notSortableColumns = [],
    onRequestSort,
    sortDirection,
    sortBy,
    selectable,
    topOffset
  } = props;

  const createSortHandler = (col: IColumn) => () => {
    onRequestSort(
      col.comparator ? col.comparator : col.field,
      sortDirection === 'desc' ? 'asc' : 'desc'
    );
  };

  return (
    <TableHead component="thead" css={tableHeaderStyle}>
      <TableRow css={tableHeaderStyle}>
        {selectable && 
          <TableCell
            component="th"
            padding="checkbox"
            css={headerStyle(topOffset, noBottomBorder)}
          >
            {firstHeaderCell && firstHeaderCell}
          </TableCell>
        }
        {columns.map((col: IColumn) => {
          return (
            <TableCell
              css={headerStyle(topOffset, noBottomBorder)}
              key={col.field}
              align={col.numeric ? 'right' : 'left'}
              padding={getPadding(col)}
              component="th"
              sortDirection={sortDirection}
            >
              {!notSortableColumns.includes(col.field) ? (
                <TableSortLabel
                  active={col.comparator ? sortBy === col.comparator : sortBy === col.field}
                  direction={sortDirection}
                  onClick={createSortHandler(col)}
                >
                  {col.label}
                </TableSortLabel>
              ) : (
                col.label
              )}
              {col.info && <ContextHelp primary helpTexts={col.info}/>}
            </TableCell>
          );
        })}
        {actions ? (
          <TableCell
            css={actionHeaderStyle(topOffset, noBottomBorder)}
            align="center"
          />
        ) : null}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
