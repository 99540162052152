import { t } from '../../translations/i18n';

/**
 * returns a prefixed name for the projection id.
 * @param projectionId the id of the projection (epsg or DHI code)
 */
export const getProjectionIdName = (projectionId: string) => {
  // todo hevo: Not all projections are epsg. we should prefix differently.
  return `${t('EPSG')}:${projectionId}`;
};

const self = {
  getProjectionIdName,
};

export default self;
