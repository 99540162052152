import { IOpenDataProvider } from '../../models/IOpenDataProvidersApi';
import { WorkspaceActionType } from '../../workspaces/store/WokspaceActionType';

export interface IWorkspaceOpenDataState {
  providers: Array<IOpenDataProvider>;
  providersLoading: boolean;
}

const initialState: IWorkspaceOpenDataState = {
  providers: [],
  providersLoading: false,
};

/**
 * Workspace Open data Reducer.
 * - returns new states for matched workspace actions.
 *
 * @name WorkspaceOpenDataReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IWorkspaceOpenDataState = initialState, action): IWorkspaceOpenDataState {
  switch (action.type) {
    case WorkspaceActionType.OPENDATA_PROVIDERS_SET: {
      return {
        ...state,
        providers: action.providers,
      };
    }

    case WorkspaceActionType.OPENDATA_PROVIDERS_LOADING: {
      return {
        ...state,
        providersLoading: action.providersLoading,
      };
    }

    default:
      return state;
  }
}
