/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';

const SidepanelContainerStyle = css`
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  padding: ${mikeSharedTheme.spacing(2)};
`;

const SidepanelDescriptionStyle = css`
  font-size: 0.75rem; /* 12px */
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  line-height: 1rem;
`;

const SidepanelTitleStyle = css`
  font-size: 1.25rem; /* 20px */
  line-height: 1.5rem;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  margin: 0;
`;

type SidepanelTitleProps = {
  sidepanelTitle: string;
  sidepanelDescription?: string;
};

/**
 * @name MmgSidepanelTitle
 * @summary Sidepanels can have one optional title and description at the top, for example the name of the current page (workspace, project, etc).
 * Usually, a sidepanel title goes under a sidepanel header.
 *
 * @param props
 */
export function MmgSidepanelTitle(props: SidepanelTitleProps) {
  const { sidepanelTitle, sidepanelDescription } = props;

  return (
    <span css={SidepanelContainerStyle}>
      {sidepanelDescription && <p css={SidepanelDescriptionStyle}>{sidepanelDescription}</p>}
      <h1 css={SidepanelTitleStyle}>{sidepanelTitle}</h1>
    </span>
  );
}
