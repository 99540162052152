/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const LayerTextComplicationStyle = css`
  position: absolute;
  top: 0;
  left: 0.4rem; 
  border: 2px solid transparent;
  display: inline-block;
  box-sizing: border-box;
  background-color: white;
  font-size: 10px;
`;

type LayerTextComplicationProps = {
  text: string;
}

/**
 * @name MikeLayerTextComplication
 * @summary Displays a color icon for a given layer.
 * Meant to be used as a complication on the left side of the layer icon.
 *
 * @param props
 */
export function MikeLayerTextComplication(props: LayerTextComplicationProps) {
  const { text } = props;

  return (
    <span css={LayerTextComplicationStyle}>
      {text}
    </span>
  );
}
