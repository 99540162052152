/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { t } from '../../translations/i18n';
import { store } from '../../store/store';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { CONFIRM_PANEL_IDS } from '../../shared/confirm-containers/confirm-constants';
import { IProject } from '../../models/IProject';
import { useSelector } from 'react-redux';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { useNavigate } from 'react-router-dom';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import { IGlobalState } from '../../store/reducers';
import MikeButton from '../../shared-components/mike-button';

const noItemsContainerStyle = css`
  padding: ${mikeSharedTheme.spacing(2)};
`;

const noItemsButtonsStyle = css`
  display: flex;
  justify-content: space-between;
`;

type WorkspaceNoItemsMessageProps = {
  projectId: string;
  workspaceId: string;
};

/**
 * @name MmgWorkspaceNoItemsMessage
 * @summary A message in case the workspaces has no meshes, geometries or variables.
 *
 * @param props
 */
export function MmgWorkspaceNoItemsMessage(props: WorkspaceNoItemsMessageProps) {
  const navigate = useNavigate();
  const { projectId, workspaceId } = props;

  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);

  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  /**
   * Callback for when the workspace settings button is pressed.
   * Navigates to the workspace edit panel.
   */
  const onWorkspaceSettingsButtonPressed = () => {
    navigate(getRouteByPath(ROUTES.workspaceEditPanel.path, { workspaceId, projectId }, ROUTES.workspace.path));
  };

  /**
   * Callback for when the extract features button is pressed.
   * Activates extract features.
   */
  const onExtractFeaturesButtonPressed = () => {
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.EXTRACT_DATA,
    });
  };

  return (
    <div css={noItemsContainerStyle}>
      <h3>{canUpdateWorkspace ? t('NO_ITEMS_IN_WORKSPACE') : t('NO_ITEMS_IN_THIS_WORKSPACE')}</h3>
      <p>{canUpdateWorkspace ? t('NO_ITEMS_IN_WORKSPACE_TIP') : t('NO_ITEMS_IN_THIS_WORKSPACE_TIP')}</p>

      <span css={noItemsButtonsStyle}>
        <MikeButton disabled={!canUpdateWorkspace} variant="outlined" onClick={onWorkspaceSettingsButtonPressed}>
          {t('ADD_WORKSPACE_DATASETS')}
        </MikeButton>

        {/* TODO dan: this should use the extract tool button (which has to wrap the tool-button component with a toolType="regular-button") */}
        <MikeButton disabled={!canUpdateWorkspace} variant="contained" color="secondary" onClick={onExtractFeaturesButtonPressed}>
          {t('TOOL_EXTRACT_FEATURES_SHORT')}
        </MikeButton>
      </span>
    </div>
  );
}
