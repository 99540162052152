import { ESubmenus } from './viewer-tool-constants';
import { store } from '../../../store/store';
import { groupBy } from 'lodash-es';
import { translateWithPrefix } from '../../../translations/utils';
import { MmgStatistics } from '../../../shared/statistics/statistics';
import { FeatureCollection } from 'geojson';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';

/**
 * Toggles a submenu.
 *
 * @param submenu
 */
const toggleSubmenu = (submenu: ESubmenus) => {
  const { openSubmenu } = store.getState().MapToolReducer;

  if (openSubmenu === submenu) {
    store.dispatch({
      type: EMapToolActionType.CLEAR_OPEN_SUBMENU,
    });
  } else {
    store.dispatch({
      type: EMapToolActionType.SET_OPEN_SUBMENU,
      openSubmenu: submenu,
    });
  }
};

/**
 * Returns a summary of the current drawing or selection.
 *
 * @param featureCollection Items to summarize
 */
const getSummary = (featureCollection: FeatureCollection<any, any>) => {
  if (featureCollection.features && featureCollection.features.length) {
    const geometries = featureCollection.features.map(({ geometry }) => geometry);

    const shapes = groupBy(geometries, 'type');

    const statistics = Object.keys(shapes)
      .filter((key) => key !== 'undefined')
      .map((key) => ({
        id: key,
        name: translateWithPrefix('PROP', key + 'COUNT'),
        value: shapes[key].length,
      }));

    return <MmgStatistics statistics={statistics} />;
  }

  return <></>;
};

const self = {
  toggleSubmenu,
  getSummary,
};

export default self;
