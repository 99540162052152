import { takeLatest, put, call, takeEvery, select } from 'redux-saga/effects';
import { IResponse } from '../../models/IResponse';
import { EProjectContentActionType } from '../../store/actions/ProjectContentActionType';
import * as ProjectContentProxy from '../../proxies/ProjectContentProxy';
import * as WorkspaceProxy from '../../proxies/WorkspaceProxy';
import { HttpErrorHandler } from '../../managers/http-utils';

import {
  setLoadingProjectAndParent,
  setLoadingProjectContent,
  setProjectAndParent,
  setProjectContent,
} from '../../store/actions/projectContent';

import { IGetProjectPath } from '../../models/IGetProjectPath';
import { IGetDataset } from '../../models/IGetDataset';
import { IAction } from '../../store/actions/Action';
import { ROUTES } from '../../app/routes';
import { EProjectActionType } from '../../store/actions/project';
import { getProjectPath } from '../../proxies/ProjectProxy';
import { store } from '../../store';
import BreadcrumbUtils from '../../app/topbar/breadcrumb-utils';
import { getTenantId, getTenantName } from '../../workspaces/sagas/selectors';
import { IItemToReplace } from '../../store/reducers/ProjectContentReducer';
import { IGetProject } from '../../shared-components/mike-project-explorer/model';
import { NavigateFunction } from 'react-router-dom';

export function* watchProject() {
  yield takeLatest(EProjectContentActionType.GET_PROJECTANDPARENT, handleGetProjectAndParent);
  yield takeEvery(EProjectActionType.GET_PROJECT_PATH, getPath);
}

export function* watchProjectContent() {
  yield takeLatest(EProjectContentActionType.GET_PROJECTCONTENT, handleGetProjectContent);
  yield takeEvery(EProjectContentActionType.REPLACE_WITH_PROJECTCONTENT, handleReplaceWithProjectContent);
}

function* handleReplaceWithProjectContent(action: IAction) {
  const { datasetId, workspaceId } = action.data;
  const itemToReplace: IItemToReplace = action.data.itemToReplace;
  try {
    yield call(WorkspaceProxy.updateWorkspaceItem, workspaceId, itemToReplace.elementId, datasetId);
  } catch (error: any) {
    HttpErrorHandler('Failed to replace data source.', error);
  }
}

function* handleGetProjectAndParent(action: IAction) {
  const projectId = action.data.projectId;  
  if (projectId) {
    try {
      yield put(setLoadingProjectAndParent());
      let project = null;
      try {
        const response: IResponse = yield call(ProjectContentProxy.getProject, projectId);
        if ((response.status === 200 || response.status === 201) && response.data) {
          project = response.data;
        }
      } catch {
        const navigate: NavigateFunction = action.data.navigate
        navigate(ROUTES.notFound.path);       
      }

      if (project) {
        let projectParent = null;
        if (project.parentProjectId) {
          try {
            const projectParentResponse: IResponse = yield call(
              ProjectContentProxy.getProject,
              project.parentProjectId,
            );
            if (
              (projectParentResponse.status === 200 || projectParentResponse.status === 201) &&
              projectParentResponse.data
            ) {
              projectParent = projectParentResponse.data;
            }
          } catch (error: any) {
            HttpErrorHandler('Failed to get project parent.', error);
          }
        }

        yield put(setProjectAndParent(project, projectParent));
      } else {
        // yield put(addError("Project with id " + projectId + " is not accessible"));
        yield put(setProjectAndParent(null, null));
      }
    } catch (error: any) {
      HttpErrorHandler('Failed to get project and parent.', error);
    }
  }
}

function* getPath(action) {
  try {
    const { projectId, workspace } = action.data;
    const { data } = yield call(getProjectPath, projectId);

    const tenantId = yield select(getTenantId);
    const tenantName = yield select(getTenantName);

    const filteredData = data.filter((prj) => {
      return prj.id !== tenantId;
    });

    let crumbs = [];
    if (workspace && workspace.name) {
      const workspaceCrumb = workspace && BreadcrumbUtils.getWorkspaceCrumb(workspace.id, projectId, workspace.name);
      crumbs.push(workspaceCrumb);
    }

    crumbs = [
      BreadcrumbUtils.getHomeCrumb(tenantName),
      ...filteredData.map((project) => {
        return BreadcrumbUtils.getProjectCrumb(project.id, project.name || 'project');
      }),
      ...crumbs,
    ];

    store.dispatch({
      type: 'app/breadcrumbs/SET',
      breadcrumbs: crumbs,
    });
  } catch (error) {
    console.log(error);
  }
}

function* handleGetProjectContent(action: IAction) {
  const project = action.data;
  if (project) {
    try {
      yield put(setLoadingProjectContent());
      let projectParent = null;
      if (project.parentProjectId) {
        try {
          const projectParentResponse: IResponse = yield call(ProjectContentProxy.getProject, project.parentProjectId);
          if (
            (projectParentResponse.status === 200 || projectParentResponse.status === 201) &&
            projectParentResponse.data
          ) {
            projectParent = projectParentResponse.data;
          }
        } catch (error: any) {
          HttpErrorHandler('Failed to get parent project.', error);
        }
      }
      let projectPath = Array<IGetProjectPath>();
      try {
        const projectPathResponse: IResponse = yield call(ProjectContentProxy.getPath, project.id);
        if ((projectPathResponse.status === 200 || projectPathResponse.status === 201) && projectPathResponse.data) {
          projectPath = projectPathResponse.data;
        }
      } catch (error: any) {
        HttpErrorHandler('Failed to get project path.', error);
      }

      const itemInPath = projectPath.find((path: IGetProjectPath) => path.id === project.id);
      const capabilities = itemInPath && itemInPath.capabilities ? itemInPath.capabilities : null;
      let folders = [];
      let datasets = [];
      // We should only query the backend for folder's content if it has respective capabilities
      if (capabilities && capabilities.canListContent) {
        try {
          const foldersResponse: IResponse = yield call(ProjectContentProxy.getSubProjects, project.id);
          if ((foldersResponse.status === 200 || foldersResponse.status === 201) && foldersResponse.data) {
            folders = foldersResponse.data;
          }
        } catch (error: any) {
          HttpErrorHandler('Failed to get subprojects.', error);
        }

        try {
          const datasetsResponse: IResponse = yield call(ProjectContentProxy.getDatasets, project.id);
          if ((datasetsResponse.status === 200 || datasetsResponse.status === 201) && datasetsResponse.data) {
            datasets = datasetsResponse.data;
          }
        } catch (error: any) {
          HttpErrorHandler('Failed to get datasets.', error);
        }
      }

      yield put(
        setProjectContent(
          datasets.sort((dataset) => dataset.updatedAt).concat(folders.sort((folder) => folder.updatedAt)),
          project,
          projectParent,
          projectPath,
        ),
      );
    } catch (error: any) {
      HttpErrorHandler('Failed to get project content.', error);
    }
  } else {
    yield put(setProjectContent(Array<IGetDataset | IGetProject>(), null, null, Array<IGetProjectPath>()));
  }
}
