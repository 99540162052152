import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { MmgGroup } from './group';
import { EGroupIds, GROUP_IDS } from './group-constants';
import { IGlobalState } from '../../store/reducers';
import { store } from '../../store';

type GroupProps = {
  groupId: EGroupIds;
  groupTools?: React.ReactNode;
  canBeHidden?: boolean;
  children?: React.ReactNode;
};

/**
 * @name MmgConnectedGroup
 * @summary A generic container that can be collapsed or expanded.
 * It typically holds children that are optionally visible (if expanded).
 * The visibility of the group is handled by the panel reducer.
 *
 * @param props
 */
export const MmgConnectedGroup = (props: GroupProps) => {
  const { groupId, groupTools, canBeHidden = true } = props;
  const collapsedGroups: Array<any> = useSelector((state: IGlobalState) => state.PanelReducer.collapsedGroups);

  const getGroupName = (id: string) => {
    switch (id) {
      case GROUP_IDS.WORKSPACE_DETAILS_MESH:
        return t('MESH', 2);

      case GROUP_IDS.WORKSPACE_DETAILS_GEOMETRY:
        return t('GEOMETRY', 2);

      case GROUP_IDS.WORKSPACE_DETAILS_VARIABLE:
        return t('VARIABLE', 2);

      default:
        return '';
    }
  };

  const toggleOpen = () => {
    store.dispatch({
      type: collapsedGroups.includes(groupId) ? 'panel/SET_GROUP_SHOW' : 'panel/SET_GROUP_COLLAPSE',
      groupId,
    });
  };

  return (
    <MmgGroup
      groupName={getGroupName(groupId)}
      toggleOpenCallback={toggleOpen}
      groupTools={groupTools}
      canBeHidden={canBeHidden}
      children={props.children}
      isOpen={collapsedGroups.includes(groupId) ? false : true}
    />
  );
};
