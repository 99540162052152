/** @jsxImportSource @emotion/react */
import { useEffect, useCallback } from 'react';
import { store } from '../../../store';
import { t } from '../../../translations/i18n';
import { ViewerToolsButtonStyle, ViewerToolsButtonActiveStyle } from './viewer-tools-styles';
import { MmgToolButton } from '../../../shared/tools/tool-button';
import SpatialSelection from '../../../icons/SpatialSelection.svg?react';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';
import { EToolButtonType } from '../../../shared/tools/IToolButton';
import { css } from '@emotion/react';

const {
  enable2DPolygonSelection,
  disable2DPolygonSelection,
  disableAllDrawingTools,
  clearDrawnVectorLayerData,
} = MikeVisualizer;

type ViewerTool2DSpatialSelectionProps = {
  spatialSelectionActive: boolean;
  onSpatialSelectionToggled: () => void;
  toolButtonType?: EToolButtonType;
};

const style = (active: boolean) => {
  if (active){
    return css`
      ${ViewerToolsButtonStyle};
      ${ViewerToolsButtonActiveStyle};
   `
  }
  else{
    return css`
      ${ViewerToolsButtonStyle};     
   `
  }
 
}

/**
 * @name MmgViewerTool2DSpatialSelection
 * @summary Allows drawing a polygon to be used as a spatial selection.
 * This component is meant to be wrapper by i.e. GeometryTool2DSpatialSection and used as a base component.
 *
 * @param props
 */
export function MmgViewerTool2DSpatialSelection(props: ViewerTool2DSpatialSelectionProps) {
  const { spatialSelectionActive, toolButtonType } = props;

  /*
   * Enable closed polygon drawing widget.
   */
  const enableSpatialSelectionTool = useCallback(() => {
    store.dispatch({ type: 'maptools/SET_VIEWER_MODE_TO_2D' });

    disableAllDrawingTools();
    enable2DPolygonSelection(true);
  }, []);

  /*
   * Disable closed polygon drawing widget.
   */
  const disableSpatialSelectionTool = () => {
    disable2DPolygonSelection();
    clearDrawnVectorLayerData();
  };

  /**
   * Toggles spatial selection features tool.
   * Only calls the prop handler without altering the state in this component.
   */
  const toggleSpatialSelectionTool = () => {
    props.onSpatialSelectionToggled();
  };

  useEffect(
    () => {
      if (spatialSelectionActive) {
        enableSpatialSelectionTool();
      } else {
        disableSpatialSelectionTool();
      }
    },
    [spatialSelectionActive, enableSpatialSelectionTool],
  );

  return (
    <MmgToolButton
      toolName={t('TOOL_SPATIAL_SELECTION')}
      toolIcon={<SpatialSelection />}
      toolType={toolButtonType}
      css={toolButtonType === EToolButtonType.TOOL_BUTTON && style(spatialSelectionActive)}
      onClick={toggleSpatialSelectionTool}
    />
  );
}
