/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';

const PanelTitleContainerStyle = css`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  min-width: 0;
  margin: ${mikeSharedTheme.spacing(1)} 0;
`;

const PanelTitleStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PanelDescriptionStyle = css`
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  line-height: 1rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type PanelTitleProps = {
  panelTitle: string;
  panelDescription?: string;
};

/**
 * @name MmgPanelTitle
 * @summary Panels can have one optional title and description at the top, for example the name of the current item (mesh, geometry etc).
 * Usually, a panel title goes under a panel header.
 *
 * @param props
 */
export function MmgPanelTitle(props: PanelTitleProps) {
  const { panelTitle, panelDescription } = props;

  return (
    <div css={PanelTitleContainerStyle}>
      <Typography variant="h3" css={PanelTitleStyle}>
        {panelTitle}
      </Typography>
      {panelDescription && <p css={PanelDescriptionStyle}>{panelDescription}</p>}
    </div>
  );
}
