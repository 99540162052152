/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import MikeButton from '../../shared-components/mike-button';
import { useNavigate } from 'react-router-dom';

const noItemsContainerStyle = css`
  padding: ${mikeSharedTheme.spacing(2)};
`;

const noItemsButtonsStyle = css`
  display: flex;
  justify-content: space-between;
`;

type MeshInterpolationsNotAvailableMessageProps = {
  projectId: string;
  workspaceId: string;
};

/**
 * @name MmgMeshInterpolationsNotAvailableMessage
 * @summary A message in case the workspaces does not support interpolations.
 * This can happen if the workspace has no variables, or if the variables does not support interpolations.
 *
 * @param props
 */
export function MmgMeshInterpolationsNotAvailableMessage(props: MeshInterpolationsNotAvailableMessageProps) {
  const navigate = useNavigate();
  const { projectId, workspaceId } = props;

  /**
   * Callback for when the workspace settings button is pressed.
   * Navigates to the workspace edit panel.
   */
  const onWorkspaceSettingsButtonPressed = () => {
    navigate(getRouteByPath(ROUTES.workspaceEditPanel.path, { workspaceId, projectId }, ROUTES.workspace.path));  

  };

  return (
    <div css={noItemsContainerStyle}>
      <p>{t('MESH_INTERPOLATIONS_NOT_AVAILABLE')}</p>

      <span css={noItemsButtonsStyle}>
        <MikeButton variant="outlined" fullWidth onClick={onWorkspaceSettingsButtonPressed}>
          {t('ADD_WORKSPACE_VARIABLES')}
        </MikeButton>
      </span>
    </div>
  );
}
