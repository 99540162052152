import { TabProps as MuiTabProps } from '@mui/material/Tab';
import MikeTab from '../../shared-components/mike-tab';

export interface IMmgTabProps extends MuiTabProps {
  index: number;
}

const a11yProps = (index: any) => {
  return {
    id: `mmg-tab-${index}`,
    'aria-controls': `mmg-tabpanel-${index}`,
  };
};

export const MmgTab = (props: IMmgTabProps) => {
  const { index, ...other } = props;

  return <MikeTab {...other as any} {...a11yProps(index)} />;
};

MmgTab.muiName = 'MuiTab';
