/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { t } from '../../translations/i18n';

const offlineOverlayStyle = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
`;

type OfflineProps = {
  forceOffline?: boolean;
};

/**
 * @name MmgOfflineOverlay
 * @summary Shows an overlay if network connection is down.
 *
 * Detects network loss automatically or can be 'told' it's offline via a prop.
 * @param props
 */
export const MmgOfflineOverlay = (props: OfflineProps) => {
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOffline(true);
    });
    window.addEventListener('online', () => {
      setOffline(false);
    });

    return () => {
      window.removeEventListener('offline', () => {
        setOffline(true);
      });
      window.removeEventListener('online', () => {
        setOffline(false);
      });
    };
  }, []);

  return offline || props.forceOffline ? (
    <figure css={offlineOverlayStyle}>
      <h2>{t('OFFLINE_TITLE')}</h2>
      <p>{t('OFFLINE_DESC')}</p>
    </figure>
  ) : (
    <div />
  );
};
