import { all, call, put, takeLatest } from 'redux-saga/effects';
import FeatureFlags from '../../app/feature-flags';
import { EElementCategories, ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { IWorkspaceGeometry } from '../../models/IGeometries';
import { IWorkspaceMesh } from '../../models/IMeshes';
import { IWorkspaceVariable } from '../../models/IVariables';
import { IWorkspace } from '../../models/IWorkspaces';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { EWorkspaceGeometryActionType } from '../../store/actions/WorkspaceGeometryActionType';
import * as WorkspaceProxy from '../../proxies/WorkspaceProxy';
import { initViewerModeByUserSettings, setSettings, setUpdatedSettings } from '../../store/actions/settings';
import { ISettingsLayer, ISettingsMap, IUserSettings } from '../../models/IUserSettings';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { EWorkspaceVariableActionType } from '../../store/actions/WorkspaceVariableActionType';
import { EOperationActionType } from '../../store/actions/OperationActionType';
import { RECENT_WORKSPACES_BOUNDS, RECENT_WORKSPACES_KEY } from '../../store/reducers/WorkspaceReducer';

/**
 * Defines sagas for handling the workspace being connected to the Hub
 */
export function* watchWorkspaceConnect() {
  yield takeLatest(WorkspaceActionType.WORKSPACE_CONNECTED, handleWorkspaceConnected);
  yield takeLatest(WorkspaceActionType.GET_RECENTS, handleGetRecentWorkspaces);
}

/**
 * Defines sagas for handling the workspace structure being updated
 */
export function* watchWorkspaceUpdated() {
  yield takeLatest(WorkspaceActionType.STRUCTURE_UPDATED, handleWorkspaceUpdated);
}

function* handleGetRecentWorkspaces() {
  const workspaces = [];
  yield put({ type: WorkspaceActionType.LOADING_RECENTS });
  const recentWorkspaces = JSON.parse(localStorage.getItem(RECENT_WORKSPACES_KEY)) || [];
  for (const recentWorkspace of recentWorkspaces) {
    if (recentWorkspace && recentWorkspace.id) {
      try {
        const { data } = yield call(WorkspaceProxy.doesWorkspaceExist, recentWorkspace.id);
        if (!data) {
          yield put({
            type: WorkspaceActionType.DELETE_RECENT,
            workspaceId: recentWorkspace.id,
          });
        } else {
          workspaces.push(recentWorkspace);
        }
      } catch (error) {
        yield put({
          type: WorkspaceActionType.DELETE_RECENT,
          workspaceId: recentWorkspace.id,
        });
      }
    }
  }
  yield put({ type: WorkspaceActionType.SET_RECENTS, data: workspaces });
}

function* handleWorkspaceConnected(action) {
  const { workspace } = action;
  const { id: workspaceId, geometries, variables, meshes } = workspace as IWorkspace;
  let userSettings: IUserSettings | null = null;

  try {
    const response = yield call(WorkspaceProxy.getWorkspaceUserSettings, workspaceId);
    if (response && response.data) {
      userSettings = response.data;
    }
  } catch (error) {
    console.log(error);
  }

  if (userSettings) {
    // Remove references to deleted layers
    const ids = geometries
      .map(({ id }) => id)
      .concat(meshes.map(({ id }) => id))
      .concat(variables.map(({ id }) => id));
    const existingLayers = userSettings.tableOfContents.layers.filter((layer: ISettingsLayer) =>
      ids.includes(layer.vtkItemId),
    );
    const settings = { ...userSettings, tableOfContents: { ...userSettings.tableOfContents, layers: existingLayers } };
    yield put(setSettings(settings, false));
    yield put(setUpdatedSettings(settings));
    const map: ISettingsMap = settings.map;
    if (map) {
      yield put(initViewerModeByUserSettings(map));
    }
    const layers: ISettingsLayer[] =
      settings.tableOfContents && settings.tableOfContents.layers ? settings.tableOfContents.layers : [];
    if (layers && layers.length > 0) {
      const hiddenItems = layers.filter((l: ISettingsLayer) => l.visible === false);
      const hiddenIds = hiddenItems.map((hl: ISettingsLayer) => hl.vtkItemId);
      if (meshes && meshes.length > 0) {
        const hidden = meshes.filter((data: IWorkspaceMesh) => hiddenIds.includes(data.id));
        const userHiddenIds = hidden.map((data: IWorkspaceMesh) => data.id);
        if (userHiddenIds && userHiddenIds.length) {
          yield put({
            type: EWorkspaceMeshActionType.UPDATE_USER_HIDDEN,
            userHiddenMeshIds: userHiddenIds,
          });
          yield put({
            type: EWorkspaceMeshActionType.INIT_HIDDEN_MESH_TILES,
            data: userHiddenIds,
          });
        }
      }
      if (geometries && geometries.length > 0) {
        const hidden = geometries.filter((data: IWorkspaceGeometry) => hiddenIds.includes(data.id));
        const userHiddenIds = hidden.map((data: IWorkspaceGeometry) => data.id);
        if (userHiddenIds && userHiddenIds.length) {
          yield put({
            type: EWorkspaceGeometryActionType.UPDATE_USER_HIDDEN,
            userHiddenGeometryIds: userHiddenIds,
          });
        }
      }
      if (variables && variables.length > 0) {
        const hidden = variables.filter((data: IWorkspaceVariable) => hiddenIds.includes(data.id));
        const userHiddenIds = hidden.map((data: IWorkspaceVariable) => data.id);
        if (userHiddenIds && userHiddenIds.length) {
          yield put({
            type: EWorkspaceVariableActionType.UPDATE_USER_HIDDEN,
            userHiddenVariableIds: userHiddenIds,
          });
        }
      }
    }
  }

  yield call(dispatchLoadSuccessActions, workspace);

  yield call(loadItemDatas, workspaceId, EElementCategories.GEOMETRY, geometries);
  yield call(loadItemDatas, workspaceId, EElementCategories.MESH, meshes);
  yield call(loadItemDatas, workspaceId, EElementCategories.VARIABLE, variables);

  const geomIds = geometries.map((geom) => geom.id);
  const meshIds = meshes.map((mesh) => mesh.id);

  const itemsId = geomIds.concat(meshIds);

  yield put({
    type: 'panel/INIT_PANEL_SETTINGS',
    workspaceId,
    itemsId,
  });
}

function* handleWorkspaceUpdated(action) {
  const { workspace } = action;

  yield call(dispatchLoadSuccessActions, workspace);
}

const getInitialBounds = (workspace: IWorkspace) => {
  const recentBounds = localStorage.getItem(RECENT_WORKSPACES_BOUNDS);
  const rb = recentBounds ? JSON.parse(recentBounds) : {};
  const lastBounds = rb[workspace.id];
  if (workspace.epsgCode === 4326) {
    const initialBounds = [-60, 60, -90, 90, 0, 0];
    if (lastBounds !== undefined) {
      if (
        lastBounds[0] < initialBounds[0] ||
        lastBounds[1] > initialBounds[1] ||
        lastBounds[2] < initialBounds[2] ||
        lastBounds[3] > initialBounds[3]
      ) {
        return initialBounds;
      } else {
        return lastBounds;
      }
    } else {
      if (lastBounds !== undefined) {
        return lastBounds;
      } else {
        return workspace.bounds;
      }
    }
  }
};

function* dispatchLoadSuccessActions(workspace: IWorkspace) {
  const { geometries, variables, meshes, queries, operations } = workspace as IWorkspace;
  /*   const recentBounds = localStorage.getItem(RECENT_WORKSPACES_BOUNDS);
  const rb = recentBounds ? JSON.parse(recentBounds) : {};
  const lastBounds = rb[workspace.id]; */
  const initialBounds = getInitialBounds(workspace);
  //lastBounds !== undefined ? lastBounds : workspace.epsgCode !== 4326 ? workspace.bounds : [-60, 60, -90, 90, 0, 0];
  yield all([
    put({
      type: 'workspace/NEW_BOUNDING_BOX',
      workspaceBounds: initialBounds,
    }),

    put({
      type: EWorkspaceGeometryActionType.LOAD_SUCCESS,
      workspaceGeometries: geometries || [],
    }),

    put({
      type: 'workspace/variables/LOAD_SUCCESS',
      workspaceVariables: variables || [],
    }),

    put({
      type: 'workspace/meshes/LOAD_SUCCESS',
      workspaceMeshes: meshes || [],
    }),

    put({
      type: 'workspace/queries/LOAD_SUCCESS',
      workspaceQueries: queries || [],
    }),

    put({
      type: EOperationActionType.LOAD_SUCCESS,
      workspaceOperations: operations || [],
    }),

    put({
      type: WorkspaceActionType.WORKSPACE_LOAD_SUCCESS,
      workspace,
    }),
  ]);
}

function* loadItemDatas(
  workspaceId: string,
  category: EElementCategories,
  items: Array<IWorkspaceGeometry> | Array<IWorkspaceMesh> | Array<IWorkspaceVariable>,
) {
  if (!items || items.length === 0) {
    return;
  }

  switch (category) {
    case ELEMENT_CATEGORIES.GEOMETRY: {
      yield put({
        type: EWorkspaceGeometryActionType.LOAD,
      });
      break;
    }
    case ELEMENT_CATEGORIES.VARIABLE: {
      yield put({
        type: EWorkspaceVariableActionType.LOAD,
      });
      break;
    }
    case ELEMENT_CATEGORIES.MESH: {
      yield put({
        type: EWorkspaceMeshActionType.LOAD,
      });
      break;
    }
    case ELEMENT_CATEGORIES.QUERY: {
      yield put({
        type: 'workspace/queries/LOAD',
      });
      break;
    }
    default:
      console.warn('Unknown category', category);
      break;
  }

  for (let i = 0; i < items.length; i++) {
    const { id: itemId, dataId, created, updated, isTiled } = items[i];
    yield put({
      type: WorkspaceActionType.LOAD_DATA,
      identity: itemId,
      workspaceId,
      itemId,
      dataId,
      category,
      created,
      updated,
      excludeDataArrays: FeatureFlags.useStreamVtkGeometry,
      isTiled,
    });
  }
}
