/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ReactNode, useState } from 'react';
import { t } from '../../translations/i18n';

import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import { parseParamsToLabels } from '../parameters/parameter-utils';
import { TextFontSize, TitleFontSize } from '../styles/styleConsts';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';

const messageContentStyle = css`
  width: 100%;
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  font-size: ${TextFontSize};
  margin-left: ${mikeSharedTheme.spacing(2)};
`;

const titleStyle = css`
  font-weight: 600;
    font-size: ${TitleFontSize};
    line-height: 1.4em;
    margin: ${mikeSharedTheme.spacing(1)} 0;
`;

const moreButtonStyle = css`
  width: 100%;
  font-weight: 600;
  color: ${MIKE_COLORS.ACTIONBLUE_DEFAULT};
  margin: 0;
  padding: 0;
  padding-bottom: ${mikeSharedTheme.spacing(2)};
  background: 0;
  border: 0;
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
  text-align: right;
  &:hover {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  };
  &:focus {
    outline: 0;
  }
`;

const detailsStyle = css`
  margin-top: ${mikeSharedTheme.spacing(2)};
`;


const MessageBannerStyle = css`
  display: flex;
  width: 100%;
  padding: ${mikeSharedTheme.spacing(2)} 0;
  svg {
    margin: ${mikeSharedTheme.spacing(1)} 0 0 ${mikeSharedTheme.spacing(2)};
    transform: scale(1.5) translateX(3px);
    transform-origin: top center;
  } 
`;

const MessageBannerFailedStyle = css`
  border-bottom: 1px solid ${mikeSharedTheme.palette.error.main};
  svg {
    color: ${mikeSharedTheme.palette.error.main};
  }
`;

const MessageBannerWarningCss = css`
  border-bottom: 1px solid ${mikeSharedTheme.palette.warning.main};
  svg {
    color: ${mikeSharedTheme.palette.warning.main};
  }
`;

export type MessageBannerTypes = 'failed' | 'warning' | 'info';

type MessageBannerProps = {
  messageTitle: string;
  messageType?: MessageBannerTypes;
  MessageIcon?: React.ComponentType<any>;
  message: string;
  messageDetails?: string;
  additionalMessageDetails?: ReactNode;

  children?: React.ReactNode;
};

// todo dan refactor: this should probably become a few composable components instead
// todo hevo refactor : I think Banner would be a good candidate for this when available in mui
export const MmgMessageBanner = (props: MessageBannerProps) => {
  const { message, messageType, messageTitle, messageDetails, additionalMessageDetails, MessageIcon, children } = props;

  const [detailsShown, setDetailsShown] = useState(false);

  const canShowDetails = messageDetails != null || additionalMessageDetails != null;

  let style = MessageBannerStyle;

  let StateIcon = null;

  switch (messageType) {
    case 'failed':
      style = css`
        ${MessageBannerStyle} ${MessageBannerFailedStyle};
      `;
      StateIcon = WarningIcon;
      break;

    case 'warning':
      style = css`
        ${MessageBannerStyle} ${MessageBannerWarningCss};
      `;
      StateIcon = WarningIcon;
      break;

    case 'info':
      StateIcon = InfoIcon;
      break;

    default:
      style = MessageBannerStyle;

      break;
  }

  const Icon = MessageIcon ? MessageIcon : StateIcon;
  const iconStyle = { width: 20, height: 20 };

  return (
    <div css={style}>
      {Icon && (
        <span>
          <Icon style={iconStyle} />
        </span>
      )}

      <section css={messageContentStyle}>
        <p css={titleStyle}>{messageTitle}</p>

        <div>
          <p>{parseParamsToLabels(message)}</p>

          {children}
        </div>

        {canShowDetails && (
          <button onClick={() => setDetailsShown(!detailsShown)} css={moreButtonStyle}>
            {detailsShown ? t('SHOW_LESS') : t('SHOW_MORE')}
          </button>
        )}

        {detailsShown && (
          <>
            <p css={detailsStyle}>{messageDetails}</p>
            {additionalMessageDetails}
          </>
        )}
      </section>
    </div>
  );
};
