import { store } from '../store/store';
import BreadcrumbUtils from '../app/topbar/breadcrumb-utils';
import { IProject } from '../models/IProject';
import { t } from '../translations/i18n';
import { IWorkspaceOverview } from '../models/IWorkspaces';
import WorkspaceRoutes from '../workspaces/workspace.routes';
import CommentRoutes from '../comments/comment.routes';
import { CONFIRM_PANEL_IDS } from '../shared/confirm-containers/confirm-constants';

export const SIDEPANEL_NAVIGATION_GROUPS = {
  SETTINGS: 'workspace-settings',
  MAIN_PANEL: 'main-panel',
  COMMENTS: 'comments',
  DOWNLOAD: 'download',
};

/**
 * Returns the name of a project or a default if not available.
 *
 * @param [project]
 */
export const getProjectName = (project?: IProject) => {
  if (project && project.name) {
    return project.name;
  }

  // we do not know the name yet
  return t('PROJECT');
};

/**
 * Returns the name of a project or a default if not available.
 *
 * @param workspace
 */
export const getWorkspaceName = (workspace?: IWorkspaceOverview) => {
  if (workspace && workspace.name) {
    return workspace.name;
  }

  // we do not know the name yet
  return t('WORKSPACE');
};

/**
 * Update breadcrumbs, including project information.
 *
 * @param project
 * @param workspace
 */
export const setBreadcrumb = (project: IProject, workspace?: IWorkspaceOverview) => {
  const crumbs = [
    BreadcrumbUtils.getHomeCrumb(),
    BreadcrumbUtils.getProjectCrumb(project.id, project.name || t('PROJECT')),
  ];

  store.dispatch({
    type: 'app/breadcrumbs/SET',
    breadcrumbs: workspace
      ? crumbs.concat(BreadcrumbUtils.getWorkspaceCrumb(workspace.id, project.id, workspace.name))
      : crumbs,
  });
};

/**
 * Gets the string matching the current navigation group. Determined by path, @see MmgWorkspaceNavigationGroupSetter
 *
 * @param routePath
 */
export const getNavigationGroupByRoutePath = (routePath) => {
  switch (routePath) {
    case WorkspaceRoutes.workspaceEditPanel.path:
      return SIDEPANEL_NAVIGATION_GROUPS.SETTINGS;

    case WorkspaceRoutes.workspaceExportPanel.path:
      return SIDEPANEL_NAVIGATION_GROUPS.DOWNLOAD;

    case CommentRoutes.commentsPanel.path:
      return SIDEPANEL_NAVIGATION_GROUPS.COMMENTS;

    default:
      return SIDEPANEL_NAVIGATION_GROUPS.MAIN_PANEL;
  }
};

/**
 * Similar to getNavigationGroupByRoutePath, but returns an 'augmented' current navigation group, based on where a confirm panel is logically accessed from the UI.
 *
 * @param confirmPanelId
 */
export const getNavigationGroupByConfirmPanelId = (confirmPanelId: string) => {
  switch (confirmPanelId) {
    case CONFIRM_PANEL_IDS.GEOMETRY_SELECTION:
    case CONFIRM_PANEL_IDS.MESH_SELECTION:
    case CONFIRM_PANEL_IDS.GEOMETRY_DRAW_NEW:
    case CONFIRM_PANEL_IDS.GEOMETRY_EDIT:
    case CONFIRM_PANEL_IDS.EXTRACT_DATA:
      return SIDEPANEL_NAVIGATION_GROUPS.MAIN_PANEL;

    case CONFIRM_PANEL_IDS.COMMENT_ADD:
      return SIDEPANEL_NAVIGATION_GROUPS.COMMENTS;

    default:
      return SIDEPANEL_NAVIGATION_GROUPS.MAIN_PANEL;
  }
};

const self = {
  getProjectName,
  getWorkspaceName,
  setBreadcrumb,
  getNavigationGroupByRoutePath,
  getNavigationGroupByConfirmPanelId,
};

export default self;
