/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { t } from '../../translations/i18n';
import { updateWorkspaceDataItemColor } from '../../store/actions/workspaceDataActions';
import { useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { isEqual } from 'lodash-es';
import { HuePicker } from 'react-color';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import { IGlobalState } from '../../store/reducers';
import MIKE_COLORS from '../styles/mike-colors';

interface IProps {
  elementId: string;
  currentEdgeColor: Array<number>;
  currentSurfaceColor: Array<number>;
  isMesh?: boolean;
  isTiled?: boolean;
}

const ContainerStyle = css`
  width: 100%;
  margin-bottom: ${mikeSharedTheme.spacing(2)};
  display: flex;
  justify-content: space-between;
`;

const TinyButtonActiveStyle = css`
  position: relative;
  width: ${mikeSharedTheme.spacing(2.5)};
  height: ${mikeSharedTheme.spacing(2.5)};
  padding: ${mikeSharedTheme.spacing(0.5)};
  :before {
    content: '';
    display: inline-block;
    position: absolute;
    top: ${mikeSharedTheme.spacing(-0.5)};
    left: ${mikeSharedTheme.spacing(-0.5)};
    width: ${mikeSharedTheme.spacing(3)};
    height: ${mikeSharedTheme.spacing(3)};
    border-radius: 50%;
    border: ${mikeSharedTheme.spacing(0.25)} solid ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
`;

const TinyButtonStyle = css`
  position: relative;
  width: ${mikeSharedTheme.spacing(2.5)};
  height: ${mikeSharedTheme.spacing(2.5)};
  padding: ${mikeSharedTheme.spacing(0)};
`;


const topScrollPaperStyle = css`
  align-items: flex-end;
  background: transparent;
`;

const titleStyle = css`
  padding-bottom: ${mikeSharedTheme.spacing(1)};
`;

export const MmgConnectedApplyColor = ({
  elementId,
  currentEdgeColor,
  currentSurfaceColor,
  isMesh,
  isTiled,
}: IProps) => {
  const availableColors = useSelector((state: IGlobalState) => state.ViewerModeReducer.availableColors);
  const [isSurfacePickerOpen, setIsSurfacePickerOpen] = React.useState(false);
  const [isEdgePickerOpen, setIsEdgePickerOpen] = React.useState(false);

  const convertColorToHex = (c: any) => {
    const l = tinycolor({ r: c[0] * 255, g: c[1] * 255, b: c[2] * 255 });
    return '#' + l.toHex();
  };

  const applyEdgeColor = (color: Array<number>) => {
    const options = { elementId, edgeColor: [...color, currentEdgeColor[3]], surfaceColor: currentSurfaceColor };
    updateWorkspaceDataItemColor(options, true, isTiled);
  };
  const applySurfaceColor = (color: Array<number>) => {
    const options = { elementId, edgeColor: currentEdgeColor, surfaceColor: [...color, currentSurfaceColor[3]] };
    updateWorkspaceDataItemColor(options, true, isTiled);
  };
  const applyEdgeColorPicker = (color: any) => {
    const c = color.rgb;
    const decRgb = [c.r / 255, c.g / 255, c.b / 255];
    const options = { elementId, edgeColor: [...decRgb, currentEdgeColor[3]], surfaceColor: currentSurfaceColor };
    updateWorkspaceDataItemColor(options, true, isTiled);
  };
  const applySurfaceColorPicker = (color: any) => {
    const c = color.rgb;
    const decRgb = [c.r / 255, c.g / 255, c.b / 255];
    const options = { elementId, edgeColor: currentEdgeColor, surfaceColor: [...decRgb, currentSurfaceColor[3]] };
    updateWorkspaceDataItemColor(options, true, isTiled);
  };

  /*   const applySurfaceColorPicker = (color: any) => {
    const c = color.rgb;
    const decRgb = [c.r / 255, c.g / 255, c.b / 255];
    const options = { elementId, edgeColor: currentEdgeColor, surfaceColor: [...decRgb, currentSurfaceColor[3]] };
    updateWorkspaceDataItemColor(options);
  }; */

  return (  
    <div css={ContainerStyle}>        
      <div>
        {isMesh && (
          <>
            <Typography css={titleStyle} variant={'body2'}>
              <b>{t('EDGE_COLOR')}</b>
            </Typography>
            <div
              style={{ background: convertColorToHex(currentEdgeColor) }}
              css={TinyButtonStyle}
              onClick={() => {
                setIsEdgePickerOpen(!isEdgePickerOpen);
              }}
            >
              {isEdgePickerOpen && (
                <Dialog
                  style={{ left: '50%' }}
                  open={isEdgePickerOpen}
                  css={topScrollPaperStyle}
                >
                  <DialogContent>
                    <section>
                      {availableColors.map((color, id) => (
                        <>
                          <button
                            key={id}
                            css={isEqual(currentEdgeColor.slice(0, 3), color) ? TinyButtonActiveStyle : TinyButtonStyle}
                            onClick={() => applyEdgeColor(color)}
                            style={{ backgroundColor: `rgb(${color.map((val) => val * 255).join(',')})` }}
                          />
                        </>
                      ))}
                      <HuePicker
                        color={convertColorToHex(currentEdgeColor)}
                        onChangeComplete={applyEdgeColorPicker}
                      />
                    </section>
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        <Typography css={titleStyle} variant={'body2'}>
          <b>{t('SURFACE_COLOR')}</b>
        </Typography>
        <div
          style={{ background: convertColorToHex(currentSurfaceColor) }}
          css={TinyButtonStyle}
          onClick={() => {
            setIsSurfacePickerOpen(!isSurfacePickerOpen);
          }}
        >
          {isSurfacePickerOpen && (
            <Dialog
              style={{ left: '50%' }}
              open={isSurfacePickerOpen}
              css={topScrollPaperStyle}
            >
              <DialogContent>
                <section>
                  {availableColors.map((color, id) => (
                    <button
                      key={id}
                      css={isEqual(currentEdgeColor.slice(0, 3), color) ? TinyButtonActiveStyle : TinyButtonStyle}
                      onClick={() => applySurfaceColor(color)}
                      style={{ backgroundColor: `rgb(${color.map((val) => val * 255).join(',')})` }}
                    />
                  ))}
                  <HuePicker
                    color={convertColorToHex(currentSurfaceColor)}
                    onChangeComplete={applySurfaceColorPicker}
                  />
                </section>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>     
    </div>
  );
};
