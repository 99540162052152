import { useState } from 'react';
import { t } from '../../translations/i18n';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import { ELEMENT_CATEGORIES, PANEL_TABS } from '../../shared/panels/mesh-panel-constants';
import { getRouteByPath, ROUTES } from '../../app/routes';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceVariablesManager from '../../managers/WorkspaceVariablesManager';
import LayerUtils, { fetchLayer } from '../../shared/layers/layer-utils';
import { MmgConnectedLayerVariableActions } from '../list/actions/layer-variable-actions';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgPanelHeaderEdit } from '../../shared/panels/panel-header-edit';
import { getVariableDescription } from '../../shared/panels/panel-utils';
import { MmgTabs } from '../../shared/tabs/tabs';
import { MmgTab } from '../../shared/tabs/tab';
import { MmgTabPanel } from '../../shared/tabs/tab-panel';
import { MmgVariableOverview } from './variable-overview';
import { LinearProgress } from '@mui/material';
import { useCurrentTab } from '../../shared/tabs/useCurrentTab';
import { useHideOtherLayers } from '../../shared/hooks/useHideOtherLayers';
import { IProject } from '../../models/IProject';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { IGlobalState } from '../../store/reducers';


const { isLayerWorking, isLayerFailed, isLayerLoading } = LayerUtils;

/**
 * @name MmgConnectedVariableDetails  Displays variable tools & detailed information, such as statistics.
 * @summary Displays variable detailed information, such as statistics.
 */
export const MmgConnectedVariableDetails = () => {
  const navigate = useNavigate();
  const getVariableSelectorInstance = WorkspaceVariableSelectors.makeGetVariable();
  const getDrawnDataSelectorInstance = WorkspaceVariableSelectors.makeGetVariableDrawnData();
  const { variableId, workspaceId, projectId } = useParams();
  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const variable: IWorkspaceEnrichedVariable = useSelector((state: IGlobalState) =>
    getVariableSelectorInstance(state, { variableId }),
  );
  const variableDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, {
      variableId,
    }),
  );

  const [isEditingName, setIsEditingName] = useState(false);
  const { currentTab, onTabChange } = useCurrentTab(
    PANEL_TABS.VARIABLE_OVERVIEW_TAB,
    workspaceId,
    variable && variable.id,
  );
  const drawnVariables = variableDrawnData ? [variableDrawnData] : [];
  const title = variable && variable.name ? variable.name : t('VARIABLE');
  const description = getVariableDescription(variable, drawnVariables);
  const missingVariable = !variable || !variable.id;
  const isFailed = isLayerFailed(variable);
  const isWorking = isLayerWorking(variable, drawnVariables);
  const isLoading = isLayerLoading(variable, drawnVariables);
  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  useHideOtherLayers({
    elementId: variableId,
    elementCategory: ELEMENT_CATEGORIES.VARIABLE,
  });

  useEffect(
    () => {
      if (!loadedData.includes(variableId)) {
        fetchLayer(variableId, ELEMENT_CATEGORIES.VARIABLE);
      }
    },
    [variableId, loadedData],
  );

  // always go to workspace panel, no matter how we got here
  const onPanelExit = () => {
    navigateToWorkspacePanel();
  };

  const navigateToWorkspacePanel = () => {
    navigate(getRouteByPath(ROUTES.workspace.path, { workspaceId, projectId })); 
  };

  /**
   * Toggles editing state of variable name.
   */
  const toggleVariableNameEdit = () => {
    setIsEditingName(!isEditingName);
  };

  /**
   * Handles variable panel updates, submitting the name to the API.
   *
   * @param panel
   * @param panel.name
   */
  const onPanelUpdate = ({ name }) => {
    WorkspaceVariablesManager.renameVariable(workspaceId, variableId, name)
      .then(() => {
        store.dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('VARIABLE_UPDATED_SUCCESSFULLY'),
          },
        });

        setIsEditingName(false);
      })
      .catch((error) => {
        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: {
            text: t('VARIABLE_UPDATE_FAILED'),
          },
        });

        throw error;
      });
  };

  /**
   * Gets header actions for panel more-menu.
   */
  const getPanelHeaderActions = () => {
    if (!variable || !canUpdateWorkspace) {
      return null;
    }
    return (
      <MmgConnectedLayerVariableActions
        layerId={variable.id}
        variable={variable}
        variableDrawnData={variableDrawnData}
        canToggleLayerVisibility={false}
        onVariableEdit={toggleVariableNameEdit}
      />
    );
  };

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          {!isEditingName ? (
            <MmgPanelHeader
              noBorder={true}
              panelTitle={title}
              panelDescription={description}
              onPanelExit={onPanelExit}
              panelHeaderActions={getPanelHeaderActions()}
            />
          ) : (
            <MmgPanelHeaderEdit panelTitle={title} onPanelExit={onPanelExit} onPanelUpdate={onPanelUpdate} />
          )}

          {!missingVariable && (
            <MmgTabs value={currentTab} onChange={onTabChange}>
              <MmgTab label={t('VARIABLE_OVERVIEW_TAB')} value={PANEL_TABS.VARIABLE_OVERVIEW_TAB} index={0} />
            </MmgTabs>
          )}

          {isWorking && <LinearProgress />}
        </MikeStickyPanelHeaderContainer>

        {missingVariable && !isLoading ? (
          <MmgPanelSubsection>
            <p>{t('VARIABLE_MISSING')}</p>
          </MmgPanelSubsection>
        ) : (
          <>
            <div>
              {isFailed && (
                <MmgMessageBanner
                  messageTitle={t('MESSAGE_VARIABLE_FAILED_TITLE')}
                  message={variable.message}
                  messageType="failed"
                />
              )}

              <MmgTabPanel value={PANEL_TABS.VARIABLE_OVERVIEW_TAB} currentValue={currentTab} index={0}>
                <MmgVariableOverview variable={variable} variableDrawnData={variableDrawnData} />
              </MmgTabPanel>
            </div>
          </>
        )}
      </MikeStickyPanel>
    </>
  );
};
