import { IApplications } from '../../shared-components/mike-subscribed-apps/model/IApplications';
import { EApplicationsActionType } from './ApplicationsActionType';

export const loadApplications = () => ({
  type: EApplicationsActionType.LOAD_APPLICATIONS,
});

export const loadingApplications = (loading = true) => ({
  type: EApplicationsActionType.LOADING_APPLICATIONS,
  data: loading,
});

export const setApplications = (applications: IApplications) => ({
  type: EApplicationsActionType.SET_APPLICATIONS,
  data: applications,
});
