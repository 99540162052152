
import { IWorkspaceVariable } from '../../models/IVariables';
import { MikeLayerEdit } from '../../shared-components/mike-layer/layer-edit';

import VariableIconUtils from '../variable-icon-utils';
const { getIcon } = VariableIconUtils;

type VariableListItemEditProps = {
  variable: IWorkspaceVariable;
  onVariableUpdate: (variable: IWorkspaceVariable) => any;
};

/**
 * @name MmgVariableListItemEdit
 * @summary A edited item in a variable list.
 *
 * @param props
 */
export function MmgVariableListItemEdit(props: VariableListItemEditProps) {
  const { variable } = props;
  const { name, id } = variable;

  const onVariableUpdate = ({ name: variableName }) => props.onVariableUpdate({ name: variableName, id });

  return <MikeLayerEdit layerName={name} leftIcon={getIcon(variable)} onLayerUpdate={onVariableUpdate} />;
}
