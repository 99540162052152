/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { t } from '../translations/i18n';
import { toLocaleRoundedString, translateWithPrefix } from '../translations/utils';
import { isNumber } from 'lodash-es';
import { MmgConnectedStatistics } from './mmg-statistics';
import { EElementCategories } from '../shared/panels/mesh-panel-constants';
import { IGeometryLabelConfig } from '../store/reducers/WorkspaceDataReducer';
import { useSelector } from 'react-redux';
import { ISettingsLayer, IUserSettings } from '../models/IUserSettings';
import { IGlobalState } from '../store/reducers';
import { fetchAttributeLabels } from '../store/actions/workspaceDataActions';
import { useParams } from 'react-router-dom';
import { MmgConnectedDataStatistics } from './mmg-data-statistics';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';

const ContainerStyle = css`  
  padding: ${mikeSharedTheme.spacing(2)};
`;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface IDataArrayStatistics
  extends Array<{
      id: string | null;
      name: string;
      value?: string;
      range?: Array<number>;
      uniqueValues?: Array<number>;
    }> {}

interface IMmgStatisticContainerProps {
  elementId: string;
  elementCategory: EElementCategories;
  dataArrays: Array<any>;
  fieldDataArrays: Array<any>;
  isTiled: boolean;
}

const MmgStatisticsContainer = (props: IMmgStatisticContainerProps) => {
  const { elementCategory, fieldDataArrays, dataArrays, elementId, isTiled } = props;
  const { workspaceId } = useParams();
  const userSettings: IUserSettings = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.settings);
  const layer: ISettingsLayer | undefined =
    userSettings && userSettings.tableOfContents.layers.find((l: ISettingsLayer) => l.vtkItemId === elementId);

  const geometryAttributeLabels: IGeometryLabelConfig = useSelector(
    (state: IGlobalState) => state.WorkspaceDataReducer.geometryAttributeLabels,
  );

  const loadingFieldStatistics: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.loadingFieldStatistics,
  );

  const fieldStatistics: { [key: string]: IDataArrayStatistics } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.fieldStatistics,
  );

  const dataStatistics: { [key: string]: IDataArrayStatistics } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.dataStatistics,
  );

  const { fieldStatisticsForTiledMesh, dataStatisticsForTiledMesh } = useMemo(
    () => {
      if (isTiled && !loadingFieldStatistics && fieldStatistics && dataStatistics) {
        return {
          fieldStatisticsForTiledMesh: fieldStatistics[elementId],
          dataStatisticsForTiledMesh: dataStatistics[elementId],
        };
      }
      return {
        fieldStatisticsForTiledMesh: [],
        dataStatisticsForTiledMesh: [],
      };
    },
    [isTiled, loadingFieldStatistics, fieldStatistics, elementId, dataStatistics],
  );

  const layerAttribute: string = layer && layer.labelAttribute;

  useEffect(
    () => {
      layerAttribute && fetchAttributeLabels(elementId, layerAttribute, workspaceId);
    },
    [elementId, layerAttribute, workspaceId],
  );

  const fieldDataStatistics = useMemo(
    () => {
      const filterIds = ['Id', 'EPSG', 'HasZ'];

      if (isTiled) {
        const filtered = !fieldStatisticsForTiledMesh
          ? []
          : fieldStatisticsForTiledMesh.filter(({ id }) => !filterIds.includes(id));
        return filtered.map(({ id, value }) => {
          const val = Number(value);
          const suffix = id === 'BadElements' ? '%' : '';
          return {
            id,
            name: translateWithPrefix('PROP', id),
            value: isNumber(val) ? toLocaleRoundedString(val, 3, 10) + suffix : '-',
          };
        });
      } else {
        return fieldDataArrays
          .filter(
            ({ id }) => !filterIds.includes(id) && dataArrays.findIndex((dataArray) => id === dataArray.id) === -1,
          ) // Filter out unwanted properties.
          .map(({ id, data }) => {
            const suffix = id === 'BadElements' ? '%' : '';

            return {
              id,
              name: translateWithPrefix('PROP', id),
              value: isNumber(data[0]) ? toLocaleRoundedString(data[0], 3, 10) + suffix : '-',
            };
          });
      }
    },
    [isTiled, fieldStatisticsForTiledMesh, fieldDataArrays, dataArrays],
  );

  const dataArrayStatistics: IDataArrayStatistics = useMemo(
    () => {
      const noneStatistic = { id: null, name: t('NONE') };
      if (isTiled) {
        return !dataStatisticsForTiledMesh
          ? []
          : [
              noneStatistic,
              ...dataStatisticsForTiledMesh
                .map(({ id }) => {
                  return {
                    id,
                    name: translateWithPrefix('PROP', id),
                    value: '',
                  };
                })
                .reverse(),
            ];
      } else {
        return [
          noneStatistic,
          ...dataArrays
            .map(({ id }) => {
              return {
                id,
                name: translateWithPrefix('PROP', id),
                value: '',
              };
            })
            .reverse(),
        ];
      }
    },
    [dataArrays, isTiled, dataStatisticsForTiledMesh],
  );

  const FieldDataStatistics = () => {
    return fieldDataStatistics.length ? <MmgConnectedStatistics statistics={fieldDataStatistics} /> : null;
  };

  const DataArraysStatistics = () => {
    return (
      <MmgConnectedDataStatistics
        elementCategory={elementCategory}
        elementId={elementId}
        statistics={dataArrayStatistics}
        renderName={(name: string) => {
          return <>{name}</>;
        }}
        geometryAttributeLabels={geometryAttributeLabels}
        layerAttribute={layerAttribute}
        isTiled={isTiled}
      />
    );
  };
  return (
    <div>    
      <FieldDataStatistics />   
      <div css={ContainerStyle}>  
        <DataArraysStatistics />
      </div>    
    </div>
  );
};

export default MmgStatisticsContainer;
