import { useState, useEffect, useMemo, useCallback } from 'react';
import { t } from '../../translations/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { MmgConnectedGeometrySelectList } from '../select-list/geometry-select-list';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import { IDrawnDataItem } from '../../models//IWorkspaceData';
import { getIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { submitExportGeometries } from './export-geometry-utils';
import { useIsMounted } from '../../shared/hooks/hooks';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { CircularProgress } from '@mui/material';
import { MmgExportItemsMessage } from '../../workspaces/export/export-items-message';
import { IGlobalState } from '../../store/reducers';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../shared-components/mike-button';

type ExportGeometriesProps = {
  workspaceId: string;
  isExportAllowed: boolean;
};

/**
 * @name MmgConnectedExportGeometries
 * @summary Allows exporting selected geometries.
 *
 * @param props
 */
export const MmgConnectedExportGeometries = (props: ExportGeometriesProps) => {
  const { workspaceId, isExportAllowed } = props;

  const { hiddenWorkspaceGeometries, selectedWorkspaceGeometries } = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer,
  );
  const geometries = useSelector(WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries);
  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );

  const { exportableGeometryIds, exportableGeometriesLoading } = useSelector(
    (state: IGlobalState) => state.WorkspaceExportReducer,
  );

  const [exportInProgress, setExportInProgress] = useState(false);

  const isMounted = useIsMounted();

  const dispatch = useDispatch();

  const anyGeometries = geometries && geometries.length > 0;
  const anyExportableGeometries = exportableGeometryIds && exportableGeometryIds.length > 0;

  const exportTip = anyGeometries ? t('EXPORT_GEOMETRIES_TIP') : t('NO_GEOMETRIES_TO_EXPORT');

  // if no permission to export or export is already in progress all items are disabled.
  // Otherwise those that are not exportable are disabled
  const disabledGeometryIds = useMemo(
    () => {
      if (!geometries) {
        return [];
      }
      const disabledItems =
        !isExportAllowed || exportInProgress || !anyExportableGeometries || exportableGeometriesLoading
          ? geometries
          : geometries.filter(({ id }) => exportableGeometryIds.indexOf(id) === -1);

      return getIds(disabledItems);
    },
    [
      geometries,
      isExportAllowed,
      exportInProgress,
      exportableGeometryIds,
      anyExportableGeometries,
      exportableGeometriesLoading,
    ],
  );

  const isSelectionValid = selectedWorkspaceGeometries && selectedWorkspaceGeometries.length > 0;

  const clearSelectedGeometries = useCallback(
    () => {
      dispatch({ type: 'workspace/geometries/DESELECT_ALL' });
    },
    [dispatch],
  );

  const loadExportableGeometries = useCallback(
    () => {
      dispatch({
        type: 'workspace/export/LOAD_EXPORTABLE_GEOMETRIES',
        workspaceId,
      });
    },
    [workspaceId, dispatch],
  );

  const clearExportableGeometries = useCallback(
    () => {
      dispatch({ type: 'workspace/export/RESET_EXPORTABLE_GEOMETRIES' });
    },
    [dispatch],
  );

  const exportGeometries = (geometryIds: Array<string>) => {
    setExportInProgress(true);
    return submitExportGeometries(workspaceId, geometryIds)
      .then(() => {
        clearSelectedGeometries();
      })
      .finally(() => {
        isMounted() && setExportInProgress(false);
      });
  };

  useEffect(
    () => {
      loadExportableGeometries();

      return () => {
        clearExportableGeometries();
      };
    },
    [loadExportableGeometries, clearExportableGeometries],
  );

  useEffect(
    () => {
      clearSelectedGeometries();
      return () => {
        clearSelectedGeometries();
      };
    },
    [clearSelectedGeometries],
  );

  return (
    <>
      {exportableGeometriesLoading && (
        <MmgPanelSubsection>
          <CircularProgress />
        </MmgPanelSubsection>
      )}

      {!exportableGeometriesLoading && (
        <>
          <MmgExportItemsMessage message={exportTip} />

          <MmgConnectedGeometrySelectList
            geometries={geometries}
            hiddenGeometries={hiddenWorkspaceGeometries}
            drawnGeometries={drawnWorkspaceGeometries}
            selectedGeometries={selectedWorkspaceGeometries}
            disabledGeometries={disabledGeometryIds}
            isFailedSelectable={true}
          />
        </>
      )}

      <MikeStickyPanelBottomActions>
        <MikeButton
          variant="contained"
          color="secondary"
          disabled={!isExportAllowed || !isSelectionValid || exportInProgress}
          onClick={() => exportGeometries(selectedWorkspaceGeometries)}
          active={exportInProgress}
          fullWidth
        >
          {t('GEOMETRY_EXPORT', selectedWorkspaceGeometries.length)}
        </MikeButton>
      </MikeStickyPanelBottomActions>
    </>
  );
};
