import { isEmpty, isNumber } from 'lodash-es';
import {
  IQueryDefinitionApi,
  QUERY_DEFINITION_TYPES,
  IAttributeRangeQueryDefinitionApi,
  IPersistedQueryDefinitionApi,
} from '../../models/IQueryDefinitions';
import { t } from '../../translations/i18n';
import { translateWithPrefix, toLocaleRoundedString } from '../../translations/utils';
import { IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import { isAttributeCategorical } from './attribute-utils';
import { getCategoryDisplayName } from '../../managers/annotation-utils';
import { IWorkspaceQuery } from '../../models/IQueries';

// operation summary item?
export interface ISummaryItem {
  itemKey: string;
  itemDisplayName: string;
  itemValueString: string;
}

/**
 * Returns summary items for query configuration
 * @param queryDefinition
 * @param attributes
 * @param attributeSettings
 * @param persistedQueries
 * @param criteriaLabel
 */
export const getQueryConfigurationSummaryItems = (
  queryDefinition: IQueryDefinitionApi,
  attributes?: Array<IWorkspaceAttribute>,
  attributeSettings?: Array<IWorkspaceAttributeSettings>,
  persistedQueries?: Array<IWorkspaceQuery>,
  criteriaLabel?: string,
): Array<ISummaryItem> => {
  if (!queryDefinition || isEmpty(queryDefinition)) {
    return null;
  }

  const getQueryCriteriaSummaryItem = (definition) => {
    const { type: queryType } = definition;

    let criteriaName;

    switch (queryType) {
      case QUERY_DEFINITION_TYPES.ATTRIBUTE_RANGE: {
        // for attributes we show the attribute name
        const { name } = definition as IAttributeRangeQueryDefinitionApi;
        criteriaName = translateWithPrefix('PROP', name);
        break;
      }

      default:
        criteriaName = translateWithPrefix('QUERY', queryType);
        break;
    }
    // query criteria
    return {
      itemKey: type,
      itemDisplayName: criteriaLabel || t('QUERY_CRITERIA_LABEL'),
      itemValueString: criteriaName,
    };
  };

  const getAttributeRangeSummaryItems = (definition: IAttributeRangeQueryDefinitionApi): Array<ISummaryItem> => {
    if (!definition) {
      return null;
    }

    const { name, fromValue, toValue } = definition;
    const attribute = (attributes || []).find((a) => a.name === name);

    if (!attribute) {
      return null;
    }

    const isCategorical = isAttributeCategorical(name, attributeSettings);

    if (isCategorical) {
      // Only return a summary item if something  is selected for the categrogical attribute.
      if (fromValue || fromValue === 0) {
        const displayName = getCategoryDisplayName(name, fromValue);
        return [
          {
            itemKey: 'query-value',
            itemDisplayName: t('QUERY_VALUE'),
            itemValueString: displayName,
          },
        ];
      }
    }

    const { range } = attribute;

    //we do not want to show anything in summary if the attribute min/max values (range) have been selected for both min and max
    const minValue = !range || fromValue !== range[0] ? fromValue : null;
    const maxValue = !range || toValue !== range[1] ? toValue : null;

    // Do not inlude range queries if neither is defined
    if (!isNumber(minValue) && !isNumber(maxValue)) {
      return [];
    }

    // todo hevo Not completely sure it is ok to round here.
    const minValueString = toLocaleRoundedString(minValue, 3);
    const maxValueString = toLocaleRoundedString(maxValue, 3);

    // Only inlcude oneline in summary if same value for min/max
    if (minValueString === maxValueString) {
      return [
        {
          itemKey: 'query-value',
          itemDisplayName: t('QUERY_VALUE'),
          itemValueString: minValueString,
        },
      ];
    }

    const valueItems = [];

    // only include min in summary if defined
    if (minValueString) {
      valueItems.push({
        itemKey: 'query-min-value',
        itemDisplayName: t('QUERY_MIN_VALUE'),
        itemValueString: minValueString,
      });
    }

    // only include max in summary if defined
    if (maxValueString) {
      valueItems.push({
        itemKey: 'query-max-value',
        itemDisplayName: t('QUERY_MAX_VALUE'),
        itemValueString: maxValueString,
      });
    }

    return valueItems;
  };

  const getPersistedSelectionSummaryItems = (definition: IPersistedQueryDefinitionApi): Array<ISummaryItem> => {
    const { queryId } = definition;

    // noting to include in summary
    if (!queryId || !persistedQueries) {
      return null;
    }

    const query = persistedQueries.find(({ id }) => id === queryId);

    // nothing to include in summary
    if (!query) {
      return null;
    }

    return [
      {
        itemKey: 'query-id',
        itemDisplayName: t('QUERY_VALUE'),
        itemValueString: query.name,
      },
    ];
  };

  const { type } = queryDefinition;

  // we  do not inlcude any summary items for 'select all', since it will act the same as no query.
  if (type === QUERY_DEFINITION_TYPES.SELECT_ALL) {
    return null;
  }

  const criteriaSummary = getQueryCriteriaSummaryItem(queryDefinition);

  if (type === QUERY_DEFINITION_TYPES.ATTRIBUTE_RANGE) {
    const attributeRangeSummaries = getAttributeRangeSummaryItems(queryDefinition as IAttributeRangeQueryDefinitionApi);

    // we do not include the criteria in summary unless there are summaries for the selected range
    if (attributeRangeSummaries && attributeRangeSummaries.length > 0) {
      return [criteriaSummary, ...attributeRangeSummaries];
    }
  }

  if (type === QUERY_DEFINITION_TYPES.PERSISTED_SELECTION) {
    const persistedQuerySummaries = getPersistedSelectionSummaryItems(queryDefinition as IPersistedQueryDefinitionApi);

    // we do not include the criteria in summary unless there are summaries for the selected query
    // todo hevo: no need for two lines here, one should do.
    if (persistedQuerySummaries && persistedQuerySummaries.length > 0) {
      return [criteriaSummary, ...persistedQuerySummaries];
    }
  }

  return null;
};

const self = {
  getQueryConfigurationSummaryItems,
};

export default self;
