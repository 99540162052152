import { useEffect, useCallback, useMemo, useState } from 'react';
import { IWorkspaceAttribute, IWorkspaceOptionAttributeValue, EAttributeDataTypes } from '../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import { QUERY_CRITERIA_NAMES } from '../../models/IWorkspaceUIQueries';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import QueryUtils from '../../meshes/mesh-query/mesh-query-utils';
import LayerUtils from '../../shared/layers/layer-utils';
import { IDrawnDataGradient, IDrawnDataItem } from '../../models/IWorkspaceData';
import { store } from '../../store';
import {
  ISpatialQueryDefinitionApi,
  IPersistedQueryDefinitionApi,
  IAttributeQueryDefinitionApi,
} from '../../models/IQueryDefinitions';
import SpatialSelectionUtils from '../../queries/spatial-selections/spatial-selection-utils';
import { CircularProgress } from '@mui/material';
import {
  QUERY_DEFINITION_TYPES,
  IQueryDefinitionApi,
  ISelectAllQueryDefinitionApi,
  IAttributeRangeQueryDefinitionApi,
} from '../../models/IQueryDefinitions';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { EGeometryItemTypes, IWorkspaceEnrichedGeometry, IWorkspaceGeometry } from '../../models/IGeometries';
import {
  isAttributeCategorical,
  getCategoricalAttributeValues,
  isAttributeQuery,
  isPersistedSelectionQuery,
  isSpatialQuery,
} from './attribute-utils';
import { MmgQueryCriteriaSelector } from './query-criteria-selector';
import { MmgQueryCriteriaConfiguration } from './query-criteria-configuration';
import { IWorkspaceQuery } from '../../models/IQueries';
import { EWorkspaceQueryActionType } from '../../store/actions/WorkspaceQueryActionType';
import { IWorkspaceGeometryState } from '../../store/reducers/WorkspaceGeometryReducer';
import { useSelector } from 'react-redux';
import { IColorRange } from '../../MikeVisualizer/lib/IMikeVisualizerModels';
import { IGlobalState } from '../../store/reducers';
import { REPRESENTATION } from '../../MikeVisualizer/lib/MikeVisualizerConstants';
import { DEFAULTS } from '../../MikeVisualizer/lib/MikeVisualizerConfiguration';
import { useParams } from 'react-router-dom';
const { hideSelectionResult } = SpatialSelectionUtils;
const { isLayerLoading } = LayerUtils;

type AttributeQueryConfigurationProps = {
  projectId: string;
  workspaceId: string;
  itemId: string;
  selectCriteriaLabel: string;
  selectCriteriaPlaceholder?: string;
  allowSelectAll?: boolean;
  selectAllLabel?: string;
  initialQueryDefinition?:
    | ISelectAllQueryDefinitionApi
    | IAttributeRangeQueryDefinitionApi
    | ISpatialQueryDefinitionApi;
  item: IWorkspaceEnrichedMesh | IWorkspaceEnrichedGeometry; // todo hevo might also want to support variables in the futúre ?
  attributes?: Array<IWorkspaceAttribute>;
  attributeSettings?: Array<IWorkspaceAttributeSettings>;
  itemDrawnDatas?: Array<IDrawnDataItem>;
  savedQueries?: Array<IWorkspaceQuery>;
  onQueryDefinitionChanged?: (newQueryDefinition: IQueryDefinitionApi) => void;
  setSelectedQueryAttribute?: (e: any) => void;
};

interface IVisualisationSettings {
  dataGradient: IDrawnDataGradient;
  drawnData: IDrawnDataItem;
  colorRange?: IColorRange;
}

/**
 * @name MmgAttributeQueryConfiguration
 * @param props
 * @summary Allows configuring a queryDefinition. Can be used as as part of operations on meshes or geoemtries.
 * Highlights query in the viewer
 *
 * todo hevo When fully migrating the MmgAttributeQuery to react, consider if this component can be split into multiple as well
 *
 */
export const MmgAttributeQueryConfiguration = (props: AttributeQueryConfigurationProps) => {
  const {
    itemId,
    item,
    attributes = [],
    attributeSettings = [],
    itemDrawnDatas,
    savedQueries = [],
    onQueryDefinitionChanged: onQueryDefinitionChangedProp,
    selectCriteriaLabel,
    selectCriteriaPlaceholder,
    allowSelectAll,
    selectAllLabel,
    initialQueryDefinition,
    setSelectedQueryAttribute,
  } = props;

  const { workspaceId } = useParams();

  const workspaceGeometries: Array<IWorkspaceGeometry> = useSelector(
    (state: IGlobalState) => (state.WorkspaceGeometryReducer as IWorkspaceGeometryState).workspaceGeometries,
  );

  const loadingFieldStatistics: boolean = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshTilesReducer.loadingFieldStatistics,
  );

  const [queryDefinition, setQueryDefinition] = useState<
    ISelectAllQueryDefinitionApi | IAttributeRangeQueryDefinitionApi | ISpatialQueryDefinitionApi
  >(initialQueryDefinition);

  const [isQueryComplete, setIsQueryComplete] = useState(false);

  const [stashedVisualizationSettings, setStashedVisualizationSettings] = useState(null as IVisualisationSettings);

  useEffect(
    () => {
      if (initialQueryDefinition) {
        const queryComplete = QueryUtils.isQueryDefinitionComplete(initialQueryDefinition);
        setIsQueryComplete(queryComplete);
      }
    },
    [initialQueryDefinition],
  );

  const callOnQueryDefinitionChanged = useCallback(
    (newQueryDefinition) => {
      if (onQueryDefinitionChangedProp) {
        onQueryDefinitionChangedProp(newQueryDefinition);
      }
    },
    [onQueryDefinitionChangedProp],
  );

  /**
   * Callback for mesh-query to call whenever something changes in the query definition.
   * The query definition can be submitted later.
   *
   * @param newQueryDefinition
   */
  const onQueryDefinitionChanged = (newQueryDefinition) => {
    const queryComplete = QueryUtils.isQueryDefinitionComplete(newQueryDefinition);
    setIsQueryComplete(queryComplete);
    setQueryDefinition(newQueryDefinition);
    if (!queryComplete) {
      restoreVisualizationSettings();
    } else {
      callOnQueryDefinitionChanged(newQueryDefinition);
    }
  };

  const onPreviewQueryDefinition = useCallback(
    () => {
      // Before changing any visualization make sure to stash original settings.
      // Do not stash if already stashed
      if (!stashedVisualizationSettings) {
        const { WorkspaceDataReducer } = store.getState();
        const dataGradient =
          WorkspaceDataReducer.workspaceDataGradients.find(({ elementId }) => elementId === itemId) || {};
        const drawnData = WorkspaceDataReducer.workspaceData.find(({ id }) => id === itemId) || {};
        setStashedVisualizationSettings({
          dataGradient,
          drawnData,
        } as IVisualisationSettings);
      }

      // Preview query on map
      if (queryDefinition.type === QUERY_DEFINITION_TYPES.PERSISTED_SELECTION) {
        const { queryId } = queryDefinition as IPersistedQueryDefinitionApi;
        if (queryId) {
          store.dispatch({
            type: EWorkspaceQueryActionType.SELECTION_RESULT_SHOW,
            data: {
              workspaceId,
              selectionId: queryId,
              representation: REPRESENTATION.SURFACE_WITH_EDGES, // TODO dan: this should have the representation of the selection target
              edgeColor: DEFAULTS.colors.default.surface,
              surfaceColor: DEFAULTS.colors.select.surface,
            },
          });
        }
      } else if (queryDefinition.type !== QUERY_DEFINITION_TYPES.SPATIAL) {
        QueryUtils.highlightAttributeQueryResult(queryDefinition, item.category, itemId);
      }
    },
    [item.category, itemId, queryDefinition, stashedVisualizationSettings, workspaceId],
  );

  const onSelectedCriteriaChanged = (event: Event) => {
    const criteriaKey = (event.target as HTMLInputElement).value;

    let nextQueryDefinition = null;
    if (criteriaKey === QUERY_DEFINITION_TYPES.SELECT_ALL) {
      nextQueryDefinition = {
        type: QUERY_DEFINITION_TYPES.SELECT_ALL,
      } as ISelectAllQueryDefinitionApi;
    } else if (criteriaKey === QUERY_CRITERIA_NAMES.SAVED_SELECTIONS) {
      // todo hevo should use query definition type directly, but must change all over
      nextQueryDefinition = {
        type: QUERY_DEFINITION_TYPES.PERSISTED_SELECTION,
      } as IPersistedQueryDefinitionApi;
    } else if (criteriaKey === QUERY_CRITERIA_NAMES.GEOMETRIES) {
      nextQueryDefinition = {
        type: QUERY_DEFINITION_TYPES.SPATIAL,
      } as IPersistedQueryDefinitionApi;
    } else {
      // criteriaKey will correspond to the attribute name
      const attribute = (attributes || []).find((a) => a.name === criteriaKey);

      if (attribute) {
        // todo hevo When string values are to be supported, consider datatype.
        nextQueryDefinition = {
          type: QUERY_DEFINITION_TYPES.ATTRIBUTE_RANGE,
          name: criteriaKey,
        } as IAttributeRangeQueryDefinitionApi;
        if (!isAttributeCategorical(criteriaKey, attributeSettings) && attribute.range) {
          nextQueryDefinition.fromValue = attribute.range[0];
          nextQueryDefinition.toValue = attribute.range[1];
        }
      }
    }

    setSelectedQueryAttribute && setSelectedQueryAttribute(nextQueryDefinition);
    onQueryDefinitionChanged(nextQueryDefinition);
  };

  /**
   * Finds all names of the saved selections (savedQueries)
   */
  const getPersitedSelectionCriteriaValues = useCallback(
    (): Array<IWorkspaceOptionAttributeValue> => {
      const savedSelectionCriteriaValues = (savedQueries || []).map(({ id, name }) => {
        return { value: id, displayName: name };
      });
      return savedSelectionCriteriaValues;
    },
    [savedQueries],
  );

  /**
   * Finds all names of attributes to be shown as categorical (i.e. as a dropdown)
   */
  const getAttributeOptionValues = useCallback(
    (attributeName: string): Array<IWorkspaceOptionAttributeValue> => {
      if (isAttributeCategorical(attributeName, attributeSettings)) {
        return getCategoricalAttributeValues(itemId, attributeName);
      }
      return null;
    },
    [itemId, attributeSettings],
  );

  /**
   * Restores visualization settings for the current item based on the stashed settings.
   */
  const restoreVisualizationSettings = () => {
    if (stashedVisualizationSettings) {
      const { dataGradient, drawnData } = stashedVisualizationSettings;
      const colorRange = dataGradient && dataGradient.colorRange ? dataGradient.colorRange : undefined;
      const { gradientAttributeName, gradientSettings } =
        dataGradient || ({} as Partial<NonNullable<IDrawnDataGradient>>);
      const { edgeColor, surfaceColor, representation, pointSize } =
        drawnData || ({} as Partial<NonNullable<IDrawnDataItem>>);

      QueryUtils.restoreDisplayProperties(
        itemId,
        edgeColor,
        surfaceColor,
        representation,
        gradientSettings,
        gradientAttributeName,
        pointSize,
        colorRange,
      );
    }

    // remove any shown selection results
    if (queryDefinition && queryDefinition.type === QUERY_DEFINITION_TYPES.PERSISTED_SELECTION) {
      const { queryId } = queryDefinition as IPersistedQueryDefinitionApi;
      if (queryId) {
        hideSelectionResult(queryId);
      }
    }
  };

  // Reset visualization settings when unmounting. Must listen for all states/pros used in restoreVisualizationSettings
  // Wrap in useCallback to make the useEffect work without running on evry re-render
  const onUnMounting = useCallback(restoreVisualizationSettings, [
    stashedVisualizationSettings,
    queryDefinition,
    itemId,
  ]);
  useEffect(
    () => {
      return () => {
        onUnMounting();
      };
    },
    [onUnMounting],
  );

  const geometryItems = useMemo(
    () => {
      const geoms = workspaceGeometries.filter(
        (geoItem) =>
          geoItem.itemType === EGeometryItemTypes.POLYGON || geoItem.itemType === EGeometryItemTypes.MULTI_POLYGON,
      );
      return geoms || [];
    },
    [workspaceGeometries],
  );

  /**
   * Add queries and geometries to the querySettings whenever
   * savedQueries or geometryItems change their values.
   */
  const queryCriteriaSettings = useMemo(
    () => {
      const newQCritSts = [...attributeSettings];

      if (
        savedQueries &&
        savedQueries.length > 0 &&
        newQCritSts.find((q) => q.name === QUERY_CRITERIA_NAMES.SAVED_SELECTIONS) === undefined
      ) {
        newQCritSts.push({ name: QUERY_CRITERIA_NAMES.SAVED_SELECTIONS, isCategorical: true });
      }
      if (
        geometryItems &&
        geometryItems.length > 0 &&
        newQCritSts.find((q) => q.name === QUERY_CRITERIA_NAMES.GEOMETRIES) === undefined
      ) {
        newQCritSts.push({ name: QUERY_CRITERIA_NAMES.GEOMETRIES, isCategorical: true });
      }
      return newQCritSts;
    },
    [attributeSettings, geometryItems, savedQueries],
  );

  /**
   * Add queries and geometries to the queryCriterias whenever
   * savedQueries or geometryItems change their values.
   * Disabled eslint warning for infinite loop in useEffect.
   */
  const queryCriterias = useMemo(
    () => {
      const newQCrit = [...attributes];

      if (
        savedQueries &&
        savedQueries.length > 0 &&
        newQCrit.find((q) => q.name === QUERY_CRITERIA_NAMES.SAVED_SELECTIONS) === undefined
      ) {
        newQCrit.push({ name: QUERY_CRITERIA_NAMES.SAVED_SELECTIONS, dataType: EAttributeDataTypes.STRING });
      }
      if (
        geometryItems &&
        geometryItems.length > 0 &&
        newQCrit.find((q) => q.name === QUERY_CRITERIA_NAMES.GEOMETRIES) === undefined
      ) {
        newQCrit.push({ name: QUERY_CRITERIA_NAMES.GEOMETRIES, dataType: EAttributeDataTypes.STRING });
      }
      return newQCrit;
    },
    [attributes, geometryItems, savedQueries],
  );

  const selectedCriteria = useMemo(
    () => {
      if (!queryDefinition) {
        return undefined;
      }
      if (isAttributeQuery(queryDefinition)) {
        const { name: attributeName } = queryDefinition as IAttributeQueryDefinitionApi;
        return attributeName;
      }
      if (isSpatialQuery(queryDefinition)) {
        return QUERY_CRITERIA_NAMES.GEOMETRIES;
      }
      if (isPersistedSelectionQuery(queryDefinition)) {
        return QUERY_CRITERIA_NAMES.SAVED_SELECTIONS;
      }
      const { type } = queryDefinition;
      return type;
    },
    [queryDefinition],
  );

  const attributesLoading = useMemo(
    () => {
      const layerLoading = isLayerLoading(item, itemDrawnDatas);
      if (layerLoading) {
        return layerLoading;
      } else if (item.isTiled) {
        return loadingFieldStatistics;
      }
      return layerLoading;
    },
    [item, itemDrawnDatas, loadingFieldStatistics],
  );

  const isTiled = useMemo(
    () => {
      if (!item) {
        return false;
      }
      return item.isTiled;
    },
    [item],
  );

  const canPreviewQuery = useMemo(
    () => {
      return isQueryComplete && !isTiled;
    },
    [isQueryComplete, isTiled],
  );

  return (
    <>
      {attributesLoading && (
        <MmgPanelSubsection>
          <CircularProgress />
        </MmgPanelSubsection>
      )}

      {!attributesLoading && (
        <>
          <MmgQueryCriteriaSelector
            label={selectCriteriaLabel}
            placeholder={selectCriteriaPlaceholder}
            queryCriterias={queryCriterias}
            allowSelectAll={allowSelectAll}
            selectAllLabel={selectAllLabel}
            selectedCriteria={selectedCriteria}
            onSelectedCriteriaChanged={onSelectedCriteriaChanged}
          />
          <MmgQueryCriteriaConfiguration
            attributes={attributes}
            attributeSettings={queryCriteriaSettings}
            initialQueryDefinition={queryDefinition}
            onQueryDefinitionChanged={onQueryDefinitionChanged}
            getAttributeOptionValues={getAttributeOptionValues}
            getPersistedSelectionCriteriaValues={getPersitedSelectionCriteriaValues}
            canPreviewQuery={canPreviewQuery}
            onPreviewQuery={!isTiled && onPreviewQueryDefinition}
          />
        </>
      )}
    </>
  );
};
