import { ICapabilities } from '..//models/IProject';
import { PlatformProxy } from '../proxies/PlatformProxy';
import { HttpErrorHandler } from './http-utils';

const getTenantCapabilities = (tenantId): Promise<ICapabilities> => {
  return PlatformProxy.getTenantCapabilites(tenantId)
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch((error) => HttpErrorHandler('Failed to get configuration.', error));
};

const self = {
  getTenantCapabilities,
};

export default self;
