import { useEffect } from 'react';
import { t } from '../../translations/i18n';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgConnectedMeshCreateOperationConfiguration } from './mesh-create-operation-configuration';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { store } from '../../store/store';
import { EWorkspaceQueryActionType } from '../../store/actions/WorkspaceQueryActionType';
import { EWorkspaceVariableActionType } from '../../store/actions/WorkspaceVariableActionType';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { useParams } from 'react-router-dom';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

/**
 * @name MmgConnectedMeshCreateConfigurePanel
 * @summary Allows configuration of a new mesh
 *
 * @param props
 */
export const MmgConnectedMeshCreateConfigurePanel = () => {
  const { workspaceId, projectId } = useParams();
  // const workspaceGeometries = useSelector(WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries);
  
  const { goBackToReferrer } = useNavigateBack();

  // When an operation is sucessfully submitted we go back
  const onOperationSubmitSuccess = (execute: boolean) => {
    //if the operation was submitted to generate the mesh, we go back to default tab.
    const omitHash = execute;
    goBackToReferrer(omitHash);
  };

  // Make sure no meshes or variables are shown in the viewer when this component is mounting.
  // Whatever geometries are currently shown, will be kept visibile
  useEffect(() => {
    store.dispatch({ type: EWorkspaceVariableActionType.HIDE_ALL });
    store.dispatch({ type: EWorkspaceMeshActionType.HIDE_ALL });
    store.dispatch({
      type: EWorkspaceQueryActionType.SELECTIONS_HIDE_TEMPORARELY,
    });
  }, []);

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={t('MESH_CONFIGURATION_NEW_MESH_PANEL')} />
      </MikeStickyPanelHeaderContainer>
      <MmgConnectedMeshCreateOperationConfiguration
        projectId={projectId}
        workspaceId={workspaceId}
        onOperationSubmitSuccess={onOperationSubmitSuccess}
      />
    </MikeStickyPanel>
  );
};
