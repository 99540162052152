/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { t } from '../../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../../app/routes';
import CircularProgress from '@mui/material/CircularProgress';
import { MmgGroup } from '../../../shared/groups/group';
import { CardSummaryStyle } from '../..//../shared/cards/item-card';
import { MmgPanelSubsection } from '../..//../shared/panels/panel-subsection';
import { MmgMeshOperationGeometrySummaryCard } from './mesh-operation-geometry-summary-card';
import { MmgOperationConfigurationSummary } from '../..//../operations/operation-configuration-summary';
import WorkspaceMeshSelectors from '../../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceGeometrySelectors from '../../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceVariableSelectors from '../../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceQueryUtils from '../../../store/selectors/WorkspaceQueryUtils';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { IWorkspaceEnrichedMesh } from '../../../models/IMeshes';
import { submitCreateMeshOperation } from '../mesh-configuration-util';
import { useGroupedAndSortedGeometryConfigurations } from '../useGroupedAndSortedGeometryConfigurations';
import { useAsyncLoadCreateMeshConfiguration } from '../useAsyncLoadCreateMeshConfiguration';
import { useIsMounted } from '../../../shared/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { IProject } from '../../../models/IProject';
import { IGlobalState } from '../../../store/reducers';
import { MikeStickyPanelBottomActions } from '../../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../../shared-components/mike-button';
import { css } from '@emotion/react';

type MeshOperationSummaryProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
};

/**
 * @name MmgConnectedMeshOperationSummary
 * @summary Shows a summary of the current mesh configuration.
 *
 * @param props
 */
export const MmgConnectedMeshOperationSummary = (props: MeshOperationSummaryProps) => {
  const navigate = useNavigate();
  const { workspaceId, projectId, meshId } = props;
  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const mesh: IWorkspaceEnrichedMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const workspaceQueries: Array<IWorkspaceQuery> = useSelector(
    (state: IGlobalState) => state.WorkspaceQueryReducer.workspaceQueries,
  );
  const geometries: Array<IWorkspaceEnrichedGeometry> = useSelector(
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries,
  );
  const workspaceVariables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );
  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);

  const isMounted = useIsMounted();

  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  const onEditMesh = () => {
    navigate(getRouteByPath(ROUTES.meshEditConfigurePanel.path, { meshId: mesh.id, workspaceId, projectId }, ROUTES.workspace.path)); 
  };

  const {
    createMeshConfiguration,
    setCreateMeshConfiguration,
    createMeshConfigurationLoading,
    createMeshConfigurationLoadingFailed,
  } = useAsyncLoadCreateMeshConfiguration(workspaceId, meshId);

  const onChildOperationDelete = (operationId: string) => {
    // find any queries related to the input item of the operation to remove
    const operationToRemove = createMeshConfiguration.childOperations.find(({ id }) => id === operationId);
    const itemId = operationToRemove.inputIds[0]; // currently we only support one input item  per operation
    const queryIds = workspaceQueries
      ? WorkspaceQueryUtils.filterQueriesByTargetItem(workspaceQueries, itemId).map(({ id }) => id)
      : [];

    // remove the the operation and operations having related queries as input
    const updatedChildOperations = createMeshConfiguration.childOperations.filter(({ id, inputIds }) => {
      return id !== operationId && queryIds.indexOf(inputIds[0]) === -1;
    });

    const updatedCreateMeshOperation = {
      ...createMeshConfiguration,
      childOperations: updatedChildOperations,
    };

    return submitCreateMeshOperation(workspaceId, mesh.id, updatedCreateMeshOperation, false).then(() => {
      if (isMounted()) {
        setCreateMeshConfiguration(updatedCreateMeshOperation); // todo hveo could it be done before?? Do we need spinners??? Look in legacy - how was it done???
      }
    });
  };

  const { childOperations: childConfigurations } = createMeshConfiguration || ({} as IOperationConfiguration);

  const groupedGeometryConfigurations = useGroupedAndSortedGeometryConfigurations(
    childConfigurations,
    geometries,
    workspaceQueries,
  );

  if (!mesh) {
    return <></>;
  }

  if (createMeshConfigurationLoadingFailed) {
    return (
      <MmgPanelSubsection>
        <p>{t('FAILED_TO_LOAD_PREVIOUS_MESH_OPERATIONS')}</p>
      </MmgPanelSubsection>
    );
  }

  return (
    <>
      <MmgGroup groupName={t('MESH_BASIC_CONFIGURATION')} canBeHidden={false}>
        {createMeshConfigurationLoading ? (
          <MmgPanelSubsection>
            <CircularProgress />
          </MmgPanelSubsection>
        ) : (
          <section css={css`${CardSummaryStyle} ${CardSummaryStyle}`}>
            {createMeshConfiguration && (
              <MmgOperationConfigurationSummary
                key={createMeshConfiguration.id}
                showOperationName={false}
                operationConfiguration={createMeshConfiguration}
              />
            )}
          </section>
        )}
      </MmgGroup>

      <MmgGroup groupName={t('MESH_MEMBERS_CONFIGURATION')} canBeHidden={false}>
        {createMeshConfigurationLoading ? (
          <MmgPanelSubsection>
            <CircularProgress />
          </MmgPanelSubsection>
        ) : (
          createMeshConfiguration &&
          groupedGeometryConfigurations &&
          Object.keys(groupedGeometryConfigurations).map((geometryId) => {
            const { geometry, geometryConfiguration, queryConfigurations } = groupedGeometryConfigurations[geometryId];

            const inputGeometries = [geometry];

            return (
              <MmgMeshOperationGeometrySummaryCard
                key={geometryConfiguration.id}
                geometryConfiguration={geometryConfiguration}
                queryConfigurations={queryConfigurations}
                showOperationName={true}
                inputGeometries={inputGeometries}
                showInputDescriptions={false}
                onOperationDelete={onChildOperationDelete}
                workspaceVariables={workspaceVariables}
                workspaceQueries={workspaceQueries}
              />
            );
          })
        )}
      </MmgGroup>
      {canUpdateWorkspace &&
        createMeshConfiguration && (
          <MikeStickyPanelBottomActions>
            <MikeButton variant="contained" fullWidth disabled={createMeshConfigurationLoading} onClick={onEditMesh}>
              {t('MESH_EDIT_CONFIGURATION')}
            </MikeButton>
          </MikeStickyPanelBottomActions>
        )}
    </>
  );
};
