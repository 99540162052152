import { store } from '../../store/store';
import { IGlobalState } from '../../store/reducers';
import { IMmgConfiguration } from '../../managers/ConfigurationManager';
import { MmgConnectedBreadcrumbs } from '../../shared/breadcrumbs/breadcrumbs';
import MeshBuilder from '../../icons/MeshGenerator.svg?react';
import { MmgConnectedSupport } from '../support/support';
import { t } from '../../translations/i18n';
import { hideBlueDotOnTopbarAlert } from '../../store/actions/notification';
import { useSelector } from 'react-redux';
import { IUserState } from '../../store/reducers/UserReducer';
import { IUser } from '../../models/IUser';
import MikeProgressSpinner, { IProgressItem } from '../../shared-components/mike-topbar-progress-spinner';
import MikeNotificationContainer, { INotification } from '../../shared-components/mike-notification-list';
import { IApplications } from '../../shared-components/mike-subscribed-apps/model/IApplications';
import MikeSubscribedApps from '../../shared-components/mike-subscribed-apps';
import MikeTopbar from '../../shared-components/mike-topbar';
import MikeLogin from '../../shared-components/mike-login';
import MikeHomeButton from '../../shared-components/mike-home-button';
import { useMemo } from 'react';
import { IProject } from '../../models/IProject';

export interface AuditCompareRouteParams {
  id: string;
  workspaceId: string;
}

type TopbarProps = {
  readonly configuration: IMmgConfiguration;
  progressItems: IProgressItem[];
  notifications: INotification[];
  hideBlueDot: boolean;
  applications: IApplications;
  loadingApplications: boolean;
};

/**
 * @name MmgTopbar
 * @summary Topbar container that implements <MikeTopbar />.
 * @param props
 * @description
 * This component handles login, breadcrumbs and the app selector.
 * It also handles bad logins, redirecting to the error page.
 */
export const MmgTopbar = (props: TopbarProps) => {


  const project: IProject  | null = useSelector((state: IGlobalState) => state.ProjectReducer.project);

  const projectId = useMemo(() => {
    if (project){
      return project.id
    }
  }, [project]);

  const user: IUser = useSelector((state: IGlobalState) => (state.UserReducer as IUserState).user);

  const onHandleNotificationClickAway = () => {
    store.dispatch(hideBlueDotOnTopbarAlert(true));
  };

  const getSubscribedApps = () => {
    return (
      <MikeSubscribedApps
        currentAppName={t('APP_NAME')}
        applications={props.applications}
        projectId={projectId}
        loading={props.loadingApplications}
        tenantId={user ? user.tenantId : ''}
      />
    );
  };

  const getHomeButton = () => {
    const { configuration } = props;

    return (
      configuration &&
      configuration.cloudHomeUri && (
        <MikeHomeButton
          loggedIn={true}
          hrefLink={user ? configuration.cloudHomeUri + '?tenantId=' + user.tenantId : configuration.cloudHomeUri}
        />
      )
    );
  };

  const getSupportButton = () => {
    return <MmgConnectedSupport />;
  };

  const getNotificationsDropdown = () => {
    const { notifications, hideBlueDot } = props;

    return (
      <MikeNotificationContainer
        showDot={!hideBlueDot}
        notifications={notifications}
        tooltipPosition={{ top: 0, left: -10 }}
        clickAwayCallback={onHandleNotificationClickAway}
      />
    );
  };

  const getProgressActions = () => {
    const { progressItems } = props;
    return <MikeProgressSpinner progressItems={progressItems} />;
  };

  const getBreadcrumb = () => {
    return <MmgConnectedBreadcrumbs />;
  };

  const getAppIcon = () => {
    return <MeshBuilder />;
  };

  return (
    <MikeTopbar
      actionsArea={getProgressActions()}
      alertArea={getNotificationsDropdown()}
      appIcon={getAppIcon()}
      appTitle={t('APP_NAME')}
      breadcrump={getBreadcrumb()}
      homeButton={getHomeButton()}
      supportButton={getSupportButton()}
      loginButton={<MikeLogin />}    
      position="relative"
      appsDropdown={projectId ? getSubscribedApps() : null}
    />
  );
};
