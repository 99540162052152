/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import ArrowDown from '../../icons/ArrowDown.svg?react';
import ArrowUp from '../../icons/ArrowUp.svg?react';
import ZoomIn from '../../icons/ZoomIn.svg?react';
import MeshNodeEdit from '../../shared/Icons/MeshNodeEdit.svg?react';
import { t } from '../../translations/i18n';
import { Skeleton } from '@mui/material';
import { PROP_KEYS } from '../../shared/operations/download/download-operations';
import { useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import { IWorkspace } from '../../models/IWorkspaces';
import { IProject } from '../../models/IProject';
import { IGlobalState } from '../../store/reducers';

export const SmallerCellStyle = css`
  max-width: 40px;
`;

interface IStep {
  vtp: string;
  values: Array<number>;
}

interface IProps {
  steps: Array<IStep>;
  editNodes: (vtp: string, area: number) => void;
  zoomToElement: (vtp: string, area: number) => void;
  fetching: boolean;
  meshPropNames: Array<string>;
  queryPage: number;
  stepsNumber: number;
  onChangePage: (newPage: number) => void;
  ordering: string;
  setOrder: (ord: string) => void;
  workspace: IWorkspace;
  isTiled: boolean;
}

interface IBodyProps extends IProps {
  project: IProject;
  page: number;
  rowsPerPage: number;
  canUpdateWorkspace: boolean;
}

const Body = (props: IBodyProps) => {
  const { steps, zoomToElement, editNodes, fetching, meshPropNames, queryPage, canUpdateWorkspace } = props;

  if (!steps) {
    return null;
  }
  if (steps && steps.length === 0 && !fetching) {
    return <div>{t('NO_RESULTS_QUERY')}</div>;
  }

  // Find index of the value that holds the area:
  const mmgAreaIndex = meshPropNames.indexOf(PROP_KEYS.MMG_AREA);

  // Find index of the queried value (area is appended to the query when is is not the query parameter itself)
  const standardNames = [PROP_KEYS.MMG_AREA];
  const valueIndex =
    meshPropNames.length < 2 ? mmgAreaIndex : meshPropNames.findIndex((name: string) => !standardNames.includes(name));

  const TableSkeleton = () => {
    return (
      <>
        {[...Array(10)].map((_num: number, index: number) => {
          return <Skeleton animation="wave" key={index} />;
        })}
      </>
    );
  };

  return fetching ? (
    <TableSkeleton />
  ) : (
    <>
      {steps.length > 0 &&
        valueIndex !== -1 &&
        steps.map((step, index) => (
          <TableRow key={index}>
            <TableCell component="th" scope="row" css={SmallerCellStyle} align="center">
              {`${queryPage * 10 + (index + 1)}`}
            </TableCell>
            <TableCell align="left">{step.values[valueIndex]}</TableCell>
            <TableCell align="right" css={SmallerCellStyle} style={{ cursor: 'pointer' }}>
              <ZoomIn
                onClick={() => {
                  const values = step.values;
                  zoomToElement(step.vtp, values[mmgAreaIndex]);
                }}
              />
            </TableCell>
            {canUpdateWorkspace && (
              <TableCell align="right" css={SmallerCellStyle} style={{ cursor: 'pointer' }}>
                <MeshNodeEdit
                  onClick={() => {
                    const values = step.values;
                    editNodes(step.vtp, values[mmgAreaIndex]);
                  }}
                />
              </TableCell>
            )}
          </TableRow>
        ))}
    </>
  );
};

export const MmgConnectedMeshQueryElementTable = (props: IProps) => {
  const project = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const { steps, setOrder, ordering, fetching, queryPage, stepsNumber, onChangePage, isTiled } = props;
  const canUpdateWorkspace = useMemo(
    () => {
      const canUpdate = project && project.capabilities && project.capabilities.canUpdateContent;
      return canUpdate && !isTiled;
    },
    [isTiled, project],
  );
  const [lastPage, setLastPage] = React.useState(null);

  useEffect(
    () => {
      const lPage = Math.round(stepsNumber / 10);
      setLastPage(lPage);
    },
    [stepsNumber],
  );

  if (steps && steps.length === 0 && !fetching) {
    return <div>{t('NO_RESULTS_QUERY')}</div>;
  }

  const Head = () => {
    if (!steps) {
      return null;
    }
    return (
      <TableRow>
        <TableCell
          onClick={() => {
            ordering === 'Ascending' ? setOrder('Descending') : setOrder('Ascending');
          }}
        >
          {ordering === 'Ascending' ? <ArrowUp /> : <ArrowDown />}
        </TableCell>
        <TableCell />
        <TableCell>{t('ZOOM_IN')}</TableCell>
        {canUpdateWorkspace && <TableCell>{t('EDIT_NODE')}</TableCell>}
      </TableRow>
    );
  };

  const handleChangePage = (_event, newPage) => {
    onChangePage(newPage);
  };

  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <Head />
        </TableHead>
        <TableBody>
          <Body {...props} page={queryPage} rowsPerPage={5} project={project} canUpdateWorkspace={canUpdateWorkspace} />
        </TableBody>
      </Table>

      <div style={{ maxWidth: '300px' }}>
        <TablePagination
          component="span"
          count={stepsNumber}
          rowsPerPageOptions={[]}
          rowsPerPage={10}
          page={queryPage}
          onPageChange={handleChangePage}
          slotProps={{actions:{
            previousButton: { disabled: queryPage === 0 },
            nextButton: { disabled: queryPage === lastPage }
          }}}          
        />
      </div>
    </>
  );
};
