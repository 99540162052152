import 'react';
import {
  addGraticule,
  IAddGraticuleHandler,
} from '../../../MikeVisualizer/lib/2d/MikeVisualizerGraticule';
import MikeVisualizerStore from '../../../MikeVisualizer/lib/store/MikeVisualizerStore';
import { getViewerModeSettingsFromStore } from '../../../store/selectors/WorkspaceViewerModeSelectors';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import { degreesToStringHDMS } from 'ol/coordinate';

const { getState } = MikeVisualizerStore;

/**
 * Contains a reference to the graticule / grid overlay, if available.
 */
export let graticuleHandler: IAddGraticuleHandler | undefined;

/**
 * This will add the graticule / grid overlay.
 */
export const makeGraticule = async (): Promise<boolean> => {
  const { showGridOverlay } = getViewerModeSettingsFromStore();
  if (graticuleHandler && graticuleHandler.removeFromMap) {
    graticuleHandler.removeFromMap();
  }
  const { epsgCode } = getState();
  if (epsgCode !== 4326) {
    console.info(`Grid overlay / graticule not supported for projection EPSG:${epsgCode}`);
    graticuleHandler = undefined;
    Promise.resolve(false);
    return;
  }
  try {    
      graticuleHandler = await addGraticule({
        visible: showGridOverlay,
        graticuleOptions: {
          wrapX: true,
          latLabelPosition: 0,
          latLabelStyle: new Text({
            font: '12px Calibri,sans-serif',
            textAlign: 'end', // default: 'start'
            fill: new Fill({ color: 'rgba(0,0,0,1)' }),
            stroke: new Stroke({ color: 'rgba(255,255,255,1)', width: 1 }),
          }),
          latLabelFormatter: (lat: number) => {
            const str = degreesToStringHDMS('NS', lat);
            return `  ${str}`;
          },
        },
        graticuleOutlineOptions: {
          wrapX: true,
        },
      });  
    Promise.resolve(true);
  } catch (error) {
    console.error(`Error adding graticule`, error);
    Promise.reject(false);
  }
};
