import { put, call, takeEvery, select } from 'redux-saga/effects';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import * as MeshMoveNodeOperationsProxy from '../../proxies/MeshMoveNodeOperationsProxy';
import { IGlobalState } from '../../store/reducers';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { t } from '../../translations/i18n';
import { IChangedNode } from '../../store/reducers/MeshMapToolReducer';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { ESelectForEditingModeIds } from '../../shared/edit-modes/select-for-editing-modes';
import { changedNodesToNodePatchFeatures } from '../../workspaces/sagas/meshEditHelpers';
import { IPatchFeature } from '../../models/IQueryCompleted';


const getMeshId = (state: IGlobalState) => state.MeshMapToolReducer.meshEditNodesTargetId;
export const getNodesToUpdate = (state: IGlobalState) => state.EditReducer.nodesToUpdate;

/**
 * Defines sagas for loading which items are exportable
 */
export default function* watchEditMeshes() {
  yield takeEvery(EWorkspaceMeshActionType.SAVE_NODE_UPDATES, handleSaveNodeUpdates);
}

function* handleSaveNodeUpdates(action) {
  const changedNodes: Array<IChangedNode> = yield select(getNodesToUpdate);
  const workspaceId  = action.data;
  const meshId = yield select(getMeshId);

  const nodePatchFeatures: Array<IPatchFeature> = changedNodesToNodePatchFeatures(changedNodes);
  try {
    yield call(MeshMoveNodeOperationsProxy.updateNodes, workspaceId, meshId, nodePatchFeatures);
    yield put({ type: EWorkspaceMeshActionType.SAVE_NODE_UPDATES_SUCCEEDED });
    yield put({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
    yield put({ type: 'toast/ADD/SUCCESS', toast: { text: t('MESH_EDIT_NODES_SUBMITTED') } });
  } catch {
    yield put({ type: EWorkspaceMeshActionType.SAVE_NODE_UPDATES_FAILED });
  } finally {
    yield put({
      type: EMapToolActionType.SET_SELECTION_MODE,
      data: ESelectForEditingModeIds.SELECT_BY_POINT,
    });
  }
}
