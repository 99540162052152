import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import { t } from '../../translations/i18n';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceQuery } from '../../models/IQueries';
import { IQueryDefinitionApi } from '../../models/IQueryDefinitions';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { MmgAttributeQueryConfiguration } from '../../queries/attribute-queries/query-configuration';
import { IGlobalState } from '../../store/reducers';

type GeometryAttributeQueryConfigurationProps = {
  projectId: string;
  workspaceId: string;
  geometryId: string;

  geometry?: IWorkspaceEnrichedGeometry;
  geometryAttributes?: Array<IWorkspaceAttribute>;
  geometryAttributeSettings?: Array<IWorkspaceAttributeSettings>;
  geometryDrawnData?: IDrawnDataItem;
  geometryQueries?: Array<IWorkspaceQuery>;

  onQueryDefinitionChanged?: (newQueryDefinition: IQueryDefinitionApi) => void;
};

/**
 * @name MmgConnectedGeometryAttributeQueryConfiguration
 * @param props
 * @summary Allows querying a geometry to allow including the resulting queryDefinition in geometry operations.
 * Currently querying attributes is only supported for one geometry at the time
 *
 */
export const MmgConnectedGeometryAttributeQueryConfiguration = (props: GeometryAttributeQueryConfigurationProps) => {
  const { projectId, workspaceId, geometryId, onQueryDefinitionChanged } = props;

  const getGeometrySelectorInstance = WorkspaceGeometrySelectors.makeGetGeometry();
  const getDrawnDataSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryDrawnData();
  const getGeometryAttributesSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryAttributes();
  const getGeometryAttributeSettingsSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryAttributeSettings();
  const getGeometryQueriesSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryQueries();

  const geometry = useSelector((state: IGlobalState) => getGeometrySelectorInstance(state, { geometryId }));
  const geometryAttributes =
    useSelector((state: IGlobalState) => getGeometryAttributesSelectorInstance(state, { geometryId })) || [];
  const geometryAttributeSettings =
    useSelector((state: IGlobalState) => getGeometryAttributeSettingsSelectorInstance(state, { geometryId })) || [];
  const geometryQueries = useSelector((state: IGlobalState) =>
    getGeometryQueriesSelectorInstance(state, { geometryId }),
  );
  const geometryDrawnData = useSelector((state: IGlobalState) => getDrawnDataSelectorInstance(state, { geometryId }));

  const callOnQueryDefinitionChanged = useCallback(
    (newQueryDefinition) => {
      if (onQueryDefinitionChanged) {
        onQueryDefinitionChanged(newQueryDefinition);
      }
    },
    [onQueryDefinitionChanged],
  );

  if (!geometryId) {
    return null;
  }

  const drawnData = geometryDrawnData ? [geometryDrawnData] : [];

  return (
    <MmgAttributeQueryConfiguration
      projectId={projectId}
      workspaceId={workspaceId}
      itemId={geometryId}
      item={geometry}
      attributes={geometryAttributes}
      attributeSettings={geometryAttributeSettings}
      itemDrawnDatas={drawnData}
      onQueryDefinitionChanged={callOnQueryDefinitionChanged}
      selectCriteriaLabel={t('SELECT_GEOMETRY_ATTRIBUTE_QUERY').toLowerCase()}
      allowSelectAll={true}
      savedQueries={geometryQueries}
    />
  );
};
