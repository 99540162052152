/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback } from 'react';
import { translateWithPrefix } from '../../translations/utils';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import WorkspaceDataItemSelectors, { IDataItem, IDataItemsArray } from '../../store/selectors/WorkspaceDataItemSelectors';
import { useSelector } from 'react-redux';
import { IParameterDescription } from '../../models/IOperationDescriptions';
import { IGlobalState } from '../../store/reducers';

const TitleFont = css`
  font-size: 14px;
`;

const ItemFont = css`
  font-size: 12px;
`;

const MmgMeshPostOperationDetails = ({ postOperation }) => {
  const dataItems: IDataItemsArray = useSelector((state: IGlobalState) =>
    WorkspaceDataItemSelectors.getWorkspaceDataItems(state),
  );

  const getParams = useCallback(
    () => {
      const getName = (id: string) => {
        const dataItem = dataItems.find((item: IDataItem) => item.id === id);
        if (dataItem !== undefined) {
          return dataItem.name;
        }
        return id;
      };

      const paramsNames = {};
      const paramsDescriptions = postOperation.parameterDescriptions;
      paramsDescriptions.forEach((p: IParameterDescription) => {
        const name = p.name;
        const value = postOperation.operationDefinition[name.charAt(0).toLowerCase() + name.slice(1)];
        if (p.valueType === 'Guid') {
          const nameWithoutId = name.endsWith('Id') ? name.slice(0, name.length - 2) : name;
          paramsNames[nameWithoutId] = getName(value);
        } else {
          paramsNames[name] = value;
        }
      });
      return paramsNames;
    },
    [dataItems, postOperation],
  );

  const names = getParams();
  const namesKeys = Object.keys(names);

  return (
    <>
      <MmgPanelSubsection>
        {
          <p css={TitleFont}>
            <b>{translateWithPrefix('OP', postOperation.operationType)}</b>
          </p>
        }

        {namesKeys &&
          namesKeys.map((key) => {
            return <p css={ItemFont}>{`${translateWithPrefix('PROP', key)}: ${names[key]}`}</p>;
          })}
      </MmgPanelSubsection>
    </>
  );
};

export default MmgMeshPostOperationDetails;
