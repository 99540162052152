/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../store';
import MapToolSelectors from '../../../store/selectors/MapToolSelectors';
import { ViewerToolsWithSubmenuStyle } from '../../../workspaces/viewer/tools/viewer-tools-styles';
import { MmgConnectedViewerModes } from '../../../workspaces/viewer/tools/viewer-modes';
import { MmgViewerToolReset } from '../../../workspaces/viewer/tools/viewer-tool-reset';
import { MmgViewerToolZoomIn } from '../../../workspaces/viewer/tools/viewer-tool-zoom-in';
import { MmgViewerToolZoomOut } from '../../../workspaces/viewer/tools/viewer-tool-zoom-out';
import { MmgConnectedViewerTool3DMapData } from '../../../workspaces/viewer/tools/viewer-tool-3d-map-data';
import { MmgConnectedViewerTool3DMove } from '../../../workspaces/viewer/tools/viewer-3D-move';
import { MmgConnectedViewerTool3DExaggeration } from '../../../workspaces/viewer/tools/viewer-tool-3d-exaggeration';
import { MmgConnectedViewerTool2DDraw } from '../../../workspaces/viewer/tools/viewer-tool-2d-draw';
import { MmgConnectedViewerTool2DVariableDraw } from '../../../workspaces/viewer/tools/viewer-tool-2d-variable-draw';
import { MmgConnectedViewerTool2DExtractFeatures } from '../../../workspaces/viewer/tools/viewer-tool-2d-extract-features';
import { MmgConnectedMeshTool2DSpatialSelection } from '../../../meshes/tools/mesh-tool-2d-spatial-selection';
import { MmgConnectedGeometryTool2DSpatialSelection } from '../../../geometries/tools/geometry-tool-2d-spatial-selection';
import { MmgConnectedViewerToolFeatureInfo } from '../../../workspaces/viewer/tools/viewer-tool-feature-info';
import { MmgConnectedViewerTool2DMeasure } from '../../../workspaces/viewer/tools/viewer-tool-measure';
import { MmgConnectedCommentTool2DAddComment } from '../../../comments/tools/comment-tool-2d-add-comment';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';;
import { Z_SCALES } from './viewer-tool-constants';
import { IMapToolState } from '../../../store/reducers/MapToolReducer';
import { MenuBarHeight, ToolbarBoxShadow, ToolsZindex } from '../../../shared/styles/styleConsts';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';
import MIKE_COLORS from '../../../MikeVisualizer/colors/mike-colors';
import mikeSharedTheme from '../../../shared/styles/mikeSharedTheme';
import { IGlobalState } from '../../../store/reducers';
const { enableOrientationBox, setViewerZScale } = MikeVisualizer;

const ViewerToolsStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${ToolsZindex};
  height: calc(100vh - ${MenuBarHeight});
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ViewerToolsContainerStyle = css`
  display: flex;
  flex-direction: column;
  box-shadow: ${ToolbarBoxShadow};
  padding: 0;
  list-style-type: none;
  background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
`;

const ViewerToolsSeparatorStyle = css`
  :before {
    content: '';
    display: flex;
    margin: ${mikeSharedTheme.spacing(1)} 0;
    width: 100%;
    border-top: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
  }
`;

/**
 * @name MmgConnectedViewerTools
 * @summary Container for all viewer tools.
 *
 */
export function MmgConnectedViewerTools() {
  const {
    measureToolAllowed,
    extractFeaturesAllowed,
    geometryDrawingAllowed,
    variableDrawingAllowed,
    featureInfoAllowed,
    addCommentAllowed,
    mapDataAllowed,
    exaggarationAllowed,
  } = useSelector((state: IGlobalState) => state.MapToolReducer as IMapToolState);
  const project = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  const geometrySpatialSelectionAllowed = useSelector(
    (state: IGlobalState) => state.GeometryMapToolReducer.geometrySpatialSelectionAllowed,
  );
  const meshSpatialSelectionAllowed = useSelector(
    (state: IGlobalState) => state.MeshMapToolReducer.meshSpatialSelectionAllowed,
  );
  const is2DModeRequired = useSelector((state: IGlobalState) => MapToolSelectors.is2DModeRequired(state));

  const { workspaceId, isLatLon } = useMemo(
    () => {
      if (workspace) {
        return {
          workspaceId: workspace.id,
          isLatLon: workspace.isLatLon,
        };
      }
      return { workspaceId: '', isLatLon: false };
    },
    [workspace],
  );

  const [viewerCurrentZScale, setCurrentViewerZScale] = useState(Z_SCALES.DEFAULT);
  const [viewerZScaleMultiplier, setViewerZScaleMultiplier] = useState(1);
  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  /**
   * Updates the viewer scale based on the 'isViewerSetToLatLon' flag.
   * latLon data require a lower scale to be visualized correctly, because their zIndex is in meters.
   *
   * @param isViewerSetToLatLon
   */
  const updateViewerZScale = useCallback(
    (isViewerSetToLatLon: boolean) => {
      if (isViewerSetToLatLon && viewerCurrentZScale !== Z_SCALES.LONG_LAT) {
        setCurrentViewerZScale(Z_SCALES.LONG_LAT);
        updateViewerZScaleMultiplier(viewerZScaleMultiplier, Z_SCALES.LONG_LAT);
      } else if (!isViewerSetToLatLon && viewerCurrentZScale !== Z_SCALES.DEFAULT) {
        setCurrentViewerZScale(Z_SCALES.DEFAULT);
        updateViewerZScaleMultiplier(viewerZScaleMultiplier, Z_SCALES.DEFAULT);
      }
    },
    [viewerCurrentZScale, viewerZScaleMultiplier],
  );

  /**
   * Sets the multiplier scale for all elements in the viewer.
   *
   * @param multiplier
   * @param zScale
   */
  const updateViewerZScaleMultiplier = (multiplier: number, zScale: number) => {
    setViewerZScale(multiplier * zScale);
    setViewerZScaleMultiplier(multiplier);
    store.dispatch({
      type: EMapToolActionType.CLEAR_OPEN_SUBMENU,
    });
  };

  /*
   * Enable orientation widget.
   * TODO: this needs work in MikeVisualizerNav so it looks pretty.
   */
  const _enableOrientationBox = () => {
    enableOrientationBox();
  };

  useEffect(
    () => {
      updateViewerZScale(isLatLon);
    },
    [isLatLon, updateViewerZScale],
  );

  if (!workspaceId) {
    return <></>;
  }

  return (
    <>
      <aside css={ViewerToolsStyle}>
        <ul css={ViewerToolsContainerStyle}>
          <li>
            <MmgViewerToolZoomIn />
          </li>

          <li>
            <MmgViewerToolZoomOut />
          </li>

          <li>
            <MmgViewerToolReset />
          </li>
          <li>
            <MmgConnectedViewerTool3DMove />
          </li>
          {measureToolAllowed && (
            <li css={ViewerToolsWithSubmenuStyle}>
              <MmgConnectedViewerTool2DMeasure />
            </li>
          )}

          {mapDataAllowed && (
            <li css={ViewerToolsWithSubmenuStyle}>
              <MmgConnectedViewerTool3DMapData disabled={is2DModeRequired} />
            </li>
          )}

          {exaggarationAllowed && (
            <li css={ViewerToolsWithSubmenuStyle}>
              <MmgConnectedViewerTool3DExaggeration
                viewerZScaleMultiplier={viewerZScaleMultiplier}
                viewerZScale={viewerCurrentZScale}
                updateViewerZScaleMultiplier={updateViewerZScaleMultiplier}
                disabled={is2DModeRequired}
              />
            </li>
          )}

          {/* TODO: enable later.Needs to be fixed in MikeVisualizerNav */}
          <li onClick={_enableOrientationBox} hidden>
            Enable nav cube
          </li>

          <li css={ViewerToolsSeparatorStyle} />

          {canUpdateWorkspace &&
            geometryDrawingAllowed && (
              <li css={ViewerToolsWithSubmenuStyle}>
                <MmgConnectedViewerTool2DDraw />
              </li>
            )}

          {canUpdateWorkspace &&
            variableDrawingAllowed && (
              <li css={ViewerToolsWithSubmenuStyle}>
                <MmgConnectedViewerTool2DVariableDraw />
              </li>
            )}

          {canUpdateWorkspace &&
            extractFeaturesAllowed && (
              <li>
                <MmgConnectedViewerTool2DExtractFeatures />
              </li>
            )}

          {geometrySpatialSelectionAllowed && (
            <li>
              <MmgConnectedGeometryTool2DSpatialSelection />
            </li>
          )}

          {meshSpatialSelectionAllowed && (
            <li>
              <MmgConnectedMeshTool2DSpatialSelection />
            </li>
          )}

          {featureInfoAllowed && (
            <>
              <li css={ViewerToolsSeparatorStyle} />

              <li>
                <MmgConnectedViewerToolFeatureInfo />
              </li>
            </>
          )}

          {addCommentAllowed && (
            <li>
              <MmgConnectedCommentTool2DAddComment />
            </li>
          )}
         
        </ul>
      </aside>

      <MmgConnectedViewerModes />
    </>
  );
}
