import { ILayerAction } from '../../../shared/layers/actions/layer-default-actions';
import { t } from '../../../translations/i18n';
import DefaultView from '../../../icons/DefaultView.svg?react';
import Settings from '../../../icons/Settings.svg?react';
import { EElementCategories } from '../../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../../shared/layers/layer-utils';
import { store } from '../../../store';
import { EWorkspaceMeshActionType } from '../../../store/actions/WorkspaceMeshActionType';
import MikeVisualizer from '../../../MikeVisualizer';
import { IViewerBounds } from '../../../MikeVisualizer/lib/IMikeVisualizerModels';
import { NavigateFunction } from 'react-router-dom';

const { setActiveLayer } = LayerUtils;
const { getBounds, resetCameraToBounds } = MikeVisualizer;

export const onZoomToLayer = (layerId: string, workspaceId: string) => {
  const bounds = getBounds(layerId);
  if (bounds && bounds.length > 3) {
    const viewerBounds = [bounds[0], bounds[1], bounds[2], bounds[3], 0, 0] as IViewerBounds;
    resetCameraToBounds(viewerBounds);
    store.dispatch({ type: EWorkspaceMeshActionType.CHECK_VIEW_BOUNDS, data: {workspaceId, wait: 1000} });
  }
};

export const getZoomToLayerAction = (layerId: string, workspaceId: string, disabled?: boolean): ILayerAction => {
  return {
    actionId: 'ZOOM_TO_LAYER',
    actionDisplayName: t('ZOOM_TO_LAYER'),
    actionIcon: <DefaultView />,
    onAction: () => onZoomToLayer(layerId, workspaceId),
    disabled,
  };
};

export const getPropertiesAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  elementCategory: EElementCategories,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onOpenLayerPressed = () => {
    setActiveLayer(elementCategory, layerId, workspaceId, projectId, navigate);
  };

  return {
    actionId: 'PROPERTIES',
    actionDisplayName: t('PROPERTIES'),
    actionIcon: <Settings />,
    onAction: onOpenLayerPressed,
    disabled,
  };
};
