/* eslint-disable react-refresh/only-export-components */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../store/store';
import { t } from '../../../translations/i18n';
import { NavigateFunction, useParams } from 'react-router';
import { getRouteByPath, ROUTES } from '../../../app/routes';
import MeshGenerate from '../../../shared/Icons/MeshGenerate.svg?react';
import {
  getDuplicateLayerAction,
  getDeleteLayerAction,
  getToggleVisibilityLayerAction,
  ILayerAction,
} from '../../../shared/layers/actions/layer-default-actions';
import { MmgConfirmDeleteMeshDialog } from '../../../meshes/details/mesh-delete-dialog';
import { MmgConfirmGenerateMeshDialog } from '../../../meshes/details/mesh-generate-dialog';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import { EElementCategories } from '../../../shared/panels/mesh-panel-constants';
import WorkspaceMeshesManager from '../../../managers/WorkspaceMeshesManager';
import WorkspaceMeshSelectors from '../../../store/selectors/WorkspaceMeshSelectors';
import { IDrawnDataItem } from '../../../models/IWorkspaceData';
import { IWorkspaceEnrichedMesh } from '../../../models/IMeshes';
import { IGlobalState } from '../../../store/reducers';

type LayerMeshSelectActionsProps = {
  layerId: string;
  mesh: IWorkspaceEnrichedMesh;
  canToggleLayerVisibility?: boolean;
  hiddenMeshIds?: Array<string>;
  updateUserSettingsOnToggle?: boolean;
  meshDrawnData?: IDrawnDataItem;
};

/**
 * @name MmgConnectedLayerMeshSelectActions
 * @summary Mesh layer actions, made for items present in the select list.
 *
 * @param props
 */
export function MmgConnectedLayerMeshSelectActions(props: LayerMeshSelectActionsProps) {
  const { projectId, workspaceId } = useParams();
  const {
    layerId,
    mesh,
    meshDrawnData: meshDrawnDataProp,
    hiddenMeshIds: hiddenMeshIdsProp,
    canToggleLayerVisibility = true,
    updateUserSettingsOnToggle = false,
  } = props;
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const drawnData = useSelector((state: IGlobalState) => getDrawnDataSelectorInstance(state, { meshId: layerId }));
  const meshDrawnData = meshDrawnDataProp ? meshDrawnDataProp : drawnData;
  const hiddenIds = useSelector((state: IGlobalState) => state.WorkspaceMeshReducer.hiddenWorkspaceMeshes);
  const hiddenMeshIds = hiddenMeshIdsProp ? hiddenMeshIdsProp : hiddenIds;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [generateConfirmOpen, setGenerateConfirmOpen] = useState(false);

  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);

  const isMissing = !mesh;

  const layerActions = getLayerActions(
    projectId,
    workspaceId,
    mesh,
    meshDrawnData,
    canToggleLayerVisibility,
    hiddenMeshIds,
    updateUserSettingsOnToggle,
    loadedData,
  );

  return (
    <>
      <MikeLayerActionMenu layerId={layerId} layerActions={layerActions} disabled={isMissing} />

      <MmgConfirmDeleteMeshDialog
        open={deleteConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        mesh={mesh}
        onClose={() => setDeleteConfirmOpen(false)}
      />

      <MmgConfirmGenerateMeshDialog
        open={generateConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        mesh={mesh}
        onClose={() => setGenerateConfirmOpen(false)}
      />
    </>
  );
}

const getLayerActions = (
  _projectId: string,
  _workspaceId: string,
  mesh: IWorkspaceEnrichedMesh,
  meshDrawnData: IDrawnDataItem,
  canToggleLayerVisibility: boolean,
  hiddenMeshIds: Array<string>,
  updateUserSettingsOnToggle: boolean,
  loadedData?: Array<string>,
) => {
  if (!mesh) {
    return [];
  }

  const layerId = mesh.id;

  const layerActions = [];

  if (canToggleLayerVisibility) {
    const toggleVisibilityAction = getToggleMeshVisibilityAction(
      layerId,
      hiddenMeshIds,
      updateUserSettingsOnToggle,
      // eslint-disable-next-line no-extra-boolean-cast
      !Boolean(meshDrawnData),
      loadedData,
      mesh.isTiled,
    );
    layerActions.push(toggleVisibilityAction);
  }

  return layerActions;
};

export const getToggleMeshVisibilityAction = (
  layerId: string,
  hiddenMeshIds: Array<string>,
  updateUserSettings: boolean,
  disabled?: boolean,
  loadedData?: Array<string>,
  isTiled = false, // meshes can be tiled
): ILayerAction => {
  const toggleVisibilityAction = getToggleVisibilityLayerAction(
    EElementCategories.MESH,
    layerId,
    hiddenMeshIds,
    disabled,
    updateUserSettings,
    loadedData,
    isTiled,
  );

  return toggleVisibilityAction;
};

export const getDuplicateMeshAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onMeshDuplicate = (meshId: string) => {
    return WorkspaceMeshesManager.duplicateMesh(workspaceId, meshId)
      .then(() => {
        const toast = {
          text: t('DUPLICATED', 1, {
            thing: t('MESH'),
          }),
        };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));  
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('DUPLICATE') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  const duplicateLayerAction = getDuplicateLayerAction(() => onMeshDuplicate(layerId), disabled);

  return duplicateLayerAction;
};

export const getDeleteMeshAction = (onMeshDelete: () => void, disabled?: boolean): ILayerAction => {
  const deleteLayerAction = getDeleteLayerAction(() => onMeshDelete(), disabled);

  return deleteLayerAction;
};

export const getGenerateMeshAction = (onMeshGenerate: () => void, disabled?: boolean): ILayerAction => {
  const generateMeshLayerAction = {
    actionId: 'GENERATE_MESH',
    actionDisplayName: t('GENERATE_MESH'),
    actionIcon: <MeshGenerate />,
    onAction: () => onMeshGenerate(),
    disabled,
  };

  return generateMeshLayerAction;
};
