import { EParameterSettingsSelectType, IParameterSettings } from '../../models/IParameterSettings';

/**
 * Defines custom settings for spatial selection parameters
 */
export const SPATIAL_SELECTION_CUSTOM_SETTINGS: {
  [param: string]: IParameterSettings;
} = {
  splitType: { selectType: EParameterSettingsSelectType.RADIO },
};
