import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgConnectedVariableSelectList } from '../../variables/select-list/variable-select-list';
import WorkspaceVariablesManager from '../../managers/WorkspaceVariablesManager';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgConfirmVariableMultipleDeleteDialog } from './variable-multiple-delete-dialog';
import { useIsMounted } from '../../shared/hooks/hooks';
import { EWorkspaceGeometryActionType } from '../../store/actions/WorkspaceGeometryActionType';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';

/**
 * @name MmgConnectedVariableMultipleDeletePanel
 * @summary Allows selecting multiple variables to delete.
 *
 */
export const MmgConnectedVariableMultipleDeletePanel = () => {
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate()

  const { hiddenWorkspaceVariables, selectedWorkspaceVariables } = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer,
  );

  const drawnWorkspaceVariables: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceVariablesByIds,
  );

  const variables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const isMounted = useIsMounted();

  const anyAvailableVariables = variables && variables.length > 0;
  const isSelectionValid = selectedWorkspaceVariables && selectedWorkspaceVariables.length > 0;

  const clearSelectedVariables = () => {
    store.dispatch({ type: 'workspace/variables/DESELECT_ALL' });
  };

  const onPanelExit = () => {
    window.history.back();
    clearSelectedVariables();
  };

  const onDeleteConfirmed = () => {
    setDeleteInProgress(true);
    return WorkspaceVariablesManager.deleteVariables(workspaceId, selectedWorkspaceVariables)
      .then(() => {
        const toast = {
          text: t('DELETED', 1, { thing: t('VARIABLE', 2) }),
        };
        store.dispatch({ type: 'toast/ADD/FAILED', toast });
        const workspacePanelPath = getRouteByPath(ROUTES.workspacePanel.path, {
          projectId, workspaceId
        }, ROUTES.workspace.path);
        navigate(workspacePanelPath);
        
      })
      .catch(() => {
        const variableNames = selectedWorkspaceVariables
          .map((sid) => {
            const variable = variables.find(({ id }) => id === sid);

            if (variable) {
              return variable.name;
            }
            return null;
          })
          .filter((variable) => variable);

        const toast = {
          text: t('FAILED_TO_DELETE_VARIABLE', variableNames.length, {
            variableNames: variableNames.join(', '),
          }),
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
      })
      .finally(() => {
        isMounted() && setDeleteInProgress(false);
      });
  };

  const onDialogCloseChange = (confirmed: boolean) => {
    if (confirmed) {
      onDeleteConfirmed();
    }

    setShowDeleteDialog(false);
  };

  // Make sure only variables are shown in the viewer when this component is mounting.
  // Whatever variables are currently shown, will be kept visibile.
  useEffect(() => {
    store.dispatch({
      type: EWorkspaceMeshActionType.HIDE_ALL,
    });
    store.dispatch({
      type: EWorkspaceGeometryActionType.HIDE_ALL,
    });
  }, []);

  // Clear selected variables on mount
  useEffect(() => {
    clearSelectedVariables();
  }, []);

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <MmgPanelHeader panelTitle={t('SELECT_VARIABLES_TO_DELETE_PANEL')} onPanelExit={onPanelExit} />
        </MikeStickyPanelHeaderContainer>
        <MmgGroup groupName={t('VARIABLE', 2)}>
          {anyAvailableVariables && (
            <MmgConnectedVariableSelectList
              variables={variables}
              disabledVariables={deleteInProgress ? variables.map(({ id }) => id) : []}
              hiddenVariables={hiddenWorkspaceVariables}
              drawnVariables={drawnWorkspaceVariables}
              selectedVariables={selectedWorkspaceVariables}
              isFailedSelectable={true}
            />
          )}

          {!anyAvailableVariables && (
            <MmgPanelSubsection>
              <p>{t('NO_VAR_TIP')}</p>

              <MikeButton variant="outlined" color="secondary" onClick={onPanelExit} fullWidth>
                {t('GOT_IT')}
              </MikeButton>
            </MmgPanelSubsection>
          )}

          <MikeStickyPanelBottomActions>
            <MikeButton variant="outlined" color="secondary" onClick={onPanelExit} disabled={deleteInProgress}>
              {t('CANCEL')}
            </MikeButton>

            <MikeButton
              disabled={!isSelectionValid || deleteInProgress}
              active={deleteInProgress}
              onClick={() => setShowDeleteDialog(true)}
            >
              {t('DELETE')}
            </MikeButton>
          </MikeStickyPanelBottomActions>
        </MmgGroup>
      </MikeStickyPanel>

      <MmgConfirmVariableMultipleDeleteDialog open={showDeleteDialog} onClose={onDialogCloseChange} />
    </>
  );
};
