import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { difference } from 'lodash-es';
import { usePrevious } from '../../shared/hooks/hooks';
import { store } from '../../store/store';
import useMount from 'react-use/lib/useMount';
import { Typography } from '@mui/material';
import { MmgPanel } from '../../shared/panels/panel';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgConnectedGeometrySelectList } from '../../geometries/select-list/geometry-select-list';
import { MmgPanelBottomActions } from '../../shared/panels/panel-bottom-actions';
import { MmgGeometryExtractStepper, GEOMETRY_EXTRACT_STEPS } from '../../geometries/create/geometry-extract-stepper';
import { ConfirmHeaderStyle } from '../../shared/confirm-containers/confirm-styles';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import WorkspaceGeometriesManager from '../../managers/WorkspaceGeometriesManager';
import { enableExtractFeatures } from '../../workspaces/viewer/tools/viewer-tool-2d-extract-features-util';
import { CONFIRM_PANEL_IDS } from '../../shared/confirm-containers/confirm-constants';
import { FeatureCollection } from 'geojson';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { useParams } from 'react-router-dom';
import MikeVisualizer from '../../MikeVisualizer';
import { IGlobalState } from '../../store/reducers';
import MikeButton from '../../shared-components/mike-button';

const { appendDrawVectorLayerGeojson, setDrawVectorLayerGeojson, onDrawnDataUpdated } = MikeVisualizer;

const ALLOWED_EXTRACTION_GEOMETRY_TYPES = ['MultiPolygon', 'Polygon'];

/**
 * @name MmgConnectedGeometryExtractSelectGeometriesConfirm
 * @summary Allows selecting polygons to be used for geometry extraction.
 *
 */
export const MmgConnectedGeometryExtractSelectGeometriesConfirm = () => {
  const { workspaceId } = useParams();
  const { hiddenWorkspaceGeometries, selectedWorkspaceGeometries } = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer,
  );
  const { viewerBaseMapId } = useSelector((state: IGlobalState) => state.ViewerModeReducer);
  const { activeConfirmPanelId } = useSelector((state: IGlobalState) => state.ConfirmPanelReducer);
  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );
  const geometries: Array<IWorkspaceEnrichedGeometry> = useSelector(
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries,
  );

  const [featureCollection, setFeatureCollection] = useState({} as FeatureCollection<any, any>);
  const [isLoadingGeometries, setIsLoadingGeometries] = useState(false);

  const previousSelectedGeometries = usePrevious(selectedWorkspaceGeometries);

  const handleNext = () => {
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.EXTRACT_DATA,
    });
  };

  const onPanelExit = () => {
    if (activeConfirmPanelId === CONFIRM_PANEL_IDS.GEOMETRY_MULTIPLE_EXTRACT) {
      store.dispatch({
        type: EWorkspaceActionType.EXIT_ACTIVE_PANEL,
      });
    } else {
      store.dispatch({
        type: EWorkspaceActionType.ACTIVE_PANEL_SET,
        panelName: CONFIRM_PANEL_IDS.GEOMETRY_MULTIPLE_EXTRACT,
      });
    }
  };

  const drawUpdateCallback = (features: FeatureCollection<any, any>) => {
    setFeatureCollection(features);
  };

  useMount(() => {
    // enable the extraction feature in the first mount
    async function enableExtraction() {
      await enableExtractFeatures(viewerBaseMapId);
    }
    enableExtraction();
  });

  // check if we have selected geometries, and update the state
  useEffect(
    () => {
      // while appending GeoJson of the selected geoms we wait
      const addedGeometryIds = difference(selectedWorkspaceGeometries, previousSelectedGeometries);

      const removedGeometryIds = difference(previousSelectedGeometries, selectedWorkspaceGeometries);

      if (removedGeometryIds.length) {
        const nextFeatureCollection = {
          ...featureCollection,
          features: featureCollection.features.filter(
            (feature) => removedGeometryIds.indexOf(feature.id.toString()) === -1,
          ),
        };

        setDrawVectorLayerGeojson(nextFeatureCollection);
      }

      if (addedGeometryIds.length) {
        setIsLoadingGeometries(true);
      }

      addedGeometryIds.forEach(async (geometryId: string) => {
        try {
          const geometryGeojson = await WorkspaceGeometriesManager.getGeometryGeojson(workspaceId, geometryId);

          // Set feature id to geometry id so it can be removed if the geometry is deselected.
          const geometryGeojsonWithFeatureId = {
            ...geometryGeojson,
            features: geometryGeojson.features.map((feature) => {
              return {
                ...feature,
                id: geometryId,
              };
            }),
          };
          appendDrawVectorLayerGeojson(geometryGeojsonWithFeatureId);
        } catch (error) {
          console.error('Failed to append geometry geojson', error);
        } finally {
          setIsLoadingGeometries(false);
        }
      });
    },
    [previousSelectedGeometries, featureCollection, selectedWorkspaceGeometries, workspaceId],
  );

  useEffect(() => {
    const unsubscribers = [];
    const unsubscribeOnDrawnDataUpdated = onDrawnDataUpdated(drawUpdateCallback);
    unsubscribers.push(unsubscribeOnDrawnDataUpdated);

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  const anyAvailableGeometries = geometries && geometries.length > 0;

  return (
    <>
      <MmgPanel>
        <header css={ConfirmHeaderStyle}>
          <h4>{t('SELECT_GEOMETRIES_TO_EXTRACT_PANEL')}</h4>
        </header>

        <MmgGeometryExtractStepper activeStep={GEOMETRY_EXTRACT_STEPS.SELECT} />

        {activeConfirmPanelId === CONFIRM_PANEL_IDS.GEOMETRY_MULTIPLE_EXTRACT && (
          <>
            <header css={ConfirmHeaderStyle}>
              <h4>{t('DEFINE_AREA_FOR_EXTRACTION')}</h4>
            </header>
            <MmgPanelSubsection>
              <Typography variant="h4">{t('DRAW_SELECTION')}</Typography>
              {t('GEOMETRY_DRAW_TOOLTIP')}
            </MmgPanelSubsection>
            <MmgGroup groupName={t('GEOMETRY', 2)}>
              {anyAvailableGeometries && (
                <MmgConnectedGeometrySelectList
                  geometries={geometries}
                  disabledGeometries={geometries.map(({ id, itemType }) => {
                    return !ALLOWED_EXTRACTION_GEOMETRY_TYPES.includes(itemType) ? id : null;
                  })}
                  hiddenGeometries={hiddenWorkspaceGeometries}
                  drawnGeometries={drawnWorkspaceGeometries}
                  selectedGeometries={selectedWorkspaceGeometries}
                />
              )}

              <MmgPanelBottomActions>
                <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
                  {t('CANCEL')}
                </MikeButton>

                <MikeButton onClick={handleNext} variant="contained" color="secondary" disabled={isLoadingGeometries}>
                  {t('NEXT')}
                </MikeButton>
              </MmgPanelBottomActions>
            </MmgGroup>
          </>
        )}
      </MmgPanel>
    </>
  );
};
