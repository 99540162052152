import React from 'react';
import { IParameterDescription, PARAMETER_VALUE_TYPES } from '../../models/IOperationDescriptions';
import { TextField } from '@mui/material';
import { InputFieldInfoTooltipCss, InputFieldInfoWrapperCss, MmgParameterInfoWrapper } from './parameter-info-wrapper';
import { toNumber } from 'lodash-es';
import { MmgParameterInputRadio } from './parameter-input-radio';
import { IParameterSettings, PARAMETER_SETTINGS_SELECTTYPE } from '../../models/IParameterSettings';
import { useField } from 'formik';

type ParameterInputSelectProps = {
  parameterKey: string;
  parameterDescription: IParameterDescription;
  name: string;
  value:any; // todo hevo only allow strings????
  label: string;
  placeholder: string;
  infoText?: string;
  helperText?: string;
  error?: boolean;
  customSettings?: IParameterSettings;
  getValueDisplayName?: (parameterValue: any) => string;
  onParameterChanged?: (param: string, val: any) => void;
};

/**
 * @name MmgParameterInputSelect
 * @param props
 * @summary A generic element that renders a select parameter and accepts a callback for when it change.
 * The 'values' of the parameterDescription will be used as select options.
 * If getValueDisplayName is passed in, it will be used for displaying the selectoptuons. Otherwise the value will be used directly
 *
 */
export const MmgParameterInputSelect = (props: ParameterInputSelectProps) => {
  const {
    parameterKey,
    name,
    value,
    label,
    helperText,
    infoText,
    placeholder,
    error,
    onParameterChanged,
    getValueDisplayName,
    parameterDescription,
    customSettings,
  } = props;

  const [field] = useField({
    name: name || parameterKey,
    value: value as number,
  });

  const { values, valueType, itemType } = parameterDescription;

  const onInputChanged = (event: React.ChangeEvent) => {
    field.onChange(event);

    const eventTarget = event.target as HTMLInputElement;
    const { id, value: newValue } = eventTarget;

    let val: string | number = newValue;

    if (valueType === PARAMETER_VALUE_TYPES.ENUM) {
      // For enums the api might require a number in some cases, so if the value is a number, we keep it as a number
      // If nothing is selected we keep the value as undefined
      // eslint-disable-next-line no-extra-boolean-cast
      const numberVal = Boolean(newValue) ? toNumber(newValue) : undefined;
      val = !isNaN(numberVal) ? numberVal : newValue;
    }

    if (onParameterChanged) {
      onParameterChanged(id, val);
    }
  };

  const getValueName = (val) => {
    if (getValueDisplayName) {
      return getValueDisplayName(val);
    }
    return val;
  };

  if (!parameterKey || !values) {
    return <></>;
  }

  let optionValues;

  if (itemType === 'Geometry') {
    optionValues = {};
    const sortedValues = Object.values(values).sort();
    sortedValues.forEach((val) => {
      const k = Object.keys(values);
      const filtered = k.find((item) => {
        return values[item] === val;
      });
      optionValues[filtered] = val;
    });
  }

  if (customSettings && customSettings.selectType === PARAMETER_SETTINGS_SELECTTYPE.RADIO) {
    return (
      <MmgParameterInputRadio
        parameterKey={parameterKey}
        name={name}
        value={value}
        parameterDescription={parameterDescription}
        label={label}
        placeholder={placeholder}
        infoText={infoText}
        getValueDisplayName={getValueDisplayName}
        error={error}
        helperText={helperText}
        onParameterChanged={onParameterChanged}
      />
    );
  }

  // Use dropdown as default
  return (
    <MmgParameterInfoWrapper infoText={infoText} cssProp={InputFieldInfoWrapperCss} tooltipCss={InputFieldInfoTooltipCss}>
      <TextField
        id={parameterKey}
        name={name}
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        value={value || value === 0 ? value : ''}
        onChange={onInputChanged}
        onBlur={field.onBlur}
        margin="dense"
        fullWidth
        select
        SelectProps={{
          native: true,
        }}
        error={error}
        helperText={helperText}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {optionValues
          ? Object.keys(optionValues).map((key) => {
              return (
                <option key={key} value={key}>
                  {getValueName(key)}
                </option>
              );
            })
          : Object.keys(values).map((key) => {
              return (
                <option key={key} value={key}>
                  {getValueName(key)}
                </option>
              );
            })}
      </TextField>
    </MmgParameterInfoWrapper>
  );
};
