import Step, { StepProps as MuiStepProps } from '@mui/material/Step';
import { StepLabel } from '@mui/material';

export interface IMmgStepProps extends MuiStepProps {
  label?: string;
  index?: number;
  last?: boolean;
}

export const MmgStep = (props: IMmgStepProps) => {
  const { label, children, ...other } = props;

  return (
    <Step {...other as any}>
      {children}
      {!children && <StepLabel>{label}</StepLabel>}
    </Step>
  );
};

MmgStep.muiName = 'MuiStep';
