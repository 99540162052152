import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import WorkspaceGeometriesManager from '../../managers/WorkspaceGeometriesManager';
import { store } from '../../store/store';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import MikeButton from '../../shared-components/mike-button';
import { useNavigate } from 'react-router-dom';

type ConfirmDeleteDialogProps = {
  projectId: string;
  workspaceId: string;
  geometry: IWorkspaceEnrichedGeometry;
  open: boolean;
  onClose: (confirmed: boolean) => void;
};

/**
 * Shows a confimation dialog for deleting a geometry and deletes the geometry if user confirms
 * @param props
 *
 * Todo hevo confirm dialogs for all layer types (meshes, geometry and variables) are avery similar. We could maybe like make something more reusable.
 */
export const MmgConfirmDeleteGeometryDialog = (props: ConfirmDeleteDialogProps) => {
  const navigate = useNavigate();
  const { open, onClose, geometry, projectId, workspaceId } = props;

  if (!geometry) {
    return;
  }

  const geometryId = geometry.id;

  const handleClose = () => {
    onClose(false);
  };

  const handleOk = () => {
    onGeometryDelete();
    closeDialog(true);
  };

  const handleCancel = () => {
    closeDialog(false);
  };

  const closeDialog = (confirmed: boolean) => {
    onClose(confirmed);
  };

  const onGeometryDelete = () => {
    return WorkspaceGeometriesManager.deleteGeometries(workspaceId, [geometryId])
      .then(() => {
        store.dispatch({
          type: 'workspace/geometries/DELETE_SUCCESS',
          workspaceGeometries: [geometryId],
        });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));   
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('DELETE') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      id="confirm-delete-geometry"
      aria-labelledby="delete-geometry-dialog-title"
      aria-describedby="delete-geometry-dialog-description"
    >
      <DialogTitle id="delete-geometry-dialog-title">
        {t('GEOMETRY_CONFIRM_DELETE_TITLE')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-geometry-dialog-description">
          {t('GEOMETRY_CONFIRM_DELETE_TEXT', 1, {
            name: geometry.name,
          })}{' '}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MikeButton onClick={handleCancel} variant="outlined" color="secondary">
          {t('CANCEL')}
        </MikeButton>
        <MikeButton onClick={handleOk} variant="contained" color="secondary" autoFocus>
          {t('DELETE')}
        </MikeButton>
      </DialogActions>
    </Dialog>
  );
};
