import { MikeLayerSelect, LayerSelectProps } from './mike-layer-select';
import LayerUtils from './layer-utils';

const { highlightLayer } = LayerUtils;

/**
 * @name MmgLayerSelect
 * @summary A wrapper / extensions of MikeLayerSelect, implementing some custom properties.
 *
 * @param props
 */
export const MmgLayerSelect = (props: LayerSelectProps) => {
  const onLayerMouseEnter = (layerId) => {
    highlightLayer(layerId);
  };

  return <MikeLayerSelect onLayerMouseEnter={onLayerMouseEnter} {...props} />;
};
