/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { translateWithPrefixAndSuffix } from '../../translations/utils';
import Info from '../../icons/Info.svg?react';
import { Typography } from '@mui/material';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';
import MikeTooltip from '../../shared-components/mike-tooltip';


type StatisticsProps = {
  statistics: Array<any>;
};

const StatEntryLabelStyle = css`
  flex-shrink: 0;
  margin-right: ${mikeSharedTheme.spacing(2)};
  text-transform: none;
  font-size: 14px;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
`;

const StatEntryValueStyle = css`
  font-size: 14px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const StatEntryPStyle = css`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 0;
  width: 100%;
`;

const StatsItemWithNoRenderStyle = css`
  width: 100%;
  border: 0;
  padding: 0;
  background: 0;
  font-family: inherit;
  text-transform: capitalize;
  display: flex;
  align-items: center;
`;

const ListContainerStyle = css`
  list-style-type: none;
`;

/**
 * @name MmgStatistics
 * @summary Generic element that given an array of key/value pairs of statistical data, it renders a generic UI for them.
 *
 *
 * @param props
 */
export const MmgStatistics = (props: StatisticsProps) => {
  const { statistics = [] } = props;

  const renderInfoIcon = (id: string) => {
    let translation;
    if (id) {
      translation = translateWithPrefixAndSuffix('PROP', 'HELPTEXT', id);
    }
    let formattedTranslation;
    if (translation) {
      formattedTranslation = translation.replace(/\s+/g, '');
    }

    return translation === undefined ||
      formattedTranslation === id ||
      translation === formattedTranslation ||
      formattedTranslation === undefined ? null : (
      <MikeTooltip title={translation}>
        <Info width={20} height={20} viewBox={'0 0 30 30'} style={{ marginBottom: '-6px' }} />
      </MikeTooltip>
    );
  };

  return (
    <>
      {statistics &&
        statistics.map(({ name, id, value }) => {
          return (
            <div key={id + value} css={ListContainerStyle}>
              <div css={StatsItemWithNoRenderStyle}>
                <div css={StatEntryPStyle}>
                  <Typography css={StatEntryLabelStyle} variant="body2">
                    {name}
                  </Typography>
                  <span css={StatEntryValueStyle} title={value}>
                    {value}
                  </span>
                </div>
                {renderInfoIcon(id)}
              </div>
            </div>
          );
        })}
    </>
  );
};
