/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { getShortDate } from '../../translations/utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import MeshGenerator from '../../icons/MeshGenerator.svg?react';
import Typography from '@mui/material/Typography';
import { CARD_SPACING, CARD_HEIGHT, CARD_WIDTH, CARD_MEDIA_HEIGHT } from './workspace-card-styles';
import { IWorkspaceOverview } from '../../models/IWorkspaces';
import { IProject } from '../../models/IProject';
import { t } from '../../translations/i18n';
import { useIsMounted } from '../../shared/hooks/hooks';
import { CardActions, Checkbox } from '@mui/material';
import { store } from '../../store';
import MIKE_COLORS from '../../shared/styles/mike-colors';
import mikeSharedTheme, { mikePalette } from '../../shared/styles/mikeSharedTheme';

/**
 * Solution for https://github.com/mui-org/material-ui/issues/15573
 * [Cannot add typography variants]
 *
 * @example <Typography className={subtext1}>Subtext (S)</Typography>
 * @example <p className={subtext1}>Text</p>
 */
const subtext1Style = css`
  &,
  &.MuiTypography-root {
    color: ${mikePalette.darkGrey.main};
    font-size: 0.875rem; /* 14px */
    line-height: 1.286rem; /* 18px */
  }
`;
const WorkspaceCardStyle = css`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: ${CARD_HEIGHT}px;
  width: ${CARD_WIDTH}px;
  margin: 0 ${CARD_SPACING} 0 0;
`;

const WorkspaceCardMediaStyle = css`
  display: block;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  opacity: 0.9;
  background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  max-height: ${CARD_MEDIA_HEIGHT}px;
`;

const WorkspaceCardActionStyle = css`
  margin-left: auto;
  flex-shrink: 0;
  flex-grow: 0;
`;

const WorkspaceCardActionContainerStyle = css`
  padding: 0 0 0 8px;
  flex-shrink: 0;
  flex-grow: 0;
`;
const WorkspaceCardContentStyle = css`
  display: block;
  padding: 2px;
  & > * {
    padding-bottom: ${mikeSharedTheme.spacing(0.5)};
  }

  & > *:last-child {
    padding-bottom: 0;
  }
`;

const WorkspaceCardTextStyle = css`
  flex-grow: 1;

  /* Visually align bottom & top text margins */
  padding-bottom: 0;
  margin-bottom: ${-mikeSharedTheme.spacing(1)};
`;

const WorkspaceCardEditTextStyle = css`
  flex-grow: 1;
  font-weight: bold;
  color: #00a4ec;
  /* Visually align bottom & top text margins */
  padding-bottom: 0;
  margin-bottom: ${-mikeSharedTheme.spacing(1)};
`;

type WorkspaceCardProps = {
  workspace: IWorkspaceOverview;
  project: IProject;
  projectSelectedForDelete?: Array<string>;
};

/**
 * @name MmgWorkspaceCard
 * @summary Shows a workspace in a card.
 *
 * @param props
 */
export const MmgWorkspaceCard = (props: WorkspaceCardProps) => {
  const { workspace, project, projectSelectedForDelete = [] } = props;
  const [elevation, setElevation] = useState(1);
  const isMounted = useIsMounted();

  const isEditAllowed = project && project.capabilities && project.capabilities.canUpdateContent;

  if (!workspace) {
    return <></>;
  }

  const workspaceRoutePath =
    workspace && workspace.projectId
      ? getRouteByPath(ROUTES.workspace.path, {
          projectId: workspace.projectId,
          workspaceId: workspace.id,
        })
      : '';

  const workspaceEditRoutePath =
    workspace && workspace.projectId
      ? getRouteByPath(ROUTES.workspaceEdit.path, {
          projectId: workspace.projectId,
          workspaceId: workspace.id,
        })
      : '';

  const workspaceName = (workspace && workspace.name) || t('UNTITLED');

  const handleWorkspaceDelete = (event) => {
    if (event.target.checked) {
      store.dispatch({
        type: 'project/WORKSPACE_DELETE_ADD',
        id: workspace.id,
      });
    } else {
      store.dispatch({
        type: 'project/WORKSPACE_DELETE_REMOVE',
        id: workspace.id,
      });
    }
  };

  return (
    <Card
      css={WorkspaceCardStyle}
      onMouseOver={() => isMounted() && setElevation(4)}
      onMouseOut={() => isMounted() && setElevation(1)}
      elevation={elevation}
    >
      <Link to={workspaceRoutePath} style={{ display: 'contents' }}>
        {workspace.image ? (
          <CardMedia
            component="div"
            image={workspace.image}
            title={workspaceName}
            css={WorkspaceCardMediaStyle}
          />
        ) : (
          <div css={WorkspaceCardMediaStyle}>
            <MeshGenerator
              style={{
                width: 100,
                height: 100,
              }}
              viewBox="0 0 40 40"
            />
          </div>
        )}
      </Link>

      <CardContent css={WorkspaceCardContentStyle}>
        <Link to={workspaceRoutePath} css={WorkspaceCardTextStyle}>
          <Typography>{project ? project.name : '...'}</Typography>

          <Typography>
            <b>{workspaceName}</b>
          </Typography>

          <Typography css={subtext1Style}>{getShortDate(workspace.updated)}</Typography>
        </Link>
      </CardContent>
      <CardActions css={WorkspaceCardActionContainerStyle}>
        {isEditAllowed && (
          <Link to={workspaceEditRoutePath} css={WorkspaceCardEditTextStyle}>
            <Typography style={{ fontWeight: 'bold', color: '#00A4EC' }}>Edit workspace</Typography>
          </Link>
        )}
        {isEditAllowed && (
          <div css={WorkspaceCardActionStyle}>
            <Checkbox onChange={handleWorkspaceDelete} checked={projectSelectedForDelete.includes(workspace.id)} />
          </div>
        )}
      </CardActions>
    </Card>
  );
};
