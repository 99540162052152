/**
 * Exposes generic workspace query REST endpoints to managers.
 *
 * @module WorkspaceQueryProxy
 * @version 2.0.0
 * @requires Proxy
 */
import { endpoints, paths } from './config';
import Proxy from './Proxy';
import { FeatureCollection } from 'geojson';
import {
  EQueryRelations,
  EQueryResultTypes,
  IAttributeQueryDefinitionRangeApi,
  IQueryDefinitionApi,
  ISelectionQueryApi,
} from '../models/IQueryDefinitions';
import { AxiosPromise } from 'axios';
import { IPropertyTableQuery } from '../models/IPropertyTableQuery';
import { IOperationMetadata } from '../models/IOperations';

const { meshAppService } = endpoints;
const { queries, workspaces } = paths;

/**
 * Create a generic query - backend only saves the queryId in DB, to execute the query call executeQuery
 *
 * @param type
 * @param relation
 * @param workspaceId
 * @param itemIds
 * @param name
 * @param resultType
 * @param spatialSelection
 * @param parameters
 * @param sourceItemId
 */
export const createQuery = (
  type: string,
  relation: EQueryRelations,
  workspaceId: string,
  itemIds: Array<string>,
  name: string,
  resultType: EQueryResultTypes,
  spatialSelection?: FeatureCollection<any, any>,
  parameters?: { [key: string]: number | boolean | string | object },
  sourceItemId?: string,
) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${queries}?name=${name}`, {
    type,
    relation,
    featureCollection: spatialSelection,
    targetItems: itemIds,
    resultType,
    ...(parameters || {}),
    sourceItemId,
  });

/**
 * Select an offset of and attribute - result is returned in QueryCompleted notification
 *
 * @param workspaceId
 * @param params
 */
export const createPropertyTableQuery = (workspaceId: string, params: IAttributeQueryDefinitionRangeApi) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${queries}/execute2`, params) as AxiosPromise<
    IPropertyTableQuery
  >;

/**
 * Select an offset of and attribute - result is returned in QueryCompleted notification
 *
 * @param workspaceId
 * @param params
 */
export const createSelectionQuery = (workspaceId: string, params: ISelectionQueryApi) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${queries}/execute2`, params) as AxiosPromise<
    IOperationMetadata
  >;

/**
 * Execute a generic query - result is returned in QueryCompleted notification
 *
 * @param workspaceId
 * @param queryId
 */
export const executeQuery = (workspaceId: string, queryId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${queries}/execute/${queryId}`);

// Note by: This endpoint has been used on geometries-query-criteria.tsx only but is now out commented
// Note by: Result was used as a map preview only but call can become expensive especially for large / tiled meshes
export const createSpatialQuery = (workspaceId: string, params) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${queries}/execute`, params) as AxiosPromise<
    IPropertyTableQuery
  >;

/**
 * Update a generic query via PATCH.
 *
 * @param workspaceId
 * @param queryId
 * @param name
 * @param queryDefinition Partial or complete query definition.
 */
export const patchQuery = (workspaceId: string, queryId: string, name: string, queryDefinition?: IQueryDefinitionApi) =>
  Proxy.http.patch(
    `${meshAppService}/${workspaces}/${workspaceId}/${queries}/${queryId}`,
    queryDefinition
      ? {
          name,
          queryDefinition,
        }
      : { name },
  );

/**
 * Delete a generic query
 *
 * @param workspaceId
 * @param queryId
 */
export const deleteQuery = (workspaceId: string, queryId: string) =>
  Proxy.http.delete(`${meshAppService}/${workspaces}/${workspaceId}/${queries}/${queryId}`);

/**
 * Get generic query
 *
 * @param workspaceId
 * @param queryId
 */
export const getQuery = (workspaceId: string, queryId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${queries}/${queryId}`);
