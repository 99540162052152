import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgConnectedGeometrySelectList } from '../../geometries/select-list/geometry-select-list';
import WorkspaceGeometriesManager from '../../managers/WorkspaceGeometriesManager';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgConfirmGeometryMultipleDeleteDialog } from './geometry-multiple-delete-dialog';
import { useIsMounted } from '../../shared/hooks/hooks';
import { EWorkspaceVariableActionType } from '../../store/actions/WorkspaceVariableActionType';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../shared-components/mike-button';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';

/**
 * @name MmgConnectedGeometryMultipleDeletePanel
 * @summary Allows selecting multiple geometries to delete.
 *
 */
export const MmgConnectedGeometryMultipleDeletePanel = () => {
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate()
  const { hiddenWorkspaceGeometries, selectedWorkspaceGeometries } = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer,
  );

  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );

  const geometries: Array<IWorkspaceEnrichedGeometry> = useSelector(
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries,
  );

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const anyAvailableGeometries = geometries && geometries.length > 0;
  const isSelectionValid = selectedWorkspaceGeometries && selectedWorkspaceGeometries.length > 0;
  const isMounted = useIsMounted();

  const clearSelectedGeometries = () => {
    store.dispatch({ type: 'workspace/geometries/DESELECT_ALL' });
  };

  const onPanelExit = () => {
    window.history.back();
    clearSelectedGeometries();
  };

  const onDeleteConfirmed = () => {
    setDeleteInProgress(true);
    return WorkspaceGeometriesManager.deleteGeometries(workspaceId, selectedWorkspaceGeometries)
      .then(() => {
        const toast = {
          text: t('DELETED', 1, { thing: t('GEOMETRY', 2) }),
        };
        store.dispatch({ type: 'toast/ADD/FAILED', toast });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));
      })
      .catch(() => {
        const geometryNames = selectedWorkspaceGeometries
          .map((sid) => {
            const geometry = geometries.find(({ id }) => id === sid);

            if (geometry) {
              return geometry.name;
            }
            return null;
          })
          .filter((geometry) => geometry);

        const toast = {
          text: t('FAILED_TO_DELETE_GEOMETRY', geometryNames.length, {
            geometryNames: geometryNames.join(', '),
          }),
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
      })
      .finally(() => {
        isMounted() && setDeleteInProgress(false);
      });
  };

  const onDialogCloseChange = (confirmed: boolean) => {
    if (confirmed) {
      onDeleteConfirmed();
    }

    setShowDeleteDialog(false);
  };

  // Make sure only geometries are shown in the viewer when this component is mounting.
  // Whatever geometries are currently shown, will be kept visibile.
  useEffect(() => {
    store.dispatch({
      type: EWorkspaceMeshActionType.HIDE_ALL,
    });
    store.dispatch({
      type: EWorkspaceVariableActionType.HIDE_ALL,
    });
  }, []);

  // Clear selected geometries on mount
  useEffect(() => {
    clearSelectedGeometries();
  }, []);

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <MmgPanelHeader panelTitle={t('SELECT_GEOMETRIES_TO_DELETE_PANEL')} onPanelExit={onPanelExit} />{' '}
        </MikeStickyPanelHeaderContainer>
        <MikeStickyPanelContent>
          <MmgGroup groupName={t('GEOMETRY', 2)}>
            {anyAvailableGeometries && (
              <MmgConnectedGeometrySelectList
                geometries={geometries}
                disabledGeometries={deleteInProgress ? geometries.map(({ id }) => id) : []}
                hiddenGeometries={hiddenWorkspaceGeometries}
                drawnGeometries={drawnWorkspaceGeometries}
                selectedGeometries={selectedWorkspaceGeometries}
                isFailedSelectable={true}
              />
            )}

            {!anyAvailableGeometries && (
              <MmgPanelSubsection>
                <p>{t('NO_GEOM_TIP')}</p>

                <MikeButton variant="outlined" color="secondary" onClick={onPanelExit} fullWidth>
                  {t('GOT_IT')}
                </MikeButton>
              </MmgPanelSubsection>
            )}
          </MmgGroup>
        </MikeStickyPanelContent>
        <MikeStickyPanelBottomActions>
          <MikeButton variant="outlined" color ="secondary" onClick={onPanelExit} disabled={deleteInProgress}>
            {t('CANCEL')}
          </MikeButton>

          <MikeButton
            variant="contained"
            color ="secondary" 
            disabled={!isSelectionValid || deleteInProgress}
            active={deleteInProgress}
            onClick={() => setShowDeleteDialog(true)}
          >
            {t('DELETE')}
          </MikeButton>
        </MikeStickyPanelBottomActions>
      </MikeStickyPanel>

      <MmgConfirmGeometryMultipleDeleteDialog open={showDeleteDialog} onClose={onDialogCloseChange} />
    </>
  );
};
