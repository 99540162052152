import MeshOperation from '../../../icons/MeshOperation.svg?react';
import MeshPlay from '../../../icons/MeshPlay.svg?react';
import { t } from '../../../translations/i18n';
import { ILayerAction, getDeleteLayerAction } from '../../../shared/layers/actions/layer-default-actions';
import { IOperationConfiguration } from '../../../models/IOperations';
import { submitMeshInterpolation } from '../mesh-interpolation-util';
import { IWorkspaceEnrichedMesh } from '../../../models/IMeshes';
import { getRouteByPath, ROUTES } from '../../../app/routes';
import { isLayerInProgress } from '../../../shared/layers/layer-utils';
import { store } from '../../../store';
import { NavigateFunction } from 'react-router-dom';

/**
 * @summary get the actions for the more menu for a single mesh interpolation operation
 * @param projectId
 * @param workspaceId
 * @param mesh
 * @param interpolationConfiguration
 * @param referringLocation
 * @param onInterpolationDelete
 * @param onInterpolationExecuted
 */
export const getInterpolationActions = (
  projectId: string,
  workspaceId: string,
  mesh: IWorkspaceEnrichedMesh,
  interpolationConfiguration: IOperationConfiguration,
  navigate: NavigateFunction,
  onInterpolationDelete: () => void,
  onInterpolationExecuted?: (result: Promise<IOperationConfiguration>) => void,
) => {
  if (!interpolationConfiguration) {
    return [];
  }

  const isMeshProcessing = isLayerInProgress(mesh);

  const layerActions = [];

  const editInterpolationAction = getEditMeshInterpolationAction(
    projectId,
    workspaceId,
    mesh,
    interpolationConfiguration,
    navigate,
    false,
  );
  layerActions.push(editInterpolationAction);

  if (interpolationConfiguration.state !== 'Outdated') {
    const executeInterpolationAction = getExecuteOperationAction(
      workspaceId,
      interpolationConfiguration,
      isMeshProcessing,
      onInterpolationExecuted,
    );
    layerActions.push(executeInterpolationAction);
  }

  const deleteInterpolationAction = getDeleteInterpolationAction(onInterpolationDelete, false);

  layerActions.push(deleteInterpolationAction);

  return layerActions;
};

export const getEditMeshInterpolationAction = (
  projectId: string,
  workspaceId: string,
  mesh: IWorkspaceEnrichedMesh,
  interpolationConfiguration: IOperationConfiguration,
  navigate: NavigateFunction,
  disabled: boolean,
): ILayerAction => {
  const onEditMeshInterpolations = () => {
    store.dispatch({
      type: 'workspace/meshes/SET_INTERPOLATION_TYPE',
      interpolationType: interpolationConfiguration.parameters.propertyName === 'Elevation' ? 'elevation' : 'other',
    });
    navigate(getRouteByPath(ROUTES.meshEditInterpolationPanel.path, { workspaceId, projectId, meshId: mesh.id,
      operationId: interpolationConfiguration.id }, ROUTES.workspace.path)); 
  };

  return {
    actionId: 'EDIT_INTERPOLATION',
    actionDisplayName: t('MESH_INTERPOLATION_EDIT_ACTION'),
    actionIcon: <MeshOperation />,
    onAction: () => onEditMeshInterpolations(),
    disabled,
  };
};

export const getExecuteOperationAction = (
  workspaceId: string,
  interpolationConfiguration: IOperationConfiguration,
  disabled: boolean,
  onInterpolationExecuted: (result: Promise<IOperationConfiguration>) => void,
): ILayerAction => {
  const onExecute = () => {
    const result = submitMeshInterpolation(workspaceId, interpolationConfiguration, true);

    if (onInterpolationExecuted) {
      onInterpolationExecuted(result);
    }
  };

  return {
    actionId: 'EXECUTE_INTERPOLATION',
    actionDisplayName: t('MESH_INTERPOLATION_EXECUTE'),
    actionIcon: <MeshPlay />,
    onAction: () => onExecute(),
    disabled,
  };
};

export const getDeleteInterpolationAction = (onInterpolationDelete: () => void, disabled?: boolean): ILayerAction => {
  const deleteLayerAction = getDeleteLayerAction(() => onInterpolationDelete(), disabled);

  return deleteLayerAction;
};

const self = {
  getInterpolationActions,
  getEditMeshInterpolationAction,
  getExecuteOperationAction,
  getDeleteInterpolationAction,
};

export default self;
