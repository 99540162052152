/**
 * Exposes reselect-selectors methods for variables.
 *
 * @module WorkspaceVariableSelectors
 * @version 1.0.0
 */

import { createSelector } from 'reselect';
import WorkspaceDataItemUtils from './WorkspaceDataItemUtils';
import ModelUtils from '../../managers/model-utils';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceVariable, IWorkspaceEnrichedVariable } from '../../models/IVariables';
import WorkspaceVariableOperationSelectors from '../../store/selectors/WorkspaceVariableOperationSelectors';
import { IOperationMetadata } from '../../models/IOperations';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { store } from '../store';
import { IGlobalState } from '../reducers';

interface IVariableId {
  variableId: string;
}

export const getWorkspaceVariables = (state: IGlobalState): Array<IWorkspaceVariable> =>
  state.WorkspaceVariableReducer.workspaceVariables;

export const simplyGetWorkspaceVariables = () => {
  const state: IGlobalState = store.getState() as IGlobalState;
  return getWorkspaceVariables(state);
};

export const getWorkspaceVariableById = (inId: string): IWorkspaceVariable | undefined => {
  return simplyGetWorkspaceVariables().find(({ id }) => id === inId);
};

const selectedWorkspaceVariableIds = (state: IGlobalState) => state.WorkspaceVariableReducer.selectedWorkspaceVariables;

const workspaceData = (state: IGlobalState): Array<IDrawnDataItem> => state.WorkspaceDataReducer.workspaceData;

// selects the variableId from props
const variableIdProp = (_state, props: IVariableId) => props.variableId;

/**
 * Enriches workspace variables with metadata from  latest operation
 */
const _getEnrichedWorkspaceVariables = createSelector(
  [WorkspaceVariableOperationSelectors.getLatestVariableOperations, getWorkspaceVariables],
  (operations: Array<IOperationMetadata>, variables: Array<IWorkspaceVariable>): Array<IWorkspaceEnrichedVariable> => {
    // todo hevo test!!!
    return WorkspaceDataItemUtils.getEnrichedDataItems(operations, variables, ELEMENT_CATEGORIES.VARIABLE) as Array<
      IWorkspaceEnrichedVariable
    >;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

/**
 * Selector to return sorted enriched workspace variables
 *
 * todo hevo Consider turn this around, to sort first - then enrich. Sortorder does not rely on anything from the enrichment
 * also consider not using the list of enriched items unleass needd. The entire list will change whenever just one of the items in the list changes.
 *
 */
const getSortedEnrichedWorkspaceVariables = createSelector([_getEnrichedWorkspaceVariables], (variables) => {
  if (!variables) {
    return [];
  }

  return ModelUtils.sortVariables(variables);
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Selector to return selected enriched workspace variables. Will be sorted
 * *todo hevo consider if these always need to be enriched. It might trigger too many re-renders
 */
const getSelectedEnrichedWorkspaceVariables = createSelector(
  [_getEnrichedWorkspaceVariables, selectedWorkspaceVariableIds],
  (variables, selectedIds) => {
    const selectedVariables = WorkspaceDataItemUtils.getDataItemsByIds(variables, selectedIds);

    return ModelUtils.sortVariables(selectedVariables);
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

/**
 * Selector to return workspace variables not having the status failed. Will be sorted.
 */
const getWorkspaceVariablesNotFailed = createSelector([_getEnrichedWorkspaceVariables], (variables) => {
  if (!variables) {
    return [];
  }

  const filteredVariables = WorkspaceDataItemUtils.getItemsNotFailed(variables);

  return ModelUtils.sortVariables(filteredVariables);
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Selector to return the workspace variable based on the variableId prop.
 */
const _getVariable = createSelector([_getEnrichedWorkspaceVariables, variableIdProp], (variables, variableId) => {
  if (!variables || !variableId) {
    return null;
  }
  return variables.find(({ id }) => variableId === id) || null;
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Returns an instance of a selector for getting the workspace variable based on the variableId prop.
 */
const makeGetVariable = () => {
  return createSelector([_getVariable], (variable) => {
    return variable;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  });
};

/**
 * Selector to return drawn data for the workspace variable based on the variableId prop.
 */
const _getVariableDrawnData = createSelector([workspaceData, variableIdProp], (drawnDataItems, variableId) => {
  if (!drawnDataItems || !variableId) {
    return null;
  }

  return drawnDataItems.find(({ id }) => variableId === id) || null;
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Returns an instance of a selector for getting drawn data for the workspace variable based on the variableId prop.
 */
const makeGetVariableDrawnData = () => {
  return createSelector([_getVariableDrawnData], (drawnData) => {
    return drawnData;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  });
};

const self = {
  getWorkspaceVariables,
  simplyGetWorkspaceVariables,
  getWorkspaceVariableById,
  getSortedEnrichedWorkspaceVariables,
  getSelectedEnrichedWorkspaceVariables,
  getWorkspaceVariablesNotFailed,
  makeGetVariable,
  makeGetVariableDrawnData,

  _getVariable,
  _getVariableDrawnData,
  _getEnrichedWorkspaceVariables,
};

export default self;
