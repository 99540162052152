/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { IParameterDescription } from '../../models/IOperationDescriptions';
import { FormControlLabel, Switch, FormControl, FormHelperText, Checkbox } from '@mui/material';
import { MmgParameterInfoWrapper } from './parameter-info-wrapper';
import { IParameterSettings } from '../../models/IParameterSettings';
import { useField } from 'formik';
import mikeSharedTheme from '../styles/mikeSharedTheme';

type ParameterInputBooleanProps = {
  param: string;
  parameterDescription?: IParameterDescription;
  name: string;
  value: boolean;
  label: string;
  infoText?: string;
  helperText?: string;
  error?: boolean;
  customSettings?: IParameterSettings;
  onParameterChanged?: (param: string, val: boolean) => void;
};

const ParameterLabelSX = {
  display: 'inline',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  paddingLeft: mikeSharedTheme.spacing(1)
} 

const InfoWrapperCss = css`
  align-items: center;
`;

/**
 * @name MmgParameterInputBoolean
 * @param props
 * @summary A generic element that renders a boolean parameter and accepts a callback for when it change.
 */
export const MmgParameterInputBoolean = (props: ParameterInputBooleanProps) => {
  const { param, name, value, label, helperText, infoText, error, onParameterChanged, parameterDescription } = props;
  const isCheckBox = String(parameterDescription.type).toLowerCase() === 'checkbox';
  const BooleanInput = isCheckBox ? Checkbox : Switch;

  const [field] = useField({
    name: name || param,
    checked: Boolean(value),
  });

  const onInputChanged = (event: React.ChangeEvent) => {
    field.onChange(event);
    const eventTarget = event.target as HTMLInputElement;
    if (onParameterChanged) {
      onParameterChanged(eventTarget.id, Boolean(eventTarget.checked));
    }
  };

  if (!param) {
    return <></>;
  }

  return (
    <>
      <MmgParameterInfoWrapper infoText={infoText} cssProp={InfoWrapperCss}>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <BooleanInput             
                id={param}
                name={name}
                checked={Boolean(value)}
                onChange={onInputChanged}
                onBlur={field.onBlur}
              />
            }
            label={label}
            title={label}
            checked={Boolean(value)}
            sx={{ "&.MuiTypography-root": ParameterLabelSX }}
          />
          {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>
      </MmgParameterInfoWrapper>
    </>
  );
};
