import { useSelector } from 'react-redux';
import { MmgViewerTool2DSpatialSelection } from '../../workspaces/viewer/tools/viewer-tool-2d-spatial-selection';
import { EToolButtonType } from '../../shared/tools/IToolButton';
import { CONFIRM_PANEL_IDS } from '../../shared/confirm-containers/confirm-constants';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { IGlobalState } from '../../store/reducers';
import { store } from '../../store';

type GeometryTool2DSpatialSelectionProps = {
  toolButtonType?: EToolButtonType;
};

/**
 * @name MmgConnectedGeometryTool2DSpatialSelection
 * @summary Wraps MmgViewerTool2DSpatialSelection to allow making a spatial selection on a geometry.
 *
 * @param props
 */
export function MmgConnectedGeometryTool2DSpatialSelection(props: GeometryTool2DSpatialSelectionProps) {
  const { toolButtonType } = props;
  const { activeConfirmPanelId } = useSelector((state: IGlobalState) => state.ConfirmPanelReducer);

  const onSpatialSelectionToggled = () => {
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.GEOMETRY_SELECTION,
    });
  };

  return (
    <MmgViewerTool2DSpatialSelection
      spatialSelectionActive={activeConfirmPanelId === CONFIRM_PANEL_IDS.GEOMETRY_SELECTION}
      onSpatialSelectionToggled={onSpatialSelectionToggled}
      toolButtonType={toolButtonType}
    />
  );
}
