/** @jsxImportSource @emotion/react */
import { IOperationConfiguration } from '../../../models/IOperations';
import { MmgItemCard } from '../../../shared/cards/item-card';
import { MmgNonHoverLayer } from '../../../shared/layers/mesh-non-hover-layer';
import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import LayerUtils from '../../../shared/layers/layer-utils';
import { getLayerDescription } from '../../../shared/panels/panel-utils';
import { MmgConnectedMeshOperationGeometrySummaryActions } from './mesh-operation-geometry-summary-actions';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { MmgMeshOperationGeometrySummary } from './mesh-operation-geometry-summary';
import { MmgMeshOperationQueriesSummaries } from './mesh-operation-queries-summaries';
import { useFilterQueriesForGeometry } from '../useFilterQueriesForGeometry';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { LayerWithoutBorderStyle } from '../../../queries/spatial-selections/spatial-selection-layer';

type MeshOperationGeometrySummaryCardProps = {
  geometryConfiguration: IOperationConfiguration;
  showOperationName?: boolean;
  queryConfigurations?: Array<IOperationConfiguration>;
  inputGeometries?: Array<IWorkspaceEnrichedGeometry>;
  showInputDescriptions?: boolean;
  workspaceVariables?: Array<IWorkspaceEnrichedVariable>;
  workspaceQueries?: Array<IWorkspaceQuery>;
  onOperationDelete: (operationId: string) => void;
};

/**
 * @name MmgMeshOperationGeometrySummaryCard
 * @param props
 *
 * @summary An itemcard for a mesh operation. Includes the input layer and summary.  Does not consider any child operations
 *
 */
export const MmgMeshOperationGeometrySummaryCard = (props: MeshOperationGeometrySummaryCardProps) => {
  const {
    geometryConfiguration,
    queryConfigurations,
    showOperationName,
    inputGeometries,
    showInputDescriptions,
    onOperationDelete,
    workspaceVariables,
    workspaceQueries,
  } = props;

  // Currently there will be only one geometry per operation. So we take the first.
  const inputGeometry = inputGeometries && inputGeometries.length > 0 ? inputGeometries[0] : {};

  const queriesForGeometry = useFilterQueriesForGeometry(inputGeometry, workspaceQueries);

  return (
    <MmgItemCard
      cardLayer={
        <MmgNonHoverLayer
          layerId={inputGeometry.id}
          layerName={inputGeometry.name}
          leftIcon={LayerUtils.getLayerIcon(inputGeometry)}
          layerDescription={showInputDescriptions && getLayerDescription(inputGeometry)}
          rightActions={
            <MmgConnectedMeshOperationGeometrySummaryActions
              operationConfiguration={geometryConfiguration}
              inputGeometry={inputGeometry}
              operationId={geometryConfiguration.id}
              onOperationDelete={onOperationDelete}
            />
          }
          cssProp={LayerWithoutBorderStyle}
        />
      }
      cardSummary={
        <>
          <MmgMeshOperationGeometrySummary
            key={inputGeometry.id}
            operationConfiguration={geometryConfiguration}
            showOperationName={showOperationName}
            workspaceVariables={workspaceVariables}
          />
          {queriesForGeometry &&
            queriesForGeometry.length > 0 && (
              <MmgMeshOperationQueriesSummaries
                key={'queries-for-' + inputGeometry.id}
                geometryQueries={queriesForGeometry}
                operationConfigurations={queryConfigurations}
                workspaceVariables={workspaceVariables}
              />
            )}
        </>
      }
      open={false}
    />
  );
};
