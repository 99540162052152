/**
 * Exposes methods to transform or combine statistics requests.
 *
 * @module StatisticsManager
 * @version 2.0.0
 * @requires WorkspaceStatisticsProxy
 */
import * as WorkspaceStatisticsProxy from '../proxies/WorkspaceStatisticsProxy';
import { HttpErrorHandler } from './http-utils';
import { IAttributeStatistics } from '../models/IStatistics';
import { ELEMENT_CATEGORIES } from '../shared/panels/mesh-panel-constants';

/**
 * Transforms a request that gets statistics for an attribute of an item (mesh, geometry, variable).
 *
 * @param workspaceId
 * @param itemId
 * @param itemCategory
 * @param attributeName
 */
const getAttributeStatistics = (
  workspaceId: string,
  itemId: string,
  itemCategory: string,
  attributeName: string,
): Promise<IAttributeStatistics> => {
  let getStatistics = (_workspaceId, _itemId, _attributeName): Promise<any> => {
    return Promise.reject();
  };

  switch (itemCategory) {
    case ELEMENT_CATEGORIES.GEOMETRY: {
      getStatistics = WorkspaceStatisticsProxy.getGeometryStatistics;
      break;
    }

    case ELEMENT_CATEGORIES.VARIABLE: {
      getStatistics = WorkspaceStatisticsProxy.getVariableStatistics;
      break;
    }

    case ELEMENT_CATEGORIES.MESH: {
      getStatistics = WorkspaceStatisticsProxy.getMeshStatistics;
      break;
    }

    default: {
      return Promise.reject(new Error('Attribute statistics is not supported for ' + itemCategory));
    }
  }

  return getStatistics(workspaceId, itemId, attributeName)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get statistics for attribute.', error));
};

const self = {
  getAttributeStatistics,
};

export default self;
