/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate, useParams } from 'react-router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { EElementCategories } from '../../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../../shared/layers/layer-utils';
import MIKE_COLORS from '../../styles/mike-colors';
import { LayerButtonStyle, LayerIconStyle } from '../../../queries/spatial-selections/spatial-selection-layer';
const { setActiveLayer } = LayerUtils;

const LayerOpenIconStyle = css`
  margin: 0;
`;

const iconStyle = css`
  path {
    fill: ${MIKE_COLORS.DARKGREY_DEFAULT};
  }
`;

type LayerOpenActionButtonProps = {
  layerCategory: EElementCategories;
  layerId: string;
};

/**
 * @name MikeConnectedLayerOpenActionButton
 * @summary ActionButton to 'open' a layer. Typically naviates to the layer's corresponding panel.
 *
 * todo hevo If we need to make another one of these for something else, consider making a general LayerActionButton that takes an ILayerAction as prop
 *
 * @param props
 */
export const MikeConnectedLayerOpenActionButton = (props: LayerOpenActionButtonProps) => {
  const navigate = useNavigate();
  const { layerCategory, layerId } = props;
  const { projectId, workspaceId } = useParams();

  const onLayerOpenPressed = () => setActiveLayer(layerCategory, layerId, workspaceId, projectId, navigate);

  return (
    <button css={`${LayerIconStyle} ${LayerButtonStyle} ${LayerOpenIconStyle}`} onClick={onLayerOpenPressed}>
      <ArrowForwardIcon css={iconStyle} />
    </button>
  );
};
