import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IWorkspaceBounds } from '../../models/IWorkspaces';
import MikeVisualizer from '../../MikeVisualizer/lib/MikeVisualizer';
import { areXYBoundsDefined } from './viewer-utils';
import { hasAnyWorkspaceDataItems } from '../../store/selectors/WorkspaceDataItemSelectors';
import { hasAnyWorkspaceDatasets, isWorkspaceFullyLoaded } from '../../store/selectors/WorkspaceSelectors';
import { IGlobalState } from '../../store/reducers';
const { setInitialBounds, getInitialBounds } = MikeVisualizer;

/**
 * @name MmgConnectedViewerBounding
 * @summary Sets initial bounds for the viewer to have an appropriate camera
 *
 */
export const MmgConnectedViewerBounding = () => {
  const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  const workspaceBoundingBox = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspaceBoundingBox);

  const workspaceFullyLoaded: boolean = useSelector(isWorkspaceFullyLoaded);
  const hasWorkspaceDataItems: boolean = useSelector(hasAnyWorkspaceDataItems);
  const hasWorkspaceDatasets: boolean = useSelector(hasAnyWorkspaceDatasets);

  const workspaceEpsgCode = useMemo(
    () => {
      return workspace ? workspace.epsgCode : null;
    },
    [workspace],
  );

  const [initialBoundingBox, setInitialBoundingBox] = useState(undefined);

  /**
   * Set initial bounds for the viewer to have an appropriate camera.
   *
   * todo dan: we should avoid making multiple requests. At the moment, there will be as many requests as renders if initialBoundingBox is not set.
   */
  const setVisualizationBounds = useCallback(
    async (
      anyWorkspaceData: boolean,
      epsgCode: number,
      wsBoundingBox?: IWorkspaceBounds,
    ): Promise<IWorkspaceBounds | false> => {
      /**
       * Try to set initial bounds. This might not work if the renderer hasn't finished initializing.
       * It keeps trying for each state change until the renderer succeeds to set initial bounds.
       *
       * @param bounds
       */
      const setBoundsInternal = (bounds: IWorkspaceBounds) => {
        if (setInitialBounds(bounds)) {
          setInitialBoundingBox(bounds);
          return bounds;
        }

        return false;
      };

      // If there is no workspace data, the bounds are set to a projected world view.
      if (!anyWorkspaceData) {
        switch (epsgCode) {
          case 4326: {
            // To get a better zoomlevel the xmin, xmax are set to -60/60 instead of -180/180.
            const worldBoundsEpsg4326 = [-60, 60, -90, 90, 0, 0] as IWorkspaceBounds;

            return setBoundsInternal(worldBoundsEpsg4326);
          }

          default: {
            try {
              // Use the bounding box of the projection otherwise.
              // todo dan: This generally works fine for projections with square-ish bounding boxes, but gets worse for projections with extreme aspect ratios i.e. 20:1 or 1:20. We could calculate zoom based on those, somehow?
              const MikeVisualizer2DMapUtil = await import('../../MikeVisualizer/lib/2d/MikeVisualizer2DMapUtil');
              const { fetchProjectionMetadataFromEpsg } = MikeVisualizer2DMapUtil.default;

              const { bbox: wgs84Bbox, proj4: workspaceProjectionString } = await fetchProjectionMetadataFromEpsg(
                epsgCode,
              );

              if (!wgs84Bbox) {
                throw new Error('No bbox available for projection');
              }

              const epsg4326String = 'EPSG:4326';
              const [yMin, xMin, yMax, xMax] = wgs84Bbox;
              const proj4Module = await import('proj4');
              const proj4 = proj4Module.default;

              const reprojectedBounds = [
                ...proj4(epsg4326String, workspaceProjectionString, [xMin, yMin]),
                ...proj4(epsg4326String, workspaceProjectionString, [xMax, yMax]),
                0,
                0,
              ] as IWorkspaceBounds;

              return setBoundsInternal(reprojectedBounds);
            } catch (error) {
              console.error('Failed to determine bounding box for initial bounds', error);
              return;
            }
          }
        }
      }

      // Otherwise, if a bounding box exists on the workspace, use that.
      if (areXYBoundsDefined(wsBoundingBox)) {
        return setBoundsInternal(wsBoundingBox);
      }

      // Otherwise, keep the bounds as 'null', the viewer will then fit data as it comes in.
      // todo dan: Alternatively, the UI can set bounds if the backend can't (needs to be refined).
      return;
    },
    [],
  );

  useEffect(
    () => {
      // Only set initialBounds once
      if (initialBoundingBox || getInitialBounds()) {
        return;
      }

      // Workspace is not fully loaded, do nothing.
      if (!workspaceFullyLoaded) {
        return;
      }

      const anyWorkspaceData = hasWorkspaceDataItems || hasWorkspaceDatasets;

      setVisualizationBounds(anyWorkspaceData, workspaceEpsgCode, workspaceBoundingBox as IWorkspaceBounds);
    },
    [
      workspaceBoundingBox,
      initialBoundingBox,
      workspaceEpsgCode,
      workspaceFullyLoaded,
      hasWorkspaceDataItems,
      hasWorkspaceDatasets,
      setVisualizationBounds,
    ],
  );

  return null;
};
