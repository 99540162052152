import { EWorkspaceActionType } from '../actions/WorkspaceActionType';

export interface IConfirmPanelState {
  activeConfirmPanelId: string | null;
}

const initialState = {
  activeConfirmPanelId: null, // An active confirm panel replaces any previous panel until exited.
};

/**
 * Confirm Panel Reducer.
 * - returns new states for matched confirm panel actions. Confirmation panels are shown as a result of activating a tool.
 *
 * @name ConfirmPanelReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IConfirmPanelState = initialState, action) {
  switch (action.type) {
    case EWorkspaceActionType.ACTIVE_PANEL_SET:
      return {
        ...state,
        activeConfirmPanelId: action.panelName,
      };

    case EWorkspaceActionType.EXIT_ACTIVE_PANEL:
      return {
        ...state,
        activeConfirmPanelId: null,
      };

    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
}
