/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IOperationConfiguration, IOperationMetadata } from '../../../models/IOperations';
import { useGroupedAndSortedVariableConfigurations } from '../useGroupedAndSortedVariableConfigurations';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { MmgMeshInterpolationVariableSummaryCard } from './mesh-interpolation-variable-summary-card';
import { MmgMeshInterpolationBasicSummaryCard } from './mesh-interpolation-basic-summary-card';
import { IWorkspaceEnrichedMesh } from '../../../models/IMeshes';
import { IWorkspaceAttribute } from '../../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../../models/IWorkspaceAttributeSettings';
import { IWorkspaceQuery } from '../../../models/IQueries';
import MIKE_COLORS from '../../../MikeVisualizer/colors/mike-colors';

type MeshInterpolationSummaryProps = {
  projectId: string;
  workspaceId: string;
  interpolationConfiguration: IOperationConfiguration;
  workspaceVariables: Array<IWorkspaceEnrichedVariable>;
  mesh?: IWorkspaceEnrichedMesh;
  meshAttributes?: Array<IWorkspaceAttribute>;
  meshAttributeSettings?: Array<IWorkspaceAttributeSettings>;
  meshQueries?: Array<IWorkspaceQuery>;
  interpolationMetadata?: IOperationMetadata;
  onInterpolationDeleted?: (operationId: string) => void;
  onInterpolationReplaced?: (operationConfiguration: IOperationConfiguration) => void;
};

const InterpolationSummaryStyle = css`
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
`;

const InterpolationSummaryCardStyle = css`
  border-bottom: none;
`;

/**
 * @name MmgMeshInterpolationSummary
 * @summary Shows a summary of a mesh interpolation operation.
 *
 * @param props
 */
export const MmgMeshInterpolationSummary = (props: MeshInterpolationSummaryProps) => {
  const {
    projectId,
    workspaceId,
    mesh,
    meshAttributes,
    meshAttributeSettings,
    meshQueries,
    interpolationConfiguration,
    interpolationMetadata,
    workspaceVariables,
    onInterpolationDeleted,
    onInterpolationReplaced,
  } = props;

  const onInterpolationExecuted = (result: Promise<IOperationConfiguration>) => {
    result.then((operation: IOperationConfiguration) => {
      if (onInterpolationReplaced && operation.id !== interpolationConfiguration.id) {
        // the api might replace the operation with a new operation based on state and other rules.
        // if so, we need to handle that
        onInterpolationReplaced(operation);
      }
    });
  };

  const { childOperations: childConfigurations } = interpolationConfiguration || ({} as IOperationConfiguration);

  const groupedVariableConfigurations = useGroupedAndSortedVariableConfigurations(
    childConfigurations,
    workspaceVariables,
  );

  if (!interpolationConfiguration) {
    return <></>;
  }

  return (
    <>
      <div css={InterpolationSummaryStyle}>
        <MmgMeshInterpolationBasicSummaryCard
          projectId={projectId}
          workspaceId={workspaceId}
          mesh={mesh}
          meshAttributes={meshAttributes}
          interpolationConfiguration={interpolationConfiguration}
          interpolationMetadata={interpolationMetadata}
          onInterpolationDeleted={onInterpolationDeleted}
          onInterpolationExecuted={onInterpolationExecuted}
          css={InterpolationSummaryCardStyle}
        />

        {groupedVariableConfigurations &&
          Object.keys(groupedVariableConfigurations).map((variableId) => {
            const { variable, variableConfiguration } = groupedVariableConfigurations[variableId];

            const inputVariables = [variable];

            return (
              <MmgMeshInterpolationVariableSummaryCard
                key={variableConfiguration.id}
                variableConfiguration={variableConfiguration}
                inputVariables={inputVariables}
                showInputDescriptions={false}
                meshAttributes={meshAttributes}
                attributeSettings={meshAttributeSettings}
                css={InterpolationSummaryCardStyle}
                meshQueries={meshQueries}
              />
            );
          })}
      </div>
    </>
  );
};
