import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import { t } from '../../translations/i18n';

/**
 * @name MmgProjectPermissionsMessage
 * @summary For a given proejct id, checks permissions and if something is not allowed - displays a message.
 *
 */
export const MmgProjectPermissionsMessage = () => {
  // Do not show anything while access is still laoding
  return (
    <MmgMessageBanner
      messageTitle={t('MESSAGE_IMPORT_DATASET_NOT_ALLOWED')}
      messageType="failed"
      message={t('MESSAGE_IMPORT_DATASET_NOT_ALLOWED_DETAILS')}
    />
  );
};
