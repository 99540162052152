import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../../translations/i18n';
import { MmgGroup } from '../../../shared/groups/group';
import WorkspaceMeshSelectors from '../../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceVariableSelectors from '../../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceMeshOperationSelectors from '../../../store/selectors/WorkspaceMeshOperationSelectors';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { IWorkspaceEnrichedMesh } from '../../../models/IMeshes';
import { MmgPanelSubsection } from '../../../shared/panels/panel-subsection';
import { getRouteByPath, ROUTES } from '../../../app/routes';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import { IWorkspaceAttribute } from '../../../models/IWorkspaceAttributes';
import { useAsyncLoadCurrentInterpolationOperations } from '../../../meshes/interpolation/useAsyncLoadCurrentInterpolationOperations';
import { MmgMeshInterpolationsNotAvailableMessage } from '../mesh-interpolations-not-available-message';
import { MmgMeshInterpolationSummary } from './mesh-interpolation-summary';
import { IOperationConfiguration, IOperationMetadata } from '../../../models/IOperations';
import { IWorkspaceAttributeSettings } from '../../../models/IWorkspaceAttributeSettings';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../store';
import { upperFirst } from 'lodash-es';
import { IProject } from '../../../models/IProject';
import { IGlobalState } from '../../../store/reducers';
import { MikeStickyPanelBottomActions } from '../../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeStickyPanelContent from '../../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import MikeButton from '../../../shared-components/mike-button';

type MeshInterpolationsSummaryProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
};

export const INTERPOLATION_TYPE_OTHER = 'other';
export const INTERPOLATION_TYPE_ELEVATION = 'elevation';

/**
 * @name MmgConnectedMeshInterpolationsSummary
 * @summary Shows a summary of each of the current mesh interpolations.
 *
 * @param props
 * @deprecated
 */
export const MmgConnectedMeshInterpolationsSummary = (props: MeshInterpolationsSummaryProps) => {
  const { projectId, workspaceId, meshId } = props;

  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getMeshAttributesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributes();
  const getMeshAttributeSettingsSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributeSettings();
  const getMeshQueriesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshQueries();
  const getMeshOperationsForMeshInstance = WorkspaceMeshOperationSelectors.makeGetMeshOperationsForMesh();

  const mesh: IWorkspaceEnrichedMesh =
    useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId })) || {};
  const workspaceVariables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );
  const meshAttributes: Array<IWorkspaceAttribute> =
    useSelector((state: IGlobalState) => getMeshAttributesSelectorInstance(state, { meshId })) || [];
  const meshAttributeSettings: Array<IWorkspaceAttributeSettings> =
    useSelector((state: IGlobalState) => getMeshAttributeSettingsSelectorInstance(state, { meshId })) || [];
  const meshQueries: Array<IWorkspaceQuery> =
    useSelector((state: IGlobalState) => getMeshQueriesSelectorInstance(state, { meshId })) || [];
  const meshOperationsMetadata: Array<IOperationMetadata> = useSelector((state: IGlobalState) =>
    getMeshOperationsForMeshInstance(state, { meshId }),
  );
  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);

  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;
  const [currentTab, onTabChange] = React.useState(1);

  const handleInterpolationTabChange = (_event, newValue: number) => {
    onTabChange(newValue);
  };

  const {
    interpolationOperations,
    updateInterpolations,
    interpolationOperationsLoading,
    interpolationOperationsLoadingFailed,
    retryLoadCurrentInterpolationOperations,
  } = useAsyncLoadCurrentInterpolationOperations(workspaceId, meshId);

  const navigate = useNavigate();

  // group interolation metadata by ids for easy lookup
  // todo hevo could make a hook for this
  const interpolationMetadataById: {
    [id: string]: IOperationMetadata;
  } = useMemo(
    () => {
      if (!meshOperationsMetadata || !interpolationOperations) {
        return {};
      }
      const interpolationIds = interpolationOperations.map(({ id }) => id);
      const metadataById = meshOperationsMetadata.reduce(
        (acc, cur) => {
          const { id } = cur;
          if (interpolationIds.indexOf(id) !== -1) {
            return { ...acc, [id]: cur };
          } else {
            return acc;
          }
        },
        {} as { [id: string]: IOperationMetadata },
      );
      return metadataById;
    },
    [interpolationOperations, meshOperationsMetadata],
  );

  // reload current interpolation if just one becomes superseded
  useEffect(
    () => {
      const superSeded = Object.values(interpolationMetadataById).find((metadata) => {
        return metadata && (metadata.isSuperseded || Boolean(metadata.isSupersededBy));
      });
      if (superSeded) {
        retryLoadCurrentInterpolationOperations();
      }
    },
    [interpolationMetadataById, retryLoadCurrentInterpolationOperations],
  );

  const onAddMeshInterpolation = () => {
    const iType = currentTab === 1 ? INTERPOLATION_TYPE_ELEVATION : INTERPOLATION_TYPE_OTHER;
    store.dispatch({ type: 'workspace/meshes/SET_INTERPOLATION_TYPE', interpolationType: iType });
    navigate(getRouteByPath(ROUTES.meshCreateInterpolationPanel.path, { workspaceId, projectId, meshId: mesh.id,
      interpolationType: currentTab === 1 ? INTERPOLATION_TYPE_ELEVATION : INTERPOLATION_TYPE_OTHER }, ROUTES.workspace.path));     
    };

  const onInterpolationDeleted = (operationId: string) => {
    const nextInterpolations = interpolationOperations.filter(({ id }) => {
      return id !== operationId;
    });
    updateInterpolations(nextInterpolations);
  };

  const onInterpolationReplaced = (current: IOperationConfiguration, next: IOperationConfiguration) => {
    const filtered = interpolationOperations.filter(({ id }) => {
      return id !== current.id;
    });
    const nextInterpolations = [...filtered, next];
    updateInterpolations(nextInterpolations);
  };

  if (!mesh) {
    return <></>;
  }

  if (interpolationOperationsLoadingFailed) {
    return (
      <MmgPanelSubsection>
        <p>{t('FAILED_TO_LOAD_CURRENT_MESH_INTERPOLATIONS')}</p>
      </MmgPanelSubsection>
    );
  }

  const hasAnyWorkspaceVariables = workspaceVariables && workspaceVariables.length > 0;

  return (
    <>
      {interpolationOperationsLoading && (
        <MmgPanelSubsection>
          <CircularProgress />
        </MmgPanelSubsection>
      )}

      {/* Todo hevo SHould be grouped by interpolation type, having the elevation interpolations - and later CFL interpolation - shown first and then 'other' */}
      <MikeStickyPanelContent>
        <MmgGroup groupName={t('MESH_INTERPOLATIONS')} canBeHidden={false}>
          {interpolationOperationsLoading && (
            <MmgPanelSubsection>
              <CircularProgress />
            </MmgPanelSubsection>
          )}
          <div>
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleInterpolationTabChange}
              variant="fullWidth"
            >
              <Tab label={upperFirst(INTERPOLATION_TYPE_ELEVATION)} value={1} />
              <Tab label={upperFirst(INTERPOLATION_TYPE_OTHER)} value={2} />
            </Tabs>
          </div>
          {(interpolationOperations || []).map((interpolation) => {
            return interpolation.operationDefinition.parameters.propertyName ===
              upperFirst(INTERPOLATION_TYPE_ELEVATION) && currentTab === 1 ? (
              <MmgMeshInterpolationSummary
                key={interpolation.id}
                projectId={projectId}
                workspaceId={workspaceId}
                mesh={mesh}
                meshAttributes={meshAttributes}
                meshAttributeSettings={meshAttributeSettings}
                meshQueries={meshQueries}
                interpolationMetadata={interpolationMetadataById ? interpolationMetadataById[interpolation.id] : null}
                interpolationConfiguration={interpolation}
                workspaceVariables={workspaceVariables}
                onInterpolationDeleted={onInterpolationDeleted}
                onInterpolationReplaced={(next) => onInterpolationReplaced(interpolation, next)}
              />
            ) : interpolation.operationDefinition.parameters.propertyName !==
              upperFirst(INTERPOLATION_TYPE_ELEVATION) && currentTab === 2 ? (
              <MmgMeshInterpolationSummary
                key={interpolation.id}
                projectId={projectId}
                workspaceId={workspaceId}
                mesh={mesh}
                meshAttributes={meshAttributes}
                meshAttributeSettings={meshAttributeSettings}
                meshQueries={meshQueries}
                interpolationMetadata={interpolationMetadataById ? interpolationMetadataById[interpolation.id] : null}
                interpolationConfiguration={interpolation}
                workspaceVariables={workspaceVariables}
                onInterpolationDeleted={onInterpolationDeleted}
                onInterpolationReplaced={(next) => onInterpolationReplaced(interpolation, next)}
              />
            ) : null;
          })}
        </MmgGroup>
      </MikeStickyPanelContent>
      {hasAnyWorkspaceVariables &&
        canUpdateWorkspace && (
          <MikeStickyPanelBottomActions>
            <MikeButton variant="contained" fullWidth onClick={onAddMeshInterpolation}>
              {currentTab === 1 ? t('INTERPOLATION_NEW_ELEVATION') : t('INTERPOLATION_NEW_OTHER')}
            </MikeButton>
          </MikeStickyPanelBottomActions>
        )}
      {!hasAnyWorkspaceVariables && (
        <MmgMeshInterpolationsNotAvailableMessage projectId={projectId} workspaceId={workspaceId} />
      )}
    </>
  );
};
