/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PanelHeaderZindex } from '../styles/styleConsts';
import mikeSharedTheme from '../styles/mikeSharedTheme';

export const PanelBodyStyle = css`
  background-color: white;
`;

export const PanelFooterButtonsCss = css`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

export const HEADER_ICON_SIZE = '40px';
export const PANEL_HEADER_SIZE = '72px'; // todo hevo Need to find a better way to handle tabs and steppers in sticky panels.

export const PanelHeaderStyle = css`
  background: white;
  position: sticky;
  top: 0;
  padding: ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(2)};
  min-height: ${PANEL_HEADER_SIZE};

  z-index: ${PanelHeaderZindex};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PanelHeaderNoBorderStyle = css`
  border: 0;
`;

export const PanelHeaderWithBackArrowStyle = css`
  padding: ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(1)};
`;

export const PanelHeaderTitleStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PanelHeaderBackStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  outline: 0;

  background: 0;
  border: 0;
  padding: 0;
  transition: opacity 0.3s;

  height: ${HEADER_ICON_SIZE};
  width: ${HEADER_ICON_SIZE};

  padding-right: ${mikeSharedTheme.spacing(1)};

  :hover,
  :focus {
    outline: 0;
    border: 0;
    opacity: 0.7;
    transition: opacity 0.3s;
  }
`;

export const PanelHeaderBackIconStyle = css`
  transition: transform 0.3s;
  display: flex;

  :hover,
  :focus {
    transform: translateX(-10px);
  }
`;
