export const workspacePath = '/project/:projectId/workspace/:workspaceId'

const AppRoutes = {
  home: {
    path: '/',
  },
  error: {
    path: '/error',
  },
  login: {
    path: '/login',
  },
  notFound: {
    path: '/not-found',
  },
  workspace: {
    path: workspacePath,
  },
  workspaceList: {
    path: '/project/:projectId/workspace-list',
  },
  workspaceEdit: {
    path: '/project/:projectId/workspace/:workspaceId/edit',
  },
};

export default AppRoutes
