export interface IGeometryClientOperationState {
  activeOperationToolId: string | null;
}

const initialState = {
  activeOperationToolId: null,
};

/**
 * Geometry Client Operation Reducer.
 * - returns new states for matched Geometry Client Operation message actions. That is typically vector operations done by map shaper.
 *
 * @name GeometryClientOperationReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IGeometryClientOperationState = initialState, action) {
  switch (action.type) {
    case 'clientOperation/SET_ACTIVE_TOOL':
      return {
        ...state,
        activeOperationToolId: action.operationToolId,
      };

    case 'clientOperation/EXIT_ACTIVE_TOOL':
      return {
        ...state,
        activeOperationToolId: null,
      };

    default:
      return state;
  }
}
