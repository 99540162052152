
import { useSelector } from 'react-redux';
import { BACKEND_PROD_ENVIRONMENT_KEY } from '../../managers/ConfigurationManager';
import { translateWithPrefix } from '../../translations/utils';
import { t } from '../../translations/i18n';
import { MmgKeyboardHelp } from './keyboard-help';
import { stripIndents } from 'common-tags';
import { IGlobalState } from '../../store/reducers';
import MikeSupportMenuContainer from '../../shared-components/mike-support';

const SUPPORT_EMAIL_KEY = 'eMail';

/**
 * @name MmgSupport
 * @summary Wraps the react support element.
 */
export const MmgConnectedSupport = () => {
  const configuration = useSelector((state: IGlobalState) => state.AppReducer.configuration);

  const supportLinks =
    configuration && configuration.supportUrls
      ? {
          links: Object.keys(configuration.supportUrls)
            .filter((key) => SUPPORT_EMAIL_KEY !== key)
            .map((key) => {
              return {
                label: translateWithPrefix('SP', key),
                href: configuration.supportUrls[key],
                target: '_blank',
              };
            }),
        }
      : { links: [] };

  const subjectText =
    configuration && configuration.environment !== BACKEND_PROD_ENVIRONMENT_KEY
      ? `${t('SP_SUBJECT')} [${configuration.environment}]`
      : t('SP_SUBJECT');

  const bodyText =
    t('SP_BODY_TEXT') +
    '\r\n\r\n' +
    stripIndents`${t('SP_BULLETED_LIST')}` +
    '\r\n\r\n' +
    stripIndents`
        ${t('SP_BODY_TIP')}
        ----------------------------------------------------------------------------------       
      `;

  const mailConfig = {
    to: configuration ? configuration.supportUrls[SUPPORT_EMAIL_KEY] : '',
    subject: subjectText,
    body: bodyText,
  };
  return (
    <>
      <MmgKeyboardHelp />
      {configuration &&
        configuration.supportUrls && (
          <MikeSupportMenuContainer
            mailConfig={mailConfig}
            documentationConfig={supportLinks.links}
            tooltipPosition={{ top: 0, left: -5 }}
          />
        )}
    </>
  );
};
