/**
 * @param event
 */
export const preventNumberInputScrolling = (event) => {
  const el = event.target;
  const hasFocus = el.matches(':focus');

  // remove focus to prevent the number from increasing/decreasing on scroll
  el.blur();

  // set focus back
  if (hasFocus) {
    setTimeout(() => {
      el.focus({ preventScroll: true });
    }, 10);
  }
};
