import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import WorkspaceMeshesManager from '../../managers//WorkspaceMeshesManager';
import { EMeshFormats } from '../../models/IWorkspaceData';

/**
 * Submits exporting of selected meshes.
 * @param workspaceId
 * @param meshIds
 * @param exportFormat
 */
export const submitExportMeshes = (
  workspaceId: string,
  meshIds: Array<string>,
  exportFormat: EMeshFormats,
): Promise<void> => {
  return (
    WorkspaceMeshesManager.exportMeshes(workspaceId, meshIds, exportFormat)
      /* .then(() => {
      const toast = {
        text: t('EXPORT_MESHES_SUCCESSFUL'),
      };

      store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
    }) */
      .catch((error) => {
        const toast = {
          text: t('EXPORT_MESHES_FAILED'),
          operationId: error.operationId,
        };

        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      })
  );
};

const self = {
  submitExportMeshes,
};

export default self;
