/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { translateWithPrefixAndSuffix } from '../translations/utils';
import MIKE_COLORS from '../MikeVisualizer/colors/mike-colors';

// todo hevo should use the theme instead somehow.
// eslint-disable-next-line react-refresh/only-export-components
export const operationDescriptionCss = css`
  font-size: 12px;
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
`;

type OperationDescriptionProps = {
  operationKey: string;
};

/**
 * @name MmgOperationDescription
 * @param props
 * @summary Renders a description for an operation if available.
 *
 */
export const MmgOperationDescription = (props: OperationDescriptionProps) => {
  const { operationKey } = props;

  if (!operationKey) {
    return <></>;
  }

  const description = translateWithPrefixAndSuffix('OP', 'DESCRIPTION', operationKey, true);

  return description && <p css={operationDescriptionCss}>{description}</p>;
};
