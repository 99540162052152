/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import mikeSharedTheme from '../../../shared/styles/mikeSharedTheme';

const longTextStyle = css`
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: table-cell;
  padding-right: ${mikeSharedTheme.spacing(1)};
`;

interface IProps {
  text: string
}

const LongText = (props: IProps) => {
  const { text } = props

  return (
    <Tooltip title={text ? text : ""}>
      <div css={longTextStyle}>{text}</div>
    </Tooltip>
  )
}

export default React.memo(LongText)