import { v4 as uuid } from 'uuid';
import { TOAST_TYPES } from '../../models/IToasts';

export interface IToastState {
  toasts: Array<any>;
}
const initialState = {
  toasts: Array<any>()
};

/**
 * Toast Reducer.
 * - returns new states for matched toast actions.
 *
 * @name ToastReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IToastState = initialState, action) {
  switch (action.type) {
    case 'toast/ADD/INFO':
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            ...action.toast,
            id: uuid(),
            type: TOAST_TYPES.INFO,
            timeout: action.toast.timeout || 5000,
          },
        ],
      };

    case 'toast/ADD/NOTICE':
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            ...action.toast,
            id: uuid(),
            type: TOAST_TYPES.INFO,
            timeout: false,
          },
        ],
      };

    case 'toast/ADD/SUCCESS':
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            ...action.toast,
            id: uuid(),
            type: TOAST_TYPES.SUCCESS,
            timeout: action.toast.timeout || 5000,
          },
        ],
      };

    case 'toast/ADD/WARN':
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            ...action.toast,
            id: uuid(),
            type: TOAST_TYPES.WARNING,
            timeout: action.toast.timeout || 10000,
          },
        ],
      };

    case 'toast/ADD/ERROR':
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            ...action.toast,
            id: uuid(),
            type: TOAST_TYPES.ERROR,
          },
        ],
      };

    case 'toast/ADD/WORKING':
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            ...action.toast,
            id: uuid(),
            type: TOAST_TYPES.WORKING,
          },
        ],
      };

    case 'toast/DELETE':
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.id !== action.toastId),
      };

    case 'toast/CLEAR_ALL': {
      return {
        ...state,
        toasts: [],
      };
    }

    case 'toast/CLEAR_ALL_WORKING': {
      return {
        ...state,
        toasts: state.toasts.filter((t) => t.type !== TOAST_TYPES.WORKING),
      };
    }

    default:
      return state;
  }
}
