import { IOperationConfiguration } from '../../../models/IOperations';
import { MmgOperationConfigurationSummary } from '../../../operations/operation-configuration-summary';
import { t } from '../../../translations/i18n';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { getVariableParametersValueStrings } from '../mesh-configuration-util';
import { MESH_OPERATION_PARAMETER_CONDITIONS } from '../mesh-configuration-constants';

type MeshOperationGeometrySummaryProps = {
  operationConfiguration: IOperationConfiguration;
  showOperationName?: boolean;
  workspaceVariables?: Array<IWorkspaceEnrichedVariable>;
};

/**
 * @name MmgMeshOperationGeometrySummary
 * @param props
 *
 * @summary A summary a mesh child geometry operation.
 */
export const MmgMeshOperationGeometrySummary = (props: MeshOperationGeometrySummaryProps) => {
  const { operationConfiguration, showOperationName, workspaceVariables } = props;
  const variableValueStrings = getVariableParametersValueStrings(operationConfiguration, workspaceVariables);

  return (
    <MmgOperationConfigurationSummary
      operationConfiguration={operationConfiguration}
      showOperationName={showOperationName}
      operationLabel={t('MESH_OPERATION')}
      customValueStrings={variableValueStrings}
      parameterConditionDescriptions={MESH_OPERATION_PARAMETER_CONDITIONS}
    />
  );
};
