
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { store } from '../store/store';
import MapPinOutline from '../icons/MapPinOutline.svg?react';
import MapPinFilled from '../icons/MapPinFilled.svg?react';
import { IWorkspaceComment } from '../models/IComments';
import { getShortDate } from '../translations/utils';
import { MmgCommentActions } from './comment-actions';
import { IUser } from '../models/IUser';
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../MikeVisualizer/colors/mike-colors';
import { MikeLayer } from '../shared-components/mike-layer/layer';

const { focusTo2DPoint } = MikeVisualizerLib;

type MmgCommentSummayProps = {
  comment: IWorkspaceComment;
  isFocused: boolean;
  loggedInUser: IUser;
  userCanUpdateWorkspace: boolean;
};

export const CommentMessageStyle = css`
  display: inline-block;
  width: 100%;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 12px;
  line-height: 1.33;
  font-weight: normal;
  padding: 0 0 ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(6)};
  background: white;
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
`;

const CommentHeaderStyle = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
`;

const CommentLayerStyle = css`
  border-bottom: none;
`;

export const MmgCommentSummary = (props: MmgCommentSummayProps) => {
  const { comment, isFocused, loggedInUser, userCanUpdateWorkspace } = props;
  if (!comment) {
    return;
  }
  const { id, message, created, createdBy } = comment;
  const { name } = createdBy || {};
  const onCommentPressed = () => {
    const [x, y] = comment.coordinate;
    focusTo2DPoint(x, y);
    store.dispatch({
      type: 'comments/SET_FOCUSED_COMMENT',
      commentId: comment.id,
    });
  };

  const onCommentIconPressed = (event) => {
    if (event) {
      event.preventDefault();
    }
    onCommentPressed();
  };

  /**
   * Gets actions for comment context menu.
   */
  const getContextMenuActions = () => {
    if (!userCanUpdateWorkspace && loggedInUser.id !== comment.createdBy.userId) {
      return null;
    }
    return (
      <>
        <MmgCommentActions commentId={id} />
      </>
    );
  };

  const LeftIcon = () => (
    <a href={comment.id} onClick={onCommentIconPressed}>
      {isFocused ? <MapPinFilled /> : <MapPinOutline />}
    </a>
  );

  return (
    <>
      <div css={CommentHeaderStyle}>
        <MikeLayer
          layerId={id}
          layerName={name}
          layerDescription={getShortDate(created)}
          leftIcon={<LeftIcon />}
          css={CommentLayerStyle}
          onLayerNamePressed={onCommentPressed}
          rightActions={getContextMenuActions()}
        />
        <div css={CommentMessageStyle}>{message}</div>
      </div>
    </>
  );
};
