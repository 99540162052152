import MmgStatisticsContainer from '../../statistics-react/mmg-mesh-statistics-container';
import { EElementCategories } from '../panels/mesh-panel-constants';

import { MmgConnectedHistograms } from '../histograms/histogram';
import { IWorkspaceEnrichedDataItem } from '../../models/IWorkspaceData';

type StatisticsContainerProps = {
  dataArrays: Array<any>;
  fieldDataArrays: Array<any>;

  elementId: string;
  elementCategory: EElementCategories;
  enrichedDataItem: IWorkspaceEnrichedDataItem;
};

/**
 * @name MmgStatisticsSection

 * @summary Generic element that given a data element's dataArrays & fieldDataArrays, figures out what relevant statistics it should show.
 *
 * @description
 * The current rules are
 * - show all field data that does not have a corresponding cellData in dataArray
 * - show all cell data
 * - show all point data
 *
 *
 * @param props
 */
export const MmgStatisticsSection = (props: StatisticsContainerProps) => {
  const { dataArrays = [], fieldDataArrays = [], elementId, elementCategory, enrichedDataItem } = props;

  return (
    <>
      <MmgStatisticsContainer
        elementId={elementId}
        elementCategory={elementCategory}
        dataArrays={dataArrays}
        fieldDataArrays={fieldDataArrays}
        isTiled={enrichedDataItem.isTiled}
      />
      <section>
        {
          <MmgConnectedHistograms
            enrichedDataItem={enrichedDataItem}
            dataItemCategory={elementCategory}
            elementId={elementId}
            dataArrays={dataArrays}
          />
        }
      </section>
    </>
  );
};
