/* eslint-disable react-refresh/only-export-components */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem, ListItemIcon, Typography} from '@mui/material';
import { ILayerAction } from './layer-default-actions';
import MIKE_COLORS from '../../styles/mike-colors';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { ContextMenuStyle } from '../../menus/menu-styles';

type LayerActionMenuProps = {
  layerId?: string;
  layerActions?: Array<ILayerAction>;
  disabled?: boolean;
};

const iconStyle = css`
  path {
    fill: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
`;

const MENU_ICON_SIZE = '2.5rem';

export const menuitemSX = {
  paddingLeft: mikeSharedTheme.spacing(1), // The icon already contains some space
  paddingRight: mikeSharedTheme.spacing(2)
};
export const menuitemiconSX = {
  color: mikeSharedTheme.palette.primary.main,
  fill: mikeSharedTheme.palette.primary.main,
  minWidth: MENU_ICON_SIZE,
  width: MENU_ICON_SIZE,
  height: MENU_ICON_SIZE,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  flexGrow: 0,
};

/**
 * @name MikeLayerActionMenu
 * @summary An action menu for layers, typically present on the right side of the layer.
 *
 * @param props
 */
export function MikeLayerActionMenu(props: LayerActionMenuProps) {
  const { layerId, layerActions, disabled } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const contextMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const contextMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (action: () => void) => {
    contextMenuClose();
    action();
  };

  if (!layerActions || layerActions.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`layer-${layerId}`}
        aria-haspopup="true"
        onClick={contextMenuOpen}
        disabled={disabled}
      >
        <MoreVertIcon css={iconStyle} />
      </IconButton>

      <Menu
        id={`layer-${layerId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={contextMenuClose}
        css={ContextMenuStyle}
      >
        {layerActions.map((action) => (
          <MenuItem
            key={action.actionId}
            dense={true}
            onClick={() => onMenuItemClick(action.onAction)}
            sx={menuitemSX}
            disabled={action.disabled}
            disableGutters={true}
          >
            <ListItemIcon sx={menuitemiconSX}>{action.actionIcon}</ListItemIcon>

            <Typography variant="body2" noWrap>
              {action.actionDisplayName}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
