/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../translations/i18n';
import { CircularProgress } from '@mui/material';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgPanelTopActions } from '../../shared/panels/panel-top-actions';
import { MmgMeshOperationConfiguration } from './configuration/mesh-operation-configuration';
import LayerUtils, { hideAllItems } from '../../shared/layers/layer-utils';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import { getDataItemsByIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { getFilteredCreateMeshChildOperations, submitCreateMeshOperation } from './mesh-configuration-util';
import { IOperationConfiguration } from '../../models/IOperations';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { IWorkspaceQuery } from '../../models/IQueries';
import { useAsyncLoadContextualCreateMeshOperationDescription } from './useAsyncLoadContextualCreateMeshOperationDescription';
import { useAsyncLoadCreateMeshConfiguration } from './useAsyncLoadCreateMeshConfiguration';
import { useStepper } from '../../shared/steppers/useStepper';
import { MmgStepper } from '../../shared/steppers/stepper';
import { MmgStep } from '../../shared/steppers/step';
import { stepperStickyPanelStyle } from '../../shared/steppers/stepper-styles';
import { MmgStepContent } from '../../shared/steppers/step-content';
import { isCreateMeshChildOperationsValid } from '../../managers/MeshOperationValidation';
import { MmgGroup } from '../../shared/groups/group';
import { MmgConnectedMeshConfigurationSelectGeometries } from './mesh-configuration-select-geometries';
import { store } from '../../store/store';
import { useIsMounted } from '../../shared/hooks/hooks';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { EWorkspaceGeometryActionType } from '../../store/actions/WorkspaceGeometryActionType';
import { IGlobalState } from '../../store/reducers';
import MikeButton from '../../shared-components/mike-button';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeExpandableButton from '../../shared-components/mike-expandable-button';
const { showLayer } = LayerUtils;

const meshConfigurationStyleReadOnly = css`
  pointer-events: none;
`;

type MeshEditOperationConfigurationProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
  geometryIdToAdd?: string;
  onOperationSubmitSuccess?: (execute: boolean) => void;
};

/**
 * @name MmgConnectedMeshEditOperationConfiguration
 * @summary Allows re-configuring existing mesh operations.
 * Supports both 'basic'/'base' configurations, geometry configurations.
 * Supports configuring 'newly' added geometries
 *
 * @param props
 */
export const MmgConnectedMeshEditOperationConfiguration = (props: MeshEditOperationConfigurationProps) => {
  const { workspaceId, meshId, geometryIdToAdd, onOperationSubmitSuccess } = props;

  const workspaceGeometries: Array<IWorkspaceEnrichedGeometry> = useSelector(
    WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries,
  );
  const workspaceVariables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );
  const workspaceQueries: Array<IWorkspaceQuery> = useSelector(
    (state: IGlobalState) => state.WorkspaceQueryReducer.workspaceQueries,
  );

  const EDIT_MESH_STEPS = {
    SELECT_GEOMETRIES: 0,
    CONFIGURE: 1,
  };
  const isMounted = useIsMounted();
  const { goBackToReferrer } = useNavigateBack();
  const [meshingInProgress, setMeshingInProgress] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [meshOperationConfiguration, setMeshOperationConfiguration] = useState(null as IOperationConfiguration);
  const [selectedGeometryIds, setSelectedGeometryIds] = useState([] as Array<string>);
  const { activeStep, handleNext, goToStep } = useStepper(EDIT_MESH_STEPS.CONFIGURE);

  /**
   * Effectively checks if any geometries are selected to be included in the configuration.
   */
  const anyGeometriesSelected = selectedGeometryIds && selectedGeometryIds.length > 0;

  /**
   * Callback to when the 'add more' button is pressed.
   */
  const addMoreItems = () => {
    goToStep(EDIT_MESH_STEPS.SELECT_GEOMETRIES);
  };

  const retryFailedLoading = () => {
    if (contextualOperationDescriptionsLoading) {
      retryLoadContextualDescriptions();
    }
    if (createMeshConfigurationLoadingFailed) {
      retryLoadCreateMeshConfiguration();
    }
  };

  /**
   * Saves changes without generating the mesh.
   */
  const saveMesh = () => submitMeshConfiguration(false);

  /**
   * Saves changes, generating the mesh.
   */
  const createMesh = () => submitMeshConfiguration(true);

  /**
   * Creates a mesh request, optionally generating the mesh.
   *
   * @param generateMesh
   */
  const submitMeshConfiguration = (generateMesh: boolean) => {
    if (!meshOperationConfiguration) {
      // nothing to submit
      return;
    }

    if (generateMesh) {
      setMeshingInProgress(true);
    } else {
      setSaveInProgress(true);
    }

    // only include child operations of selected geometries
    const filteredChildOperations = getFilteredChildOperations();

    const operationConfigurationToSubmit = {
      ...meshOperationConfiguration,
      childOperations: filteredChildOperations,
      inputIds: [meshId],
    };

    submitCreateMeshOperation(workspaceId, meshId, operationConfigurationToSubmit, generateMesh)
      .then(() => {
        if (onOperationSubmitSuccess) {
          onOperationSubmitSuccess(generateMesh);
        }
      })
      .finally(() => {
        isMounted() && setMeshingInProgress(false);
        isMounted() && setSaveInProgress(false);
      });
  };

  // Only include child operations corresponding to selected geometries.
  // This allows user to remove a geometry and add it back with the same parameters set
  const getFilteredChildOperations = useCallback(
    () =>
      getFilteredCreateMeshChildOperations(
        meshOperationConfiguration,
        selectedGeometryIds,
        workspaceGeometries,
        workspaceQueries,
      ),
    [meshOperationConfiguration, selectedGeometryIds, workspaceGeometries, workspaceQueries],
  );

  /**
   * Callback for when operation configuration has changed
   *
   * @param configuration
   */
  const onMeshOperationConfigurationChanged = (configuration: IOperationConfiguration) => {
    setMeshOperationConfiguration(configuration);
  };

  /**
   * Sets the selected geometries the selected ones.
   *
   * @param selectedIds
   */
  const onSelectedGeometriesChanged = (selectedIds: Array<string>) => {
    setSelectedGeometryIds(selectedIds);
    // we do not reset configuration as we do not want to loose any changes already made by the user
  };

  /**
   * Gets the current createmesh operation configuration for the mesh.
   */
  const {
    createMeshConfiguration,
    createMeshConfigurationLoading,
    createMeshConfigurationLoadingFailed,
    retryLoadCreateMeshConfiguration,
  } = useAsyncLoadCreateMeshConfiguration(workspaceId, meshId);

  /**
   * Gets the contextual operation descriptions based on geometries and queries.
   */
  const {
    basicCreateMeshOperationDescription,
    contextualChildOperationDescriptions,
    contextualOperationDescriptionsLoading,
    contextualOperationDescriptionsLoadingFailed,
    retryLoadContextualDescriptions,
  } = useAsyncLoadContextualCreateMeshOperationDescription(
    workspaceId,
    selectedGeometryIds,
    workspaceQueries,
    workspaceVariables || [],
  );

  useEffect(
    () => {
      // set the new configuration
      setMeshOperationConfiguration(createMeshConfiguration);
      // set selected geometryIds to match the children of the createMeshConfiguration
      if (createMeshConfiguration) {
        const { childOperations } = createMeshConfiguration;
        const geometryIds = childOperations.map(({ inputIds }) => inputIds[0]);
        // only add if not already added - might be the case if user refresh browser
        if (geometryIdToAdd && geometryIds.indexOf(geometryIdToAdd) === -1) {
          geometryIds.push(geometryIdToAdd);
        }
        setSelectedGeometryIds(geometryIds);
      }
    },
    [createMeshConfiguration, geometryIdToAdd],
  );

  const selectedGeometries = useMemo(
    () => {
      return getDataItemsByIds(workspaceGeometries, selectedGeometryIds);
    },
    [workspaceGeometries, selectedGeometryIds],
  );

  const isLoading = createMeshConfigurationLoading || contextualOperationDescriptionsLoading;

  const isLoadingFailed = createMeshConfigurationLoadingFailed || contextualOperationDescriptionsLoadingFailed;

  const isCreateMeshOperationValid = useMemo(
    () => {
      // we only consider childoperations corresponding to selected geometry Ids
      const childOperationsForSelected = getFilteredChildOperations();
      return isCreateMeshChildOperationsValid(childOperationsForSelected);
    },
    [getFilteredChildOperations],
  );

  const saveDisabled =
    isLoading || meshingInProgress || saveInProgress || !meshOperationConfiguration || !isCreateMeshOperationValid;
  const generateDisabled = saveDisabled;
  const isReadOnly = meshingInProgress || saveInProgress || !meshOperationConfiguration;

  const isStepComplete = (step: number) => {
    switch (step) {
      case EDIT_MESH_STEPS.SELECT_GEOMETRIES:
        return anyGeometriesSelected;
      case EDIT_MESH_STEPS.CONFIGURE:
        return isCreateMeshOperationValid;
      default:
        return false;
    }
  };

  // Make sure all selected geoemtries are shown in the viewer together with those set visible by the user
  // todo hevo same logic in create - can we share ????
  useEffect(
    () => {
      hideAllItems();
      store.dispatch({ type: EWorkspaceGeometryActionType.SHOW_ALL_BUT_USER_HIDDEN });
      if (selectedGeometryIds) {
        selectedGeometryIds.forEach((id) => {
          showLayer(ELEMENT_CATEGORIES.GEOMETRY, id);
        });
      }
    },
    [selectedGeometryIds],
  );

  const onPanelExit = () => {
    goBackToReferrer();
  };

  const expandbleButtonOptions = [
    {
      label: t('MESH_SAVE_AND_GENERATE'),
      callBack: createMesh,
      disabled: generateDisabled,
    },
    {
      label: t('MESH_SAVE'),
      callBack: saveMesh,
      disabled: saveDisabled,
    },
  ];

  if (isLoading) {
    return (
      <MmgPanelSubsection>
        <CircularProgress />
      </MmgPanelSubsection>
    );
  }

  if (isLoadingFailed) {
    return (
      <MmgPanelSubsection>
        <p>
          {createMeshConfigurationLoadingFailed
            ? t('FAILED_TO_LOAD_PREVIOUS_MESH_OPERATIONS')
            : t('FAILED_TO_LOAD_AVAILABLE_CREATE_MESH_OPERATIONS')}
        </p>

        <MikeButton color="secondary" variant="contained" onClick={retryFailedLoading}>{t('RETRY')}</MikeButton>
      </MmgPanelSubsection>
    );
  }

  if (!createMeshConfiguration) {
    return <></>;
  }

  return (
    <>
      <MmgStepper cssProp={stepperStickyPanelStyle} activeStep={activeStep}>
        <MmgStep key={'STEP_SELECT_GEOMETRIES'} index={0} />
        <MmgStep key={'STEP_CONFIGURE'} index={1} last={true} />
      </MmgStepper>

      {activeStep === EDIT_MESH_STEPS.CONFIGURE && (
        <MmgPanelTopActions>
          <MikeButton variant="outlined" color="secondary" onClick={addMoreItems}>
            <span css="mesh-button-text">{t('MESH_ADD_MESH_MEMBERS')}</span>
          </MikeButton>
        </MmgPanelTopActions>
      )}

      <MikeStickyPanelContent>
        <MmgStepContent
          step={EDIT_MESH_STEPS.SELECT_GEOMETRIES}
          activeStep={activeStep}
          index={0}
          className={isReadOnly ? meshConfigurationStyleReadOnly : ''}
        >
          <MmgGroup groupName={t('EDIT_MESH_SELECT_GEOMETRIES')} canBeHidden={false}>
            <MmgConnectedMeshConfigurationSelectGeometries
              selectedGeometryIds={selectedGeometryIds}
              onSelectionChanged={onSelectedGeometriesChanged}
            />
          </MmgGroup>
        </MmgStepContent>

        <MmgStepContent
          step={EDIT_MESH_STEPS.CONFIGURE}
          activeStep={activeStep}
          index={1}
          className={isReadOnly ? meshConfigurationStyleReadOnly : ''}
        >
          {selectedGeometries && selectedGeometries.length > 0 ? (
            <MmgMeshOperationConfiguration
              groupName={t('MESH_MEMBERS_CONFIGURATION')}
              workspaceId={workspaceId}
              initialCreateMeshOperationConfiguration={meshOperationConfiguration}
              geometries={selectedGeometries}
              workspaceQueries={workspaceQueries}
              workspaceVariables={workspaceVariables}
              basicCreateMeshOperationDescription={basicCreateMeshOperationDescription}
              contextualChildOperationDescriptions={contextualChildOperationDescriptions}
              onMeshOperationConfigurationChanged={onMeshOperationConfigurationChanged}
            />
          ) : (
            <MmgPanelSubsection>
              <p>{t('EDIT_MESH_NO_SELECTED_GEOMETRIES_TIP')}</p>
            </MmgPanelSubsection>
          )}
        </MmgStepContent>
      </MikeStickyPanelContent>

      <MikeStickyPanelBottomActions>
        {activeStep !== EDIT_MESH_STEPS.CONFIGURE && (
          <>
            <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
              {t('BACK')}
            </MikeButton>
            <MikeButton color="secondary" variant="contained" onClick={handleNext} disabled={!isStepComplete(activeStep)}>
              {t('NEXT')}
            </MikeButton>
          </>
        )}
        {activeStep === EDIT_MESH_STEPS.CONFIGURE && (
          <>
            <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
              {t('CANCEL')}
            </MikeButton>
            <MikeExpandableButton active={saveInProgress || meshingInProgress} options={expandbleButtonOptions} />
          </>
        )}
      </MikeStickyPanelBottomActions>
    </>
  );
};
