/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect, useCallback } from 'react';
import { isEqual, upperFirst, isEmpty } from 'lodash-es';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IOperationDescription } from '../../../models/IOperationDescriptions';
import { usePrevious } from '../../../shared/hooks/hooks';
import { MmgItemCard } from '../../../shared/cards/item-card';
import { LayerWithoutBorderStyle, SpatialSelectionLayer } from '../../../queries/spatial-selections/spatial-selection-layer';
import { MmgOperationConfigurationSummary } from '../../../operations/operation-configuration-summary';
import { MmgOperationConfiguration } from '../../../operations/operation-configuration';
import { MESH_OPERATION_PARAMETER_CONDITIONS } from '../mesh-configuration-constants';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { getVariableParametersValueStrings } from '../mesh-configuration-util';
import { Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../../store/reducers';
import { EWorkspaceQueryActionType } from '../../../store/actions/WorkspaceQueryActionType';
import mikeSharedTheme from '../../../shared/styles/mikeSharedTheme';
import { useParams } from 'react-router-dom';

type MeshOperationQueryConfigurationProps = {
  geometryQuery: IWorkspaceQuery;
  initialQueryConfiguration: IOperationConfiguration;
  queryOperationDescriptions: {
    [operationKey: string]: IOperationDescription;
  };
  workspaceVariables: Array<IWorkspaceEnrichedVariable>;
  isQueryIncluded: boolean;
  onQueryIncludedChanged?: (queryId: string, selected: boolean) => void;
  onOperationConfigurationChanged?: (operationConfigurations: IOperationConfiguration) => void;
  dirtyParentConfiguration?: IOperationConfiguration;
};

// todo hevo almost the same as in MmgMeshInterpolationVariableConfiguration. Why is different margin needed???
const SwitchCss = css`
  &.MuiSwitch-root {
    margin-right: ${mikeSharedTheme.spacing(1)};
  }
`;

/**
 * @name MmgMeshOperationQueryConfiguration
 * @summary Generic component that given a saved query and a list of mesh childoperation descriptions for that query, allows configuring the operation for the query.
 * Will supply the complete list of updated configuration objects via a callback.
 * @param props
 */
export const MmgMeshOperationQueryConfiguration = (props: MeshOperationQueryConfigurationProps) => {
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const shownSelectionIds = useSelector((state: IGlobalState) => state.WorkspaceQueryReducer.shownSelectionIds);
  const {
    geometryQuery,
    workspaceVariables,
    initialQueryConfiguration,
    queryOperationDescriptions,
    isQueryIncluded,
    onOperationConfigurationChanged,
    onQueryIncludedChanged,
    dirtyParentConfiguration,
  } = props;

  const [dirtyQueryConfiguration, setDirtyQueryConfiguration] = useState({} as IOperationConfiguration); // Object with current configuration. This will be sent higher up the component chain and used for submission.

  const previousOperationDescriptions = usePrevious(queryOperationDescriptions) as {
    [operationKey: string]: IOperationDescription;
  };

  const createQueryConfigurationFromDescription = useCallback(
    (operationKey: string) => {
      if (!operationKey) {
        return null;
      }
      const operationDescriptionForKey = queryOperationDescriptions[operationKey];
      if (!operationDescriptionForKey) {
        return null;
      }
      const { operationType } = operationDescriptionForKey;
      const configuration = {
        type: upperFirst(operationType),
        operationType,
        inputIds: [geometryQuery.id],
        parameters: {},
      };
      return configuration;
    },
    [queryOperationDescriptions, geometryQuery],
  );

  const onQueryConfigurationsChanged = (operationConfiguration: IOperationConfiguration) => {
    const nextConfiguration = {
      ...operationConfiguration,
      inputIds: [geometryQuery.id],
    };
    setDirtyQueryConfiguration(nextConfiguration);
    callOnOperationConfigurationChanged(nextConfiguration);
  };

  const callOnOperationConfigurationChanged = useCallback(
    (newQueryConfiguration) => {
      // only call if configuration actually changed
      if (onOperationConfigurationChanged && !isEqual(newQueryConfiguration, initialQueryConfiguration)) {
        onOperationConfigurationChanged(newQueryConfiguration);
      }
    },
    [onOperationConfigurationChanged, initialQueryConfiguration],
  );

  const toggleIncluded = (workspaceId: string, selectionId: string) => {
    const newValue = !isQueryIncluded;
    const isShown = shownSelectionIds.includes(selectionId);

    if (!isShown) {
      dispatch({ type: EWorkspaceQueryActionType.SELECTION_RESULT_TOGGLE_VISIBILITY, data: {workspaceId, selectionId } });
    } else {
      dispatch({ type: EWorkspaceQueryActionType.SHOWN_SELECTION_GEOMETRY_REMOVE, data: selectionId });
    }
    //toggleAction && toggleAction[0] && toggleAction[0].onAction();
    if (onQueryIncludedChanged) {
      onQueryIncludedChanged(selectionId, newValue);
    }
  };

  useEffect(
    () => {
      if (queryOperationDescriptions && !isEqual(previousOperationDescriptions, queryOperationDescriptions)) {
        // Every time contextualOperations change, queries get assigned a configuration by updating the state of `queryConfiguration`.
        const operationKeys = Object.keys(queryOperationDescriptions);
        const firstOperationKey = operationKeys[0];
        const nextConfiguration = {
          ...(initialQueryConfiguration || createQueryConfigurationFromDescription(firstOperationKey)),
        };
        setDirtyQueryConfiguration(nextConfiguration);
        callOnOperationConfigurationChanged(nextConfiguration);
      }
    },
    [
      initialQueryConfiguration,
      previousOperationDescriptions,
      queryOperationDescriptions,
      geometryQuery,
      callOnOperationConfigurationChanged,
      createQueryConfigurationFromDescription,
    ],
  );

  // if no supported operations exists, do not show anything
  if (!queryOperationDescriptions || isEmpty(queryOperationDescriptions)) {
    return null;
  }

  const firstOperationDescription = (queryOperationDescriptions
    ? queryOperationDescriptions[Object.keys(queryOperationDescriptions)[0]]
    : null) as IOperationDescription;

  const selectedOperationDescription = dirtyQueryConfiguration
    ? queryOperationDescriptions[dirtyQueryConfiguration.operationType]
    : firstOperationDescription;

  const variableValueStrings = getVariableParametersValueStrings(dirtyQueryConfiguration, workspaceVariables);

  return (
    <MmgItemCard
      cardLayer={
        /* TODO dan: this should show if the item on this layer is 'in progress' / show a spinner or failed, etc */
        // hide the icon in this case
        <SpatialSelectionLayer
          layerId={geometryQuery.id}
          layerName={geometryQuery.name}
          cssProp={LayerWithoutBorderStyle}
          showVisibilityIcon={false}
          leftActions={
            <Switch
              id={geometryQuery.id}
              checked={Boolean(isQueryIncluded)}
              value={Boolean(isQueryIncluded)}
              onClick={() => toggleIncluded(workspaceId, geometryQuery.id)}
              css={SwitchCss}
            />
          }
        />
      }
      cardSummary={
        <>
          <MmgOperationConfigurationSummary
            operationConfiguration={dirtyQueryConfiguration}
            showOperationName={false}
            customValueStrings={variableValueStrings}
            parameterConditionDescriptions={MESH_OPERATION_PARAMETER_CONDITIONS}
          />
        </>
      }
      open={isQueryIncluded}
    >
      <MmgOperationConfiguration
        operationDescription={selectedOperationDescription}
        initialOperationConfiguration={initialQueryConfiguration}
        onConfigurationChanged={onQueryConfigurationsChanged}
        parameterConditionDescriptions={MESH_OPERATION_PARAMETER_CONDITIONS}
        dirtyParentConfiguration={dirtyParentConfiguration}
      />
    </MmgItemCard>
  );
};
