import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { useSelector } from 'react-redux';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgConnectedGeometryOperationsSection } from '../operations/geometry-operation-section';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { store } from '../../store/store';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgPanelTopActions } from '../../shared/panels/panel-top-actions';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { getGeometryOperationPanelDescription } from '../../shared/panels/panel-utils';
import { isLayerWorking } from '../../shared/layers/layer-utils';
import { LinearProgress } from '@mui/material';
import { EWorkspaceVariableActionType } from '../../store/actions/WorkspaceVariableActionType';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeButton from '../../shared-components/mike-button';

/**
 * @name MmgConnectedGeometryOperationMultiplePanel
 * @summary Allows performing 'geometry operation' on the currently selected geometries
 */

export const MmgConnectedGeometryOperationMultiplePanel = () => {
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate()
  const selectedGeometries: Array<IWorkspaceEnrichedGeometry> = useSelector(
    WorkspaceGeometrySelectors.getSelectedEnrichedWorkspaceGeometries,
  );
  const drawnGeometries: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );

  const count = selectedGeometries ? selectedGeometries.length : 0;
  const anyGeometries = count > 0;

  const title = t('GEOMETRY_OPERATION_MULTIPLE_PANEL_TITLE');

  const onPanelExit = () => {
    navigate(getRouteByPath(ROUTES.geometriesOperationSelectItemsPanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
  };

  // When an operation is submitted succesfully we currently navigate to workspace panel
  const onOperationSubmitted = (success: boolean) => {
    if (success) {
      navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));       
    }
  };

  const onAddMoreItems = () => {
    navigate(getRouteByPath(ROUTES.geometriesOperationSelectItemsPanel.path, { workspaceId, projectId }, ROUTES.workspace.path));  
  };

  // make sure only geometries are shown in the viewer when this component is mounting.
  // Whatever geometries are currently shown, will be kept visibile
  // todo hevo should only show selected geoemtries, and then show what was previously shown when unmounting
  useEffect(() => {
    store.dispatch({
      type: EWorkspaceMeshActionType.HIDE_ALL,
    });
    store.dispatch({
      type: EWorkspaceVariableActionType.HIDE_ALL,
    });
  }, []);

  const geometryIds = (selectedGeometries || []).map(({ id }) => id);

  const description =
    count === 0
      ? ''
      : count > 1
        ? t('GEOMETRY_OPERATION_MULTIPLE_PANEL_DESCRIPTION', count, {
            count,
          })
        : getGeometryOperationPanelDescription(selectedGeometries[0], drawnGeometries);

  const isAnyWorking = count === 1 ? isLayerWorking(selectedGeometries[0], drawnGeometries) : false;

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={title} panelDescription={description} onPanelExit={onPanelExit} />
      </MikeStickyPanelHeaderContainer>

      {isAnyWorking && <LinearProgress />}
      <MmgPanelTopActions>
        <MikeButton variant="outlined" color="secondary" onClick={onAddMoreItems}>
          {t('SELECT_MORE_GEOMETRIES')}
        </MikeButton>
      </MmgPanelTopActions>

      {!anyGeometries && (
        <MmgPanelSubsection>
          <p>{t('SELECT_GEOM_FOR_OPERATION_TIP')}</p>
        </MmgPanelSubsection>
      )}

      <MmgConnectedGeometryOperationsSection
        projectId={projectId}
        workspaceId={workspaceId}
        geometryIds={geometryIds}
        onOperationSubmitted={onOperationSubmitted}
      />
    </MikeStickyPanel>
  );
};
