import { useEffect, useRef } from 'react';
import WDISelectors, { IDataItemsArray } from '../../store/selectors/WorkspaceDataItemSelectors';
import { isEqual, orderBy } from 'lodash-es';
import LayerUtils from '../../shared/layers/layer-utils';
import { EElementCategories } from '../../shared/panels/mesh-panel-constants';
import { useSelector } from 'react-redux';
const { hideOtherLayers } = LayerUtils;

// Order dataItems by id:
const order = (dataItems: IDataItemsArray): IDataItemsArray => {
  return orderBy(dataItems, ['id']) as IDataItemsArray;
};

interface IArg {
  elementId: string;
  elementCategory: EElementCategories;
}

/**
 * This hook hides other layers in the viewer except the one
 * that the user is currently working on. The hook runs either
 * when layerId changes or when new items are added/removed to
 * the viewer, ie when one of these parameters change:
 * `geometries, variables, meshes, layerId, elementCategory`
 *
 * @param arg
 * @param arg.elementId
 * @param arg.elementCategory
 */
export const useHideOtherLayers = (arg: IArg) => {
  const dataItems = useSelector(mapGlobalState);
  const { elementId, elementCategory } = arg;
  // TODO: joel; Ideally want to use elementId inside the same effect iso separate effects.
  const prevItems = useRef(dataItems);

  // Hide when layerId changes (e.g. when user opens panel):
  useEffect(
    // TODO: joel; Can we remove this? It seems to have no effect.
    // If I remove this effect, everything still seems to work
    // as expected (both refresh and click on panel).
    () => {
      if (elementId) {
        hideOtherLayers(elementCategory, elementId);
      }
    },
    [elementId, elementCategory],
  );

  // Hide when other items are added/removed in the viewer:
  useEffect(
    () => {
      if (!elementId) {
        return;
      }
      // Sort arrays before comparing them to avoid false differences:
      if (!isEqual(order(prevItems.current), order(dataItems))) {
        hideOtherLayers(elementCategory, elementId);
        prevItems.current = dataItems;
      }
    },
    [dataItems, elementId, elementCategory],
  );
};

const mapGlobalState = (state) => {
  return WDISelectors.getWorkspaceDataItems(state);
};
