import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { useNavigate } from 'react-router';
import { ROUTES, getRouteByPath } from '../../app/routes';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils, { fetchLayer, fetchTiledLayer } from '../../shared/layers/layer-utils';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { t } from '../../translations/i18n';
import { MmgConnectedMeshPostOperationsSection } from './mesh-post-operation-section';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { LinearProgress } from '@mui/material';
import { getMeshOperationPanelDescription } from '../../shared/panels/panel-utils';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { useParams } from 'react-router-dom';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeVisualizerViewManager from '../../MikeVisualizer/lib/MikeVisualizerViewManager';

const { hideOtherLayers, isLayerWorking } = LayerUtils;

/**
 * @name MmgConnectedMeshPostOperationPanel
 * @summary Allows performing 'mesh post operation'  on the current mesh, also referred to as modifymesh operations.
 */
export const MmgConnectedMeshPostOperationPanel = () => {
  const { projectId, workspaceId, meshId } = useParams<any>();
  const navigate = useNavigate();
  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();

  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);

  const mesh: IWorkspaceEnrichedMesh | null = useSelector((state: IGlobalState) =>
    getMeshSelectorInstance(state, { meshId }),
  );
  const meshDrawnData: IDrawnDataItem | null = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId }),
  );

  const { goBackToReferrer } = useNavigateBack();

  const title = t('POST_OPERATIONS_TITLE');

  // When an operation is submitted succesfully we go back. Otherwise we stay.
  // If going back, we exclude the hash-tag, if any.
  // Basically this will go back to the overview tab, if opened from mesh details.
  const onOperationSubmitted = (success: boolean) => {
    if (success) {
      goBackToReferrer(true);
    }
  };

  // todo hevo show/hide items should be as in mesh detail
  // make sure the only item shown in the viewer is the mesh at hand when the mesh is loaded
  useEffect(
    () => {
      if (mesh && mesh.id) {
        if (!loadedData.includes(mesh.id)) {
          if (mesh.isTiled) {
            const { getCurrentViewBounds } = MikeVisualizerViewManager;
            const bounds = getCurrentViewBounds();
            fetchTiledLayer(mesh.id, [], bounds, false);
          } else {
            fetchLayer(mesh.id, ELEMENT_CATEGORIES.MESH);
          }
        }
        hideOtherLayers(ELEMENT_CATEGORIES.MESH, mesh.id);
      }
    },
    [loadedData, mesh],
  );

  const drawnMeshes = meshDrawnData ? [meshDrawnData] : [];
  const isMeshWorking = isLayerWorking(mesh, drawnMeshes);

  const description = getMeshOperationPanelDescription(mesh, drawnMeshes);

  if (!mesh) {
    navigate(getRouteByPath(ROUTES.meshDetailsPanel.path, { projectId, workspaceId, meshId }, ROUTES.workspace.path));
  }

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <MmgPanelHeader panelTitle={title} panelDescription={description} />
        </MikeStickyPanelHeaderContainer>

        {isMeshWorking && <LinearProgress />}

        <MmgConnectedMeshPostOperationsSection
          projectId={projectId}
          workspaceId={workspaceId}
          meshId={meshId}
          onOperationSubmitted={onOperationSubmitted}
        />
      </MikeStickyPanel>
    </>
  );
};
