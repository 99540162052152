import React, { useState, useMemo } from 'react';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import { useSelector } from 'react-redux';
import { IOperationConfiguration } from '../../models/IOperations';
import { t } from '../../translations/i18n';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { useAsyncLoadContextualInterpolationDescriptions } from './useAsyncLoadContextualInterpolationDescriptions';
import { submitMeshInterpolation } from './mesh-interpolation-util';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { MmgMeshInterpolationConfiguration } from './configuration/mesh-interpolation-configuration';
import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import LayerUtils from '../../shared/layers/layer-utils';
import { MmgConnectedMeshInterpolationSelectVariables } from './mesh-interpolation-select-variables';
import { getDataItemsByIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { getMessageFromOperation } from '../../store/selectors/WorkspaceOperationUtils';
import { isInterpolationChildOperationsValid } from '../../managers/MeshInterpolationValidation';
import { MmgGroup } from '../../shared/groups/group';
import { MmgStepper } from '../../shared/steppers/stepper';
import { MmgStepContent } from '../../shared/steppers/step-content';
import { MmgStep } from '../../shared/steppers/step';
import { MmgPanelTopActions } from '../../shared/panels/panel-top-actions';
import { stepperStickyPanelStyle } from '../../shared/steppers/stepper-styles';
import { IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import { createOperationConfigurationFromDescription } from '../../operations/operation-utils';
import { IOperationDescription, IContextualOperationDescriptions } from '../../models/IOperationDescriptions';
import { useIsMounted } from '../../shared/hooks/hooks';
import { useStepper } from '../../shared/steppers/useStepper';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { useMount } from 'react-use';
import { store } from '../../store';
import { IGlobalState } from '../../store/reducers';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeExpandableButton from '../../shared-components/mike-expandable-button';

const { isLayerInProgress, isMeshInterpolationAllowed, isLayerFailed } = LayerUtils;

type MeshCreateInterpolationConfigurationProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
  onOperationSubmitSuccess?: (execute: boolean) => void;
};

const CREATE_INTERPOLATION_STEPS = {
  OPERATION_AND_VARIABLES: 0,
  CONFIGURE: 1,
};

const DEFAULT_OPERATION_KEY = 'meshInterpolationOperationDefinition';

/**
 * @name MmgConnectedMeshCreateInterpolationConfiguration
 * @summary Allows configuring new  mesh interpolation.
 * Supports both 'basic'/'base' configurations and variable configurations.
 *
 * @param props
 */
export const MmgConnectedMeshCreateInterpolationConfiguration = (props: MeshCreateInterpolationConfigurationProps) => {
  const { projectId, workspaceId, meshId, onOperationSubmitSuccess } = props;

  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getMeshAttributesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributes();
  const getMeshAttributeSettingsSelectorInstance = WorkspaceMeshSelectors.makeGetMeshAttributeSettings();
  const getMeshQueriesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshQueries();
  const mesh: IWorkspaceEnrichedMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const workspaceVariables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );
  const meshAttributes: Array<IWorkspaceAttribute> =
    useSelector((state: IGlobalState) => getMeshAttributesSelectorInstance(state, { meshId })) || [];
  const interpolationType: string = useSelector((state: IGlobalState) => state.WorkspaceMeshReducer.interpolationType);
  const meshAttributeSettings: Array<IWorkspaceAttributeSettings> =
    useSelector((state: IGlobalState) => getMeshAttributeSettingsSelectorInstance(state, { meshId })) || [];
  const meshQueries = useSelector((state: IGlobalState) => getMeshQueriesSelectorInstance(state, { meshId })) || [];

  const isMounted = useIsMounted();
  const { goBackToReferrer } = useNavigateBack();

  const [interpolationInProgress, setInterpolationInProgress] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [interpolationConfiguration, setInterpolationConfiguration] = useState(null as IOperationConfiguration);

  const selectedOperationKey = DEFAULT_OPERATION_KEY;

  const [selectedVariableIds, setSelectedVariableIds] = React.useState([] as Array<string>);

  const { activeStep, handleNext, goToStep, handleBack } = useStepper(
    CREATE_INTERPOLATION_STEPS.OPERATION_AND_VARIABLES,
  );

  useMount(() => {
    const interpolationDescription =
      (interpolationDescriptions || [])[selectedOperationKey] ||
      ({} as {
        basicOperationDescription: IOperationDescription;
        childContextualOperationDescriptions: IContextualOperationDescriptions;
      });

    const basicConfiguration = createOperationConfigurationFromDescription(
      interpolationDescription.basicOperationDescription,
    );

    // reset configuration and selected variables
    setInterpolationConfiguration(basicConfiguration);
    setSelectedVariableIds([]);
  });

  /**
   * Effectively checks if any variables are selected to be included in the interpolation.
   */
  const anyVariablesSelected = selectedVariableIds && selectedVariableIds.length > 0;

  const retryFailedLoading = () => {
    if (interpolationDescriptionsLoadingFailed) {
      retryLoadInterpolationDescriptions();
    }
  };

  /**
   * Saves changes without executing the interpolations.
   */
  const saveInterpolation = () => submitInterpolation(false);

  /**
   * Saves changes and executes the interpolation.
   */
  const executeInterpolation = () => submitInterpolation(true);

  /**
   * Callback to when the 'add more' button is pressed.
   */
  const addMoreItems = () => {
    goToStep(CREATE_INTERPOLATION_STEPS.OPERATION_AND_VARIABLES);
  };

  /**
   * saves the interpolation operation, optionally executes it.
   *
   * @param execute
   */
  const submitInterpolation = (execute: boolean) => {
    if (!interpolationConfiguration) {
      // nothing to submit
      return;
    }

    const { childOperations, postOperation, parameters } = interpolationConfiguration;

    const defaultParams = {
      propertyName: 'Elevation',
    };

    // Other inpolation must provide a property other than 'Elevation' - else backend cannot distinguish to 'Elevation interpolation'
    if (parameters) {
      if (!parameters.propertyName || parameters.propertyName === defaultParams.propertyName) {
        const toast = {
          text: t('MESH_SAVE_AND_RUN_INTERPOLATION_FORBIDDEN_PROPERTY_HINT') + ' ' + defaultParams.propertyName,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        return;
      }
    }

    if (execute) {
      setInterpolationInProgress(true);
    } else {
      setSaveInProgress(true);
    }

    // only include child operations of selected variables
    const filteredChildOperations =
      childOperations &&
      childOperations.filter(
        // we know that there is currently only one variable per child operation.
        ({ inputIds }) => selectedVariableIds.indexOf(inputIds[0]) !== -1,
      );

    //we set the default definition for elevation
    const elevationOperationDefinition = {
      type: 'MeshInterpolationOperationDefinition',
    };

    //if we have parameters we build a different config
    const operationConfigurationToSubmit: any = {
      parameters: parameters ? parameters : defaultParams,
      ...elevationOperationDefinition,
      operationType: 'MeshInterpolationOperationDefinition',
      childOperations: filteredChildOperations,
      inputIds: [meshId],
      postOperation,
    };

    submitMeshInterpolation(workspaceId, operationConfigurationToSubmit, execute)
      .then(() => {
        if (onOperationSubmitSuccess) {
          onOperationSubmitSuccess(execute);
        }
      })
      .finally(() => {
        if (isMounted()) {
          setInterpolationInProgress(false);
          setSaveInProgress(false);
        }
      });
  };

  /**
   * Callback for when operation configuration has changed
   *
   * @param operationConfiguration
   */
  const onInterpolationConfigurationChanged = (operationConfiguration: IOperationConfiguration) => {
    setInterpolationConfiguration(operationConfiguration);
  };

  const onPanelExit = () => {
    goBackToReferrer();
  };

  /**
   * Sets the selected variables the selected ones.
   *
   * @param selectedIds
   */
  const onSelectedVariablesChanged = (selectedIds: Array<string>) => {
    setSelectedVariableIds(selectedIds);
    // we do not reset interplationConfiguration as we do not want to loose any changes already made by the user
  };

  /**
   * Gets the contextual operation descriptions based on variables.
   */
  const {
    interpolationDescriptions,
    interpolationDescriptionsLoading,
    interpolationDescriptionsLoadingFailed,
    retry: retryLoadInterpolationDescriptions,
  } = useAsyncLoadContextualInterpolationDescriptions(workspaceId, workspaceVariables);

  const selectedVariables = useMemo(
    () => {
      return getDataItemsByIds(workspaceVariables, selectedVariableIds);
    },
    [workspaceVariables, selectedVariableIds],
  );

  const isMeshMissing = !mesh;

  if (isMeshMissing) {
    return <></>;
  }

  //User can do some work even if the mesh is not loaded.
  const isMeshInProgress = isLayerInProgress(mesh);

  const isInterpolationAllowed = isMeshInterpolationAllowed(mesh);

  const isInterpolationsLoading = interpolationDescriptionsLoading;

  // todo hevo should update this based on operationmetadata
  const isOperationFailed = isLayerFailed(interpolationConfiguration);
  const message = getMessageFromOperation(interpolationConfiguration);

  const isInterpolationOperationValid =
    meshId && isInterpolationChildOperationsValid((interpolationConfiguration || {}).childOperations);

  const saveDisabled =
    !meshId || isInterpolationsLoading || interpolationInProgress || saveInProgress || !isInterpolationOperationValid;

  const interpolateDisabled = saveDisabled || !isInterpolationAllowed;

  const selectedInterpolationDescription =
    (interpolationDescriptions || [])[selectedOperationKey] ||
    ({} as {
      basicOperationDescription: IOperationDescription;
      childContextualOperationDescriptions: IContextualOperationDescriptions;
      postOperationDescriptions: IOperationDescription;
    });

  const {
    basicOperationDescription,
    childContextualOperationDescriptions,
    postOperationDescription,
  } = selectedInterpolationDescription;

  // we expect one key per variable
  const allowedVariableIds = Object.keys(childContextualOperationDescriptions || {});

  const isStepComplete = (step: number) => {
    switch (step) {
      case CREATE_INTERPOLATION_STEPS.OPERATION_AND_VARIABLES:
        return selectedOperationKey && selectedVariableIds && selectedVariableIds.length > 0;
      case CREATE_INTERPOLATION_STEPS.CONFIGURE:
        return isInterpolationOperationValid;
      default:
        return false;
    }
  };

  const expandbleButtonOptions = [
    {
      label: t('MESH_SAVE_AND_RUN_INTERPOLATION'),
      callBack: executeInterpolation,
      disabled: interpolateDisabled,
    },
    {
      label: t('MESH_SAVE_INTERPOLATION'),
      callBack: saveInterpolation,
      saveDisabled,
    },
  ];

  if (interpolationDescriptionsLoadingFailed) {
    return (
      <MmgPanelSubsection>
        <p>{t('FAILED_TO_LOAD_AVAILABLE_MESH_INTERPOLATIONS')}</p>

        <MikeButton variant="contained" onClick={retryFailedLoading}>{t('RETRY')}</MikeButton>
      </MmgPanelSubsection>
    );
  }

  return (
    <>
      {(isMeshInProgress || interpolationInProgress) && (
        <MmgPanelSubsection>
          <p>{t('CONFIGURE_AFTER_DATA_PROCESSED')}</p>
        </MmgPanelSubsection>
      )}

      <MmgStepper cssProp={stepperStickyPanelStyle} activeStep={activeStep}>
        <MmgStep key={'STEP_OPERATION_AND_VARIABLES'} index={0} />
        <MmgStep key={'STEP_CONFIGURE'} index={1} last={true} />
      </MmgStepper>

      {isOperationFailed &&
        !isMeshInProgress &&
        !interpolationInProgress && (
          <MmgMessageBanner messageTitle={t('MESH_INTERPOLATION_FAILED')} message={message} messageType="failed" />
        )}
      {activeStep === CREATE_INTERPOLATION_STEPS.CONFIGURE && (
        <MmgPanelTopActions>
          <MikeButton variant="outlined" color="secondary" onClick={addMoreItems}>
            <span css="mesh-button-text">{t('MESH_INTERPOLATION_ADD_VARIABLES')}</span>
          </MikeButton>
        </MmgPanelTopActions>
      )}

      <MmgStepContent step={CREATE_INTERPOLATION_STEPS.OPERATION_AND_VARIABLES} activeStep={activeStep} index={0}>
        <MmgGroup groupName={t('INTERPOLATION_STEP_VARIABLES_AND_OPERATION')} canBeHidden={false}>
          {/* todo hevo handle no allowed variables  and not loading */}
          {/*If any variables are allowed the user to select some */}
          {allowedVariableIds &&
            allowedVariableIds.length > 0 && (
              <MmgPanelSubsection>
                <MmgConnectedMeshInterpolationSelectVariables
                  allowedVariableIds={allowedVariableIds}
                  selectedVariableIds={selectedVariableIds}
                  onSelectionChanged={onSelectedVariablesChanged}
                />
              </MmgPanelSubsection>
            )}
        </MmgGroup>
      </MmgStepContent>

      <MmgStepContent step={CREATE_INTERPOLATION_STEPS.CONFIGURE} activeStep={activeStep} index={1}>
        {selectedOperationKey &&
          anyVariablesSelected && (
            <MmgMeshInterpolationConfiguration
              projectId={projectId}
              workspaceId={workspaceId}
              meshId={meshId}
              variables={selectedVariables}
              initialMeshInterpolationsConfiguration={interpolationConfiguration}
              basicMeshInterpolationDescription={basicOperationDescription}
              mappedPostOperationContextualOperationDescriptions={postOperationDescription}
              contextualChildOperationDescriptions={childContextualOperationDescriptions}
              onMeshInterpolationConfigurationChanged={onInterpolationConfigurationChanged}
              meshAttributes={meshAttributes}
              meshAttributeSettings={meshAttributeSettings}
              meshQueries={meshQueries}
              interpolationType={interpolationType}
            />
          )}
      </MmgStepContent>

      <MikeStickyPanelBottomActions>
        {activeStep !== CREATE_INTERPOLATION_STEPS.CONFIGURE && (
          <>
            <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
              {t('CANCEL')}
            </MikeButton>
            <MikeButton variant="contained" color="secondary" onClick={handleNext} disabled={!isStepComplete(activeStep)}>
              {t('NEXT')}
            </MikeButton>
          </>
        )}

        {activeStep === CREATE_INTERPOLATION_STEPS.CONFIGURE && (
          <>
            <MikeButton variant="outlined" color="secondary" onClick={handleBack}>
              {t('BACK')}
            </MikeButton>
            <MikeExpandableButton active={saveInProgress || interpolationInProgress} options={expandbleButtonOptions} />
          </>
        )}
      </MikeStickyPanelBottomActions>
    </>
  );
};
