/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMatch } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Close from '../../icons/Close.svg?react';
import Alert from '../../icons/Alert.svg?react';
import { t } from '../../translations/i18n';
import { IProject } from '../../models/IProject';
import mikeSharedTheme, { mikePalette } from '../../shared/styles/mikeSharedTheme';
import MikeButton from '../../shared-components/mike-button';

interface IProps {
  /**
   * Banner has been dismissed.
   */
  dismissed?: boolean;
  /**
   * Dismiss callback.
   */
  onDismissCallBack: () => void;
  /**
   * Project.
   */
  project: IProject;
}

const iconStyle = (disabled = false) => {
  const color = disabled ? mikePalette.secondary.light : mikePalette.secondary.main;
  return css`
    path {
      fill: ${color};
    }
  `;
};

const rootStyle = css`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${mikeSharedTheme.spacing(6)};
  background-color: ${mikeSharedTheme.palette.background.paper};
  color: ${mikeSharedTheme.palette.primary.main};
  position: absolute;
  z-index: ${Math.round(mikeSharedTheme.zIndex.appBar * 0.98)};
  border-bottom: 1px solid ${mikeSharedTheme.palette.mediumGrey.main};
  box-shadow: 0 0 5px ${mikeSharedTheme.palette.darkGrey.main};
`;

  const warningContainerStyle = css`
    display: flex;
    align-items: center;
    paddingLeft: ${mikeSharedTheme.spacing(2.5)};
 `;

  const warningTextStyle = css`
    padding-left: ${mikeSharedTheme.spacing(1.5)};
 `;

 const alertContainerStyle = css`
    height: ${mikeSharedTheme.spacing(3)};
    width: ${mikeSharedTheme.spacing(3)};
    border-radius: ${mikeSharedTheme.spacing(1.5)};
    background-color: ${mikeSharedTheme.palette.error.main};
    margin: auto;
 `;
  const alertSvgStyle = css`
    & path {
      fill: #fff;
      transform: translate(-8px, -8px);
    };
    & path:first-of-type {
      fill: transparent;
      stroke: #fff;
      stroke-width: 2px;
      stroke-join: miter;
    },
  `;

/**
 * @name MmgProjectReadOnlyBanner
 * @summary The support bottom, opening the support menu.
 *
 *  @param props
 */
export const MmgProjectReadOnlyBanner = (props: IProps) => {
  const match = useMatch({
    path: '/project/:id'
  });
 
  const { onDismissCallBack, dismissed = false, project } = props;
  const isAccessAllowed = project && project.capabilities && project.capabilities.canUpdateContent;

  if (dismissed || !project) {
    return <></>;
  }

  return (
    match &&
    !isAccessAllowed && (
      <div css={rootStyle}>
        <div css={warningContainerStyle}>
          <div css={alertContainerStyle}>
            <Alert
              css={alertSvgStyle}
              width={undefined} // overrules the default 40 value
              height={undefined} // overrules the default 40 value
              viewBox="-6.5 -5.5 37 37"
            />
          </div>

          <Typography css={warningTextStyle} variant="body2">
            {t('PROJECTS_READONLY_ACCESS')}
          </Typography>
        </div>

        <MikeButton variant="text" onClick={onDismissCallBack}>
          {t('DISMISS')}
          <Close css={iconStyle()} />
        </MikeButton>
      </div>
    )
  );
};

export default MmgProjectReadOnlyBanner;
