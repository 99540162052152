import { useState, useEffect, useCallback } from 'react';

import MeshOperationsManager from '../../managers/MeshOperationsManager';
import { IOperationConfiguration } from '../../models/IOperations';
import { usePrevious } from '../../shared/hooks/hooks';

/**
 * Custom hook that loads the current create mesh operation configuration for the mesh given
 * @param workspaceId
 * @param meshId
 */
export const useAsyncLoadCreateMeshConfiguration = (
  workspaceId: string,
  meshId: string,
): {
  createMeshConfiguration: IOperationConfiguration;
  createMeshConfigurationLoading: boolean;
  createMeshConfigurationLoadingFailed: boolean;
  setCreateMeshConfiguration: (operaionConfiguration: IOperationConfiguration) => void;
  retryLoadCreateMeshConfiguration: () => void;
} => {
  const [createMeshConfigurationLoading, setCreateMeshConfigurationLoading] = useState(false);

  const [createMeshConfigurationLoadingFailed, setCreateMeshConfigurationLoadingFailed] = useState(false);

  const [createMeshConfiguration, setCreateMeshConfiguration] = useState<IOperationConfiguration>(null);

  const prevWorkspaceId = usePrevious(workspaceId);
  const prevMeshId = usePrevious(meshId);

  /**
   * Gets the latest mesh create mesh operation configuration
   */
  const getCreateMeshOperationsConfiguration = useCallback(
    (force: boolean) => {
      if (!workspaceId || !meshId || (!force && prevWorkspaceId === workspaceId && prevMeshId === meshId)) {
        return Promise.resolve();
      }

      setCreateMeshConfigurationLoading(true);
      setCreateMeshConfigurationLoadingFailed(false);

      return MeshOperationsManager.getCurrentCreateMeshOperations(workspaceId, meshId)
        .then((operations) => {
          if (!operations || operations.length === 0) {
            return Promise.resolve(null);
          }
          setCreateMeshConfiguration(operations[0]);
        })
        .catch((error) => {
          setCreateMeshConfigurationLoadingFailed(true);
          console.error('Failed to get create mesh operation');
          throw error;
        })
        .finally(() => {
          setCreateMeshConfigurationLoading(false);
        });
    },
    [workspaceId, meshId, prevWorkspaceId, prevMeshId],
  );

  const retryLoadCreateMeshConfiguration = () => {
    getCreateMeshOperationsConfiguration(true);
  };

  // load mesh current create mesh
  useEffect(
    () => {
      if (meshId) {
        getCreateMeshOperationsConfiguration(false);
      }
    },
    [getCreateMeshOperationsConfiguration, meshId],
  );

  return {
    createMeshConfiguration,
    createMeshConfigurationLoading,
    createMeshConfigurationLoadingFailed,
    setCreateMeshConfiguration,
    retryLoadCreateMeshConfiguration,
  };
};
