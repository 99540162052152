/**
 * This file exports configuration, mainly related to API endpoints.
 *
 * @module Config
 * @version 4.0.0
 */

/**
 * Paths are URL pieces for a certain entity; also called resource names or resource paths.
 *
 * @name paths
 * @type { Object }
 */
export const paths = {
  path: 'path',
  subprojects: 'subprojects',
  project: 'projects',
  dataset: 'datasets',
  workspaces: 'workspaces',
  geometries: 'geometries',
  variables: 'variables',
  meshes: 'meshes',
  selection: 'selection',
  configuration: 'configuration',
  operations: 'operations',
  attributes: 'attributes',
  statistics: 'statistics',
  queries: 'queries',
  projections: 'coordinate-systems',
  comments: 'comments',
  comment: 'comment',
  definitions: 'definitions',
  descriptions: 'descriptions',
  creation: 'create-mesh',
  modifyMesh: 'modify-mesh',
  execute: 'execute',
  duplicate: 'duplicate',
  data: 'data',
  interpolate: 'interpolate-mesh',
  nodes: 'nodes',
  onTheFlyOperations: 'geojson',
  properties: 'properties',
  opendataproviders: 'opendataproviders',
  extract: 'extract',
  replaceItem: 'replaceItem',
  withId: 'with',
};

const httpProtocol = 'https';
// const mode = import.meta.env.MODE;
const apiDomain = window.location.origin.includes("localhost:58996") ? 'meshbuilder.eu.mike-cloud-dev.com' : '';
const meshAppService = apiDomain ? `${httpProtocol}://${apiDomain}/api` : '/api';
const meshAppServiceSocket = apiDomain ? `${httpProtocol}://${apiDomain}/notification` : `/notification`;

/**
 * API base URL endpoints.
 * The API is expected to be on the same domain with a `/api` prefix.
 * API domains can be passed as a CLI argument.
 *
 * @name endpoints
 * @type { Object }
 */
export const endpoints = {
  meshAppService,
  meshAppServiceSocket,
  domain: apiDomain ? `${httpProtocol}://${apiDomain}` : window.location.origin,
};

/**
 * The version of the api to use.
 */
export const apiVersion = 2;
