import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import { MmgVisualizationSettingsSection } from '../../shared/visualization-settings/visualization-settings-section';
import { MmgStatisticsSection } from '../../shared/statistics/statistics-section';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { REPRESENTATION } from '../../MikeVisualizer/lib/MikeVisualizerConstants';
import { MmgGeometryParameters } from './geometry-parameters';
import LayerUtils from '../../shared/layers/layer-utils';
import { MmgGroup } from '../../shared/groups/group';
import { t } from '../../translations/i18n';

const { isLayerFailed } = LayerUtils;

type GeometryOverviewProps = {
  workspaceId: string;
  geometry: IWorkspaceEnrichedGeometry;
  geometryDrawnData: IDrawnDataItem;
};

// todo hevo consider if this componet should be connected, getting the geoemtry and drawn data itself or if we should pass it in as done now

/**
 * @name MmgGeometryOverview
 * @summary Displays geometry overview information, such as statistics.
 *
 * @param props
 */
export const MmgGeometryOverview = (props: GeometryOverviewProps) => {
  const { workspaceId, geometry, geometryDrawnData } = props;

  const isFailed = isLayerFailed(geometry);

  return (
    geometry && (
      <>
        {!isFailed && <MmgGeometryParameters workspaceId={workspaceId} geometry={geometry} />}
        {!isFailed &&
          geometryDrawnData && (
            <>
              <MmgGroup groupName={t('ANALYZE_GEOMETRY')}>
                <MmgStatisticsSection
                  enrichedDataItem={geometry}
                  elementId={geometry.id}
                  elementCategory={ELEMENT_CATEGORIES.GEOMETRY}
                  dataArrays={geometryDrawnData.dataArrays}
                  fieldDataArrays={geometryDrawnData.fieldData}
                />
              </MmgGroup>
              <MmgVisualizationSettingsSection
                elementId={geometry.id}
                canApplyRepresentation={true}
                canApplyColor={true}
                representation={geometryDrawnData.representation}
                disabledRepresentations={[REPRESENTATION.SURFACE, REPRESENTATION.SURFACE_WITH_EDGES]}
                surfaceColor={geometryDrawnData.surfaceColor}
                edgeColor={geometryDrawnData.edgeColor}
              />
            </>
          )}
      </>
    )
  );
};
