import { useEffect, useMemo } from 'react';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { MmgVisualizationSettingsSection } from '../../shared/visualization-settings/visualization-settings-section';
import { MmgStatisticsSection } from '../../shared/statistics/statistics-section';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { MmgMeshGenerateSubSection } from './mesh-generate-subsection';
import { MmgGroup } from '../../shared/groups/group';
import LayerUtils from '../../shared/layers/layer-utils';
import { t } from '../../translations/i18n';
import { store } from '../../store';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';

const { isMeshGenerated } = LayerUtils;

type MeshOverviewProps = {
  projectId: string;
  workspaceId: string;
  mesh: IWorkspaceEnrichedMesh;
  meshDrawnData: IDrawnDataItem;
};

// todo hevo consider if this component should be connected

/**
 * @name MmgMeshOverview
 * @summary Displays mesh overview information, such as statisticsand mesh items
 *
 * @param props
 */
export const MmgMeshOverview = (props: MeshOverviewProps) => {
  const { mesh, meshDrawnData, workspaceId, projectId } = props;

  const isGenerated = isMeshGenerated(mesh);
  const tiledMeshId = useMemo(
    () => {
      return mesh && mesh.isTiled && isGenerated ? mesh.id : '';
    },
    [mesh, isGenerated],
  );

  useEffect(
    () => {
      if (tiledMeshId && workspaceId) {
        store.dispatch({ type: EWorkspaceMeshActionType.GET_TILED_MESH_FIELD_STATISTICS, data: {meshId: tiledMeshId, workspaceId} });
      }
    },
    [tiledMeshId, workspaceId],
  );

  if (!mesh) {
    return <></>;
  }

  return (
    <>
      <MmgMeshGenerateSubSection mesh={mesh} workspaceId={workspaceId} projectId={projectId} />

      {isGenerated &&
        meshDrawnData && (
          <>
            <MmgGroup groupName={t('ANALYZE_MESH')}>
              <MmgStatisticsSection
                enrichedDataItem={mesh}
                elementId={mesh.id}
                elementCategory={ELEMENT_CATEGORIES.MESH}
                dataArrays={meshDrawnData.dataArrays}
                fieldDataArrays={meshDrawnData.fieldData}
              />
            </MmgGroup>
            <MmgVisualizationSettingsSection
              elementId={mesh.id}
              canApplyRepresentation={true}
              canApplyColor={true}
              representation={meshDrawnData.representation}
              edgeColor={meshDrawnData.edgeColor}
              surfaceColor={meshDrawnData.surfaceColor}
              isMesh
              isTiled={mesh.isTiled}
            />
          </>
        )}
    </>
  );
};
