import { useEffect } from 'react';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgLayerSelect } from '../../shared/layers/mesh-layer-select';
import { MmgConnectedLayerMeshSelectActions } from './actions/layer-mesh-select-actions';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import MeshIconUtils from '../mesh-icon-utils';
import LayerUtils, { fetchLayer, fetchTiledLayer } from '../../shared/layers/layer-utils';
import { MmgLayerErrorSelect } from '../../shared/layers/mesh-layer-error-select';
import { MmgLayerError } from '../../shared/layers/mesh-layer-error';
import { useSelector } from 'react-redux';
import { MikeLayerWorking } from '../../shared-components/mike-layer/layer-working';
import { MikeLayerColorComplication } from '../../shared-components/mike-layer/complications/layer-color-complications';
import { IGlobalState } from '../../store/reducers';
import MikeVisualizerViewManager from '../../MikeVisualizer/lib/MikeVisualizerViewManager';

const { getIcon } = MeshIconUtils;

const {
  isLayerWorking,
  isLayerFailed,
  isLayerHidden,
  getLayerSurfaceColor,
  getLayerEdgeColor,
  selectLayer,
  deselectLayer,
} = LayerUtils;

type MeshSelectListProps = {
  meshes: Array<IWorkspaceEnrichedMesh>;
  hiddenMeshes: Array<string>;
  drawnMeshes: Array<IDrawnDataItem>;
  selectedMeshes: Array<string>;
  disabledMeshes?: Array<string>;
  isFailedSelectable?: boolean;
  onLayerSelectChanged?: (layerId: string, selected: boolean) => void;
};

/**
 * @name MmgConnectedMeshSelectList
 * @summary Given an array of meshes, renders them and allows their selection.
 *
 * @param props
 */
export const MmgConnectedMeshSelectList = (props: MeshSelectListProps) => {
  const {
    meshes,
    hiddenMeshes,
    drawnMeshes,
    selectedMeshes,
    disabledMeshes = [],
    isFailedSelectable,
    onLayerSelectChanged: onLayerSelectChangedProps,
  } = props;

  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);

  useEffect(
    () => {
      meshes.forEach((mesh: IWorkspaceEnrichedMesh) => {
        if (!loadedData.includes(mesh.id)) {
          if (mesh.isTiled) {
            const { getCurrentViewBounds } = MikeVisualizerViewManager;
            const bounds = getCurrentViewBounds();
            fetchTiledLayer(mesh.id, [], bounds, false);
          } else {
            fetchLayer(mesh.id, ELEMENT_CATEGORIES.MESH);
          }
        }
      });
    },
    [loadedData, meshes],
  );

  const onLayerSelectChanged = (layerId: string, selected: boolean) => {
    if (selected) {
      selectLayer(ELEMENT_CATEGORIES.MESH, layerId);
    } else {
      deselectLayer(ELEMENT_CATEGORIES.MESH, layerId);
    }

    if (onLayerSelectChangedProps) {
      onLayerSelectChangedProps(layerId, selected);
    }
  };

  if (!meshes || meshes.length === 0) {
    return null;
  }

  return (
    <div>
      {meshes.map((mesh) => {
        const { id, name, message } = mesh;
        const isWorking = isLayerWorking(mesh, drawnMeshes);
        const isDisabled = disabledMeshes.indexOf(id) !== -1;

        const baseLayerProperties = {         
          layerId: id,
          layerName: name,
          rightActions: <MmgConnectedLayerMeshSelectActions layerId={id} mesh={mesh} />,
          isLayerFaded: isDisabled || isLayerHidden(ELEMENT_CATEGORIES.MESH, id, hiddenMeshes),
        };

        if (isLayerFailed(mesh)) {
          return isFailedSelectable ? (
            <MmgLayerErrorSelect
              key={id}
              {...baseLayerProperties}
              selected={selectedMeshes.indexOf(id) !== -1}
              layerDescription={message}
              onLayerSelectChanged={onLayerSelectChanged}
              disabled={isDisabled}
            />
          ) : (
            <MmgLayerError key={id} {...baseLayerProperties} layerDescription={message} />
          );
        }

        if (isWorking) {
          return <MikeLayerWorking key={id} {...baseLayerProperties} />;
        }

        return (
          <MmgLayerSelect
            key={id}
            {...baseLayerProperties}
            selected={selectedMeshes.indexOf(id) !== -1}
            leftIcon={getIcon(mesh)}
            leftComplications={
              <MikeLayerColorComplication
                edgeColor={getLayerSurfaceColor(mesh, drawnMeshes)}
                surfaceColor={getLayerEdgeColor(mesh, drawnMeshes)}
              />
            }
            onLayerSelectChanged={onLayerSelectChanged}
            disabled={isDisabled}
          />
        );
      })}
    </div>
  );
};
