import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { t } from '../translations/i18n';
import { getRouteByPath, ROUTES } from '../app/routes';
import OperationsManager from '../managers/OperationsManager';
import { store } from '../store/store';
import { IOperationMetadata } from '../models/IOperations';
import MikeButton from '../shared-components/mike-button';
import { useNavigate } from 'react-router-dom';

type ConfirmDeleteDialogProps = {
  projectId: string;
  workspaceId: string;
  operation: IOperationMetadata;
  open: boolean;
  onClose: (confirmed: boolean) => void;
};

/**
 * Shows a confimation dialog for deleting a operation and deletes the operation if user confirms
 * @param props
 *
 */
export const MmgConfirmDeleteOperationDialog = (props: ConfirmDeleteDialogProps) => {
  const navigate = useNavigate();
  const { open, onClose, operation, projectId, workspaceId } = props;

  if (!operation) {
    return;
  }
  const cancelStates = ['Scheduled', 'Processing'];
  const operationId = operation.id;

  const handleClose = () => {
    onClose(false);
  };

  const handleOk = () => {
    if (cancelStates.includes(operation.state)) {
      onOperationCancel();
    } else {
      onOperationDelete();
    }
    closeDialog(true);
  };

  const handleCancel = () => {
    closeDialog(false);
  };

  const closeDialog = (confirmed: boolean) => {
    onClose(confirmed);
  };

  const onOperationCancel = () => {
    return OperationsManager.cancelOperation(workspaceId, operationId)
      .then(() => {
        const toast = {
          text: t('CANCEL', 1, {
            thing: t('OPERATION'),
          }),
        };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('CANCEL') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  const onOperationDelete = () => {
    return OperationsManager.deleteOperation(workspaceId, operationId)
      .then(() => {
        const toast = {
          text: t('DELETE', 1, {
            thing: t('OPERATION'),
          }),
        };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('CANCEL') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      id="confirm-delete-operation"
      aria-labelledby="delete-operation-dialog-title"
      aria-describedby="delete-operation-dialog-description"
    >
      <DialogTitle id="delete-operation-dialog-title">
        {t('OPERATION_CONFIRM_DELETE_TITLE')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-operation-dialog-description">
          {t('OPERATION_CONFIRM_DELETE_TEXT')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MikeButton onClick={handleCancel} variant="outlined" color="secondary">
          {t('NO')}
        </MikeButton>
        <MikeButton onClick={handleOk} variant="contained" color="secondary" autoFocus>
          {t('YES')}
        </MikeButton>
      </DialogActions>
    </Dialog>
  );
};
