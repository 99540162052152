/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect, useCallback } from 'react';
import { t } from '../../translations/i18n';
import MmgMeshOperationDetails from './mesh-post-operation-details';
import { CardSummaryStyle } from '../../shared/cards/item-card';
import { MmgGroup } from '../../shared/groups/group';
import ArrowDown from '../../icons/ArrowDown.svg?react';
import ArrowUp from '../../icons/ArrowUp.svg?react';
import { MmgOperationConfigurationSummary } from '../../operations/operation-configuration-summary';
import OperationsManager from '../../managers/OperationsManager';
import { useAsyncLoadCreateMeshConfiguration } from '../configure/useAsyncLoadCreateMeshConfiguration';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

const BasicConfigurationStyle = css`
  &:not(:empty) {
    padding-top: ${mikeSharedTheme.spacing(2)};
  }
`;

const MmgMeshPostOperationSummary = ({ meshId, workspaceId }) => {
  const [ordering, setOrdering] = useState('asc');
  const [postOperations, setPostOperations] = useState([]);

  const { createMeshConfiguration } = useAsyncLoadCreateMeshConfiguration(workspaceId, meshId);

  const getOperationDetails = useCallback(
    (wId: string, mId: string): Promise<any> => {
      if (!workspaceId || !meshId) {
        return Promise.resolve<any>(null);
      }
      return OperationsManager.getMeshPostOperations(wId, mId)
        .then((operations) => {
          setPostOperations(operations);
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {});
    },
    [meshId, workspaceId],
  );

  useEffect(
    () => {
      getOperationDetails(workspaceId, meshId);
    },
    [getOperationDetails, meshId, workspaceId],
  );

  const getSortedOperations = () => {
    if (ordering === 'asc') {
      return postOperations.sort((a, b) => (a.created > b.created ? 1 : b.created > a.created ? -1 : 0)).reverse();
    }
    if (ordering === 'desc') {
      return postOperations.sort((a, b) => (a.created > b.created ? 1 : b.created > a.created ? -1 : 0));
    }
  };

  const orderedOperations = getSortedOperations();

  const handleSetOrdering = () => {
    ordering === 'asc' ? setOrdering('desc') : setOrdering('asc');
  };
  return (
    <>
      <MmgGroup groupName={t('MESH_BASIC_CONFIGURATION')} canBeHidden={false}>
        <section css={css`${CardSummaryStyle}; ${BasicConfigurationStyle};`}>
          {createMeshConfiguration && (
            <MmgOperationConfigurationSummary
              key={createMeshConfiguration.id}
              showOperationName={false}
              operationConfiguration={createMeshConfiguration}
            />
          )}
        </section>
      </MmgGroup>
      {postOperations.length > 0 && (
        <MmgGroup
          groupName={ordering === 'asc' ? t('OPERATION_LIST_ASC') : t('OPERATION_LIST_DESC')}
          canBeHidden={false}
          groupTools={
            ordering === 'asc' ? <ArrowUp onClick={handleSetOrdering} /> : <ArrowDown onClick={handleSetOrdering} />
          }
        />
      )}
      {postOperations &&
        orderedOperations.map((operation) => {
          return <MmgMeshOperationDetails postOperation={operation} />;
        })}
    </>
  );
};

export default MmgMeshPostOperationSummary;
