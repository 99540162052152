import React, { useMemo } from 'react';
import { t } from '../../translations/i18n';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import SpatialSelection from '../../icons/SpatialSelection.svg?react';
import DefaultView from '../../icons/DefaultView.svg?react';
import Delete from '../../icons/Delete.svg?react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { menuitemiconSX, menuitemSX } from '../../shared/layers/actions/layer-action-menu';
import SpatialSelectionUtils from './spatial-selection-utils';
import { ContextMenuStyle } from '../../shared/menus/menu-styles';
import { MmgConnectedLayer } from '../../shared/layers/mesh-layer';
import { MmgSpatialSelectionVisibilityAction } from '../../meshes/configure/configuration/spatial-selection-visibility-action';
import { onZoomToLayer } from '../../workspaces/details/actions/layer-actions';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { IOperationMetadata } from '../../models/IOperations';
import WorkspaceQuerySelectors from '../../store/selectors/WorkspaceQuerySelectors';
import { isLayerFailed, isLayerProcessing, isLayerScheduled } from '../../shared/layers/layer-utils';
import { PROGRESS_SIZE } from './spatial-selection-layer';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { IGlobalState } from '../../store/reducers';
import { useParams } from 'react-router-dom';

const { highlightSelection, unhighlightSelection } = SpatialSelectionUtils;

type SpatialSelectionProps = {
  selectionName: string;
  selectionId: string;
  isShown: boolean;
  onSelectionEdit?: (selectionId: string) => void;
  onSelectionDelete?: (selectionId: string) => void;
  selection: any;
  isSelection: boolean;
};

/**
 * @name MmgSpatialSelection
 * @summary A component that displays a spatial selection summary i.e. name & exposes a context menu to rename, delete.
 *
 * @param props
 */
export function MmgSpatialSelection(props: SpatialSelectionProps) {
  const { selectionName, selectionId, selection, isSelection } = props;
  const {workspaceId } = useParams();
  const shownSelectionIds = useSelector((state: IGlobalState) => state.WorkspaceQueryReducer.shownSelectionIds);

  const getQueryOperationSelectorInstance = WorkspaceQuerySelectors.makeGetQueryOperation();

  const latestOperation: IOperationMetadata | null = useSelector((state: IGlobalState) =>
    getQueryOperationSelectorInstance(state, {
      queryId: selectionId,
    }),
  );

  const { working, icon } = useMemo(
    () => {
      const isWorking = isLayerProcessing(latestOperation) || isLayerScheduled(latestOperation);
      const isFailed = isLayerFailed(latestOperation);
      return {
        working: isWorking,
        icon: isWorking ? (
          <CircularProgress style={{ width: PROGRESS_SIZE, height: PROGRESS_SIZE }} color="secondary" />
        ) : isFailed ? (
          <WarningRoundedIcon style={{ width: 30, height: 30 }} />
        ) : (
          <SpatialSelection />
        ),
      };
    },
    [latestOperation],
  );

  const isShown = useMemo(
    () => {
      return selectionId && shownSelectionIds && shownSelectionIds.includes(selectionId);
    },
    [selectionId, shownSelectionIds],
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const contextMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const contextMenuClose = () => {
    setAnchorEl(null);
  };

  const onSelectionEdit = (sId: string) => () => {
    contextMenuClose();
    props.onSelectionEdit(sId);
  };

  const onSelectionDelete = (sId: string) => () => {
    contextMenuClose();
    props.onSelectionDelete(sId);
  };

  /*   const onSelectionToggleResultPress = (sId: string) => () => {
    contextMenuClose();
    props.onSelectionToggleResultPress(sId);
  }; */

  const onSelectionMouseEnter = (sId) => highlightSelection(sId);
  const onSelectionMouseLeave = (sId) => unhighlightSelection(sId);

  return (
    <MmgConnectedLayer
      showVisibilityIcon={!isSelection}
      layerId={selectionId}
      layerName={selectionName}
      key={`layer-${selectionId}`}
      leftIcon={icon}
      onLayerMouseEnter={onSelectionMouseEnter}
      onLayerMouseLeave={onSelectionMouseLeave}
      rightActions={
        <>
          <MmgSpatialSelectionVisibilityAction selection={selection} />
          <div>
            <IconButton
              aria-label="more"
              aria-controls={`menu-${selectionId}`}
              aria-haspopup="true"
              onClick={contextMenuOpen}
            >
              <MoreVertIcon />
            </IconButton>

            {/* TODO dan/ TODO: hevo should use layer-action-menu component, but refactoring is more involved (there are custom actions for i.e. showing/hiding that make requests, which is only the case for queries right now) */}

            <Menu
              id={`menu-${selectionId}`}
              key={`menu-${selectionId}`}
              anchorEl={anchorEl}
              open={open}
              onClose={contextMenuClose}
              css={ContextMenuStyle}
            >
              <MenuItem
                dense={true}
                onClick={() => onZoomToLayer(selectionId, workspaceId)}
                sx={menuitemSX}
                disabled={working || !isShown}
              >
                <ListItemIcon sx={menuitemiconSX}>
                  <DefaultView />
                </ListItemIcon>

                <Typography variant="body2" noWrap>
                  {t('ZOOM_TO_LAYER')}
                </Typography>
              </MenuItem>
              {props.onSelectionEdit && (
                <MenuItem dense={true} onClick={onSelectionEdit(selectionId)} sx={menuitemSX}>
                  <ListItemIcon sx={menuitemiconSX}>
                    <EditIcon />
                  </ListItemIcon>

                  <Typography variant="body2" noWrap>
                    {t('RENAME')}
                  </Typography>
                </MenuItem>
              )}

              {props.onSelectionDelete && (
                <MenuItem dense={true} onClick={onSelectionDelete(selectionId)} sx={menuitemSX}>
                  <ListItemIcon sx={menuitemiconSX}>
                    <Delete />
                  </ListItemIcon>

                  <Typography variant="body2" noWrap>
                    {t('DELETE')}
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </div>
        </>
      }
    />
  );
}
