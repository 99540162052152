import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PlatformProxy } from '../../proxies/PlatformProxy';
import { HttpErrorHandler } from '../../managers/http-utils';

import { EApplicationsActionType } from '../../store/actions/ApplicationsActionType';
import { setApplications, loadingApplications } from '../../store/actions/applications';
import { setMyTenants, loadingTenants } from '../../store/actions/iam';
import { EIAMActionType } from '../../store/actions/IAMActionType';
import ITenant from '../../models/ITenant';
import { IGlobalState } from '../../store/reducers';
import { IApplication, IApplications } from '../../shared-components/mike-subscribed-apps/model/IApplications';

export default function* watchIAM() {
  yield takeLatest(EApplicationsActionType.LOAD_APPLICATIONS, fetchApplications);
  yield takeLatest(EIAMActionType.GET_MY_TENANTS, fetchMyTenants);
}

interface IFeature {
  enabled: boolean;
  featureId: string;
  name: string;
  responseUrl: string;
  type: string;
  url: string;
}

const getConfiguration = (state: IGlobalState) => state.AppReducer.configuration;

function* fetchMyTenants() {
  try {
    yield put(loadingTenants());

    const response = yield call(PlatformProxy.getUserTenants);

    if (response && response.status === 200 && response.data) {
      let myTenants = new Array<ITenant>();
      if (response.data.length > 0) {
        myTenants = response.data;
      }
      yield put(setMyTenants(myTenants));
    }
  } catch (error) {
    HttpErrorHandler('Failed to fetch my tenants.', error as any);
  } finally {
    yield put(loadingTenants(false));
  }
}

function* fetchApplications() {
  try {
    yield put(loadingApplications());

    const response = yield call(PlatformProxy.getApplications);

    if (response && response.status === 200 && response.data) {
      const features: Array<IFeature> = response.data.data;
      let apps = new Array<IApplication>();
      if (features && features.length > 0) {
        let ownFeatureId = '939d2981-a918-ed11-b83e-002248818247';
        if (import.meta.env.MODE !== 'production' && !window.location.origin.includes("localhost")) {
          const configuration = yield select(getConfiguration);
          if (configuration.oAuth2.clientName) {
            ownFeatureId = configuration.oAuth2.clientName;
          }
        }
        apps = features
          .filter(
            (app: IFeature) => app.enabled && app.url && app.type === 'Application' && app.featureId !== ownFeatureId,
          )
          .map((app: IFeature) => {
            return {
              appName: app.name,
              forAdministratorOnly: false,
              // ToDo: Fix this in mike-shared once Data Link is migrated
              appUrl: app.name === 'Data Link' || app.url.endsWith('/') ? app.url : app.url + '/',
              iconName: app.name,
            };
          });
      }

      const applications: IApplications = {
        administrationApps: new Array<IApplication>(),
        appsInSubscription: apps,
        appsOutsideSubscription: new Array<IApplication>(),
      };
      yield put(setApplications(applications));
    }
  } catch (error) {
    HttpErrorHandler('Failed to fetch applications.', error as any);
  } finally {
    yield put(loadingApplications(false));
  }
}
