import { EProjectionActionType } from '../actions/ProjectionActionType';
import { IAction } from '../actions/Action';
import { IProjection } from '../../models/IProjections';

export interface IProjectionsState {
  allProjectionSystems: IProjection[];
  loadingProjectionSystems: boolean;
  selectedProjectionSystems: IProjection[];
  searchProjectionSystemsById: boolean;
}

const initState: IProjectionsState = {
  allProjectionSystems: Array<IProjection>(),
  loadingProjectionSystems: false,
  selectedProjectionSystems: Array<IProjection>(),
  searchProjectionSystemsById: false,
};

export default function(
  state: IProjectionsState = initState,
  action: IAction,
): IProjectionsState {
  switch (action.type) {
    case EProjectionActionType.LOADING_PROJECTION_SYSTEMS:
      return { ...state, loadingProjectionSystems: action.data as boolean };
    case EProjectionActionType.SET_PROJECTION_SYSTEMS:
      return { ...state, allProjectionSystems: action.data as Array<IProjection> };
    case EProjectionActionType.FILTER_PROJECTION_SYSTEMS: {
      const filter: string = action.data as string;
      const allCS = [...state.allProjectionSystems];
      const names = allCS.filter((coordinateSystem: IProjection) =>
        coordinateSystem.name.toLowerCase().includes(filter.toLowerCase()),
      );
      return {
        ...state,
        searchProjectionSystemsById: false,
        selectedProjectionSystems: names,
      };
    }
    case EProjectionActionType.GET_PROJECTION_SYSTEMS_BY_ID: {
      const id: number = action.data as number;
      const _allCS = [...state.allProjectionSystems];
      const ids = _allCS.filter((projectionSystem: IProjection) =>
        projectionSystem.id.toString().startsWith(id.toString()),
      );
      return {
        ...state,
        searchProjectionSystemsById: true,
        selectedProjectionSystems: ids,
      };
    }
    default:
      return state;
  }
}
