import { uniq } from 'lodash-es';
import { IWorkspaceVariable } from '../../models/IVariables';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EWorkspaceVariableActionType } from '../actions/WorkspaceVariableActionType';

const initialState = {
  workspaceVariables: [] as Array<IWorkspaceVariable>,
  workspaceVariablesLoading: false,
  hiddenWorkspaceVariables: [],
  selectedWorkspaceVariables: [],
  submittedForDeleteWorkspaceVariables: [],
  userHiddenVariableIds: [],
  updateVariableDataFailed: false,
  updatingVariableData: false,
};

export interface IWorkspaceVariableState {
  workspaceVariables: Array<IWorkspaceVariable>;
  workspaceVariablesLoading: boolean;
  hiddenWorkspaceVariables: Array<string>; // Holds the currently hidden variable ids TODO hevo Find better name - maybe hiddenVariableIds ?
  selectedWorkspaceVariables: Array<string>;
  submittedForDeleteWorkspaceVariables: Array<string>;
  userHiddenVariableIds: Array<string>; // Holds the variables hidden in the workspace by the user
  updateVariableDataFailed: boolean;
  updatingVariableData: boolean;
}

/**
 * Workspace Variable Reducer.
 * - returns new states for matched workspace variable actions.
 *
 * @name WorkspaceVariableReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IWorkspaceVariableState = initialState, action) {
  const updateHiddenVariableIds = (hiddenVariableIds: Array<string>, updateUserSettings: boolean) => {
    return {
      ...state,
      hiddenWorkspaceVariables: hiddenVariableIds,
      userHiddenVariableIds: updateUserSettings ? hiddenVariableIds : state.userHiddenVariableIds,
    };
  };

  switch (action.type) {
    case EWorkspaceVariableActionType.UPDATING_DATA: {
      return { ...state, updatingVariableData: action.data };
    }
    case EWorkspaceVariableActionType.UPDATE_DATA_FAILED: {
      return { ...state, updateVariableDataFailed: action.data };
    }
    case 'workspace/variables/LOAD': {
      return {
        ...state,
        workspaceVariablesLoading: true,
      };
    }

    case 'workspace/variables/LOAD_SUCCESS': {
      const variables = action.workspaceVariables;

      return {
        ...state,
        workspaceVariablesLoading: false,
        workspaceVariables: variables,
      };
    }

    case 'workspace/variables/CLEAR':
      return {
        ...state,
        workspaceVariables: initialState.workspaceVariables,
      };

    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case 'workspace/variables/DELETE': {
      const submittedForDeleteWorkspaceVariables = state.workspaceVariables.filter(
        (v) => action.workspaceVariables.indexOf(v.id) === -1,
      );

      return {
        ...state,
        submittedForDeleteWorkspaceVariables,
      };
    }

    case 'workspace/variables/ITEMS_DELETED': {
      const idsToDelete = action.variableIds as Array<string>;

      if (!idsToDelete || idsToDelete.length === 0) {
        return state;
      }

      const workspaceVariables = state.workspaceVariables.filter((v) => idsToDelete.indexOf(v.id) === -1);

      return {
        ...state,
        workspaceVariables,
      };
    }

    case 'workspace/variables/DELETE_SUCCESS': {
      const submittedForDeleteWorkspaceVariables = [];

      const workspaceVariables = state.workspaceVariables.filter((v) => action.workspaceVariables.indexOf(v.id) === -1);

      return {
        ...state,
        workspaceVariables,
        submittedForDeleteWorkspaceVariables,
      };
    }

    case 'workspace/variables/DELETE_FAILED': {
      const submittedForDeleteWorkspaceVariables = [];

      return {
        ...state,
        submittedForDeleteWorkspaceVariables,
      };
    }

    case 'workspace/variables/ITEM_ADDED_OR_UPDATED': {
      const { workspaceVariable } = action;

      if (!workspaceVariable) {
        return state;
      }

      const variableIndex = state.workspaceVariables.findIndex((v) => v.id === workspaceVariable.id);

      // Add new variable if not found
      if (variableIndex === -1) {
        return {
          ...state,
          workspaceVariables: [...state.workspaceVariables, workspaceVariable],
        };
      }

      // replace existing variable if found
      return {
        ...state,
        workspaceVariables: [
          ...state.workspaceVariables.slice(0, variableIndex),
          workspaceVariable,
          ...state.workspaceVariables.slice(variableIndex + 1),
        ],
      };
    }

    case EWorkspaceVariableActionType.HIDE_ITEMS: {
      const hiddenVariableIds = uniq([...state.hiddenWorkspaceVariables, ...action.workspaceVariableIds]);

      return updateHiddenVariableIds(hiddenVariableIds, action.updateUserSettings);
    }

    case EWorkspaceVariableActionType.HIDE_ITEM: {
      const hiddenVariableIds = uniq([...state.hiddenWorkspaceVariables, action.workspaceVariableId]);

      return updateHiddenVariableIds(hiddenVariableIds, action.updateUserSettings);
    }

    case EWorkspaceVariableActionType.HIDE_ALL: {
      const variableIds = state.workspaceVariables.map(({ id }) => id);

      return updateHiddenVariableIds([...variableIds], action.updateUserSettings);
    }

    case EWorkspaceVariableActionType.SHOW_ITEM: {
      const hiddenVariableIds = state.hiddenWorkspaceVariables.filter((vId) => vId !== action.workspaceVariableId);

      return updateHiddenVariableIds(hiddenVariableIds, action.updateUserSettings);
    }

    case EWorkspaceVariableActionType.SHOW_ITEM_ONLY: {
      const hiddenVariableIds = state.workspaceVariables
        .map((wg: IWorkspaceVariable) => wg.id)
        .filter((id: string) => id !== action.variableId);
      const userHidden = action.updateUserSettings
        ? state.userHiddenVariableIds.filter((id: string) => id !== action.variableId)
        : state.userHiddenVariableIds;
      return {
        ...state,
        hiddenWorkspaceVariables: hiddenVariableIds,
        userHiddenVariableIds: userHidden,
      };
    }

    case EWorkspaceVariableActionType.SHOW_ALL:
      return updateHiddenVariableIds([], action.updateUserSettings);

    case EWorkspaceVariableActionType.SHOW_ALL_BUT_USER_HIDDEN: {
      const hiddenVariableIds = [...state.userHiddenVariableIds];
      return updateHiddenVariableIds(hiddenVariableIds, false);
    }

    case EWorkspaceVariableActionType.UPDATE_USER_HIDDEN: {
      const hiddenVariableIds = uniq([...state.userHiddenVariableIds, ...action.userHiddenVariableIds]);

      return { ...state, userHiddenVariableIds: hiddenVariableIds };
    }

    case EWorkspaceVariableActionType.SELECT:
      return {
        ...state,
        selectedWorkspaceVariables: action.unselectOthers
          ? [action.workspaceVariableId]
          : [...state.selectedWorkspaceVariables, action.workspaceVariableId],
      };

    case 'workspace/variables/DESELECT': {
      const selectedWorkspaceVariables = state.selectedWorkspaceVariables.filter(
        (el) => el !== action.workspaceVariableId,
      );

      return {
        ...state,
        selectedWorkspaceVariables,
      };
    }

    case 'workspace/variables/RESET_SELECTIONS':
    case 'workspace/variables/DESELECT_ALL':
      return {
        ...state,
        selectedWorkspaceVariables: [],
      };

    case 'workspace/variables/SELECT_MULTIPLE':
      return {
        ...state,
        selectedWorkspaceVariables: uniq([...state.selectedWorkspaceVariables, ...action.workspaceVariableIds]),
      };

    default:
      return state;
  }
}
