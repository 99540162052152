import {useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { EElementCategories } from '../../../shared/panels/mesh-panel-constants';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import { getToggleVisibilityLayerAction, ILayerAction } from '../../../shared/layers/actions/layer-default-actions';
import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import { MmgConfirmDeleteGeometryDialog } from '../../details/geometry-delete-dialog';
import { IDrawnDataItem } from '../../../models/IWorkspaceData';
import { IGlobalState } from '../../../store/reducers';
import WorkspaceGeometrySelectors from '../../../store/selectors/WorkspaceGeometrySelectors';

type LayerGeometrySelectActionsProps = {
  layerId: string;
  geometry: IWorkspaceEnrichedGeometry;
  canToggleLayerVisibility?: boolean;
  hiddenGeometryIds?: Array<string>;
  updateUserSettingsOnToggle?: boolean;
  geometryDrawnData?: IDrawnDataItem;
};

/**
 * @name MmgConnectedLayerGeometrySelectActions
 * @summary Geometry layer actions, made for items present in the select list.
 *
 * @param props
 */
export function MmgConnectedLayerGeometrySelectActions(props: LayerGeometrySelectActionsProps) {
  const { projectId, workspaceId } = useParams();
  const getDrawnDataSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryDrawnData();
  const {
    geometry,
    layerId,
    hiddenGeometryIds: hiddenGeometryIdsProp,
    geometryDrawnData: geometryDrawnDataProp,
    canToggleLayerVisibility = true,
    updateUserSettingsOnToggle = false,
  } = props;
  const drawnData = useSelector((state: IGlobalState) => getDrawnDataSelectorInstance(state, { geometryId: layerId }));
  const geometryDrawnData = geometryDrawnDataProp ? geometryDrawnDataProp : drawnData;
  const hiddenGeoIds = useSelector((state: IGlobalState) => state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries);
  const hiddenGeometryIds = hiddenGeometryIdsProp ? hiddenGeometryIdsProp : hiddenGeoIds;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const isMissing = !geometry;

  const layerActions = getLayerActions(
    projectId,
    workspaceId,
    geometry,
    geometryDrawnData,
    canToggleLayerVisibility,
    hiddenGeometryIds,
    updateUserSettingsOnToggle,
  );

  return (
    <>
      <MikeLayerActionMenu layerId={layerId} layerActions={layerActions} disabled={isMissing} />

      <MmgConfirmDeleteGeometryDialog
        open={deleteConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        geometry={geometry}
        onClose={() => setDeleteConfirmOpen(false)}
      />
    </>
  );
}

const getLayerActions = (
  _projectId: string,
  _workspaceId: string,
  geometry: IWorkspaceEnrichedGeometry,
  geometryDrawnData: IDrawnDataItem,
  canToggleLayerVisibility: boolean,
  hiddenGeometryIds: Array<string>,
  updateUserSettingsOnToggle: boolean,
) => {
  if (!geometry) {
    return [];
  }

  const layerId = geometry.id;
  const layerActions = [];

  if (canToggleLayerVisibility) {
    const toggleVisibilityAction = getToggleGeometryVisibilityAction(
      layerId,
      hiddenGeometryIds,
      updateUserSettingsOnToggle,
      !Boolean(geometryDrawnData),
    );
    layerActions.push(toggleVisibilityAction);
  }

  return layerActions;
};

export const getToggleGeometryVisibilityAction = (
  layerId: string,
  hiddenGeometryIds,
  updateUserSettings: boolean,
  disabled?: boolean,
): ILayerAction => {
  const toggleVisibilityAction = getToggleVisibilityLayerAction(
    EElementCategories.GEOMETRY,
    layerId,
    hiddenGeometryIds,
    disabled,
    updateUserSettings,
  );

  return toggleVisibilityAction;
};
