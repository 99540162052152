/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { IProject } from '../../models/IProject';
import { t } from '../../translations/i18n';
import { store } from '../../store/store';
import { IGlobalState } from '../../store/reducers';
import { orderBy } from 'lodash-es';
import Skeleton from '@mui/material/Skeleton';
import { MmgMetas } from '../../shared/metas/metas';
import WorkspaceUtils from '../workspace-utils';
import { MmgConnectedWorkspaceCreate } from '../create/workspace-create';
import { MmgCardPlaceholders } from '../card/workspace-card-placeholders';
import { MmgWorkspaceCard } from '../card/workspace-card';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { useMatch, useNavigate } from 'react-router-dom';
import { useIsMounted } from '../../shared/hooks/hooks';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { mikeSharedTheme } from '../../shared/styles/mikeSharedTheme';
import { TopPadding } from '../../shared/styles/styleConsts';
import MmgSubprojectsTable from '../recent/subprojects-table';
import MmgFilterInput from '../../shared/filter-input/filter-input';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';
import Typography from '@mui/material/Typography';

const { getProjectName } = WorkspaceUtils;

const WorkspacePageGridStyle = css`
  background-color: ${MIKE_COLORS.XLIGHTGREY};
  margin: ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(2)} 0 ${mikeSharedTheme.spacing(3)};
`;

const HeaderContainerStyle= css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WorkspaceListContainerStyle = css`
  padding-top: ${mikeSharedTheme.spacing(2)};
  overflow: auto;
  display: flex;
`;

const SkeletonContainerStyle = css`
  padding: ${TopPadding} ${mikeSharedTheme.spacing(3)};

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;


/**
 * @name MmgWorkspaceList
 * @summary Lists workspaces in a project.
 */
const MmgConnectedWorkspaceList = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const match = useMatch({
    path: '/project/:id/workspace-list'
  });

  const [filter, setFilter] = useState('');

  const projectId = match && match.params.id;
  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const { workspaceListLoading, workspaces } = useSelector((state: IGlobalState) => state.WorkspaceReducer);
  const { projectSelectedForDelete } = useSelector((state: IGlobalState) => state.ProjectReducer);

  const capabilities = project ? project.capabilities : null;

  const { subprojects } = useSelector((state: IGlobalState) => state.ProjectReducer);

  useEffect(
    () => {
      if (projectId) {
        store.dispatch({ type: WorkspaceActionType.WORKSPACE_LOAD_OVERVIEW, data: { projectId, navigate } });
      }
    },
    [navigate, projectId],
  );

  const handleClearFilter = useCallback(() => {
    setFilter('');
    store.dispatch({
      type: 'app/filter/SET',
      filter: '',
    });
  }, []);

  const handleSetFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    store.dispatch({
      type: 'app/filter/SET',
      filter: e.target.value,
    });
  }, []);

  const renderMetas = (currentProject: IProject) => {
    if (currentProject) {
      const metaTitle = `${t('WORKSPACES_PAGE_TITLE', 1, {
        projectName: getProjectName(currentProject),
      })}`;

      return <MmgMetas metaTitle={metaTitle} />;
    }
    return '';
  };

  const renderNewWorkspace = (workspaceIsListLoading: boolean) => {
    if (workspaceIsListLoading) {
      return <CircularProgress size={14} />;
    }
    return capabilities && capabilities.canCreateContent && <MmgConnectedWorkspaceCreate />;
  };

  const projectName = getProjectName(project);

  if (!project || workspaceListLoading) {
    return (
      <main css={SkeletonContainerStyle}>
        <Skeleton />
        <Skeleton width="60%" />

        {workspaceListLoading && <MmgCardPlaceholders size={6} />}
      </main>
    );
  }

  return (
    <>
      {renderMetas(project)}
      <div css={WorkspacePageGridStyle}>
        <div css={HeaderContainerStyle}>
          <Typography variant="h2">{t('WORKSPACES_PAGE_HEADER', 1, {projectName})}</Typography>
          {renderNewWorkspace(workspaceListLoading)}
        </div>
      
       
        {workspaceListLoading && <MmgCardPlaceholders size={6} />}
        <div css={WorkspaceListContainerStyle}>
          {isMounted &&
            !workspaceListLoading &&
            workspaces &&
            orderBy(workspaces, ['updated', 'created'], ['desc', 'desc']).map((workspace, id) => (
              <div key={id}>
                <MmgWorkspaceCard
                  key={workspace.id}
                  workspace={workspace}
                  project={project}
                  projectSelectedForDelete={projectSelectedForDelete}
                />
              </div>
            ))}
        </div>
        {!workspaceListLoading &&
          workspaces &&
          workspaces.length === 0 && (
             <div css={WorkspaceListContainerStyle}>
              <Typography variant="body2">{t('NO_WORKSPACES')}</Typography>
            </div> 
           
          )}
        {subprojects.length > 0 && (
          <div css={WorkspaceListContainerStyle}>
            <Typography variant="h2">{t('WORKSPACES_PAGE_SUBHEADER', 1, {projectName})}</Typography>     
          </div>
        )}
        {subprojects.length > 0 && (
          <div css={WorkspaceListContainerStyle}>
            <MmgFilterInput
              value={filter}
              onChangeValue={handleSetFilter}
              onResetValue={handleClearFilter}
              data-testid="mmg-subprojects-filter"
            />
          </div>
        )}           
      </div>
      {subprojects.length > 0 && (
        <MmgSubprojectsTable subprojectList={subprojects} subprojectListLoading={false} filter={filter} />
      )}
    </>
  );
};

export default MmgConnectedWorkspaceList;
