/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from '@emotion/react';
import { MmgItemCard } from '../../../shared/cards/item-card';
import { MmgNonHoverLayer } from '../../../shared/layers/mesh-non-hover-layer';
import { IOperationConfiguration, IOperationMetadata } from '../../../models/IOperations';
import { MmgMeshInterpolationBasicSummary } from './mesh-interpolation-basic-summary';
import { getInterpolationOperationName, getInterpolationOperationDescription } from '../mesh-interpolation-util';
import { MmgMeshInterpolationOverflowMenu } from '../actions/mesh-interpolation-overflow-menu';
import { IWorkspaceEnrichedMesh } from '../../../models/IMeshes';
import { IWorkspaceAttribute } from '../../../models/IWorkspaceAttributes';
import { isLayerInProgress, isLayerFailed } from '../../../shared/layers/layer-utils';
import { MmgLayerError } from '../../../shared/layers/mesh-layer-error';
import { LayerNonHoverStyle } from '../../../shared/layers/mesh-layer-styles';
import { IProject } from '../../../models/IProject';
import { useSelector } from 'react-redux';
import mikeSharedTheme from '../../../shared/styles/mikeSharedTheme';
import { IGlobalState } from '../../../store/reducers';
import { LayerWithoutBorderStyle } from '../../../queries/spatial-selections/spatial-selection-layer';
import { MikeLayerWorking } from '../../../shared-components/mike-layer/layer-working';


type MeshInterpolationBasicSummaryCardProps = {
  interpolationConfiguration: IOperationConfiguration;
  css?: Interpolation<Theme>;
  projectId: string;
  workspaceId: string;
  mesh?: IWorkspaceEnrichedMesh;
  meshAttributes?: Array<IWorkspaceAttribute>;
  interpolationMetadata?: IOperationMetadata;
  onInterpolationDeleted?: (operationId: string) => void;
  onInterpolationExecuted?: (result: Promise<any>) => void;
};

// todo hevo styling of the layername as bold would be less hackish if layer component supported individial styling of layer name and description
const BasicConfigurationLayerStyle = css`
  &:not(:empty) {
    padding-top: ${mikeSharedTheme.spacing(2)};
    padding-left: ${mikeSharedTheme.spacing(2)};
  }
`;

/**
 * @name MmgMeshInterpolationBasicSummaryCard
 * @param props
 *
 * @summary An itemcard for a mesh interpolation child operation. Includes the input layer and summary.
 *
 */
export const MmgMeshInterpolationBasicSummaryCard = (props: MeshInterpolationBasicSummaryCardProps) => {
  const {
    interpolationConfiguration,
    projectId,
    workspaceId,
    mesh,
    interpolationMetadata,
    onInterpolationDeleted,
    onInterpolationExecuted,
    css: cssProp,
  } = props;

  const interpolationName = getInterpolationOperationName(interpolationConfiguration);
  const project: IProject | null = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  // use the interpolationMetadata if available as these are expected to be updated during the execution via SignalR notifications.
  // Fallback to the interpolationConfiguration itself, even though data migth be sligtly outdated
  const metadata = interpolationMetadata || interpolationConfiguration;
  const description = getInterpolationOperationDescription(metadata);

  const isInProgress = isLayerInProgress(metadata);
  const isFailed = isLayerFailed(metadata);

  // todo hevo enrich interpolationConfiguration with metadata to get correct status for action menu!!!!!!
  const baseLayerProperties = {    
    layerId: interpolationConfiguration.id,
    layerName: interpolationName,
    layerDescription: description,
    rightActions: canUpdateWorkspace && (
      <MmgMeshInterpolationOverflowMenu
        projectId={projectId}
        workspaceId={workspaceId}
        mesh={mesh}
        interpolationConfiguration={interpolationConfiguration}
        onInterpolationDeleted={onInterpolationDeleted}
        onInterpolationExecuted={onInterpolationExecuted}
      />
    ),
  };

  if (isFailed) {
    return (
      <MmgItemCard
        cardLayer={
          <MmgLayerError {...baseLayerProperties} cssProp={css`${LayerWithoutBorderStyle}; ${LayerNonHoverStyle};`} />
        }
        cardSummary={<MmgMeshInterpolationBasicSummary operationConfiguration={interpolationConfiguration} />}
        open={false}
        cssProp={cssProp}
      />
    );
  }

  if (isInProgress) {
    return (
      <MmgItemCard
        cardLayer={
          <MikeLayerWorking
            {...baseLayerProperties}
            cssProp={css`${LayerWithoutBorderStyle} ${LayerNonHoverStyle}`}
          />
        }
        cardSummary={<MmgMeshInterpolationBasicSummary operationConfiguration={interpolationConfiguration} />}
        open={false}
        cssProp={cssProp}
      />
    );
  }
  return (
    <MmgItemCard
      cardLayer={
        <MmgNonHoverLayer
          layerId={interpolationConfiguration.id}
          layerName={<b>{interpolationName}</b>}
          layerDescription={description}
          rightActions={baseLayerProperties.rightActions}
          cssProp={css`${LayerWithoutBorderStyle}; ${BasicConfigurationLayerStyle}; ${LayerNonHoverStyle};`}          
        />
      }
      cardSummary={<MmgMeshInterpolationBasicSummary operationConfiguration={interpolationConfiguration} />}
      open={false}
      cssProp={cssProp}
    />
  );
};
