import { combineReducers } from 'redux'

import { default as AppReducer, IAppState } from './AppReducer';
import { default as ConfirmPanelReducer, IConfirmPanelState } from './ConfirmPanelReducer';
import { default as GeometryClientOperationReducer, IGeometryClientOperationState } from './GeometryClientOperationReducer';
import { default as GeometryMapToolReducer, IGeometryMapToolState } from './GeometryMapToolReducer';
import { default as MapToolReducer, IMapToolState } from './MapToolReducer';
import { default as MeshMapToolReducer, IMeshMapToolState } from './MeshMapToolReducer';
import { default as PanelReducer, IPanelState } from './PanelReducer';
import { default as ProjectReducer, IProjectState } from './ProjectReducer';
import { default as ToastReducer, IToastState } from './ToastReducer';
import { default as UserReducer, IUserState } from './UserReducer';
import { default as VariableMapToolReducer, IVariableMapToolState } from './VariableMapToolReducer';
import { default as ViewerCursorReducer, IViewerCursorState } from './ViewerCursorReducer';
import { default as ViewerModeReducer } from './ViewerModeReducer';
import { IViewerMode } from '../../models/IViewMode';
import { default as WorkspaceChartReducer, IWorkspaceChartState } from './WorkspaceChartReducer';
import { default as WorkspaceCommentReducer, IWorkspaceCommentState } from './WorkspaceCommentReducer';
import { default as WorkspaceDataReducer, IWorkspaceDataState } from './WorkspaceDataReducer';
import { default as WorkspaceExportReducer, IWorkspaceExportState } from './WorkspaceExportReducer';
import { default as WorkspaceGeometryReducer, IWorkspaceGeometryState } from './WorkspaceGeometryReducer';
import { default as WorkspaceMeshReducer, IWorkspaceMeshState } from './WorkspaceMeshReducer';
import { default as WorkspaceMeshTilesReducer, IWorkspaceMeshTilesState } from './WorkspaceMeshTilesReducer';
import { default as WorkspaceOperationReducer, IWorkspaceOperationState } from './WorkspaceOperationReducer';
import { default as WorkspaceQueryReducer, IWorkspaceQueryState } from './WorkspaceQueryReducer';
import { default as WorkspaceReducer, IWorkspaceState } from './WorkspaceReducer';
import { default as WorkspaceVariableReducer, IWorkspaceVariableState } from './WorkspaceVariableReducer';
import { default as ProjectionsReducer, IProjectionsState } from './ProjectionsReducer';
import { default as NotificationReducer, INotificationState } from './NotificationReducer';
import { default as ProgressItemsReducer, IProgressItemsState } from './ProgressItemsReducer';
import { default as ApplicationsReducer, IApplicationsState } from './ApplicationsReducer';
import { default as ProjectContentReducer, IProjectContentState } from './ProjectContentReducer';
import { default as WorkspaceOpenDataReducer, IWorkspaceOpenDataState } from './WorkspaceOpenDataReducer';
import { default as SocketReducer, ISocketState } from './SocketReducer';
import { default as PanelSettingsReducer, IPanelSettingsState } from './PanelSettingsReducer';
import { default as EditReducer, IEditState } from './EditReducer'; 


export default combineReducers({
  AppReducer,
  ConfirmPanelReducer,  
  GeometryClientOperationReducer, 
  GeometryMapToolReducer,
  MapToolReducer,
  MeshMapToolReducer,
  PanelReducer,
  ProjectReducer,
  ToastReducer,
  UserReducer, 
  VariableMapToolReducer,
  ViewerCursorReducer,
  ViewerModeReducer,
  WorkspaceChartReducer,
  WorkspaceCommentReducer,
  WorkspaceDataReducer,
  WorkspaceExportReducer,
  WorkspaceGeometryReducer,
  WorkspaceMeshReducer,
  WorkspaceMeshTilesReducer,
  WorkspaceOperationReducer,
  WorkspaceQueryReducer,
  WorkspaceReducer,
  WorkspaceVariableReducer,
  ProjectionsReducer,
  NotificationReducer,
  ProgressItemsReducer,
  ApplicationsReducer,
  ProjectContentReducer,
  WorkspaceOpenDataReducer,
  SocketReducer,
  PanelSettingsReducer,
  EditReducer
})

export interface IGlobalState {
  AppReducer: IAppState;
  ConfirmPanelReducer: IConfirmPanelState; 
  GeometryClientOperationReducer: IGeometryClientOperationState;
  GeometryMapToolReducer: IGeometryMapToolState;
  MapToolReducer: IMapToolState;
  MeshMapToolReducer: IMeshMapToolState;
  PanelReducer: IPanelState;
  ProjectReducer: IProjectState;
  ToastReducer: IToastState;
  UserReducer: IUserState;
  VariableMapToolReducer: IVariableMapToolState;
  ViewerCursorReducer: IViewerCursorState;
  ViewerModeReducer: IViewerMode;
  WorkspaceChartReducer: IWorkspaceChartState;
  WorkspaceCommentReducer: IWorkspaceCommentState;
  WorkspaceDataReducer: IWorkspaceDataState;
  WorkspaceExportReducer: IWorkspaceExportState;
  WorkspaceGeometryReducer: IWorkspaceGeometryState;
  WorkspaceMeshReducer: IWorkspaceMeshState;
  WorkspaceMeshTilesReducer: IWorkspaceMeshTilesState;
  WorkspaceOperationReducer: IWorkspaceOperationState;
  WorkspaceQueryReducer: IWorkspaceQueryState;
  WorkspaceReducer: IWorkspaceState;
  WorkspaceVariableReducer: IWorkspaceVariableState;
  ProjectionsReducer: IProjectionsState;
  NotificationReducer: INotificationState;
  ProgressItemsReducer: IProgressItemsState;
  ApplicationsReducer: IApplicationsState;
  ProjectContentReducer: IProjectContentState;
  WorkspaceOpenDataReducer: IWorkspaceOpenDataState;
  SocketReducer: ISocketState;
  PanelSettingsReducer: IPanelSettingsState;
  EditReducer: IEditState;
}

/* export { default as AppReducer } from './AppReducer';
export { default as ConfirmPanelReducer } from './ConfirmPanelReducer';
export { default as GeometryClientOperationReducer } from './GeometryClientOperationReducer';
export { default as GeometryMapToolReducer } from './GeometryMapToolReducer';
export { default as MapToolReducer } from './MapToolReducer';
export { default as MeshMapToolReducer } from './MeshMapToolReducer';
export { default as PanelReducer } from './PanelReducer';
export { default as ProjectReducer } from './ProjectReducer';
export { default as ToastReducer } from './ToastReducer';
export { default as UserReducer } from './UserReducer';
export { default as VariableMapToolReducer } from './VariableMapToolReducer';
export { default as ViewerCursorReducer } from './ViewerCursorReducer';
export { default as ViewerModeReducer } from './ViewerModeReducer';
export { default as WorkspaceChartReducer } from './WorkspaceChartReducer';
export { default as WorkspaceCommentReducer } from './WorkspaceCommentReducer';
export { default as WorkspaceDataReducer } from './WorkspaceDataReducer';
export { default as WorkspaceExportReducer } from './WorkspaceExportReducer';
export { default as WorkspaceGeometryReducer } from './WorkspaceGeometryReducer';
export { default as WorkspaceMeshReducer } from './WorkspaceMeshReducer';
export { default as WorkspaceMeshTilesReducer } from './WorkspaceMeshTilesReducer';
export { default as WorkspaceOperationReducer } from './WorkspaceOperationReducer';
export { default as WorkspaceQueryReducer } from './WorkspaceQueryReducer';
export { default as WorkspaceReducer } from './WorkspaceReducer';
export { default as WorkspaceVariableReducer } from './WorkspaceVariableReducer';
export { default as ProjectionsReducer } from './ProjectionsReducer';
export { default as NotificationReducer } from './NotificationReducer';
export { default as ProgressItemsReducer } from './ProgressItemsReducer';
export { default as ApplicationsReducer } from './ApplicationsReducer';
export { default as ProjectContentReducer } from './ProjectContentReducer';
export { default as WorkspaceOpenDataReducer } from './WorkspaceOpenDataReducer';
export { default as SocketReducer } from './SocketReducer';
export { default as PanelSettingsReducer } from './PanelSettingsReducer';
export { default as EditReducer } from './EditReducer'; */
