import { useEffect } from 'react';
import { isEqual, difference, isEmpty } from 'lodash-es';
import { store } from '../store/store';
import WorkspaceDataItemUtils from '../store/selectors/WorkspaceDataItemUtils';
import { IWorkspaceDataItem } from '../models/IWorkspaceData';
import { IWorkspaceMesh } from '../models/IMeshes';
import { usePrevious } from '../shared/hooks/hooks';
import WDISelectors, { IDataItemsKVP } from '../store/selectors/WorkspaceDataItemSelectors';
import { getUserSettings } from '../store/selectors/WorkspaceSelectors';
import { useSelector } from 'react-redux';
import { EWorkspaceMeshActionType } from '../store/actions/WorkspaceMeshActionType';
import { EWorkspaceVariableActionType } from '../store/actions/WorkspaceVariableActionType';
import { ISettingsLayer, IUserSettings } from '../models/IUserSettings';
import { IGlobalState } from '../store/reducers';

/**
 * Set default visibility for new items being added to the workspace after loading
 * This can happen by operations like duplicating or by editing the workspace. Can also be triggered by another user
 *
 * @param workspaceId
 */
export const useDefaultHiddenItems = (workspaceId: string) => {
  const dataItems: IDataItemsKVP = useSelector((state: IGlobalState) => WDISelectors.getWorkspaceDataItemsKVP(state));
  const settings: IUserSettings = useSelector((state: IGlobalState) => getUserSettings(state));
  const prevDataItems = usePrevious(dataItems);

  useEffect(
    () => {
      // Skip if this is the first load of the workspace.
      if (!workspaceId || !dataItems) {
        return;
      }

      if (noDataItems(dataItems)) {
        return;
      }

      const visibleLayers: ISettingsLayer[] =
        settings && settings.tableOfContents && settings.tableOfContents.layers
          ? settings.tableOfContents.layers.filter((l: ISettingsLayer) => l.visible === true)
          : [];
      const visibleIds = visibleLayers.map((l: ISettingsLayer) => l.vtkItemId);

      // By default new geometries are shown on workspace level, so no need to do hide any items
      // By default new variables are hidden on workspace level, unless the workspace contains only one variable and no geometries or meshes
      const addedVariableIds = getAddedItemIds(
        dataItems ? dataItems.variables : [],
        prevDataItems ? prevDataItems.variables : [],
        visibleIds,
      );
      if (!isEmpty(addedVariableIds) && hideVariablesByDefault(dataItems)) {
        store.dispatch({
          type: EWorkspaceVariableActionType.UPDATE_USER_HIDDEN,
          userHiddenVariableIds: addedVariableIds,
        });
      }

      // By default new Meshes are hidden on workspace level to not interfere with what meshes the user is currently seeing.
      // However, if the workspace did not have any meshes before, we will show the most recent of the addeed meshes
      let newUserHiddenMeshIds = [];
      const prevMeshes = prevDataItems && prevDataItems.meshes;
      if (!prevMeshes || !prevMeshes.length) {
        // If there were not any meshes at all, hide all but the most recent updated mesh on workspace level
        newUserHiddenMeshIds = getMeshIdsExceptMostRecentUpdated(dataItems.meshes, visibleIds);
      } else {
        // Hide all added meshes
        newUserHiddenMeshIds = getAddedItemIds(dataItems.meshes, prevDataItems.meshes, visibleIds);
      }
      if (newUserHiddenMeshIds && newUserHiddenMeshIds.length) {
        store.dispatch({
          type: EWorkspaceMeshActionType.UPDATE_USER_HIDDEN,
          userHiddenMeshIds: newUserHiddenMeshIds,
        });
      }
    },
    [workspaceId, prevDataItems, dataItems, settings],
  );
};

// get ids of what was added in items compared to prevItems.
const getAddedItemIds = (
  items: Array<IWorkspaceDataItem>,
  prevItems: Array<IWorkspaceDataItem>,
  visibleIds: Array<string>,
) => {
  const itemIds = WorkspaceDataItemUtils.getIds(items);
  const prevItemIds = WorkspaceDataItemUtils.getIds(prevItems);
  if (!isEqual(itemIds, prevItemIds)) {
    const newItemIds = difference(itemIds, prevItemIds);
    return newItemIds.filter((i: string) => !visibleIds.includes(i));
  }
  return null;
};

// Check if all dataItems are empty:
const noDataItems = (dataItems: IDataItemsKVP) => {
  return !dataItems.variables.length && !dataItems.geometries.length && !dataItems.meshes.length;
};

const getMeshIdsExceptMostRecentUpdated = (meshes: Array<IWorkspaceMesh>, visibleIds: Array<string>): Array<string> => {
  if (!meshes || !meshes.length) {
    return [];
  }
  const meshIds = WorkspaceDataItemUtils.getIds(meshes);
  if (meshIds.length === 1) {
    // This one mesh will be the most recent updated
    return [];
  }
  const mostRecentMesh: IWorkspaceDataItem = WorkspaceDataItemUtils.getMostRecentUpdated(meshes);
  return meshIds.filter((mId) => mId !== mostRecentMesh.id && !visibleIds.includes(mId));
};

// By default all variables are hidden unless the workspace contains only one variable and no geometries or meshes
const hideVariablesByDefault = (dataItems: IDataItemsKVP) => {
  const { variables, geometries, meshes } = dataItems;
  return variables.length > 1 || !isEmpty(geometries) || !isEmpty(meshes);
};
