import { Style, Text, Fill } from 'ol/style';

import MikeVisualizer from '../../MikeVisualizer';
import { getOlStylePresets } from '../../MikeVisualizer/lib/2d/MikeVisualizer2DDrawConstants';
import { FONTFAMILY } from '../../shared/styles/mikeSharedTheme';

const { getConfiguration } = MikeVisualizer;
const { drawColors } = getConfiguration();
const defaultVectorLayerStyle = getOlStylePresets().DEFAULT.vectorLayer;
const selectVectorLayerStyle = getOlStylePresets().DEFAULT.selectInteraction;
const LABELFONTSIZE = '0.8rem';

/**
 * Generates an ol style fn that shows labels for the Z values of each drawn feature.
 * TODO dan: this is a temporary way to display elevation values, and it should be replaced in the future by a legend & color coding (maybe).
 *
 * @param style
 */
const makePointWithElevationTextStyle = (style: (feature) => Array<Style>) => (feature) => {
  try {
    const labelText = feature
      .getGeometry()
      .getCoordinates()[2]
      .toString();

    return [
      ...style(feature),
      new Style({
        text: new Text({
          text: labelText,
          fill: new Fill({ color: drawColors.primary }),
          font: `${LABELFONTSIZE} ${FONTFAMILY}`,
          offsetY: 16,
        }),
      }),
    ];
  } catch (error) {
    console.log(error)
    return style(feature);
  }
};

export const pointDrawVectorLayerStyle = makePointWithElevationTextStyle(defaultVectorLayerStyle);
export const pointSelectVectorLayerStyle = makePointWithElevationTextStyle(selectVectorLayerStyle);
