import { MMG_BASE_MAPS } from './viewer-tool-constants';
import {
  IBaseMapConfiguration,
  BASE_MAP_IDS,
} from '../../../MikeVisualizer/MikeBaseMapConfigurations';

/**
 * Gets the configuration of a basemap by looking it up by id.
 *
 * @param baseMapId
 */
export const getBaseMapConfigurationById = (baseMapId: string): IBaseMapConfiguration => {
  switch (baseMapId) {
    case BASE_MAP_IDS.MAPBOX_SATELLITE:
      return MMG_BASE_MAPS.MAPBOX_SATELLITE;
    case BASE_MAP_IDS.MAPTILER_STREET:
      return MMG_BASE_MAPS.MAPTILER_STREET;
    case BASE_MAP_IDS.NONE:
      return MMG_BASE_MAPS.NONE;
    default:
      return { id: baseMapId, title: '', url: '', attributions: [] };
  }
};

/**
 * returns true if the basemap id is a mapbox map
 * todo hevo Should be moved to shared.
 * @param baseMapId
 */
export const isMapboxMap = (baseMapId: string) => {
  if (!baseMapId) {
    return false;
  }
  return baseMapId === BASE_MAP_IDS.MAPBOX_SATELLITE || baseMapId === BASE_MAP_IDS.MAPBOX_STREET;
};
