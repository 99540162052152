/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { PanelHeaderZindex } from '../styles/styleConsts';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';

const PanelTopActionsStyle = css`
  background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  padding: ${mikeSharedTheme.spacing(2)};
  border: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
  border-left: 0;
  border-right: 0;

  z-index: ${PanelHeaderZindex};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type PanelTopActionsProps = {
  children?: React.ReactNode;
};

/**
 * @name MmgPanelTopActions
 * @summary Panels can have optional top actions, usually containing buttons.
 *
 * @param props
 */
export const MmgPanelTopActions = (props: PanelTopActionsProps) => {
  return <div css={PanelTopActionsStyle}>{props.children}</div>;
};
