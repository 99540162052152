/**
 * Exposes methods to transform or combine project requests.
 *
 * @module ProjectManager
 * @version 2.0.0
 * @requires ProjectProxy
 */
import * as ProjectProxy from '../proxies/ProjectProxy';
import { HttpErrorHandler } from './http-utils';
import { IProject } from '../models/IProject';

/**
 * Transforms a request that gets a list of all projects.
 */
const getProjectList = (): Promise<Array<IProject>> => {
  return ProjectProxy.getProjectList()
    .then((res) => res.data.data)
    .catch((error) => HttpErrorHandler('Failed to get project list.', error));
};

/**
 * Transforms a request that gets a list of all subprojects.
 *
 * @param projectId
 */
const getSubprojectsList = (projectId: string): Promise<Array<IProject>> => {
  return ProjectProxy.getSubprojectsList(projectId)
    .then((res) => res.data.data)
    .catch((error) => HttpErrorHandler('Failed to get project list.', error));
};

/**
 * Transforms a request that gets one project.
 *
 * @param projectId
 */
const getProject = (projectId: string): Promise<IProject> => {
  return ProjectProxy.getProject(projectId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get project.', error));
};

/**
 * Transforms a request that creates an empty project.
 *
 * @param name
 * @param description
 * @param targetProjectId
 */
const createProject = (name: string, description?: string, targetProjectId?: string): Promise<IProject> => {
  const project: IProject = {
    name,
    parentProjectId: targetProjectId,
  };

  if (description) {
    project.description = description;
  }

  return ProjectProxy.createProject(project)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to create project.', error));
};

const getProjectPath = (projectId: string): Promise<IProject> => {
  return ProjectProxy.getProjectPath(projectId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get project.', error));
};

const self = {
  getProjectList,
  getProject,
  createProject,
  getProjectPath,
  getSubprojectsList
};

export default self;
