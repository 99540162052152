/**
 * Exposes reselect-selectors methods for map tools.
 *
 * @module MapToolSelectors
 * @version 1.0.0
 */

import { createSelector } from 'reselect';
import { IGlobalState } from '../../store/reducers';

const activeConfirmPanelId = (state: IGlobalState) => state.ConfirmPanelReducer.activeConfirmPanelId;

/**
 * Selector to return if the active maptools requires the viewer to be in 2D
 */
const is2DModeRequired = createSelector([activeConfirmPanelId], (panelId) => {
  // For now, 2d mode is always required if there is a confirm panel active, since these are only (and always) used for 2D tools.
  // todo hevo this can be made more explixit based on the tools currently enabled in any of the map tool reducers (MapToolreducer, GeometryMapToolReduer, MeshMapToolreducer)
  return Boolean(panelId);
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

const self = {
  is2DModeRequired,
};

export default self;
