import { useState, useEffect, useCallback } from 'react';

import MeshInterpolationOperationsManager from '../../managers/MeshInterpolationOperationsManager';
import { IOperationConfiguration } from '../../models/IOperations';
import { usePrevious } from '../../shared/hooks/hooks';

/**
 * Custom hook that loads an interpolation operation given by the id
 * @param workspaceId
 * @param operationId
 */
export const useAsyncLoadInterpolationOperation = (
  workspaceId: string,
  operationId: string,
): {
  interpolationOperation: IOperationConfiguration;
  interpolationOperationLoading: boolean;
  interpolationOperationLoadingFailed: boolean;
  setInterpolationOperation: (interpolationConfiguration: IOperationConfiguration) => void;
  retryLoadInterpolationOperation: () => void;
} => {
  const [interpolationOperationLoading, setInterpolationOperationLoading] = useState(false);

  const [interpolationOperationLoadingFailed, setInterpolationOperationLoadingFailed] = useState(false);

  const [interpolationOperation, setInterpolationOperation] = useState<IOperationConfiguration>(null);

  const prevWorkspaceId = usePrevious(workspaceId);
  const prevOperationId = usePrevious(operationId);

  /**
   * Gets the mesh interpolation operation configuration by id
   */
  const getMeshInterpolationOperation = useCallback(
    (force: boolean) => {
      if (
        !workspaceId ||
        !operationId ||
        (!force && prevWorkspaceId === workspaceId && prevOperationId === operationId)
      ) {
        return Promise.resolve();
      }

      setInterpolationOperationLoading(true);
      setInterpolationOperationLoadingFailed(false);

      return MeshInterpolationOperationsManager.getInterpolateMeshOperation(workspaceId, operationId)
        .then((operation) => {
          setInterpolationOperation(operation);
        })
        .catch((error) => {
          setInterpolationOperationLoadingFailed(true);
          console.error('Failed to get interpolate mesh operation');
          throw error;
        })
        .finally(() => {
          setInterpolationOperationLoading(false);
        });
    },
    [workspaceId, operationId, prevWorkspaceId, prevOperationId],
  );

  const retryLoadInterpolationOperation = () => {
    getMeshInterpolationOperation(true);
  };

  // load mesh interpolation operation
  useEffect(
    () => {
      if (operationId) {
        getMeshInterpolationOperation(false);
      }
    },
    [getMeshInterpolationOperation, operationId],
  );

  return {
    interpolationOperation,
    interpolationOperationLoading,
    interpolationOperationLoadingFailed,
    setInterpolationOperation,
    retryLoadInterpolationOperation,
  };
};
