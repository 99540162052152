/**
 * Exposes reselect-selectors methods for geometries.
 *
 * @module WorkspaceGeometrySelectors
 * @version 1.0.0
 */

import { createSelector } from 'reselect';
import WorkspaceDataItemUtils from './WorkspaceDataItemUtils';
import WorkspaceDrawnDataUtils from './WorkspaceDrawnDataUtils';
import WorkspaceQueryUtils from './WorkspaceQueryUtils';
import ModelUtils from '../../managers/model-utils';
import WorkspaceGeometryOperationSelectors from '../../store/selectors/WorkspaceGeometryOperationSelectors';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceQuery } from '../../models/IQueries';
import { IOperationMetadata } from '../../models/IOperations';
import { IWorkspaceEnrichedGeometry, IWorkspaceGeometry } from '../../models/IGeometries';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings, ATTRIBUTE_SETTINGS } from '../../models/IWorkspaceAttributeSettings';
import { store } from '../store';
import { IGlobalState } from '../reducers';
interface IGeometryId {
  geometryId: string;
}
interface IGeometryIds {
  geometryIds: Array<string>;
}

export const getWorkspaceGeometries = (state: IGlobalState): Array<IWorkspaceGeometry> =>
  state.WorkspaceGeometryReducer.workspaceGeometries;

export const simplyGetWorkspaceGeometries = () => {
  const state: IGlobalState = store.getState() as IGlobalState;
  return getWorkspaceGeometries(state);
};

export const justGetHiddenWorkspaceGeometryIds = () => {
  const state = store.getState();
  return state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries;
};

const selectedWorkspaceGeometryIds = (state: IGlobalState): Array<string> =>
  state.WorkspaceGeometryReducer.selectedWorkspaceGeometries;

// TODO: joel: Fix TS error here when adding IGlobalState:
const workspaceData = (state: IGlobalState): Array<IDrawnDataItem> => state.WorkspaceDataReducer.workspaceData;

const workspaceQueries = (state: IGlobalState): Array<IWorkspaceQuery> => state.WorkspaceQueryReducer.workspaceQueries;

// selects the geometryId from props
const geometryIdProp = (_state, props: IGeometryId) => props.geometryId;

// selects the geometryIds from props
const geometryIdsProp = (_state, props: IGeometryIds) => props.geometryIds;

/**
 * Enriches workspace geoemtries with metadata from latest operation
 */
const _getEnrichedWorkspaceGeometries = createSelector(
  [WorkspaceGeometryOperationSelectors.getLatestGeometryOperations, getWorkspaceGeometries],
  (operations: Array<IOperationMetadata>, geometries: Array<IWorkspaceGeometry>): Array<IWorkspaceEnrichedGeometry> => {
    // todo hevo test!!!
    return WorkspaceDataItemUtils.getEnrichedDataItems(operations, geometries, ELEMENT_CATEGORIES.GEOMETRY) as Array<
      IWorkspaceEnrichedGeometry
    >;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

/**
 * Selector to return sorted enriched workspace geometries
 *
 * todo hevo Consider turn this around, to sort first - then enrich. Sortorder does not rely on anything from the enrichment
 * also consider not using the list of enriched items unleass needd. The entire list will change whenever just one of the items in the list changes.
 */

const getSortedEnrichedWorkspaceGeometries = createSelector(
  [_getEnrichedWorkspaceGeometries],

  (geometries) => {
    if (!geometries) {
      return [];
    }
    return ModelUtils.sortGeometries(geometries);
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
); // createSelector<any, any, Array<IWorkspaceGeometry>>

/**
 * Selector to return selected enriched workspace geometries. Will be sorted.
 * todo hevo consider if these always need to be enriched. It might trigger too many re-renders
 */
const getSelectedEnrichedWorkspaceGeometries = createSelector(
  [_getEnrichedWorkspaceGeometries, selectedWorkspaceGeometryIds],
  (geometries, selectedIds) => {
    const selectedGeometries = WorkspaceDataItemUtils.getDataItemsByIds(geometries, selectedIds);
    return ModelUtils.sortGeometries(selectedGeometries);
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

/**
 * Selector to return workspace geometries not having the status failed. Will be sorted.
 */
const getWorkspaceGeometriesNotFailed = createSelector([_getEnrichedWorkspaceGeometries], (geometries) => {
  if (!geometries) {
    return [];
  }
  const filteredGeometries = WorkspaceDataItemUtils.getItemsNotFailed(geometries);
  return ModelUtils.sortGeometries(filteredGeometries);
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Selector to return the workspace geometry based on the geometryId prop.
 */
const _getGeometry = createSelector([_getEnrichedWorkspaceGeometries, geometryIdProp], (geometries, geometryId) => {
  if (!geometries || !geometryId) {
    return null;
  }
  return geometries.find(({ id }) => geometryId === id) || null;
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Returns an instance of a selector for getting the workspace geometry based on the geometryId prop.
 */
const makeGetGeometry = () => {
  return createSelector([_getGeometry], (geometry) => {
    return geometry;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  });
};

/**
 * Selector to return drawn data for the workspace geometry based on the geometryId prop.
 */
const _getGeometryDrawnData = createSelector([workspaceData, geometryIdProp], (drawnDataItems, geometryId) => {
  if (!drawnDataItems || !geometryId) {
    return null;
  }
  return drawnDataItems.find(({ id }) => geometryId === id) || null;
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Returns an instance of a selector for getting drawn data for the workspace geometry based on the geometryId prop.
 */
const makeGetGeometryDrawnData = () => {
  return createSelector([_getGeometryDrawnData], (drawnData) => {
    return drawnData;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  });
};

/**
 * Selector to return workspace geometries based on geometryIds prop. Will be sorted.
 */
const _getGeometries = createSelector([_getEnrichedWorkspaceGeometries, geometryIdsProp], (geometries, geometryIds) => {
  const geometriesByIds = WorkspaceDataItemUtils.getDataItemsByIds(geometries, geometryIds);
  return ModelUtils.sortGeometries(geometriesByIds);
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Returns an instance of a selector for getting  geometries based on the geometryIds prop.
 */
const makeGetGeometries = () => {
  return createSelector([_getGeometries], (geometries) => {
    return geometries;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  });
};

/**
 * Selector to return queries for the workspace geometry based on the geometryId prop.
 */
const _getGeometryQueries = createSelector(
  [workspaceQueries, geometryIdProp],
  (queries: Array<IWorkspaceQuery>, geometryId) => {
    if (!queries || !geometryId) {
      return null;
    }
    const queriesForGeometry = WorkspaceQueryUtils.filterQueriesByTargetItem(queries, geometryId);
    if (!queriesForGeometry.length) {
      return null;
    }
    return ModelUtils.sortQueries(queriesForGeometry);
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

const makeGetGeometryQueries = () => {
  return createSelector([_getGeometryQueries], (queries) => {
    return queries;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  });
};

/**
 * Selector to return geometry attributes  based on the geometryId prop.
 */
const _getGeometryAttributes = createSelector([workspaceData, geometryIdProp], (drawnDataItems, geometryId) => {
  return WorkspaceDrawnDataUtils.getAttributes(geometryId, drawnDataItems);
}, {
  devModeChecks: {identityFunctionCheck: 'never'}
});

/**
 * Returns an instance of a selector for getting attributes of the geometry based on the geometryId prop.
 */
const makeGetGeometryAttributes = () => {
  return createSelector([_getGeometryAttributes], (attributes) => {
    return attributes;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  });
};

/**
 * Selector to return settings for the the geometry based on the geometryId prop.
 */
const _getGeometryAttributeSettings = createSelector(
  [_getGeometryAttributes, geometryIdProp],
  (attributes: Array<IWorkspaceAttribute>): Array<IWorkspaceAttributeSettings> => {
    if (!attributes || !attributes.length) {
      return [];
    }
    const attributeSettings = ATTRIBUTE_SETTINGS.filter(
      (setting) => attributes.findIndex((d) => d.name === setting.name) !== -1,
    );
    return attributeSettings;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

/*
* Returns an instance of a selector for getting geometry attributes settings of the geometry based on the geometryId prop.
*/
const makeGetGeometryAttributeSettings = () => {
  return createSelector([_getGeometryAttributeSettings], (attributeSettings) => {
    return attributeSettings;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  });
};

const self = {
  getWorkspaceGeometries,
  simplyGetWorkspaceGeometries,
  getSortedEnrichedWorkspaceGeometries,
  getSelectedEnrichedWorkspaceGeometries,
  getWorkspaceGeometriesNotFailed,

  makeGetGeometry,
  makeGetGeometryDrawnData,
  makeGetGeometryQueries,
  makeGetGeometryAttributes,
  makeGetGeometryAttributeSettings,
  makeGetGeometries,

  _getGeometry,
  _getGeometryDrawnData,
  _getGeometryQueries,
  _getGeometryAttributes,
  _getGeometryAttributeSettings,
  _getEnrichedWorkspaceGeometries,
  _getGeometries,
};

export default self;
