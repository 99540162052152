import * as ulp from 'ulp';
import { isNumber, debounce, isEmpty } from 'lodash-es';
import { IWorkspaceUIGenericQuery, IWorkspaceUIQueryRangeValues, QUERY_TYPE } from '../../models/IWorkspaceUIQueries';
import MikeVisualizer from '../../MikeVisualizer';
import { DEFAULTS } from '../../workspaces/viewer/viewer-utils';

import {
  IQueryDefinitionApi,
  IAttributeRangeQueryDefinitionApi,
  QUERY_DEFINITION_TYPES,
  IPersistedQueryDefinitionApi,
  ISpatialQueryDefinitionApi,
  ISelectAllQueryDefinitionApi,
  IAttributeQueryDefinitionApi,
} from '../../models/IQueryDefinitions';
import { EElementCategories } from '../../shared/panels/mesh-panel-constants';
import { IRepresentation } from '../../MikeVisualizer/lib/models/IRepresentation';
import {
  getAttributeRepresentation,
  getAttributePointSize,
} from '../../shared/visualization-settings/visualization-utils';
import { EMeshItemTypes } from '../../models/IMeshes';
import { isAttributeCategorical } from '../../queries/attribute-queries/attribute-utils';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import {
  updateWorkspaceDataItemColor,
  updateWorkspaceDataItemGradient,
  updateWorkspaceDataItemPointSize,
  updateWorkspaceDataItemRepresentation,
} from '../../store/actions/workspaceDataActions';
import { IColorRange, IDrawnDataGradientSettings } from '../../MikeVisualizer/lib/IMikeVisualizerModels';

const { generateColorMap, generateSelectAllColorMap } = MikeVisualizer;

/**
 * Checks if a query has all the data it requires to be submitted.
 * @param queryDefinition
 */
const isQueryDefinitionComplete = (queryDefinition: IQueryDefinitionApi) => {
  if (!queryDefinition) {
    return false;
  }

  const { type } = queryDefinition;
  switch (type) {
    case QUERY_DEFINITION_TYPES.SELECT_ALL:
      return true;

    case QUERY_DEFINITION_TYPES.PERSISTED_SELECTION: {
      const persistedQueryDefinition = queryDefinition as IPersistedQueryDefinitionApi;
      return !isEmpty(persistedQueryDefinition.queryId);
    }

    case QUERY_DEFINITION_TYPES.ATTRIBUTE_RANGE: {
      const rangeQueryDefinition = queryDefinition as IAttributeRangeQueryDefinitionApi;
      return isNumber(rangeQueryDefinition.fromValue) && isNumber(rangeQueryDefinition.toValue);
    }

    case QUERY_DEFINITION_TYPES.SPATIAL: {
      const spatialQueryDefinition = queryDefinition as ISpatialQueryDefinitionApi;
      return Boolean(spatialQueryDefinition.sourceItemId) || Boolean(spatialQueryDefinition.featureCollection);
    }
    default:
      return false;
  }
};

/**
 * Restores display properties of a given item.
 * Sets colors to default, then tries to apply gradient if provided; otherwise applies 'basic' colors if those are provided, otherwise does nothing.
 *
 * @param elementId
 * @param [edgeColor]
 * @param [surfaceColor]
 * @param representation
 * @param gradientSettings
 * @param gradientAttributeName
 * @param pointSize
 * @param colorRange
 */
const restoreDisplayProperties = (
  elementId: string,
  edgeColor?: Array<number>,
  surfaceColor?: Array<number>,
  representation?: IRepresentation,
  gradientSettings?: IDrawnDataGradientSettings,
  gradientAttributeName?: string,
  pointSize?: number,
  colorRange?: IColorRange,
) => {
  // Set colors to default
  updateWorkspaceDataItemColor({
    elementId,
    edgeColor: DEFAULTS.colors.default.edge,
    surfaceColor: DEFAULTS.colors.default.surface,
  });

  if (gradientAttributeName) {
    const dataGradient = {
      workspaceGradientId: elementId,
      workspaceGradientAttributeName: gradientAttributeName,
      workspaceGradientSettings: gradientSettings,
      colorRange,
    };
    updateWorkspaceDataItemGradient(dataGradient);
    return true;
  }

  if (edgeColor && surfaceColor) {
    updateWorkspaceDataItemColor({ elementId, edgeColor, surfaceColor });
    return true;
  }

  if (representation) {
    updateWorkspaceDataItemRepresentation(elementId, representation);
    return true;
  }

  updateWorkspaceDataItemPointSize(elementId, pointSize);

  return false;
};

const getRangeValues = (query: IWorkspaceUIGenericQuery): IWorkspaceUIQueryRangeValues => {
  let min: number;
  let max: number;

  if (query && query.queryType !== QUERY_TYPE.SELECT_ALL) {
    if (query.optionQueryValue) {
      min = max = parseInt(query.optionQueryValue, 10);
    } else {
      min = query.rangeQueryMin;
      max = query.rangeQueryMax;
    }
  }

  return { minValue: min, maxValue: max };
};

/**
 *
 * @param queryDefinition get the colormap to use for coloring according to the query definition
 * @param attributeSettings
 */
const getHighlightAttributeQueryColorMap = (
  queryDefinition: ISelectAllQueryDefinitionApi | IAttributeRangeQueryDefinitionApi,
  attributeSettings?: Array<IWorkspaceAttributeSettings>,
) => {
  const { type: queryType } = queryDefinition || {};

  const matchColorWithOpacity = [...DEFAULTS.colors.select.surface];
  const matchColor = [...matchColorWithOpacity].splice(0, 3); // without alpha
  const nonMatchColor = [...DEFAULTS.colors.inactive];

  switch (queryType) {
    case QUERY_DEFINITION_TYPES.SELECT_ALL: {
      // this will set basic actor color, so opacity must be included
      return generateSelectAllColorMap(matchColorWithOpacity);
    }

    case QUERY_DEFINITION_TYPES.ATTRIBUTE_RANGE: {
      const { name: attributeName, fromValue, toValue } = queryDefinition as IAttributeRangeQueryDefinitionApi;

      const isCategorical = isAttributeCategorical(attributeName, attributeSettings);

      if (isCategorical && isNumber(fromValue)) {
        const optionNumberValue = fromValue;
        const colorValuePoints = [optionNumberValue, ulp.nextUp(optionNumberValue)];
        const colorRgbPoints = [matchColor, matchColor];
        return generateColorMap(colorValuePoints, colorRgbPoints, nonMatchColor);
      }

      if (isNumber(fromValue) && isNumber(toValue)) {
        const colorValuePoints = [ulp.nextDown(fromValue), ulp.nextUp(toValue)];
        const colorRgbPoints = [matchColor, matchColor];

        return generateColorMap(colorValuePoints, colorRgbPoints, nonMatchColor);
      }

      return null;
    }

    default:
      return null;
  }
};
/**
 * Color element according to the query definition
 * @param queryDefinition
 * @param elementCategory
 * @param elementId
 * @param attributeSettings
 *
 */
const highlightAttributeQueryResult = (
  queryDefinition: ISelectAllQueryDefinitionApi | IAttributeRangeQueryDefinitionApi,
  elementCategory: EElementCategories,
  elementId: string,
  attributeSettings?: Array<IWorkspaceAttributeSettings>,
) => {
  return debounce(() => {
    const colorMap = self.getHighlightAttributeQueryColorMap(queryDefinition, attributeSettings);

    const { name: queryAttribute } = queryDefinition as IAttributeQueryDefinitionApi;

    const attributeRepresentation = getAttributeRepresentation(queryAttribute, elementCategory);

    if (attributeRepresentation) {
      updateWorkspaceDataItemRepresentation(elementId, attributeRepresentation);
    }

    const attributePointSize = getAttributePointSize(queryAttribute, elementCategory, EMeshItemTypes.MESH);

    updateWorkspaceDataItemPointSize(elementId, attributePointSize);

    if (colorMap) {
      const dataGradient = {
        workspaceGradientId: elementId,
        workspaceGradientAttributeName: queryAttribute,
        workspaceGradientSettings: {
          gradientColorMap: colorMap,
        },
      };
      updateWorkspaceDataItemGradient(dataGradient);
    }
  }, 500)();
};

const self = {
  isQueryDefinitionComplete,
  getRangeValues,
  highlightAttributeQueryResult,
  getHighlightAttributeQueryColorMap,
  restoreDisplayProperties,
};

export default self;
