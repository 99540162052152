import { t } from '../../translations/i18n';
import { store } from '../../store/store';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import WorkspaceMeshesManager from '../../managers/WorkspaceMeshesManager';
import { MmgConnectedMeshListItem } from '../../meshes/list/mesh-list-item';
import { MmgMeshListItemEdit } from '../../meshes/list/mesh-list-item-edit';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import { useDispatch, useSelector } from 'react-redux';
import { addEditingMesh, removeEditingMesh } from '../../store/actions/editingItems';
import { IGlobalState } from '../../store/reducers';
import { useNavigate } from 'react-router-dom';
const { setActiveLayer } = LayerUtils;

type MeshListProps = {
  projectId: string;
  workspaceId: string;
  meshes: Array<IWorkspaceEnrichedMesh>;
  hiddenMeshes: Array<string>;
  drawnMeshes: Array<IDrawnDataItem>;
  failedData: Array<string>;
};

/**
 * @name MmgMeshList
 * @summary Given an array of meshes, renders them and sets up their interactions.
 *
 * @param props
 */
export const MmgMeshList = (props: MeshListProps) => {
  const navigate = useNavigate();
  const editingMeshIds: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.editingMeshIds);
  const { workspaceId, projectId, meshes, hiddenMeshes, drawnMeshes, failedData } = props;
  const dispatch = useDispatch();

  /**
   * Callback for when mesh open is pressed.
   * Opens the mesh panel for it.
   *
   * @param meshId
   */
  const onMeshOpenPressed = (meshId: string) => {
    setActiveLayer(ELEMENT_CATEGORIES.MESH, meshId, workspaceId, projectId, navigate);
  };

  /**
   * Callback for when a mesh edit button is pressed.
   *
   * @param meshId
   */
  const onMeshEdit = (meshId: string) => {
    dispatch(addEditingMesh(meshId));
  };

  /**
   * Callback for when a mesh updates.
   * Sends an api request and displays relevant toasts.

   * @param mesh
   * @param mesh.id
   * @param mesh.name
   */
  const onMeshUpdate = ({ id, name }: IWorkspaceEnrichedMesh) => {
    dispatch(removeEditingMesh(id));

    WorkspaceMeshesManager.renameMesh(workspaceId, id, name)
      .then(() => {
        store.dispatch({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('MESH_UPDATED_SUCCESSFULLY'),
          },
        });
      })
      .catch((error) => {
        dispatch(addEditingMesh(id));

        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: {
            text: t('MESH_UPDATE_FAILED'),
          },
        });

        throw error;
      });
  };

  return (
    <>
      {meshes.map((mesh) => {
        const meshIsEditing = editingMeshIds.includes(mesh.id);

        if (meshIsEditing) {
          return (
            <MmgMeshListItemEdit key={mesh.id} meshName={mesh.name} meshId={mesh.id} onMeshUpdate={onMeshUpdate} />
          );
        }

        return (
          <MmgConnectedMeshListItem
            key={mesh.id}
            mesh={mesh}
            hiddenMeshes={hiddenMeshes}
            drawnMeshes={drawnMeshes}
            onMeshEdit={onMeshEdit}
            onMeshOpenPressed={onMeshOpenPressed}
            failedData={failedData}
          />
        );
      })}
    </>
  );
};
