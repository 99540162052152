import {
  getDeleteLayerAction,
  getCancelLayerAction,
  ILayerAction,
} from '../../shared/layers/actions/layer-default-actions';
import { IOperationMetadata } from '../../models/IOperations';

/**
 *
 * @param operation
 * @param onOperationDelete
 */
export const getOperationActions = (operation: IOperationMetadata, onOperationDelete: () => void) => {
  if (!operation) {
    return [];
  }

  const layerActions = [];

  const deleteLayerAction = getDeleteOperationAction(onOperationDelete);

  layerActions.push(deleteLayerAction);

  return layerActions;
};

export const getDeleteOperationAction = (onOperationDelete: () => void, disabled?: boolean): ILayerAction => {
  const deleteLayerAction = getCancelLayerAction(() => onOperationDelete(), disabled);

  return deleteLayerAction;
};

const self = {
  getOperationActions,
  getDeleteLayerAction,
};

export default self;
