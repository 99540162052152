import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Delete from '../../../icons/Delete.svg?react';
import OpenInEditor from '../../../icons/OpenInEditor.svg?react';
import FileCopyOutlineIcon from '@mui/icons-material/FileCopyOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import { t } from '../../../translations/i18n';
import LayerUtils, { fetchTiledLayer } from '../layer-utils';
import { EElementCategories } from '../../panels/mesh-panel-constants';
import MikeVisualizerViewManager from '../../../MikeVisualizer/lib/MikeVisualizerViewManager';

const { showLayer, hideLayer, isLayerHidden, fetchLayer } = LayerUtils;

export interface ILayerAction {
  actionId: string;
  actionDisplayName: string;
  actionIcon?: React.ReactNode;
  onAction: () => void;
  disabled?: boolean;
}

export const getToggleVisibilityLayerAction = (
  layerCategory: EElementCategories,
  layerId: string,
  layerCategoryHiddenLayers: string[],
  disabled: boolean,
  updateUserSettings = false, // have to be explicit in places where this is wanted
  loadedData?: Array<string>,
  isTiled = false, // meshes can be tiled
) => {
  const hidden = isLayerHidden(layerCategory, layerId, layerCategoryHiddenLayers || []);

  let toggleAction: ILayerAction;

  const isLoaded = loadedData && loadedData.includes(layerId);

  if (hidden && isLoaded) {
    toggleAction = getShowLayerAction(() => {
      showLayer(layerCategory, layerId, updateUserSettings);
    });
  } else if (hidden && !isLoaded) {
    toggleAction = getFetchLayerAction(() => {
      if (isTiled) {
        const { getCurrentViewBounds } = MikeVisualizerViewManager;
        const bounds = getCurrentViewBounds();
        fetchTiledLayer(layerId, [], bounds, false);
      } else {
        fetchLayer(layerId, layerCategory);
      }
    });
  } else {
    toggleAction = getHideLayerAction(() => {
      hideLayer(layerCategory, layerId, updateUserSettings);
    });
  }

  toggleAction.disabled = disabled;

  return toggleAction;
};

const getShowLayerAction = (onAction: () => void) => {
  return {
    actionId: 'SHOW',
    actionDisplayName: t('SHOW'),
    actionIcon: <VisibilityIcon />,
    onAction,
  };
};

const getHideLayerAction = (onAction: () => void) => {
  return {
    actionId: 'HIDE',
    actionDisplayName: t('HIDE'),
    actionIcon: <VisibilityOffIcon />,
    onAction,
  };
};

export const getEditLayerAction = (onAction: () => void, disabled?: boolean): ILayerAction => {
  return {
    actionId: 'RENAME',
    actionDisplayName: t('RENAME'),
    actionIcon: <EditIcon />,
    onAction,
    disabled,
  };
};

export const getReplaceLayerAction = (onAction: () => void, disabled?: boolean): ILayerAction => {
  return {
    actionId: 'REPLACE',
    actionDisplayName: t('REPLACE'),
    actionIcon: <OpenInEditor />,
    onAction,
    disabled,
  };
};

export const getDuplicateLayerAction = (onAction: () => void, disabled?: boolean): ILayerAction => {
  return {
    actionId: 'DUPLICATE',
    actionDisplayName: t('DUPLICATE'),
    actionIcon: <FileCopyOutlineIcon />,
    onAction,
    disabled,
  };
};

export const getDeleteLayerAction = (onAction: () => void, disabled?: boolean): ILayerAction => {
  return {
    actionId: 'DELETE',
    actionDisplayName: t('DELETE'),
    actionIcon: <Delete />,
    onAction,
    disabled,
  };
};

export const getFetchLayerAction = (onAction: () => void, disabled?: boolean): ILayerAction => {
  return {
    actionId: 'FETCH',
    actionDisplayName: t('FETCH'),
    actionIcon: <VisibilityIcon />,
    onAction,
    disabled,
  };
};

export const getCancelLayerAction = (onAction: () => void, disabled?: boolean): ILayerAction => {
  return {
    actionId: 'CANCEL',
    actionDisplayName: t('CANCEL'),
    actionIcon: <Delete />,
    onAction,
    disabled,
  };
};
