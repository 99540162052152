import { IParameterConditionDescription, DENSITY_FUNCTION_PARAMETERS } from '../../models/IOperationDescriptions';

export const MESH_OPERATION_PARAMETER_CONDITIONS: {
  [parameterKey: string]: IParameterConditionDescription;
} = {
  [DENSITY_FUNCTION_PARAMETERS.NUMBER_OF_POINTS]: {
    requires: [DENSITY_FUNCTION_PARAMETERS.APPLY_DENSITY_PARAMETERS],
  },
  [DENSITY_FUNCTION_PARAMETERS.VARIABLE_ID]: {
    requires: [DENSITY_FUNCTION_PARAMETERS.APPLY_DENSITY_PARAMETERS],
  },
  [DENSITY_FUNCTION_PARAMETERS.APPLY_GRADIENT]: {
    requires: [DENSITY_FUNCTION_PARAMETERS.APPLY_DENSITY_PARAMETERS, DENSITY_FUNCTION_PARAMETERS.VARIABLE_ID],
  },
  [DENSITY_FUNCTION_PARAMETERS.DENSITY_BUFFER_SIZE]: {
    requires: [DENSITY_FUNCTION_PARAMETERS.APPLY_DENSITY_PARAMETERS],
  },
  [DENSITY_FUNCTION_PARAMETERS.INVERT_DENSITY]: {
    requires: [DENSITY_FUNCTION_PARAMETERS.APPLY_DENSITY_PARAMETERS, DENSITY_FUNCTION_PARAMETERS.VARIABLE_ID],
  },
};
