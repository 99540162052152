import { all } from 'redux-saga/effects';
import geometrySagas from '../geometries/sagas/geometrySagas';
import meshSagas from '../meshes/sagas/meshSagas';
import variableSagas from '../variables/sagas/variableSagas';
import watchProjects from '../projects/sagas/projectsSagas';
import workspaceSagas from '../workspaces/sagas/workspaceSagas';
import { watchProject, watchProjectContent } from '../project-datasets/sagas/projectContent';
import watchIAM from '../iam/sagas/iam';

/**
 * Exposes all available sagas
 *
 * Combines sagas from different domains such as projects, workspaces etc
 *
 * todo hevo: consider if aný of the other root saga patterns is better?:  https://redux-saga.js.org/docs/advanced/RootSaga.html
 */
export default function* rootSaga() {
  yield all([
    ...watchProjects(),
    ...workspaceSagas(),
    ...meshSagas(),
    ...geometrySagas(),
    ...variableSagas(),
    ...watchProject(),
    ...watchProjectContent(),
    ...watchIAM(),
  ]);
}
