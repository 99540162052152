/* eslint-disable react-refresh/only-export-components */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { useCallback, useMemo } from 'react';
import MikeVisualizerUtil from '../MikeVisualizer/lib/MikeVisualizerUtil';
import { translateWithPrefix } from '../translations/utils';
import { IDrawnDataLegend } from '../models/IWorkspaceData';
import { getItemName } from '../store/selectors/WorkspaceDataItemUtils';
import { updateWorkspaceDataItemGradient } from '../store/actions/workspaceDataActions';
import { ATTRIBUTE_SETTINGS } from '../models/IWorkspaceAttributeSettings';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';

const containerStyle = css`
  background-color: ${mikeSharedTheme.palette.background.default};
  height: 100%;
  margin-left: ${mikeSharedTheme.spacing(1)};
  margin-right: ${mikeSharedTheme.spacing(1)};
  width: ${mikeSharedTheme.spacing(20)};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${mikeSharedTheme.spacing(1)};
`;

const sliderLegendContainer = (isTiled: boolean) => {
  return isTiled
    ? css`
        padding-top: ${mikeSharedTheme.spacing(1)};
        display: flex;
        align-items: center;
      `
    : css`
        padding-top: ${mikeSharedTheme.spacing(1)};
        display: flex;
        height: ${mikeSharedTheme.spacing(22)};
        align-items: center;
      `;
};

const sliderStyle = css`
  & input[type='range']: {
    webkitappearance: slider-vertical;
  }
`;

export const legendListitemStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const legendListitemColorStyle = css`
  width: ${mikeSharedTheme.spacing(2)};
  height: ${mikeSharedTheme.spacing(2)};
  padding: 0px ${mikeSharedTheme.spacing(1)} 0px ${mikeSharedTheme.spacing(1)};
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const legendListitemTextStyle = css`
  padding: 0px ${mikeSharedTheme.spacing(1)} 0px ${mikeSharedTheme.spacing(1)};
  color: ${mikeSharedTheme.palette.primary.main};
  font-size: ${mikeSharedTheme.spacing(1.5)};
`;

export const ellipsisStyle = css`
  width: ${mikeSharedTheme.spacing(18)};
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type MmgViewerColorLegendItemProps = {
  legendData: IDrawnDataLegend;
};

const MmgViewerColorLegendItem = (props: MmgViewerColorLegendItemProps) => {
  const { legendData } = props;
  const MAX_NUMBER_OF_ITEMS = 10;

  const { layerName, fullRange, legendItems, nTh, range, hideSlider } = useMemo(
    () => {
      if (legendData) {
        const attributeSetting = ATTRIBUTE_SETTINGS.find((a) => a.name === legendData.title);

        const name = legendData ? getItemName(legendData.elementId) : '';
        const valueRange = !legendData.isTiled
          ? MikeVisualizerUtil.getDataRange(legendData.elementId, legendData.title)
          : legendData.tiledMeshDataRange
            ? legendData.tiledMeshDataRange
            : legendData.colorRange.colorMappingRange;
        return {
          layerName: name,
          fullRange: valueRange,
          isTiled: legendData.isTiled,
          legendItems: legendData.legendItems,
          range:
            legendData.colorRange && legendData.colorRange.colorMappingRange
              ? legendData.colorRange.colorMappingRange
              : valueRange,
          nTh:
            legendData.legendItems && legendData.legendItems.length > MAX_NUMBER_OF_ITEMS
              ? Math.ceil(legendData.legendItems.length / MAX_NUMBER_OF_ITEMS)
              : 1, // If there are too many items, we pick every nTh.
          hideSlider: attributeSetting ? attributeSetting.isCategorical : false, // ToDo: Backend need to return unique values
        };
      }
    },
    [legendData],
  );

  const handleChangeRange = useCallback(
    (newRange: Array<number>) => {
      if (newRange && newRange.length === 2) {
        updateWorkspaceDataItemGradient({
          workspaceGradientId: legendData.elementId,
          workspaceGradientAttributeName: legendData.title,
          workspaceGradientSettings: legendData.gradientSettings ? legendData.gradientSettings : null,
          colorRange: { colorMappingRange: newRange },
          isTiled: legendData.isTiled,
        });
        // setTextFieldRange(newRange);
      }
    },
    [legendData],
  );

  /*   const handleChangeMin = (event) => {
    setTextFieldRange((previous) => [event.target.value, previous.length === 2 ? previous[1] : undefined]);
  };

  const handleChangeMax = (event) => {
    setTextFieldRange((previous) => [previous.length > 0 ? previous[0] : undefined, event.target.value]);
  };

  const getDisabledStatus = useCallback(
    () => {
      if (textFieldRange.length > 1) {
        const min = Number(textFieldRange[0]);
        const max = Number(textFieldRange[1]);
        if (isNumber(min) && isFinite(min) && isNumber(max) && isFinite(max)) {
          if (min < max) {
            return false;
          }
        }
      }
      return true;
    },
    [textFieldRange],
  ); */
  if (!legendItems || legendItems.length === 0) {
    return null;
  }

  return (
    <div css={containerStyle}>
      <Typography css={ellipsisStyle} variant={'body2'}>
        <b>{layerName}</b>
      </Typography>
      <Typography variant={'body2'}>
        <b>{translateWithPrefix('PROP', legendData.title)}</b>
      </Typography>
      {/*   <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField onChange={handleChangeMin} style={{ width: 100 }} value={textFieldRange[0]} />
          <TextField onChange={handleChangeMax} style={{ width: 100 }} value={textFieldRange[1]} />
          <Button
            disabled={getDisabledStatus()}
            style={{ width: 100 }}
            onClick={() => handleChangeRange(textFieldRange)}
          />         
        </div> */}
      <div css={sliderLegendContainer(hideSlider)}>
        {!hideSlider && (
          <Slider
            css={sliderStyle}
            orientation="vertical"
            min={fullRange ? fullRange[0] : undefined}
            max={fullRange ? fullRange[1] : undefined}
            value={range}
            onChangeCommitted={(_event, newValue) => {
              handleChangeRange(newValue as Array<number>);
            }}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
        )}
        <div>
          {legendItems.map((item, index) => {
            if (index % nTh !== 0) {
              return null;
            }

            const rgbaStyle = item.rgba ? item.rgba.join(',') : null;

            return (
              <div key={index} css={legendListitemStyle}>
                <div css={legendListitemTextStyle}>{item.annotation}</div>
                <div css={legendListitemColorStyle} style={{ backgroundColor: 'rgba(' + rgbaStyle + ')' }} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MmgViewerColorLegendItem;
