export const variableDetailsPanelPath = '/variable/:variableId';
export const variablesDeletePanelPath = '/variables/delete';
export const variablesOperationPanelPath = '/variable/:variableId/operation';
export const variablesOperationsPanelPath = '/variables/operation';
export const variablesMultipleOperationPanelPath = '/variables/operation/multiple';
export const variablesOperationSelectItemsPanelPath = '/variables/operation/select';

const VariableRoutes = {
  variableDetailsPanel: {
    path: variableDetailsPanelPath,
  },
  variablesDeletePanel: {
    path: variablesDeletePanelPath,
  },
  variablesOperationPanel: {
    path: variablesOperationPanelPath,
  },
  variablesOperationsPanel: {
    path: variablesOperationsPanelPath,
  },
  variablesMultipleOperationPanel: {
    path: variablesMultipleOperationPanelPath,
  },
  variablesOperationSelectItemsPanel: {
    path: variablesOperationSelectItemsPanelPath,
  },
};

export default VariableRoutes