/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { object as yupObject, string as yupString } from 'yup';
import { t } from '../../translations/i18n';
import { store } from '../../store';
import { FeatureCollection } from 'geojson';
import TextField from '@mui/material/TextField';
import WorkspaceQueryManager from '../../managers/WorkspaceQueryManager';
import { EElementCategories, ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { IParameterDescription } from '../../models/IOperationDescriptions';
import { MmgOperationParameters } from '../../operations/operation-parameters';
import { Formik } from 'formik';
import { getDefaultParameterValues } from '../../shared/parameters/parameter-utils';
import { createValidationSchema } from '../../shared/parameters/parameter-validation-utils';
import { FeatCollAny } from '../../models/IGeometryUtils';
import { Divider, Typography } from '@mui/material';
import { useIsMounted } from '../../shared/hooks/hooks';
import { MmgConnectedGeometrySelectList } from '../../geometries/select-list/geometry-select-list';
import { useSelector } from 'react-redux';
import WorkspaceGeometrySelectors from '../../store/selectors/WorkspaceGeometrySelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgGroup } from '../../shared/groups/group';
import { ConfirmHeaderStyle } from '../../shared/confirm-containers/confirm-styles';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { showLayer } from '../../shared/layers/layer-utils';
import { IWorkspaceEnrichedGeometry } from '../../models/IGeometries';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import MikeVisualizer from '../../MikeVisualizer';
import { IGlobalState } from '../../store/reducers';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import { SPATIAL_SELECTION_CUSTOM_SETTINGS } from './spatial-selection-custom-settings';

const {
  onDrawnDataUpdated,
  onDrawingInProgressChanged,
  getCurrentlyDrawnGeojson,
  clearDrawnVectorLayerData,
} = MikeVisualizer;

type SpatialSelectionConfirmProps = {
  workspaceId: string;
  targetItemId: string;
  elementCategory: EElementCategories;
  successMessage: string;
  errorMessage: string;
  parameters?: { [key: string]: number | boolean | string | object };
  parameterDescriptions?: { [parameterKey: string]: IParameterDescription };
  onSelectionCancelled: () => void;
};

const InputCss = css`
  padding: ${mikeSharedTheme.spacing(1)};
`;

const ALLOWED_SELECTION_GEOMETRY_TYPES = ['MultiPolygon', 'Polygon'];

/**
 * @name MmgSpatialSelectionConfirm Allows submitting (confirming) a generic spatial selection.
 * @summary Grabs the drawn polygon and submits it as a selection (query).
 * It is meant to be a base component for i.e. GeometryConfirmSpatialSelection or MeshConfirmSpatialSelection.
 * This component shows & allows editing selection metadata.
 *
 * @param props
 */
export function MmgSpatialSelectionConfirm(props: SpatialSelectionConfirmProps) {
  const geometries = useSelector(WorkspaceGeometrySelectors.getSortedEnrichedWorkspaceGeometries);
  const hiddenWorkspaceGeometries = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries,
  );
  const selectedWorkspaceGeometries = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer.selectedWorkspaceGeometries,
  );
  const drawnWorkspaceGeometries: IDrawnDataItem[] = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );

  const [spatialSelectionFeatureCollection, setSpatialSelectionFeatureCollection] = useState({} as FeatCollAny);
  const [spatialSelectionName, setSpatialSelectionName] = useState('');
  const [createSelectionFailed, setCreateSelectionFailed] = useState(false);
  const [createSelectionWorking, setCreateSelectionWorking] = useState(false);
  const [drawingInProgress, setDrawingInProgress] = useState(false);
  const isMounted = useIsMounted();

  const {
    workspaceId,
    targetItemId,
    elementCategory,
    successMessage,
    errorMessage,
    parameters,
    parameterDescriptions,
  } = props;

  const allowedGeometries = useMemo(
    () => {
      if (geometries && geometries.length > 0 && targetItemId) {
        const allowed = geometries.filter(
          (geometry: IWorkspaceEnrichedGeometry) =>
            targetItemId !== geometry.id && ALLOWED_SELECTION_GEOMETRY_TYPES.includes(geometry.itemType),
        );
        return allowed;
      }
      return [];
    },
    [geometries, targetItemId],
  );

  const [dirtyParameters, setDirtyParameters] = useState(parameters);

  const handleNameChange = (event) => {
    const newVal = event.target.value;
    setSpatialSelectionName(newVal);
  };

  const onParameterChanged = useCallback(
    (param: string, val: string | number | boolean | object) => {
      const nextParameters = {
        ...dirtyParameters,
        [param]: val,
      };
      setDirtyParameters(nextParameters);
    },
    [dirtyParameters],
  );
  /**
   * Callback for when the drawn selection has updated.
   * Updates the local reference to the feature collection so it can be eventually sent to the API.
   *
   * @param featureCollection
   */
  function selectionUpdateCallback(featureCollection: FeatureCollection<any, any>) {
    setSpatialSelectionFeatureCollection(featureCollection);
  }

  /**
   * Clears spatial selection.
   * It will remove drawn data & trigger the cancelled callback prop.
   */
  function clearSpatialSelection() {
    clearDrawnVectorLayerData();
    props.onSelectionCancelled();
  }

  /**
   * Submits a spatial selection to the API.
   */
  //check for the highlight task here
  function createSpatialSelection() {
    setCreateSelectionWorking(true);
    setCreateSelectionFailed(false);

    return WorkspaceQueryManager.createSpatialQuery(
      workspaceId,
      [targetItemId],
      spatialSelectionName,
      selectedWorkspaceGeometries && selectedWorkspaceGeometries.length === 1
        ? undefined
        : spatialSelectionFeatureCollection,
      elementCategory,
      dirtyParameters,
      selectedWorkspaceGeometries && selectedWorkspaceGeometries.length === 1
        ? selectedWorkspaceGeometries[0]
        : undefined,
    )
      .then(() => {
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast: { text: successMessage } });
        clearSpatialSelection();
        isMounted() && setSpatialSelectionFeatureCollection({} as FeatCollAny);
      })
      .catch((error) => {
        store.dispatch({
          type: 'toast/ADD/ERROR',
          toast: { text: error && error.response && error.response.data ? error.response.data : errorMessage },
        });
        isMounted() && setCreateSelectionFailed(true);
        throw error;
      })
      .finally(() => {
        isMounted() && setCreateSelectionWorking(false);
      });
  }

  /**
   * Callback for when drawing in progress changes.
   *
   * @param inProgress
   */
  function drawingProgressChangedCallback(inProgress: boolean) {
    setDrawingInProgress(inProgress);
  }

  const validationSchema = useMemo(
    () => {
      const nameSchema = yupObject().shape({
        name: yupString().label(t('SPATIAL_SELECTION_NAME')),
      });
      if (parameterDescriptions){
        const parameterSchema = createValidationSchema(parameterDescriptions);      
        return nameSchema.concat(parameterSchema);
      }
      else{
        return nameSchema;
      }
      
    },
    [parameterDescriptions],
  );

  useEffect(() => {
    const unsubscribers = [];
    const unsubscribeOnDrawnDataUpdated = onDrawnDataUpdated(selectionUpdateCallback);
    const unsubscribeOnDrawingInProgressChanged = onDrawingInProgressChanged(drawingProgressChangedCallback);
    setSpatialSelectionFeatureCollection(getCurrentlyDrawnGeojson());
    unsubscribers.push(unsubscribeOnDrawingInProgressChanged, unsubscribeOnDrawnDataUpdated);
    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  const initialValues = useMemo(
    () => {
      return {
        name: undefined,
        ...getDefaultParameterValues(parameterDescriptions),
      };
    },
    [parameterDescriptions],
  );

  const enableButton = useMemo(
    () => {
      if (createSelectionWorking || drawingInProgress) {
        return false;
      }
      if (selectedWorkspaceGeometries && selectedWorkspaceGeometries.length === 1) {
        return true;
      }
      if (
        spatialSelectionFeatureCollection &&
        spatialSelectionFeatureCollection.features &&
        spatialSelectionFeatureCollection.features.length > 0
      ) {
        return true;
      }
      return false;
    },
    [createSelectionWorking, drawingInProgress, selectedWorkspaceGeometries, spatialSelectionFeatureCollection],
  );

  const onLayerSelectChanged = (layerId: string, selected: boolean) => {
    if (selected && layerId) {
      clearDrawnVectorLayerData();
      const { getState } = MikeVisualizer;
      const { hiddenElementIds } = getState();
      hiddenElementIds.includes(layerId) && showLayer(ELEMENT_CATEGORIES.GEOMETRY, layerId, false);
    }
  };

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <header css={ConfirmHeaderStyle}>
          <h4>{t('SPATIAL_SELECTION_CONFIRMATION_TITLE')}</h4>
        </header>
        <MmgPanelSubsection>
          <Typography variant="h4">{t('DRAW_OR_SELECT_POLYGON')}</Typography>
          {t('GEOMETRY_SELECTION_DRAW_TOOLTIP')}
        </MmgPanelSubsection>
        <MikeStickyPanelContent>
          <MmgGroup groupName={t('GEOMETRY', 2)}>
            {allowedGeometries && (
              <MmgConnectedGeometrySelectList
                geometries={allowedGeometries}
                hiddenGeometries={hiddenWorkspaceGeometries}
                drawnGeometries={drawnWorkspaceGeometries}
                selectedGeometries={selectedWorkspaceGeometries}
                onLayerSelectChanged={onLayerSelectChanged}
                unselectOthers={true}
              />
            )}
          </MmgGroup>
          <MmgGroup groupName={'Options'}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}}>
              <>
                <MmgOperationParameters
                  parameters={dirtyParameters}
                  parameterDescriptions={parameterDescriptions}
                  onParameterChanged={onParameterChanged}
                  parameterSettings={SPATIAL_SELECTION_CUSTOM_SETTINGS}
                />
                <Divider />
                <div css={InputCss}>
                  <TextField
                    id="name"
                    label={`${t('SPATIAL_SELECTION_NAME')} (${t('OPTIONAL')})`}
                    value={spatialSelectionName}
                    onChange={handleNameChange}
                    autoComplete="off"
                    fullWidth={true}
                  />
                </div>
              </>
            </Formik>
          </MmgGroup>
        </MikeStickyPanelContent>
        <MikeStickyPanelBottomActions>
          <MikeButton color="secondary" variant="outlined" onClick={clearSpatialSelection} disabled={createSelectionWorking}>
            {t('CANCEL')}
          </MikeButton>
          <MikeButton variant="contained" color="secondary" disabled={!enableButton} onClick={createSpatialSelection} active={createSelectionWorking}>
            {createSelectionFailed ? t('RETRY_SPATIAL_SELECTION') : t('CONFIRM_SPATIAL_SELECTION')}
          </MikeButton>
        </MikeStickyPanelBottomActions>
      </MikeStickyPanelHeaderContainer>
    </MikeStickyPanel>
  );
}
