import Delete from '../icons/Delete.svg?react';
import { ILayerAction } from '../shared/layers/actions/layer-default-actions';
import { t } from '../translations/i18n';
import { useParams } from 'react-router';
import { MikeLayerActionMenu } from '../shared/layers/actions/layer-action-menu';
import WorkspaceCommentsManager from '../managers/WorkspaceCommentsManager';
import { store } from '../store/store';

type CommentActionsProps = {
  commentId: string;
};

/**
 * @name MmgCommentActions
 * @summary Actions for comments.
 *
 * @param props
 */
export function MmgCommentActions(props: CommentActionsProps) {
  const { commentId } = props;
  const { workspaceId } = useParams();
  const commentActions = [];
  const deleteCommentAction = getDeleteCommentAction(workspaceId, commentId);
  commentActions.push(deleteCommentAction);

  return (
    <>
      <MikeLayerActionMenu layerId={commentId} layerActions={commentActions} disabled={false} />
    </>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const getDeleteCommentAction = (workspaceId: string, commentId: string, disabled?: boolean): ILayerAction => {
  const onDeleteComment = () => {
    return WorkspaceCommentsManager.deleteComment(workspaceId, commentId)
      .then(() => {
        const toast = {
          text: t('DELETED', 1, {
            thing: t('COMMENT'),
          }),
        };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('DELETE') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  return {
    actionId: 'DELETE',
    actionDisplayName: t('DELETE'),
    actionIcon: <Delete />,
    onAction: onDeleteComment,
    disabled,
  };
};
