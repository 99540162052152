/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import Info from '../../icons/Info.svg?react';
import { Tooltip } from '@mui/material';

type ParameterInfoWrapperProps = {
  infoText?: string;
  children?: React.ReactNode;
  cssProp?: any;
  tooltipCss? : any;
};

export const ParameterInfoCss = css`
  margin-left: auto;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const ParameterInfoWrapperCss = css`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

/**
 * Styling of info wrapper when used together with input fields (including selects)
 */
export const InputFieldInfoWrapperCss = css`
  align-items: flex-start;
`;

export const InputFieldInfoTooltipCss = css`
  height: 48px;
  margin-top: 8px;
  margin-bottom: 4px;
`;



export const MmgParameterInfoWrapper = (props: ParameterInfoWrapperProps) => {
  const { infoText, children, cssProp, tooltipCss } = props;

  if (!infoText) {
    return <>{children}</>;
  }

  return (
    <div css={css`${ParameterInfoWrapperCss}; ${cssProp && cssProp};`}>
      {children}
      <Tooltip title={infoText} css={css`${ParameterInfoCss} ${tooltipCss && tooltipCss};`}>       
        <span>
          <Info />
        </span>      
      </Tooltip>
    </div>
  );
};
