import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import MeshOperationsManager from '../../managers/MeshOperationsManager';
import LayerUtils from '../../shared/layers/layer-utils';
import { IOperationConfiguration, IOperationDefinition } from '../../models/IOperations';
import { DENSITY_FUNCTION_PARAMETERS, IOperationDescription } from '../../models/IOperationDescriptions';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { IWorkspaceGeometry } from '../../models/IGeometries';
import { getIds } from '../../store/selectors/WorkspaceDataItemUtils';
import { IWorkspaceQuery } from '../../models/IQueries';
import { createOperationConfigurationFromDescription } from '../../operations/operation-utils';
import { EOperationActionType } from '../../store/actions/OperationActionType';

const { hideOtherMeshes } = LayerUtils;

/**
 * Submits a create mesh operation, saving or generating the mesh.
 *
 * @param workspaceId
 * @param meshId
 * @param meshConfiguration
 * @param generateMesh
 */
export const submitCreateMeshOperation = (
  workspaceId: string,
  meshId: string,
  meshConfiguration: IOperationConfiguration,
  generateMesh: boolean,
): Promise<IOperationConfiguration> => {
  let createMeshPromise;

  if (generateMesh) {
    createMeshPromise = MeshOperationsManager.executeCreateMeshOperation(workspaceId, meshConfiguration);
  } else {
    createMeshPromise = MeshOperationsManager.saveCreateMeshOperation(workspaceId, meshConfiguration);
  }

  return createMeshPromise
    .then((operation: IOperationConfiguration) => {
      // We want only the newly generated mesh to be visible in viewer.
      // We update the workspace settings to ensure the new mesh to be the only visible mesh when going back to the workspace list.
      const updateUserSettings = true;
      hideOtherMeshes(meshId, updateUserSettings);

      const toast = {
        text: t('MESH_SETTINGS_SAVED'),
      };
      !generateMesh && store.dispatch({ type: 'toast/ADD/SUCCESS', toast });

      store.dispatch({
        type: EOperationActionType.CREATE_OR_UPDATE,
        operationMetadata: operation,
      });

      return operation;
    })
    .catch((error) => {
      const toast = {
        text: generateMesh ? t('MESH_SUBMITTING_FAILED') : t('MESH_SETTINGS_SAVE_FAILED'),
        operationId: error.operationId,
      };

      store.dispatch({ type: 'toast/ADD/ERROR', toast });
      throw error;
    });
};

/**
 * Creates a new mesh child operation configuration based on the operation description
 * @param operationKey
 * @param inputIds Ids of the geometries or queries being input of the operation
 * @param operationDescriptions
 */
export const createMeshChildConfigurationFromDescription = (
  operationKey: string,
  inputIds: Array<string>,
  operationDescriptions: {
    [operationKey: string]: IOperationDescription;
  },
): IOperationConfiguration => {
  if (!operationKey || !operationDescriptions) {
    return null;
  }

  const operationDescriptionForKey = operationDescriptions[operationKey];

  if (!operationDescriptionForKey) {
    return null;
  }

  const config = createOperationConfigurationFromDescription(operationDescriptionForKey);
  const configuration = { ...config, inputIds };

  return configuration;
};

/**
 * Returns value strings (variable name) for any variable parameters of the operation configuration
 * @param operationConfiguration
 * @param workspaceVariables
 */
export const getVariableParametersValueStrings = (
  operationConfiguration: IOperationDefinition,
  workspaceVariables: Array<IWorkspaceEnrichedVariable>,
): { [parameterKey: string]: string } => {
  if (!operationConfiguration) {
    return null;
  }
  const { parameters } = operationConfiguration;

  if (!parameters) {
    return null;
  }

  const variableParameter = parameters[DENSITY_FUNCTION_PARAMETERS.VARIABLE_ID];

  if (!variableParameter) {
    return null;
  }

  const variable = workspaceVariables.find(({ id }) => id === variableParameter);

  if (!variable) {
    return null;
  }

  return {
    [DENSITY_FUNCTION_PARAMETERS.VARIABLE_ID]: variable.name,
  };
};

/**
 * Will leave out operations having an input geometry that is no longer selected or a query that does not relate to a selected geometry.
 * Will also leave out operations having an input being deleted from the workspace - possibly by some other user.
 * @param meshOperationConfiguration
 * @param selectedGeometryIds
 * @param workspaceGeometries
 * @param workspaceQueries Holds all saved selections in the workspace (for meshes and geometries)
 */
export const getFilteredCreateMeshChildOperations = (
  meshOperationConfiguration: IOperationConfiguration,
  selectedGeometryIds: Array<string>,
  workspaceGeometries: Array<IWorkspaceGeometry>,
  workspaceQueries: Array<IWorkspaceQuery>,
) => {
  const { childOperations } = meshOperationConfiguration || {};

  // Do not include any selected ids no longer included in the workspace geometries.
  const allGeometryIds = getIds(workspaceGeometries) || [];
  const geometryIds = selectedGeometryIds.filter((id) => allGeometryIds.indexOf(id) !== -1);

  // Only include query ids related to selected geometries. And do not include query ids no longer included in the workspace queries.
  const queryIds =
    getIds(
      workspaceQueries.filter(({ parentItemId }) => {
        return geometryIds.indexOf(parentItemId) !== -1;
      }),
    ) || [];

  const filteredChildOperations = (childOperations || []).filter(
    // we know that there is currently only one geometry or query per child operation.
    ({ inputIds }) => (inputIds && geometryIds.indexOf(inputIds[0]) !== -1) || queryIds.indexOf(inputIds[0]) !== -1,
  );

  return filteredChildOperations;
};

const self = {
  submitCreateMeshOperation,
  getVariableParametersValueStrings,
  getFilteredCreateMeshChildOperations,
};

export default self;
