import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { t } from '../../translations/i18n';
import OperationsManager from '../../managers/OperationsManager';
import { store } from '../../store/store';
import { IOperationConfiguration } from '../../models/IOperations';
import { getInterpolationOperationName } from './mesh-interpolation-util';
import MikeButton from '../../shared-components/mike-button';

type ConfirmDeleteMeshInterpolationProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
  interpolation: IOperationConfiguration;
  open: boolean;
  onInterpolationDeleted: (operationId: string) => void;
  onClose: (confirmed: boolean) => void;
};

/**
 * Shows a confimation dialog for deleting a mesh interpolation and deletes the interpolation operation if user confirms
 * @param props
 *
 */
export const MmgConfirmDeleteMeshInterpolationDialog = (props: ConfirmDeleteMeshInterpolationProps) => {
  const { open, onClose, interpolation, workspaceId, onInterpolationDeleted: onInterpolationDeletedProp } = props;

  if (!interpolation) {
    return;
  }

  const operationId = interpolation.id;
  const name = getInterpolationOperationName(interpolation);

  const handleClose = () => {
    onClose(false);
  };

  const handleOk = () => {
    onOperationDelete();
    closeDialog(true);
  };

  const handleCancel = () => {
    closeDialog(false);
  };

  const closeDialog = (confirmed: boolean) => {
    onClose(confirmed);
  };

  const onOperationDelete = () => {
    return OperationsManager.deleteOperation(workspaceId, operationId)
      .then(() => {
        const toast = {
          text: t('DELETED', 1, {
            thing: t('MESH_INTERPOLATION'),
          }),
        };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });

        if (onInterpolationDeletedProp) {
          onInterpolationDeletedProp(operationId);
        }
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('DELETE') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      id="confirm-delete-interpolation"
      aria-labelledby="delete-interpolation-dialog-title"
      aria-describedby="delete-interpolation-dialog-description"
    >
      <DialogTitle id="delete-interpolation-dialog-title">
        {t('MESH_INTERPOLATION_CONFIRM_DELETE_TITLE')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-interpolation-dialog-description">
          {t('MESH_INTERPOLATION_CONFIRM_DELETE_TEXT', 1, {
            name,
          })}{' '}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MikeButton onClick={handleCancel} variant="outlined" color="secondary">
          {t('CANCEL')}
        </MikeButton>
        <MikeButton onClick={handleOk} variant="contained" color="secondary" autoFocus>
          {t('DELETE')}
        </MikeButton>
      </DialogActions>
    </Dialog>
  );
};
