import { useEffect, useRef } from 'react';
import MikeVisualizer from '../../MikeVisualizer/lib/MikeVisualizer';
import { EViewerModes, VIEWER_MODES } from '../../MikeVisualizer/lib/IMikeVisualizerModels';
import { useMount } from 'react-use';
import { t } from '../../translations/i18n';
import { MAX_WIDGET_SIZE } from '../viewer/tools/tools-constants';
const { enableMikeOrientationMarkerWidget } = MikeVisualizer;

const CORNER = 'TOP_LEFT';
const ALT_CORNER = 'BOTTOM_LEFT';
const T1 = 50;

const enableWidget = (maxWidgetSize: number) => {
  return enableMikeOrientationMarkerWidget({
    corner: CORNER as any,
    // pixelSize: maxWidgetSize, // static size
    pixelSize: { min: 80, max: maxWidgetSize }, // dynamic size
    viewPortSize: 0.1, // dynamic size
    // defaultEnabled: false, // TODO: joel; Use this option after update shared
    defaultStyle: {
      fontSizeScale: (res) => res / 4,
    },
    // East
    xPlusFaceProperty: {
      faceRotation: 90,
    },
    // West
    xMinusFaceProperty: {
      faceRotation: 270,
    },
    // North
    yPlusFaceProperty: {
      faceRotation: 180,
    },
    zPlusFaceProperty: {
      text: t('TOOL_3D_WIDGET_ZPLUS'),
    },
    zMinusFaceProperty: {
      text: t('TOOL_3D_WIDGET_ZMINUS'),
      faceRotation: 180,
    },
  });
};

/**
 * Add cube widget to viewer and switch in on/off for 3D/2D view recpectively
 * @param args
 * @param args.viewerMode
 * @param args.resetViewCount
 */
export const useCubeWidget = ({ viewerMode, resetViewCount }: { viewerMode: EViewerModes; resetViewCount: number }) => {
  const widgetHandlerRef = useRef<ReturnType<typeof enableWidget>>(null);
  useMount(() => {
    widgetHandlerRef.current = enableWidget(MAX_WIDGET_SIZE);
  });

  useEffect(
    () => {
      switch (viewerMode) {
        case VIEWER_MODES.TWO_D:
          { if (!widgetHandlerRef.current) {
            console.warn(`No widget handler`);
            break;
          }
          const disable = widgetHandlerRef.current.disable;
          disable && disable();
          // Hacky attempt to fix bug 37472; Force VTK.js to re-render & disable after timeout without the cube in 2D:
          try {
            const w = widgetHandlerRef.current.instance;
            if (!w.isDeleted) {
              w.setViewportCorner(ALT_CORNER);
              w.updateViewport();
              disable && disable();
            }
            setTimeout(() => {
              if (!w.isDeleted) {
                w.setViewportCorner(CORNER);
                w.updateViewport();
                disable && disable();
                setTimeout(() => {
                  disable && disable();
                }, T1);
              }
            }, T1);
          } catch (error) {
            console.warn(`Widget problem:`, error);
          }
          break; }

        case VIEWER_MODES.THREE_D:
          if (!widgetHandlerRef.current) {
            console.warn(`No widget handler`);
            break;
          }
          widgetHandlerRef.current.enable();
          break;

        default:
          console.info(`default`);
          break;
      }
    },
    [viewerMode],
  ); // useEffect

  // TODO: joel; Remove this bug fix again if fixed within mike-shared.
  // Update widget when 3D view is reset:
  useEffect(
    () => {
      if (!widgetHandlerRef.current) {
        console.warn(`No widget handler`);
        return;
      }
      const w = widgetHandlerRef.current.instance;
      if (!w) {
        console.warn(`No widget instance`);
        return;
      }
      w.updateMarkerOrientation();
      w.updateViewport();
    },
    [resetViewCount],
  );
};
