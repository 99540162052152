import { useState } from 'react';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { t } from '../../translations/i18n';
import { MmgPanelTopActions } from '../../shared/panels/panel-top-actions';
import { MmgConfirmGenerateMeshDialog } from './mesh-generate-dialog';
import LayerUtils from '../../shared/layers/layer-utils';
import { onMeshGenerate } from './mesh-details-util';
import MikeButton from '../../shared-components/mike-button';

const { isMeshGenerateAvailable } = LayerUtils;

type MmgMeshGenerateSubSectionProps = {
  projectId: string;
  workspaceId: string;
  mesh: IWorkspaceEnrichedMesh;
};

/**
 * A subsection with quick access to generate/re-generate a mesh
 *
 * @param props
 *
 */
export const MmgMeshGenerateSubSection = (props: MmgMeshGenerateSubSectionProps) => {
  const { projectId, workspaceId, mesh } = props;

  const [generateConfirmOpen, setGenerateConfirmOpen] = useState(false);

  const isGenerateAvailable = isMeshGenerateAvailable(mesh);

  const onGeneratePress = () => {
    return onMeshGenerate(projectId, workspaceId, mesh.id);
  };

  if (!mesh || !isGenerateAvailable) {
    return <></>;
  }

  return (
    <>
      <MmgConfirmGenerateMeshDialog
        open={generateConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        mesh={mesh}
        onClose={() => setGenerateConfirmOpen(false)}
      />

      <MmgPanelTopActions>
        <MikeButton variant="contained" fullWidth onClick={onGeneratePress}>
          {t('GENERATE_MESH')}
        </MikeButton>
      </MmgPanelTopActions>
    </>
  );
};
