import React, { useCallback } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { MinStringLengthToShowTooltipOnSelectLayer } from '../styles/styleConsts';
import { ILayerProps, MikeLayer } from '../../shared-components/mike-layer/layer';
import mikeSharedTheme from '../styles/mikeSharedTheme';

export interface LayerSelectProps extends ILayerProps {
  selected?: boolean;
  disabled?: boolean;
  onLayerSelectChanged?: (layerId: string, selected: boolean) => any;
}

/**
 * @name MikeLayerSelect
 * @summary A wrapper / extensions of MikeLayer, implementing checkbox selection.
 *
 * @param props
 */
export const MikeLayerSelect = (props: LayerSelectProps) => {
  const { leftIcon, selected, disabled, onLayerSelectChanged: onLayerSelectChangedProps = () => null } = props;

  const checkboxStyle = leftIcon
    ? {
        marginRight:
          mikeSharedTheme.spacing(-1) /* Cancels out icon margin. TODO dan: should use theme variables in shared and then update this component (spacing) */,
      }
    : {};

  const onLayerSelectChanged = useCallback(
    (layerId: string, isSelected: boolean) => {
      if (!disabled) {
        onLayerSelectChangedProps(layerId, isSelected);
      }
    },
    [disabled, onLayerSelectChangedProps],
  );

  const onLayerCheckboxStateChanged = useCallback(
    (layerId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        onLayerSelectChanged(layerId, event.target.checked);
      }
    },
    [disabled, onLayerSelectChanged],
  );

  const onLayerNamePressed = useCallback(
    (layerId: string) => () => {
      if (selected) {
        return onLayerSelectChanged(layerId, false);
      }

      return onLayerSelectChanged(layerId, true);
    },
    [selected, onLayerSelectChanged],
  );

  const showTooltip = (layerName: string | React.ReactNode) => {
    if (typeof layerName === 'string') {
      const name: string = layerName;
      return name.length > MinStringLengthToShowTooltipOnSelectLayer;
    } else {
      return false;
    }
  };

  return (
    <MikeLayer
      onLayerNamePressed={onLayerNamePressed(props.layerId)}
      leftActions={
        <Checkbox
          disabled={disabled}
          checked={selected}
          onChange={onLayerCheckboxStateChanged(props.layerId)}
          style={checkboxStyle}
        />
      }
      showTooltip={showTooltip(props.layerName)}
      {...props}
    />
  );
};
