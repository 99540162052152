/** @jsxImportSource @emotion/react */
import { IParameterDescription, PARAMETER_VALUE_TYPES } from '../../models/IOperationDescriptions';
import { translateWithPrefixAndSuffix } from '../../translations/utils';
import { MmgParameterInputNumber } from './parameter-input-number';
import { MmgParameterInputBoolean } from './parameter-input-boolean';
import { MmgParameterInputSelect } from './parameter-input-select';
import { MmgParameterInputString } from './parameter-input-string';
import { MmgParameterInputPercent } from './parameter-input-percent';
import {
  getValueTypeByValue,
  getParamValueString,
  getParamLabel,
  isPercentageType,
  isGeometrySelectType,
  isMeshSelectType,
  isVariableSelectType,
} from '../../shared/parameters/parameter-utils';
import { IParameterSettings } from '../../models/IParameterSettings';
import { useField } from 'formik';
import { t } from '../../translations/i18n';
import { MmgConnectedParameterGeometrySelect } from './parameter-geometry-select';
import { MmgConnectedParameterMeshSelect } from './parameter-mesh-select';
import { MmgConnectedParameterVariableSelect } from './parameter-variable-select';
import { lowerFirst } from 'lodash-es';
import { IOperationConfiguration } from '../../models/IOperations';

type ParameterInputProps = {
  parameterKey: string;
  parameterDescription: IParameterDescription;
  value: number | boolean | string | object;
  name?: string; // to be used as name attribute of input element. If not provided, the parameterKey will be used.
  customParameterSettings?: { [param: string]: IParameterSettings };
  onParameterChanged?: (param: string, val: number | boolean | string | object) => void;
  dirtyOperationConfiguration?: IOperationConfiguration;
  dirtyParentConfiguration?: IOperationConfiguration;
};

/**
 * @name MmgParameterInput
 * @param props
 * @summary A generic element that renders a parameter based on the parameterDescription and accepts a callback for when they change.
 * If parameterDescription contains the 'values' property a select input will be rendered.
 */
export const MmgParameterInput = (props: ParameterInputProps) => {
  const {
    parameterKey,
    value,
    parameterDescription,
    name,
    customParameterSettings,
    onParameterChanged,
    dirtyOperationConfiguration,
    dirtyParentConfiguration,
  } = props;
  const PARAM_PREFIX = 'PARAM';
  const [field, meta] = useField({
    name: name || parameterKey,
    value: value as string | number | string[],
  });

  const onChanged = (param: string, val: number | boolean | string | object) => {
    if (onParameterChanged) {
      onParameterChanged(param, val);
    }
  };

  if (!parameterKey) {
    return <></>;
  }

  // Check for conditional parameters; only render input if condition is met;
  // fail safely by rendering all if conditional input isn't found:
  const {
    conditionalParameterNameKey,
    conditonalParameterValue,
    conditionalParameterNameKey2,
    conditonalParameterValue2,
  } = parameterDescription;
  if (conditionalParameterNameKey && conditionalParameterNameKey2) {
    const condParamInputValue = dirtyOperationConfiguration
      ? dirtyOperationConfiguration.parameters[lowerFirst(conditionalParameterNameKey)]
      : undefined;
    const condParamInputValue2 = dirtyOperationConfiguration
      ? dirtyOperationConfiguration.parameters[lowerFirst(conditionalParameterNameKey2)]
      : undefined;
    if (condParamInputValue !== undefined && String(condParamInputValue) !== conditonalParameterValue) {
      return null;
    }
    if (condParamInputValue2 !== undefined && String(condParamInputValue2) !== conditonalParameterValue2) {
      return null;
    }
    const condParentParamInputValue = dirtyParentConfiguration
      ? dirtyParentConfiguration.parameters[lowerFirst(conditionalParameterNameKey)]
      : undefined;
    const condParentParamInputValue2 = dirtyParentConfiguration
      ? dirtyParentConfiguration.parameters[lowerFirst(conditionalParameterNameKey2)]
      : undefined;
    if (condParentParamInputValue !== undefined && String(condParentParamInputValue) !== conditonalParameterValue) {
      return null;
    }
    if (condParentParamInputValue2 !== undefined && String(condParentParamInputValue2) !== conditonalParameterValue2) {
      return null;
    }
  }

  if (conditionalParameterNameKey) {
    const condParamInputValue = dirtyOperationConfiguration
      ? dirtyOperationConfiguration.parameters[lowerFirst(conditionalParameterNameKey)]
      : undefined;
    if (condParamInputValue !== undefined && String(condParamInputValue) !== conditonalParameterValue) {
      return null;
    }
    const condParentParamInputValue = dirtyParentConfiguration
      ? dirtyParentConfiguration.parameters[lowerFirst(conditionalParameterNameKey)]
      : undefined;
    if (condParentParamInputValue !== undefined && String(condParentParamInputValue) !== conditonalParameterValue) {
      return null;
    }
  }

  const fieldName = field.name || parameterKey;
  const { nameKey, valueType, values, required } = parameterDescription || {};
  const errorText = meta.error && meta.touched ? meta.error : undefined;
  const error = Boolean(errorText);
  const helperText = errorText || (required ? t('REQUIRED_HELPERTEXT') : '');

  // if valueType is not defined, figure it out from data
  let parameterType = valueType;
  if (!parameterType) {
    parameterType = getValueTypeByValue(value);
  }
  const customSettings = customParameterSettings ? customParameterSettings[parameterKey] : null;
  // we check if is preset nameKey parameter on parameterDescription
  const paramKey = nameKey ? nameKey : parameterKey;
  const translatedParam = getParamLabel(parameterKey, nameKey);
  const translatedParamPlaceholder = translateWithPrefixAndSuffix(PARAM_PREFIX, 'PLACEHOLDER', paramKey);
  const translatedParamInfo = translateWithPrefixAndSuffix(PARAM_PREFIX, 'INFO', paramKey, true);

  // If any parameter values, render as a select input independend of value type
  if (values) {
    const getTranslatedValueDisplayName = (val: number | boolean | string | object) => {
      const key = val.toString();
      return getParamValueString(values[key], parameterDescription) as string;
    };
    return (
      <MmgParameterInputSelect
        parameterKey={parameterKey}
        name={fieldName}
        value={value}
        parameterDescription={parameterDescription}
        label={translatedParam}
        placeholder={translatedParamPlaceholder}
        infoText={translatedParamInfo}
        getValueDisplayName={getTranslatedValueDisplayName}
        customSettings={customSettings}
        error={error}
        helperText={helperText}
        onParameterChanged={onChanged}
      />
    );
  }

  if (isPercentageType(parameterDescription)) {
    return (
      <MmgParameterInputPercent
        parameterKey={parameterKey}
        name={fieldName}
        value={value as string | number}
        parameterDescription={parameterDescription}
        label={translatedParam}
        infoText={translatedParamInfo}
        customSettings={customSettings}
        error={error}
        helperText={helperText}
        onParameterChanged={onChanged}
      />
    );
  }

  if (isGeometrySelectType(parameterDescription)) {
    return (
      <MmgConnectedParameterGeometrySelect
        parameterKey={parameterKey}
        name={fieldName}
        value={value as string}
        parameterDescription={parameterDescription}
        label={translatedParam}
        placeholder={translatedParamPlaceholder}
        infoText={translatedParamInfo}
        customSettings={customSettings}
        error={error}
        helperText={helperText}
        onParameterChanged={onChanged}
      />
    );
  }

  if (isMeshSelectType(parameterDescription)) {
    return (
      <MmgConnectedParameterMeshSelect
        parameterKey={parameterKey}
        name={fieldName}
        value={value as string}
        parameterDescription={parameterDescription}
        label={translatedParam}
        placeholder={translatedParamPlaceholder}
        infoText={translatedParamInfo}
        customSettings={customSettings}
        error={error}
        helperText={helperText}
        onParameterChanged={onChanged}
      />
    );
  }

  if (isVariableSelectType(parameterDescription)) {
    return (
      <MmgConnectedParameterVariableSelect
        parameterKey={parameterKey}
        name={fieldName}
        value={value as string}
        parameterDescription={parameterDescription}
        label={translatedParam}
        placeholder={translatedParamPlaceholder}
        infoText={translatedParamInfo}
        customSettings={customSettings}
        error={error}
        helperText={helperText}
        onParameterChanged={onChanged}
      />
    );
  }

  switch (parameterType) {
    case PARAMETER_VALUE_TYPES.INT32:
    case PARAMETER_VALUE_TYPES.INTEGER:
    case PARAMETER_VALUE_TYPES.DOUBLE: {
      return (
        <>
          <MmgParameterInputNumber
            parameterKey={parameterKey}
            name={fieldName}
            value={value as number}
            parameterDescription={parameterDescription}
            label={translatedParam}
            infoText={translatedParamInfo}
            error={error}
            helperText={helperText}
            customSettings={customSettings}
            onParameterChanged={onChanged}
          />
        </>
      );
    }

    case PARAMETER_VALUE_TYPES.BOOLEAN: {
      return (
        <MmgParameterInputBoolean
          param={parameterKey}
          name={fieldName}
          value={value as boolean}
          parameterDescription={parameterDescription}
          label={translatedParam}
          infoText={translatedParamInfo}
          customSettings={customSettings}
          error={error}
          helperText={helperText}
          onParameterChanged={onChanged}
        />
      );
    }

    case PARAMETER_VALUE_TYPES.ENUM: // if no values, fallback to render as input string
    case PARAMETER_VALUE_TYPES.GUID:
    case PARAMETER_VALUE_TYPES.STRING:
    default: {
      return (
        <MmgParameterInputString
          param={parameterKey}
          name={fieldName}
          value={value as string}
          parameterDescription={parameterDescription}
          label={translatedParam}
          infoText={translatedParamInfo}
          placeholder={translatedParamPlaceholder}
          customSettings={customSettings}
          error={error}
          helperText={helperText}
          onParameterChanged={onChanged}
        />
      );
    }
  }
};
