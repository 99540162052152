/**
 * Exposes methods to transform or combine geometry operation requests.
 *
 * @module GeometryOperationsManager
 * @version 2.0.0
 * @requires OperationsProxy
 */
import { HttpErrorHandler } from './http-utils';
import * as OperationsProxy from '../proxies/OperationsProxy';
import OperationsManager from './OperationsManager';

import { IOperationDescriptionsKVP } from '../models/IOperationDescriptions';
import { IOperationConfiguration } from '../models/IOperations';
import { IOperationDescriptionApi } from '../models/IOperationDescriptionsApi';

import OperationMappingUtils from '../managers/operation-mapping-utils';

/**
 * Transforms a request that gets available contextual geometry operation descriptions for the workspace and the given geometries.
 * @param workspaceId
 * @param geometryIds
 */
const getGeometryOperationDescriptions = (workspaceId: string, geometryIds: Array<string>) => {
  return OperationsProxy.getGeometryOperationDescriptions(workspaceId, geometryIds)
    .then((res) => {
      const apIOperationDescriptions = res.data as Array<IOperationDescriptionApi>;

      const operationDescriptions = OperationMappingUtils.mapOperationDescriptions(apIOperationDescriptions);

      return operationDescriptions;
    })
    .catch((error) => HttpErrorHandler('Failed to get geometry operation descriptions.', error)) as Promise<
    IOperationDescriptionsKVP
  >;
};

/**
 * Transforms a request that performs a geometry operation on multiple geometries.
 *
 * @param workspaceId
 * @param geometryOperationConfiguration
 */
const executeGeometriesOperation = (workspaceId: string, geometryOperationConfiguration: IOperationConfiguration) => {
  if (!geometryOperationConfiguration) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  if (!geometryOperationConfiguration.inputIds || !geometryOperationConfiguration.inputIds.length) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  return OperationsManager.executeOperation(workspaceId, geometryOperationConfiguration).catch((error) =>
    HttpErrorHandler('Failed to excute geometryOperation.', error),
  ) as Promise<IOperationConfiguration>;
};

const self = {
  getGeometryOperationDescriptions,
  executeGeometriesOperation,
};

export default self;
