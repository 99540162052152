import React, { useEffect } from 'react';
import WorkspaceManager from '../managers/WorkspaceManager';
import { IProject } from '../models/IProject';
import { MmgWorkspaceCard } from './card/workspace-card';
import { MmgCardPlaceholders } from './card/workspace-card-placeholders';

interface IMmgWorkspaceProps {
  workspaceId: string;
  projectList: Array<IProject>;
}

const MmgWorkspaceOverview: React.FC<IMmgWorkspaceProps> = ({ workspaceId, projectList }) => {
  const [isFetchingOverview, setIsFetchingOverview] = React.useState(false);
  const [workspace, setWorkspace] = React.useState(null);

  useEffect(
    () => {
      const getWorkspaceOverview = async () => {
        try {
          setIsFetchingOverview(true);
          const overview = await WorkspaceManager.getWorkspaceOverview(workspaceId, true);
          setWorkspace(overview);
        } catch (error) {
          console.warn('Failed to load recent workspace overview.', error);
        } finally {
          setIsFetchingOverview(false);
        }
        setIsFetchingOverview(false);
      };

      if (workspaceId) {
        getWorkspaceOverview();
      }
    },
    [workspaceId],
  );

  return isFetchingOverview ? (
    <MmgCardPlaceholders size={1} excludeWrapper={true} />
  ) : workspace ? (
    <MmgWorkspaceCard
      workspace={workspace}
      key={workspace.id}
      project={projectList.find((p) => p.id === workspace.projectId)}
    />
  ) : null;
};

export default MmgWorkspaceOverview;
