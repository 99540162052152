/* eslint-disable no-extra-boolean-cast */
import { useState } from 'react';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import Delete from '../../../icons/Delete.svg?react';
import { getToggleVisibilityLayerAction, ILayerAction } from '../../../shared/layers/actions/layer-default-actions';
import { IDrawnDataItem } from '../../../models/IWorkspaceData';
import { useSelector } from 'react-redux';
import WorkspaceGeometrySelectors from '../../../store/selectors/WorkspaceGeometrySelectors';
import { MmgConfirmDeleteMeshOperationGeometryDialog } from '../configuration/mesh-delete-mesh-operation-geometry-dialog';
import { t } from '../../../translations/i18n';
import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IProject } from '../../../models/IProject';
import { IGlobalState } from '../../../store/reducers';

type MeshOperationGeometrySummaryActionsProps = {
  operationId: string;
  operationConfiguration: IOperationConfiguration;
  inputGeometry: IWorkspaceEnrichedGeometry;
  geometryDrawnData?: IDrawnDataItem;
  hiddenGeometryIds?: Array<string>;
  onOperationDelete: (operationId: string) => void;
  project?: IProject;
};

/**
 * @name MmgConnectedMeshOperationGeometrySummaryActions
 * @summary Layer actions for  mesh child operations for configuring geometries.
 *
 * @param props
 */
export function MmgConnectedMeshOperationGeometrySummaryActions(props: MeshOperationGeometrySummaryActionsProps) {
  const {
    operationId,
    operationConfiguration,
    inputGeometry,
    geometryDrawnData: geometryDrawnDataProp,
    hiddenGeometryIds: hiddenGeometryIdsProp,
    onOperationDelete,
  } = props;

  const getDrawnDataSelectorInstance = WorkspaceGeometrySelectors.makeGetGeometryDrawnData();
  const drawnData = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { geometryId: inputGeometry.id }),
  );
  const geometryDrawnData = geometryDrawnDataProp ? geometryDrawnDataProp : drawnData;

  const hiddenWorkspaceGeometries = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries,
  );
  const hiddenGeometryIds = hiddenGeometryIdsProp ? hiddenGeometryIdsProp : hiddenWorkspaceGeometries;

  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);

  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onChildOperationDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const isMissing = !operationConfiguration;

  const layerActions = getLayerActions(
    operationConfiguration,
    inputGeometry,
    geometryDrawnData,
    hiddenGeometryIds,
    onChildOperationDelete,
    canUpdateWorkspace,
  );

  return (
    <>
      <MikeLayerActionMenu layerId={operationId} layerActions={layerActions} disabled={isMissing} />

      <MmgConfirmDeleteMeshOperationGeometryDialog
        open={deleteConfirmOpen}
        childOperation={operationConfiguration}
        geometry={inputGeometry}
        onClose={() => setDeleteConfirmOpen(false)}
        onOperationDelete={onOperationDelete}
      />
    </>
  );
}

const getLayerActions = (
  operationConfiguration: IOperationConfiguration,
  inputGeometry: IWorkspaceEnrichedGeometry,
  itemDrawnData: IDrawnDataItem,
  hiddenItemIds: Array<string>,
  onOperationDelete: () => void,
  canUpdateWorkspace = true,
) => {
  if (!operationConfiguration) {
    return [];
  }
  const layerActions = [];

  const toggleVisibilityAction = getToggleVisibilityLayerAction(
    inputGeometry.category,
    inputGeometry.id,
    hiddenItemIds,
    false,
    !Boolean(itemDrawnData),
  );
  layerActions.push(toggleVisibilityAction);

  if (canUpdateWorkspace) {
    const deleteLayerAction = getDeleteItemAction(onOperationDelete);
    layerActions.push(deleteLayerAction);
  }

  return layerActions;
};

// eslint-disable-next-line react-refresh/only-export-components
export const getDeleteItemAction = (onItemDelete: () => void, disabled?: boolean): ILayerAction => {
  return {
    actionId: 'REMOVE',
    actionDisplayName: t('DELETE'),
    actionIcon: <Delete />,
    onAction: onItemDelete,
    disabled,
  };
};
