import { IGlobalState } from '../reducers';
import WorkspaceDrawnDataSelectors from './WorkspaceDrawnDataSelectors';

/**
 * Gets the basemap id from the state
 * @param state
 */
export const getViewerBaseMapId = (state: IGlobalState) => state.ViewerModeReducer.viewerBaseMapId;

/**
 * Gets the viewer mode from the state
 * @param state
 */
export const getViewerMode = (state: IGlobalState) => state.ViewerModeReducer.viewerMode;

/**
 * Gets the drawn geometries from the state
 * @param state
 */
export const drawnWorkspaceGeometries = (state: IGlobalState) =>
  WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds(state);

/**
 * Gets the drawn variables from the state
 * @param state
 */
export const drawnWorkspaceVariables = (state: IGlobalState) =>
  WorkspaceDrawnDataSelectors.getDrawnWorkspaceVariablesByIds(state);

/**
 * Gets the drawn meshes from the state
 * @param state
 */
export const drawnWorkspaceMeshes = (state: IGlobalState) =>
  WorkspaceDrawnDataSelectors.getDrawnWorkspaceMeshesByIds(state);
