import SpatialSelection from '../../icons/SpatialSelection.svg?react';
import { IWorkspaceQuery } from '../../models/IQueries';
import { MikeLayerEdit } from '../../shared-components/mike-layer/layer-edit';

type SpatialSelectionProps = {
  selectionName: string;
  selectionId: string;
  onSelectionUpdate: (selection: IWorkspaceQuery) => void;
};

/**
 * @name MmgSpatialSelectionEdit
 * @summary A component that allows editing spatial selection metadata (i.e. name).
 * Meant to be used in a selection list, where users can toggle between edit / view through a context menu.
 *
 * @param props
 */
export function MmgSpatialSelectionEdit(props: SpatialSelectionProps) {
  const { selectionName, selectionId } = props;

  const onSelectionUpdate = ({ name }) => props.onSelectionUpdate({ name, id: selectionId });

  return <MikeLayerEdit layerName={selectionName} leftIcon={<SpatialSelection />} onLayerUpdate={onSelectionUpdate} />;
}
