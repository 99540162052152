import { endpoints, paths } from './config';

const getConfigUrl = () => {
  if (window.location.origin.includes("localhost")){
    return '/config/app.config-dev.json';
  }
  const mode = import.meta.env.MODE;
  switch (mode) {
    case 'development':
      return '/config/app.config-dev.json';
    default:
      return `${endpoints.meshAppService}/${paths.configuration}`;
  }
};

async function fetchConfig() {
  const configPath = getConfigUrl();
  const resp = await fetch(configPath);
  const config = await resp.json();
  return config;
}

export default fetchConfig;
