/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { MmgConnectedLayer } from './mesh-layer';
import { ILayerProps } from '../../shared-components/mike-layer/layer';


const LayerNonHoverStyle = css`
  transition: none;
  border-bottom: 0;
  :hover {
    transition: none;
    background: 0;
    border: 0;
  }
`;

/**
 * @name MmgNonHoverLayer
 * @summary A wrapper / extensions of MmgLayer, removing hover styles.
 *
 * @param props
 */
export const MmgNonHoverLayer = (props: ILayerProps) => {
  const { cssProp } = props;
  return (
    <MmgConnectedLayer {...props} cssProp={css`${cssProp && cssProp} ${LayerNonHoverStyle}`} showVisibilityIcon={false} />
  );
};
