import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgConnectedMeshSelectList } from '../select-list/mesh-select-list';
import { MmgConfirmMeshMultipleDeleteDialog } from './mesh-multiple-delete-dialog';
import WorkspaceMeshesManager from '../../managers/WorkspaceMeshesManager';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { useIsMounted } from '../../shared/hooks/hooks';
import { EWorkspaceGeometryActionType } from '../../store/actions/WorkspaceGeometryActionType';
import { EWorkspaceVariableActionType } from '../../store/actions/WorkspaceVariableActionType';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';

/**
 * @name MmgConnectedMeshMultipleDeletePanel
 * @summary Allows selecting multiple meshes to delete.
 *
 */
export const MmgConnectedMeshMultipleDeletePanel = () => {
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate();
  const { hiddenWorkspaceMeshes, selectedWorkspaceMeshes } = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer,
  );
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceMeshesByIds,
  );
  const meshes: Array<IWorkspaceEnrichedMesh> = useSelector(WorkspaceMeshSelectors.getSortedEnrichedWorkspaceMeshes);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const isMounted = useIsMounted();

  const anyAvailableMeshes = meshes && meshes.length > 0;
  const isSelectionValid = selectedWorkspaceMeshes && selectedWorkspaceMeshes.length > 0;

  const clearSelectedMeshes = () => {
    store.dispatch({ type: 'workspace/meshes/DESELECT_ALL' });
  };

  const onPanelExit = () => {
    window.history.back();
    clearSelectedMeshes();
  };

  const onDeleteConfirmed = () => {
    setDeleteInProgress(true);
    return WorkspaceMeshesManager.deleteMeshes(workspaceId, selectedWorkspaceMeshes)
      .then(() => {
        const toast = {
          text: t('DELETED', 1, { thing: t('MESH', 2) }),
        };
        store.dispatch({ type: 'toast/ADD/FAILED', toast });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
      })
      .catch(() => {
        const meshNames = selectedWorkspaceMeshes
          .map((sid) => {
            const mesh = meshes.find(({ id }) => id === sid);

            if (mesh) {
              return mesh.name;
            }
            return null;
          })
          .filter((mesh) => mesh);

        const toast = {
          text: t('FAILED_TO_DELETE_MESH', meshNames.length, {
            meshNames: meshNames.join(', '),
          }),
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
      })
      .finally(() => {
        isMounted() && setDeleteInProgress(false);
      });
  };

  const onDialogCloseChange = (confirmed: boolean) => {
    if (confirmed) {
      onDeleteConfirmed();
    }

    setShowDeleteDialog(false);
  };

  // Make sure only meshes are shown in the viewer when this component is mounting.
  // Whatever meshes are currently shown, will be kept visibile.
  useEffect(() => {
    store.dispatch({
      type: EWorkspaceGeometryActionType.HIDE_ALL,
    });
    store.dispatch({
      type: EWorkspaceVariableActionType.HIDE_ALL,
    });
  }, []);

  // Clear selected meshes on mount
  useEffect(() => {
    clearSelectedMeshes();
  }, []);

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <MmgPanelHeader panelTitle={t('SELECT_MESHES_TO_DELETE_PANEL')} onPanelExit={onPanelExit} />
        </MikeStickyPanelHeaderContainer>
        <MikeStickyPanelContent>
          <MmgGroup groupName={t('MESH', 2)}>
            {anyAvailableMeshes && (
              <MmgConnectedMeshSelectList
                meshes={meshes}
                disabledMeshes={deleteInProgress ? meshes.map(({ id }) => id) : []}
                hiddenMeshes={hiddenWorkspaceMeshes}
                drawnMeshes={drawnWorkspaceMeshes}
                selectedMeshes={selectedWorkspaceMeshes}
                isFailedSelectable={true}
              />
            )}

            {!anyAvailableMeshes && (
              <MmgPanelSubsection>
                <p>{t('NO_MESH_TIP')}</p>

                <MikeButton variant="outlined" color="secondary" onClick={onPanelExit} fullWidth>
                  {t('GOT_IT')}
                </MikeButton>
              </MmgPanelSubsection>
            )}
          </MmgGroup>
        </MikeStickyPanelContent>
        <MikeStickyPanelBottomActions>
          <MikeButton variant="outlined" color="secondary" onClick={onPanelExit} disabled={deleteInProgress}>
            {t('CANCEL')}
          </MikeButton>

          <MikeButton
            disabled={!isSelectionValid || deleteInProgress}
            active={deleteInProgress}
            onClick={() => setShowDeleteDialog(true)}
          >
            <span css="mesh-button-text">{t('DELETE')}</span>
          </MikeButton>
        </MikeStickyPanelBottomActions>
      </MikeStickyPanel>

      <MmgConfirmMeshMultipleDeleteDialog open={showDeleteDialog} onClose={onDialogCloseChange} />
    </>
  );
};
