/* eslint-disable react-refresh/only-export-components */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo } from 'react';
import { t } from '../../translations/i18n';
import MikeVisualizer from '../../MikeVisualizer/lib/MikeVisualizer';
import ClearSelection from '../../icons/ClearSelection.svg?react';
import Select from '../../icons/Select.svg?react';
import SelectByShape from '../../icons/SelectByShape.svg?react';
import SelectByAttributes from '../../icons/SelectByAttributes.svg?react';
import { Feature, FeatureCollection } from 'geojson';
import { CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { store } from '../../store';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import Tooltip from '@mui/material/Tooltip';

import WorkspaceQuerySelectors from '../../store/selectors/WorkspaceQuerySelectors';
import { IOperationMetadata } from '../../models/IOperations';
import { isLayerFailed, isLayerProcessing, isLayerScheduled } from '../layers/layer-utils';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { PROGRESS_SIZE } from '../../queries/spatial-selections/spatial-selection-layer';
import { EItemType } from '../../models/IOperationDescriptions';
import MIKE_COLORS from '../styles/mike-colors';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import { IGlobalState } from '../../store/reducers';

const { onDrawnDataUpdated, clearDrawnVectorLayerData, getState } = MikeVisualizer;

export const buttonActiveStyle = css`
  background-color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  color: ${MIKE_COLORS.WHITE};
  width: 40px;
  height: 40px;
  & path {
    fill: currentColor;
  }
`;

export const buttonDisabledStyle = css`
  background-color: ${MIKE_COLORS.WHITE};
  color: ${MIKE_COLORS.BRANDBLUE_LIGHT};
  width: 40px;
  height: 40px;
  & path {
    fill: currentColor;
  }
`;

const containerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const toolsStyle = css`
  display: flex;
  align-items: center;
`;

const paddingBottomStyle = css`
  padding-bottom: ${mikeSharedTheme.spacing(1)};
`;

const paddingRightStyle = css`
  padding-right: ${mikeSharedTheme.spacing(1)};
`;

export enum ESelectForEditingModeIds {
  SELECT_BY_POINT = 'SELECT_BY_POINT',
  SELECT_BY_RECTANGLE = 'SELECT_BY_RECTANGLE',
  SELECT_BY_POLYGON = 'SELECT_BY_POLYGON',
}

export const SELECTFOREDITINGMODE_IDS = {
  SELECT_BY_POINT: ESelectForEditingModeIds.SELECT_BY_POINT,
  SELECT_BY_RECTANGLE: ESelectForEditingModeIds.SELECT_BY_RECTANGLE,
  SELECT_BY_POLYGON: ESelectForEditingModeIds.SELECT_BY_POLYGON,
};

interface IProps {
  onSelectForEditingModeChanged: (mode: ESelectForEditingModeIds) => void;
  onSelectionGeometryChanged: (feature: Feature<any>) => void;
  selectionMode: ESelectForEditingModeIds;
  itemType: EItemType;
  disabled?: boolean;
}

/**
 * @param props
 * @name MmgSelectForEditingModes
 * @summary Allows changing node modes (modify / add / delete)
 */
const MmgSelectForEditingModes = (props: IProps) => {
  const { onSelectForEditingModeChanged, onSelectionGeometryChanged, selectionMode, itemType, disabled } = props;

  const { featuresForEditing, loadingFeaturesForEditing, loadingFeaturesForEditingFailed, itemId } = useSelector(
    (state: IGlobalState) => state.EditReducer,
  );

  const nodeNeighbours = useSelector((state: IGlobalState) => state.EditReducer.nodeNeighbours);

  const getQueryOperationSelectorInstance = WorkspaceQuerySelectors.makeGetQueryOperation();

  const latestOperation: IOperationMetadata | null = useSelector((state: IGlobalState) =>
    getQueryOperationSelectorInstance(state, {
      queryId: itemId,
    }),
  );

  const { working, icon, failed } = useMemo(
    () => {
      const isWorking = !latestOperation
        ? loadingFeaturesForEditing
        : isLayerProcessing(latestOperation) || isLayerScheduled(latestOperation);
      const isFailed = !latestOperation ? loadingFeaturesForEditingFailed : isLayerFailed(latestOperation);
      return {
        working: isWorking,
        failed: isFailed,
        icon: isWorking ? (
          <CircularProgress style={{ width: PROGRESS_SIZE, height: PROGRESS_SIZE }} color="secondary" />
        ) : isFailed ? (
          <WarningRoundedIcon style={{ width: 30, height: 30 }} />
        ) : (
          <ClearSelection />
        ),
      };
    },
    [latestOperation, loadingFeaturesForEditing, loadingFeaturesForEditingFailed],
  );

  const selectedFeaturesCount = useMemo(
    () => {
      if (itemType === EItemType.MESH) {
        return nodeNeighbours !== null ? 1 : 0;
      } else {
        return featuresForEditing && featuresForEditing.features ? featuresForEditing.features.length : 0;
      }
    },
    [featuresForEditing, nodeNeighbours, itemType],
  );

  useEffect(
    () => {
      const { dataMap } = getState();
      const drawUpdateCallback = async (newFeatureCollection: FeatureCollection<any, any>) => {
        const features = newFeatureCollection.features;
        if (!features || features.length === 0) {
          return;
        }
        const feature = features[features.length - 1];

        // ToDo: Remove once backend is ready to query mesh nodes via query endpoint
        if (itemType === EItemType.MESH) {
          onSelectionGeometryChanged(feature);
          return;
        }

        if (selectionMode === SELECTFOREDITINGMODE_IDS.SELECT_BY_POINT && dataMap) {
          // Add buffer to extent
          const resolution = dataMap.getView().getResolution(); // map units per pixel.
          const radius = resolution ? resolution * 5 : 1;
          const x = feature.geometry.coordinates[0];
          const y = feature.geometry.coordinates[1];
          const xMin = x - radius;
          const yMin = y - radius;
          const xMax = x + radius;
          const yMax = y + radius;

          const rectangle = {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [[[xMin, yMin], [xMin, yMax], [xMax, yMax], [xMax, yMin], [xMin, yMin]]],
            },
          } as Feature<any>;

          // update2DData({ type: 'FeatureCollection', features: [rectangle] }, 'point_sel', null, null, 1);
          onSelectionGeometryChanged(rectangle);
        } else {
          if (feature.geometry && feature.geometry.type && feature.geometry.type === 'Polygon') {
            onSelectionGeometryChanged(feature);
          }
        }
      };

      const unsubscribers = Array<any>();
      if (selectionMode) {
        const unsubscribeOnDrawnDataUpdated = onDrawnDataUpdated(drawUpdateCallback);
        unsubscribers.push(unsubscribeOnDrawnDataUpdated);
      }
      return () => {
        // delete2DData('point_sel');
        unsubscribers.forEach((unsubscribe) => unsubscribe());
      };
    },
    [itemType, onSelectionGeometryChanged, selectionMode],
  );

  const handleChangeSelectForEditingMode = (mode: ESelectForEditingModeIds) => {
    clearDrawnVectorLayerData();
    onSelectForEditingModeChanged(mode);
  };

  const handleClearEditSelection = useCallback(
    () => {
      store.dispatch({ type: EMapToolActionType.CLEAR_SELECTION_FOR_EDITING });
      store.dispatch({
        type: EMapToolActionType.SET_SELECTION_MODE,
        data:
          itemType && itemType === EItemType.MESH
            ? ESelectForEditingModeIds.SELECT_BY_POINT
            : ESelectForEditingModeIds.SELECT_BY_RECTANGLE,
      });
    },
    [itemType],
  );

  const getSelectedFeaturesText = useCallback(
    () => {
      return selectedFeaturesCount + ' ' + t('SELECTED_FOR_EDITING');
    },
    [selectedFeaturesCount],
  );

  const { loadingLabel, failedLabel } = useMemo(
    () => {
      switch (itemType) {
        case EItemType.VARIABLE: {
          return { failedLabel: t('VARIABLE_EDIT_LOADING_FAILED'), loadingLabel: t('VARIABLE_EDIT_LOADING') };
        }
        case EItemType.GEOMETRY: {
          return { failedLabel: t('GEOMETRY_EDIT_LOADING_FAILED'), loadingLabel: t('GEOMETRY_EDIT_LOADING') };
        }
        case EItemType.MESH: {
          return { failedLabel: t('MESH_EDIT_LOADING_FAILED'), loadingLabel: t('MESH_EDIT_LOADING') };
        }
      }
    },
    [itemType],
  );

  return (
    <div>
      <Typography css={paddingBottomStyle} variant="body2">
        {itemType === EItemType.MESH ? t('SELECT_TOOL_TITLE') : t('SELECT_TOOLS_TITLE')}
      </Typography>
      <div css={containerStyle}>
        <div css={toolsStyle}>
          <Tooltip title={t('TOOLTIP_SELECT_BY_POINT')}>
            <button
              disabled={disabled}
              onClick={() => handleChangeSelectForEditingMode(SELECTFOREDITINGMODE_IDS.SELECT_BY_POINT)}
              css={
                selectionMode === SELECTFOREDITINGMODE_IDS.SELECT_BY_POINT
                  ? buttonActiveStyle
                  : disabled
                    ? buttonDisabledStyle
                    : ''
              }
            >
              <Select />
            </button>
          </Tooltip>
          {itemType !== EItemType.MESH && (
            <Tooltip title={t('TOOLTIP_SELECT_BY_RECTANGLE')}>
              <button
                disabled={disabled}
                onClick={() => handleChangeSelectForEditingMode(SELECTFOREDITINGMODE_IDS.SELECT_BY_RECTANGLE)}
                css={
                  selectionMode === SELECTFOREDITINGMODE_IDS.SELECT_BY_RECTANGLE
                    ? buttonActiveStyle
                    : disabled
                      ? buttonDisabledStyle
                      : ''
                }
              >
                <SelectByShape />
              </button>
            </Tooltip>
          )}
          {itemType !== EItemType.MESH && (
            <Tooltip title={t('TOOLTIP_SELECT_BY_POLYGON')}>
              <button
                disabled={disabled}
                onClick={() => handleChangeSelectForEditingMode(SELECTFOREDITINGMODE_IDS.SELECT_BY_POLYGON)}
                css={
                  selectionMode === SELECTFOREDITINGMODE_IDS.SELECT_BY_POLYGON
                    ? buttonActiveStyle
                    : disabled
                      ? buttonDisabledStyle
                      : ''
                }
              >
                <SelectByAttributes />
              </button>
            </Tooltip>
          )}
        </div>
        {selectedFeaturesCount ? (
          <div css={toolsStyle}>
            <Typography css={paddingRightStyle} variant="body2">
              {getSelectedFeaturesText()}
            </Typography>
            <button
              disabled={selectedFeaturesCount === 0}
              onClick={handleClearEditSelection}
              css={selectedFeaturesCount === 0 ? buttonDisabledStyle : ''}
            >
              <Typography variant="body2">{t('CLEAR_EDIT_SELECTION')}</Typography>
            </button>
          </div>
        ) : failed ? (
          <div css={toolsStyle}>
            <Typography variant="body2">{failedLabel}</Typography>
            {icon}
          </div>
        ) : working ? (
          <div css={toolsStyle}>
            <Typography variant="body2">{loadingLabel}</Typography>
            {icon}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MmgSelectForEditingModes;
