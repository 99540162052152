import { useEffect } from 'react';
import { t } from '../../translations/i18n';
import { store } from '../../store';
import { MmgSpatialSelectionConfirm } from '../../queries/spatial-selections/spatial-selection-confirm';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { IParameterDescription, PARAMETER_VALUE_TYPES } from '../../models/IOperationDescriptions';
import { useMatch } from 'react-router-dom';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';

/**
 * @name MmgConnectedGeometryConfirmSpatialSelection
 * @summary Wraps the generic confirm spatial selection to implement geometry spatial selections.
 *
 */
export const MmgConnectedGeometryConfirmSpatialSelection = () => {
  const geometryMatch = useMatch({
    path: '/project/:id/workspace/:workspaceId/geometry/:geometryId'
  });

  const { workspaceId, geometryId } = geometryMatch && geometryMatch.params;

  // Currently API does not have an endpoint for getting parameterDescriptions for queries
  // For now we hardcode here
  const parameterDescriptions: { [param: string]: IParameterDescription } = {
    extendToVertices: {
      name: 'ExtendToVertices',
      nameKey: 'ExtendToVertices',
      type: 'checkbox',
      valueType: PARAMETER_VALUE_TYPES.BOOLEAN,
      defaultValue: false,
    },
  };
  const parameters = {};
  for (const key in parameterDescriptions) {
    parameters[key] = parameterDescriptions[key].defaultValue;
  }

  /**
   * Cancels the geometry spatial selection, deactivating the tool.
   */
  const cancelGeometrySpatialSelection = () => {
    store.dispatch({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  };

  useEffect(() => {
    store.dispatch({ type: EMapToolActionType.GEOMETRY_ALLOW_SPATIAL_SELECTION });
    return () => {
      // on unmount we reset to default tools
      store.dispatch({ type: EMapToolActionType.RESET_ALLOWED_TOOLS });
    };
  }, []);

  return (
    <MmgSpatialSelectionConfirm
      workspaceId={workspaceId}
      targetItemId={geometryId}
      elementCategory={ELEMENT_CATEGORIES.GEOMETRY}
      successMessage={t('GEOMETRY_SPATIAL_SELECTION_SUBMITTED_SUCCESSFULLY')}
      errorMessage={t('GEOMETRY_SPATIAL_SELECTION_SUBMIT_FAILED')}
      onSelectionCancelled={cancelGeometrySpatialSelection}
      parameters={parameters}
      parameterDescriptions={parameterDescriptions}
    />
  );
};
