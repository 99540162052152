import { TextField } from '@mui/material';
import React from 'react';
import { t } from '../../translations/i18n';
import { EElementCategories } from '../../shared/panels/mesh-panel-constants';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';

type SelectExportItemTypeProps = {
  selectedItemType: EElementCategories;
  disabled?: boolean;
  onItemTypechanged: (itemType: EElementCategories) => void;
};

/**
 * A select dropdown for selecting which type of item (mesh,geometry, variable) to export
 * @param props
 */
export const MmgSelectExportItemType = (props: SelectExportItemTypeProps) => {
  const { selectedItemType, onItemTypechanged, disabled } = props;

  const sortedItemTypes = [EElementCategories.MESH, EElementCategories.GEOMETRY, EElementCategories.VARIABLE];

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventTarget = event.target;

    if (onItemTypechanged) {
      onItemTypechanged((eventTarget.value as unknown) as EElementCategories);
    }
  };

  return (
    <MmgPanelSubsection>
      <TextField
        id={'select-export-item-type'}
        label={t('EXPORT_SELECT_ITEM_TYPE')}
        InputLabelProps={{
          shrink: true,
        }}
        value={selectedItemType || ''}
        onChange={handleOnChange}
        fullWidth
        select
        SelectProps={{
          native: true,
        }}
        disabled={disabled}
      >
        {sortedItemTypes.map((itemType) => {
          return (
            <option key={itemType} value={itemType}>
              {t('ELEMENTCATEGORY_' + itemType, 2)}
            </option>
          );
        })}
      </TextField>
    </MmgPanelSubsection>
  );
};
