import { useCallback, useMemo } from 'react';
import { MmgConnectedLayer } from '../../shared/layers/mesh-layer';
import { t } from '../../translations/i18n';
import { MmgConnectedLayerMeshActions } from '../../meshes/list/actions/layer-mesh-actions';
import { MikeConnectedLayerOpenActionButton } from '../../shared/layers/actions/layer-open-action-button';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { getMeshDescription } from '../../shared/panels/panel-utils';
import { EElementCategories, ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import LayerUtils from '../../shared/layers/layer-utils';
import MeshIconUtils from '../mesh-icon-utils';
import { MmgLayerError } from '../../shared/layers/mesh-layer-error';
import { IProject } from '../../models/IProject';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../store/reducers';
import { MmgConnectedSpatialSelectionListItems } from '../../queries/spatial-selections/statial-selection-list-items';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { IWorkspaceQuery } from '../../models/IQueries';
import { EWorkspaceQueryActionType } from '../../store/actions/WorkspaceQueryActionType';
import { MikeLayerColorComplication } from '../../shared-components/mike-layer/complications/layer-color-complications';
import { MikeLayerWorking } from '../../shared-components/mike-layer/layer-working';
import { LayerWithoutBorderStyle } from '../../queries/spatial-selections/spatial-selection-layer';
import { MikeLayerTextComplication } from '../../shared-components/mike-layer/complications/layer-text-complications';

const {
  isLayerWorking,
  isLayerFailed,
  isLayerHidden,
  getLayerSurfaceColor,
  getLayerEdgeColor,
  isLayerLoading,
} = LayerUtils;

type MeshListItemProps = {
  mesh: IWorkspaceEnrichedMesh;
  hiddenMeshes: Array<string>;
  drawnMeshes: Array<IDrawnDataItem>;
  onMeshEdit: (meshId: string) => void;
  onMeshOpenPressed: (meshId: string) => void;
  failedData: Array<string>;
};

/**
 * @name MmgConnectedMeshListItem
 * @summary An item in a mesh list.
 *
 * @param props
 */
export function MmgConnectedMeshListItem(props: MeshListItemProps) {
  const dispatch = useDispatch();

  const getMeshQueriesSelectorInstance = WorkspaceMeshSelectors.makeGetMeshQueries();
  const { mesh, drawnMeshes, hiddenMeshes, failedData, onMeshOpenPressed } = props;
  const { id, name, message, dataId } = mesh;
  const meshQueries: Array<IWorkspaceQuery> = useSelector((state: IGlobalState) =>
    getMeshQueriesSelectorInstance(state, {
      meshId: id,
    }),
  );

  const isWorking = isLayerWorking(mesh, drawnMeshes);
  const isLoading = isLayerLoading(mesh, drawnMeshes);
  const meshDescription = getMeshDescription(mesh, drawnMeshes);
  // NB: colors are flipped on purpose. It is a consequence of display modes (WIREFRAMES VS SURFACE + WIREFRAME)
  const edgeColor = getLayerSurfaceColor(mesh, drawnMeshes);
  const surfaceColor = getLayerEdgeColor(mesh, drawnMeshes);
  const project: IProject | null = useSelector((state: IGlobalState) => state.ProjectReducer.project);
/*   const highlightedWorkspaceElementId = useSelector(
    (state: IGlobalState) => state.WorkspaceReducer.highlightedWorkspaceElementId,
  ); */

  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  const drawnData = useMemo(
    () => {
      if (drawnMeshes && id) {
        return drawnMeshes.find((d: IDrawnDataItem) => d.id === id);
      } else {
        return undefined;
      }
    },
    [drawnMeshes, id],
  );

  const handleVisibiltyChanged = useCallback(
    (show: boolean) => {
      if (meshQueries && meshQueries.length > 0) {
        const ids = meshQueries.map((query: IWorkspaceQuery) => query.id);
        if (ids.length > 0) {
          if (show) {
            dispatch({ type: EWorkspaceQueryActionType.SELECTIONS_RESHOW_DUE_TO_REVISIBLE_PARENT, ids });
          } else {
            dispatch({ type: EWorkspaceQueryActionType.SELECTIONS_HIDE_DUE_TO_INVISIBLE_PARENT, ids });
          }
        }
      }
    },
    [dispatch, meshQueries],
  );

  /**
   * Gets common right actions for mesh layers.
   */
  const getCommonRightActions = () => {
    return (
      <>
        {canUpdateWorkspace && (
          <MmgConnectedLayerMeshActions
            meshDrawnData={drawnData}
            layerId={id}
            mesh={mesh}
            updateUserSettingsOnToggle={true}
            onMeshEdit={props.onMeshEdit}
          />
        )}
        <MikeConnectedLayerOpenActionButton layerId={id} layerCategory={ELEMENT_CATEGORIES.MESH} />
      </>
    );
  };

  const baseLayerProperties = {
    layerId: id,
    layerName: name,
    layerDescription: meshDescription,
    rightActions: getCommonRightActions(),
    // isLayerFaded: dataId && isLayerHidden(ELEMENT_CATEGORIES.MESH, mesh.id, hiddenMeshes),
    onLayerNamePressed: onMeshOpenPressed,
  };
  const isParentHidden = hiddenMeshes.includes(mesh.id);

  if (failedData.includes(mesh.id)) {
    return <MmgLayerError {...baseLayerProperties} layerDescription={t('FAILED_LOAD_DATA')} />;
  }

  if (isLayerFailed(mesh)) {
    return <MmgLayerError {...baseLayerProperties} layerDescription={message} />;
  }

/*   const handleLayerMouseEnter = (layerId: string) => {
    highlightLayer(layerId);
  }; */

  if (isLoading && isLayerHidden(ELEMENT_CATEGORIES.MESH, mesh.id, hiddenMeshes)) {
    return (
      <>
        <MmgConnectedLayer
          layerId={id}
          {...baseLayerProperties}
          leftIcon={MeshIconUtils.getIcon(mesh)}
          leftComplications={
            dataId &&
            edgeColor &&
            surfaceColor && <MikeLayerColorComplication edgeColor={edgeColor} surfaceColor={surfaceColor} />
          }
          showTooltip={true}
          type={EElementCategories.MESH}
    /*       onLayerMouseEnter={handleLayerMouseEnter}*/
          onVisibiltyChanged={handleVisibiltyChanged} 
          showVisibilityIcon={true}
          // hovering={highlightedWorkspaceElementId === id}
          isTiled={mesh.isTiled}
        />
        {<MmgConnectedSpatialSelectionListItems geometryId={mesh.id} isParentHidden={isParentHidden} />}
      </>
    );
  }

  if (isWorking) {
    return (
      <MikeLayerWorking
        {...baseLayerProperties}       
      />
    );
  }

  return (
    <>
      <MmgConnectedLayer
        layerId={id}
        {...baseLayerProperties}
        leftIcon={MeshIconUtils.getIcon(mesh)}
        leftComplications={
          dataId &&
          edgeColor &&
          surfaceColor && <MikeLayerColorComplication edgeColor={edgeColor} surfaceColor={surfaceColor} />
        }
        showTooltip={true}
        type={EElementCategories.MESH}
        css={meshQueries && meshQueries.length > 0 && LayerWithoutBorderStyle}
/*         onLayerMouseEnter={handleLayerMouseEnter}
        onVisibiltyChanged={handleVisibiltyChanged} */
        showVisibilityIcon={true}
        // hovering={highlightedWorkspaceElementId === id}
        isTiled={mesh.isTiled}
        topComplications={mesh.isTiled ? <MikeLayerTextComplication text={t('TILED')} /> : undefined}
      />
      {<MmgConnectedSpatialSelectionListItems geometryId={mesh.id} isParentHidden={isParentHidden} />}
    </>
  );
}
