import Mesh from '../../icons/Mesh.svg?react';
import { IWorkspaceMesh } from '../../models/IMeshes';
import { MikeLayerEdit } from '../../shared-components/mike-layer/layer-edit';

type MeshListItemEditProps = {
  meshName: string;
  meshId: string;
  onMeshUpdate: (mesh: IWorkspaceMesh) => any;
};

/**
 * @name MmgMeshListItemEdit
 * @summary A edited item in a mesh list.
 *
 * @param props
 */
export function MmgMeshListItemEdit(props: MeshListItemEditProps) {
  const { meshName, meshId } = props;

  const onMeshUpdate = ({ name }) => props.onMeshUpdate({ name, id: meshId });

  return <MikeLayerEdit layerName={meshName} leftIcon={<Mesh />} onLayerUpdate={onMeshUpdate} />;
}
