import { IParameterSettings, EParameterSettingsSelectType } from '../../../models/IParameterSettings';

/**
 * Defines custom settings for mesh configuration parameters
 */
export const MESH_OPERATION_CUSTOM_SETTINGS: {
  [param: string]: IParameterSettings;
} = {
  radialElementType: { selectType: EParameterSettingsSelectType.RADIO },
};
