import { IOperationDescription } from '../models/IOperationDescriptions';
import { isEmpty, upperFirst } from 'lodash-es';
import { IOperationConfiguration } from '../models/IOperations';
import { getDefaultParameterValues } from '../shared/parameters/parameter-utils';

/**
 * Returns true in case the opeation description have any parameters that can be configured or if queries are supported
 * @param operationDescription
 */
export const anythingToConfigure = (operationDescription: IOperationDescription) => {
  if (!operationDescription) {
    return false;
  }
  const { supportsQuery, parameterDescriptions } = operationDescription;

  return !isEmpty(parameterDescriptions) || supportsQuery;
};

/**
 * Will create a new operationConfiguration based on the operationDescription.
 * If an initial operationConfiguration is given the parameters will be set accordingly
 * @param operationDescription
 * @param initialOperationConfiguration
 */
export const createOperationConfigurationFromDescription = (
  operationDescription: IOperationDescription,
  initialOperationConfiguration?: IOperationConfiguration,
) => {
  if (!operationDescription) {
    return initialOperationConfiguration;
  }

  const initialParameterValues = self.getOperationParameterValues(
    operationDescription,
    (initialOperationConfiguration || {}).parameters,
  );

  const { operationType } = operationDescription;
  const initialOperation = {
    ...initialOperationConfiguration,
    type: upperFirst(operationType),
    operationType,
    parameters: initialParameterValues || {},
  };

  return initialOperation;
};

/**
 * Will get an inital value for each of the operation parameters defined by the operation description
 * Will use the parameter values if provided, otherwise the default values
 * Will leave out any parameters that are not included in the parameter description of the operation description
 *
 * @param operationDescription
 * @param parameterValues
 */
export const getOperationParameterValues = (
  operationDescription: IOperationDescription,
  parameterValues?: {
    [parameterKey: string]: string | number | boolean | object;
  },
): {
  [parameterKey: string]: string | number | boolean | object;
} => {
  const defaultValues = self.getDefaultOperationParameterValues(operationDescription) || {};

  // Use default values if initial values are not provided. Leave out any initial values for parameters that are not included in the parameterDescription
  const initialValues = !parameterValues
    ? defaultValues
    : Object.keys(defaultValues).reduce(
        (a, key) => ({
          ...a,
          [key]: (key in parameterValues ? parameterValues : defaultValues)[key],
        }),
        {},
      );
  return initialValues;
};

/**
 * Gets default values of the parameters for the operation description
 *
 * @param operationDescription
 */
export const getDefaultOperationParameterValues = (
  operationDescription: IOperationDescription,
): { [parameterKey: string]: string | number | boolean | object } => {
  if (!operationDescription) {
    return null;
  }

  const { parameterDescriptions } = operationDescription;

  const defaultValues = getDefaultParameterValues(parameterDescriptions);

  return defaultValues;
};

const self = {
  anythingToConfigure,
  createOperationConfigurationFromDescription,
  getOperationParameterValues,
  getDefaultOperationParameterValues,
};

export default self;
