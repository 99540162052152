import { useState, useCallback } from 'react';
import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import {
  IWorkspaceGeometry,
  IWorkspaceGeometryParameters,
  GEOMETRY_ITEM_TYPES,
  IWorkspaceEnrichedGeometry,
} from '../../models/IGeometries';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import WorkspaceGeometriesManager from '../../managers/WorkspaceGeometriesManager';
import { IParameterDescription, PARAMETER_VALUE_TYPES } from '../../models/IOperationDescriptions';
import { MmgParameterInput } from '../../shared/parameters/parameter-input';
import { Formik } from 'formik';
import { IProject } from '../../models/IProject';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../store/reducers';

type GeometryParametersProps = {
  workspaceId: string;
  geometry: IWorkspaceEnrichedGeometry;
};

export const MmgGeometryParameters = (props: GeometryParametersProps) => {
  const { workspaceId, geometry = {} } = props;
  const { id: geometryId } = geometry;
  const project: IProject | null = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  // TODO hevo the available geometry parameters (default mesh parameters) should eventually come from the api. For now we hardcode them here.
  const allowExcludeFromMeshes =
    geometry.itemType === GEOMETRY_ITEM_TYPES.POLYGON || geometry.itemType === GEOMETRY_ITEM_TYPES.MULTI_POLYGON;

  const anyParametersAllowed = allowExcludeFromMeshes; // there might be more parameters in the future.

  const [geometryParameters, setGeometryParameters] = useState((allowExcludeFromMeshes
    ? {
        isHole: geometry.isHole,
      }
    : {}) as IWorkspaceGeometryParameters);

  const parameterDescriptions: { [param: string]: IParameterDescription } = {
    isHole: {
      type: '',
      name: 'isHole',
      valueType: PARAMETER_VALUE_TYPES.BOOLEAN,
      defaultValue: false,
    },
  };

  const onParameterChanged = useCallback(
    (param: string, val: string | number | boolean) => {
      const changedGeometryParameters = {
        ...geometryParameters,
        [param]: val,
      };
      setGeometryParameters(changedGeometryParameters);
      onGeometryParametersChanged(workspaceId, geometryId, changedGeometryParameters);
    },
    [geometryId, workspaceId, geometryParameters],
  );

  if (!anyParametersAllowed) {
    return <></>;
  }

  return (
    geometry &&
    canUpdateWorkspace && (
      <MmgPanelSubsection>
        {allowExcludeFromMeshes && (
          <Formik
            initialValues={{ isHole: geometryParameters.isHole }}
            onSubmit={() => {
              // todo hevo for now we do not submit using formik
            }}
          >
            <MmgParameterInput
              parameterKey="isHole"
              parameterDescription={parameterDescriptions['isHole']}
              value={geometryParameters.isHole}
              onParameterChanged={onParameterChanged as any}
            />
          </Formik>
        )}
      </MmgPanelSubsection>
    )
  );
};

const onGeometryParametersChanged = (
  workspaceId: string,
  geometryId: string,
  parameters: IWorkspaceGeometryParameters,
) => {
  const updatedGeometry = { id: geometryId, ...parameters };
  submitGeometryParameters(workspaceId, updatedGeometry);
};

const submitGeometryParameters = (workspaceId: string, geometry: IWorkspaceGeometry) => {
  return WorkspaceGeometriesManager.updateGeometries(workspaceId, [geometry])
    .then(() => {
      const toast = {
        text: t('UPDATED', 1, { thing: t('GEOMETRY') }),
      };
      store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
    })
    .catch((error) => {
      const toast = {
        text: t('FAILED_TO', 1, { action: t('UPDATE') }),
        operationId: error.operationId,
      };
      store.dispatch({ type: 'toast/ADD/ERROR', toast });
      throw error;
    });
};
