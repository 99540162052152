import { EOperationStates } from './IOperations';
import { EElementCategories } from '../shared/panels/mesh-panel-constants';

export enum EVariableItemTypes {
  VARIABLE = 'Variable',
  VARIABLE_ELEVATION = 'VariableElevation',
  VARIABLE_OTHER = 'VariableOther',
  ELEVATION = 'Elevation',

  GRIDDED = 'Gridded',
  SCATTER = 'Scatter',
}

export const VARIABLE_ITEM_TYPES = {
  VARIABLE: EVariableItemTypes.VARIABLE,
  VARIABLE_ELEVATION: EVariableItemTypes.VARIABLE_ELEVATION,
  ELEVATION: EVariableItemTypes.ELEVATION,

  GRIDDED: EVariableItemTypes.GRIDDED,
  SCATTER: EVariableItemTypes.SCATTER,
};

export interface IWorkspaceVariable {
  id?: string;
  dataId?: string; // should be replaced by vtkFileId
  // vtkFileId?: string; // will be included ny api soon
  projectId?: string;
  dataset?: string;
  isGrid?: boolean;
  itemType?: EVariableItemTypes;
  name?: string;
  created?: string;
  updated?: string;
  hasElevation?: boolean;
  isTiled?: boolean;
}

export interface IWorkspaceEnrichedVariable extends IWorkspaceVariable {
  state?: EOperationStates;
  message?: string;
  category?: EElementCategories;
}
