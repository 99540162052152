import { InputAdornment } from '@mui/material';

export interface IInputUnitAdornmentProps {
  unitString?: string;
}

export const MmgInputUnitAdornment = (props: IInputUnitAdornmentProps) => {
  const { unitString } = props;

  return unitString && <InputAdornment position="end">{unitString}</InputAdornment>;
};
