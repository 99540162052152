import { EItemType } from '../../models/IOperationDescriptions';
import { EMapToolActionType } from './MapToolActionType';
import { EWorkspaceDataActionType } from './WorkspaceDataActionType';
import { FeatureCollection } from 'geojson';
import { EWorkspaceVariableActionType } from './WorkspaceVariableActionType';
import { EWorkspaceGeometryActionType } from './WorkspaceGeometryActionType';
import { EGeometryItemTypes } from '../../models/IGeometries';
import { EEditModeIds } from '../../shared/edit-modes/edit-modes';
import { IPatchFeature } from '../../models/IQueryCompleted';

export const addEditingGeometry = (id: string) => ({
  type: EWorkspaceDataActionType.ADD_EDITING_GEOMETRY,
  data: id,
});

export const removeEditingGeometry = (id: string) => ({
  type: EWorkspaceDataActionType.REMOVE_EDITING_GEOMETRY,
  data: id,
});

export const addEditingMesh = (id: string) => ({
  type: EWorkspaceDataActionType.ADD_EDITING_MESH,
  data: id,
});

export const removeEditingMesh = (id: string) => ({
  type: EWorkspaceDataActionType.REMOVE_EDITING_MESH,
  data: id,
});

export const addEditingVariable = (id: string) => ({
  type: EWorkspaceDataActionType.ADD_EDITING_VARIABLE,
  data: id,
});

export const removeEditingVariable = (id: string) => ({
  type: EWorkspaceDataActionType.REMOVE_EDITING_VARIABLE,
  data: id,
});

export const updateValue = (key: string, value: any) => ({
  type: EMapToolActionType.UPDATE_FEATURES_FOR_EDITING_VALUE,
  data: { key, value },
});

export const getFeaturesForEditing = (
  workspaceId: string,
  itemId: string,
  itemType: EItemType,
  selectionFeatureCollection: FeatureCollection<any, any>,
  previousEdits: Array<FeatureCollection<any, any>>,
  geometryEditType: EGeometryItemTypes,
) => ({
  type: EMapToolActionType.GET_FEATURES_FOR_EDITING,
  data: { workspaceId, itemId, itemType, selectionFeatureCollection, previousEdits, geometryEditType },
});

export const setLoadingFeaturesForEditing = (loading = true) => ({
  type: EMapToolActionType.LOADING_FEATURES_FOR_EDITING,
  data: loading,
});

export const setFeaturesForEditing = (fc: FeatureCollection<any, any>, id: string) => ({
  type: EMapToolActionType.SET_FEATURES_FOR_EDITING,
  data: { featureCollection: fc, operationId: id },
});

export const clearFeaturesForEditing = () => ({
  type: EMapToolActionType.CLEAR_FEATURES_FOR_EDITING,
});

export const updateVariableData = (workspaceId: string, variableId: string, previousEdits: Array<IPatchFeature>) => ({
  type: EWorkspaceVariableActionType.UPDATE_DATA,
  data: { workspaceId, variableId, previousEdits },
});

export const createVariable = (workspaceId: string, variableId: string, previousEdits: Array<IPatchFeature>) => ({
  type: EWorkspaceVariableActionType.CREATE,
  data: { workspaceId, variableId, previousEdits },
});

export const creatingVariable = (creating = true) => ({
  type: EWorkspaceVariableActionType.CREATING_DATA,
  data: creating,
});

export const createVariableFailed = () => ({
  type: EWorkspaceVariableActionType.CREATE_FAILED,
});

export const updatingVariableData = (updating = true) => ({
  type: EWorkspaceVariableActionType.UPDATING_DATA,
  data: updating,
});

export const updateVariableDataFailed = () => ({
  type: EWorkspaceVariableActionType.UPDATE_DATA_FAILED,
});

export const setEditMode = (editMode: EEditModeIds, geometryType: EGeometryItemTypes, itemType: EItemType) => ({
  type: EMapToolActionType.SET_EDIT_MODE,
  data: { editMode, geometryType, itemType },
});

export const updateGeometryData = (workspaceId: string, geometryId: string, previousEdits: Array<IPatchFeature>) => ({
  type: EWorkspaceGeometryActionType.UPDATE,
  data: { workspaceId, geometryId, previousEdits },
});

export const createGeometry = (workspaceId: string, geometryId: string, previousEdits: Array<IPatchFeature>) => ({
  type: EWorkspaceGeometryActionType.CREATE,
  data: { workspaceId, geometryId, previousEdits },
});

export const creatingGeometry = (creating = true) => ({
  type: EWorkspaceGeometryActionType.CREATING,
  data: creating,
});

export const createGeoemtryFailed = () => ({
  type: EWorkspaceGeometryActionType.CREATE_FAILED,
});

export const updatingGeometryData = (updating = true) => ({
  type: EWorkspaceGeometryActionType.UPDATING,
  data: updating,
});

export const updateGeometryDataFailed = () => ({
  type: EWorkspaceGeometryActionType.UPDATE_FAILED,
});
