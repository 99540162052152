import { t } from '../translations/i18n';
import { IWorkspaceComment } from '../models/IComments';
import { MmgPanelSubsection } from '../shared/panels/panel-subsection';
import { MmgCommentSummary } from './comment-summary';
import { useSelector } from 'react-redux';
import WorkspaceCommentSelectors from '../store/selectors/WorkspaceCommentSelectors';
import { CircularProgress } from '@mui/material';
import { IGlobalState } from '../store/reducers';

export const MmgConnectedCommentsList = () => {
  const { commentsLoading, commentsLoadingFailed, focusedCommentId } = useSelector(
    (state: IGlobalState) => state.WorkspaceCommentReducer,
  );
  const { user } = useSelector((state: IGlobalState) => state.UserReducer);
  const comments: Array<IWorkspaceComment> = useSelector(WorkspaceCommentSelectors.getSortedComments);
  const { project } = useSelector((state: IGlobalState) => state.ProjectReducer);

  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;
  const anyComments = comments && comments.length > 0;

  return (
    <>
      {commentsLoading && (
        <MmgPanelSubsection>
          <p>{t('COMMENTS_LOADING')}</p>
          <div>
            <CircularProgress />
          </div>
        </MmgPanelSubsection>
      )}

      {commentsLoadingFailed && <MmgPanelSubsection>{t('COMMENTS_LOADING_FAILED')}</MmgPanelSubsection>}

      {!anyComments &&
        !commentsLoading &&
        !commentsLoadingFailed && (
          <MmgPanelSubsection>
            <p>{t('NO_COMMENTS')}</p>
          </MmgPanelSubsection>
        )}

      {anyComments &&
        comments.map((comment) => {
          return (
            <MmgCommentSummary
              key={comment.id}
              comment={comment}
              isFocused={focusedCommentId === comment.id}
              loggedInUser={user}
              userCanUpdateWorkspace={canUpdateWorkspace}
            />
          );
        })}
    </>
  );
};
