/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import React from 'react';
import { Typography, Link } from '@mui/material';
import { formatDate } from '../../mike-shared-helpers/date';
import { INotification } from './INotification';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Notifications of one day.
   */
  notification: INotification;
  /**
   * Link href.
   */
  href?: string;
  /**
   * Link callback.
   */
  onLinkCallBack?: (arg0: INotification) => void;
  /**
   * Custom render function.
   * To display the content of a notification item.
   */
  renderContent?: (notification: INotification) => React.ReactNode;
  /**
   * Custom render function.
   * To display the title of a notification item.
   */
  renderTitle?: (notification: INotification) => React.ReactNode;
}

const notificationItemStyle = css` 
  padding-bottom: ${mikeSharedTheme.spacing(1)};
`;

const notificationTitleStyle = css`
  padding-top: ${mikeSharedTheme.spacing(1)};
  padding-bottom: ${mikeSharedTheme.spacing(1)};
  font-weight: bold;
`;

const notificationTextStyle = css` 
  padding-bottom: ${mikeSharedTheme.spacing(1)};
`;

const notificationLinkStyle = css` 
  cursor: pointer;
  font-size: 12px;
`;

/**
 * @name MikeNotificationItem
 * @summary A single notification icon, meant to be rendered by a notification item group.
 */
export const MikeNotificationItem = ({
  notification,
  href,
  onLinkCallBack,
  renderContent,
  renderTitle,
}: IProps) => { 
  const title = notification.title;

  const handleClick = (currentNotification: INotification) => (e) => {
    if (onLinkCallBack) {
      e.preventDefault();
      onLinkCallBack(currentNotification);
    }
  };

  return (
    <div css={notificationItemStyle}>
      {renderTitle ? renderTitle(notification) : 
        <Typography css={notificationTitleStyle} variant="body2">
          {title}
        </Typography>
      }
      {renderContent ? (
        renderContent(notification)
      ) : (
        <>
          {notification.message && (
            <Typography css={notificationTextStyle} variant="body2">
              {notification.message}
            </Typography>
          )}
          <Typography css={notificationTextStyle} variant="body2">
            {formatDate(notification.time, true, true)}
          </Typography>
        </>
      )}
      {notification.link && (
        <Link
          href={href}
          target="_blank"
          rel="noopener"
          onClick={handleClick(notification)}
          css={notificationLinkStyle}
        >
          {notification.linkLabel ? notification.linkLabel : 'See details'}
        </Link>
      )}
    </div>
  );
};

export default MikeNotificationItem;
