import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import VariableOperationsManager from '../../managers/VariableOperationsManager';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { t } from '../../translations/i18n';
import { MmgOperationDescription } from '../../operations/operation-description';
import { MmgOperationSelector } from '../../operations/operation-selector';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import { MmgOperationConfiguration } from '../../operations/operation-configuration';
import { IOperationDescription } from '../../models/IOperationDescriptions';
import { IOperationConfiguration } from '../../models/IOperations';
import { IQueryDefinitionApi } from '../../models/IQueryDefinitions';
import QueryUtils from '../../meshes/mesh-query/mesh-query-utils';
import { isAnyLayerWorking } from '../../shared/layers/layer-utils';
import { MmgGroup } from '../../shared/groups/group';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { useIsMounted } from '../../shared/hooks/hooks';
import { useMount } from 'react-use';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import { isEqual, sortBy } from 'lodash';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { EOperationActionType } from '../../store/actions/OperationActionType';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../shared-components/mike-button';

type VariablOperationsSectionProps = {
  projectId: string;
  workspaceId: string;
  variableIds: Array<string>;
  onOperationSubmitted?: (success: boolean) => void;
};

/**
 * @name MmgGeometryOperationsSection
 * @param props
 * @summary Allows applying operations from the api to a geometry.
 *
 */
export const MmgConnectedVariableOperationsSection = (props: VariablOperationsSectionProps) => {
  const { workspaceId, variableIds, onOperationSubmitted } = props;

  const variablesDrawnData: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceVariablesByIds,
  );
  const variables = useSelector(WorkspaceVariableSelectors.getSelectedEnrichedWorkspaceVariables);

  const [operationDescriptions, setOperationDescriptions] = React.useState({} as {
    [operationKey: string]: IOperationDescription;
  });
  const [operationsLoading, setOperationsLoading] = React.useState(false);
  const [operationsLoadingFailed, setOperationsLoadingFailed] = React.useState(false);
  const [operationInProgress, setOperationInProgress] = React.useState(false);
  const [selectedOperationKey, setSelectedOperationKey] = React.useState(null as string);
  const [operationConfiguration, setOperationConfiguration] = useState(null as IOperationConfiguration);
  // TODO: masm; queryDefinition is always {}, it seems redundant.
  const [queryDefinition] = React.useState({} as IQueryDefinitionApi);

  const isMounted = useIsMounted();

  const { goBackToReferrer } = useNavigateBack();

  const onPanelExit = () => {
    goBackToReferrer();
  };

  const onOperationChanged = (event) => {
    const operationKey = event.target.value;
    setSelectedOperationKey(operationKey);
    setOperationConfiguration(null);
  };

  const onConfigurationChanged = (newConfiguration: IOperationConfiguration) => {
    setOperationConfiguration(newConfiguration);
  };

  const submitOperation = (event) => {
    event.preventDefault();
    if (!selectedOperationKey) {
      return;
    }
    setOperationInProgress(true);

    // Always ask for elements
    // todo hevo might consider if we sometimes wants points, e.g. for for nodemarkers
    // todo hevo should this be passed in? Or should it be set from the outside afterwards or should it be set to NOT_ET or set by the api alltogether?????????
    let success = false;

    VariableOperationsManager.executeVariableOperation(workspaceId, {
      ...operationConfiguration,
      inputIds: variableIds,
      queryDefinition,
    })
      .then((operationMetadata) => {
        success = true;
        // todo hevo Might not need to show the toast
        const toast = {
          text: t('VARIABLE_OPERATION_SUBMITTED_SUCCESSFULLY'),
        };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
        store.dispatch({
          type: EOperationActionType.CREATE_OR_UPDATE,
          operationMetadata,
        });
      })
      .catch((error) => {
        success = false;
        const toast = {
          text: t('VARIABLE_OPERATION_SUBMITTED_FAILED'),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      })
      .finally(() => {
        isMounted() && setOperationInProgress(false);
        if (onOperationSubmitted) {
          onOperationSubmitted(success);
        }
      });
  };

  // load available operations when mounting
  useMount(() => {
    if (!workspaceId) {
      return;
    }
    setOperationsLoading(true);
    setOperationsLoadingFailed(false);
    VariableOperationsManager.getVariableOperationDescriptions(workspaceId, variableIds)
      .then((operations) => {
        const keys = Object.keys(operations);
        for (let i = 0; i < keys.length; i++) {
          const obj = operations[keys[i]];
          if (!isEqual(sortBy(obj.allowedInputIds), sortBy(variableIds))) {
            isMounted() && setOperationDescriptions({});
          } else {
            isMounted() && setOperationDescriptions(operations);
          }
        }
      })
      .catch((error) => {
        isMounted() && setOperationsLoadingFailed(true);
        console.error('Failed to get variable operations');
        throw error;
      })
      .finally(() => {
        isMounted() && setOperationsLoading(false);
      });
  });

  const operationKeys = Object.keys(operationDescriptions);
  const isAnyWorking = isAnyLayerWorking(variables, variablesDrawnData);

  const selectedOperationDescription = (operationDescriptions || {})[selectedOperationKey] as IOperationDescription;

  const isOperationsAvailable = operationKeys && operationKeys.length > 0;

  const { supportsQuery: selecteOperationSupportQuery } = selectedOperationDescription || {};

  // If query is not supported, the QueryDefinition is by default complete.
  const isQueryComplete = !selecteOperationSupportQuery || QueryUtils.isQueryDefinitionComplete(queryDefinition);

  const isOperationValid = selectedOperationKey && variableIds && variableIds.length;

  const submitDisabled = !isOperationValid || !isQueryComplete || isAnyWorking || operationInProgress;

  return (
    <>
      {(isAnyWorking || operationInProgress) && (
        <MmgPanelSubsection>
          <p>{t('CONFIGURE_AFTER_DATA_PROCESSED')}</p>
        </MmgPanelSubsection>
      )}

      <MmgGroup groupName={t('VARIABLE_OPERATION_GROUP')} canBeHidden={false}>
        {isOperationsAvailable ? (
          <>
            <MmgPanelSubsection>
              <MmgOperationSelector
                label={t('SELECT_VARIABLE_OPERATION')}
                placeholder={t('SELECT_VARIABLE_OPERATION').toLowerCase()}
                operationKeys={operationKeys}
                operationsLoading={operationsLoading}
                operationsLoadingFailed={operationsLoadingFailed}
                onOperationChanged={onOperationChanged}
                selectedOperationKey={selectedOperationKey}
              />

              <MmgOperationDescription operationKey={selectedOperationKey} />

              <MmgOperationConfiguration
                operationDescription={selectedOperationDescription}
                onConfigurationChanged={onConfigurationChanged}
              />
            </MmgPanelSubsection>

            <MikeStickyPanelBottomActions>
              <MikeButton variant="contained" color="secondary" onClick={onPanelExit}>
                {t('CANCEL')}
              </MikeButton>
              <MikeButton variant="contained" disabled={submitDisabled} onClick={submitOperation} active={operationInProgress}>
                {t('SUBMIT_VARIABLE_OPERATION')}
              </MikeButton>
            </MikeStickyPanelBottomActions>
          </>
        ) : (
          <>
            <MmgMessageBanner
              messageTitle={t('OPERATIONS_NOT_AVAILABLE')}
              message={t('VARIABLE_POSTOPERATIONS_NOTVIABLE_DETAILS')}
            />
            <MikeStickyPanelBottomActions>
              <MikeButton variant="contained" color="secondary" onClick={onPanelExit}>
                {t('BACK')}
              </MikeButton>
            </MikeStickyPanelBottomActions>
          </>
        )}
      </MmgGroup>
    </>
  );
};
