import { useMemo, useCallback } from 'react';
import { IWorkspaceAttribute, IWorkspaceOptionAttributeValue, ATTRIBUTE_DATA_TYPES } from '../../models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import { MmgQueryCriteriaOptionsInput } from './query-criteria-options-input';
import { IAttributeRangeQueryDefinitionApi, IQueryDefinitionApi } from '../../models/IQueryDefinitions';
import { MmgQueryCriteriaStringInput } from './query-criteria-string-input';
import { MmgQueryCriteriaRangeInput } from './query-criteria-range-input';
import { toNumber } from 'lodash-es';

type AttributeQueryCriteriaProps = {
  attribute: IWorkspaceAttribute;
  attributeSettings?: Array<IWorkspaceAttributeSettings>;
  queryDefinition?: IQueryDefinitionApi;
  getAttributeOptionValues?: (attributeName: string) => Array<IWorkspaceOptionAttributeValue>;
  onQueryParametersChanged?: (parameters: { [param: string]: number | string }) => void;
  canPreviewQuery?: boolean;
  onPreviewQuery?: () => void;
};

/**
 * Renders a query criteria for an attribute. Depending on the attribute settings a range criteria or a option criteria (dropdown) will be rendered
 * @param props
 */
export const MmgAttributeQueryCriteria = (props: AttributeQueryCriteriaProps) => {
  const {
    attribute,
    queryDefinition,
    onQueryParametersChanged,
    getAttributeOptionValues,
    canPreviewQuery,
    onPreviewQuery,
  } = props;

  const optionValues = useMemo(
    () => {
      if (attribute && getAttributeOptionValues) {
        const { name } = attribute;
        return getAttributeOptionValues(name);
      }

      return null;
    },
    [attribute, getAttributeOptionValues],
  );

  const callOnQueryParametersChanged = useCallback(
    (parameters: { [param: string]: number | string }) => {
      if (onQueryParametersChanged) {
        onQueryParametersChanged(parameters);
      }
    },
    [onQueryParametersChanged],
  );

  const onRangeParameterChanged = (param: string, value: number) => {
    callOnQueryParametersChanged({ [param]: value });
  };

  const onSelectedOptionChanged = (newVal: string | number) => {
    // option values are currently assumed to be numbers
    // needed to be able to highlight query in viewer
    callOnQueryParametersChanged({
      fromValue: toNumber(newVal),
      toValue: toNumber(newVal),
    });
  };

  if (!attribute) {
    return null;
  }

  if (optionValues) {
    // options are send to the api as range queries with fromValue === toValue.
    const { fromValue: value } = (queryDefinition || {}) as IAttributeRangeQueryDefinitionApi;

    return (
      <MmgQueryCriteriaOptionsInput
        value={value}
        optionValues={optionValues}
        onSelectedOptionChanged={onSelectedOptionChanged}
        canPreviewQuery={canPreviewQuery}
        onPreviewQuery={onPreviewQuery}
      />
    );
  }

  const { dataType, range } = attribute;

  if (dataType === ATTRIBUTE_DATA_TYPES.INT32 || dataType === ATTRIBUTE_DATA_TYPES.DOUBLE) {
    const rangeMin = range ? range[0] : null;
    const rangeMax = range ? range[1] : null;

    const { fromValue, toValue } = (queryDefinition || {}) as IAttributeRangeQueryDefinitionApi;

    return (
      <MmgQueryCriteriaRangeInput
        rangeMin={rangeMin}
        rangeMax={rangeMax}
        rangeQueryMin={fromValue}
        rangeQueryMax={toValue}
        onQueryParameterChanged={onRangeParameterChanged}
        canPreviewQuery={canPreviewQuery}
        onPreviewQuery={onPreviewQuery}
      />
    );
  }

  if (dataType === ATTRIBUTE_DATA_TYPES.STRING) {
    return <MmgQueryCriteriaStringInput />;
  }
};
