import { translateWithPrefixAndSuffix, translateWithPrefix, toLocaleRoundedString } from '../../translations/utils';
import {
  PARAMETER_VALUE_TYPES,
  IParameterDescription,
  EParameterDescriptionTypes,
  EItemType,
} from '../../models/IOperationDescriptions';
import { isNumber, isBoolean, isInteger, isString } from 'lodash-es';

const PARAM_PREFIX = 'PARAM';
const PARAM_SUFFIX = 'SHORT';

export const getParameterDescription = (
  param: string,
  parameterDescriptions: { [parameterKey: string]: IParameterDescription },
): IParameterDescription => {
  if (!parameterDescriptions) {
    return null;
  }

  const parameterDescription = parameterDescriptions[param];
  return parameterDescription;
};

export const getParamShortName = (param: string, nameKey?: string) => {
  const paramName = translateWithPrefixAndSuffix(PARAM_PREFIX, PARAM_SUFFIX, nameKey || param);
  return paramName;
};

export const getParamLabel = (param: string, nameKey?: string) => {
  return translateWithPrefix(PARAM_PREFIX, nameKey || param);
};

export const getParamUnitString = (parameterDescription: IParameterDescription) => {
  if (!parameterDescription) {
    return '';
  }

  const { unit } = parameterDescription || {};

  if (!unit) {
    return '';
  }
  const UNIT_PREFIX = 'UNIT';
  const unitString = translateWithPrefix(UNIT_PREFIX, unit, true);

  return unitString;
};
/**
 *
 * @param parameterValue
 * @param parameterDescription
 */
export const getParamValueString = (
  parameterValue: number | boolean | string | object,
  parameterDescription: IParameterDescription,
) => {
  // if no value, just use the value given.
  if (!parameterValue && parameterValue !== 0) {
    return parameterValue;
  }

  const { valueType: parameterValueType, values: parameterValues } = parameterDescription || {};

  if (parameterValues) {
    const valuestring = parameterValues[parameterValue.toString()];
    if (valuestring) {
      return valuestring;
    }
  }
  // if no value type, just use the value given.
  const valueType = parameterValueType ? parameterValueType : self.getValueTypeByValue(parameterValue);

  switch (valueType) {
    case PARAMETER_VALUE_TYPES.INT32:
    case PARAMETER_VALUE_TYPES.INTEGER:
    case PARAMETER_VALUE_TYPES.DOUBLE: {
      return toLocaleRoundedString(parameterValue, 3);
    }

    case PARAMETER_VALUE_TYPES.BOOLEAN: {
      return parameterValue
        ? translateWithPrefix(PARAM_PREFIX, 'VALUE_ON')
        : translateWithPrefix(PARAM_PREFIX, 'VALUE_OFF');
    }

    case PARAMETER_VALUE_TYPES.ENUM: {
      const VALUE_PREFIX = 'PARAM_VALUE_' + parameterDescription.name;
      return translateWithPrefix(VALUE_PREFIX, parameterValue as string);
    }

    case PARAMETER_VALUE_TYPES.GUID:
    case PARAMETER_VALUE_TYPES.STRING:
    default:
      return parameterValue;
  }
};

/**
 *
 * @param value Determines the valueType based on the value given.
 * Note that numbers with no decimals, or with decimals being zero(s) will be considered of type INTEGER
 */
export const getValueTypeByValue = (value: number | boolean | string | object): string => {
  if (isBoolean(value)) {
    return PARAMETER_VALUE_TYPES.BOOLEAN;
  }

  if (isInteger(value)) {
    return PARAMETER_VALUE_TYPES.INTEGER;
  }

  if (isNumber(value)) {
    return PARAMETER_VALUE_TYPES.DOUBLE;
  }
  if (isString(value)) {
    return PARAMETER_VALUE_TYPES.STRING;
  }

  return null;
};

/**
 * Gets default values of parameters for each parameter description included
 *
 * @param parameterDescriptions
 */
export const getDefaultParameterValues = (parameterDescriptions: {
  [parameterKey: string]: IParameterDescription;
}): { [parameterKey: string]: string | number | boolean | object } => {
  if (!parameterDescriptions) {
    return null;
  }

  const defaultValues = Object.keys(parameterDescriptions).reduce((values, key) => {
    const parameterDescription = parameterDescriptions[key];
    return { ...values, [key]: parameterDescription.defaultValue };
  }, {});

  return defaultValues;
};

export const isNumericValueType = (parameterValueType: string) => {
  return (
    parameterValueType === PARAMETER_VALUE_TYPES.INT32 ||
    parameterValueType === PARAMETER_VALUE_TYPES.INTEGER ||
    parameterValueType === PARAMETER_VALUE_TYPES.DOUBLE
  );
};

export const isPercentageType = (parameterDescription: IParameterDescription) => {
  const { unit } = parameterDescription || {};
  return unit && unit.toUpperCase() === 'PERCENT';
};

export const isGeometrySelectType = (parameterDescription: IParameterDescription) => {
  const { type, itemType } = parameterDescription;
  if (type === EParameterDescriptionTypes.GUID && itemType === EItemType.GEOMETRY) {
    return true;
  }
  return false;
};

export const isMeshSelectType = (parameterDescription: IParameterDescription) => {
  const { type, itemType } = parameterDescription;
  if (type === EParameterDescriptionTypes.GUID && itemType === EItemType.MESH) {
    return true;
  }
  return false;
};

export const isVariableSelectType = (parameterDescription: IParameterDescription) => {
  const { type, itemType } = parameterDescription;
  if (type === EParameterDescriptionTypes.GUID && itemType === EItemType.VARIABLE) {
    return true;
  }
  return false;
};

/**
 * Replace $ prefixed parameter names with translated parameter labels in a string.
 *
 * @param message string - input message.
 * @returns string - the parsed message.
 */
export const parseParamsToLabels = (message: string) => {
  // Find words that start with '$':
  const matches = message.match(/\$\w*/g);
  if (!matches || !matches.length) {
    return message;
  }
  // Replace any matches with its parameter label:
  let parsedMsg = message;
  matches.forEach((m) => {
    const paramLabel = getParamLabel(m.substr(1));
    parsedMsg = parsedMsg.replace(new RegExp('\\' + m, 'g'), `"${paramLabel}"`);
  });
  return parsedMsg;
};

const self = {
  getParameterDescription,
  getParamName: getParamShortName,
  getParamValueString,
  getValueTypeByValue,
  getDefaultParameterValues,
  isNumericValueType,
  isGeometrySelectType,
  parseParamsToLabels,
};

export default self;
