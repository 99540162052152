/**
 * Exposes reselect-selectors methods for legends.
 *
 * @module WorkspaceDataLegendSelectors
 * @version 1.0.0
 */

import { createSelector } from 'reselect';
import { IDrawnDataLegend, IDrawnDataGradient, IDrawnDataItem, IAnnotatedValue } from '../../models/IWorkspaceData';
import { isEmpty } from 'lodash-es';
import AnnotationUtils, { getCategoryDisplayName } from '../../managers/annotation-utils';
import { DEFAULTS } from '../../MikeVisualizer/lib/MikeVisualizerConfiguration';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';
import MikeVisualizerAnnotations from '../../MikeVisualizer/lib/MikeVisualizerAnnotations';
import { toLocaleRoundedString } from '../../translations/utils';
import { getWorkspaceDataStatistics, getWorkspaceMeshTiles } from './WorkspaceDrawnDataSelectors';
import { IWorkspaceMeshTiles } from '../reducers/WorkspaceMeshTilesReducer';
import { ATTRIBUTE_SETTINGS } from '../../models/IWorkspaceAttributeSettings';
import { IGlobalState } from '../reducers';

const { getValueColors } = MikeVisualizerLib;

const { getDataRangeColors, getUniqueValuesColors } = MikeVisualizerAnnotations;

const drawnDataGradients = (state: IGlobalState): Array<IDrawnDataGradient> =>
  state.WorkspaceDataReducer.workspaceDataGradients;

const drawnDataItems = (state: IGlobalState): Array<IDrawnDataItem> => state.WorkspaceDataReducer.workspaceData;

const hiddenWorkspaceGeometries = (state: IGlobalState): Array<string> =>
  state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries;
const hiddenWorkspaceVariables = (state: IGlobalState): Array<string> =>
  state.WorkspaceVariableReducer.hiddenWorkspaceVariables;
const hiddenWorkspaceMeshes = (state: IGlobalState): Array<string> => state.WorkspaceMeshReducer.hiddenWorkspaceMeshes;

const getDataRangeAnnotatedValues = (
  id: string,
  attributeName: string,
  updatedRange: Array<number>,
  uniqueValuesForTiledMesh = [],
): Array<IAnnotatedValue> => {
  if (!id || !attributeName || !updatedRange) {
    return [];
  }

  const { numberOfLegends, numberOfSignificantDigits } = DEFAULTS.gradientSettings;

  const maxAnnotationTextLength = 7;

  const attributeSetting = ATTRIBUTE_SETTINGS.find((a) => a.name === attributeName);

  const isCategorical = attributeSetting ? attributeSetting.isCategorical : false;

  // ToDo: For tiled meshes with categorical attribute, unique values needs to be returned from backend and applied in the frontend instead of interpolating values between min and mx
  const valueColors = isCategorical
    ? uniqueValuesForTiledMesh && uniqueValuesForTiledMesh.length > 0
      ? getValueColors(id, uniqueValuesForTiledMesh)
      : getUniqueValuesColors(id, attributeName)
    : getDataRangeColors(id, attributeName, updatedRange, numberOfLegends);

  if (valueColors && valueColors.length) {
    const annotations = valueColors.map(({ value, rgba }) => ({
      value,
      rgba,
      annotation: isCategorical
        ? getCategoryDisplayName(attributeName, value)
        : toLocaleRoundedString(value, numberOfSignificantDigits, maxAnnotationTextLength),
    }));

    return annotations;
  }

  return [];
};

/**
 * Selector for getting data legends for all drawn gradients.
 */
const getLegends = createSelector(
  [
    drawnDataGradients,
    drawnDataItems,
    hiddenWorkspaceGeometries,
    hiddenWorkspaceMeshes,
    hiddenWorkspaceVariables,
    getWorkspaceMeshTiles,
    getWorkspaceDataStatistics,
  ],
  (drawnGradients, drawnItems, hiddenGeometryIds, hiddenMeshIds, hiddenVariableIds, meshTiles, meshDataStatistics) => {
    // Legends are only relevant if there are gradients drawn
    if (!drawnGradients || !drawnGradients.length) {
      return [];
    }

    // Legends are only relevant for drawnGradients with gradientAttributeName
    const validGradients = drawnGradients.filter(({ gradientAttributeName }) => !isEmpty(gradientAttributeName));

    const withData = validGradients.filter(({ elementId }) => {
      const item = drawnItems.find(({ id }) => id === elementId);
      return item !== undefined && item.dataArrays;
    });

    const hiddenElementIds = [...hiddenGeometryIds, ...hiddenMeshIds, ...hiddenVariableIds];
    const visibleGradients = withData.filter(({ elementId }) => !hiddenElementIds.includes(elementId));
    const legends = visibleGradients.map((gradient) => {
      const { elementId, gradientAttributeName, gradientSettings, colorRange, isTiled } = gradient;
      let tiledMeshDataRange = null;
      let uniqueValues = null;
      if (isTiled && meshDataStatistics) {
        const dataStatisticsForId = meshDataStatistics[elementId];
        if (dataStatisticsForId) {
          const statistic = dataStatisticsForId.find((d) => d.id === gradientAttributeName);
          if (statistic) {
            tiledMeshDataRange = statistic.range;
            uniqueValues = statistic.uniqueValues;
          }
        }
      }
      const item = drawnItems.find(({ id }) => id === elementId);
      const gradientDataArray = item.dataArrays.find(({ id }) => id === gradientAttributeName);
      let annotatedValues: Array<IAnnotatedValue>;
      if (colorRange && colorRange.colorMappingRange) {
        let id = elementId;
        if (isTiled) {
          const meshTilesForId: Array<IWorkspaceMeshTiles> = meshTiles && meshTiles[elementId];
          if (meshTilesForId) {
            const tiles = meshTilesForId.filter((t: IWorkspaceMeshTiles) => !t.isOverview);
            if (tiles && tiles.length > 0) {
              const partIds = tiles[tiles.length - 1].partIds;
              if (partIds.length > 0) {
                id = partIds[0];
              }
            }
          }
        }
        annotatedValues = getDataRangeAnnotatedValues(
          id,
          gradientAttributeName,
          colorRange.colorMappingRange,
          uniqueValues,
        );
      } else {
        annotatedValues = AnnotationUtils.getAnnotatedValues(elementId, gradientDataArray, gradientSettings);
      }

      const legendData: IDrawnDataLegend = {
        elementId,
        legendItems: annotatedValues.reverse(),
        title: gradientAttributeName,
        colorRange,
        isTiled,
        gradientSettings: gradient.gradientSettings,
        tiledMeshDataRange,
      };

      return legendData;
    });

    return legends;
  }, {
    devModeChecks: {identityFunctionCheck: 'never'}
  }
);

const self = {
  getLegends,
};

export default self;
