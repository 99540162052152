import { useRef, useEffect } from 'react';
import { init, getInstanceByDom } from 'echarts';
import { CSSProperties } from 'react';
import { ECharts, SetOptionOpts } from 'echarts';
import 'echarts/lib/component/legend';
import { translateWithPrefix } from '../../translations/utils';

export interface ReactEChartsProps {
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: 'light' | 'dark';
  chartStatistics?: any;
  chartDataArrayId: string;
  targetDataItem: any;
}

function ReactECharts({
  style,
  settings,
  loading,
  theme,
  chartStatistics,
  chartDataArrayId,
  targetDataItem,
}: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      // Initialize chart
      let chart: ECharts | undefined;
      if (chartRef.current !== null) {
        chart = init(chartRef.current, theme);
      }

      function resizeChart() {
        chart && chart.resize();
      }
      window.addEventListener('resize', resizeChart);

      return () => {
        chart && chart.dispose();
        window.removeEventListener('resize', resizeChart);
      };
    },
    [theme],
  );

  const formatValues = (intervals) => {
    const data = Object.values(intervals).map((interval: any) => {
      return Math.round(interval * 100) / 100;
    });
    return data.length > 10 ? data.slice(10) : data;
  };

  const formatKeys = (intervals) => {
    const data = Object.keys(intervals).map((interval: any) => {
      return Math.round(interval * 100) / 100;
    });
    return data.length > 10 ? data.slice(10) : data;
  };

  useEffect(
    () => {
      const labelOption = {
        show: true,
        verticalAlign: 'top',
        position: 'top',
        rotate: 90,
        fontSize: 12,
        rich: {
          name: {
            textBorderColor: '#fff',
          },
        },
      };
      // Update chart
      if (chartRef.current !== null) {
        const chart = getInstanceByDom(chartRef.current);
        const intervals = chartStatistics && chartStatistics.histogram && chartStatistics.histogram.intervals;

        let option;
        if (intervals) {
          option = {
            title: {
              text: `${targetDataItem.name} ${translateWithPrefix('PROP', chartDataArrayId)}`,
              textStyle: {
                fontSize: 14,
              },
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },

            xAxis: {
              data: formatKeys(intervals),
              axisLabel: {
                show: true,
                interval: 0,
                rotate: 45,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              z: 10,
            },
            yAxis: [
              {
                type: 'value',
                axisLabel: {
                  show: true,
                  interval: 0,
                  rotate: 45,
                },
              },
            ],
            series: [
              {
                data: formatValues(intervals),
                label: labelOption,
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                  color: 'rgba(3, 3, 3, 0.2)',
                },
              },
            ],
          };
        }
        chart.setOption(option, settings);
      }
    },
    [chartDataArrayId, chartStatistics, settings, targetDataItem.name, theme],
  ); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(
    () => {
      // Update chart
      if (chartRef.current !== null) {
        const chart = getInstanceByDom(chartRef.current);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        loading === true ? chart.showLoading() : chart.hideLoading();
      }
    },
    [loading, theme],
  );

  return <div ref={chartRef} style={{ width: '100%', height: '310px', ...style }} />;
}

export default ReactECharts;
