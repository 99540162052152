import { MmgOperationListItem } from './operation-list-item';
import { IOperationMetadata } from '../models/IOperations';

type OperationListProps = {
  projectId: string;
  workspaceId: string;
  operations: Array<IOperationMetadata>;
};

/**
 * @name MmgOperationList
 * @summary Given an array of operations, renders them and sets up their interactions.
 *
 * @param props
 */
export const MmgOperationList = (props: OperationListProps) => {
  const { operations, projectId, workspaceId } = props;

  const opFiltered = operations.filter((op) => {
    return op.state !== 'Cancelled';
  });

  return (
    <>
      {opFiltered.map((operation) => {
        return (
          <MmgOperationListItem
            key={operation.id}
            operation={operation}
            projectId={projectId}
            workspaceId={workspaceId}
          />
        );
      })}
    </>
  );
};
