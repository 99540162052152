/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { LayerHeadingZindex } from '../styles/styleConsts';
import MIKE_COLORS from '../styles/mike-colors';
import mikeSharedTheme from '../styles/mikeSharedTheme';

export const ConfirmStyle = css`
  background-color: white;
  border: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
  border-left: 0;
  border-right: 0;
  overflow-x: hidden;
  hr {
    margin: 0.5rem -10% 1rem -10%;
  }
`;

export const ConfirmContentStyle = css`
  background-color: white;
  padding: ${mikeSharedTheme.spacing(2)};
  & > p,
  & > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const ConfirmHelpTextStyle = css`
  padding-top: ${mikeSharedTheme.spacing(2)};
  padding-bottom: 0;
`;

// TODO hevo Some styles for the header  are copied from mesh-layer-css. We should do this in a better way.
export const ConfirmHeaderStyle = css`
  /* .layer__heading */
  height: 56px;
  display: flex;
  align-items: center;

  /* .layer__heading--primary */
  position: relative;
  z-index: ${LayerHeadingZindex};
  background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};

  /* .layer__name  */
  h4 {
    display: flex;
    color: inherit;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0 0 0 ${mikeSharedTheme.spacing(2)};
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
