/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Tooltip } from '@mui/material';
import Tab, { TabProps as MuiTabProps } from '@mui/material/Tab';

const labelIconStyle = css`
  &.MuiTab-labelIcon {
    min-height: 56px;
    min-width: 90px;
    padding-top: 0px;
    padding-bottom: 4px;
    line-height: 1rem;
  };
  &.MuiTab-icon[data-index="0"] {
    min-height: 40px;
    fill: currentcolor;
    margin: 0px;
  };
`;

interface IProps extends MuiTabProps {
  tooltipTitle?: string
}

/**
 * @name MikeTab
 * @summary A convenience wrapper for the MuiTab to allow styling according to the DHI Guideline. Needed for tabs with icons
 * @param props
 */
export const MikeTab = (props: IProps) => {
  const {tooltipTitle} = props;
  const tabProps = {...props}
  delete tabProps.tooltipTitle
  if (tooltipTitle){
    return (<Tooltip title={tooltipTitle}>
      <Tab css={labelIconStyle} {...tabProps} /> 
    </Tooltip>)
  }
  else{
    return <Tab css={labelIconStyle} {...tabProps} />;
  }  
};

MikeTab.muiName = 'MuiTab';
export default MikeTab;
