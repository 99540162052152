/**
 * Exposes methods related to Mesh Interpolation operations.
 *
 * @module MeshInterpolationOperationsManager
 * @version 2.0.0
 * @requires OperationsProxy
 * @requires OperationsManager
 */
import { HttpErrorHandler } from './http-utils';
import * as OperationsProxy from '../proxies/OperationsProxy';
import OperationsManager from './OperationsManager';

import { IOperationDescriptionApi } from '../models/IOperationDescriptionsApi';

import OperationMappingUtils from '../managers/operation-mapping-utils';
import { IOperationConfiguration } from '../models/IOperations';
import { IOperationDescription, IContextualOperationDescriptions } from '../models/IOperationDescriptions';
import { lowerFirst } from 'lodash-es';
import { isInterpolateMeshOperationValid } from './MeshInterpolationValidation';

/**
 * Transforms a request that gets available mesh interpolation operation descriptions for the workspace, takes into consideration  variables and returns a basic mesh interpolation operation description together with contextual descriptions for children for each supported interpolation type.
 *
 * @param workspaceId
 * @param variableIds List of variables to consider for contextualization.
 *
 *
 */
export const getContextualMeshInterpolationOperationDescriptions = (
  workspaceId: string,
  variableIds: Array<string>,
) => {
  return OperationsProxy.getMeshInterpolationOperationDescriptions(workspaceId, variableIds)
    .then((res) => {
      if (!res) {
        return Promise.resolve({});
      }

      const apiOperationDescriptions = res.data as Array<IOperationDescriptionApi>;

      if (!apiOperationDescriptions) {
        return null;
      }

      const mappedDescriptions = apiOperationDescriptions.reduce((acc, apiDescription) => {
        const key = lowerFirst(apiDescription.operationType);
        return {
          ...acc,
          [key]: self.mapInterpolationContextualDescriptions(apiDescription),
        };
      }, {});

      return mappedDescriptions;
    })
    .catch((error) =>
      HttpErrorHandler('Failed to get contextual mesh interpolation operation descriptions.', error),
    ) as Promise<{
    [operationType: string]: {
      basicOperationDescription: IOperationDescription;
      childContextualOperationDescriptions: IContextualOperationDescriptions;
    };
  }>;
};
/**
 * Maps interpolation operation descriptions received from the api to contextual descriptions
 * Basically group the child operation descriptions by allowedInputIds
 * @param apiOperationDescription
 */
export const mapInterpolationContextualDescriptions = (
  apiOperationDescription: IOperationDescriptionApi,
): {
  basicOperationDescription: IOperationDescription;
  childContextualOperationDescriptions: IContextualOperationDescriptions;
} => {
  const {
    childOperationDescriptions,
    postInterpolationDescriptions,
    ...basicOperationDescriptionApi
  } = apiOperationDescription;

  const mappedBasicOperation = OperationMappingUtils.mapOperationDescriptions([basicOperationDescriptionApi]);

  const mappedChildContextualOperationDescriptions = OperationMappingUtils.mapOperationDescriptionsToContextualDescriptions(
    childOperationDescriptions,
  );

  const mappedPostOperationContextualOperationDescriptions = OperationMappingUtils.mapOperationDescriptions(
    postInterpolationDescriptions,
  );

  const mappedDescriptions = {
    basicOperationDescription: mappedBasicOperation[Object.keys(mappedBasicOperation)[0]],
    childContextualOperationDescriptions: mappedChildContextualOperationDescriptions,
    postOperationDescription:
      mappedPostOperationContextualOperationDescriptions[
        Object.keys(mappedPostOperationContextualOperationDescriptions)[0]
      ],
  };
  return mappedDescriptions;
};

/**
 * Transforms a request that gets current interpolate mesh operations ( @see OperationsProxy ).
 *
 * @param workspaceId
 * @param meshId
 */
export const getCurrentInterpolateMeshOperations = (
  workspaceId: string,
  meshId: string,
): Promise<Array<IOperationConfiguration>> => {
  return OperationsProxy.getCurrentInterpolateMeshOperations(workspaceId, meshId)
    .then((res) => {
      const operationConfigurations = res.data.map((operation) =>
        OperationMappingUtils.mapOperationApiToOperationConfiguration(operation),
      );
      return operationConfigurations;
    })
    .catch((error) => HttpErrorHandler('Failed to get current interpolate mesh operations.', error));
};

/**
 * Transforms a request that performs an interpolate mesh operation on a mesh.
 *
 * @param workspaceId
 * @param interpolateMeshConfiguration
 */
export const executeInterpolateMeshOperation = (
  workspaceId: string,
  interpolateMeshConfiguration: IOperationConfiguration,
): Promise<IOperationConfiguration> => {
  if (!workspaceId || !interpolateMeshConfiguration) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  if (!isInterpolateMeshOperationValid(interpolateMeshConfiguration)) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  return OperationsManager.executeOperation(workspaceId, {
    ...interpolateMeshConfiguration,
  }).catch((error) => HttpErrorHandler('Failed to excute interpolate mesh operation.', error)) as Promise<
    IOperationConfiguration
  >;
};

/**
 * Transforms a request that saves an interpolate  mesh operation on a mesh without exceuting it.
 *
 * @param workspaceId
 * @param interpolateMeshConfiguration
 */
export const saveInterpolateMeshOperation = (
  workspaceId: string,
  interpolateMeshConfiguration: IOperationConfiguration,
): Promise<IOperationConfiguration> => {
  if (!workspaceId || !interpolateMeshConfiguration) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  if (!isInterpolateMeshOperationValid(interpolateMeshConfiguration)) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  return OperationsManager.createOperation(workspaceId, interpolateMeshConfiguration).catch((error) =>
    HttpErrorHandler('Failed to save interpolate mesh operation.', error),
  ) as Promise<IOperationConfiguration>;
};

/**
 * Transforms a request that gets a mesh interpolate operation by id.
 *
 * @param workspaceId
 * @param operationId
 */
export const getInterpolateMeshOperation = (
  workspaceId: string,
  operationId: string,
): Promise<IOperationConfiguration> => {
  if (!workspaceId || !operationId) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  return OperationsManager.getOperation(workspaceId, operationId);
};

const self = {
  getContextualMeshInterpolationOperationDescriptions,
  getCurrentInterpolateMeshOperations,
  executeInterpolateMeshOperation,
  saveInterpolateMeshOperation,
  getInterpolateMeshOperation,
  mapInterpolationContextualDescriptions,
};

export default self;
