/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DefaultIconSize, ToolbarBoxShadow } from '../../../shared/styles/styleConsts';
import MIKE_COLORS from '../../../MikeVisualizer/colors/mike-colors';
import mikeSharedTheme from '../../../shared/styles/mikeSharedTheme';

export const ViewerToolsButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  width: ${DefaultIconSize};
  height: ${DefaultIconSize};
  border: 0;
  padding: 0;
  background: 0;
  background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  transition: opacity 0.3s;
  font-size: 1.05em;
  font-weight: bold;
  position: relative;
  z-index: 1; /* Needs to be just on top of the submenu so it can be toggled off without having to choose a value. */

  &:focus,
  &:hover {
    outline: 0;
    opacity: 0.7;
    transition: opacity 0.3s;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[hidden] {
    display: none;
  }
`;

export const ViewerToolsButtonFluidStyle = css`
  width: auto;
  padding: 0 ${mikeSharedTheme.spacing(1)};
`;

export const ViewerToolsButtonActiveStyle = css`
  background-color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  color: white;

  & path {
    fill: currentColor;
  }
`;

export const ViewerToolsButtonActiveStrokedStyle = css`
  background-color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  color: white;

  & path {
    stroke: currentColor;
  }
`;

export const ViewerToolsWithSubmenuStyle = css`
  position: relative;
`;

export const ViewerToolsWithSubmenuMenuStyle = css`
  list-style-type: none;
  position: absolute;
  left: ${DefaultIconSize};
  top: 0;
  margin: 0 ${mikeSharedTheme.spacing(0.5)};
  display: flex;
  flex-direction: column;
  box-shadow: ${ToolbarBoxShadow};

  &[hidden] {
    display: none;
  }

  &[data-disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const ViewerToolsWithSubmenuMenuIndicatorStyle = css`
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
`;
