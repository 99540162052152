import React, { useState, useCallback } from 'react';
import { t } from '../../../translations/i18n';
import { isEqual, isEmpty } from 'lodash-es';
import { MmgNonHoverLayer } from '../../../shared/layers/mesh-non-hover-layer';
import { MmgItemCard } from '../../../shared/cards/item-card';
import GeometryIconUtils from '../../../geometries/geometry-icon-utils';
import { MmgOperationSelector } from '../../../operations/operation-selector';
import { MmgOperationConfiguration } from '../../../operations/operation-configuration';
import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import { IOperationDescription, IContextualOperationDescriptions } from '../../../models/IOperationDescriptions';
import { MmgPanelSubsection } from '../../../shared/panels/panel-subsection';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { createMeshChildConfigurationFromDescription } from '../mesh-configuration-util';
import { MESH_OPERATION_PARAMETER_CONDITIONS } from '../mesh-configuration-constants';
import { IWorkspaceQuery } from '../../../models/IQueries';
import { MmgConnectedMeshOperationQueriesConfigurations } from './mesh-operation-queries-configurations';
import { MmgMeshOperationGeometrySummary } from '../summary/mesh-operation-geometry-summary';
import { useFilterQueriesForGeometry } from '../useFilterQueriesForGeometry';
import { MmgMeshOperationQueriesSummaries } from '../summary/mesh-operation-queries-summaries';
import { anythingToConfigure } from '../../../operations/operation-utils';
import { MESH_OPERATION_CUSTOM_SETTINGS } from './mesh-operation-custom-settings';
import { getLayerEdgeColor, getLayerSurfaceColor } from '../../../shared/layers/layer-utils';
import WorkspaceDrawnDataSelectors from '../../../store/selectors/WorkspaceDrawnDataSelectors';
import { useSelector } from 'react-redux';
import { IDrawnDataItem } from '../../../models/IWorkspaceData';
import { MikeLayerColorComplication } from '../../../shared-components/mike-layer/complications/layer-color-complications';
import { LayerWithoutBorderStyle } from '../../../shared-components/mike-layer/layer-styles';

type MeshOperationGeometryConfigurationProps = {
  geometry: IWorkspaceEnrichedGeometry;
  initialGeometryConfiguration?: IOperationConfiguration;
  geometryOperationDescriptions: {
    [operationKey: string]: IOperationDescription;
  };
  workspaceQueries: Array<IWorkspaceQuery>;
  initialQueryOperationsConfigurations?: Array<IOperationConfiguration>;
  contextualQueryOperations: IContextualOperationDescriptions;
  workspaceVariables: Array<IWorkspaceEnrichedVariable>;
  onGeometryOperationConfigurationChanged?: (operationConfiguration: IOperationConfiguration) => void;
  onQueryOperationConfigurationsChanged?: (operationConfigurations: Array<IOperationConfiguration>) => void;
};


/**
 * @name MmgConnectedMeshOperationGeometryConfiguration
 * @summary Generic component that given a geometry and a list of mesh childoperation descriptions for that geometry, allows configuring the operation for the geometry.
 * Will supply the complete list of updated configuration objects via a callback.
 *
 * @param props
 */
export const MmgConnectedMeshOperationGeometryConfiguration = (props: MeshOperationGeometryConfigurationProps) => {
  const {
    geometry,
    workspaceQueries,
    workspaceVariables,
    initialGeometryConfiguration,
    geometryOperationDescriptions,
    contextualQueryOperations,
    initialQueryOperationsConfigurations,
    onGeometryOperationConfigurationChanged,
    onQueryOperationConfigurationsChanged,
  } = props;

  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceGeometriesByIds,
  );

  const [dirtyGeometryConfiguration, setDirtyGeometryConfiguration] = useState(initialGeometryConfiguration); // Object with current configuration of the geometry. This will be sent higher up the component chain and used for submission.
  const [queryConfigurations, setQueryConfigurations] = useState(initialQueryOperationsConfigurations); // Object with current configuration of saved queries realted to the geometry. This will be sent higher up the component chain and used for submission.
  const [isItemCardOpen, setIsItemCardOpen] = useState(false);
  const queriesForGeometry = useFilterQueriesForGeometry(geometry, workspaceQueries);
  const onItemCardOpenStateToggled = (isOpen: boolean) => {
    setIsItemCardOpen(isOpen);
  };

  const createGeometryConfigurationFromDescription = useCallback(
    (operationKey: string) => {
      const configuration = createMeshChildConfigurationFromDescription(
        operationKey,
        [geometry.id],
        geometryOperationDescriptions,
      );
      return configuration;
    },
    [geometryOperationDescriptions, geometry],
  );

  const onOperationChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const operationKey = event.target.value;
    const nextConfiguration = createGeometryConfigurationFromDescription(operationKey);
    setDirtyGeometryConfiguration(nextConfiguration);
    callOnGeometryOperationConfigurationChanged(nextConfiguration);
  };

  const onGeometryConfigurationChanged = (newGeometryConfiguration: IOperationConfiguration) => {
    const nextConfiguration = {
      ...newGeometryConfiguration,
      inputIds: [geometry.id],
    };
    setDirtyGeometryConfiguration(nextConfiguration);
    callOnGeometryOperationConfigurationChanged(newGeometryConfiguration);
  };

  const callOnGeometryOperationConfigurationChanged = useCallback(
    (newGeometryConfiguration) => {
      // only call back if configuration actually changed
      if (onGeometryOperationConfigurationChanged && !isEqual(newGeometryConfiguration, initialGeometryConfiguration)) {
        onGeometryOperationConfigurationChanged(newGeometryConfiguration);
      }
    },
    [onGeometryOperationConfigurationChanged, initialGeometryConfiguration],
  );

  const onQueryConfigurationsChanged = useCallback(
    (newQueryConfigurations: Array<IOperationConfiguration>) => {
      setQueryConfigurations(newQueryConfigurations);

      if (onQueryOperationConfigurationsChanged) {
        onQueryOperationConfigurationsChanged(newQueryConfigurations);
      }
    },
    [onQueryOperationConfigurationsChanged],
  );

  // if no supported operations exists, do not show anything
  if (!geometryOperationDescriptions || isEmpty(geometryOperationDescriptions)) {
    return null;
  }

  const operationKeys = geometryOperationDescriptions ? Object.keys(geometryOperationDescriptions) : [];

  const firstOperationDescription = (geometryOperationDescriptions
    ? geometryOperationDescriptions[Object.keys(geometryOperationDescriptions)[0]]
    : null) as IOperationDescription;

  const selectedOperationDescription = dirtyGeometryConfiguration
    ? geometryOperationDescriptions[dirtyGeometryConfiguration.operationType]
    : firstOperationDescription;

  const selectedOperationType = (selectedOperationDescription || {}).operationType;

  const allowToggle = anythingToConfigure(selectedOperationDescription);

  return (
    <MmgItemCard     
      openLabel={t('EDIT')}
      closeLabel={t('CLOSE')}
      cardLayer={
        // check here the icon
        /* TODO dan: this should show if the item on this layer is 'in progress' / show a spinner or failed, etc */
        <MmgNonHoverLayer
          layerId={geometry.id}
          layerName={geometry.name}
          leftIcon={GeometryIconUtils.getIcon(geometry)}
          cssProp={LayerWithoutBorderStyle}
          leftComplications={
            <MikeLayerColorComplication
              edgeColor={getLayerSurfaceColor(geometry, drawnWorkspaceGeometries)}
              surfaceColor={getLayerEdgeColor(geometry, drawnWorkspaceGeometries)}
            />
          }
        />
      }
      cardSummary={
        <>
          <MmgMeshOperationGeometrySummary
            operationConfiguration={dirtyGeometryConfiguration}
            showOperationName={true}
            workspaceVariables={workspaceVariables}
          />
          <MmgMeshOperationQueriesSummaries
            geometryQueries={queriesForGeometry}
            operationConfigurations={queryConfigurations}
            contextualOperationDescriptions={contextualQueryOperations}
            workspaceVariables={workspaceVariables}
          />
        </>
      }
      onOpenStateChanged={onItemCardOpenStateToggled}
      open={isItemCardOpen}
      canBeToggled={allowToggle}
    >
      <MmgPanelSubsection>
        <MmgOperationSelector
          label={t('SELECT_CREATE_MESH_OPERATION_LABEL')}
          placeholder={t('SELECT_CREATE_MESH_OPERATION').toLowerCase()}
          operationKeys={operationKeys}
          operationsLoading={false}
          operationsLoadingFailed={false}
          onOperationChanged={onOperationChanged}
          selectedOperationKey={selectedOperationType}
        />

        <MmgOperationConfiguration
          operationDescription={selectedOperationDescription}
          initialOperationConfiguration={dirtyGeometryConfiguration}
          onConfigurationChanged={onGeometryConfigurationChanged}
          parameterConditionDescriptions={MESH_OPERATION_PARAMETER_CONDITIONS}
          parameterSettings={MESH_OPERATION_CUSTOM_SETTINGS}
        />

        {/* Make sure to re-mount this comp each time an operationType is selected, hence the `.map`: */}
        {operationKeys.map((opKey) => {
          return (
            opKey === selectedOperationType && (
              <MmgConnectedMeshOperationQueriesConfigurations
                key={opKey}
                geometryQueries={queriesForGeometry}
                workspaceVariables={workspaceVariables}
                contextualOperationDescriptions={contextualQueryOperations}
                initialOperationConfigurations={queryConfigurations}
                operationType={selectedOperationType}
                onOperationConfigurationChanged={onQueryConfigurationsChanged}
                dirtyParentConfiguration={dirtyGeometryConfiguration}
              />
            )
          );
        })}
      </MmgPanelSubsection>
    </MmgItemCard>
  );
};
