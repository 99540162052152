import { useCallback } from 'react';
import { IWorkspaceOptionAttributeValue } from '../../models/IWorkspaceAttributes';
import { MmgQueryCriteriaOptionsInput } from './query-criteria-options-input';
import { IQueryDefinitionApi, IPersistedQueryDefinitionApi } from '../../models/IQueryDefinitions';

type PersistedSelectionQueryCriteriaProps = {
  queryDefinition?: IQueryDefinitionApi;
  getPersistedSelectionCriteriaValues?: () => Array<IWorkspaceOptionAttributeValue>;
  onQueryParametersChanged?: (parameters: { [param: string]: number | string }) => void;
  canPreviewQuery?: boolean;
  onPreviewQuery?: () => void;
};

/**
 * Renders a query criteria for a persisted selection (as a dropdown)
 * @param props
 */
export const MmgPersistedSelectionQueryCriteria = (props: PersistedSelectionQueryCriteriaProps) => {
  const {
    queryDefinition,
    onQueryParametersChanged,
    getPersistedSelectionCriteriaValues,
    canPreviewQuery,
    onPreviewQuery,
  } = props;

  const optionValues = getPersistedSelectionCriteriaValues ? getPersistedSelectionCriteriaValues() : null;

  const callOnQueryParametersChanged = useCallback(
    (parameters: { [param: string]: number | string }) => {
      if (onQueryParametersChanged) {
        onQueryParametersChanged(parameters);
      }
    },
    [onQueryParametersChanged],
  );

  const onSelectedOptionChanged = (newVal: string | number) => {
    callOnQueryParametersChanged({
      queryId: newVal,
    });
  };

  if (!optionValues) {
    return null;
  }

  // options are send to the api as range queries with fromValue === toValue.
  const { queryId: value } = (queryDefinition || {}) as IPersistedQueryDefinitionApi;

  return (
    <MmgQueryCriteriaOptionsInput
      value={value}
      optionValues={optionValues}
      onSelectedOptionChanged={onSelectedOptionChanged}
      onPreviewQuery={onPreviewQuery}
      canPreviewQuery={canPreviewQuery}
    />
  );
};
