import { orderBy as _orderBy } from 'lodash-es';
import { IColumn } from '../../TableComponents/support';

export const getPadding = (col: IColumn) => (col.disablePadding ? 'none' : 'normal');

export const getSortedPageRows = (
  data: Array<any>,
  orderBy: string | ((item: any) => string | number),
  orderDir: 'asc' | 'desc',
  page?: number,
  rowsPerPage?: number
) => {
  const sortedData = _orderBy(data, orderBy, orderDir);
  return rowsPerPage && page !== undefined
    ? getPageRows(page, rowsPerPage, sortedData)
    : sortedData;
};

const getPageRows = (page: number, rowsPerPage: number, data: Array<any>) =>
  data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

export const getRender = (col: IColumn) => col.render || ((value) => value);
