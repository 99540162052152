export enum EGroupIds {
  WORKSPACE_DETAILS_MESH = 'WORKSPACE_DETAILS_MESH',
  WORKSPACE_DETAILS_GEOMETRY = 'WORKSPACE_DETAILS_GEOMETRY',
  WORKSPACE_DETAILS_VARIABLE = 'WORKSPACE_DETAILS_VARIABLE',
}

export const GROUP_IDS = {
  WORKSPACE_DETAILS_MESH: EGroupIds.WORKSPACE_DETAILS_MESH,
  WORKSPACE_DETAILS_GEOMETRY: EGroupIds.WORKSPACE_DETAILS_GEOMETRY,
  WORKSPACE_DETAILS_VARIABLE: EGroupIds.WORKSPACE_DETAILS_VARIABLE,
};
