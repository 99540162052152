/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { t } from '../../translations/i18n';
import { Drawer } from '@mui/material';
import { MmgProjectCreatePanel } from '../../projects/project-create-panel';
import { useSelector } from 'react-redux';
import { store } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import PlatformManager from '../../managers/PlatformManager';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { mikePalette } from '../../shared/styles/mikeSharedTheme';
import { IGlobalState } from '../../store/reducers';
import MikeButton from '../../shared-components/mike-button';

export const ButtonStyle = css`
  padding: 0;
  padding-right: 12;
  border: 2px solid ${mikePalette.secondary.main};
  color: ${mikePalette.background.default};
  &[disabled] {
    color: ${mikePalette.background.paper};
    opacity: 0.5;
    cursor: not-allowed;
    border: 2px solid ${mikePalette.secondary.light};
  }
`;

const FolderCreateDrawerContentStyle = css`
  width: 100%;
  z-index: 1;
  @media (min-width: 460px) {
    width: 400px;
  }
`;

const FolderCreateStyle = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const MmgConnectedCreateFolder = () => {
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [, setCreateProjectOpen] = useState(false);

  const { projectId } = useParams();
  const tenantId: string = useSelector((state: IGlobalState) => state.UserReducer.user.tenantId);
  const capabilities = useSelector((state: IGlobalState) => state.AppReducer.capabilities);

  const targetProjectId = projectId ? projectId : tenantId;

  const canCreate = capabilities && capabilities.canCreateContent;

  useEffect(
    () => {
      PlatformManager.getTenantCapabilities(targetProjectId).then((resp) => {
        store.dispatch({
          type: 'app/tenant/SET_CAPABILITIES',
          capabilities: resp,
        });
      });
    },
    [targetProjectId],
  );

  const onFolderCreated = (createdProject) => {
    const route = getRouteByPath(ROUTES.workspaceList.path, {
      projectId: createdProject.id,
    });
    navigate(route);
    setCreateProjectOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div css={FolderCreateStyle}>
      <MikeButton variant="contained" css={ButtonStyle} onClick={toggleDrawer} disabled={!canCreate}>
        {t('FOLDER_CREATE_BUTTON')}
      </MikeButton>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <div css={FolderCreateDrawerContentStyle}>
          <MmgProjectCreatePanel
            onProjectCreated={onFolderCreated}
            onPanelExit={toggleDrawer}
            targetProjectId={targetProjectId}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default MmgConnectedCreateFolder;
