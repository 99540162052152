import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from '../../translations/i18n';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { MmgPanelHeader } from '../../shared/panels/panel-header';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';
import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgConnectedVariableSelectList } from '../select-list/variable-select-list';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import { EWorkspaceGeometryActionType } from '../../store/actions/WorkspaceGeometryActionType';
import { EWorkspaceMeshActionType } from '../../store/actions/WorkspaceMeshActionType';
import { IGlobalState } from '../../store/reducers';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import MikeButton from '../../shared-components/mike-button';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';

/**
 * @name MmgConnectedVariableOperationSelectVariablesPanel
 * @summary Allows selecting multiple geometries to transform by vector operations.
 *
 */
export const MmgConnectedVariableOperationSelectVariablesPanel = () => {
  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate();
  const { hiddenWorkspaceVariables, selectedWorkspaceVariables } = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer,
  );
  const drawnWorkspaceVariables: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceVariablesByIds,
  );
  const variables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );

  const anyAvailableVariables = variables && variables.length > 0;
  const isSelectionValid = selectedWorkspaceVariables && selectedWorkspaceVariables.length > 0;

  const clearSelectedVariables = () => {
    store.dispatch({ type: 'workspace/variables/DESELECT_ALL' });
  };

  const onPanelExit = () => {
    clearSelectedVariables();
    navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));   
  }
  
  const onNext = () => {
    navigate(getRouteByPath(ROUTES.variablesMultipleOperationPanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
  };

  // Make sure only geometries are shown in the viewer when this component is mounting.
  // Whatever geometries are currently shown, will be kept visibile.
  useEffect(() => {
    store.dispatch({
      type: EWorkspaceMeshActionType.HIDE_ALL,
    });
    store.dispatch({
      type: EWorkspaceGeometryActionType.HIDE_ALL,
    });
  }, []);

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader panelTitle={t('SELECT_VARIABLES_TO_TRANSFORM_PANEL')} onPanelExit={onPanelExit} />
      </MikeStickyPanelHeaderContainer>
      <MikeStickyPanelContent>
        <MmgGroup groupName={t('VARIABLE', 2)}>
          {anyAvailableVariables && (
            <MmgConnectedVariableSelectList
              variables={variables}
              hiddenVariables={hiddenWorkspaceVariables}
              drawnVariables={drawnWorkspaceVariables}
              selectedVariables={selectedWorkspaceVariables}
              isFailedSelectable={true}
            />
          )}

          {!anyAvailableVariables && (
            <MmgPanelSubsection>
              <p>{t('NO_VAR_TIP')}</p>

              <MikeButton variant="contained" color="secondary" onClick={onPanelExit} fullWidth>
                {t('GOT_IT')}
              </MikeButton>
            </MmgPanelSubsection>
          )}
        </MmgGroup>
      </MikeStickyPanelContent>
      <MikeStickyPanelBottomActions>
        <MikeButton color="secondary" variant="contained" disabled={!isSelectionValid} onClick={onNext} fullWidth>
          {t('GO_TO_VARIABLES_OPERATION')}
        </MikeButton>
      </MikeStickyPanelBottomActions>
    </MikeStickyPanel>
  );
};
