import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MmgQueryCriteriaOptionsInput } from './query-criteria-options-input';
import { IQueryDefinitionApi } from '../../models/IQueryDefinitions';
import { IWorkspaceOptionAttributeValue } from '../../models/IWorkspaceAttributes';
import { getPolygonGeometryItems } from './attribute-utils';
import { FeatureCollection } from 'geojson';
import { fetchLayer, showLayer } from '../../shared/layers/layer-utils';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { IGlobalState } from '../../store/reducers';
import { IWorkspaceGeometry } from '../../models/IGeometries';

interface GeometryCriteriaProps {
  queryDefinition?: IQueryDefinitionApi;
  onQueryParametersChanged?: (parameters: { [param: string]: number | string | FeatureCollection<any, any> }) => void;
}

/**
 * Renders a query criteria for a geometry (as a dropdown)
 * @param props
 */
export const MmgGeometriesQueryCriteria = (props: GeometryCriteriaProps) => {
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const workspaceGeometries: Array<IWorkspaceGeometry> = useSelector((state: IGlobalState) =>
    state.WorkspaceGeometryReducer.workspaceGeometries);

  const { queryDefinition, onQueryParametersChanged } = props;
  const geometryItems = useMemo(() => {
    return getPolygonGeometryItems(workspaceGeometries);
  }, [workspaceGeometries])

  const optionValues: IWorkspaceOptionAttributeValue[] = geometryItems.map((geoItem: any) => {
    return {
      value: geoItem.id || '<NO_ID>',
      displayName: geoItem.name || '<NO_NAME>',
    };
  });

  const sourceItemId = useMemo(
    () => {
      return queryDefinition && queryDefinition.sourceItemId ? queryDefinition.sourceItemId : '';
    },
    [queryDefinition],
  );

  useEffect(
    () => {
      if (sourceItemId) {
        onSelectedOptionChanged(sourceItemId);
      }
      /*       return () => {
        deleteData(SPATIALQUERYRESULT);
      }; */
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSelectedOptionChanged = useCallback(
    (newVal: any) => {
      if (loadedData.includes(newVal)) {
        showLayer(ELEMENT_CATEGORIES.GEOMETRY, newVal, false);
      } else {
        fetchLayer(newVal, ELEMENT_CATEGORIES.GEOMETRY, false, false);
      }
      onQueryParametersChanged({ sourceItemId: newVal });
    },
    [loadedData, onQueryParametersChanged],
  );

  if (!geometryItems) {
    return null;
  }

  return (
    <MmgQueryCriteriaOptionsInput
      value={sourceItemId}
      optionValues={optionValues}
      onSelectedOptionChanged={onSelectedOptionChanged}
      label={'Geometries'}
    />
  );
};
