/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { PanelBodyStyle } from './panel-styles';
import mikeSharedTheme from '../styles/mikeSharedTheme';

export const PanelSubsectionStyle = css`
  padding: ${mikeSharedTheme.spacing(2)};
`;

type MmgPanelSubsectionProps = {
  children?: ReactNode;
  css?: any;
};

/**
 * @name MmgPanelSubsection
 * @summary A generic container of a panel with the correct box model. Typically holds short messages like 'Mesh deleted', 'Loading', etc.
 * It fits items into the correct box model, gives them padding, margin, etc.
 *
 * @param props
 */
export function MmgPanelSubsection(props: MmgPanelSubsectionProps) {
  const {children, css: cssProp} = props;
  return (
    <div css={css`${PanelBodyStyle} ${PanelSubsectionStyle} ${cssProp && cssProp}`}>{children}</div>
  );
}
