/** @jsxImportSource @emotion/react */
import { MmgStepper } from '../../shared/steppers/stepper';
import { MmgStep } from '../../shared/steppers/step';
import { stepperExtractionStyle } from '../../shared/steppers/stepper-styles';

type GeometryExtractStepperProps = {
  activeStep: number;
};

// eslint-disable-next-line react-refresh/only-export-components
export const GEOMETRY_EXTRACT_STEPS = {
  SELECT: 0,
  EXTRACT: 1,
};

const GEOMETRY_EXTRACT_STEPS_VALUES = {
  SELECT: 'STEP_SELECT',
  EXTRACT: 'STEP_EXTRACT',
};

/**
 * @name MmgGeometryExtractStepper
 * @summary The stepper used for geometry extraction panels.
 *
 * @param props
 */
export const MmgGeometryExtractStepper = (props: GeometryExtractStepperProps) => {
  const { activeStep } = props;

  return (
    <MmgStepper cssProp={stepperExtractionStyle} activeStep={activeStep}>
      <MmgStep key={GEOMETRY_EXTRACT_STEPS_VALUES.SELECT} index={0} />
      <MmgStep key={GEOMETRY_EXTRACT_STEPS_VALUES.EXTRACT} index={1} />
    </MmgStepper>
  );
};
