import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getRouteByPath, ROUTES } from '../app/routes';
import { MmgPanelHeader } from '../shared/panels/panel-header';
import { t } from '../translations/i18n';
import { LinearProgress } from '@mui/material';
import { useNavigateBack } from '../app/navigation/useNavigateBack';
import { MmgGroup } from '../shared/groups/group';
import { MmgStepper } from '../shared/steppers/stepper';
import { MmgStep } from '../shared/steppers/step';
import { MmgStepContent } from '../shared/steppers/step-content';
import { useStepper } from '../shared/steppers/useStepper';
import { IGlobalState } from '../store/reducers';
import MikeStickyPanel from '../shared-components/mike-sticky-panel';
import MikeStickyPanelContent from '../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import { MikeStickyPanelHeaderContainer } from '../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import { MikeStickyPanelBottomActions } from '../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../shared-components/mike-button';
import { MmgConnectedWorkspaceOperationsSection } from './workspace-operation-section';
import { MmgConnectedWorkspaceSelectLists } from './workspace-select-lists';

/**
 * @name MmgConnectedWorkspaceOperationPanel
 * @summary Allows performing 'geometry operation' on the current geometry, former referred to as 'transforms'.
 *
 * If the geometry does not exists, it will redirect to geometry details panel
 *
 */
export const MmgConnectedWorkspaceOperationPanel = () => {
  const { projectId, workspaceId } = useParams();
  const navigate = useNavigate();

  const workspaceLoading: boolean = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspaceLoading);
  const selectedWorkspaceMeshes = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer.selectedWorkspaceMeshes,
  );
  const selectedWorkspaceGeometries = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer.selectedWorkspaceGeometries,
  );
  const selectedWorkspaceVariables = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer.selectedWorkspaceVariables,
  );

  const { goBackToReferrer } = useNavigateBack();

  const selectedItems = selectedWorkspaceMeshes.concat(selectedWorkspaceGeometries, selectedWorkspaceVariables);

  const WORKSPACE_OPERATION_STEPS = {
    SELECT_ELEMENTS: 0,
    CONFIGURE: 1,
  };

  const { activeStep, handleNext } = useStepper(WORKSPACE_OPERATION_STEPS.SELECT_ELEMENTS);

  const title = t('WORKSPACE_OPERATION_PANEL');

  // When an operation is submitted succesfully we currently navigate to workspace panel
  const onOperationSubmitted = (success: boolean) => {
    if (success) {
      // always go to workspace panel on success, since all geoemtry operations currently will create a new geometry 
      navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));   
    }
  };

  const onPanelExit = () => {
    goBackToReferrer();
  };

  return (
    <>
      <MmgStepper activeStep={activeStep}>
        <MmgStep key={'STEP_SELECT_ELEMENTS'} index={0} />
        <MmgStep key={'STEP_CONFIGURE'} index={1} last={true} />
      </MmgStepper>

      <MikeStickyPanel>
        <MikeStickyPanelContent>
          <MmgStepContent step={WORKSPACE_OPERATION_STEPS.SELECT_ELEMENTS} activeStep={activeStep} index={0}>
            <MmgGroup groupName={'Select elements'} canBeHidden={false}>
              <MmgConnectedWorkspaceSelectLists />
            </MmgGroup>
          </MmgStepContent>

          <MmgStepContent step={WORKSPACE_OPERATION_STEPS.CONFIGURE} activeStep={activeStep} index={1}>
            <MikeStickyPanelHeaderContainer>
              <MmgPanelHeader panelTitle={title} />
              {workspaceLoading && <LinearProgress />}
            </MikeStickyPanelHeaderContainer>

            <MmgConnectedWorkspaceOperationsSection
              projectId={projectId}
              workspaceId={workspaceId}
              onOperationSubmitted={onOperationSubmitted}
              selectedItems={selectedItems}
            />
          </MmgStepContent>
        </MikeStickyPanelContent>
        <MikeStickyPanelBottomActions>
          {activeStep !== WORKSPACE_OPERATION_STEPS.CONFIGURE && (
            <>
              <MikeButton variant="contained" color="secondary" onClick={onPanelExit}>
                {t('CANCEL')}
              </MikeButton>
              <MikeButton variant="contained" onClick={handleNext} disabled={selectedItems.length === 0}>
                {t('NEXT')}
              </MikeButton>
            </>
          )}

          {activeStep === WORKSPACE_OPERATION_STEPS.CONFIGURE && <></>}
        </MikeStickyPanelBottomActions>
      </MikeStickyPanel>
    </>
  );
};
