/**
 * Exposes methods to handle workspace comments requests.
 *
 * @module WorkspaceCommentsManager
 * @deprecated See the same file in ./src
 *
 * @version 1.0.0
 * @requires WorkspaceCommentsProxy
 *
 *  todo hevo Also include this in v2 managers!
 */
import * as WorkspaceCommentsProxy from '../proxies/WorkspaceCommentsProxy';

import { HttpErrorHandler } from './http-utils';
import { IWorkspaceComment } from '../models/IComments';

/**
 * Creates a comment in the workspace.
 *
 * @param workspaceId
 * @param message
 * @param coordinate
 */
const createComment = (workspaceId: string, message: string, coordinate: Array<number>) => {
  const comment = {
    message,
    coordinate,
  };

  return WorkspaceCommentsProxy.createComment(workspaceId, comment)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to create comment.', error));
};

/**
 * Deletes a comment.
 *
 * @param workspaceId
 * @param commentId
 */
const deleteComment = (workspaceId: string, commentId: string) => {
  return WorkspaceCommentsProxy.deleteComment(workspaceId, commentId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to delete comment.', error));
};

/**
 * Get all comments in the workspace
 * @param workspaceId
 */
const getComments = (workspaceId: string): Promise<IWorkspaceComment> => {
  return WorkspaceCommentsProxy.getComments(workspaceId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get comments.', error));
};

const self = {
  createComment,
  deleteComment,
  getComments,
};

export default self;
