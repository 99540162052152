/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Typography } from '@mui/material';
import LandingLayout from './LandingLayout';
import { useOidc } from '@axa-fr/react-oidc';
import MikeButton from '../mike-button';
import { t } from '../../translations/i18n';


const containerStyle = 
  css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

type Props = {
  headline: string;
  body: string;
  showRetryButton?: boolean;
};

const OpenIdStatusPage = ({ headline, body, showRetryButton }: Props) => {
  const { login } = useOidc();
  return (
    <LandingLayout>
      <div css={containerStyle}>        
        <Typography variant="h2">{headline}</Typography>        
        <Typography variant="body2">{body}</Typography>
        {showRetryButton && (
          <MikeButton color="secondary" variant="contained" onClick={() => login('/')}>
             {t('LOGIN_RETRY')}
          </MikeButton>
        )}
      </div>
    </LandingLayout>
  );
};

export default OpenIdStatusPage;
