/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { translateWithPrefix } from '../../translations/utils';
import { IWorkspaceAttribute } from '../../models/IWorkspaceAttributes';
import { t } from '../../translations/i18n';
import { TextField } from '@mui/material';
import { QUERY_DEFINITION_TYPES } from '../../models/IQueryDefinitions';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

/* todo: dan this is needed to align with lit-parameters. maybe we can remove when refactoring parameters to React (& we use MUI for all inputs) */
const SelectWrapperStyle = css`
  margin-bottom: ${mikeSharedTheme.spacing(1)};
`;

type QueryCriteriaSelectorProps = {
  queryCriterias?: Array<IWorkspaceAttribute>; // todo hevo shold be made more generic - not all criterias are attributes
  label?: string;
  placeholder?: string;
  allowSelectAll?: boolean;
  selectAllLabel?: string;
  selectedCriteria?: string;
  onSelectedCriteriaChanged?: (event) => void;
};

/**
 * Renders a drop down for selection of query criteria
 *
 * @param props
 */
export const MmgQueryCriteriaSelector = (props: QueryCriteriaSelectorProps) => {
  const {
    label: labelProps,
    placeholder: placeholderProps,
    queryCriterias,
    allowSelectAll,
    selectAllLabel: selectAllLabelProps,
    selectedCriteria,
    onSelectedCriteriaChanged,
  } = props;
  const criteriasAvailable = Boolean(queryCriterias && queryCriterias.length) || allowSelectAll;

  if (!criteriasAvailable) {
    return null;
  }

  const label = labelProps || t('SELECT_QUERY_CRITERIA_LABEL'); // todo hevo add to translatiosn!!!!!!!
  const placeholder = placeholderProps || t('SELECT_QUERY_CRITERIA_PLACEHOLDER');
  const selectAllLabel = selectAllLabelProps || t('SELECT_ALL');

  return (
    <div css={SelectWrapperStyle}>
      {criteriasAvailable && (
        <TextField
          id="select-criteria"
          select
          label={label}
          value={selectedCriteria || ''}
          onChange={onSelectedCriteriaChanged}
          fullWidth
          SelectProps={{
            displayEmpty: true,
          }}
          InputLabelProps={{ shrink: true }}
        >
          <option value="" disabled>
            {placeholder}
          </option>

          {allowSelectAll && (
            <option key={QUERY_DEFINITION_TYPES.SELECT_ALL} value={QUERY_DEFINITION_TYPES.SELECT_ALL}>
              {selectAllLabel}
            </option>
          )}

          {queryCriterias &&
            queryCriterias.map((attribute) => {
              const displayName = translateWithPrefix('PROP', attribute.name);
              return (
                <option key={attribute.name} value={attribute.name}>
                  {displayName}
                </option>
              );
            })}
        </TextField>
      )}
    </div>
  );
};
