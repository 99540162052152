/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';

type IProps = {
  currentLoad: number;
  totalLoad: number;
};

const loaderStyle = css`
  display: flex;
  width: 100vw;
  height: ${mikeSharedTheme.spacing(0.5)};
`;

const loaderGraphicStyle = css`
  height: 100vh;
  flex-shrink: 1;
`;

const loaderProgressStyle = css`
  background-color: ${mikeSharedTheme.palette.secondary.main};
`;

const loaderBackgroundStyle = css`
  background-color: ${mikeSharedTheme.palette.mediumGrey.main};
`;

const MmgViewerLinearProgress = (props: IProps) => {
  const { currentLoad, totalLoad } = props;

  return (
    <div css={loaderStyle}>
      <div css={`${loaderGraphicStyle} ${loaderProgressStyle}`} style={{ flexGrow: currentLoad }} />
      <div css={`${loaderGraphicStyle} ${loaderBackgroundStyle}`} style={{ flexGrow: totalLoad - currentLoad }} />
    </div>
  );
};

export default MmgViewerLinearProgress;
