import { call, put, race, select, take } from 'redux-saga/effects';
import { hiddenGeometryIds, hiddenMeshIds, hiddenVariableIds } from './selectors';
import { t } from '../../translations/i18n';
import { IWorkspaceSocketManager } from '../../models/ISocketManager';
import { isEqual } from 'lodash-es';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import MikeVisualizerViewManager from '../../MikeVisualizer/lib/MikeVisualizerViewManager';
import { IWorkspaceMeshTiles } from '../../store/reducers/WorkspaceMeshTilesReducer';
import { getStreamingIds, getWorkspaceDataByItemId, getWorkspaceMeshTilesById } from '../../store/selectors/WorkspaceDrawnDataSelectors';

/**
 * handle a request for streaming data
 * @param payload
 * @param socketManager
 */
export function* handleStreamDataRequest(payload, socketManager: IWorkspaceSocketManager) {
  const { identity, itemId, dataId, excludeDataArrays, updated, isTiled } = payload;

  const geom: Array<string> = yield select(hiddenGeometryIds);
  const mesh: Array<string> = yield select(hiddenMeshIds);
  const vari: Array<string> = yield select(hiddenVariableIds);

  const hiddens = geom.concat(mesh, vari);

  if (hiddens.includes(itemId)) {
    return;
  }

  const shouldLoad = yield shouldLoadData(itemId, updated, excludeDataArrays);
  if (!shouldLoad) {
    return;
  }

  // start streaming
  yield call(streamData, socketManager, itemId, dataId, excludeDataArrays, isTiled);

  // wait for stream to complete or fail before moving on to the next
  const { failed } = yield race({
    completed: take((act) => act.type === WorkspaceActionType.LOAD_DATA_COMPLETED && isEqual(act.identity, identity)),
    failed: take((act) => act.type === WorkspaceActionType.LOAD_DATA_FAILED && isEqual(act.identity, identity)),
  });

  if (failed) {
    // todo hevo better error handling should be added. Eg including the name of failed item, or setting the item in failed state and stop spinner
    put({
      type: 'toast/ADD/ERROR',
      toast: { text: t('WORKSPACE_ITEM_FAILED_LOADING') },
    });
  }
}

/**
 * Invoke streaming data for a specific item
 * @param socketManager
 * @param itemId
 * @param dataId
 * @param excludeDataArrays
 * @param isTiled
 */
function* streamData(
  socketManager: IWorkspaceSocketManager,
  itemId: string,
  dataId: string,
  excludeDataArrays: boolean,
  isTiled?: boolean,
) {
  try {
    if (socketManager) {
      if (isTiled) {
        const streamingIds = yield select(getStreamingIds);
        if (!streamingIds.includes(itemId)) {
          const mtb = yield select(getWorkspaceMeshTilesById, { itemId });
          const overview = mtb ? mtb.find((b: IWorkspaceMeshTiles) => b.isOverview) : undefined;
          const previousBounds = mtb
            ? mtb.filter((b: IWorkspaceMeshTiles) => !b.isOverview).map((b: IWorkspaceMeshTiles) => b.bounds)
            : [];
          const { getCurrentViewBounds } = MikeVisualizerViewManager;
          const bounds = getCurrentViewBounds();
          const { streamMesh } = socketManager;
          streamMesh(itemId, bounds, previousBounds, overview !== undefined);
        }
      } else {
        const { streamVtkFileData } = socketManager;
        if (streamVtkFileData) {
          yield call(streamVtkFileData, itemId, dataId, excludeDataArrays);
        }
      }
    }
  } catch (error) {
    // todo hevo how to handle??
    console.error('streaming data failed', error);
  }
}

/**
 * Examines if more recent data is already loaded.
 * @param itemId
 * @param updated
 * @param excludeDataArrays
 */
function* shouldLoadData(itemId: string, updated: string, excludeDataArrays: boolean) {
  const existingDataItem: IDrawnDataItem = yield select(getWorkspaceDataByItemId, {
    itemId,
  });

  // If we don't have the data or don't know when data was updated, just load.
  if (!existingDataItem || !existingDataItem.updated) {
    return true;
  }

  // If existing dataItem was loaded without dataArrays and we now want to include these, load
  if (!excludeDataArrays) {
    if (!existingDataItem.dataArrays) {
      return true;
    }
  }

  const newDate = new Date(updated);
  const existingDate = new Date(existingDataItem.updated);

  // If new data are more recent, load
  if (newDate > existingDate) {
    return true;
  }

  // no reason to laod
  return false;
}
