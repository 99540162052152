import { useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import WorkspaceVariableSelectors from '../../../store/selectors/WorkspaceVariableSelectors';

import { EElementCategories } from '../../../shared/panels/mesh-panel-constants';

import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';

import { getToggleVisibilityLayerAction, ILayerAction } from '../../../shared/layers/actions/layer-default-actions';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { MmgConfirmDeleteVariableDialog } from '../../../variables/details/variable-delete-dialog';
import { IDrawnDataItem } from '../../../models/IWorkspaceData';
import { IGlobalState } from '../../../store/reducers';

type LayerVariableSelectActionsProps = {
  layerId: string;
  variable: IWorkspaceEnrichedVariable;
  canToggleLayerVisibility?: boolean;
  hiddenVariableIds?: Array<string>;
  updateUserSettingsOnToggle?: boolean;
  variableDrawnData?: IDrawnDataItem;
};

/**
 * @name MmgConnectedLayerVariableSelectActions
 * @summary Variable layer actions, made for items present in the select list.
 *
 * @param props
 */
export function MmgConnectedLayerVariableSelectActions(props: LayerVariableSelectActionsProps) {
  const {
    layerId,
    variable,
    hiddenVariableIds: hiddenVariableIdsProp,
    variableDrawnData: variableDrawnDataProp,
    canToggleLayerVisibility = true,
    updateUserSettingsOnToggle = false,
  } = props;

  const { projectId, workspaceId } = useParams();

  const getDrawnDataSelectorInstance = WorkspaceVariableSelectors.makeGetVariableDrawnData();
  const drawnData = useSelector((state: IGlobalState) => getDrawnDataSelectorInstance(state, { variableId: layerId }));
  const variableDrawnData = variableDrawnDataProp ? variableDrawnDataProp : drawnData;
  const hiddenIds = useSelector((state: IGlobalState) => state.WorkspaceVariableReducer.hiddenWorkspaceVariables);
  const hiddenVariableIds = hiddenVariableIdsProp ? hiddenVariableIdsProp : hiddenIds;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const isMissing = !variable;

  const layerActions = getLayerActions(
    projectId,
    workspaceId,
    variable,
    variableDrawnData,
    canToggleLayerVisibility,
    hiddenVariableIds,
    updateUserSettingsOnToggle,
  );

  return (
    <>
      <MikeLayerActionMenu layerId={layerId} layerActions={layerActions} disabled={isMissing} />

      <MmgConfirmDeleteVariableDialog
        open={deleteConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        variable={variable}
        onClose={() => setDeleteConfirmOpen(false)}
      />
    </>
  );
}

const getLayerActions = (
  _projectId: string,
  _workspaceId: string,
  variable: IWorkspaceEnrichedVariable,
  variableDrawnData: IDrawnDataItem,
  canToggleLayerVisibility: boolean,
  hiddenVariableIds: Array<string>,
  updateUserSettingsOnToggle: boolean,
) => {
  if (!variable) {
    return [];
  }

  const layerId = variable.id;
  const layerActions = [];

  if (canToggleLayerVisibility) {
    const toggleVisibilityAction = getToggleVariableVisibilityAction(
      layerId,
      hiddenVariableIds,
      updateUserSettingsOnToggle,
      // eslint-disable-next-line no-extra-boolean-cast
      !Boolean(variableDrawnData),
    );
    layerActions.push(toggleVisibilityAction);
  }

  return layerActions;
};

// eslint-disable-next-line react-refresh/only-export-components
export const getToggleVariableVisibilityAction = (
  layerId: string,
  hiddenVariableIds,
  updateUserSettings: boolean,
  disabled?: boolean,
): ILayerAction => {
  const toggleVisibilityAction = getToggleVisibilityLayerAction(
    EElementCategories.VARIABLE,
    layerId,
    hiddenVariableIds,
    disabled,
    updateUserSettings,
  );

  return toggleVisibilityAction;
};
