import { MmgMeshOperationGeometriesConfigurations } from '../../../meshes/configure/configuration/mesh-operation-geometries-configurations';

import { MmgGroup } from '../../../shared/groups/group';

import { IWorkspaceEnrichedGeometry } from '../../../models/IGeometries';
import { IContextualOperationDescriptions } from '../../../models/IOperationDescriptions';
import { IOperationConfiguration } from '../../../models/IOperations';
import { IWorkspaceEnrichedVariable } from '../../../models/IVariables';
import { IWorkspaceQuery } from '../../../models/IQueries';

type MeshOperationGeometriesConfigurationsGroupProps = {
  workspaceId: string;
  groupName: string;
  geometries: Array<IWorkspaceEnrichedGeometry>;
  workspaceQueries: Array<IWorkspaceQuery>;
  workspaceVariables: Array<IWorkspaceEnrichedVariable>;
  initialOperationConfigurations?: Array<IOperationConfiguration>;
  contextualOperationDescriptions: IContextualOperationDescriptions;
  onConfigurationsChanged: (operationConfigurations: Array<IOperationConfiguration>) => void;
};

/**
 * @name MmgMeshOperationGeometriesConfigurationsGroup
 * @summary Wraps the operation geometry configurations component in a group and propagates changes to configuration to a parent.
 *
 * @param props
 */
export const MmgMeshOperationGeometriesConfigurationsGroup = (
  props: MeshOperationGeometriesConfigurationsGroupProps,
) => {
  const {
    workspaceId,
    groupName,
    geometries,
    workspaceQueries,
    workspaceVariables,
    initialOperationConfigurations,
    contextualOperationDescriptions,
  } = props;

  /**
   * Callback for when operations configurations on geoemtries or queries have changed.
   *
   * @param nextOperationConfigurations
   */
  const onConfigurationsChanged = (nextOperationConfigurations: Array<IOperationConfiguration>) => {
    props.onConfigurationsChanged(nextOperationConfigurations);
  };

  return (
    <MmgGroup groupName={groupName} canBeHidden={false}>
      <MmgMeshOperationGeometriesConfigurations
        workspaceId={workspaceId}
        geometries={geometries}
        workspaceQueries={workspaceQueries}
        workspaceVariables={workspaceVariables}
        contextualOperationDescriptions={contextualOperationDescriptions}
        initialOperationConfigurations={initialOperationConfigurations}
        onConfigurationsChanged={onConfigurationsChanged}
      />
    </MmgGroup>
  );
};
