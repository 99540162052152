/* eslint-disable react-refresh/only-export-components */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { t } from '../translations/i18n';
import { getRouteByPath, ROUTES } from '../app/routes';
import { MikeLayerActionMenu } from '../shared/layers/actions/layer-action-menu';
import { ILayerAction } from '../shared/layers/actions/layer-default-actions';
import Operations from '../icons/Operations.svg?react';
import Voronoi from '../icons/Voronoi.svg?react';
import FolderZip from '../icons/FolderZip.svg?react';
import { store } from '../store';
import { WorkspaceActionType } from './store/WokspaceActionType';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { IWorkspace } from '../models/IWorkspaces';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../MikeVisualizer/colors/mike-colors';
import { IGlobalState } from '../store/reducers';

const containerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const textContainerStyle = css`
  padding: ${mikeSharedTheme.spacing(2)};
`;

const titleStyle = css`
  width: ${mikeSharedTheme.spacing(33.75)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  &.MuiTypography-root {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
  &.MuiTypography-h3 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.5rem;
  }
`;

const descriptionStyle = css`
  &.MuiTypography-root {
    color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  }
  &.MuiTypography-body1 {
    font-size: 0.75rem; /* 12px */
    line-height: 1rem;
  }
`;

/**
 * @name MmgConnectedLayerWorkspaceActions
 * @summary Workspace layer actions.
 */
const MmgConnectedLayerWorkspaceActions = () => {
  const { projectId, workspaceId } = useParams();
  const navigate = useNavigate()
  const workspace: IWorkspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);

  const workspaceTitle = useMemo(
    () => {
      if (!workspace) {
        return t('LOADING');
      } else if (!workspace.name) {
        return t('UNTITLED');
      }
      return workspace.name;
    },
    [workspace],
  );

  const layerActions = getLayerActions(projectId, workspaceId, navigate);

  return (
    <div css={containerStyle}>
      <div css={textContainerStyle}>
        <Typography css={descriptionStyle}>{t('WORKSPACE_NAME')}</Typography>
        <Typography css={titleStyle} variant="h3">
          {workspaceTitle}
        </Typography>
      </div>

      <MikeLayerActionMenu layerId={workspaceId} layerActions={layerActions} />
    </div>
  );
};

const getLayerActions = (projectId: string, workspaceId: string, nav: NavigateFunction) => {
  if (!workspaceId || !projectId) {
    return [];
  }

  const layerActions = [];

  const toggleOperationAction = getWorkspaceOperationAction(projectId, workspaceId, nav);

  layerActions.push(toggleOperationAction);

  const snapshotOperationAction = getSnapshotOperationAction();

  const snapshotToProjectOperationAction = getSnapshotToProjectOperationAction(workspaceId);

  layerActions.push(snapshotOperationAction);
  layerActions.push(snapshotToProjectOperationAction);

  return layerActions;
};

export const getSnapshotOperationAction = () => {
  const onOperation = () => {
    store.dispatch({ type: 'workspace/SET_SNAPSHOT_DIALOG_OPEN' });
  };

  const snapshotLayerAction = {
    actionId: 'WORKSPACE_SNAPSHOT_SUPPORT',
    actionDisplayName: t('WORKSPACE_SNAPSHOT_SUPPORT'),
    actionIcon: <Voronoi />,
    onAction: () => onOperation(),
  };
  return snapshotLayerAction;
};

export const getSnapshotToProjectOperationAction = (workspaceId: string) => {
  const onOperation = () => {
    store.dispatch({ type: WorkspaceActionType.CREATE_SNAPSHOT, data: {isSupportSnapshot: false, workspaceId} });
  };

  const snapshotLayerAction = {
    actionId: 'WORKSPACE_SNAPSHOT',
    actionDisplayName: t('WORKSPACE_SNAPSHOT'),
    actionIcon: <FolderZip />,
    onAction: () => onOperation(),
  };
  return snapshotLayerAction;
};

export const getWorkspaceOperationAction = (projectId: string, workspaceId: string, nav: NavigateFunction): ILayerAction => {
  const onOperation = () => {
    return nav(getRouteByPath(ROUTES.workspaceOperationPanel.path, { workspaceId, projectId }, ROUTES.workspace.path));    
  };

  const operationLayerAction = {
    actionId: 'WORKSPACE_OPERATIONS',
    actionDisplayName: t('WORKSPACE_OPERATIONS'),
    actionIcon: <Operations />,
    onAction: () => onOperation(),
  };
  return operationLayerAction;
};

export default MmgConnectedLayerWorkspaceActions;
