import { useMemo, useCallback } from 'react';

import { IWorkspaceEnrichedVariable } from '../../models/IVariables';

import WorkspaceDrawnDataSelectors from '../../store/selectors/WorkspaceDrawnDataSelectors';

import WorkspaceVariableSelectors from '../../store/selectors/WorkspaceVariableSelectors';

import { useSelector } from 'react-redux';

import { MmgConnectedVariableSelectList } from '../../variables/select-list/variable-select-list';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import WorkspaceDataItemUtils from '../../store/selectors/WorkspaceDataItemUtils';
import { IGlobalState } from '../../store/reducers';


type MeshInterpolationSelectVariablesProps = {
  allowedVariableIds: Array<string>;
  selectedVariableIds: Array<string>;
  onSelectionChanged?: (selectedIds: Array<string>) => void;
};

/**
 * @name MmgConnectedMeshInterpolationSelectVariables
 * @summary Allows selecting variabels for a mesh interpolation.
 *
 * @param props
 */
export const MmgConnectedMeshInterpolationSelectVariables = (props: MeshInterpolationSelectVariablesProps) => {
  const {
    // interpolationDescription,
    allowedVariableIds,
    selectedVariableIds,
    onSelectionChanged,
  } = props;

  const workspaceVariables: Array<IWorkspaceEnrichedVariable> = useSelector(
    WorkspaceVariableSelectors.getSortedEnrichedWorkspaceVariables,
  );
  const hiddenWorkspaceVariables: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer.hiddenWorkspaceVariables,
  );
  const drawnWorkspaceVariables: Array<IDrawnDataItem> = useSelector(
    WorkspaceDrawnDataSelectors.getDrawnWorkspaceVariablesByIds,
  );

  // todo hevo useOperationAllowedInputItems or make sure memo does not run too often
  const allowedVariables = useMemo(
    () => {
      return WorkspaceDataItemUtils.getDataItemsByIds(workspaceVariables, allowedVariableIds);
    },
    [allowedVariableIds, workspaceVariables],
  );

  const onLayerSelectChanged = useCallback(
    (layerId: string, selected: boolean) => {
      let nextSelectedIds = [];

      if (selected) {
        nextSelectedIds = [...selectedVariableIds, layerId];
      } else {
        nextSelectedIds = selectedVariableIds.filter((id) => id !== layerId);
      }

      if (onSelectionChanged) {
        onSelectionChanged(nextSelectedIds);
      }
    },
    [selectedVariableIds, onSelectionChanged],
  );

  // todo hevo handle of no allowed variables are present (corner case)
  return (
    allowedVariables &&
    allowedVariables.length > 0 && (
      <MmgConnectedVariableSelectList
        variables={allowedVariables}
        hiddenVariables={hiddenWorkspaceVariables}
        drawnVariables={drawnWorkspaceVariables}
        selectedVariables={selectedVariableIds}
        onLayerSelectChanged={onLayerSelectChanged}
        isInterpolation
      />
    )
  );
};
