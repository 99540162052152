/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MIKE_COLORS from '../styles/mike-colors';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import { LayerNameStyle, LayerContentStyle,
  LayerButtonStyle,
  LAYER_HEIGHT} from '../../shared-components/mike-layer/layer-styles';

  const FlexContainerStyle = css`
    display: flex;
    align-items: center;  
`;


const GroupStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(${LAYER_HEIGHT} - 8px);
  background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
`;

const GroupNameStyle = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
`;

const GroupExpandIconStyle = css`
  margin-left: auto;

  :focus {
    outline: 0;
  }
`;

type GroupProps = {
  groupName: string | React.ReactNode;
  groupTools?: React.ReactNode;
  canBeHidden?: boolean;
  children?: React.ReactNode;
  /**
   * A custom callback for when the group is opened / collapsed.
   * NB: this should be used together with `isOpen`; internal state won't be used if a custom callback is passed.
   */
  toggleOpenCallback?: () => void;
  /**
   * Passing in `isOpen` makes the MmgGroup a 'controlled' component; internal `open` state is overriden by the `isOpen` prop. It is generally used together with `toggleOpenCallback`.
   */
  isOpen?: boolean;
};

/**
 * @name MmgGroup
 * @summary A generic container that can be collapsed or expanded.
 * It typically holds children that are optionally visible (if expanded).
 *
 * @param props
 */
export function MmgGroup(props: GroupProps) {
  const [open, setOpen] = useState(true);
  const { groupName, groupTools, canBeHidden = true, toggleOpenCallback, isOpen } = props;

  const toggleOpen = () => {
    if (canBeHidden) {
      setOpen(!open);
    }
  };

  const isNotCollapsed = isOpen !== undefined ? isOpen : open;
  const onToggleOpen = toggleOpenCallback ? toggleOpenCallback : toggleOpen;

  return (
    <>
      <div css={css`${LayerButtonStyle} ${GroupStyle}`}>
        <button onClick={onToggleOpen}>
          <p css={css`${LayerNameStyle} ${GroupNameStyle}`}>{groupName}</p>
        </button>
        <div css={FlexContainerStyle}>
          {groupTools && <div css={FlexContainerStyle}>{groupTools}</div>}
          {canBeHidden && (
            <button css={css`${LayerButtonStyle} ${GroupStyle} ${GroupExpandIconStyle}`} onClick={onToggleOpen}>
              {isNotCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
          )}
        </div>
      </div>

      {isNotCollapsed ? <section css={LayerContentStyle}>{props.children}</section> : null}
    </>
  );
}
