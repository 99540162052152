import { every, isEmpty } from 'lodash-es';
import { IOperationConfiguration } from '../models/IOperations';
import QueryUtils from '../meshes/mesh-query/mesh-query-utils';

/**
 * Exposes methods related to validation of Mesh Interpolation operations.
 *
 * @module MeshInterpolationValidation
 * @version 2.0.0
 */

/**
 *
 * @param interpolateMeshConfiguration Validates the interpolate mesh operation
 */
export const isInterpolateMeshOperationValid = (interpolateMeshConfiguration: IOperationConfiguration) => {
  if (!interpolateMeshConfiguration) {
    return false;
  }

  // Must have correct input id (the meshId)
  if (!self.isInterpolateMeshOperationInputIdValid(interpolateMeshConfiguration)) {
    return false;
  }

  // Must have valid childOperations
  const { childOperations } = interpolateMeshConfiguration;
  if (!self.isInterpolationChildOperationsValid(childOperations as any)) {
    return false;
  }

  return true;
};

/**
 * Validates the inputIds ([meshId]) of the interpolate mesh operation given.
 * Be aware that this only consider inputids of the parentoperation itself. To validate childoperations @see isInterpolationChildOperationsValid
 * @param interpolateMeshConfiguration
 */
export const isInterpolateMeshOperationInputIdValid = (interpolateMeshConfiguration: IOperationConfiguration) => {
  if (!interpolateMeshConfiguration) {
    return false;
  }

  // Must have exactly one input id (the meshId)
  if (!interpolateMeshConfiguration.inputIds || interpolateMeshConfiguration.inputIds.length !== 1) {
    return false;
  }

  return true;
};
/**
 * Validates the childoperations of an interpolate mesh operation.
 * @param interpolationChildOperations
 */
export const isInterpolationChildOperationsValid = (interpolationChildOperations: Array<IOperationConfiguration>) => {
  // Must have at least one childOperation
  if (!interpolationChildOperations || !interpolationChildOperations.length) {
    return false;
  }

  // childoperations must have input ids and a valid queryDeinition or none
  const childrenValid = every(interpolationChildOperations, ({ inputIds, queryDefinition }) => {
    if (!inputIds || !inputIds.length) {
      return false;
    }

    // if a non empty queryDefinition exists is must be valid
    if (queryDefinition && !isEmpty(queryDefinition)) {
      return QueryUtils.isQueryDefinitionComplete(queryDefinition);
    }

    return true;
  });

  if (!childrenValid) {
    return false;
  }

  return true;
};

const self = {
  isInterpolateMeshOperationValid,
  isInterpolationChildOperationsValid,
  isInterpolateMeshOperationInputIdValid,
};

export default self;
