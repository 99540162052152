/**
 * Exposes methods to transform or combine variable operation requests.
 *
 * @module VariableOperationsManager
 * @version 2.0.0
 * @requires OperationsProxy
 */
import { HttpErrorHandler } from './http-utils';
import * as OperationsProxy from '../proxies/OperationsProxy';
import OperationsManager from './OperationsManager';

import { IOperationDescription } from '../models/IOperationDescriptions';
import { IOperationConfiguration } from '../models/IOperations';
import { IOperationDescriptionApi } from '../models/IOperationDescriptionsApi';

import OperationMappingUtils from '../managers/operation-mapping-utils';

/**
 * Transforms a request that gets available contextual geometry operation descriptions for the workspace and the given geometries.
 * @param workspaceId
 * @param variableIds
 */
const getVariableOperationDescriptions = (workspaceId: string, variableIds: Array<string>) => {
  return OperationsProxy.getVariableOperationDescriptions(workspaceId, variableIds)
    .then((res) => {
      const apIOperationDescriptions = res.data as Array<IOperationDescriptionApi>;

      const operationDescriptions = OperationMappingUtils.mapOperationDescriptions(apIOperationDescriptions);

      return operationDescriptions;
    })
    .catch((error) => HttpErrorHandler('Failed to get variable operation descriptions.', error)) as Promise<{
    [operationKey: string]: IOperationDescription;
  }>;
};

/**
 * Transforms a request that performs a geometry operation on multiple geometries.
 *
 * @param workspaceId
 * @param variableOperationConfiguration
 */
const executeVariableOperation = (workspaceId: string, variableOperationConfiguration: IOperationConfiguration) => {
  if (!variableOperationConfiguration) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  if (!variableOperationConfiguration.inputIds || !variableOperationConfiguration.inputIds.length) {
    return Promise.resolve<IOperationConfiguration>(null);
  }

  return OperationsManager.executeOperation(workspaceId, variableOperationConfiguration).catch((error) =>
    HttpErrorHandler('Failed to excute variableOperation.', error),
  ) as Promise<IOperationConfiguration>;
};

const self = {
  getVariableOperationDescriptions,
  executeVariableOperation,
};

export default self;
