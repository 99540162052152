import { useEffect } from 'react';
import { IParameterDescription } from '../../models/IOperationDescriptions';
import { IParameterSettings } from '../../models/IParameterSettings';
import { MmgParameterInputSelect } from './parameter-input-select';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import { useSelector } from 'react-redux';
import { IWorkspaceMesh } from '../../models/IMeshes';
import { useAllowedDataItemsAsSelectValues } from './useAllowedDataItemsAsSelectValues';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';
const { showElement, hideElements } = MikeVisualizerLib;

type ParameterMeshSelectProps = {
  parameterKey: string;
  parameterDescription: IParameterDescription;
  name: string;
  value: string;
  label: string;
  placeholder: string;
  infoText?: string;
  helperText?: string;
  error?: boolean;
  customSettings?: IParameterSettings;
  onParameterChanged?: (param: string, val: string) => void;
};

/**
 * @name MmgConnectedParameterMeshSelect
 * @param props
 * @summary A generic element that renders a select parameter for meshes and accepts a callback for when it change.
 *
 * The 'allowedValues' of the parameterDescription holds geoemtry ids and will be used for the select options
 *
 *
 */
export const MmgConnectedParameterMeshSelect = (props: ParameterMeshSelectProps) => {
  const { parameterDescription, value, ...rest } = props;
  const workspaceMeshes: Array<IWorkspaceMesh> = useSelector(WorkspaceMeshSelectors.getWorkspaceMeshes);

  const { parameterDescriptionWithValues, getValueDisplayName } = useAllowedDataItemsAsSelectValues(
    parameterDescription,
    workspaceMeshes,
  );

  useEffect(
    () => {
      // Show selected geometry when there is a selected value:
      hideElements(parameterDescription.allowedValues);
      value && showElement(value);
      return () => {
        // hide geometries when unmounting:
        hideElements(parameterDescription.allowedValues);
      };
    },
    [value, parameterDescription.allowedValues],
  );

  return (
    <MmgParameterInputSelect
      parameterDescription={parameterDescriptionWithValues}
      getValueDisplayName={getValueDisplayName}
      value={value}
      {...rest}
    />
  );
};
