import { MAP_DATA_TYPES } from '../../../models/IMaps';
import {
  getBaseMapConfiguration,
  EBaseMapIds,
  IBaseMapConfiguration,
} from '../../../MikeVisualizer/MikeBaseMapConfigurations';
import SatelliteImage from './satellite.jpg';
import StreetImage from './street.jpg';

interface IBaseMapDefinition extends IBaseMapConfiguration {
  image: any; // todo hevo: Should we include this in the shared configuration? Do we need it in the new LayerManager?
}

const MAPTILER_KEY = import.meta.env.VITE_MAPTILER_KEY;
const MAPBOX_KEY = import.meta.env.VITE_MAPBOX_KEY;

export enum ESubmenus {
  DRAW = 'draw-menu',
  VARIABLE_DRAW = 'variable-draw-menu',
  Z_SCALE = 'z-scale-menu',
  MAP_TYPE = 'map-type-menu',
  MEASURE = 'measure-menu',
  MOVE_3D = 'move-3d',
}

export const SUBMENUS = {
  DRAW: ESubmenus.DRAW,
  VARIABLE_DRAW: ESubmenus.VARIABLE_DRAW,
  Z_SCALE: ESubmenus.Z_SCALE,
  MAP_TYPE: ESubmenus.MAP_TYPE,
  MEASURE: ESubmenus.MEASURE,
  MOVE_3D: ESubmenus.MOVE_3D,
};

export const Z_SCALES = {
  LONG_LAT: 1 / 110570, // Multiplying long/lat z points (meters) by this factor supposed to make it close to reality. Otherwise, the visualization will be grately exagerated (for i.e. scale = 1).
  DEFAULT: 1,
};
export const DEFAULT_MULTIPLIERS = [1, 2, 5, 10, 25, 50];
export const AVAILABLE_MAP_DATA_TYPES = [MAP_DATA_TYPES.NONE, MAP_DATA_TYPES.ELEVATION];

export const MMG_BASE_MAPS: { [index: string]: IBaseMapDefinition } = {
  MAPBOX_SATELLITE: {
    ...getBaseMapConfiguration(EBaseMapIds.MAPBOX_SATELLITE, MAPBOX_KEY),
    image: SatelliteImage,
  },
  MAPTILER_STREET: {
    ...getBaseMapConfiguration(EBaseMapIds.MAPTILER_STREET, MAPTILER_KEY),
    image: StreetImage,
  },
  NONE: { ...getBaseMapConfiguration(EBaseMapIds.NONE), image: null },
};

export const DEFAULT_BASE_MAP = MMG_BASE_MAPS.MAPBOX_SATELLITE;
