import { useEffect } from 'react';
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import './MeshOpenLayers.css';

const MmgViewerMapScale = () => {
  const { onBaseMapChanged, onBaseMapDestroyed, setupMapScaleControl } = MikeVisualizerLib;

  const MAP_SCALE_CONTAINER_ID = 'MAP_SCALE';

  const getScaleControlTarget = () => {
    return document.getElementById(MAP_SCALE_CONTAINER_ID);
  };

  useEffect(() => {
    const events = [
      onBaseMapChanged(() => {
        setupScaleControl();
      }),
      onBaseMapDestroyed(() => {
        clearScaleControl();
      }),
    ];

    return () => events.forEach((unsubscribe) => unsubscribe());
  });

  const setupScaleControl = () => {
    const target = getScaleControlTarget();
    target && setupMapScaleControl(target);
    return;
  };

  const clearScaleControl = () => {
    const target = getScaleControlTarget();

    if (target) {
      target.innerHTML = '';
    }
    return target;
  };

  return <span id={MAP_SCALE_CONTAINER_ID} />;
};

export default MmgViewerMapScale;
