/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Polygon from '../../icons/Polygon.svg?react';
import Circle from '../../icons/Circle.svg?react';
import Rectangle from '../../icons/Rectangle.svg?react';
import { useSelector } from 'react-redux';
import { store } from '../../store';
import { EGeometryDrawType } from '../../store/reducers/EditReducer';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { buttonActiveStyle } from '../../shared/edit-modes/select-for-editing-modes';
import { IGlobalState } from '../../store/reducers';

const containerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const toolsStyle = css`
  display: flex;
  align-items: center;
`;

/**
 * @name MmgPolygonDrawTools
 * @summary Allows polygon draw modes (rectangle / circle / polygon)
 */
const MmgPolygonDrawTools = () => {
  const { geometryDrawType } = useSelector((state: IGlobalState) => state.EditReducer);

  const handleDrawTypeChanged = (drawType: EGeometryDrawType) => {
    store.dispatch({ type: EMapToolActionType.SET_DRAW_TYPE, data: drawType });
  };

  return (
    <div css={containerStyle}>
      <div css={toolsStyle}>
        <button
          onClick={() => handleDrawTypeChanged(EGeometryDrawType.RECTANGLE)}
          css={geometryDrawType === EGeometryDrawType.RECTANGLE ? buttonActiveStyle : ''}
        >
          <Rectangle />
        </button>
        <button
          onClick={() => handleDrawTypeChanged(EGeometryDrawType.CIRCLE)}
          css={geometryDrawType === EGeometryDrawType.CIRCLE ? buttonActiveStyle : ''}
        >
          <Circle />
        </button>
        <button
          onClick={() => handleDrawTypeChanged(EGeometryDrawType.POLYGON)}
          css={geometryDrawType === EGeometryDrawType.POLYGON ? buttonActiveStyle : ''}
        >
          <Polygon />
        </button>
      </div>
    </div>
  );
};

export default MmgPolygonDrawTools;
