/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import { isNumber } from 'lodash-es';
import { t } from '../translations/i18n';
import { CircularProgress } from '@mui/material';
import MmgViewerMapScale from './viewer-mapscale';
import { toLocaleRoundedString } from '../translations/utils';
import { getProjectionIdName } from '../workspaces/create/projection-utils';
import MmgViewerLinearProgress from './viewer-linear-progress';
import mikeSharedTheme from '../shared/styles/mikeSharedTheme';
import MIKE_COLORS from '../MikeVisualizer/colors/mike-colors';

type IProps = {
  totalGeometriesToLoad: number;
  totalVariablesToLoad: number;
  totalMeshesToLoad: number;
  geometriesLoadedSoFar: number;
  variablesLoadedSoFar: number;
  meshesLoadedSoFar: number;
  isWorking: boolean;
  epsgCode: string;
  currentViewerCoordinates: Array<number>;
  drawnDataSize?: number;
};

const footerStyle = css`
  width: 100%;
  display: flex;
  height: ${mikeSharedTheme.spacing(4)};
  align-items: flex-end;
  flex-shrink: 1;
`;

const statusStyle = css`
  position: absolute;
  top: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 ${mikeSharedTheme.spacing(1)};
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  font-size: ${mikeSharedTheme.spacing(1.4)};
  text-transform: lowercase;
`;

const statusCenterStyle = css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
`;

const mapScaleStyle = css`
  flex-grow: 1;
  margin-left: ${mikeSharedTheme.spacing(3)};
`;

const spinnerStyle = css`
  margin-right: ${mikeSharedTheme.spacing(1)};
`;

const coordinatesStyle = css`
  display: flex;
  min-width: ${mikeSharedTheme.spacing(25)};
  justify-content: center;
`;

const epsgCodeStyle = css`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  margin-left: ${mikeSharedTheme.spacing(1)};
`;

const coordinateMap = {
  0: 'x',
  1: 'y',
  2: 'z',
};

const MmgViewerInfoBar = (props: IProps) => {
  const {
    totalGeometriesToLoad,
    totalVariablesToLoad,
    totalMeshesToLoad,
    geometriesLoadedSoFar,
    variablesLoadedSoFar,
    meshesLoadedSoFar,
    isWorking,
    epsgCode,
    currentViewerCoordinates,
    drawnDataSize,
  } = props;

  const [firstCompleteLoadDone, setFirstCompleteLoadDone] = useState(false);

  const size = useMemo(
    () => {
      const prettySize = (bytes: number, separator = '', postFix = '') => {
        if (bytes) {
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
          const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
          return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`;
        }
        return 'n/a';
      };
      return prettySize(drawnDataSize, ' ') + '  ';
    },
    [drawnDataSize],
  );

  const getStatusText = (firstCompleteLoadIsDone: boolean) => {
    if (firstCompleteLoadIsDone) {
      if (isWorking) {
        return t('RENDERING');
      }

      return t('READY');
    }

    // NB: although it doesn't make sense that items loaded so far are bigger than total, we account for API errors and still check for >= instead of ===.
    if (
      isNumber(totalGeometriesToLoad) &&
      geometriesLoadedSoFar >= totalGeometriesToLoad &&
      (isNumber(totalVariablesToLoad) && variablesLoadedSoFar >= totalVariablesToLoad) &&
      (isNumber(totalMeshesToLoad) && meshesLoadedSoFar >= totalMeshesToLoad)
    ) {
      setTimeout(() => {
        // Hides any loading progress after first load. The 1s timeout allows showing the bar at 1000 for a brief moment to signal loading is completed.
        setFirstCompleteLoadDone(true);
      }, 1000);
    }

    if (geometriesLoadedSoFar < totalGeometriesToLoad) {
      return t('GEOMETRY', 2) + geometriesLoadedSoFar / totalGeometriesToLoad;
    }
    if (variablesLoadedSoFar < totalVariablesToLoad) {
      return t('VARIABLE', 2) + variablesLoadedSoFar / totalVariablesToLoad;
    }
    if (meshesLoadedSoFar < totalMeshesToLoad) {
      return t('MESH', 2) + meshesLoadedSoFar / totalMeshesToLoad;
    }

    return null;
  };

  return (
    <div css={footerStyle}>
      {isWorking || !firstCompleteLoadDone ? <CircularProgress size={14} css={spinnerStyle} /> : null}
      <div css={statusStyle}>
        {size}
        {getStatusText(firstCompleteLoadDone)}
        <div css={statusCenterStyle}>
          <div css={mapScaleStyle}>
            <MmgViewerMapScale />
          </div>
          {currentViewerCoordinates ? (
            <div css={coordinatesStyle}>
              {currentViewerCoordinates
                .slice(0, 2) // Exclude z for the moment, it is confusing.
                .map((coord, index) => {
                  const roundedCoordinate = toLocaleRoundedString(coord, 10);
                  const value = roundedCoordinate ? roundedCoordinate.replace(',', '.') : '';
                  return coordinateMap[index] + ':' + value;
                })
                .join(', ')}
            </div>
          ) : null}
          <span css={epsgCodeStyle}>{epsgCode ? getProjectionIdName(epsgCode) : ''}</span>
        </div>
      </div>
      {firstCompleteLoadDone || !totalGeometriesToLoad ? null : (
        <MmgViewerLinearProgress currentLoad={geometriesLoadedSoFar} totalLoad={totalGeometriesToLoad} />
      )}
      {firstCompleteLoadDone || !totalVariablesToLoad ? null : (
        <MmgViewerLinearProgress currentLoad={variablesLoadedSoFar} totalLoad={totalVariablesToLoad} />
      )}
      {firstCompleteLoadDone || !totalMeshesToLoad ? null : (
        <MmgViewerLinearProgress currentLoad={meshesLoadedSoFar} totalLoad={totalMeshesToLoad} />
      )}
    </div>
  );
};

export default MmgViewerInfoBar;
