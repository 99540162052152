/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import  { useMemo } from 'react';
import { t } from '../../translations/i18n';
import Mouse from '@mui/icons-material/Mouse';
import { Typography } from '@mui/material';
import { EItemType } from '../../models/IOperationDescriptions';
import { EEditModeIds } from './edit-modes';
import ContextHelp from './ContextHelp';
import { EGeometryItemTypes } from '../../models/IGeometries';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';

const contentContainerStyle = css`
  min-width: ${mikeSharedTheme.spacing(50)};
`;

const HelpKeyStyle = css`
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.8em;
  background: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  border-radius: 5px;
`;

const HelpKeyPlusStyle = css`
  margin: ${mikeSharedTheme.spacing(1)};
`;

const HelpKeyContainer = css`
  display: flex;
  align-items: center;
  min-width: ${mikeSharedTheme.spacing(15)};
`;

const KeyboardShortcutDescriptionStyle = css`
  padding: ${mikeSharedTheme.spacing(1)};
`;

const KeyboardShortcutItemStyle = css`
  display: flex;
  align-items: center;
`;

const getAddMeshNodeShortcuts = () => {
  const items = [
    {
      id: 'KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING',
      description: t('KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING_POINT_SINGLE'),
      Icons: (
        <div css={HelpKeyContainer}>
          <Mouse fontSize="small" />
        </div>
      ),
    },
    {
      id: 'KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW',
      description: t('KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW'),
      Icons: (
        <div css={HelpKeyContainer}>
          <span css={HelpKeyStyle}>{t('SHIFT')}</span>
          <span css={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },
    {
      id: 'KEY_SCROLL',
      description: t('KEY_SCROLL'),
      Icons: (
        <div css={HelpKeyContainer}>
          <span css={HelpKeyStyle}>{t('SCROLL')}</span>
        </div>
      ),
    },
  ];
  return items;
};

const getAddShortcuts = (geometry: EGeometryItemTypes) => {
  if (!geometry) {
    return [];
  }
  switch (geometry) {
    case EGeometryItemTypes.POINT:
    case EGeometryItemTypes.MULTI_POINT: {
      const items = [
        {
          id: 'KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING',
          description: t('KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING_POINT'),
          Icons: (
            <div css={HelpKeyContainer}>
              <Mouse fontSize="small" />
            </div>
          ),
        },
        {
          id: 'KEY_MOUSE_LEFT_PRESS_CTRL_PRESS_MODIFY_DRAWING',
          description: t('KEY_MOUSE_LEFT_PRESS_CTRL_PRESS_MODIFY_DRAWING'),
          Icons: (
            <div css={HelpKeyContainer}>
            <span css={HelpKeyStyle}>{t('CTRL')}</span>
            <span css={HelpKeyPlusStyle}>+</span>
            <Mouse fontSize="small" />
          </div>     
          ),
        },     
        {
          id: 'KEY_DELETE',
          description: t('KEY_DELETE_DRAW_POINT'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('KEY_DELETE')}</span>
            </div>
          ),
        },
        {
          id: 'KEY_MOUSE_CTRL_LEFT_CLICK_DRAW',
          description: t('KEY_MOUSE_CTRL_LEFT_CLICK_DRAW_POINT'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('CTRL')}</span>
              <span css={HelpKeyPlusStyle}>+</span>
              <Mouse fontSize="small" />
            </div>
          ),
        },
        {
          id: 'KEY_MOUSE_SHIFT_LEFT_CLICK_DRAW',
          description: t('KEY_MOUSE_SHIFT_LEFT_CLICK_DRAW_POINT'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('SHIFT')}</span>
              <span css={HelpKeyPlusStyle}>+</span>
              <Mouse fontSize="small" />
            </div>
          ),
        },
        {
          id: 'KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW',
          description: t('KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('SHIFT')}</span>
              <span css={HelpKeyPlusStyle}>+</span>
              <Mouse fontSize="small" />
            </div>
          ),
        },
        {
          id: 'KEY_SCROLL',
          description: t('KEY_SCROLL'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('SCROLL')}</span>
            </div>
          ),
        },
      ];
      return items;
    }
    case EGeometryItemTypes.LINE_STRING:
    case EGeometryItemTypes.MULTI_LINE_STRING:
    case EGeometryItemTypes.POLYGON:
    case EGeometryItemTypes.MULTI_POLYGON: {
      const items = [
        {
          id: 'KEY_MOUSE_LEFT_PRESS_DRAW',
          description: t('KEY_MOUSE_LEFT_PRESS_DRAW'),
          Icons: (
            <div css={HelpKeyContainer}>
              <Mouse fontSize="small" />
            </div>
          ),
        },

        {
          id: 'KEY_MOUSE_DOUBLE_LEFT_PRESS_DRAW',
          description: t('KEY_MOUSE_DOUBLE_LEFT_PRESS_DRAW'),
          Icons: (
            <>
              <div css={HelpKeyContainer}>
                <Mouse fontSize="small" />
                <span css={HelpKeyPlusStyle}>+</span>
                <Mouse fontSize="small" />
              </div>
            </>
          ),
        },
        {
          id: 'KEY_SPACE',
          description: t('KEY_SPACE_DRAW'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('KEY_SPACE')}</span>
            </div>
          ),
        },

        // while drawing
        {
          id: 'KEY_BACKSPACE',
          description: t('KEY_BACKSPACE_DRAW'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('KEY_BACKSPACE')}</span>
            </div>
          ),
        },
        {
          id: 'KEY_ESC',
          description: t('KEY_ESC_DRAW'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('KEY_ESC')}</span>
            </div>
          ),
        },

        {
          id: 'KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW',
          description: t('KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('SHIFT')}</span>
              <span css={HelpKeyPlusStyle}>+</span>
              <Mouse fontSize="small" />
            </div>
          ),
        },

        {
          id: 'KEY_MOUSE_ALT_SHIFT_DRAG_DRAW',
          description: t('KEY_MOUSE_ALT_SHIFT_DRAG_DRAW'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('ALT')}</span>
              <span css={HelpKeyPlusStyle}>+</span>
              <span css={HelpKeyStyle}>{t('SHIFT')}</span>
              <span css={HelpKeyPlusStyle}>+</span>
              <Mouse fontSize="small" />
            </div>
          ),
        },

        {
          id: 'KEY_SCROLL',
          description: t('KEY_SCROLL'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('SCROLL')}</span>
            </div>
          ),
        },
      ];
      return items;
    }
    default:
      return [];
  }
};

const getModifyShortcuts = (geometry: EGeometryItemTypes) => {
  if (!geometry) {
    return [];
  }
  switch (geometry) {
    case EGeometryItemTypes.LINE_STRING:
    case EGeometryItemTypes.MULTI_LINE_STRING:
    case EGeometryItemTypes.POLYGON:
    case EGeometryItemTypes.MULTI_POLYGON: {
      const items = [
        {
          id: 'KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING',
          description: t('KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING'),
          Icons: (
            <div css={HelpKeyContainer}>
              <Mouse fontSize="small" />
            </div>
          ),
        },
        {
          id: 'KEY_MOUSE_LEFT_PRESS_CTRL_PRESS_MODIFY_DRAWING',
          description: t('KEY_MOUSE_LEFT_PRESS_CTRL_PRESS_MODIFY_DRAWING'),
          Icons: (
            <div css={HelpKeyContainer}>
            <span css={HelpKeyStyle}>{t('CTRL')}</span>
            <span css={HelpKeyPlusStyle}>+</span>
            <Mouse fontSize="small" />
          </div>     
          ),
        },  
        {
          id: 'KEY_MOUSE_ALT_LEFT_CLICK_DRAW',
          description: t('KEY_MOUSE_ALT_LEFT_CLICK_DRAW'),
          Icons: (
            <div css={HelpKeyContainer}>
              <span css={HelpKeyStyle}>{t('ALT')}</span>
              <span css={HelpKeyPlusStyle}>+</span>
              <Mouse fontSize="small" />
            </div>
          ),
        },
      ];
      return items;
    }
    default:
      return [];
  }
};

const getKeyboardShortcuts = (editType: EEditModeIds, geometryType: EGeometryItemTypes) => {
  switch (editType) {
    case EEditModeIds.EDIT_MODE_ADD: {
      return getAddShortcuts(geometryType);
    }
    case EEditModeIds.EDIT_MODE_MODIFY: {
      return getModifyShortcuts(geometryType);
    }
    default:
      return [];
  }
};

const renderShortcuts = ({ id, description, Icons }) => (
  <div key={id} css={KeyboardShortcutItemStyle}>
    {Icons}
    <Typography css={KeyboardShortcutDescriptionStyle} variant="caption">
      {description}
    </Typography>
  </div>
);

interface IProps {
  itemType: EItemType;
  editMode: EEditModeIds;
  geometryType: EGeometryItemTypes;
}

export function MmgKeyboardEditHelp(props: IProps) {
  const { editMode, geometryType, itemType } = props;

  const shortCuts = useMemo(
    () => {
      const sh = itemType === EItemType.MESH ? getAddMeshNodeShortcuts() : getKeyboardShortcuts(editMode, geometryType);
      if (!sh) {
        return [];
      } else {
        return sh;
      }
    },
    [editMode, geometryType, itemType],
  );

  return (
    <ContextHelp primary content={<div css={contentContainerStyle}>{shortCuts.map(renderShortcuts)}</div>} />
  );
}
