/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LayerUtils, { fetchTiledLayer } from '../../../shared/layers/layer-utils';
import { EElementCategories, ELEMENT_CATEGORIES } from '../../../shared/panels/mesh-panel-constants';
import { EWorkspaceGeometryActionType } from '../../../store/actions/WorkspaceGeometryActionType';
import { EWorkspaceVariableActionType } from '../../../store/actions/WorkspaceVariableActionType';
import { EWorkspaceMeshActionType } from '../../../store/actions/WorkspaceMeshActionType';
import MikeVisualizerViewManager from '../../../MikeVisualizer/lib/MikeVisualizerViewManager';
import { IWorkspaceMesh } from '../../../models/IMeshes';
import MIKE_COLORS from '../../styles/mike-colors';
import { IGlobalState } from '../../../store/reducers';
import { store } from '../../../store';

const { fetchLayer } = LayerUtils;

type GroupVisibilityActionProps = {
  actionElementCategory: EElementCategories;
};

const iconStyle = css`
  path {
    fill: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
`;

/**
 * @name MmgConnectedGroupVisibilityAction
 * @summary Group visibility actions. Allows toggling visibility of items of a given type, i.e. meshes.
 *
 * @param props
 */
export const MmgConnectedGroupVisibilityAction = (props: GroupVisibilityActionProps) => {
  const { actionElementCategory } = props;
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const hiddenWorkspaceGeometries = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries,
  );
  const workspaceGeometries = useSelector((state: IGlobalState) => state.WorkspaceGeometryReducer.workspaceGeometries);
  const hiddenWorkspaceVariables = useSelector(
    (state: IGlobalState) => state.WorkspaceVariableReducer.hiddenWorkspaceVariables,
  );
  const workspaceVariables = useSelector((state: IGlobalState) => state.WorkspaceVariableReducer.workspaceVariables);
  const hiddenWorkspaceMeshes = useSelector((state: IGlobalState) => state.WorkspaceMeshReducer.hiddenWorkspaceMeshes);
  const workspaceMeshes: Array<IWorkspaceMesh> = useSelector(
    (state: IGlobalState) => state.WorkspaceMeshReducer.workspaceMeshes,
  );

  const workspaceGeometryIds = useMemo(
    () => {
      return workspaceGeometries.map(({ id }) => id);
    },
    [workspaceGeometries],
  );

  const workspaceVariableIds = useMemo(
    () => {
      return workspaceVariables.map(({ id }) => id);
    },
    [workspaceVariables],
  );

  const workspaceMeshIds = useMemo(
    () => {
      return workspaceMeshes.map(({ id }) => id);
    },
    [workspaceMeshes],
  );

  const allItemsInCategoryHidden = useMemo(
    () => {
      /**
       * Checks if all items of a category type are hidden.
       *
       * @param itemType The type of item to check if all items are hidden for.
       */
      const areItemsHiddenForType = (itemType: EElementCategories) => {
        switch (itemType) {
          case ELEMENT_CATEGORIES.GEOMETRY:
            return hiddenWorkspaceGeometries && hiddenWorkspaceGeometries.length === workspaceGeometryIds.length;

          case ELEMENT_CATEGORIES.VARIABLE:
            return hiddenWorkspaceVariables && hiddenWorkspaceVariables.length === workspaceVariableIds.length;

          case ELEMENT_CATEGORIES.MESH:
            return hiddenWorkspaceMeshes && hiddenWorkspaceMeshes.length === workspaceMeshIds.length;

          default:
            return false;
        }
      };
      return areItemsHiddenForType(actionElementCategory);
    },
    [
      actionElementCategory,
      hiddenWorkspaceGeometries,
      hiddenWorkspaceMeshes,
      hiddenWorkspaceVariables,
      workspaceGeometryIds,
      workspaceMeshIds,
      workspaceVariableIds,
    ],
  );

  /**
   * Callback for when item visibility has changed.
   *
   */
  const onItemVisibilityChanged = useCallback(
    () => {
      const noMeshesExist = !workspaceMeshIds.length;

      if (allItemsInCategoryHidden) {
        switch (actionElementCategory) {
          case ELEMENT_CATEGORIES.GEOMETRY:
            hiddenWorkspaceGeometries.forEach((geom: string) => {
              if (!loadedData.includes(geom)) {
                fetchLayer(geom);
              }
            });
            return store.dispatch({
              type: EWorkspaceGeometryActionType.SHOW_ALL,
              updateUserSettings: true,
            });

          case ELEMENT_CATEGORIES.VARIABLE:
            hiddenWorkspaceVariables.forEach((variable: string) => {
              if (!loadedData.includes(variable)) {
                fetchLayer(variable);
              }
            });
            return store.dispatch({
              type: EWorkspaceVariableActionType.SHOW_ALL,
              updateUserSettings: true,
            });

          case ELEMENT_CATEGORIES.MESH: {
            if (noMeshesExist) {
              return false;
            }
            const { getCurrentViewBounds } = MikeVisualizerViewManager;
            const bounds = getCurrentViewBounds();
            hiddenWorkspaceMeshes.forEach((mesh: string) => {
              const wsMesh = workspaceMeshes.find((wm: IWorkspaceMesh) => wm.id === mesh);
              const isTiled = wsMesh && wsMesh.isTiled;
              if (!loadedData.includes(mesh)) {
                if (isTiled) {
                  fetchTiledLayer(mesh, [], bounds, false);
                } else {
                  fetchLayer(mesh);
                }
              }
            });
            return store.dispatch({
              type: EWorkspaceMeshActionType.SHOW_ALL,
              updateUserSettings: true,
            });
          }

          default:
            return false;
        }
      } else {
        switch (actionElementCategory) {
          case ELEMENT_CATEGORIES.GEOMETRY:
            return store.dispatch({
              type: EWorkspaceGeometryActionType.HIDE_ALL,
              updateUserSettings: true,
            });

          case ELEMENT_CATEGORIES.VARIABLE:
            return store.dispatch({
              type: EWorkspaceVariableActionType.HIDE_ALL,
              updateUserSettings: true,
            });

          case ELEMENT_CATEGORIES.MESH: {
            if (noMeshesExist) {
              return false;
            }

            return store.dispatch({
              type: EWorkspaceMeshActionType.HIDE_ALL,
              updateUserSettings: true,
            });
          }

          default:
            return false;
        }
      }
    },
    [
      actionElementCategory,
      allItemsInCategoryHidden,
      hiddenWorkspaceGeometries,
      hiddenWorkspaceMeshes,
      hiddenWorkspaceVariables,
      loadedData,
      workspaceMeshIds.length,
      workspaceMeshes,
    ],
  );

  return (
    <IconButton aria-label="toggle-variable-visibility" onClick={onItemVisibilityChanged}>
      {allItemsInCategoryHidden ? (
        <VisibilityOffOutlinedIcon css={iconStyle} />
      ) : (
        <VisibilityOutlinedIcon css={iconStyle} />
      )}
    </IconButton>
  );
};
