/**
 * Exposes utility methods for building breadcrumbs.
 *
 * @module BreadcrumbUtils
 * @version 1.0.0
 */

import { t } from '../../translations/i18n';
import { ROUTES, getRouteByPath } from '../routes';

const getHomeCrumb = (tenantName?: string) => {
  const crumb = {
    name: tenantName,
    routePath: getRouteByPath(ROUTES.home.path),
  };

  return crumb;
};

const getProjectCrumb = (projectId, projectName) => {
  if (!projectId) {
    return null;
  }

  const crumb = {
    name: projectName || t('PROJECT'),
    routePath: getRouteByPath(ROUTES.workspaceList.path, { projectId }),
    id: projectId,
  };

  return crumb;
};

const getWorkspaceCrumb = (workspaceId, projectId, workspaceName) => {
  if (!workspaceId) {
    return null;
  }

  const crumb = {
    name: workspaceName || t('UNTITLED'),
    routePath: getRouteByPath(ROUTES.workspace.path, { projectId, workspaceId }),
  };

  return crumb;
};

const self = {
  getHomeCrumb,
  getProjectCrumb,
  getWorkspaceCrumb,
};

export default self;
