
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { MmgConnectedViewerTools } from './tools/viewer-tools';
import { useSelector } from 'react-redux';
import '../../MikeVisualizer/MikeVisualizer.css';
import { EViewerModes, VIEWER_MODES } from '../../MikeVisualizer/lib/IMikeVisualizerModels';
import MngViewerConnected from '../../viewer/viewer-connected';
import { PanelWidth, ViewerMapAttributionsZindex } from '../../shared/styles/styleConsts';
import { useMatch } from 'react-router-dom';
import { IGlobalState } from '../../store/reducers';
import { MmgConnectedViewerMapAttributions } from './attributions/viewer-map-attributions';
import CellInfo from '../../shared/cell-info';

const TOGGLE_WIDTH = 100;

const AttributionsCss = css`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  right: 0;
  bottom: ${0}px;
  z-index: ${ViewerMapAttributionsZindex};
  width: 100%;
  max-width: 100vw;
`;

const PanelOpenCss = css`
  right: ${PanelWidth};
  max-width: calc(100vw - ${PanelWidth});
`;

/* todo hevo For now we need to leave some space (100px) for basemap toggle i 2D. Not needed when shared layermananger gets implemented*/
const Attributions2DCss = css`
  max-width: calc(100vw - ${TOGGLE_WIDTH}px);
`;

const PanelOpen2DCss = css`
  max-width: calc(100vw - ${PanelWidth} - ${TOGGLE_WIDTH}px);
`;

const AttributionStyle = (mode: EViewerModes, hidden: boolean) => {
  if (mode === VIEWER_MODES.TWO_D){
    if (hidden){
      return css`
        ${AttributionsCss};
        ${Attributions2DCss};
      `;
    }
    else{
      return css`
        ${AttributionsCss};
        ${Attributions2DCss};
        ${PanelOpenCss};
        ${PanelOpen2DCss};
      `;
    }
  }
  else
  {
    if (hidden){
      return css`
        ${AttributionsCss};       
      `;
      }
      else{
        return css`
          ${AttributionsCss};         
          ${PanelOpenCss};      
        `;
      }
    }
  }  


/**
 * @name MmgViewer
 * @summary Main application viewer.
 * Orchestrates the visualisation of geometries, meshes and variables via the MikeVisualizer.
 *
 */
export const MmgConnectedViewer = () => {
  const panelHidden: boolean = useSelector((state: IGlobalState) => state.PanelReducer.panelHidden);
  const viewerMode: EViewerModes = useSelector((state: IGlobalState) => state.ViewerModeReducer.viewerMode);

  const meshMatch = useMatch({
    path: '/project/:id/workspace/:workspaceId/mesh/:meshId'
  });

  const meshId = meshMatch && meshMatch.params.meshId;

  return (
    <>
      {meshId && <CellInfo elementId={meshId} />}
      <MngViewerConnected />
      <MmgConnectedViewerTools />
      <aside
        css={AttributionStyle(viewerMode, panelHidden)}
      >
        <MmgConnectedViewerMapAttributions />
      </aside>
    </>
  );
};

export default MmgConnectedViewer;
