import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { IWorkspaceEnrichedMesh } from '../../models/IMeshes';
import WorkspaceMeshSelectors from '../../store/selectors/WorkspaceMeshSelectors';
import ModifyMeshOperationsManager from '../../managers/ModifyMeshOperationsManager';
import { t } from '../../translations/i18n';
import { MmgGroup } from '../../shared/groups/group';
import { MmgPanelSubsection } from '../../shared/panels/panel-subsection';
import QueryUtils from '../../meshes/mesh-query/mesh-query-utils';
import LayerUtils from '../../shared/layers/layer-utils';
import { store } from '../../store/store';
import { MmgOperationSelector } from '../../operations/operation-selector';
import { IOperationDescription } from '../../models/IOperationDescriptions';
import { MmgOperationDescription } from '../../operations/operation-description';
import { MmgOperationConfiguration } from '../../operations/operation-configuration';
import { MmgConnectedMeshAttributeQueryConfiguration } from './mesh-attribute-query-configuration';
import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { IQueryDefinitionApi, ISpatialQueryDefinitionApi, QUERY_RESULT_TYPES } from '../../models/IQueryDefinitions';
import { IOperationConfiguration } from '../../models/IOperations';
import { useNavigateBack } from '../../app/navigation/useNavigateBack';
import { MmgMessageBanner } from '../../shared/message-banner/message-banner';
import { useIsMounted } from '../../shared/hooks/hooks';
import { isSpatialQuery } from '../../queries/attribute-queries/attribute-utils';
import { getGeometryGeojson } from '../../managers/WorkspaceGeometriesManager';
import { getRouteByPath, ROUTES } from '../../app/routes';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash-es';
import { EOperationActionType } from '../../store/actions/OperationActionType';
import { IGlobalState } from '../../store/reducers';
import { useNavigate } from 'react-router-dom';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import { MikeStickyPanelBottomActions } from '../../shared-components/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '../../shared-components/mike-button';

const { isLayerWorking, isMeshGenerated } = LayerUtils;

type MeshPostOperationsSectionProps = {
  projectId: string;
  workspaceId: string;
  meshId: string;
  onOperationSubmitted?: (success: boolean) => void;
};

/**
 * @name MmgConnectedMeshPostOperationsSection
 * @param props
 * @summary Allows querying a mesh and applying post operations on it.
 *
 */
export const MmgConnectedMeshPostOperationsSection = (props: MeshPostOperationsSectionProps) => {
  const navigate = useNavigate();
  const { projectId, workspaceId, meshId, onOperationSubmitted } = props;
  const getMeshSelectorInstance = WorkspaceMeshSelectors.makeGetMesh();
  const getDrawnDataSelectorInstance = WorkspaceMeshSelectors.makeGetMeshDrawnData();
  const mesh: IWorkspaceEnrichedMesh = useSelector((state: IGlobalState) => getMeshSelectorInstance(state, { meshId }));
  const meshDrawnData: IDrawnDataItem = useSelector((state: IGlobalState) =>
    getDrawnDataSelectorInstance(state, { meshId }),
  );

  const [operationDescriptions, setOperationDescriptions] = React.useState({} as {
    [operationKey: string]: IOperationDescription;
  });
  const [operationsLoading, setOperationsLoading] = React.useState(false);
  const [operationsLoadingFailed, setOperationsLoadingFailed] = React.useState(false);
  const [operationInProgress, setOperationInProgress] = React.useState(false);
  const [selectedOperationKey, setSelectedOperationKey] = React.useState(null as string);
  const [operationConfiguration, setOperationConfiguration] = useState(null as IOperationConfiguration);
  const [queryDefinition, setQueryDefinition] = React.useState({} as IQueryDefinitionApi);
  const prevQueryDefinition = usePrevious(queryDefinition);
  const isMounted = useIsMounted();

  /**
   * Callback for mesh-query to call whenever something changes in the query.
   * The query can be submitted later.
   *
   * @param newQueryDefinition
   */
  const onQueryDefinitionChanged = (newQueryDefinition: IQueryDefinitionApi) => {
    setQueryDefinition(newQueryDefinition);
  };

  /**
   * Fetch geojson for selected geometry, if the user has selected one of those,
   * which in turn sets a new queryDefinition each time a geometry is selected.
   */
  useEffect(() => {
    //is here where we fetch the data from
    const queryDef = queryDefinition as ISpatialQueryDefinitionApi;
    if (isSpatialQuery(queryDefinition)) {
      // Abort if equal to previous:
      if (isEqual(queryDefinition, prevQueryDefinition)) {
        return;
      }
      // Abort if no id or if geojson is already fetched:
      if (!queryDef.queryId || queryDef.featureCollection) {
        return;
      }
      getGeometryGeojson(workspaceId, queryDef.queryId)
        .then((geojson) => {
          isMounted() &&
            setQueryDefinition({
              featureCollection: geojson,
              ...queryDef,
            });
        })
        .catch((error) => console.error(`Error fetching GeoJson for "${queryDef.queryId}": ${error}`));
    }
  });

  const submitOperation: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (!selectedOperationKey) {
      return;
    }
    setOperationInProgress(true);

    // Always ask for elements
    // todo hevo might consider if we sometimes wants points, e.g. for for nodemarkers
    // todo hevo should this be passed in? Or should it be set from the outside afterwards or should it be set to NOT_ET or set by the api alltogether?????????

    if (queryDefinition) {
      queryDefinition.resultType = QUERY_RESULT_TYPES.POLYGON;
    }
    let success = false;
    const opConfig: IOperationConfiguration = {
      ...operationConfiguration,
      inputIds: [meshId],
      queryDefinition,
    };
    ModifyMeshOperationsManager.executeModifyMeshOperation(workspaceId, opConfig, supportsQuery)
      .then((operationMetadata) => {
        success = true;
        store.dispatch({ type: EOperationActionType.CREATE_OR_UPDATE, operationMetadata });
        // Route back to overview after a delay, so the user can see the new mesh:
        setTimeout(() => {
          navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path)); 
        }, 1500);
      })
      .catch((error) => {
        success = false;
        const toast = {
          text: error.response && error.response.data ? error.response.data : t('MESH_POST_OPERATION_SUBMITTED_FAILED'),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      })
      .finally(() => {
        isMounted() && setOperationInProgress(false);
        if (onOperationSubmitted) {
          onOperationSubmitted(success);
        }
      });
  };

  /**
   * Gets geometry operations
   */
  const getMeshPostOperations = useCallback(
    async () => {
      if (!workspaceId || !meshId) {
        return;
      }
      setOperationsLoading(true);
      setOperationsLoadingFailed(false);
      try {
        const operations = await ModifyMeshOperationsManager.getModifyMeshOperationDescriptions(workspaceId, [meshId]);
        setOperationDescriptions(operations);
      } catch (error) {
        setOperationsLoadingFailed(true);
        console.error('Failed to get mesh post operations');
        throw error;
      } finally {
        setOperationsLoading(false);
      }
    },
    [workspaceId, meshId],
  );

  /**
   * Sets the selected post operation to the selected one.
   *
   * @param event
   */
  const onOperationChanged = (event: React.MouseEvent) => {
    const operationKey = (event.target as HTMLSelectElement).value;
    setSelectedOperationKey(operationKey);
    setOperationConfiguration(null);
  };

  const { goBackToReferrer } = useNavigateBack();

  const onConfigurationChanged = (newConfiguration: IOperationConfiguration) => {
    setOperationConfiguration(newConfiguration);
  };

  // load available operations when mounting
  useEffect(
    () => {
      getMeshPostOperations();
    },
    [getMeshPostOperations],
  );

  const isMissing = !mesh;
  const drawnMeshes = meshDrawnData ? [meshDrawnData] : [];
  const isWorking = isLayerWorking(mesh, drawnMeshes);
  const operationKeys = Object.keys(operationDescriptions);
  const selectedOperationDescription = (operationDescriptions || {})[selectedOperationKey];
  const isOperationsAvailable = operationKeys && operationKeys.length > 0;

  // If query is not supported, the QueryDefinition is by default complete.
  const { supportsQuery } = selectedOperationDescription || {};
  const isQueryComplete = !supportsQuery || QueryUtils.isQueryDefinitionComplete(queryDefinition);
  const isOperationValid = meshId && selectedOperationKey;
  const submitDisabled = !isOperationValid || !isQueryComplete || isWorking || operationInProgress;

  const onPanelExit = () => {
    goBackToReferrer();
  };

  if (!isMeshGenerated(mesh)) {
    return (
      <>
        <MmgMessageBanner
          messageTitle={t('POST_OPERATIONS_NOT_AVAILABLE')}
          message={t('MESH_NOT_GENERATED_POSTOPERATIONS_DETAILS')}
        />
        <MikeStickyPanelBottomActions>
          <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
            {t('BACK')}
          </MikeButton>
        </MikeStickyPanelBottomActions>
      </>
    );
  }

  if (isMissing || (!isWorking && !isMeshGenerated(mesh))) {
    return <></>;
  }

  return (
    <>
      {(isWorking || operationInProgress) && (
        <MikeStickyPanelContent>
          <MmgPanelSubsection>
            <p>{t('CONFIGURE_AFTER_DATA_PROCESSED')}</p>
          </MmgPanelSubsection>
        </MikeStickyPanelContent>
      )}
      <MmgGroup groupName={t('POST_OPERATIONS_TITLE')} canBeHidden={false}>
        {isOperationsAvailable && (
          <>
            <MikeStickyPanelContent>
              <MmgPanelSubsection>
                <MmgOperationSelector
                  label={t('SELECT_POST_MESHING_OPERATION_LABEL')}
                  placeholder={t('SELECT_POST_MESHING_OPERATION').toLowerCase()}
                  operationKeys={operationKeys}
                  operationsLoading={operationsLoading}
                  operationsLoadingFailed={operationsLoadingFailed}
                  onOperationChanged={onOperationChanged}
                  selectedOperationKey={selectedOperationKey}
                />

                <MmgOperationDescription operationKey={selectedOperationKey} />
                {supportsQuery && (
                  <MmgConnectedMeshAttributeQueryConfiguration onQueryDefinitionChanged={onQueryDefinitionChanged} />
                )}

                <MmgOperationConfiguration
                  operationDescription={selectedOperationDescription}
                  onConfigurationChanged={onConfigurationChanged}
                />
              </MmgPanelSubsection>
            </MikeStickyPanelContent>
            <MikeStickyPanelBottomActions>
              <MikeButton variant="outlined" color="secondary" onClick={onPanelExit}>
                {t('CANCEL')}
              </MikeButton>
              <MikeButton variant="contained" color="secondary" disabled={submitDisabled} onClick={submitOperation} active={operationInProgress}>
                {t('SUBMIT_MESH_POST_OPERATION')}
              </MikeButton>
            </MikeStickyPanelBottomActions>
          </>
        )}
      </MmgGroup>
    </>
  );
};
