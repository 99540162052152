/**
 * Exposes map REST endpoints to managers.
 *
 * @module MapProxy
 * @version 2.0.0
 * @requires Proxy
 *
 */
import { endpoints } from './config';
import Proxy from './Proxy';
import { EMapDataTypes } from '../models/IMaps';

/**
 * Get a map of the provided type.
 *
 * @deprecated
 *
 * @param mapType
 * @param mapQuery
 */
export const getMap = (mapType: EMapDataTypes, mapQuery: string) =>
  Proxy.http.get(`${endpoints.meshAppService}/map/${mapType}?${mapQuery}`);

/**
 * Get tile information (metadata) for a bounding box.
 *
 * @param mapTileQuery
 */
export const getMapTileInformation = (mapTileQuery: string) =>
  Proxy.http.get(`${endpoints.meshAppService}/map/tiles?${mapTileQuery}`);

/**
 * Get a map tile.
 *
 * @param mapType
 * @param zoom
 * @param column
 * @param row
 * @param epsgCode
 * @param includeElevation
 * @param lowResolution
 */
export const getMapTile = (
  mapType: EMapDataTypes,
  zoom: number,
  column: number,
  row: number,
  epsgCode: number,
  includeElevation: boolean,
  lowResolution: boolean,
) =>
  Proxy.http.get(
    `${
      endpoints.meshAppService
    }/map/${mapType}/${zoom}/${column}/${row}?epsgCode=${epsgCode}&includeElevation=${includeElevation}&lowResolution=${lowResolution}`,
  );
