/* eslint-disable react-refresh/only-export-components */
import { useCallback, useState } from 'react';
import { Location } from 'history';
import { useSelector } from 'react-redux';
import { store } from '../../../store/store';
import { t } from '../../../translations/i18n';
import WorkspaceGeometriesManager from '../../../managers/WorkspaceGeometriesManager';
import TimelineIcon from '@mui/icons-material/Timeline';
// import CloudExport from '../../../icons/CloudExport.svg?react';
import AddToMesh from '../../../icons/AddToMesh.svg?react'
import Operations from '../../../icons/Operations.svg?react'
import Extracting from '../../../icons/Extracting.svg?react'
import { EElementCategories } from '../../../shared/panels/mesh-panel-constants';
import { getRouteByPath, ROUTES } from '../../../app/routes';
import LayerUtils from '../../../shared/layers/layer-utils';
import { MikeLayerActionMenu } from '../../../shared/layers/actions/layer-action-menu';
import {
  getEditLayerAction,
  getDuplicateLayerAction,
  getDeleteLayerAction,
  getToggleVisibilityLayerAction,
  ILayerAction,
  getFetchLayerAction,
  getReplaceLayerAction,
} from '../../../shared/layers/actions/layer-default-actions';
import { EGeometryItemTypes, IWorkspaceEnrichedGeometry, GEOMETRY_ITEM_TYPES } from '../../../models/IGeometries';
import { CONFIRM_PANEL_IDS } from '../../../shared/confirm-containers/confirm-constants';
import { MmgConfirmDeleteGeometryDialog } from '../../../geometries/details/geometry-delete-dialog';
import { IDrawnDataItem } from '../../../models/IWorkspaceData';
/* import { mapFeatureToExportable } from '../../../shared/operations/download/download-operations';
import { MMG_TYPE_KEY } from '../../../managers/open-data-utils'; */
import { enableExtractFeatures } from '../../../workspaces/viewer/tools/viewer-tool-2d-extract-features-util';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPropertiesAction, getZoomToLayerAction } from '../../../workspaces/details/actions/layer-actions';
import { getProjectContent, setProjectContentDialogOpen } from '../../../store/actions/projectContent';
import { EDATASETUSAGE_TYPE } from '../../../models/IGetDataset';
import { EMapToolActionType } from '../../../store/actions/MapToolActionType';
import { EWorkspaceActionType } from '../../../store/actions/WorkspaceActionType';
import MikeVisualizer2DDrawIO from '../../../MikeVisualizer/lib/2d/draw/MikeVisualizer2DDrawIO';
import { IGlobalState } from '../../../store/reducers';
import { IGetProject } from '../../../shared-components/mike-project-explorer/model';

const { isLayerInProgress, isLayerFailed } = LayerUtils;
const { setDrawVectorLayerGeojson } = MikeVisualizer2DDrawIO;

type LayerGeometryActionsProps = {
  layerId: string;
  geometry: IWorkspaceEnrichedGeometry;
  canToggleLayerVisibility?: boolean;
  canEdit?: boolean;
  canUseForExtraction?: boolean;
  canAddToMesh?: boolean;
  canApplyOperations?: boolean;
  geometryDrawnData?: IDrawnDataItem;
  updateUserSettingsOnToggle?: boolean;
  onGeometryEdit?: (geometryId: string) => void;
};


/**
 * @name MmgConnectedLayerGeometryActions
 * @summary Geometry layer actions.
 *
 * @param props
 */
export function MmgConnectedLayerGeometryActions(props: LayerGeometryActionsProps) {
  const {
    layerId,
    geometry,
    geometryDrawnData,
    canToggleLayerVisibility= true,
    updateUserSettingsOnToggle = false,
    canEdit = true,
    canUseForExtraction = true,
    canAddToMesh = true,
    canApplyOperations = true,
  } = props;

  const { workspaceId, projectId } = useParams();
  const navigate = useNavigate();
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const hiddenGeometryIds: Array<string> = useSelector(
    (state: IGlobalState) => state.WorkspaceGeometryReducer.hiddenWorkspaceGeometries,
  );
  const project: IGetProject = useSelector((state: IGlobalState) => state.ProjectContentReducer.project);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const location = useLocation();

  const onGeometryDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const onGeometryReplace = useCallback(
    (geometryId: string) => {
      store.dispatch(getProjectContent(project));
      store.dispatch(
        setProjectContentDialogOpen(true, true, { elementId: geometryId, usageType: EDATASETUSAGE_TYPE.Geometry }),
      );
    },
    [project],
  );

  const isMissing = !geometry;

  const layerActions = getLayerActions(
    projectId,
    workspaceId,
    geometry,
    geometryDrawnData,
    canToggleLayerVisibility,
    canEdit,
    canUseForExtraction,
    canAddToMesh,
    canApplyOperations,
    hiddenGeometryIds,
    updateUserSettingsOnToggle,
    location,
    loadedData,
    props.onGeometryEdit,
    onGeometryDelete,
    onGeometryReplace,
    navigate
  );

  return (
    <>
      <MikeLayerActionMenu layerId={layerId} layerActions={layerActions} disabled={isMissing} />
      <MmgConfirmDeleteGeometryDialog
        open={deleteConfirmOpen}
        projectId={projectId}
        workspaceId={workspaceId}
        geometry={geometry}
        onClose={() => setDeleteConfirmOpen(false)}
      />
    </>
  );
}

const getLayerActions = (
  projectId: string,
  workspaceId: string,
  geometry: IWorkspaceEnrichedGeometry,
  _geometryDrawnData: IDrawnDataItem,
  _canToggleLayerVisibility: boolean,
  canEdit: boolean,
  canUseForExtraction: boolean,
  canAddToMesh: boolean,
  canApplyOperations: boolean,
  _hiddenGeometryIds: Array<string>,
  _updateUserSettingsOnToggle: boolean,
  _referringLocation: Location,
  _loadedData: Array<string>,
  onGeometryEdit: (geometryId: string) => void,
  onGeometryDelete: () => void,
  onGeometryReplace: (geometryId: string) => void,
  navigate: NavigateFunction
) => {
  if (!geometry) {
    return [];
  }

  const isFailed = isLayerFailed(geometry);
  const isInProgress = isLayerInProgress(geometry);
  const canEditData = !isFailed;
  // const canDownloadData = !isFailed;
  const canUseForExtractingData =
    canUseForExtraction &&
    (geometry.itemType === GEOMETRY_ITEM_TYPES.POLYGON || geometry.itemType === GEOMETRY_ITEM_TYPES.MULTI_POLYGON);

  const layerId = geometry.id;
  const layerActions = [];

  if (canApplyOperations) {
    const geometryOperationAction = getGeometryOperationAction(
      projectId,
      workspaceId,
      layerId,
      navigate,
      isInProgress || isFailed,
    );
    layerActions.push(geometryOperationAction);
  }

  const zoomToLayerAction = getZoomToLayerAction(layerId, workspaceId, !_geometryDrawnData || isInProgress || isFailed);
  layerActions.push(zoomToLayerAction);

  if (canAddToMesh) {
    const addToMeshLayerAction = getAddGeometryToMeshAction(
      projectId,
      workspaceId,
      layerId,
      navigate,
      isInProgress || isFailed,
    );
    layerActions.push(addToMeshLayerAction);
  }

  if (canEditData) {
    const editLayerDataAction = getEditGeometryDataAction(layerId, geometry.itemType, isInProgress);
    layerActions.push(editLayerDataAction);
  }

  if (canEdit) {
    if (!onGeometryEdit) {
      console.warn('When allowing editing, onGeometryEdit must be provided');
    } else {
      const editLayerAction = getEditGeometryAction(layerId, onGeometryEdit, isInProgress);
      layerActions.push(editLayerAction);
    }
    if (!onGeometryReplace) {
      console.warn('When allowing replacing, onGeometryReplace must be provided');
    } else {
      const replaceLayerAction = getReplaceGeometryAction(layerId, onGeometryReplace, isInProgress);
      layerActions.push(replaceLayerAction);
    }
  }

  const propertiesAction = getPropertiesAction(projectId, workspaceId, layerId, EElementCategories.GEOMETRY, navigate);
  layerActions.push(propertiesAction);

  if (canUseForExtractingData) {
    const useGeometryForExtractionAction = getUseGeometryForExtractionAction(workspaceId, layerId, isInProgress);
    layerActions.push(useGeometryForExtractionAction);
  }

/*   if (canDownloadData) {
    const downloadLayerAction = getGeometryDownloadDataAction(layerId, geometry.name, workspaceId, isInProgress);
    layerActions.push(downloadLayerAction);
  } */

  const duplicateLayerAction = getDuplicateGeometryAction(projectId, workspaceId, layerId, navigate, isInProgress || isFailed);
  layerActions.push(duplicateLayerAction);

  const deleteLayerAction = getDeleteGeometryAction(onGeometryDelete);
  layerActions.push(deleteLayerAction);

  return layerActions;
};

export const getToggleGeometryVisibilityAction = (
  layerId: string,
  hiddenGeometryIds,
  updateUserSettings: boolean,
  _disabled?: boolean,
  loadedData?: Array<string>,
): ILayerAction => {
  const toggleVisibilityAction = getToggleVisibilityLayerAction(
    EElementCategories.GEOMETRY,
    layerId,
    hiddenGeometryIds,
    false,
    updateUserSettings,
    loadedData,
  );
  return toggleVisibilityAction;
};

export const getEditGeometryAction = (
  layerId: string,
  onGeometryEdit: (geometryId) => void,
  disabled?: boolean,
): ILayerAction => {
  const editLayerAction = getEditLayerAction(() => onGeometryEdit && onGeometryEdit(layerId), disabled);
  return editLayerAction;
};

export const getReplaceGeometryAction = (
  layerId: string,
  onReplaceGeometry: (geometryId) => void,
  disabled?: boolean,
): ILayerAction => {
  const editLayerAction = getReplaceLayerAction(() => onReplaceGeometry && onReplaceGeometry(layerId), disabled);
  return editLayerAction;
};

export const getFetchGeometryAction = (onGeometryFetch: () => void, disabled?: boolean): ILayerAction => {
  const fetchLayerAction = getFetchLayerAction(() => onGeometryFetch(), disabled);
  return fetchLayerAction;
};

export const getUseGeometryForExtractionAction = (
  workspaceId: string,
  layerId: string,
  disabled?: boolean,
): ILayerAction => {
  const onUseForExtraction = async () => {
    store.dispatch({
      type: 'toast/ADD/SUCCESS',
      toast: { text: t('GEOMETRY_USE_FOR_EXTRACTION_SUBMITED') },
    });

    try {
      const geometryGeojson = await WorkspaceGeometriesManager.getGeometryGeojson(workspaceId, layerId);
      const { viewerBaseMapId } = store.getState().ViewerModeReducer;
      await enableExtractFeatures(viewerBaseMapId);
      setDrawVectorLayerGeojson(geometryGeojson);
      store.dispatch({
        type: EWorkspaceActionType.ACTIVE_PANEL_SET,
        panelName: CONFIRM_PANEL_IDS.EXTRACT_DATA,
      });
    } catch {
      console.error('Failed to use geometry for extraction');
      store.dispatch({
        type: 'toast/ADD/ERROR',
        toast: { text: t('GEOMETRY_USE_FOR_EXTRACTION_SUBMIT_FAILED') },
      });
    }
  };

  return {
    actionId: 'USE_FOR_EXTRACTION',
    actionDisplayName: t('USE_FOR_EXTRACTION'),
    actionIcon: <Extracting />,
    onAction: onUseForExtraction,
    disabled,
  };
};

export const getDuplicateGeometryAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onGeometryDuplicate = (geometryId: string) => {
    return WorkspaceGeometriesManager.duplicateGeometry(workspaceId, geometryId)
      .then(() => {
        const toast = { text: t('DUPLICATED', 1, { thing: t('GEOMETRY') }) };
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
        navigate(getRouteByPath(ROUTES.workspacePanel.path, { workspaceId, projectId }, ROUTES.workspace.path));   
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, { action: t('DUPLICATE') }),
          operationId: error.operationId,
        };
        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        throw error;
      });
  };

  const duplicateLayerAction = getDuplicateLayerAction(() => onGeometryDuplicate(layerId), disabled);
  return duplicateLayerAction;
};

export const getDeleteGeometryAction = (onGeometryDelete: () => void, disabled?: boolean): ILayerAction => {
  const deleteLayerAction = getDeleteLayerAction(() => onGeometryDelete(), disabled);
  return deleteLayerAction;
};

export const getEditGeometryDataAction = (
  layerId: string,
  layerType: EGeometryItemTypes,
  disabled?: boolean,
): ILayerAction => {
  const onEditData = () => {
    store.dispatch({
      type: EMapToolActionType.GEOMETRY_EDIT,
      data: {
        geometryEditItemType: layerType,
        geometryEditTargetId: layerId,
      },
    });
    store.dispatch({
      type: EWorkspaceActionType.ACTIVE_PANEL_SET,
      panelName: CONFIRM_PANEL_IDS.GEOMETRY_EDIT,
    });

    /*     store.dispatch({
      type: EMapToolActionType.SET_OPEN_SUBMENU,
      openSubmenu: SUBMENUS.DRAW,
    }); */
  };

  return {
    actionId: 'GEOMETRY_EDIT_DATA',
    actionDisplayName: t('GEOMETRY_EDIT_DATA'),
    actionIcon: <TimelineIcon />,
    onAction: onEditData,
    disabled,
  };
};

/* export const getGeometryDownloadDataAction = (
  layerId: string,
  layerName: string,
  workspaceId: string,
  disabled?: boolean,
): ILayerAction => {
  const onDownloadData = async () => {
    const toast = {
      text: t('GEOMETRY_DOWNLOAD_DATA_SUBMITTED'),
    };
    store.dispatch({ type: 'toast/ADD/SUCCESS', toast });

    try {
      const geometryProperties = await getGeometryProperties(workspaceId, layerId);
      const geometryGeojson = await WorkspaceGeometriesManager.getGeometryGeojson(workspaceId, layerId);
      const geometryType = geometryProperties[MMG_TYPE_KEY];
      const mapFeatureToExportableForType = mapFeatureToExportable(geometryType);
      const mappedGeometryFeatures = geometryGeojson.features.map(mapFeatureToExportableForType);
      const MikeVisualizerMapshaper = await import('../../../MikeVisualizer/lib/MikeVisualizerMapShaper');
      const { exportShpFromGeojson } = MikeVisualizerMapshaper;
      const filename = layerName.replace(/[/\\?%*:|"<>]/g, '-');
      exportShpFromGeojson({ ...geometryGeojson, features: mappedGeometryFeatures }, filename, true);
    } catch (error) {
      const errorToast = { text: t('GEOMETRY_DOWNLOAD_DATA_FAILED') };
      store.dispatch({ type: 'toast/ADD/ERROR', toast: errorToast });
      console.error(error);
    }
  };

  return {
    actionId: 'GEOMETRY_DOWNLOAD_DATA',
    actionDisplayName: t('GEOMETRY_DOWNLOAD_DATA'),
    actionIcon: <CloudExport />,
    onAction: onDownloadData,
    disabled,
  };
}; */

export const getAddGeometryToMeshAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onAddToMesh = (geometryId: string) => {
    return navigate(getRouteByPath(ROUTES.meshAddGeometrySelectMeshPanel.path, { geometryId, workspaceId, projectId }, ROUTES.workspace.path));    
    
  };

  const addToMeshLayerAction = {
    actionId: 'ADD_TO_MESH',
    actionDisplayName: t('ADD_TO_MESH'),
    actionIcon: <AddToMesh />,
    onAction: () => onAddToMesh(layerId),
    disabled,
  };
  return addToMeshLayerAction;
};

export const getGeometryOperationAction = (
  projectId: string,
  workspaceId: string,
  layerId: string,
  navigate: NavigateFunction,
  disabled?: boolean,
): ILayerAction => {
  const onOperation = (geometryId: string) => {
    return navigate(getRouteByPath(ROUTES.geometryOperationPanel.path, { geometryId, workspaceId, projectId }, ROUTES.workspace.path)); 
  };

  const operationLayerAction = {
    actionId: 'GEOMETRY_OPERATIONS',
    actionDisplayName: t('GEOMETRY_OPERATIONS'),
    actionIcon: <Operations />,
    onAction: () => onOperation(layerId),
    disabled,
  };
  return operationLayerAction;
};
