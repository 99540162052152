/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { t } from '../../translations/i18n';
import MIKE_COLORS from '../styles/mike-colors';
import Button from '@mui/material/Button';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import { LayerNameStyle,
  LayerContentStyle,
  LayerIconStyle,
  LayerButtonStyle,LAYER_HEIGHT } from '../../shared-components/mike-layer/layer-styles';

// todo hevo in general use MIKE_COLORS instead of old css consts
const CardStyle = css`
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
`;

const CardHeaderStyle = css`
  display: flex;
  align-items: center;  
  width: 100%;
  height: ${LAYER_HEIGHT};
  background-color: white;

  :focus {
    outline: 0;
  }
`;

const CardCloseButtonStyle = css`
  min-width: auto;
  text-transform: capitalize;  
`;

const CardNameStyle = css`
  font-weight: 400;
`;

const CardNameWithoutIconStyle = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
`;

const CardExpandButtonStyle = css`
  margin: 0 ${mikeSharedTheme.spacing(2)} 0 auto;
`;

export const CardSummaryStyle = css`
  &:not(:empty) {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 12px;
    line-height: 1.33;
    font-weight: normal;
    padding: 0 0 ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(6)};
    background: white;
    color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  }
  &(:empty) {
    border-bottom: none;
  }
`;

const CardContentStyle = css`
  background-color: ${MIKE_COLORS.WHITE};
`;

type CardProps = {
  cardName?: string;
  cardLayer?: React.ReactNode;
  cardSummary?: React.ReactNode;
  openLabel?: string;
  closeLabel?: string;
  leftIcon?: React.ReactNode;
  canBeToggled?: boolean;
  children?: React.ReactNode;
  cssProp?: any;
  open?: boolean;
  onOpenStateChanged?: (open: boolean) => void;
};

/**
 * @name MmgItemCard
 * @summary A generic container that can show name & summary or a layer as a title.
 * It accepts arbitrary content that can be collapsed or expanded.
 *
 * TODO hevo should be moved to shared components
 *
 * @param props
 */
export function MmgItemCard(props: CardProps) {
  const {
    cardName,
    cardLayer,
    cardSummary,
    openLabel = t('CONFIGURE'),
    closeLabel = t('OK'),
    leftIcon,
    cssProp,
    canBeToggled = true,
    open = true,
    onOpenStateChanged,
  } = props;

  const toggleOpen = (event) => {
    event.preventDefault();

    if (canBeToggled && onOpenStateChanged) {
      onOpenStateChanged(!open);
    }
  };

  // merge prop styles together with internal styles
  const cssStyle = css`
    ${CardStyle} ${cssProp};
  `;

  const allowToggle = canBeToggled && props.children && onOpenStateChanged;

  return (
    <>
      <div css={cssStyle}>
        <div css={css`${LayerButtonStyle} ${CardHeaderStyle}`} onClick={toggleOpen}>
          {cardLayer ? (
            cardLayer
          ) : (
            // todo hevo This cpould be seen as a type of layer. Sole of the styling here could also be relevant for layers.
            // Also seems wrong that that card uses a Layer Style for stomething that is not a layer.
            // worth thinking about when moveing this to shared components
            <div>
              {leftIcon && <button css={css`${LayerButtonStyle} ${LayerIconStyle}`}>{leftIcon}</button>}

              <p css={css`${LayerNameStyle} ${CardNameStyle} ${leftIcon && CardNameWithoutIconStyle}`}>
                {cardName}
              </p>
            </div>
          )}

          {allowToggle && (
            <div css={CardExpandButtonStyle}>
              {open ? (
                <Button css={CardCloseButtonStyle} variant="outlined" size="small" color="primary">
                  {closeLabel}
                </Button>
              ) : (
                <Button css={CardCloseButtonStyle} variant="outlined" size="small" color="primary">
                  {openLabel}
                </Button>
              )}
            </div>
          )}
        </div>

        {!open && cardSummary && <div css={CardSummaryStyle}>{cardSummary}</div>}
       
        {open && <section css={css`${LayerContentStyle} ${CardContentStyle}`}>{props.children}</section>}
      </div>
    </>
  );
}
