import { WorkspaceActionType } from '../../workspaces/store/WokspaceActionType';
import { IAction } from '../actions/Action';
import { IWorkspaceSocketManager } from '../../models/ISocketManager';
import { IDrawnData } from '../../models/IWorkspaceData';

export interface ISocketState {
  workspaceSocketManager: IWorkspaceSocketManager | undefined;
  workspaceAutomaticReconnect: boolean;
  workspaceListenerErrorCount: number;
  connectedToNewSocket: boolean;
  drawnWorkspaceData: IDrawnData;
}

const initState: ISocketState = {
  workspaceSocketManager: undefined,
  workspaceAutomaticReconnect: true,
  workspaceListenerErrorCount: 0,
  connectedToNewSocket: false,
  drawnWorkspaceData: {},
};

export default function(
  state: ISocketState = initState,
  action: IAction,
): ISocketState {
  switch (action.type) {
    case WorkspaceActionType.SOCKET_SET_DRAWNDATA: {
      return { ...state, drawnWorkspaceData: action.data as IDrawnData };
    }
    case WorkspaceActionType.SOCKET_SETUP: {
      const socketMgr = action.data as IWorkspaceSocketManager;
      // const newSocketManager = { workspace: socketMgr };
      return {
        ...state,
        workspaceAutomaticReconnect: true,
        workspaceSocketManager: socketMgr,
        connectedToNewSocket: true,
        drawnWorkspaceData: {},
      };
    }
    case WorkspaceActionType.SOCKET_RESET: {
      return { ...state, workspaceSocketManager: undefined, connectedToNewSocket: false, drawnWorkspaceData: {} };
    }
    case WorkspaceActionType.SOCKET_SET_TRY_RECONNECT:
      return { ...state, workspaceAutomaticReconnect: action.data as boolean };
    case WorkspaceActionType.SOCKET_SET_ERROR_COUNT: {
      return { ...state, workspaceListenerErrorCount: action.data as number };
    }
    default:
      return state;
  }
}
