import React, { ReactNode } from 'react';
import MikeInnerExpandableButton, { OptionProps } from './MikeInnerExpandableButton';

interface IProps {
  options: Array<OptionProps>;
  active?: boolean;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: 'small' | 'medium' | 'large';
  children?: ReactNode;
}

const MikeExpandableButton: React.FC<IProps> = ({
  active,
  options,
  children,
  variant,
  color,
  size,
}) => {
  return options ? (
    <MikeInnerExpandableButton
      active={active}
      options={options}
      variant={variant}
      color={color}
      size={size}
    >
      {children && children}
    </MikeInnerExpandableButton>
  ) : (
    <MikeInnerExpandableButton
      active={true}
      options={[]}
      variant={variant}
      color={color}
      size={size}
    >
      {children && children}
    </MikeInnerExpandableButton>
  );
};

export default MikeExpandableButton;
