import MikeVisualizerUtils from '../../MikeVisualizer/lib/MikeVisualizerUtil';
import { IWorkspaceAttributeSettings } from '../../models/IWorkspaceAttributeSettings';
import {
  IWorkspaceAttribute,
  ATTRIBUTE_DATA_TYPES,
  IWorkspaceOptionAttributeValue,
  IWorkspaceNumberAttributeValue,
} from '../../models/IWorkspaceAttributes';
import { EUIQueryType, QUERY_TYPE } from '../../models/IWorkspaceUIQueries';
import { IQueryDefinitionApi, QUERY_DEFINITION_TYPES } from '../../models/IQueryDefinitions';
import { getCategoricalNames } from '../..//managers/annotation-utils';
import { EGeometryItemTypes, IWorkspaceGeometry } from '../../models/IGeometries';

/**
 * Determines if the attribute is categorical based on the attributeSettings given
 * @param attributeName
 * @param attributeSettings
 */
export const isAttributeCategorical = (
  attributeName: string,
  attributeSettings: Array<IWorkspaceAttributeSettings>,
): boolean => {
  if (!attributeSettings || attributeSettings.length === 0) {
    return false;
  }

  const settings = attributeSettings.find((s) => s.name === attributeName);

  return settings && settings.isCategorical;
};

/**
 * Returns the type of query based on the attribute and attribute settings
 * @param attribute
 * @param attributeSettings
 */
export const getAttributeQueryType = (
  attribute: IWorkspaceAttribute,
  attributeSettings: Array<IWorkspaceAttributeSettings>,
): EUIQueryType => {
  if (!attribute) {
    return QUERY_TYPE.SELECT_ALL;
  }

  const { name, dataType } = attribute;

  const isCategorical = self.isAttributeCategorical(name, attributeSettings);
  if (isCategorical) {
    return QUERY_TYPE.OPTION;
  }

  if (dataType === ATTRIBUTE_DATA_TYPES.DOUBLE || dataType === ATTRIBUTE_DATA_TYPES.INT32) {
    return QUERY_TYPE.RANGE;
  }

  return QUERY_TYPE.STRING;
};

export const isAttributeQuery = (queryDefinition: IQueryDefinitionApi) => {
  return queryDefinition && queryDefinition.type === QUERY_DEFINITION_TYPES.ATTRIBUTE_RANGE;
};

export const isPersistedSelectionQuery = (queryDefinition: IQueryDefinitionApi) => {
  return queryDefinition && queryDefinition.type === QUERY_DEFINITION_TYPES.PERSISTED_SELECTION;
};

export const isSpatialQuery = (queryDefinition: IQueryDefinitionApi) => {
  return queryDefinition && queryDefinition.type === QUERY_DEFINITION_TYPES.SPATIAL;
};

/**
 * For a given attribute of an item specified by itemId, it retrieves its values.
 * Currently only works with ranges (number).
 *
 * @param itemId
 * @param attribute
 * @param attributeSettings
 */
export const getAttributeValues = (
  itemId: string,
  attribute: IWorkspaceAttribute,
  attributeSettings: Array<IWorkspaceAttributeSettings>,
): Array<IWorkspaceOptionAttributeValue> | IWorkspaceNumberAttributeValue => {
  const { dataType, name } = attribute;
  const isCategorical = self.isAttributeCategorical(name, attributeSettings);

  if (isCategorical) {
    return self.getCategoricalAttributeValues(itemId, name);
  }

  if (dataType === ATTRIBUTE_DATA_TYPES.INT32 || dataType === ATTRIBUTE_DATA_TYPES.DOUBLE) {
    if (attribute.range) {
      const min = attribute.range[0];
      const max = attribute.range[1];

      return { min, max, count: -1 } as IWorkspaceNumberAttributeValue;
    }
  }

  // todo hevo String values are currently not supported.

  return null;
};

/**
 * For a given categorical attribute name  of an item (geometry, mesh, variable) specified by itemId, it retrieves its values.
 * Currently this is based on the datavalues being stored in the vizualizer. String values are not handled.
 * @param itemId
 * @param attributeName
 */
export const getCategoricalAttributeValues = (
  itemId: string,
  attributeName: string,
): Array<IWorkspaceOptionAttributeValue> => {
  const values = MikeVisualizerUtils.getDataValues(itemId, attributeName);

  const annotations = getCategoricalNames(values as any, attributeName);

  const attributeValues = (annotations || []).map(({ value, annotation }) => {
    return {
      value,
      displayName: annotation,
    } as IWorkspaceOptionAttributeValue;
  });

  return attributeValues;
};

export const getPolygonGeometryItems = (workspaceGeometries: Array<IWorkspaceGeometry>) => {
  const geoms = workspaceGeometries.filter(
    (geoItem) =>
      geoItem.itemType === EGeometryItemTypes.POLYGON || geoItem.itemType === EGeometryItemTypes.MULTI_POLYGON,
  );
  const sortable = geoms.sort((a, b) => (a.name > b.name ? 1 : -1));
  return sortable;
};

const self = {
  isAttributeCategorical,
  getAttributeQueryType,
  isAttributeQuery,
  isPersistedSelectionQuery,
  isSpatialQuery,
  getAttributeValues,
  getCategoricalAttributeValues,
  getPolygonGeometryItems,
};

export default self;
