import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { EMeshFormats, MESH_FORMATS } from '../../models/IWorkspaceData';
import { t } from '../../translations/i18n';

type SelectMeshExportFormatProps = {
  selectedFormat: EMeshFormats;
  onFormatChanged: (format: EMeshFormats) => void;
  disabled?: boolean;
};

/**
 *
 * @param props Allows user to select the format to use when exporting the mesh
 */
export const MmgSelectMeshExportFormat = (props: SelectMeshExportFormatProps) => {
  const { selectedFormat, onFormatChanged, disabled } = props;

  const sortedMeshFormats = [MESH_FORMATS.DOTMESH, MESH_FORMATS.DOTMESH_LONGLAT, MESH_FORMATS.VTU, MESH_FORMATS.DFSU];

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventTarget = event.target;

    if (onFormatChanged) {
      onFormatChanged((eventTarget.value as unknown) as EMeshFormats);
    }
  };

  return (
    <FormControl fullWidth component="fieldset" disabled={disabled}>
      <RadioGroup
        aria-label={'select-mesh-format'}
        name={'select-mesh-format'}
        value={selectedFormat.toString()}
        onChange={handleOnChange}
      >
        {sortedMeshFormats.map((key) => {
          const formatId = key.toString();
          return (
            <FormControlLabel
              key={formatId}
              value={formatId}
              control={<Radio />}
              label={t('EXPORT_MESH_FORMAT_' + formatId)}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
