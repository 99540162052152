/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Typography } from '@mui/material';

interface StepContentProps {
  children?: React.ReactNode;
  index: any;
  step: any;
  activeStep: any;
  className?: any;
}

export const StepContentStyle = css`
  width: 100%;
`;

export const MmgStepContent = (props: StepContentProps) => {
  const { children, step, index, activeStep, ...other } = props;

  return (
    <Typography
      component="div"
      hidden={step !== activeStep}
      id={`mmg-stepcontent-${index}`}
      aria-labelledby={`mmg-step-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
};
