/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from 'react';
import { t } from '../../../translations/i18n';
import { useSelector } from 'react-redux';
import { store } from '../../../store';
import Tooltip from '@mui/material/Tooltip';

import {
  ViewerToolsButtonStyle,
  ViewerToolsButtonActiveStyle,
  ViewerToolsWithSubmenuMenuStyle,
  ViewerToolsWithSubmenuMenuIndicatorStyle,
} from '../../../workspaces/viewer/tools/viewer-tools-styles';
import ViewerToolUtils from '../../../workspaces/viewer/tools/viewer-tool-utils';
import NestedTools from '../../../icons/NestedTools.svg?react';
import Close from '../../../icons/Close.svg?react';
import Measure from '../../../icons/Measure.svg?react';
import Distance from '../../../icons/Distance.svg?react';
import Area from '../../../icons/Area.svg?react';
import MeasureM from '../../../icons/MeasureM.svg?react';
import MeasureFt from '../../../icons/MeasureFt.svg?react';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';;
import { SUBMENUS } from '../../../workspaces/viewer/tools/viewer-tool-constants';
import { IGlobalState } from '../../../store/reducers';
import { css } from '@emotion/react';

const { toggleSubmenu } = ViewerToolUtils;
const { enable2DAreaMeasure, enable2DLineMeasure, disableMeasureTools, setLineMeasurementUnit } = MikeVisualizer;

/**
 * @name MmgConnectedViewerTool2DMeasure
 * @summary Allows enabling/disabling 2d measurement tools
 *
 */
export function MmgConnectedViewerTool2DMeasure() {
  const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  const { lineMeasurementEnabled, areaMeasurementEnabled, openSubmenu } = useSelector(
    (state: IGlobalState) => state.MapToolReducer,
  );

  const epsgCode = useMemo(
    () => {
      return workspace ? workspace.epsgCode : null;
    },
    [workspace],
  );

  /**
   * Toggles the measure tools submenu.
   */
  const toggleMeasureSubmenu = () => {
    toggleSubmenu(SUBMENUS.MEASURE);
  };

  const dispatchEnableLineMeasurement = () => {
    store.dispatch({ type: 'maptools/ENABLE_LINE_MEASUREMENT' });
  };

  const dispatchEnableAreaMeasurement = () => {
    store.dispatch({ type: 'maptools/ENABLE_AREA_MEASUREMENT' });
  };

  const dispatchDisableLineMeasurement = () => {
    store.dispatch({ type: 'maptools/DISABLE_LINE_MEASUREMENT' });
  };

  const dispatchDisableAreaMeasurement = () => {
    store.dispatch({ type: 'maptools/DISABLE_AREA_MEASUREMENT' });
  };

  useEffect(
    () => {
      if (lineMeasurementEnabled) {
        enable2DLineMeasure();
      }

      if (areaMeasurementEnabled) {
        enable2DAreaMeasure();
      }
      if (!lineMeasurementEnabled && !areaMeasurementEnabled) {
        disableMeasureTools();
      }
    },
    [lineMeasurementEnabled, areaMeasurementEnabled],
  );

  if (!epsgCode) {
    return <></>;
  }

  return (
    <>
      {/* TODO dan: disable buttons if projection is not supported */}
      <Tooltip title={t('TOOL_MEASUREMENTS')} placement="right">
        <button css={ViewerToolsButtonStyle} onClick={toggleMeasureSubmenu}>
          <Measure />
        </button>
      </Tooltip>

      <span css={ViewerToolsWithSubmenuMenuIndicatorStyle}>
        <NestedTools />
      </span>

      {openSubmenu === SUBMENUS.MEASURE && (
        <ul css={ViewerToolsWithSubmenuMenuStyle}>
          <li>
            <button
              css={ViewerToolsButtonStyle}
              onClick={dispatchEnableLineMeasurement}
              hidden={lineMeasurementEnabled}
              title={t('TOOL_MEASURE_LINE')}
            >
              <Distance />
            </button>
            <button
              css={css`${ViewerToolsButtonStyle} ${ViewerToolsButtonActiveStyle}`}
              onClick={dispatchDisableLineMeasurement}
              hidden={!lineMeasurementEnabled}
            >
              <Close />
            </button>
          </li>

          <li>
            <button
              css={ViewerToolsButtonStyle}
              onClick={dispatchEnableAreaMeasurement}
              hidden={areaMeasurementEnabled}
              title={t('TOOL_MEASURE_AREA')}
            >
              <Area />
            </button>

            <button
              css={css`${ViewerToolsButtonStyle} ${ViewerToolsButtonActiveStyle}`}
              onClick={dispatchDisableAreaMeasurement}
              hidden={!areaMeasurementEnabled}
            >
              <Close />
            </button>
          </li>
          <ul>
            <li>
              <button
                css={ViewerToolsButtonStyle}
                onClick={() => {
                  setLineMeasurementUnit('metric');
                }}
              >
                <MeasureM />
              </button>
              <button
                css={ViewerToolsButtonStyle}
                onClick={() => {
                  setLineMeasurementUnit('english');
                }}
              >
                <MeasureFt />
              </button>
            </li>
          </ul>
        </ul>
      )}
    </>
  );
}
