/** @jsxImportSource @emotion/react */
import { Typography, Link } from '@mui/material';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';
import { css } from '@emotion/react'
import ArrowRight from '../../icons/ArrowRight.svg?react';

import { ILinkConfig } from './ILinkConfig';

interface IProps {
  /**
   * Configuration.
   */
  config: ILinkConfig;
}

const linkStyle  =
  css`
    display: flex;
    align-items: center;
    padding: ${mikeSharedTheme.spacing(2)};   
    color: ${mikeSharedTheme.palette.primary.main};
`;

const linkTextStyle  =
  css`    
    padding-left: ${mikeSharedTheme.spacing(1)};
    color: ${mikeSharedTheme.palette.secondary.main};
`;

/**
 * @name MikeSupportMenuLinkItem
 * @summary A generic support link. Meant to be placed in the mike support menu container.
 */
export const MikeSupportMenuLinkItem = (props: IProps) => { 
  const { config = {} as ILinkConfig } = props;

  return (
    <Link
      rel="noopener"
      href={config.href}
      target={config.target ? config.target : ''}
      css={linkStyle}
      underline="none"
    >
      {config.icon ? config.icon : <ArrowRight />}
      {config.label && <Typography css={linkTextStyle}>{config.label}</Typography>}
    </Link>
  );
};

export default MikeSupportMenuLinkItem;
