/**
 * Exposes utility methods for operationicons.
 *
 * @module OperationIconUtils
 * @version 1.0.0
 */
import React from 'react';
import Operations from '../icons/Operations.svg?react';
import Mystery from '../icons/Mystery.svg?react';
import { IOperationMetadata, OPERATION_RESULT_TYPES } from '../models/IOperations';

const getIcon = (operation: IOperationMetadata) => {
  const iconName = getIconName(operation);
  return getIconByName(iconName);
};

const getIconName = (operation: IOperationMetadata) => {
  if (!operation) {
    return '';
  }

  return operation.resultType;
};

const getIconByName = (iconName: string): React.ReactNode => {
  switch (iconName) {
    case OPERATION_RESULT_TYPES.GEOMETRY:
    case OPERATION_RESULT_TYPES.MESH:
    case OPERATION_RESULT_TYPES.VARIABLE:
      return <Operations />;

    default: {
      console.debug(`No operation icon found for ${iconName} `);
      return <Mystery />;
    }
  }
};

const self = {
  getIcon,
  getIconName,
  getIconByName,
};

export default self;
