import { IDrawnDataItem } from '../../models/IWorkspaceData';
import { MmgVisualizationSettingsSection } from '../../shared/visualization-settings/visualization-settings-section';
import { MmgStatisticsSection } from '../../shared/statistics/statistics-section';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { IWorkspaceEnrichedVariable } from '../../models/IVariables';
import LayerUtils from '../../shared/layers/layer-utils';
import { MmgGroup } from '../../shared/groups/group';
import { t } from '../../translations/i18n';

const { isLayerFailed } = LayerUtils;

type VariableOverviewProps = {
  variable: IWorkspaceEnrichedVariable;
  variableDrawnData: IDrawnDataItem;
};

// todo hevo consider if this component should be connected

/**
 * @name MmgVariableOverview
 * @summary Displays variable  overview information, such as statistics
 *
 * @param props
 */
export const MmgVariableOverview = (props: VariableOverviewProps) => {
  const { variable, variableDrawnData } = props;

  const isFailed = isLayerFailed(variable);
  return (
    variable && (
      <>
        {!isFailed &&
          variableDrawnData && (
            <>
              <MmgGroup groupName={t('ANALYZE_VARIABLE')}>
                <MmgStatisticsSection
                  enrichedDataItem={variable}
                  elementId={variable.id}
                  elementCategory={ELEMENT_CATEGORIES.VARIABLE}
                  dataArrays={variableDrawnData.dataArrays}
                  fieldDataArrays={variableDrawnData.fieldData}
                />
              </MmgGroup>
              <MmgVisualizationSettingsSection
                elementId={variable.id}
                canApplyRepresentation={true}
                representation={variableDrawnData.representation}
              />
            </>
          )}
      </>
    )
  );
};
