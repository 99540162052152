/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import mikeSharedTheme from '../../shared/styles/mikeSharedTheme';

type ExportItemsMessageProps = {
  message: string;
};

export const ExportItemsMessageCss = css`
  padding: 0 ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(2)};
`;
/**
 * Will show a message text for exportable items
 * @param props
 */
export const MmgExportItemsMessage = (props: ExportItemsMessageProps) => {
  const { message } = props;

  return (
    message && (
      <Typography variant="body2" css={ExportItemsMessageCss}>
        {message}
      </Typography>
    )
  );
};
