import React from 'react';
import { useIsMounted } from '../hooks/hooks';

/**
 * Custom hook for handling navigation with stepper
 * @param initialStep
 */
export const useStepper = (initialStep = 0) => {
  const [activeStep, setActiveStep] = React.useState(initialStep);

  const isMounted = useIsMounted();

  const updateActiveStep = (newValue) => {
    if (isMounted()) {
      setActiveStep(newValue);
    }
  };

  const handleNext = () => {
    if (isMounted()) {
      updateActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (isMounted()) {
      updateActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const goToStep = (step: number) => {
    if (isMounted()) {
      updateActiveStep(step);
    }
  };

  return {
    activeStep,
    handleNext,
    handleBack,
    goToStep,
  };
};
