import { INotification } from '../../shared-components/mike-notification-list';
import { ENotificationActionType } from './NotificationActionType';

export const hideBlueDotOnTopbarAlert = (hide = true) => ({
  type: ENotificationActionType.HIDE_BLUE_DOT,
  data: hide,
});

export const setNotification = (notifications: INotification[]) => ({
  type: ENotificationActionType.SET_NOTIFICATION,
  data: notifications,
});
