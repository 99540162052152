import React, { useEffect } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MikeButton, { IMikeButtonProps } from '../mike-button/MikeButton';
import MikeExpandableButtonEntries from './MikeExpandableButtonEntries';
import MIKE_COLORS from '../../MikeVisualizer/colors/mike-colors';

export type OptionProps = {
  label: string;
  callBack?: () => void;
  disabled?: boolean;
};

const MuiButtonGroupSX = (props: IMikeButtonProps) => {
  if (props.size !== 'large'){
    return {     
      minWidth: 156,
      maxWidth: 156
    };
  }
  else{
    return {
      minWidth: 328,
      maxWidth: 328,
    }
  }
}; 

export interface IExpandableButtonProps extends IMikeButtonProps {
  options: Array<OptionProps>;
}

export const MikeInnerExpandableButton = (props: IExpandableButtonProps) => {
  const { options, color, variant, active, children, ...rest } = props;

  const [menuWidth, setMenuWidth] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    const buttonGroup = anchorRef.current;
    if (buttonGroup) {
      const rect = buttonGroup.getBoundingClientRect();
      setMenuWidth(rect.width);
    }
  }, []);

  const sendCallBack = (currentOptionIndex: number) => {
    const option = options[currentOptionIndex];
    const { callBack } = option;
    if (callBack) {
      callBack();
    }
  };

  const handleClick = () => {
    sendCallBack(selectedIndex);
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    sendCallBack(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        color={color ? color : 'secondary'}
        variant={variant ? variant : 'contained'}
        ref={anchorRef}
        aria-label="split button"
        sx={MuiButtonGroupSX(props)}
        fullWidth
      >
        <MikeButton
          {...rest}
          onClick={handleClick}
          disabled={options[selectedIndex] && options[selectedIndex].disabled}
        >
          {children ? (
            children
          ) : (
            <div
              style={{
                color:
                  color === 'secondary' || !color
                    ? MIKE_COLORS.WHITE
                    : MIKE_COLORS.BRANDBLUE_DEFAULT,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {options[selectedIndex] && options[selectedIndex].label}
            </div>
          )}
        </MikeButton>
        <MikeButton
          {...rest}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleToggle}
          style={{ width: '2.5rem' }}
        >
          <ArrowDropDownIcon />
        </MikeButton>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" style={{ width: menuWidth }}>
                  <MikeExpandableButtonEntries
                    options={options}
                    selectedIndex={selectedIndex}
                    handleClick={handleMenuItemClick}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default MikeInnerExpandableButton;
