import { Feature } from 'geojson';
import { differenceWith, isNaN as _isNaN } from 'lodash-es';

/**
 * Attempts to parse a property value in the right type.
 * This is needed, because text fields always cast values to strings.
 *
 * TODO dan: this might need some improvements regarding type support, if we decide to support more complex types.
 *
 * @param value
 */
export const parsePropertyValue = (value: string): string | number | boolean => {
  const floatValue = parseFloat(value);
  // Return the passed numbers, otherwise strings or booleans should become NaN. They are returned as-is.
  return _isNaN(floatValue) ? value : floatValue;
};

/**
 * Filters out property keys that should not be editable.
 * For now, that is the id, but there might be other internal properties we should filter.
 *
 * @param properties
 */
export const getAllowedProperties = (properties: {
  [key: string]: string | number | boolean;
}): { [key: string]: string | number | boolean } => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: _omit, ...allowedProperties } = properties;
  return allowedProperties;
};

/**
 * Determines new features between two arrays of features.
 *
 * @param newFeatures
 * @param oldFeatures
 */
export const getNewFeatures = (
  newFeatures: Array<Feature<any, any>>,
  oldFeatures: Array<Feature<any, any>>,
): Array<Feature<any, any>> => {
  const diff =
    oldFeatures && oldFeatures.length
      ? differenceWith(newFeatures, oldFeatures, (newFeature: Feature<any, any>, oldFeature: Feature<any, any>) => {
          const newFeatureId = self.getFeatureId(newFeature);
          const oldFeatureId = self.getFeatureId(oldFeature);

          return newFeatureId === oldFeatureId;
        })
      : newFeatures;

  return diff;
};

/**
 * Determines new feature ids between two arrays of features.
 *
 * @param newFeatures
 * @param oldFeatures
 */
export const getNewFeatureIds = (
  newFeatures: Array<Feature<any, any>>,
  oldFeatures: Array<Feature<any, any>>,
): Array<string | number> => {
  return self.getNewFeatures(newFeatures, oldFeatures).map(({ id }) => id);
};

/**
 * Gets a feature id, either on the feature itself or in its properties, if it exists.
 *
 * @param feature
 */
export const getFeatureId = (feature: Feature<any, any>): string => {
  if (!feature) {
    return;
  }

  if (feature.id) {
    return String(feature.id);
  }

  if (feature.properties && feature.properties.id) {
    return String(feature.properties.id);
  }

  return;
};

const self = {
  parsePropertyValue,
  getAllowedProperties,
  getNewFeatures,
  getNewFeatureIds,
  getFeatureId,
};

export default self;
